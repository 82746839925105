import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { HeaderLandscape } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';

var htmlToImage = require('html-to-image');

export const generateMotivationExercisePDF = (props, state) => {
    const resultPage = document.getElementById('motivationPolygon');
    if (resultPage) {
        resultPage.className += ' result-print';
        htmlToImage.toJpeg(resultPage, { quality: 1, backgroundColor: "#FFFFFF", height: resultPage.clientHeight, width: resultPage.clientWidth })
            .then((dataUrl) => {
                resultPage.className = resultPage.className.replace("result-print", "");

                let documentname = props.intl.formatMessage({ id: "motivationExercise.label" });
                let leadershipSum, foundingSum, independenceSum, dedicationSum, workLifeSum, knowledgeSum, securitySum, challengeSum = 0;
                state.motivations.forEach(mot => {
                    if (mot.name === "motivationExercise.leadership.label") {
                        leadershipSum = mot.sum;
                    } else if (mot.name === "motivationExercise.founding.label") {
                        foundingSum = mot.sum;
                    } else if (mot.name === "motivationExercise.independence.label") {
                        independenceSum = mot.sum;
                    } else if (mot.name === "motivationExercise.dedication.label") {
                        dedicationSum = mot.sum;
                    } else if (mot.name === "motivationExercise.workLife.label") {
                        workLifeSum = mot.sum;
                    } else if (mot.name === "motivationExercise.knowledge.label") {
                        knowledgeSum = mot.sum;
                    } else if (mot.name === "motivationExercise.security.label") {
                        securitySum = mot.sum;
                    } else if (mot.name === "motivationExercise.challenge.label") {
                        challengeSum = mot.sum;
                    }
                });
                let rowMotivation = [];
                rowMotivation.push([
                    ' ',
                    [{ text: props.intl.formatMessage({ id: "motivationExercise.leadership.label" }) + " [" + leadershipSum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.leadership.description" }), fontSize: 9, alignment: 'justify' }],
                    '']);
                rowMotivation.push([
                    [{ text: props.intl.formatMessage({ id: "motivationExercise.security.label" }) + " [" + securitySum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.security.description" }), fontSize: 9, alignment: 'justify', margin: [0, 0, 0, 10] },
                    { text: "", fontSize: 10 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.independence.label" }) + " [" + independenceSum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.independence.description" }), fontSize: 9, alignment: 'justify', margin: [0, 0, 0, 10] },
                    { text: props.intl.formatMessage({ id: "motivationExercise.knowledge.label" }) + " [" + knowledgeSum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.knowledge.description" }), fontSize: 9, alignment: 'justify' }],
                    { image: dataUrl, width: 280, height: 280, alignment: 'center' },
                    [{ text: props.intl.formatMessage({ id: "motivationExercise.workLife.label" }) + " [" + workLifeSum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.workLife.description" }), fontSize: 9, alignment: 'justify', margin: [0, 0, 0, 10] },
                    { text: props.intl.formatMessage({ id: "motivationExercise.challenge.label" }) + " [" + challengeSum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.challenge.description" }), fontSize: 9, alignment: 'justify', margin: [0, 0, 0, 10] },
                    { text: props.intl.formatMessage({ id: "motivationExercise.dedication.label" }) + " [" + dedicationSum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.dedication.description" }), fontSize: 9, alignment: 'justify' }]
                ]);
                rowMotivation.push([
                    ' ',
                    [{ text: props.intl.formatMessage({ id: "motivationExercise.founding.label" }) + " [" + foundingSum + "]", bold: true, fontSize: 9 },
                    { text: props.intl.formatMessage({ id: "motivationExercise.founding.description" }), fontSize: 9, alignment: 'justify' }],
                    '']);

                let rowEval = [];
                rowEval.push([{ text: props.intl.formatMessage({ id: "motivationExercise.result" }), bold: true }, { text: props.intl.formatMessage({ id: "motivationExercise.label1" }), bold: true }]);
                state.motivations.forEach((mot) => {
                    rowEval.push([mot.sum, props.intl.formatMessage({ id: mot.name })]);
                });

                try {
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    let docDefinition = {
                        pageOrientation: 'landscape',
                        pageSize: 'A4',
                        images: Images,
                        header: HeaderLandscape,
                        footer: (currentPage, pageCount) => { return footer(props, true, currentPage, pageCount, true) },
                        pageMargins: [40, 60, 40, 65],
                        content: [
                            {
                                text: props.intl.formatMessage({ id: "motivationExercise.label" }), fontSize: 20, fontWeight: 'bold', color: '#d11b28', lineHeight: 1.5
                            },
                            {
                                text: props.intl.formatMessage({ id: 'roles.student' }) + ": " + props.auth.firstname + " " + props.auth.lastname, lineHeight: 1.5
                            },
                            {
                                layout: 'noBorders', // optional
                                table: {
                                    // headers are automatically repeated if the table spans over multiple pages
                                    // you can declare how many rows should be treated as headers
                                    headerRows: 0,
                                    widths: ['30%', 'auto', '30%'],

                                    body: rowMotivation,
                                }
                            },
                            // {
                            //     pageBreak: 'before',
                            //     text: props.intl.formatMessage({ id: "motivationExercise.label" }), fontSize: 20, fontWeight: 'bold',  color: '#d11b28', fillColor: [255, 255, 255], lineHeight:1.5   
                            // },
                            // {
                            //     // layout: 'noBorders', // optional
                            //     table: {
                            //       headerRows: 0,
                            //       widths: [ '10%', '30%' ],
                            //       body: rowEval,
                            //     }
                            // },
                        ]
                    }
                    pdfMake.createPdf(docDefinition).download(documentname);
                } catch (error) {
                    console.error('Error: create_pdf(' + documentname + ')', error);
                }
            }).catch((error) => {
                console.error('Error: htmlToImage', error);
            });
    }
}