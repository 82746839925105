import { actionTypes } from '../actions/types';

const initialState = {
    inquirySendOfSchool: [],
    inquiryOfSchool: [],
    inquiryOfSchoolAndExecution: [],
    loading: false,
    loadingError: '',
    createError: '',
    updateError: '',
    createInquiryState: 0,
    updateInquiryState: 0,
    total_pages: 1,
    total: 0,
    openInquiry: 0,
};

const inquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INQUIRY_OF_SCHOOL_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.INQUIRY_OF_SCHOOL_SUCCESS:
            return {
                ...state,
                inquiryOfSchool: action.inquiry ? action.inquiry.data : [],
                total_pages: action.inquiry ? action.inquiry.total_pages : 1,
                total: action.inquiry ? action.inquiry.total : 0,
                loading: false
            };
        case actionTypes.INQUIRY_OF_SCHOOL_FAILURE:
            return {
                ...state,
                inquiryOfSchool: [],
                total_pages: 1,
                total: 0,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.INQUIRY_OF_SCHOOL_AND_EXECUTION_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.INQUIRY_OF_SCHOOL_AND_EXECUTION_SUCCESS:
            return {
                ...state,
                inquiryOfSchoolAndExecution: action.inquiry ? action.inquiry.data : [],
                total_pages: action.inquiry ? action.inquiry.total_pages : 1,
                total: action.inquiry ? action.inquiry.total : 0,
                loading: false
            };
        case actionTypes.INQUIRY_OF_SCHOOL_AND_EXECUTION_FAILURE:
            return {
                ...state,
                inquiryOfSchoolAndExecution: [],
                total_pages: 1,
                total: 0,
                loading: false,
                loadingError: action.error,
            };

        case actionTypes.INQUIRY_CREATE_REQUEST:
            return {
                ...state,
                createInquiryState: 0,
                loading: true,
                createError: '',
            };
        case actionTypes.INQUIRY_CREATE_SUCCESS:
            return {
                ...state,
                createInquiryState: 1,
                loading: false,
            };
        case actionTypes.INQUIRY_CREATE_FAILURE:
            return {
                ...state,
                createInquiryState: 2,
                loading: false,
                createError: action.error,
            };
        case actionTypes.INQUIRY_UPDATE_REQUEST:
            return {
                ...state,
                updateInquiryState: 0,
                loading: true,
                updateError: '',
            };
        case actionTypes.INQUIRY_UPDATE_SUCCESS:
            return {
                ...state,
                updateInquiryState: 1,
                loading: false,
            };
        case actionTypes.INQUIRY_UPDATE_FAILURE:
            return {
                ...state,
                updateInquiryState: 2,
                loading: false,
                updateError: action.error,
            };
        case actionTypes.INQUIRY_SEND_OF_SCHOOL_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.INQUIRY_SEND_OF_SCHOOL_SUCCESS:
            return {
                ...state,
                inquirySendOfSchool: action.inquiry ? action.inquiry.data : [],
                total_pages: action.inquiry ? action.inquiry.total_pages : 1,
                total: action.inquiry ? action.inquiry.total : 0,
                loading: false
            };
        case actionTypes.INQUIRY_SEND_OF_SCHOOL_FAILURE:
            return {
                ...state,
                inquirySendOfSchool: [],
                total_pages: 1,
                total: 0,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.INQUIRY_COUNT_OPEN_REQUEST:
            return {
                ...state,
            };
        case actionTypes.INQUIRY_COUNT_OPEN_SUCCESS:
            return {
                ...state,
                openInquiry: action.inquiry.open
            };
        case actionTypes.INQUIRY_COUNT_OPEN_FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default inquiryReducer;
