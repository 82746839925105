import * as React from 'react';
import { Container, Input, Label } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadService } from '../services';

class UploadFile extends React.Component {
    static propTypes = {
        // redux state
        className: PropTypes.string,
        uploadUrl: PropTypes.string,
        onUploadSuccess: PropTypes.func,
        onUploadFailure: PropTypes.func,
        allowedFileExts: PropTypes.string,
        buttonText: PropTypes.string,
        procedure: PropTypes.number,
        // redux dispatch
        // ...
    };

    static defaultProps = {
        className: '',
        uploadUrl: '',
        onUploadSuccess: null,
        onUploadFailure: null,
        allowedFileExts: '',
        buttonText: '',
    }

    constructor(props) {
        super(props);
        this.state = {
            file: '',
            uploading: false,
            openImportModal: false,
            showUploadError: false
        };
    }

    onFileChange(e) {
        let file = e.target.files[0];

        if (this.state.uploading) return;
        this.setState({ uploading: true });

        this.fileUpload(file).then((response) => {
            this.setState({ uploading: false });

            if (this.props.onUploadSuccess) {
                this.props.onUploadSuccess(response, this.props.procedure);
            }

        }).catch((err) => {
            this.setState({ uploading: false });

            if (this.props.onUploadFailure) {
                this.props.onUploadFailure(err.message);
            }
        });
    }

    fileUpload(file) {
        const formData = new FormData();
        formData.append('importFile', file);
        return uploadService.uploadFile(this.props.auth.token, this.props.uploadUrl, formData);
    }

    render() {
        return (
            <Container fluid className={"" + this.props.className.toString()}>
                <Label className="btn btn-primary w-100">
                    {this.props.buttonText}
                    <Input type="file" onChange={this.onFileChange.bind(this)} onClick={event => event.target.value = null} accept={this.props.allowedFileExts} hidden />
                </Label>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadFile));