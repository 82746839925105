import * as React from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';


class Offline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            online: true,
        }

        window.addEventListener('online', this.updateOnlineStatus.bind(this));
        window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    }

    updateOnlineStatus = (event) => {
        this.setState({ online: navigator.onLine });
    }

    render() {
        return (
            <div>
                <div className={this.state.online ? "d-none" : "offline"}>
                    <FormattedMessage id="offline" />
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default injectIntl(Offline);