import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


class DisplayRoles extends React.Component {

    static propTypes = {
        roles: PropTypes.array.isRequired,
    }

    static defaultProps = {
        short: true
    }

    render() {
        if (this.props.roles) {
            return (
                this.props.roles.map((role, index) => {
                    return (
                        <div key={index} className="display-role">
                            <FormattedMessage id={role.name} />
                        </div>
                    )
                })
            )
        }
        return null;
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayRoles));