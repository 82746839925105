import ENV from '../config/env';

export const verbexerciseService = {
    getVerbs,
    saveVerbs,
    saveResultVerbs,
    deleteVerbs
};

async function getVerbs(token, executionId, studentsId, honorarkraftId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };
    
    studentsId = studentsId ? studentsId : '';
    honorarkraftId = honorarkraftId > 0 ? honorarkraftId : '';

    return fetch(ENV.apiUrl + '/verbexercise?executionId=' + executionId + '&studentId=' + studentsId + '&honorarkraftId=' + honorarkraftId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();
        }).then(response => {
            return response;
        });
}

async function deleteVerbs(token, executionId, studentsId, honorarkraftId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };
    
    studentsId = studentsId ? studentsId : '';
    honorarkraftId = honorarkraftId > 0 ? honorarkraftId : '';

    return fetch(ENV.apiUrl + '/verbexercise?executionId=' + executionId + '&studentId=' + studentsId + '&honorarkraftId=' + honorarkraftId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();
        }).then(response => {
            return response;
        });
}

async function saveVerbs(token, executionId, verbs) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({verbs: verbs})
    };

    return fetch(ENV.apiUrl + '/verbexercise?executionId=' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return 'save-error';
            }
            return response;
        });
}

async function saveResultVerbs(token, executionId, verbsResult) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({verbsResult: verbsResult})
    };

    return fetch(ENV.apiUrl + '/verbexerciseResult?executionId=' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return 'save-error';
            }
            return response;
        });
}