import ENV from '../config/env';

export const competencesService = {
    getCompetences,
    getCompetencesStudentObserver,
    createUsersCompetences,
    getStudentCompetencesStudentObserver,
    checkOutUsersCompetences,
    checkInUsersCompetences,
    getCompetencesKompog,
    createUsersCompetencesKompog
};

async function getCompetences(token, executionId, studentsId, honorarkraftId, order, desc, enabled) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };
    enabled = enabled !== null ? ("&enabled=" + (enabled ? "1" : "0")) : "";
    studentsId = studentsId ? '/' + studentsId : '';
    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";

    return fetch(ENV.apiUrl + '/competences/' + executionId + studentsId + honorarkraftId + '?order=' + order + "&desc=" + (desc ? "1" : "0") + enabled, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });
}
async function getCompetencesStudentObserver(token, executionId, studentsId, honorarkraftId, order, desc, enabled) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };
    enabled = enabled !== null ? ("&enabled=" + (enabled ? "1" : "0")) : "";
    studentsId = studentsId ? '/' + studentsId : '';
    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";

    return fetch(ENV.apiUrl + '/competencesstudentobserver/' + executionId + studentsId + honorarkraftId + '?order=' + order + "&desc=" + (desc ? "1" : "0") + enabled, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}
async function getStudentCompetencesStudentObserver(token, executionId, order, desc, enabled) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };
    enabled = enabled !== null ? ("&enabled=" + (enabled ? "1" : "0")) : "";

    return fetch(ENV.apiUrl + '/competencesstudentobserver/' + executionId + '?order=' + order + "&desc=" + (desc ? "1" : "0") + enabled, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

async function getCompetencesKompog(token, executionId, studentId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };

    return fetch(ENV.apiUrl + '/competencesKompog?user_id=' + studentId + '&execution_id=' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.json();
        }).then(response => {
            return response;
        });
}


/**
* CREATE USERSCOMPETENCES
*/
async function createUsersCompetences(token, executionId, studentsId, honorarkraftId, rating, ) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ rating: rating })
    };

    studentsId = studentsId ? '/' + studentsId : '';
    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";

    return fetch(ENV.apiUrl + '/competences/' + executionId + studentsId + honorarkraftId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response);
            }

            if (response.status === 204) {
                return 'save-error';
            }
            return response;

        });

}

async function createUsersCompetencesKompog(token, executionId, studentId, honorarkraftId, competences) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({competences: competences})
    };

    let student = studentId ? studentId : -1;
    let execution = executionId ? executionId : -1;
    let honorarkraft = honorarkraftId ? honorarkraftId : -1;

    return fetch(ENV.apiUrl + '/competencesKompog?execution=' + execution + '&student=' + student + '&honorarkraft=' + honorarkraft, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return 'save-error';
            }
            return response;
        });

}


/**
* CHECK OUT USERSCOMPETENCES
*/
async function checkOutUsersCompetences(token, executionId, studentId, editorId) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };

    return fetch(ENV.apiUrl + '/competences/checkout/' + executionId + '/' + studentId + '/' + editorId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response);
            }

            return response.json();

        }).then(response => {
            return response;
        });
}

/**
* CHECK IN USERSCOMPETENCES
*/
async function checkInUsersCompetences(token, executionId, studentId, editorId) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };

    return fetch(ENV.apiUrl + '/competences/checkin/' + editorId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response);
            }

            return response;

        }).then(response => {
            return response;
        });
}
