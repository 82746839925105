import ENV from '../config/env';

export const kompoGResultService = {
    getResult,
    getAllPupilResults,
    saveResult,
    updateResultTeacher
};

async function getResult(token, executionId, studentsId, honorarkraftId) {
  
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };
    
    studentsId = studentsId ? studentsId : '';
    honorarkraftId = honorarkraftId > 0 ? honorarkraftId : '';
    return fetch(ENV.apiUrl + '/kompoGresult?executionId=' + executionId + '&studentId=' + studentsId + '&honorarkraftId=' + honorarkraftId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();
        }).then(response => {
            return response;
        });
}

async function getAllPupilResults(token, executionId, order, desc) {
  
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };
    order = order ? "&order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    return fetch(ENV.apiUrl + '/kompoGAllPupilResults?executionId=' + executionId + order + desc, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();
        }).then(response => {
            return response;
        });
}


async function saveResult(token, executionId, result) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({result: result})
    };

    return fetch(ENV.apiUrl + '/kompoGresult?executionId=' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return 'save-error';
            }
            return response;
        });
}

async function updateResultTeacher(token, executionId, feedback) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({feedback: feedback})
    };

    return fetch(ENV.apiUrl + '/kompoGresultFeedback?executionId=' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return 'save-error';
            }
            return response;
        });
}



