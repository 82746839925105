import { actionTypes } from '../actions/types';

const initialState = {
    skillsProfile: null,
    skillsProfileError: '',
    loading: false,
    createStatus: 0,
    updateStatus: 0,
    approvedStatus: 0,
};

const skillsProfilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SKILLSPROFILE_REQUEST:
            return {
                ...state,
                skillsProfileError: '',
                loading: true,
            };
        case actionTypes.SKILLSPROFILE_SUCCESS:
            return {
                ...state,
                skillsProfile:  action.skillsProfile && action.skillsProfile.skillsProfile ? action.skillsProfile.skillsProfile : null,
                skillsProfileError: '',
                loading: false,
            };
        case actionTypes.SKILLSPROFILE_FAILURE:
            return {
                ...state,
                skillsProfileError: action.error,
                skillsProfile: null,
                loading: false,
            };        
        case actionTypes.SKILLSPROFILE_STUDENT_REQUEST:
            return {
                ...state,
                skillsProfileError: '',
                loading: true,
            };
        case actionTypes.SKILLSPROFILE_STUDENT_SUCCESS:
            return {
                ...state,
                skillsProfile:  action.skillsProfile && action.skillsProfile.skillsProfile ? action.skillsProfile.skillsProfile : null,
                skillsProfileError: '',
                loading:false
            };
        case actionTypes.SKILLSPROFILE_STUDENT_FAILURE:
            return {
                ...state,
                skillsProfileError: action.error,
                skillsProfile: null,
                loading:false
            };        
        case actionTypes.SKILLSPROFILE_CREATE_REQUEST:
            return {
                ...state,
                skillsProfileError: '',
                createStatus: 0,
            };
        case actionTypes.SKILLSPROFILE_CREATE_SUCCESS:
            return {
                ...state,
                skillsProfileError: '',
                createStatus: 1,
            };
        case actionTypes.SKILLSPROFILE_CREATE_FAILURE:
            return {
                ...state,
                skillsProfileError: action.error,
                createStatus: 2,
            };
        case actionTypes.SKILLSPROFILE_UPDATE_REQUEST:
            return {
                ...state,
                skillsProfileError: '',
                updateStatus: 0,
            };
        case actionTypes.SKILLSPROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                skillsProfileError: '',
                updateStatus: 1,
            };
        case actionTypes.SKILLSPROFILE_UPDATE_FAILURE:
            return {
                ...state,
                skillsProfileError: action.error,
                updateStatus: 2,
            };
        case actionTypes.SKILLSPROFILE_APPROVED_REQUEST:
            return {
                ...state,
                skillsProfileError: '',
                approvedStatus: 0,
            };
        case actionTypes.SKILLSPROFILE_APPROVED_SUCCESS:
            return {
                ...state,
                skillsProfileError: '',
                approvedStatus: 1,
            };
        case actionTypes.SKILLSPROFILE_APPROVED_FAILURE:
            return {
                ...state,
                skillsProfileError: action.error,
                approvedStatus: 2,
            };
        default:
            return state;
    }
};

export default skillsProfilesReducer;
