import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ProcedureSelect extends React.Component {

    static propTypes = {
        procedureId: PropTypes.number,
        handleProcedures: PropTypes.func,
        procedures: PropTypes.array,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        withEmpty: PropTypes.bool,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        disabled: PropTypes.bool,
        withLabel: PropTypes.bool,
        autoSelect: PropTypes.bool
    };

    static defaultTypes = {
        procedures: [],
        procedureId: -1,
        withEmpty: false,
        invalid: false,
        disabled: false,
        label: "procedureselect.label.default",
        autoSelect: false
    };

    componentDidMount() {
        this.autoSelection(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.procedures !== this.props.procedures || prevProps.procedureId !== this.props.procedureId) {
            this.autoSelection(this.props);
        }
    }

    // select the current procedure if the current selection id is -1 and autoSelection is true
    autoSelection(props) {
        if (props.proceduresId === -1 && props.procedures.length > 0) {
            if (!props.withEmpty || props.autoSelect) {
                props.handleProcedures(props.procedures[0]);
            }
        }
    }

    createProcedureItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: 'procedureselect.option.pleaseselect' })}</option>);
        this.props.procedures.forEach((procedure) => {
            items.push(<option key={procedure.id} value={procedure.id}>{procedure.name}</option>);
        });
        return items;
    }

    // handleProcedures = (e) => {
    //     let id = parseInt(e.target.value, 10);
    //     let procedure = this.props.procedures.filter(el => el.id === id)[0] || { id: -1, start: 0, end: 0 };
    //     this.props.handleProcedures(procedure);
    // }

    handleProcedures = async (e) => {
        let id = parseInt(e.target.value, 10);
        let procedure = this.props.procedures.filter(el => el.id === id)[0] || { id: -1, start: 0, end: 0 };
        await this.props.handleProcedures(procedure);
    }

    render() {
        return (
            <Container fluid className={((this.props.procedures && this.props.procedures.length > 1) || this.props.withEmpty) ? this.props.className : "d-none"}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "procedureselect.label.default" || this.props.withEmpty ? "fw-bold" : "d-none"} for="procedures">
                        <FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled} invalid={this.props.invalid} bsSize="sm" type="select" name="procedures" id="procedures" value={this.props.procedureId} onChange={this.handleProcedures}>
                        {this.createProcedureItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProcedureSelect))

