import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from '../pdf/HeaderPDF';
import { Images } from '../pdf/ImagesPDF';
import { footer } from './FooterPDF';

const blueColor = '#383980';
const redColor = '#D11B28';
const grayColor = '#808080';
const whiteColor = '#ffffff';

const styles = {
    headline: {
        margin: [0, 20, 0, 0],
        fontSize: 20,
        fontWeight: 'bold',
        color: '#d11b28',
        fillColor: [255, 255, 255],
        height: (20 * window.devicePixelRatio), lineHeight: 1.5
    }
};

export const generateMyResultPDF = (props, state, content) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let result = null;

    try {
        let docDefinition = createDocument(props, state, content);
        result = pdfMake.createPdf(docDefinition);
    } catch (error) {
        console.error('Error: create pdf', error);
    }

    return result;
}

function createDocument(props, state, content) {
    return {
        pageOrientation: 'portrait',
        pageSize: 'A4',
        styles: styles,
        images: Images,
        header: Header,
        footer: (currentPage, pageCount) => { return footer(props, false, currentPage, pageCount) },
        pageMargins: [40, 60, 40, 68],
        content: createContent(props, state, content)
    };
}

function createContent(props, state, content) {
    const result = [];
    for (const entry of content) {
        result.push(createSingleContent(props, state, entry));
        result.push({ text: '', pageBreak: 'after' });
    }

    if (result.length > 0)
        result.pop();

    return result;
}

function createSingleContent(props, state, content) {
    let result = [
        createUserData(props, content),

        createColumns(
            [
                createSmallBox(translate(props, 'myresultPage.preferences'), blueColor,
                    createListing(props, content.preferences)),

                createSmallBox(translate(props, 'myresultPage.interests'), redColor,
                    createListing(props, content.interests), content.motivations.length > 3 ? content.motivations.length - 3 : 0)
            ],
            [
                createSmallBox(translate(props, 'myresultPage.competences'), blueColor,
                    createListing(props, content.competences)),

                createSmallBox(translate(props, 'myresultPage.motivation.label1'), redColor,
                    createListing(props, content.motivations))
            ]),

        createBigBox(translate(props, 'myresultPage.conclusion'), blueColor, createListingWithTextField(
            [
                { question: translate(props, 'myresultPage.conclusion.label1'), answer: content.answer1 },
                { question: translate(props, 'myresultPage.conclusion.label2'), answer: content.answer2 },
                { question: translate(props, 'myresultPage.conclusion.label3'), answer: content.answer3 }
            ])),
    ];
    if (content.releaseTeacher && content.feedbackTeacher !== "") {
        result = result.concat([
            { text: '', pageBreak: 'after' },
            createBigBox(translate(props, 'myresultPage.feedback.teacher'), blueColor, { text: content.feedbackTeacher }
            )]);
    }
    return result;
}

function createListing(props, array) {
    return { ul: array.map(value => translate(props, value)), margin: [4, 4, 0, 0] };
}

function translate(props, text) {
    return text !== '' ? props.intl.formatMessage({ id: text }) : '';
}

function createListingWithTextField(array) {
    const result = [];

    for (let entry of array)
        result.push([{
            unbreakable: true, stack: [
                { text: entry.question, color: grayColor, margin: [0, 10, 0, 0] },
                createTextField(entry.answer)
            ]
        }])

    return { type: 'none', ol: result, margin: [0, 0, 0, 10] };
}

function createUserData(props, content) {
    return [
        {
            text: props.intl.formatMessage({ id: "myresultPage.kompoG.label" }),
            style: 'headline'
        },
        {
            text: props.intl.formatMessage({ id: 'roles.student' }) + ": " + content.username,
            lineHeight: 1.5
        }
    ];
}

function createTextField(text) {
    return {
        table: {
            widths: [470],
            heights: [60],
            body: [[{ text: text, border: [false, false, false, false] }]]
        }
    };
}

function createColumns(array1, array2) {
    return {
        columns: [
            array1,
            array2
        ],
        columnGap: 10
    };
}

function createSmallBox(headline, color, content, diff = 0) {
    return {
        margin: [0, 20, 0, 0],
        table: {
            widths: [155, 70],
            heights: [12, 6, 68 + (diff > 0 ? diff * 20 : 0)],
            body: [
                [
                    { text: headline, rowSpan: 2, color: whiteColor, fillColor: color, margin: [4, 5, 0, 0], border: [false, false, false, false] },
                    { text: '', border: [false, false, false, false] }
                ],
                [
                    '',
                    { text: '', border: [false, true, true, false] }
                ],
                [
                    { ...content, colSpan: 2, border: [true, false, true, true], lineHeight: 1.5 },
                    ''
                ]
            ]
        },
        layout: {
            hLineColor: blueColor,
            vLineColor: blueColor
        }
    };
}

function createBigBox(headline, color, content) {
    return {
        margin: [0, 20, 0, 0],
        table: {
            widths: [300, 183],
            heights: [12, 6, 60],
            body: [
                [
                    { text: headline, rowSpan: 2, color: whiteColor, fillColor: color, margin: [6, 5, 0, 0] },
                    { text: '', border: [false, false, false, false] }
                ],
                [
                    '',
                    { text: '', border: [true, true, true, false] }
                ],
                [
                    { ...content, colSpan: 2, border: [true, false, true, true] },
                    ''
                ]
            ]
        },
        layout: {
            hLineColor: blueColor,
            vLineColor: blueColor
        }
    };
}


