import { actionTypes } from '../actions/types';

const initialState = {
    schools: [],
    schoolsForUser: [],
    schoolsLoading: false,
    schoolsError: '',
    schoolsContactPerson: [],
    schoolsTeacher: [],
    schoolsStudents: [],
    schoolyears: [],
    schooltypes: [],
    total: 0,
    loading: false,
    total_pages: 1,
    settings: [],
    settingSaveState: 0,
    settingsLoading: false,
    settingsLoadingError: '',
    createSchoolState: 0,
    createSchoolError: '',
    updateSchoolState: 0,
    updateSchoolError: '',
    deleteSchoolState: 0,
    deleteSchoolError: '',
};

const schoolsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SCHOOLS_CREATE_SCHOOL_REQUEST: 
            return {
                ...state,
                createSchoolState: 0,
                createSchoolError: '',
            };
        case actionTypes.SCHOOLS_CREATE_SCHOOL_SUCCESS: 
            return {
                ...state,
                createSchoolState: 1,
            };
        case actionTypes.SCHOOLS_CREATE_SCHOOL_FAILURE: 
            return {
                ...state,
                createSchoolState: 2,
                createSchoolError: action.error.error,
            };
        case actionTypes.SCHOOLS_UPDATE_SCHOOL_REQUEST: 
            return {
                ...state,
                updateSchoolState: 0,
                updateSchoolError: '',
            };
        case actionTypes.SCHOOLS_UPDATE_SCHOOL_SUCCESS: 
            return {
                ...state,
                updateSchoolState: 1,
            };
        case actionTypes.SCHOOLS_UPDATE_SCHOOL_FAILURE: 
            return {
                ...state,
                updateSchoolState: 2,
                updateSchoolError: action.error.error,
            };
        case actionTypes.SCHOOLS_DELETE_SCHOOL_REQUEST: 
            return {
                ...state,
                deleteSchoolState: 0,
                deleteSchoolError: '',
            };
        case actionTypes.SCHOOLS_DELETE_SCHOOL_SUCCESS: 
            return {
                ...state,
                deleteSchoolState: 1,
            };
        case actionTypes.SCHOOLS_DELETE_SCHOOL_FAILURE: 
            return {
                ...state,
                deleteSchoolState: 2,
                deleteSchoolError: action.error,
            };
        case actionTypes.SCHOOLS_REQUEST:
            return {
                ...state,
                schoolsError: '',
                schoolsLoading: true,
            };
        case actionTypes.SCHOOLS_SUCCESS:
            return {
                ...state,
                schools: action.schools ? action.schools.data : [],
                total: action.schools ? action.schools.total : 1,
                total_pages: action.schools ? action.schools.total_pages : 0,
                page_limit: action.schools ? action.schools.page_limit : 9999999,
                schoolsError: '',
                schoolsLoading: false,
            };
        case actionTypes.SCHOOLS_FAILURE:
            return {
                ...state,
                schoolsError: action.error,
                schools: [],
                total: 0,
                total_pages: 1,
                page_limit: 9999999,
                schoolsLoading: false,
            };
        case actionTypes.SCHOOLS_FOR_USER_REQUEST:
            return {
                ...state,
                schoolsError: '',
                schoolsLoading: true,
            };
        case actionTypes.SCHOOLS_FOR_USER_SUCCESS:
            return {
                ...state,
                schoolsForUser: action.schools ? action.schools.data : [],
                total: action.schools ? action.schools.total : 1,
                total_pages: action.schools ? action.schools.total_pages : 0,
                page_limit: action.schools ? action.schools.page_limit : 9999999,
                schoolsError: '',
                schoolsLoading: false,
            };
        case actionTypes.SCHOOLS_FOR_USER_FAILURE:
            return {
                ...state,
                schoolsError: action.error,
                schoolsForUser: [],
                total: 0,
                total_pages: 1,
                page_limit: 9999999,
                schoolsLoading: false,
            };
        case actionTypes.SCHOOLS_CONTACTPERSON_REQUEST:
            return {
                ...state,
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_CONTACTPERSON_SUCCESS:
            return {
                ...state,
                schoolsContactPerson: action.schools ? action.schools.data : [],
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_CONTACTPERSON_FAILURE:
            return {
                ...state,
                schoolsError: action.error,
                schoolsContactPerson: [],
            };
        case actionTypes.SCHOOLS_TEACHER_REQUEST:
            return {
                ...state,
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_TEACHER_SUCCESS:
            return {
                ...state,
                schoolsTeacher: action.schools ? action.schools.data : [],
                total: action.schools ? action.schools.total : 1,
                total_pages: action.schools ? action.schools.total_pages : 0,
                page_limit: action.schools ? action.schools.page_limit : 9999999,
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_TEACHER_FAILURE:
            return {
                ...state,
                schoolsError: action.error,
                schoolsTeacher: [],
                total: 0,
                total_pages: 1,
                page_limit: 9999999,
            };
        case actionTypes.SCHOOLS_STUDENTS_REQUEST:
            return {
                ...state,
                loading: true,
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_STUDENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                schoolsStudents: action.schools ? action.schools.data : [],
                total: action.schools ? action.schools.total : 1,
                total_pages: action.schools ? action.schools.total_pages : 0,
                page_limit: action.schools ? action.schools.page_limit : 9999999,
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_STUDENTS_FAILURE:
            return {
                ...state,
                loading: false,
                schoolsError: action.error,
                schoolsStudents: [],
                total: 0,
                total_pages: 1,
                page_limit: 9999999,
            };
        case actionTypes.SCHOOLS_YEARS_REQUEST:
            return {
                ...state,
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_YEARS_SUCCESS:
            return {
                ...state,
                schoolyears: action.schoolyears ? action.schoolyears : [],
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_YEARS_FAILURE:
            return {
                ...state,
                schoolsError: action.error,
                schooltypes: [],
            };
        case actionTypes.SCHOOLS_TYPES_REQUEST:
            return {
                ...state,
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_TYPES_SUCCESS:
            return {
                ...state,
                schooltypes: action.schooltypes ? action.schooltypes : [],
                schoolsError: '',
            };
        case actionTypes.SCHOOLS_TYPES_FAILURE:
            return {
                ...state,
                schoolsError: action.error,
                schoolyears: [],
            };
        case actionTypes.SCHOOLS_SETTINGS_REQUEST:
            return {
                ...state,
                settingsLoading: true,
                settingsLoadingError: '',
            };
        case actionTypes.SCHOOLS_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.settings ? action.settings : [],
                settingsLoading: false,
            };
        case actionTypes.SCHOOLS_SETTINGS_FAILURE:
            return {
                ...state,
                settings: [],
                settingsLoading: false,
                settingsLoadingError: action.error,
            };
        case actionTypes.SCHOOLS_SETTINGS_SAVE_SETTING_REQUEST:
            return {
                ...state,
                settingSaveState: 0,
                settingsLoading: true,
                settingsLoadingError: '',
            };
        case actionTypes.SCHOOLS_SETTINGS_SAVE_SETTING_SUCCESS:
            return {
                ...state,
                settingSaveState: 1,
                settingsLoading: false,
            };
        case actionTypes.SCHOOLS_SETTINGS_SAVE_SETTING_FAILURE: 
            return {
                ...state,
                settingSaveState: 2,
                settingsLoading: false,
                settingsLoadingError: action.error,
            };
        default:
            return state;
    }
};

export default schoolsReducer;
