import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';

export const generateSelfAssessmentPDF = (props, state) => {

    let documentname = props.intl.formatMessage({ id: "selfassessment.print.filename" }, state.translationsVars)
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    //Tableheader
    let rows = [[
        { text: props.intl.formatMessage({ id: "selfassessment.label.competence" }), fontSize: 12, color: 'white', fillColor: [67, 53, 140], border: [1, 1, 1, 1] },
        {
            stack: [
                { image: 'smiley1', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-1" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley4', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-2" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley3', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-3" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley2', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-4" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
    ]];

    //Tablebody
    props.competences.forEach((trData, trIndex) => {
        let tmpA = [];
        tmpA.push([{ text: props.intl.formatMessage({ id: trData.description }) + '\n\n' + props.intl.formatMessage({ id: trData.name }).toUpperCase(), fontSize: 10 }]);
        tmpA.push(props.rating.filter((el) => (el.competences_id === trData.id && el.rating === 1)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.rating.filter((el) => (el.competences_id === trData.id && el.rating === 2)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.rating.filter((el) => (el.competences_id === trData.id && el.rating === 3)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.rating.filter((el) => (el.competences_id === trData.id && el.rating === 4)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        rows.push(tmpA);
    });

    let docDefinition = {
        //595pt x 842pt
        //pageOrientation: 'landscape',
        pageSize: 'A4',
        pageMargins: [40, 115, 40, 65],
        images: Images,
        header: Header,
        footer: footer(props),
        content: [
            {
                //Title
                text: props.intl.formatMessage({ id: "selfassessment.print.title" }),
                absolutePosition: { x: 40, y: 65 },
                fontSize: 20,
            },
            {
                //Subtitle
                text: props.intl.formatMessage({ id: "selfassessment.print.subtitle" }),
                absolutePosition: { x: 40, y: 90 },
                fontSize: 10,
            },
            {
                //Student
                text: props.auth.firstname + " " + props.auth.lastname,
                fontSize: 10,
                margin: [0, 0, 0, 10]
            },
            {
                table: {
                    dontBreakRows: true,
                    headerRows: 1,
                    widths: ["*", 40, 40, 40, 40],
                    body: rows
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i) {
                        return 0.5;
                    },
                }
            }
        ],
    };
    pdfMake.createPdf(docDefinition).download(documentname);
}