import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CheckAuthorization } from '../models/Authorization';

class Authorization extends Component {

    static propTypes = {
        // redux state
        id: PropTypes.string,
        roles: PropTypes.array,
        procedure: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = { child: null };
    }

    componentDidMount() {
        if (CheckAuthorization(this.props.id, this.props.roles, this.props.procedure)) {
            this.setState({ child: this.props.children });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.roles !== this.props.roles || prevProps.id !== this.props.id || prevProps.procedure !== this.props.procedure || prevProps.children !== this.props.children) {
            if (CheckAuthorization(this.props.id, this.props.roles, this.props.procedure)) {
                this.setState({ child: this.props.children });
            };
        }
    }


    render() {
        return this.state.child;
    }

}

const mapStateToProps = (state) => {
    return {
        roles: state.auth.roles,
        procedure: state.auth.procedure
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Authorization);
