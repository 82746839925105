import * as React from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, Container } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Logout from './Logout';
import Authorization from './Authorization';
import { inquiryActions } from '../actions';
import { CheckAuthorization } from '../models/Authorization';

class Menu extends React.Component {
    static propTypes = {
        toggle: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dropdownInquiryOpen: false,
        };
    }

    componentDidMount() {
        if (CheckAuthorization('inquiry-count-open', this.props.auth.roles)) {
            this.props.dispatchGetCountOfOpenInquirys(this.props.auth.token);
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    dropDownToggle = (dropdown, e) => {
        switch (dropdown) {
            case 'inquiry':
                this.setState({ dropdownInquiryOpen: !this.state.dropdownInquiryOpen });
                break;
            default:
                break;
        }
    }

    isActivePage(page) {
        let hashIndex = window.location.href.indexOf('#');
        let currPage = window.location.href.substr(hashIndex + 2);
        return currPage === page;
    }

    render() {
        return (
            <Container fluid className="m-0 px-0 py-0 menu-container">
                <Navbar light expand="sm" className="m-0 px-0 py-0">
                    <NavbarToggler onClick={this.toggle.bind(this)} />
                    <Collapse isOpen={this.state.isOpen} navbar >
                        <Nav vertical className="m-0 px-0 py-0 d-flex flex-column justify-content-start " navbar role="navigation">

                            {/* <NavbarBrand className="px-2 m-0 text-uppercase pt-1 pb-1 menu-navbarbrand " onClick={this.props.toggle}><FormattedMessage id="menu.title.menu" /></NavbarBrand>   */}
                            <NavItem className="d-none m-0 mx-2 border-bottom text-white d-sm-flex strong align-items-center py-2 p-0" onClick={this.props.toggle} style={{ cursor: 'pointer' }}>
                                <div className="menu-toggler"></div>
                                <div className="px-2 fw-bold">
                                    <FormattedMessage id="menu.title.menu" />
                                </div>
                            </NavItem>
                            <NavItem active={this.isActivePage('home')} className="d-block border-top border-bottom d-sm-none m-0 mx-2">
                                <Link to="home" name="home"><FormattedMessage id="menu.button.home" tabIndex="1" /></Link>
                            </NavItem>
                            <NavItem active={this.isActivePage('settings')} className="d-block border-bottom d-sm-none mx-2">
                                <Link to="settings" name="settings"><FormattedMessage id="menu.button.settings" tabIndex="2" /></Link>
                            </NavItem>
                            <NavItem active={this.isActivePage('help')} className="d-block border-bottom d-sm-none mx-2">
                                <Link to="help" name="help"><FormattedMessage id="menu.button.help" tabIndex="3" /></Link>
                            </NavItem>
                            <NavItem active={this.isActivePage('impressum')} className="d-block border-bottom d-sm-none mx-2">
                                <Link to="impressum" name="impressum"><FormattedMessage id="menu.button.impressum" tabIndex="4" /></Link>
                            </NavItem>
                            <NavItem active={this.isActivePage('sponsor')} className="d-block border-bottom d-sm-none mx-2">
                                <Link to="sponsor" name="sponsor"><FormattedMessage id="menu.button.sponsor" tabIndex="5" /></Link>
                            </NavItem>
                            <NavItem className="d-block border-bottom d-sm-none mx-2">
                                <Logout tabIndex="6" />
                            </NavItem>

                            <Authorization id="menu-self-assessment">
                                <NavItem active={this.isActivePage('selfassessment')} className="border-bottom mx-2">
                                    <Link to="selfassessment" name="selfassessment"><FormattedMessage id="menu.button.self-assessment" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-self-assessment-kompog">
                                <NavItem active={this.isActivePage('selfassessmentkompog')} className="border-bottom mx-2">
                                    <Link to="selfassessmentkompog" name="selfassessmentkompog"><FormattedMessage id="menu.button.self-assessment-kompog" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-verbexercise">
                                <NavItem active={this.isActivePage('verbexercise')} className="border-bottom mx-2">
                                    <Link to="verbexercise" name="verbexercise"><FormattedMessage id="verb-exercise.label.verb-exercise" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-motivationExercise">
                                <NavItem active={this.isActivePage('motivationExercise')} className="border-bottom mx-2">
                                    <Link to="motivationExercise" name="motivationExercise"><FormattedMessage id="menu.button.motivationExercise" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-myresults-page">
                                <NavItem active={this.isActivePage('myresultspage')} className="border-bottom mx-2">
                                    <Link to="myresultspage" name="myresultspage"><FormattedMessage id="menu.button.myresultsPage" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-interest-test">
                                <NavItem active={this.isActivePage('interesttest')} className="border-bottom mx-2">
                                    <Link to="interesttest" name="interesttest"><FormattedMessage id="menu.button.interest-test" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-skills-profile-student">
                                <NavItem active={this.isActivePage('skillsprofilestudent')} className="border-bottom mx-2">
                                    <Link to="skillsprofilestudent" name="skillsprofilestudent"><FormattedMessage id="menu.button.skills-profile-student" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-kompo7-rating">
                                <NavItem active={this.isActivePage('kompo7rating')} className="mx-2">
                                    <Link to="kompo7rating" name="kompo7rating"><FormattedMessage id="menu.button.kompo7-rating" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-school-administration">
                                <NavItem active={this.isActivePage('schooladministration')} className="border-bottom mx-2">
                                    <Link to="/schooladministration" name="schooladministration" ><FormattedMessage id="menu.button.schooladministration" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-school">
                                <NavItem active={this.isActivePage('school')} className="border-bottom mx-2">
                                    <Link to="/school" name="school" ><FormattedMessage id="menu.button.school" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-student-administration">
                                <NavItem active={this.isActivePage('studentadministration')} className="border-bottom mx-2">
                                    <Link to="studentadministration" name="studentadministration"><FormattedMessage id="menu.button.student-administration" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-teacher-administration">
                                <NavItem active={this.isActivePage('teacheradministration')} className="border-bottom mx-2">
                                    <Link to="/teacheradministration" name="teacheradministration"><FormattedMessage id="menu.button.teacher-administration" /></Link>
                                </NavItem>
                            </Authorization>

                            <Authorization id="menu-user-administration">
                                <NavItem active={this.isActivePage('useradministration')} className="border-bottom mx-2">
                                    <Link to="useradministration" name="useradministration"><FormattedMessage id="menu.button.user-administration" /></Link>
                                </NavItem>
                            </Authorization>

                            <Authorization id="menu-results-school">
                                <NavItem active={this.isActivePage('resultsschool')} className="border-bottom mx-2">
                                    <Link to="resultsschool" name="resultsschool"><FormattedMessage id="menu.button.results-school" /></Link>
                                </NavItem>
                            </Authorization>

                            <Authorization id="menu-executions">
                                <NavItem active={this.isActivePage('executions')} className="border-bottom mx-2">
                                    <Link to="executions" name="executions"><FormattedMessage id="menu.button.executions" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-self-assessment-overview">
                                <NavItem active={this.isActivePage('selfassessmentoverview')} className="border-bottom mx-2">
                                    <Link to="selfassessmentoverview" name="selfassessmentoverview"><FormattedMessage id="menu.button.self-assessment-overview" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-interest-test-overview">
                                <NavItem active={this.isActivePage('interesttestoverview')} className="border-bottom mx-2">
                                    <Link to="interesttestoverview" name="interesttestoverview"><FormattedMessage id="menu.button.interest-test-overview" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-observations-evaluations">
                                <NavItem active={this.isActivePage('observationsevaluations')} className="border-bottom mx-2">
                                    <Link to="observationsevaluations" name="observationsevaluations"><FormattedMessage id="menu.button.observations-evaluations" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-feedback">
                                <NavItem active={this.isActivePage('feedback')} className="border-bottom mx-2">
                                    <Link to="feedback" name="feedback"><FormattedMessage id="menu.button.feedback" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-skills-profile">
                                <NavItem active={this.isActivePage('skillsprofile')} className="border-bottom mx-2">
                                    <Link to="skillsprofile" name="skillsprofile"><FormattedMessage id="menu.button.skills-profile" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-results">
                                <NavItem active={this.isActivePage('results')} className="border-bottom mx-2">
                                    <Link to="results" name="results"><FormattedMessage id="menu.button.results" /></Link>
                                </NavItem>
                            </Authorization>

                            <Authorization id="menu-inquiry">
                                <NavItem active={this.isActivePage('inquiry')} className={this.state.dropdownInquiryOpen ? "menu-inquiry mx-2" : "menu-inquiry mx-2 border-bottom"}>
                                    <a aria-haspopup aria-expanded={this.state.dropdownInquiryOpen} className="btn-link text-white" onClick={this.dropDownToggle.bind(this, "inquiry")} name="openmenuinquiry" id="openmenuinquiry">
                                        <Container fluid className="m-0 p-0">
                                            <span>
                                                <span className={this.state.dropdownInquiryOpen ? "menu-triangle-open" : "menu-triangle"}></span>
                                                <FormattedMessage id="menu.button.inquiry" />
                                            </span>
                                            {!this.state.dropdownInquiryOpen && this.props.openInquiry > 0 && <div className="menu-open-inquiry align-self-end">{this.props.openInquiry}</div>}
                                        </Container>
                                    </a>
                                </NavItem>
                                <Collapse isOpen={this.state.dropdownInquiryOpen} >
                                    <NavItem active={this.isActivePage('inquiryreceived')} className="mx-2 px-2 ">
                                        <Link to="inquiryreceived" name="inquiryreceived">
                                            <FormattedMessage id="menu.button.inquiry-received" />
                                            {this.props.openInquiry > 0 && <div className="menu-open-inquiry align-self-end">{this.props.openInquiry}</div>}
                                        </Link>
                                    </NavItem>
                                    <NavItem active={this.isActivePage('inquirysend')} className={this.state.dropdownInquiryOpen ? "mx-2 px-2 border-bottom" : "mx-2 px-2"} >
                                        <Link to="inquirysend" name="inquirysend"><FormattedMessage id="menu.button.inquiry-send" /></Link>
                                    </NavItem>
                                </Collapse>
                                {/* <NavItem active={this.isActivePage('inquiry')} className="border-bottom mx-2">
                                    <Link to="inquiry" name="inquiry"><FormattedMessage id="menu.button.inquiry" /></Link>
                                </NavItem> */}
                            </Authorization>

                            <Authorization id="menu-kompo7-rating-results">
                                <NavItem active={this.isActivePage('kompo7ratingresults')} className="mx-2 border-bottom">
                                    <Link to="kompo7ratingresults" name="kompo7ratingresults"><FormattedMessage id="menu.button.kompo7-rating-results" /></Link>
                                </NavItem>
                            </Authorization>

                            <Authorization id="menu-statistic">
                                <NavItem active={this.isActivePage('statistic')} className="mx-2 border-bottom">
                                    <Link to="statistic" name="statistic"><FormattedMessage id="menu.button.statistic" /></Link>
                                </NavItem>
                            </Authorization>
                            <Authorization id="menu-documents">
                                <NavItem active={this.isActivePage('documents')} className="d-block mx-2">
                                    <Link to="documents" name="documents"><FormattedMessage id="menu.button.documents" /></Link>
                                </NavItem>
                            </Authorization>

                        </Nav>

                    </Collapse>
                </Navbar>
            </Container>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        openInquiry: state.inquiry.openInquiry
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        dispatchIntlUpdate: (translation) => {
            dispatch(updateIntl(translation));
        },
        dispatchGetCountOfOpenInquirys: (token) => {
            dispatch(inquiryActions.getCountOfOpenInquirys(token));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu));

