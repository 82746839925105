import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Table } from 'reactstrap';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import Loading from '../components/Loading';
import { userProfileService } from '../services';

class ImportStudentsModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        toggle: PropTypes.func,
        importFileData: PropTypes.object,
        procedure: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            th: [],
            tb: [],
            tbShow: [],
            thDetails: [{ 'text': 'importstudentsmodal.details.line' }, { 'text': 'importstudentsmodal.details.description' }],
            tbDetails: [],
            tbDetailsShow: ['line', 'description'],
            importFileData: null,
            importState: 'preview', // preview, running, finished, failure
            importResult: null,
            highlightLines: []
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.importFileData && prevProps.importFileData !== this.props.importFileData) {
            // map the detected properties to the format required by the table head
            let th = this.props.importFileData.detected_properties.map(el => {
                return { 'text': 'importstudentsmodal.th.' + el };
            });


            //highlight lines with errors
            let highlightLines = this.props.importFileData.errors.map(el => {
                if (el.line) {
                    return el.line - 2;
                }
                return false;
            });

            let details = this.getDetails(this.props.importFileData.errors);

            this.setState({
                th: th,
                tb: this.props.importFileData.preview_records,
                tbShow: this.props.importFileData.detected_properties,
                importFileData: this.props.importFileData,
                importState: 'preview',
                importResult: null,
                highlightLines: highlightLines,
                tbDetails: details
            });
        }
    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            this.props.toggle();
        } else if (type === 'import') {
            this.import(this.state.importFileData.import_file);
        }
    }

    import = async (importFile) => {
        if (this.props.auth) {
            this.setState({ importState: 'running', importError: '' });
            let importRes = await userProfileService.importStudents(this.props.auth.token, importFile, this.props.procedure.id).catch(err => {
                this.setState({ importState: 'failure', importError: err });
            });
            if (importRes) {
                let details = this.getDetails(importRes.errors);
                this.setState({ importState: 'finished', importResult: importRes, tbDetails: details });
            }
        }
    }

    getDetails(errors) {
        if (!errors || errors.length === 0) return [];

        let detailList = errors.map((el) => {
            if (el.type === 'WRONG_TYPE') {
                return {
                    'line': el.line,
                    'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.wrongdatatype' }) + this.props.intl.formatMessage({ id: 'importstudentsmodal.details.wrongdatatype.' + el.required_type })
                };
            } else if (el.type === 'PROPS_MISSING') {
                let props = el.properties.map((el) => { return this.props.intl.formatMessage({ id: 'importstudentsmodal.details.propsmissing.' + el }) });
                return {
                    'line': el.line,
                    'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.propsmissing' }) + props.join(', ')
                };
            } else if (el.type === 'WRONG_PROP') {
                return {
                    'line': el.line,
                    'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.wrongprop.' + el.property })
                };
            } else if (el.type === 'UNAUTHORIZED') {
                return {
                    'line': el.line,
                    'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.unauthorized' })
                };
            } else if (el.type === 'NO_ENTRY') {
                return {
                    'line': el.line,
                    'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.noentry1' })
                        + this.props.intl.formatMessage({ id: 'importstudentsmodal.details.' + el.param1 })
                        + el.param2.toString()
                        + this.props.intl.formatMessage({ id: 'importstudentsmodal.details.noentry2' })
                };
            } else if (el.type === 'DUPLICATE_ENTRY') {
                return null;

                // let duplicate = this.props.intl.formatMessage({ id: 'importstudentsmodal.th.' + el.duplicate });

                // return {
                //     'line': el.line,
                //     'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.duplicateentry1' })
                //         + duplicate
                //         + this.props.intl.formatMessage({ id: 'importstudentsmodal.details.duplicateentry2' })
                // };
            } else if (el.type === 'DISPATCH_EMAIL') {
                return {
                    'line': el.email,
                    'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.emaildispatcherror' })
                };
            } else {
                return { 'line': el.line, 'description': this.props.intl.formatMessage({ id: 'importstudentsmodal.details.internalerror' }) };
            }
        });

        // filter duplicate entries
        detailList = detailList.filter((el) => { return el !== null });

        return detailList;
    }

    getIgnoredCount() {
        return this.state.importResult.errors.filter((el) => { return el.type === 'DUPLICATE_ENTRY' }).length;
    }

    getErrorCount() {
        return this.state.importResult.errors.filter((el) => { return el.type !== 'DUPLICATE_ENTRY' }).length;
    }

    render() {
        return (
            <Container>
                <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} className="modal-height-100 modal-width-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                    <ModalHeader ><FormattedMessage id="importstudentsmodal.title" /></ModalHeader>

                    <ModalBody>
                        {
                            this.state.importState === 'preview' &&
                            <Container className="m-0 p-0">
                                {
                                    this.state.importFileData &&
                                    <Container className="m-0 p-0">
                                        <FormattedMessage id="importstudentsmodal.filename" />
                                        {
                                            this.state.importFileData.import_file_orig
                                        }
                                    </Container>
                                }

                                <Container fluid className="table-scroll m-0 p-0 mt-2 border container-scroll-y" style={{ 'height': '30vh' }}>
                                    <Table striped bordered className="m-0 p-0">
                                        <TableHead data={this.state.th} order={this.order} />
                                        <TableBody loading={false} data={this.state.tb} show={this.state.tbShow} highlightLines={this.state.highlightLines} />
                                    </Table>
                                </Container>

                                {
                                    this.state.importFileData && this.state.importFileData.errors.length > 0 &&
                                    <Container className="m-0 p-0 mt-3">
                                        <Row>
                                            <Col className="fw-bold">
                                                <FormattedMessage id="importstudentsmodal.details.title" />
                                            </Col>
                                        </Row>
                                        <Container fluid className="table-scroll m-0 p-0 mt-2 border container-scroll-y" style={{ height: '10rem' }}>
                                            <Table striped bordered className="m-0 p-0">
                                                <TableHead data={this.state.thDetails} order={this.order} />
                                                <TableBody loading={false} data={this.state.tbDetails} show={this.state.tbDetailsShow} />
                                            </Table>
                                        </Container>
                                        <Row>
                                            <Col className="fw-bold mt-2" style={{ color: 'red' }}>
                                                <FormattedMessage id="importstudentsmodal.warning.title" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="importstudentsmodal.warning.body" />
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                            </Container>

                        }

                        {
                            this.state.importState === 'running' &&
                            <Container className="import-loader">
                                <Loading />
                            </Container>
                        }

                        {
                            this.state.importState === 'finished' &&
                            <Container className="mt-2 m-0 p-0">
                                <Row>
                                    <Col className="fw-bold">
                                        <FormattedMessage id="importstudentsmodal.importcomplete" />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <FormattedMessage id="importstudentsmodal.details.importcount" values={{ count: this.state.importResult.imported_records }} />
                                    </Col>
                                </Row>
                                {
                                    this.state.importResult.errors.length > 0 &&
                                    <Container className="m-0 p-0 mt-3">
                                        <Row>
                                            <Col className="fw-bold">
                                                <FormattedMessage id="importstudentsmodal.details.title" />
                                            </Col>
                                        </Row>
                                        <Container fluid className="table-scroll m-0 p-0 mt-2 border container-scroll-y" style={{ height: '10rem' }}>
                                            <Table striped bordered className="m-0 p-0">
                                                <TableHead data={this.state.thDetails} order={this.order} />
                                                <TableBody loading={false} data={this.state.tbDetails} show={this.state.tbDetailsShow} />
                                            </Table>
                                        </Container>
                                        <Row>
                                            <Col className="fw-bold mt-2" style={{ color: 'red' }}>
                                                <FormattedMessage id="importstudentsmodal.warning.title" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="importstudentsmodal.warning.body" />
                                            </Col>
                                        </Row>
                                    </Container>
                                }
                            </Container>
                        }
                    </ModalBody>

                    {
                        this.state.importState === 'preview' &&
                        <ModalFooter>
                            <Button type="submit" color="primary" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.cancel" /></Button>
                            <Button type="submit" color="primary" disabled={this.state.importFileData && this.state.importFileData.errors.length > 0} onClick={this.handleSubmit.bind(this, "import")}><FormattedMessage id="button.import" /></Button>
                        </ModalFooter>
                    }

                    {
                        (this.state.importState === 'finished') &&
                        <ModalFooter>
                            <Button type="submit" color="primary" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.close" /></Button>
                        </ModalFooter>
                    }
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportStudentsModal));