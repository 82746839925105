import ENV from '../config/env';

export const loginService = {
    login,
    logout,
    sendResetPasswordMail,
    setPrivacypolicy
};

function login(login, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login: login, password: password })
    };

    return fetch(ENV.apiUrl + '/token', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.status);
            }

            return response.json();
        })
        .then(response => {
            // login successful if there's a jwt token in the response
            if (response && response.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('user', JSON.stringify(response));
            }

            return response;
        });
}

function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('user');
}

function sendResetPasswordMail(login) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login: login })
    };

    return fetch(ENV.apiUrl + '/reset_pwd', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;
        });
}

/**
 * SET PRIVACYPOLICY
 */
async function setPrivacypolicy(token) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/users/privacypolicy/', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return true;
        });
}
