import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userProfileService } from '../services';

class UsersTitleSelect extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        userTitleId: PropTypes.number,
        handleUsersTitleId: PropTypes.func,
        order: PropTypes.string,
        desc: PropTypes.bool,
        dataEnabled: PropTypes.bool,
        withEmpty: PropTypes.bool,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        filter: PropTypes.array,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        order: "name",
        desc: false,
        dataEnabled: null,
        withEmpty: false,
        invalid: false,
        filter: [],
        disabled: false,
        label: "userstitle.label.default"
    };

    constructor(props) {
        super(props);
        this.state = {
            userstitle: [],
        }
    }

    async componentDidMount() {
        await this.getUsersTitle();
    }


    async getUsersTitle() {
        let data = await userProfileService.getUsersTitle(this.props.auth.token);

        if (data && data.data) {
            await this.setState({ userstitle: data.data.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) })
            if (!this.props.withEmpty && this.props.usersTitleId < 0) {
                this.props.handleUsersTitleId(data.data[0].id);
            }
        } else {
            this.props.handleUsersTitleId(-1);
            this.setState({ userstitle: [] })
        }
    }

    createUsersTitleItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "userstitle.option.pleaseselect" })}</option>);
        this.state.userstitle.forEach((usertitle) => {
            items.push(<option key={usertitle.id} value={usertitle.id} >{usertitle.name && this.props.intl.formatMessage({ id: usertitle.name })}</option>);
        })
        return items;
    }

    handleUsersTitle = async (e) => {
        this.props.handleUsersTitleId(parseInt(e.target.value, 10));
    }

    render() {
        return (
            <Container fluid className={this.state.userstitle.length > 1 ? this.props.className : "d-none " + this.props.className}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "userstitle.label.default" ? "fw-bold" : "d-none"} for="userstitle"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled} invalid={this.props.invalid} bsSize="sm" type="select" name="userstitle" id="userstitle" value={this.props.usersTitleId} onChange={this.handleUsersTitle}>
                        {this.createUsersTitleItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersTitleSelect));