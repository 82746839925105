import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'reactstrap';

class HelpPage extends Component {

    render() {
        return (
            <Container fluid role="main" className="content-margin">
                <h1>Datenschutz</h1>
                <p>Willkommen in der <i>KomPo</i>-App!<br /><br />

                    Die Kompetenzfeststellung <i>KomPo</i> wird mit Hilfe einer webbasierten Software durchgeführt, die personenbezogene Daten verarbeitet. Im Vorfeld der Nutzung der <i>KomPo</i>-App wurden Sie darüber informiert, welche Daten dies genau sind. Es wird sichergestellt, dass nur Sie sowie die unmittelbar an der Kompetenzfeststellung Beteiligten Zugriff auf die erhobenen Daten haben. Die Verarbeitung und Weitergabe der Daten erfolgt verschlüsselt. Gemeinsam verantwortlich für die Datenverarbeitung sind das Hessische Ministerium für Kultus, Bildung und Chancen und die jeweilige Schule. Den behördlichen Datenschutzbeauftragten des Hessischen Ministeriums für Kultus, Bildung und Chancen erreichen Sie über:
                    <br /><br />
                    Hessisches Ministerium für Kultus, Bildung und Chancen<br />
                    Datenschutzbeauftragter<br />
                    Luisenplatz 10<br />
                    65185 Wiesbaden<br />
                    E-Mail: datenschutzbeauftragter.hkm@kultus.hessen.de<br /><br />

                    Sie haben jederzeit das Recht, Auskunft über Ihre gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung falscher Daten, die Einschränkung der Bearbeitung von Daten, deren Richtigkeit unklar ist, ggf. die Datenübertragbarkeit oder die Löschung dieser Daten zu verlangen.<br />
                    Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Dies ist der Hessische Beauftragte für Datenschutz und Informationsfreiheit, Gustav-Stresemann-Ring 1, 65189 Wiesbaden, Tel.: 0611/1408-0, <a href="https://datenschutz.hessen.de" target="blank">https://datenschutz.hessen.de</a>

                </p>

            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(HelpPage));