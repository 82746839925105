import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { Container, Row, Col, Collapse, Label, Button, Input, Alert } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import ExecutionsPastCheckbox from '../components/ExecutionsPastCheckbox';
import SchoolSelect from '../components/SchoolSelect';
import StudentsButtons from '../components/StudentsButtons';
import { schoolsActions, filterActions, skillsProfilesActions, competencesActions, executionsActions } from '../actions';
import Confirm from '../components/Confirm';
import Authorization from '../components/Authorization';
import config from '../config/config';
import translationsVars from '../translations/translationsVars';
import moment from 'moment';
import HonorarkraftSelect from '../components/HonorarkraftSelect';
import { CheckAuthorization } from '../models/Authorization';
import Loading from '../components/Loading';
import { sortProfessinalfields, replaceLineBreakWithBr, replaceQuotationMarks, getCaretPos, setCaretPos } from '../utils/Tools';

class SkillsProfilePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skillsProfile: { created: null, edited: null, competences: [], approved: 0, jobtext: '', jobvariant: 1 },
            defaultSkillsProfile: { created: null, edited: null, competences: [], approved: 0, jobtext: '', jobvariant: 1 },
            collapse: { about: true, competences: true, interesttest: true, recommendation: true },
            variantTextId: 'skillsprofile.body.variant.1.3',
            variantPrintId: 'skillsprofile.print.body.variant.1.3',
            alert: { visible: false, textId: "alert.save", color: "success" },
            confirm: false,
            confirmApproved: false,
            confirmLock: false,
            showAlert: true,
            translationsVars: translationsVars,
        }
    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        if (this.props.executionId >= 0 && this.props.studentId >= 0) {
            this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
            this.props.dispatchGetSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
            if (CheckAuthorization('skillsprofile-honorarkraftselect', this.props.auth.roles)) {
                this.props.dispatchGetExecutionsHonorarkraft(this.props.auth.token, this.props.executionId);
            }
        }
        this.setState({ variantTextId: 'skillsprofile.body.variant.1.' + this.props.studentAddress });
        this.setState({ variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.studentAddress });
        this.setState({ translationsVars: { ...this.state.translationsVars, name: this.props.studentFirstname } });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.executionId !== this.props.executionId || prevProps.studentId !== this.props.studentId || prevProps.honorarkraftId !== this.props.honorarkraftId) {
            this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
            this.props.dispatchGetSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);

            if (CheckAuthorization('skillsprofile-honorarkraftselect', this.props.auth.roles)) {
                this.props.dispatchGetExecutionsHonorarkraft(this.props.auth.token, this.props.executionId);
            }

            if (this.props.executionId > 0 && this.props.studentId > 0 && JSON.stringify(this.props.skillsProfile) !== JSON.stringify(this.state.skillsProfile) && JSON.stringify(this.state.skillsProfile) !== JSON.stringify(this.state.defaultSkillsProfile)) {
                this.setState({ showAlert: false })
                this.save();
            }
        }

        if (prevProps.skillsProfile !== this.props.skillsProfile) {
            if (!this.props.skillsProfile || this.props.skillsProfile.length === 0) {
                this.setState({ skillsProfile: JSON.parse(JSON.stringify(this.state.defaultSkillsProfile)) });
            } else {
                this.setState({ skillsProfile: JSON.parse(JSON.stringify(this.props.skillsProfile)) });
            }
            if (this.props.skillsProfile && this.props.skillsProfile.jobvariant) {
                this.setState({ variantTextId: 'skillsprofile.body.variant.' + this.props.skillsProfile.jobvariant + '.' + this.props.studentAddress });
                this.setState({ variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.skillsProfile.jobvariant + '.' + this.props.studentAddress });
            } else {
                this.setState({ variantTextId: 'skillsprofile.body.variant.1.' + this.props.studentAddress });
                this.setState({ variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.studentAddress });
            }
        }

        //Update variant text id for gender of student
        if (prevProps.studentAddress !== this.props.studentAddress) {
            if (this.props.skillsProfile && this.props.skillsProfile.jobvariant) {
                this.setState({ variantTextId: 'skillsprofile.body.variant.' + this.props.skillsProfile.jobvariant + '.' + this.props.studentAddress });
                this.setState({ variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.skillsProfile.jobvariant + '.' + this.props.studentAddress });
            } else {
                this.setState({ variantTextId: 'skillsprofile.body.variant.1.' + this.props.studentAddress });
                this.setState({ variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.studentAddress });
            }
        }

        if (prevProps.studentFirstname !== this.props.studentFirstname) {
            this.setState({ translationsVars: { ...this.state.translationsVars, name: this.props.studentFirstname } });
        }

        /* alert create and update */
        if (prevProps.createStatus !== this.props.createStatus || prevProps.updateStatus !== this.props.updateStatus || prevProps.approvedStatus !== this.props.approvedStatus) {
            if (this.props.createStatus === 1 || this.props.updateStatus === 1 || this.props.approvedStatus === 1) {
                if (this.state.showAlert) {
                    this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                    setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                } else {
                    this.setState({ showAlert: true })
                }
                this.props.dispatchGetSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
            } else if (this.props.createStatus === 2 || this.props.updateStatus === 2 || this.props.approvedStatus === 2) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

    }

    componentWillUnmount() {
        if (this.props.executionId && this.props.studentId && this.state.skillsProfile && this.state.skillsProfile.approved === 0 && this.props.executionId > 0 && this.props.studentId > 0) {
            this.save();
        }
    }

    handleCollapse = (c) => {
        switch (c) {
            case 'about':
                this.setState({ collapse: { about: !this.state.collapse.about, competences: this.state.collapse.competences, interesttest: this.state.collapse.interesttest, recommendation: this.state.collapse.recommendation } })
                break;
            case 'competences':
                this.setState({ collapse: { about: this.state.collapse.about, competences: !this.state.collapse.competences, interesttest: this.state.collapse.interesttest, recommendation: this.state.collapse.recommendation } })
                break;
            case 'interesttest':
                this.setState({ collapse: { about: this.state.collapse.about, competences: this.state.collapse.competences, interesttest: !this.state.collapse.interesttest, recommendation: this.state.collapse.recommendation } })
                break;
            case 'recommendation':
                this.setState({ collapse: { about: this.state.collapse.about, competences: this.state.collapse.competences, interesttest: this.state.collapse.interesttest, recommendation: !this.state.collapse.recommendation } })
                break;
            default:
                break;
        }

    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }
    toggleConfirmApproved = (e) => {
        this.setState({
            confirmApproved: !this.state.confirmApproved
        });
    }
    toggleConfirmLock = (e) => {
        this.setState({
            confirmLock: !this.state.confirmLock
        });
    }

    hideAlert() {
        this.setState({ alert: { visible: false, textId: "alert.save", color: "success" } })
    }

    handleTextInteresttest = async (e) => {
        //inquiry.text_receiver = e.target.value;
        // this.setState({ inquiry: inquiry });
    }

    handleTextRecommendation = async (e) => {
        //inquiry.text_receiver = e.target.value;
        let skillsProfile = this.state.skillsProfile;
        const cp = getCaretPos(e.target);
        const domEle = e.target;
        skillsProfile.jobtext = replaceQuotationMarks(e.target.value);
        this.setState({ skillsProfile: skillsProfile }, () => { setCaretPos(domEle, cp); });
    }


    handleVariant = (variant) => {
        let skillsProfile = this.state.skillsProfile;
        skillsProfile.jobvariant = variant;
        this.setState({ skillsProfile: skillsProfile, variantTextId: 'skillsprofile.body.variant.' + variant + '.' + this.props.studentAddress })
    }

    handleCompetence = (e) => {

        let index = this.state.skillsProfile.competences ? this.state.skillsProfile.competences.indexOf(parseInt(e.target.value, 10)) : -1;
        let skillsProfile = this.state.skillsProfile;

        if (index === -1) {
            skillsProfile.competences.push(parseInt(e.target.value, 10));
        } else {
            skillsProfile.competences.splice(index, 1);
        }
        this.setState({ skillsProfile: skillsProfile });
    }

    handleApproved = (e) => {
        this.toggleConfirmApproved();
    }

    handleLock = (e) => {
        this.toggleConfirmLock();
    }

    handleSave = (e) => {
        this.setState({ showAlert: true })
        this.toggleConfirm();
    }

    save = async () => {
        // check for create or update
        if (this.state.skillsProfile.id) {
            this.props.dispatchUpdateSkillsProfile(this.props.auth.token, this.props.honorarkraftId, this.state.skillsProfile);
        } else {
            this.props.dispatchCreateSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId, this.state.skillsProfile);
        }
    }

    approved = async () => {
        this.props.dispatchApprovedSkillsProfile(this.props.auth.token, this.props.honorarkraftId, this.state.skillsProfile, 1);
    }

    render() {

        let editedDatetime = null;
        let approvedDatetime = null;

        let interesttest = [];
        let lastPf = null;
        let countPf = 0;

        //get 3 professionalfields with rating over 33%
        this.props.skillsProfile && this.props.skillsProfile.interesttest && this.props.skillsProfile.interesttest.filter((item) => { return item.procent >= 33.3 }).forEach((pf) => {
            if (lastPf === null || countPf < 3 || lastPf.procent === pf.procent) {
                countPf++;
                lastPf = pf;
                interesttest.push(pf);
            }
        });
        interesttest.sort(sortProfessinalfields);


        if (this.props.skillsProfile && this.props.skillsProfile.edited) {
            editedDatetime = moment(this.props.skillsProfile.edited.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.skillsProfile.edited.replace(" ", "T"), config.dateFormat.format, true) : null;
        }
        if (this.props.skillsProfile && this.props.skillsProfile.approveddate) {
            approvedDatetime = moment(this.props.skillsProfile.approveddate.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.skillsProfile.approveddate.replace(" ", "T"), config.dateFormat.format, true) : null;
        }
        return (
            <Container fluid role="main" className="content-margin">

                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>
                <Confirm open={this.state.confirm} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.save} headertext="confirm.header.save" bodytext="confirm.body.save" />
                <Confirm open={this.state.confirmApproved} toggle={this.toggleConfirmApproved} no={this.toggleConfirm} yes={this.approved} headertext="confirm.header.approved" bodytext="confirm.body.approved" bodytextBoldRed={this.state.skillsProfile.competences.length > 0 ? "" : "confirm.body.approved_missing_compentences"} />
                {/* <Confirm open={this.state.confirmLock} toggle={this.toggleConfirmLock} no={this.toggleConfirmLock} yes={this.lock} headertext="confirm.header.lock" bodytext="confirm.body.lock" /> */}


                <h1><FormattedMessage id="skillsprofile.title.skillsprofile" /></h1>
                <Row>
                    <Col xs="12" className="my-3">
                        <ExecutionsPastCheckbox />
                    </Col>
                </Row>
                { /* Select School / Execution */}
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3" className={this.props.schools.length > 1 ? "" : "d-none"}>
                        <SchoolSelect className="mb-3 p-0" withEmpty label="skillsprofile.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3">
                        <ExecutionSelect className="mb-3 p-0" withEmpty label="skillsprofile.label.execution" executionId={this.props.executionId} schoolId={this.props.schoolId} onlyInvolved onlyKompo7={true} />
                    </Col>
                    <Authorization id="skillsprofile-honorarkraftselect">
                        <Col xs="12" sm="6" md="6" lg="6" xl="3">
                            <HonorarkraftSelect className={this.props.executionId > 0 && this.props.honorarkraft.length > 0 ? "mb-3 p-0" : "d-none"} withEmpty label="observationsevaluations.label.honorarkraft" honorarkraft={this.props.honorarkraft} handleHonorarkraft={this.props.dispatchHonorarkraft.bind(this)} honorarkraftId={this.props.honorarkraftId} />
                        </Col>
                    </Authorization>
                </Row>
                { /* Students */}
                <StudentsButtons className="mt-4" executionId={this.props.executionId} disabled={this.props.loading} />
                {this.props.loading ? <Loading /> :
                    <Row className={this.props.studentId > 0 ? "pt-2" : "d-none"}>
                        <Col xs="12" >
                            {/* edited */}
                            {editedDatetime &&
                                <Container fluid className={"small p-1 pt-2 m-0 justify-content-start d-flex"}>
                                    <FormattedMessage id="skillsprofile.label.edited" />&nbsp;<FormattedDate value={editedDatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={editedDatetime} hour={config.dateFormat.hour} minute={config.dateFormat.minute} />&nbsp;
                                    <FormattedMessage id="skillsprofile.label.editedby" />&nbsp;
                                    {this.state.skillsProfile && this.state.skillsProfile.editor ? this.state.skillsProfile.editor.firstname + ' ' + this.state.skillsProfile.editor.lastname : ''}
                                </Container>
                            }
                        </Col>
                        <Col xs="12">
                            {/* edited */}

                            <Container fluid className={"small p-1 pb-2 m-0 justify-content-start d-flex"}>
                                <FormattedMessage id="skillsprofile.label.approveddate" />
                                {approvedDatetime ?
                                    <span>
                                        &nbsp;<FormattedDate value={approvedDatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={approvedDatetime} hour={config.dateFormat.hour} minute={config.dateFormat.minute} />
                                    </span>
                                    : <span>&nbsp;-</span>}
                            </Container>

                        </Col>
                        {/* PDF
                    <Col xs="12" sm="6">
                         
                        <Container fluid className="p-0 mb-4">
                            <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this, this.props.intl.formatMessage({ id: "skillsprofile.print.filename" }))} />
                        </Container>
                    </Col>
                     */}
                    </Row>
                }
                {!this.props.loading &&
                    <Container fluid className={this.props.studentId !== -1 ? "m-0 p-0" : "d-none m-0 p-0"}>
                        {/* ABOUT */}
                        <Row className="px-3">
                            <Col xs="12" className="border-bottom p-0 py-3" >
                                <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.about ? "collapse.close" : "collapse.open"} /></Label>
                                <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'about')} >
                                    <span className={this.state.collapse.about ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                    <FormattedMessage id="skillsprofile.header.about" />
                                </Button>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.collapse.about} className="border-bottom">
                            <Row className="px-3">
                                <Col xs="12" className="text-justify py-3">
                                    <FormattedMessage id="skillsprofile.body.about" values={this.state.translationsVars} />
                                </Col>
                            </Row>
                        </Collapse>
                        <Row className="px-3">
                            <Col xs="12" className=" py-3 pe-0 text-black fw-bold" style={{ paddingLeft: '1.35rem' }} >
                                <FormattedMessage id="skillsprofile.header.results" />
                            </Col>
                        </Row>
                        {/* INTERESTTEST */}
                        <Row className="px-3">
                            <Col xs="12" className="mt-0 py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                                <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.interesttest ? "collapse.close" : "collapse.open"} /></Label>
                                <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0 fw-bold" onClick={this.handleCollapse.bind(this, 'interesttest')} >
                                    <span className={this.state.collapse.interesttest ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                    <FormattedMessage id="skillsprofile.subtitle.interesttest" />
                                </Button>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.collapse.interesttest}>
                            <Row className="px-3">
                                <Col xs="12" className="m-0" style={{ paddingLeft: '1.35rem' }}>
                                    <Row>
                                        <Col xs="12" className="text-justify pb-3 border-bottom" style={{ paddingLeft: '2.5rem' }}>
                                            {
                                                (this.props.skillsProfile === null || (this.props.skillsProfile && this.props.skillsProfile.interesttest === null)) &&
                                                <FormattedMessage id={"professionalfield.text.nointeresttestcompleted"} values={this.state.translationsVars} />
                                            }
                                            {
                                                this.props.skillsProfile && this.props.skillsProfile.interesttest !== null && interesttest.length === 0 &&
                                                <FormattedMessage id={"professionalfield.text.nointerest." + this.props.studentAddress} values={this.state.translationsVars} />
                                            }
                                            {
                                                this.props.skillsProfile && this.props.skillsProfile.interesttest && interesttest.length === 1 &&
                                                <FormattedMessage id={"professionalfield.text.start.single"} values={this.state.translationsVars} />
                                            }
                                            {
                                                this.props.skillsProfile && this.props.skillsProfile.interesttest && interesttest.length > 1 &&
                                                <FormattedMessage id={"professionalfield.text.start.multiple"} values={this.state.translationsVars} />
                                            }
                                            <i>
                                                {
                                                    interesttest.map((pf, index) => {
                                                        if (index === interesttest.length - 1 && index === 0) {
                                                            return <FormattedMessage id={"professionalfield." + pf.id + ".name"} key={"pf" + index} />
                                                        }
                                                        else if (index === interesttest.length - 1 && index >= 0) {
                                                            return <span key={"pf" + index}><FormattedMessage id={"professionalfield.text.and"} /><FormattedMessage id={"professionalfield." + pf.id + ".name"} /></span>
                                                        } else if (index === 0) {
                                                            return <FormattedMessage id={"professionalfield." + pf.id + ".name"} key={"pf" + index} />
                                                        } else {
                                                            return <span key={"pf" + index}>, <FormattedMessage id={"professionalfield." + pf.id + ".name"} /></span>
                                                        }

                                                    })
                                                }
                                            </i>
                                            {
                                                this.props.skillsProfile && this.props.skillsProfile.interesttest && this.props.skillsProfile.interesttest.length > 0 && this.props.skillsProfile.interesttest[0].procent >= 33.3 &&
                                                <FormattedMessage id={"professionalfield.text.end." + this.props.studentAddress} values={this.state.translationsVars} />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse>

                        {/* RESULTS */}
                        <Row className="px-3">
                            <Col xs="12" className="py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                                <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.competences ? "collapse.close" : "collapse.open"} /></Label>
                                <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'competences')} >
                                    <span className={this.state.collapse.competences ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                    <FormattedMessage id="skillsprofile.subtitle.competences" />
                                </Button>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.collapse.competences}>
                            {/* COMPETENCES */
                                /* filter competences with no rating */
                                this.props.competences && this.props.competences.filter((competence) => {
                                    let tmp = false;
                                    this.props.rating && this.props.rating.forEach((rating) => {
                                        if (competence.id === rating.competences_id && (rating.rating !== 0 || rating.rating2 !== 0)) {
                                            tmp = true;
                                        }
                                    })
                                    return tmp;
                                }).map((competence, index) => {
                                    //check checked
                                    let defaultChecked = this.props.rating.filter((rating) => {
                                        if (this.state.skillsProfile.competences && this.state.skillsProfile.competences.indexOf(rating.id) !== -1 && competence.id === rating.competences_id) {
                                            return true;
                                        }
                                        return false;
                                    }).length > 0;

                                    let rating = null;
                                    this.props.rating.forEach((r) => {
                                        if (competence.id === r.competences_id) {
                                            rating = r;
                                        }
                                        return null;
                                    });

                                    return (

                                        <Row className="px-3" key={"competence" + index} >
                                            <Col xs="12" className="m-0" style={{ paddingLeft: '1.35rem' }}>
                                                <Row >
                                                    <Col xs="12" className="pt-3">
                                                        <Label for={"competence" + index} className="ps-4 mb-0 fw-bold">
                                                            <Input id={"competence" + index} name={"competence" + index} className="mt-1" disabled={this.state.skillsProfile.approved} type="checkbox" checked={defaultChecked} value={rating ? rating.id : -1} onChange={this.handleCompetence.bind(this)} />
                                                            <FormattedMessage id={competence.name} />
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" className="border-bottom pb-3" style={{ paddingLeft: '2.5rem' }}>
                                                        {
                                                            rating && rating.observations && replaceLineBreakWithBr(rating.observations)
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }

                            {
                                /* no rating for competences */
                                this.props.rating && this.props.rating.filter((r) => { return r.rating !== 0 || r.rating2 !== 0 }).length === 0 &&
                                <Row className="px-3" >
                                    <Col xs="12" className="m-0" style={{ paddingLeft: '2.9rem' }}><FormattedMessage id="skillsprofile.placeholder.competences" /></Col>
                                </Row>

                            }

                        </Collapse>

                        {/* RECOMMENDATION */}
                        <Row className="px-3">
                            <Col xs="12" className="py-3 px-0 border-bottom" >
                                <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.recommendation ? "collapse.close" : "collapse.open"} /></Label>
                                <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'recommendation')} >
                                    <span className={this.state.collapse.recommendation ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                    <FormattedMessage id="skillsprofile.header.recommendation" />
                                </Button>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.collapse.recommendation}>
                            <Row className="px-3">
                                <Col xs="12" className="text-justify py-3" style={{ paddingLeft: '1.35rem' }} >
                                    <Label className="d-none" for="textrecommendation"><FormattedMessage id="skillsprofile.label.textrecommendation" /> </Label>
                                    <Input type="textarea" name="textrecommendation" id="textrecommendation" style={{ minHeight: "10rem" }} value={this.state.skillsProfile && this.state.skillsProfile.jobtext} onChange={this.handleTextRecommendation.bind(this)} disabled={this.state.skillsProfile.approved} placeholder={this.props.intl.formatMessage({ id: "skillsprofile.placeholder.textrecommendation" })} />
                                </Col>
                            </Row>
                            <Row className="px-3">
                                <Col xs="12" sm="6" md="6" lg="6" xl="4" style={{ paddingLeft: '1.35rem' }}>
                                    <input type="radio" className="me-2 p-0" checked={this.state.skillsProfile && this.state.skillsProfile.jobvariant === 1} name="variantA" id="variantA" onChange={this.handleVariant.bind(this, 1)} disabled={this.state.skillsProfile.approved} />
                                    <Label className="" for="variantA"><FormattedMessage id="skillsprofile.label.variant-a" values={this.state.translationsVars} /></Label>
                                </Col>
                                <Col xs="12" sm="6" md="6" lg="6" xl="4">
                                    <input type="radio" className="me-2 p-0" checked={this.state.skillsProfile && this.state.skillsProfile.jobvariant === 2} name="variantB" id="variantB" onChange={this.handleVariant.bind(this, 2)} disabled={this.state.skillsProfile.approved} />
                                    <Label className="" for="variantB"><FormattedMessage id="skillsprofile.label.variant-b" values={this.state.translationsVars} /></Label>
                                </Col>
                            </Row>
                            <Row className="px-3">
                                <Col xs="12" className="text-justify border-bottom py-3" style={{ paddingLeft: '1.35rem' }}>
                                    <FormattedMessage id={this.state.variantTextId} values={this.state.translationsVars} />
                                </Col>
                            </Row>
                        </Collapse>
                        <Container fluid className="p-0 my-4 d-flex justify-content-end flex-row">
                            {/* {this.state.skillsProfile.id && this.state.skillsProfile.approved === 1 && <Authorization id="skillsprofile-lock"><Button color="primary" className="me-4" onClick={this.handleLock}><FormattedMessage id="skillsprofile.button.lock" /></Button></Authorization>} */}
                            {this.state.skillsProfile.id && this.state.skillsProfile.approved === 0 && this.state.skillsProfile.jobvariant > 0 && (this.state.skillsProfile.observer.id === this.props.auth.user_id || this.props.honorarkraftId !== -1) && <Authorization id="skillsprofile-approved"><Button color="primary" className="me-4" onClick={this.handleApproved}><FormattedMessage id="skillsprofile.button.approved" /></Button></Authorization>}
                            {this.state.skillsProfile.approved === 0 && <Button color="primary" onClick={this.save} disabled={false}><FormattedMessage id="button.save" /></Button>}
                        </Container>
                    </Container>
                }
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        executions: state.executions.executions ? state.executions.executions : [],
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        studentId: state.filter.student && state.filter.student.id ? state.filter.student.id : -1,
        studentFirstname: state.filter.student && state.filter.student.firstname ? state.filter.student.firstname : "",
        studentLastname: state.filter.student && state.filter.student.lastname ? state.filter.student.lastname : "",
        studentClass: state.filter.student && state.filter.student.class ? state.filter.student.class : "",
        studentAddress: state.filter.student && state.filter.student.address && state.filter.student.address.id ? state.filter.student.address.id : 3,
        skillsProfile: state.skillsProfile.skillsProfile,
        createStatus: state.skillsProfile.createStatus,
        updateStatus: state.skillsProfile.updateStatus,
        approvedStatus: state.skillsProfile.approvedStatus,
        competences: state.competences.competences,
        rating: state.competences.rating,
        honorarkraft: state.executions.honorarkraft ? state.executions.honorarkraft : [],
        honorarkraftId: state.filter.honorarkraft ? state.filter.honorarkraft.id : -1,
        loading: state.competences.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetCompetences: (token, executionId, studentId, honorarkraftId) => {
            dispatch(competencesActions.getCompetences(token, executionId, studentId, honorarkraftId, null, null, null));
        },
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetSkillsProfile: (token, executionId, studentId, honorarkraftId) => {
            dispatch(skillsProfilesActions.getSkillsProfile(token, executionId, studentId, honorarkraftId))
        },
        dispatchCreateSkillsProfile: (token, executionId, studentId, honorarkraftId, skillsProfile) => {
            dispatch(skillsProfilesActions.createSkillsProfile(token, executionId, studentId, honorarkraftId, skillsProfile))
        },
        dispatchUpdateSkillsProfile: (token, honorarkraftId, skillsProfile) => {
            dispatch(skillsProfilesActions.updateSkillsProfile(token, honorarkraftId, skillsProfile))
        },
        dispatchApprovedSkillsProfile: (token, skillsProfile, honorarkraftId, approved) => {
            dispatch(skillsProfilesActions.approvedSkillsProfile(token, skillsProfile, honorarkraftId, approved))
        },
        dispatchGetExecutionsHonorarkraft: (token, executionId) => {
            dispatch(executionsActions.getExecutionsHonorarkraft(token, executionId, "lastname", false, null));
        },
        dispatchHonorarkraft: (honorarkraft) => {
            dispatch(filterActions.honorarkraft(honorarkraft));
        },
    };
}


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SkillsProfilePage));