import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import translationsVars from '../translations/translationsVars';

class Confirm extends React.Component {

    static propTypes = {
        headertext: PropTypes.string,
        bodytext: PropTypes.string,
        bodytextBoldRed: PropTypes.string,
        cancel: PropTypes.bool,
        save: PropTypes.func,
        dontsave: PropTypes.func,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        yes: PropTypes.func,
        no: PropTypes.func,
    };

    handleSubmit = (type, e) => {
        if (type === 'cancel' || type === 'no') {
            this.props.toggle();
        } else if (type === "save") {
            this.props.toggle();
            this.props.save();
        } else if (type === "dontsave") {
            this.props.toggle();
            this.props.dontsave();
        } else if (type === "yes") {
            this.props.toggle();
            this.props.yes();
        }
    }


    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle} className="confirm" backdrop={'static'}>
                <ModalHeader toggle={(this.props.cancel || this.props.no) && this.handleSubmit.bind(this, "cancel")} >{this.props.headertext && <FormattedMessage id={this.props.headertext} />}</ModalHeader>
                <ModalBody>
                    {this.props.bodytext && <FormattedMessage id={this.props.bodytext} values={translationsVars} />}
                    {this.props.bodytextBoldRed && <span style={{ color: 'red' }}><b><FormattedMessage id={this.props.bodytextBoldRed} values={translationsVars} /></b></span>}
                </ModalBody>
                <ModalFooter>
                    {this.props.save && <Button id="confirmBtnSave" name="save" color="primary" onClick={this.handleSubmit.bind(this, "save")}><FormattedMessage id="button.save" /></Button>}
                    {this.props.dontsave && <Button id="confirmBtnDontSave" name="dontsave" color="primary" onClick={this.handleSubmit.bind(this, "dontsave")}><FormattedMessage id="button.dontsave" /></Button>}
                    {this.props.cancel && <Button id="confirmBtnCancel" name="cancel" color="primary" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.cancel" /></Button>}
                    {this.props.yes && <Button id="confirmBtnYes" name="yes" color="primary" onClick={this.handleSubmit.bind(this, "yes")}><FormattedMessage id="button.yes" /></Button>}
                    {this.props.no && <Button id="confirmBtnNo" name="no" color="primary" onClick={this.handleSubmit.bind(this, "no")}><FormattedMessage id="button.no" /></Button>}
                </ModalFooter>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Confirm));