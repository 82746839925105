import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userProfileService } from '../services';

class UsersAddressSelect extends React.Component {

    static propTypes = {
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        usersAddressId: PropTypes.number,
        handleUsersAddressId: PropTypes.func,
        order: PropTypes.string,
        desc: PropTypes.bool,
        dataEnabled: PropTypes.bool,
        withEmpty: PropTypes.bool,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        filter: PropTypes.array,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        order: "name",
        desc: false,
        dataEnabled: null,
        withEmpty: false,
        invalid: false,
        filter: [],
        disabled: false,
        label: "usersaddress.label.default"
    };

    constructor(props) {
        super(props);
        this.state = {
            usersaddress: [],
        }
    }

    async componentDidMount() {
        await this.getUsersAddress();
    }


    async getUsersAddress() {
        let data = await userProfileService.getUsersAddress(this.props.auth.token);

        if (data && data.data) {
            await this.setState({ usersaddress: data.data.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) })
            if (!this.props.withEmpty && this.props.usersAddressId < 0) {
                this.props.handleUsersAddressId(data.data[0].id);
            }
        } else {
            this.props.handleUsersAddressId(-1);
            this.setState({ usersaddress: [] })
        }
    }

    createUsersAddressItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "usersaddress.option.pleaseselect" })}</option>);
        this.state.usersaddress.forEach((useraddress) => {
            items.push(<option key={useraddress.id} value={useraddress.id} >{useraddress.name && this.props.intl.formatMessage({ id: useraddress.name })}</option>);
        })
        return items;
    }

    handleUsersAddress = async (e) => {
        this.props.handleUsersAddressId(parseInt(e.target.value, 10));
    }

    render() {
        return (
            <Container fluid className={this.state.usersaddress.length > 1 ? this.props.className : "d-none " + this.props.className}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "usersaddress.label.default" ? "fw-bold" : "d-none"} for="usersaddress"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled} invalid={this.props.invalid} bsSize="sm" type="select" name="usersaddress" id="usersaddress" value={this.props.usersAddressId ? this.props.usersAddressId : ''} onChange={this.handleUsersAddress}>
                        {this.createUsersAddressItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersAddressSelect));