import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Table, Input, Button, Row, Col, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import Pages from '../components/Pages';
import config from '../config/config';
import ExecutionsModal from '../components/ExecutionsModal';
import ExecutionsGroupsModal from '../components/ExecutionsGroupsModal';
import ExecutionsInquiryModal from '../components/ExecutionsInquiryModal';
import ExecutionsPastCheckbox from '../components/ExecutionsPastCheckbox';
import ExecutionsCooperationCheckbox from '../components/ExecutionsCooperationCheckbox';
import ProcedureSelect from '../components/ProcedureSelect';
import Loading from '../components/Loading';
import { CheckAuthorization } from '../models/Authorization';
import Confirm from '../components/Confirm';
import Authorization from '../components/Authorization';
import SchoolSelect from '../components/SchoolSelect';
import SchooltypeSelect from '../components/SchooltypeSelect';
import DepartmentSelect from '../components/DepartmentSelect';
import PropTypes from 'prop-types';
import { schoolsActions, filterActions, executionsActions, departmentsActions, userProfileActions } from '../actions';
import { authorizationRoles } from '../config/authorization';
import { generateResetPwdStudentsPDF, generateForeignUsersPDF } from '../pdf';

class ExecutionsPage extends Component {

    static propTypes = {
        tb: PropTypes.array.isRequired,
        schoolId: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pages: 5,
            pagelimit: 10,
            loading: false,
            confirm: false,
            editExecution: null,
            editExecutionInquiry: null,
            editExecutionGroup: null,
            foreignUsersExecution: null,
            deleteId: null,
            openModalNewEdit: false,
            openModalGroup: false,
            openModalInquiry: false,
            alert: { visible: false, textId: "alert.save", color: "success" },
            order: "start",
            desc: false,
            search: "",
            th: [
                { text: "execution.th.procedure", width: "10%" },
                { text: "execution.th.school", order: "schools_name", width: "35%" },
                { text: "execution.th.department", order: "departments_name", width: "15%" },
                { text: "execution.th.startdate", order: "start", width: "10%" },
                { text: "execution.th.enddate", order: "end", width: "10%" },
                { text: "execution.th.action", className: "table-border-left-action", width: "20%" }],
            tb: [],
            tbshow: ["procedure", "school", "school.department.name", "start", "end"],
            confirmResetPwd: false,
            openModalResetPwdError: false,
            resetPwdExecutionId: -1,
            confirmResetPwdPdf: false,
            openModalResetPwdErrorPdf: false,
            resetPwdExecutionIdPdf: -1,
            showGlobalLoading: false,
            searchTimer: null,
            procedures: []
        }
    }

    componentDidMount() {
        this.getData();
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        this.props.dispatchGetDepartments(this.props.auth.token);
        this.props.dispatchGetSchooltypes(this.props.auth.token);
    }

    getData(page = "", search = "", order = "", desc = "") {
        let pageTmp = page === "" ? this.state.page : page
        let searchTmp = search === "" ? this.state.search : search
        let orderTmp = order === "" ? this.state.order : order
        let descTmp = desc === "" ? this.state.desc : desc
        let filter = { past: this.props.past, departmentId: this.props.departmentId, schooltypeId: this.props.schooltypeId, cooperation: this.props.cooperation, procedure: this.props.procedureId };
        if ((this.props.auth.roles.indexOf(authorizationRoles.teacher) !== -1 && this.props.auth.roles.indexOf(authorizationRoles.school) === -1) || this.props.auth.roles.indexOf(authorizationRoles.hk) !== -1) {
            filter.onlyInvolved = true;
        }
        if (this.props.schoolId !== -1 && CheckAuthorization("executions-select-school", this.props.auth.roles)) {
            this.props.dispatchGetExecutionsOfSchool(this.props.auth.token, this.props.schoolId, orderTmp, descTmp, searchTmp, pageTmp, this.state.pagelimit, filter);
        } else {
            this.props.dispatchGetExecutions(this.props.auth.token, orderTmp, descTmp, searchTmp, pageTmp, this.state.pagelimit, filter);
        }
    }

    componentWillUnmount() {
        if (this.props.procedureId > 0) {
            this.props.dispatchProcedure(-1);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.past !== this.props.past || prevProps.cooperation !== this.props.cooperation || prevProps.schoolId !== this.props.schoolId || prevProps.departmentId !== this.props.departmentId || prevProps.schooltypeId !== this.props.schooltypeId
            || prevProps.procedureId !== this.props.procedureId) {
            this.getData();
        }

        /* alert create and update */
        if (prevProps.createExecutionState !== this.props.createExecutionState || prevProps.updateExecutionState !== this.props.updateExecutionState) {
            if (this.props.createExecutionState === 1 || this.props.updateExecutionState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.createExecutionState === 2 || this.props.updateExecutionState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* alert delete */
        if (prevProps.deleteExecutionState !== this.props.deleteExecutionState) {
            if (this.props.deleteExecutionState === 1) {

                //Check if the page still exists
                if (this.state.page > Math.ceil(this.props.total / this.state.pagelimit)) {
                    let newPage = Math.ceil(this.props.total / this.state.pagelimit);
                    this.setState({ page: newPage <= 0 ? 1 : newPage });
                }

                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.delete", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.deleteExecutionState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.delete-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* alert groups */
        if (prevProps.createGroupsState !== this.props.createGroupsState) {
            if (this.props.createGroupsState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.createGroupsState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* reset pwd */
        if (prevProps.resetPwdState !== this.props.resetPwdState) {
            if (this.props.resetPwdState === 1) {
                if (this.props.resetPwdErrors.length === 0) {
                    this.setState({ alert: { visible: true, textId: "execution.alert.resetpwd.success", color: "success" } });
                    setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                } else {
                    this.setState({ openModalResetPwdError: true });
                }

            } else if (this.props.resetPwdState === 2) {
                this.setState({ alert: { visible: true, textId: "execution.alert.resetpwd.failure", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* reset pwd pdf */
        if (prevProps.resetPwdPdfState !== this.props.resetPwdPdfState && this.props.resetPwdPdfState !== 0) {

            if (this.props.resetPwdPdfState === 1) {
                if (this.props.resetPwdPdfStudents.length > 0) {

                    generateResetPwdStudentsPDF(this.props, this.state);
                    // hide the loader if the pdf was created
                    this.setState({ showGlobalLoading: false });
                } else {
                    this.setState({ alert: { visible: true, textId: "execution.alert.resetpwdpdf.nostudents", color: "danger" }, showGlobalLoading: false });
                    setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                }

            } else if (this.props.resetPwdPdfState === 2) {
                this.setState({ alert: { visible: true, textId: "execution.alert.resetpwdpdf.failure", color: "danger" }, showGlobalLoading: false });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* foreign users pdf */
        if (prevProps.foreignUsers !== this.props.foreignUsers) {
            generateForeignUsersPDF(this.props, this.state);
        }

        if (this.props.schools !== prevProps.schools && this.props.schools.length > 0) {
            let arrayProcedures = JSON.parse(JSON.stringify(this.state.procedures));
            this.props.schools.forEach(school => {
                school.procedures.forEach(p => {
                    if (!arrayProcedures.some(item => item.id === p.id)) {
                        arrayProcedures.push(p);
                    }
                });
            });
            this.setState({ procedures: arrayProcedures });
        }
    }


    hideAlert() {
        this.setState({ alert: { ...this.state.alert, visible: false } })
    }

    search = (e) => {
        let search = e.target.value;
        let page = 1;
        this.setState({ search: search, page: page });

        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
        }
        //start search after 500ms
        this.searchTimer = setTimeout(() => {
            this.getData(page, search);
        }, 500);
    }

    order = (order, desc, e) => {
        this.setState({ order: order, desc: desc });
        this.getData("", "", order, desc);
    }

    handleDelete = (id) => {
        this.setState({ deleteId: id });
        this.toggleConfirm();
    }

    handleEdit = (id) => {
        this.handleOpenModalNewEdit(id)
    }

    handleGroup = (id) => {
        this.handleOpenModalGroup(id)
    }

    handleInquiry = (id) => {
        this.handleOpenModalInquiry(id)
    }

    handleOpenModalNewEdit = (id, e) => {

        let index = this.props.tb.map((value) => { return value.id }).indexOf(id);
        if (id === -1 || index === -1) {
            this.setState({ editExecution: null });
        } else {
            this.setState({ editExecution: this.props.tb[index] });
        }
        this.setState({ openModalNewEdit: !this.state.openModalNewEdit });
    }

    handleOpenModalGroup = (id, e) => {
        let index = this.props.tb.map((value) => { return value.id }).indexOf(id);
        if (id)
            this.setState({ editExecutionGroup: this.props.tb[index] });
        this.setState({ openModalGroup: !this.state.openModalGroup });
    }

    handleOpenModalInquiry = (id, e) => {
        let index = this.props.tb.map((value) => { return value.id }).indexOf(id);

        this.setState({ editExecutionInquiry: this.props.tb[index] });
        this.setState({ openModalInquiry: !this.state.openModalInquiry });
    }

    handleConfirmPwdReset = (executionId) => {
        this.toggleConfirmResetPwd(executionId);
    };

    handleShowResetPwdError = () => {
        this.toggleModalResetPwdError();
    };

    handleConfirmPwdResetPdf = (executionId) => {
        this.toggleConfirmResetPwdPdf(executionId);
    };

    handleForeignUsersPdf = (executionId) => {

        let index = this.props.tb.map((value) => { return value.id }).indexOf(executionId);
        if (executionId === -1 || index === -1) {
            this.setState({ foreignUsersExecution: null })
        } else {
            this.setState({ foreignUsersExecution: this.props.tb[index] });
            this.props.dispatchGetForeignUsers(this.props.auth.token, executionId);
        }

    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    toggleConfirmResetPwd = (executionId) => {
        this.setState({
            confirmResetPwd: !this.state.confirmResetPwd,
            resetPwdExecutionId: executionId,
        });
    };

    toggleConfirmResetPwdPdf = (executionId) => {
        this.setState({
            confirmResetPwdPdf: !this.state.confirmResetPwdPdf,
            resetPwdExecutionIdPdf: executionId,
        });
    };

    toggleModalResetPwdError = (e) => {
        this.setState({
            openModalResetPwdError: !this.state.openModalResetPwdError
        });
    };

    resetPwdOfStudents = () => {
        if (this.state.resetPwdExecutionId > -1)
            this.props.dispatchResetPwd(this.props.auth.token, { role: authorizationRoles.student, execution: this.state.resetPwdExecutionId });

        this.toggleConfirmResetPwd();
    };

    resetPwdOfStudentsPdf = () => {
        if (this.state.resetPwdExecutionIdPdf > -1) {
            this.props.dispatchResetPwdPdf(this.props.auth.token, this.state.resetPwdExecutionIdPdf);
            this.setState({ showGlobalLoading: true });
        }

        this.toggleConfirmResetPwdPdf();
    };

    delete = (e) => {
        if (this.state.deleteId) {
            this.props.dispatchDeleteExecution(this.props.auth.token, this.state.deleteId);
        }
        this.toggleConfirm();
    }

    save(execution) {
        if (!execution.id) {
            this.props.dispatchCreateExecution(this.props.auth.token, execution);
        } else {
            this.props.dispatchUpdateExecution(this.props.auth.token, execution);
        }
    }

    saveGroups(execution, groups) {
        if (execution.id) {
            this.props.dispatchCreateGroups(this.props.auth.token, execution, groups);
        }
    }

    saveInquiry(execution) {

    }

    handlePage = (page) => {
        this.setState({ page: page });
        this.getData(page);
    }

    getTh = () => {
        let th;
        th = JSON.parse(JSON.stringify(this.state.th));

        if (!CheckAuthorization("executions-show-procedure", this.props.auth.roles, this.props.auth.procedure)) {
            th = th.filter(el => el.text !== "execution.th.procedure");
        }

        if (!CheckAuthorization("executions-show-department", this.props.auth.roles)) {
            th = th.filter(el => el.text !== "execution.th.department");
        }
        if (this.props.tb.filter(el => el.school && el.school.own === false).length === 0 && !CheckAuthorization("executions-show-school", this.props.auth.roles)) {
            th = th.filter(el => el.text !== "execution.th.school");
        }

        //start / end / action
        if (th.length === 3) {
            th[0].width = "40%";
            th[1].width = "40%";
            th[2].width = "20%";
        }
        //school / start / end / action
        if (th.length === 4) {
            th[0].width = "20%";
            th[1].width = "30%";
            th[2].width = "30%";
            th[3].width = "20%";
        }

        return th;
    }

    getTbShow = () => {

        let ts;
        ts = JSON.parse(JSON.stringify(this.state.tbshow));
        if (!CheckAuthorization("executions-show-procedure", this.props.auth.roles, this.props.auth.procedure)) {
            ts = ts.filter(el => el !== "procedure");
        }

        if (!CheckAuthorization("executions-show-department", this.props.auth.roles)) {
            ts = ts.filter(el => el !== "school.department.name");
        }
        if (this.props.tb.filter(el => el.school && el.school.own === false).length === 0 && !CheckAuthorization("executions-show-school", this.props.auth.roles)) {
            ts = ts.filter(el => el !== "school");
        }
        return ts;

    }


    render() {
        return (
            <Container fluid role="main" className="content-margin">
                {this.state.showGlobalLoading && <Loading absolute />}

                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                <Confirm open={this.state.confirm} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.delete} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                {/* Confirm reset pwd */}
                <Confirm open={this.state.confirmResetPwd} toggle={this.toggleConfirmResetPwd} no={this.toggleConfirmResetPwd} yes={this.resetPwdOfStudents} headertext="execution.confirm.header.resetpwd" bodytext="execution.confirm.body.resetpwd.students" />

                <Modal isOpen={this.state.openModalResetPwdError} toggle={this.handleShowResetPwdError} backdrop={'static'} size="lg">
                    <ModalHeader toggle={this.handleShowResetPwdError}><FormattedMessage id="execution.modal.title.resetpwderror" /></ModalHeader>
                    <ModalBody>
                        <FormattedMessage id="execution.modal.body.resetpwderror" />
                        <br /><br />
                        {this.props.resetPwdErrors.map(err => {
                            return (
                                <Row>
                                    <Col>{err.email}</Col>
                                </Row>
                            );
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleShowResetPwdError} name="close"><FormattedMessage id="button.close" /></Button>
                    </ModalFooter>
                </Modal>

                {/* Confirm reset pwd */}
                <Confirm open={this.state.confirmResetPwdPdf} toggle={this.toggleConfirmResetPwdPdf} no={this.toggleConfirmResetPwdPdf} yes={this.resetPwdOfStudentsPdf} headertext="execution.confirm.header.resetpwdpdf" bodytext="execution.confirm.body.resetpwdpdf.students" />

                <h1><FormattedMessage id="execution.label.execution" /></h1>

                {/* Search */}
                <Container fluid className="m-0 p-0 mb-3">
                    <Row>
                        <Col xs="12" md="6" lg="6" xl="4">
                            <Input type="text" id="search" bsSize="sm" name="search" placeholder={this.props.intl.formatMessage({ id: "execution.search.placeholder" })} autoComplete="off" onKeyUp={this.search} />
                        </Col>
                    </Row>
                </Container>

                {/* New Execution */}
                <Authorization id="executions-new" >
                    <Row className=" mb-4">
                        <Col xs="12" sm="6" md="6" lg="6" xl="4" className="d-flex align-items-end">
                            <Container className="m-0 p-0">
                                <Button size="sm" color="primary" onClick={this.handleOpenModalNewEdit.bind(this, -1)} id="newExecution"><FormattedMessage id="execution.button.new-execution" /></Button>
                            </Container>
                        </Col>
                    </Row>
                </Authorization>

                {/* Filter */}
                <Row>
                    <Col xs="12" md="6" lg="6" xl="4">
                        <ExecutionsPastCheckbox />
                    </Col>
                    <Authorization id="executions-filter-cooperation" >
                        <Col xs="12" md="6" lg="6" xl="4" >
                            <ExecutionsCooperationCheckbox />
                        </Col>
                    </Authorization>
                </Row>

                { /* Select Department / Schooltype */}
                <Authorization id="executions-filter-department-schooltype" >
                    <Row>
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <DepartmentSelect className={this.props.departments.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="execution.label.department" departments={this.props.departments} handleDepartment={this.props.dispatchDepartment.bind(this)} departmentId={this.props.departmentId} />
                        </Col>

                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <SchooltypeSelect className={this.props.schooltypes.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="execution.label.schooltype" schooltypes={this.props.schooltypes} handleSchooltype={this.props.dispatchSchooltype.bind(this)} schooltypeId={this.props.schooltypeId} />
                        </Col>
                    </Row>
                </Authorization>
                { /* Select School */}
                <Row className={this.props.schools && this.props.schools.length > 0 ? "mb-2" : "d-none"}>
                    {this.props.schools.length > 1 &&
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <SchoolSelect className={this.props.schools.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="execution.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                        </Col>
                    }
                    {/* Select Procedure */}
                    <Authorization id="executions-show-procedure" >
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <ProcedureSelect className="m-0 p-0" label="studentadministration.label.procedures" name="procedures" withEmpty procedures={this.state.procedures ? this.state.procedures : []} procedureId={this.props.procedureId} handleProcedures={this.props.dispatchProcedure} />
                        </Col>
                    </Authorization>

                </Row>

                { /* Table */}
                <Container fluid className="m-0 p-0 border">
                    <Table striped bordered className="m-0 p-0 table-fixed w-100">
                        <TableHead data={this.getTh()} order={this.order} orderDefault={this.state.order}/*  editdelete={CheckAuthorization("executions-delete", this.props.auth.roles) || CheckAuthorization("executions-edit", this.props.auth.roles) || CheckAuthorization("executions-group", this.props.auth.roles) || CheckAuthorization("executions-inquiry", this.props.auth.roles) ? true : false}  */ />
                        <TableBody loading={this.props.loading}
                            data={this.props.tb}
                            show={this.getTbShow()}
                            delete={CheckAuthorization("executions-delete", this.props.auth.roles) ? this.handleDelete : null}
                            edit={CheckAuthorization("executions-edit", this.props.auth.roles) ? this.handleEdit.bind(this) : null}
                            inquiry={CheckAuthorization("executions-inquiry", this.props.auth.roles) ? this.handleInquiry : null}
                            group={CheckAuthorization("executions-group", this.props.auth.roles) ? this.handleGroup : null}
                            resetPwd={CheckAuthorization("executions-reset-pwd-students", this.props.auth.roles) ? this.handleConfirmPwdReset : null}
                            resetPwdPdf={CheckAuthorization("executions-reset-pwd-students", this.props.auth.roles) ? this.handleConfirmPwdResetPdf : null}
                            foreignUsersPdf={CheckAuthorization("executions-foreign-users-pdf", this.props.auth.roles) ? this.handleForeignUsersPdf : null}
                            checkEditable
                            checkSchool />
                    </Table>
                </Container>
                { /* Pages */}
                <Container fluid className="d-flex justify-content-end mt-3 p-0">
                    <Pages pages={parseInt(this.state.pages, 10)} totalpages={parseInt(this.props.totalPages, 10)} page={parseInt(this.state.page, 10)} changePage={this.handlePage} />
                </Container>

                { /* MODAL for new and edit */}
                <ExecutionsModal open={this.state.openModalNewEdit} toggle={this.handleOpenModalNewEdit} execution={this.state.editExecution} save={this.save.bind(this)} />

                { /* MODAL for groups */}
                <ExecutionsGroupsModal open={this.state.openModalGroup} toggle={this.handleOpenModalGroup} execution={this.state.editExecutionGroup} save={this.saveGroups.bind(this)} />

                { /* MODAL for inquiry */}
                <ExecutionsInquiryModal open={this.state.openModalInquiry} toggle={this.handleOpenModalInquiry} execution={this.state.editExecutionInquiry} save={this.saveInquiry.bind(this)} />

            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        past: state.filter.executions_past,
        cooperation: state.filter.executions_cooperation,
        tb: state.executions.executions,
        loading: state.executions.loading,
        totalPages: state.executions.total_pages,
        total: state.executions.total,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        createExecutionState: state.executions.createExecutionState,
        createGroupsState: state.executions.createGroupState,
        updateExecutionState: state.executions.updateExecutionState,
        deleteExecutionState: state.executions.deleteExecutionState,
        departments: state.departments.departments,
        departmentId: state.filter.department && state.filter.department.id ? state.filter.department.id : -1,
        schooltypes: state.schools.schooltypes,
        schooltypeId: state.filter.schooltype && state.filter.schooltype.id ? state.filter.schooltype.id : -1,
        resetPwdState: state.userProfile.resetPwdState,
        resetPwdSuccessfulResetted: state.userProfile.resetPwdSuccessfulResetted,
        resetPwdTotalUsers: state.userProfile.resetPwdTotalUsers,
        resetPwdErrors: state.userProfile.resetPwdErrors,
        resetPwdPdfState: state.userProfile.resetPwdPdfState,
        resetPwdPdfStudents: state.userProfile.resetPwdPdfStudents,
        resetPwdPdfExecution: state.userProfile.resetPwdPdfExecution,
        resetPwdPdfKompoUrl: state.userProfile.resetPwdPdfKompoUrl,
        foreignUsers: state.executions.foreignUsers,
        procedures: state.procedures ? state.procedures : [],
        procedureId: state.filter.procedure /*&& state.filter.procedure.id*/ ? state.filter.procedure.id : -1,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, true));
        },
        dispatchGetDepartments: (token) => {
            dispatch(departmentsActions.getDepartments(token, "name", false, null, null, null));
        },
        dispatchGetSchooltypes: (token) => {
            dispatch(schoolsActions.getSchooltypes(token, "name", false));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchDepartment: (department) => {
            dispatch(filterActions.department(department));
        },
        dispatchSchooltype: (schooltype) => {
            dispatch(filterActions.schooltype(schooltype));
        },
        dispatchGetExecutionsOfSchool: (token, schoolId, order, desc, search, page, pagelimit, filter) => {
            dispatch(executionsActions.getExecutionsOfSchool(token, schoolId, order, desc, search, page, pagelimit, filter));
        },
        dispatchGetExecutions: (token, order, desc, search, page, pagelimit, filter) => {
            dispatch(executionsActions.getExecutions(token, order, desc, search, page, pagelimit, filter));
        },
        dispatchCreateExecution: (token, execution) => {
            dispatch(executionsActions.createExecution(token, execution));
        },
        dispatchUpdateExecution: (token, execution) => {
            dispatch(executionsActions.updateExecution(token, execution));
        },
        dispatchDeleteExecution: (token, executionId) => {
            dispatch(executionsActions.deleteExecution(token, executionId));
        },
        dispatchCreateGroups: (token, execution, groups) => {
            dispatch(executionsActions.createGroups(token, execution, groups));
        },
        dispatchResetPwd: (token, filter) => {
            dispatch(userProfileActions.resetPwd(token, filter));
        },
        dispatchResetPwdPdf: (token, executionId) => {
            dispatch(userProfileActions.resetPwdPdf(token, executionId));
        },
        dispatchGetForeignUsers: (token, executionId) => {
            dispatch(executionsActions.getForeignUsers(token, executionId));
        },
        dispatchProcedure: (procedure) => {
            dispatch(filterActions.procedure(procedure));
        }
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionsPage));
