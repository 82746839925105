import { actionTypes } from '../actions/types';

const initialState = {
    observations: {},
    observationsError: '',
    editable: false,
    createStatus: null,
    updateStatus: null,
    loading: false,
    create: true, 
    saving: false,
};

const observationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OBSERVATIONS_REQUEST:
            return {
                ...state,
                observationsError: '',
                loading: true,
                create: true,
            };
        case actionTypes.OBSERVATIONS_SUCCESS:
            return {
                ...state,
                observations: action.observations && action.observations.observation ? action.observations.observation : {},
                editable: action.observations ? action.observations.editable : true, 
                loading: false,
                create: action.observations && action.observations.observation ? false : true
            };
        case actionTypes.OBSERVATIONS_FAILURE:
            return {
                ...state,
                observations: {},
                loading: false,
                editable: false,
                create: true,
            };
        case actionTypes.OBSERVATIONS_CREATE_USERS_REQUEST:
            return {
                ...state,
                observationsError: '',
                createStatus: null,                
                saving: true,
            };
        case actionTypes.OBSERVATIONS_CREATE_USERS_SUCCESS:
            return {
                ...state,
                observationsError: '',
                createStatus: action.status,
                saving: false,
            };
        case actionTypes.OBSERVATIONS_CREATE_USERS_FAILURE:
            return {
                ...state,
                createStatus: action.status,
                observationsError: action.error,
                saving: false,
            };
        case actionTypes.OBSERVATIONS_UPDATE_USERS_REQUEST:
            return {
                ...state,
                observationsError: '',
                updateStatus: null,                
                saving: true,
            };
        case actionTypes.OBSERVATIONS_UPDATE_USERS_SUCCESS:
            return {
                ...state,
                observationsError: '',
                updateStatus: action.status,
                saving: false,
            };
        case actionTypes.OBSERVATIONS_UPDATE_USERS_FAILURE:
            return {
                ...state,
                updateStatus: action.status,
                observationsError: action.error,
                saving: false,
            };
        default:
            return state;
    }
};

export default observationsReducer;
