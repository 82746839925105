import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import { Bar, Doughnut } from 'react-chartjs-2';
import { schoolsActions, departmentsActions, filterActions, kompo7RatingActions } from '../actions';
import SchoolSelect from '../components/SchoolSelect';
import ExecutionSelect from '../components/ExecutionSelect';
import DepartmentSelect from '../components/DepartmentSelect';
import Loading from '../components/Loading';

class Kompo7RatingResultsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterSchools: [],
            maxYAxis: 0,
            barDataset: { labels: [], data: [] },
            doughnutDataset: { labels: [], data: [] }
        }
    }

    componentDidMount() {
        this.props.dispatchGetSchools(this.props.token);
        this.props.dispatchGetDepartments(this.props.token);
        this.props.dispatchGetRatingResults(this.props.token, this.props.departmentId, this.props.schoolId, this.props.executionId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.departmentId !== this.props.departmentId || prevProps.schoolId !== this.props.schoolId || prevProps.executionId !== this.props.executionId) {
            this.props.dispatchGetRatingResults(this.props.token, this.props.departmentId, this.props.schoolId, this.props.executionId);
        }

        if (prevProps.ratingresults !== this.props.ratingresults) {
            this.setState({ barDataset: this.getBarDataset(this.props.ratingresults) });
            this.setState({ doughnutDataset: this.getDoughnutDataset(this.props.ratingresults) });
        }
    }

    getBarDataset(ratingresults) {

        let data = [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]];
        ratingresults.forEach((rating) => {
            if (rating.ratingsquestions_id !== 6 && rating.rating !== 0) {
                data[rating.rating - 1][rating.ratingsquestions_id - 1]++;
            }
        });

        let ds = {
            labels: ['', '', '', '', ''],
            datasets: [
                {
                    label: this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-1" }),
                    data: data[0],
                    borderColor: 'rgba(0,150,64,1)',
                    backgroundColor: 'rgba(0,150,64,0.75)',
                },
                {
                    label: this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-2" }),
                    data: data[1],
                    borderColor: 'rgba(254,155,0,1)',
                    backgroundColor: 'rgba(254,155,0,0.75)',
                },
                {
                    label: this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-3" }),
                    data: data[2],
                    borderColor: 'rgba(227,6,19,1)',
                    backgroundColor: 'rgba(227,6,19,0.75)',
                }
            ]
        };

        //get max value for y-axcis
        let max = 0;
        data.forEach((d) => {
            max += d[0];
        })
        this.setState({ maxYAxis: max === 0 ? 1 : max + 1 });

        return ds;
    }

    getDoughnutDataset(ratingresults) {

        let data = [0, 0, 0];
        ratingresults.forEach((rating) => {
            if (rating.ratingsquestions_id === 6) {
                data[rating.rating - 1]++;
            }
        });

        let ds = {
            labels: [
                this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-1" }),
                this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-2" }),
                this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-3" })
            ],
            datasets: [
                {
                    label: this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-1" }),
                    data: data,
                    //borderColor: ['rgba(0,150,64,0.75)', 'rgba(254,155,0,0.75)', 'rgba(227,6,19,0.75)'],
                    backgroundColor: ['rgba(0,150,64,0.75)', 'rgba(254,155,0,0.75)', 'rgba(227,6,19,0.75)'],
                }
            ]
        };

        return ds;
    }

    getStepSize(maxValue) {
        if (maxValue < 15) {
            return 1;
        }
        else if (maxValue < 50) {
            return 5
        }
        else if (maxValue < 150) {
            return 10
        }
        else if (maxValue < 500) {
            return 25
        }
        else {
            return 50;
        }
    }


    render() {

        return (
            <Container fluid role="main" className="content-margin">
                <h1 className="mb-0" style={{ textTransform: 'capitalize' }}><FormattedMessage id="kompo7ratingresults.title" /></h1>
                <h2 className="text-primary fw-bold"><FormattedMessage id="kompo7ratingresults.label.results" /></h2>
                { /* Select School */}
                <Row className="mt-4">
                    <Col xs="12" sm="6" md="6" lg="6" xl="4">
                        <DepartmentSelect className={this.props.departments.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty emptyLabel="kompo7ratingresults.departmentfilter.overall" label="kompo7ratingresults.label.department" departments={this.props.departments} handleDepartment={this.props.dispatchDepartment.bind(this)} departmentId={this.props.departmentId} />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs="12" sm="6" md="6" lg="6" xl="4">
                        <SchoolSelect className="mb-3 p-0" withEmpty label="kompo7ratingresults.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} filter={this.state.filterSchools} schoolId={this.props.schoolId} />
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="6" xl="4">
                        <ExecutionSelect className="mb-3 p-0" withEmpty label="kompo7ratingresults.label.execution" executionId={this.props.executionId} schoolId={this.props.schoolId} past={true} />
                    </Col>
                </Row>

                {this.props.loading && <Loading />}
                {!this.props.loading &&
                    <Row>
                        <Col xs="12" className="d-inline mt-4">
                            <div className="kompo7ratingresults-rating-1 ps-3 me-4"><FormattedMessage id="kompo7ratingresults.label.rating-1" /></div>
                            <div className="kompo7ratingresults-rating-2 ps-3 me-4"><FormattedMessage id="kompo7ratingresults.label.rating-2" /></div>
                            <div className="kompo7ratingresults-rating-3 ps-3"><FormattedMessage id="kompo7ratingresults.label.rating-3" /></div>
                        </Col>
                    </Row>
                }
                {!this.props.loading &&
                    <Row>
                        <Col xs="12" className="pt-3 kompo7ratingresults-bar">
                            {/* Bar Chart */}
                            <Bar data={this.state.barDataset}
                                options={
                                    {
                                        maintainAspectRatio: false,
                                        scales: {
                                            yAxes: {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: this.getStepSize(this.state.maxYAxis),
                                                    max: this.state.maxYAxis,
                                                },
                                                stacked: true
                                            },
                                            xAxes: {
                                                stacked: true,
                                                ticks: {
                                                    maxRotation: 0,
                                                    minRotation: 0,
                                                    autoSkip: false,
                                                },
                                                display: true,
                                            }
                                        },
                                        plugins: {
                                            legend: {
                                                display: false,
                                            }
                                        }

                                    }
                                }

                            />
                        </Col>
                    </Row>
                }
                {/* Labels for x-axis */}
                {!this.props.loading &&
                    <Row>
                        <Col xs="12" className="kompo7ratingresults-xaxes">
                            <div><FormattedMessage id="kompo7ratingresults.question-1" /></div>
                            <div><FormattedMessage id="kompo7ratingresults.question-2" /></div>
                            <div><FormattedMessage id="kompo7ratingresults.question-3" /></div>
                            <div><FormattedMessage id="kompo7ratingresults.question-4" /></div>
                            <div><FormattedMessage id="kompo7ratingresults.question-5" /></div>
                        </Col>
                    </Row>
                }
                {!this.props.loading &&
                    <Row>
                        <Col xs="12" md="4" className="mt-4 mb-4">
                            <h2 className="text-primary fw-bold"><FormattedMessage id="kompo7ratingresults.question-6" /></h2>
                        </Col>
                        <Col xs="12" md="8" className="mt-4 mb-4">
                            <h2 className="text-primary fw-bold"><FormattedMessage id="kompo7ratingresults.label.remarks" /></h2>
                        </Col>
                    </Row>
                }
                {!this.props.loading &&
                    <Row>
                        <Col xs="12" md="4" className="kompo7ratingresults-doughnut">
                            <Doughnut data={this.state.doughnutDataset}
                                options={
                                    {
                                        maintainAspectRatio: false,
                                        max: 60,
                                        legend: {
                                            display: false,
                                        }

                                    }
                                } />
                        </Col>
                        <Col xs="12" md="8">
                            <div className="kompo7ratingresults-remarks border">
                                {
                                    this.props.ratingmessages.map((rating_message, index) => {
                                        if (rating_message.length > 1) {
                                            return <div key={index} className="border-bottom mx-2 p-2 font-italic">&bdquo;{rating_message.trim()}&ldquo;</div>
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        executionId: state.filter.execution && state.filter.execution.id ? state.filter.execution.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        departments: state.departments.departments,
        departmentId: state.filter.department && state.filter.department.id ? state.filter.department.id : -1,
        ratingresults: state.kompo7Rating.ratingresults && state.kompo7Rating.ratingresults.ratings ? state.kompo7Rating.ratingresults.ratings : [],
        ratingmessages: state.kompo7Rating.ratingresults && state.kompo7Rating.ratingresults.rating_messages ? state.kompo7Rating.ratingresults.rating_messages : [],
        questions: state.kompo7Rating.ratingresults && state.kompo7Rating.ratingresults.questions ? state.kompo7Rating.ratingresults.questions : [],
        loading: state.kompo7Rating.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchools: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetDepartments: (token) => {
            dispatch(departmentsActions.getDepartments(token, "name", false, null, null, null));
        },
        dispatchDepartment: (department) => {
            dispatch(filterActions.department(department));
        },
        dispatchGetRatingResults: (token, departmentId, schoolId, executionId) => {
            dispatch(kompo7RatingActions.getRatingResults(token, departmentId, schoolId, executionId));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Kompo7RatingResultsPage));