import { actionTypes } from './types';
import { verbexerciseService } from '../services/verbexerciseService';

export const verbexerciseActions = {
    getVerbs,
    saveVerbs,
    saveResultVerbs,
    deleteVerbs
};

function getVerbs(token, executionId, studentsId, honorarkraftId) {
    return dispatch => {
        dispatch(request({getVerbs}));

        verbexerciseService.getVerbs(token, executionId, studentsId, honorarkraftId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(verbs) { return { type: actionTypes.VERBEXERCISE_REQUEST, verbs } }
    function success(verbs) { return { type: actionTypes.VERBEXERCISE_SUCCESS, verbs } }
    function failure(error) { return { type: actionTypes.VERBEXERCISE_FAILURE, error } }
}

function deleteVerbs(token, executionId, studentsId, honorarkraftId) {
    return dispatch => {
        dispatch(request({deleteVerbs}));

        verbexerciseService.deleteVerbs(token, executionId, studentsId, honorarkraftId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.VERBEXERCISE_DELETE_REQUEST, status } }
    function success(status) { return { type: actionTypes.VERBEXERCISE_DELETE_SUCCESS, status } }
    function failure(status) { return { type: actionTypes.VERBEXERCISE_DELETE_FAILURE, status } }
}

function saveVerbs(token, executionId, verbs) {
    return dispatch => {
        dispatch(request({saveVerbs}));

        verbexerciseService.saveVerbs(token, executionId, verbs)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) {return { type: actionTypes.VERBEXERCISE_SAVE_REQUEST, status }}
    function success(status) {return { type: actionTypes.VERBEXERCISE_SAVE_SUCCESS, status }}
    function failure(status) {return { type: actionTypes.VERBEXERCISE_SAVE_FAILURE, status }}
}

function saveResultVerbs(token, executionId, verbsResult) {
    return dispatch => {
        dispatch(request({saveResultVerbs}));

        verbexerciseService.saveResultVerbs(token, executionId, verbsResult)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) {return { type: actionTypes.VERBEXERCISERESULT_SAVE_REQUEST, status }}
    function success(status) {return { type: actionTypes.VERBEXERCISERESULT_SAVE_SUCCESS, status }}
    function failure(status) {return { type: actionTypes.VERBEXERCISERESULT_SAVE_FAILURE, status }}
}

