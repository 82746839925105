import { interesttestService } from '../services';
import { actionTypes } from './types';

export const interesttestActions = {
    getStudentsInteresttest,
};

function getStudentsInteresttest(token, executionsId) {
    return dispatch => {
        dispatch(request());

        interesttestService.getStudentsInteresttest(token, executionsId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.INTERESTTEST_RESULTS_REQUEST } }
    function success(results) { return { type: actionTypes.INTERESTTEST_RESULTS_SUCCESS, results } }
    function failure(error) { return { type: actionTypes.INTERESTTEST_RESULTS_FAILURE, error } }
}

