import { actionTypes } from '../actions/types';

const initialState = {
    verbs: [],
    loading: false,
    loadingSaveVerbs: false,
    verbexerciseError: '',
    saving: false,
    saveStatus: null
};

const verbexerciseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VERBEXERCISE_REQUEST:
            return {
                ...state,
                loading: true,
                verbexerciseError: ''
            };
        case actionTypes.VERBEXERCISE_SUCCESS:
            return {
                ...state,
                loading: false,
                verbs: action.verbs ? action.verbs : [],
                verbexerciseError: ''
            };
        case actionTypes.VERBEXERCISE_FAILURE:
            return {
                ...state,
                loading: false,
                verbs: [],
                verbexerciseError: action.error
            };
        case actionTypes.VERBEXERCISE_SAVE_REQUEST:
            return {
                ...state,
                verbexerciseError: '',
                loadingSaveVerbs: true,
                saving: true,
                saveStatus: null
            };
        case actionTypes.VERBEXERCISE_SAVE_SUCCESS:
            return {
                ...state,
                verbexerciseError: '',
                saveStatus: true,
                loadingSaveVerbs: false,
                saving: false
            };
        case actionTypes.VERBEXERCISE_SAVE_FAILURE:
            return {
                ...state,
                verbexerciseError: action.error,
                loadingSaveVerbs: false,
                saveStatus: false,
                saving: false
            };
        case actionTypes.VERBEXERCISERESULT_SAVE_REQUEST:
            return {
                ...state,
                verbexerciseError: '',
                loadingSaveVerbs: true,
                saving: true,
                saveStatus: null
            };
        case actionTypes.VERBEXERCISERESULT_SAVE_SUCCESS:
            return {
                ...state,
                verbexerciseError: '',
                saveStatus: true,
                loadingSaveVerbs: false,
                saving: false
            };
        case actionTypes.VERBEXERCISERESULT_SAVE_FAILURE:
            return {
                ...state,
                verbexerciseError: action.error,
                loadingSaveVerbs: false,
                saveStatus: false,
                saving: false
            };
        case actionTypes.VERBEXERCISE_DELETE_REQUEST:
            return {
                ...state
            };
        case actionTypes.VERBEXERCISE_DELETE_SUCCESS:
            return {
                ...state
            };
        case actionTypes.VERBEXERCISE_DELETE_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default verbexerciseReducer;
