import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Button, Collapse, Row, Col, Label } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import Loading from '../components/Loading';
import ENV from '../config/env';
import { Bar } from 'react-chartjs-2';
import { interesttestActions } from '../actions/interesttestActions';
import Confirm from '../components/Confirm';
import translationsVars from '../translations/translationsVars';
import classnames from 'classnames';

class InterestTestPage extends Component {


    constructor(props) {
        super(props);

        let bit = {};
        try {
            bit = JSON.parse(props.results).bit;
        } catch (e) {
            console.log("BIT: INVALID JSON");
        }

        this.state = {
            start: false,
            confirmrestart: false,
            showFinish: false,
            barData: this.getBarData(bit),
            completeTimer: null,
            collapse: [],
            bitData: {
                primaryColor: '#383980',
                secondaryColor: '#d11b28',
                bit: { ...bit, state: bit.state ? bit.state : 'incomplete' },
                targetUrl: ENV.apiUrl + '/interesttest/' + this.props.executionId,
                jwttoken: this.props.auth.token
            },
            barOptions: {
                indexAxis: 'y',
                scales: {

                    x: {
                        // display: false,
                        min: 0,
                        max: 100,
                        ticks: {
                            display: false,
                            stepSize: 33.4,
                            suggestedMin: 0,
                            suggestedMax: 100,
                            max: 100
                        },
                        grid: {
                            color: '#bababa',
                            offsetGridLines: true,
                            lineWidth: 1,
                        },
                        // offset: true,
                    }
                },
                plugins: {
                    legend: { display: false },
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let label = data.datasets[tooltipItem.datasetIndex].label || '';
                            if (label) {
                                label += ': ';
                                label += parseFloat(tooltipItem.xLabel).toFixed(0) + " %";
                            }

                            return label;
                        }
                    }
                },
            },
            translationsVars: { ...translationsVars, user: props.auth.firstname },
        }
        if (props.executionId !== -1) {
            //load old results
            this.props.dispatchGetInteresttest(props.auth.token, props.executionId);
        }

    }

    getBarData(bit) {

        let barData = { labels: [], datasets: [], borderWidth: 0.5 };

        //const barColors = ['rgba(0,150,64,0.75)', 'rgba(254,155,0,0.75)', 'rgba(227,6,19,0.75)']; // multi color
        const barColors = ['rgba(56,57,128,0.75)', 'rgba(56,57,128,0.75)', 'rgba(56,57,128,0.75)']; // single color

        if (bit && bit.professionalfields) {
            bit.professionalfields.sort((a, b) => a.value * 100 / a.maxValue < b.value * 100 / b.maxValue ? 1 : a.value * 100 / a.maxValue > b.value * 100 / b.maxValue ? -1 : 0);
            let d = [];
            let colors = [];
            bit.professionalfields.forEach((pf) => {
                let p = pf.value * 100 / pf.maxValue;
                colors.push(p >= 66.6 ? barColors[0] : p >= 33.3 ? barColors[1] : barColors[2]);
                d.push(p)
                barData.labels.push(this.props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" }));
            })
            barData.datasets.push({ label: this.props.intl.formatMessage({ id: "interesttest.chart.value" }), data: d, backgroundColor: colors });
        }
        return barData;
    }

    componentWillUnmount() {
        clearTimeout(this.state.completeTimer);
    }

    completeTimer() {
        if (this.props.executionId !== -1) {
            //load old results
            this.props.dispatchGetInteresttest(this.props.auth.token, this.props.executionId);
        }
        if (this.state.bitData.bit.state && this.state.bitData.bit.state !== 'complete') {
            this.setState({ ...this.state, completeTimer: setTimeout(this.completeTimer.bind(this), 2000) });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.executionId !== prevProps.executionId && this.props.executionId !== -1) {

            //load old results
            this.props.dispatchGetInteresttest(this.props.auth.token, this.props.executionId);

            this.setState({
                ...this.state, bitData: {
                    ...this.state.bitData,
                    jwttoken: this.props.auth.token,
                    targetUrl: ENV.apiUrl + '/interesttest/' + this.props.executionId
                }
            })
        }
        if ((this.props.results !== prevProps.results && this.props.results)) {
            let bit = null;
            try {
                bit = JSON.parse(this.props.results).bit;
            } catch (e) {
                console.log("BIT: INVALID JSON");
            }

            if (bit && (bit.state !== this.state.bitData.bit.state || this.state.start === false)) {
                clearTimeout(this.state.completeTimer);
                this.setState({
                    ...this.state,
                    bitData: {
                        ...this.state.bitData,
                        bit: bit
                    },
                    barData: this.getBarData(bit)
                })
            }
        }

    }

    handleStart = (e) => {
        this.setState({ ...this.state, start: true, showFinish: true, completeTimer: setTimeout(this.completeTimer.bind(this), 2000) })
    }

    handleRestart = (e) => {
        this.setState({ ...this.state, confirmrestart: true });
        //this.setState({ ...this.state, start: true, showFinish: true, bitData: { ...this.state.bitData, bit: { state: 'incomplete' } }, completeTimer: setTimeout(this.completeTimer.bind(this), 2000) })
    }

    toggleConfirmRestart = (e) => {
        this.setState({ ...this.state, confirmrestart: !this.state.confirmrestart });
    }

    restart = (e) => {
        this.setState({ ...this.state, confirmrestart: false, start: true, showFinish: true, bitData: { ...this.state.bitData, bit: { state: 'incomplete' } }, completeTimer: setTimeout(this.completeTimer.bind(this), 2000) })
    }

    handleResult = (e) => {
        this.setState({ ...this.state, showFinish: false });
    }

    handleCollapse = (index) => {
        let cl = [...this.state.collapse];
        cl[index] = cl[index] ? !cl[index] : true;
        this.setState({ collapse: cl });
    }



    render() {
        let content = "";
        let cssContainerResult = false;
        let countPF = 0;
        let lastPf = null;

        if (this.state.bitData && this.state.bitData.bit && this.state.bitData.bit.state === 'complete' && this.state.showFinish === false) {
            cssContainerResult = true;
            //Result with horizontal bar
            content =
                <Container fluid>
                    <Row>
                        <Col xs="12" className="p-0"><p><FormattedMessage id="interesttest.label.result" /></p></Col>
                    </Row>
                    <Row style={{ marginBottom: '0rem' }}>
                        <Col xs="12" className="p-0">
                            <div className="container-bar">
                                <Bar data={this.state.barData} responsive="false" height={100} options={this.state.barOptions} />
                                <div id="container-smiley">
                                    <div id="container-bit-smiley-1"></div>
                                    <div id="container-bit-smiley-2"></div>
                                    <div id="container-bit-smiley-3"></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {

                        this.state.bitData.bit.professionalfields.map((pf, index) => {
                            if (lastPf === null || countPF < 3 || pf.value === lastPf.value) {
                                countPF++;
                                lastPf = pf;

                                return (
                                    <div key={pf.id}>
                                        <Row >
                                            <Col xs="12" className="pt-2" >
                                                <Label className="d-none" for="collapse-about">
                                                    <FormattedMessage id={this.state.collapse[index] && this.state.collapse[index] === true ? "collapse.close" : "collapse.open"} />
                                                </Label>
                                                <Button name="collapse" color="link" className="btn-collapse m-0 p-0 fw-bold" onClick={this.handleCollapse.bind(this, index)} >
                                                    <span className={this.state.collapse[index] && this.state.collapse[index] === true ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                                    <FormattedMessage id={"professionalfield." + pf.id + ".name"} />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Collapse className="border-bottom" isOpen={this.state.collapse[index] && this.state.collapse[index] === true}>
                                            {/* <Row>                                                                                                
                                                <Col xs="12" style={{ paddingLeft: '2.35rem' }}>
                                                    <FormattedMessage id={"professionalfield." + pf.id + ".description." + this.props.auth.address.id} values={translationsVars} />
                                                </Col> 
                                            </Row> */}
                                            <Row>
                                                <Col xs="12" style={{ paddingLeft: '2.35rem' }}>
                                                    <FormattedMessage id={"professionalfield." + pf.id + ".jobs"} values={translationsVars} />
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })
                    }
                </Container>;
        } else if (this.state.bitData && this.state.bitData.bit && this.state.bitData.bit.state === 'complete' && this.state.showFinish === true) {
            //finish 
            content =
                <div>
                    <p><FormattedMessage id="interesttest.label.finish" /></p>
                    <Container fluid className="p-0 mb-4 d-flex justify-content-end flex-row">
                        <Button color="primary" onClick={this.handleResult} ><FormattedMessage id="interesttest.button.result" /></Button>
                    </Container>
                </div>;
        } else if (this.props.executionId !== -1 && this.state.start) {
            //bit 
            content = <iframe id="bit-iframe" className="p-0" src={ENV.bitUrl + "?jsvars=" + encodeURIComponent(JSON.stringify(this.state.bitData)) + '&x=0'} title="interesttest"></iframe>
        } else if (this.props.loading) {
            //loading
            content = <Loading />;
        } else if (this.props.executionId !== -1) {
            //start the test
            content =
                <Container fluid className="mb-4 pb-4">
                    <p><FormattedMessage id="interesttest.label.statement" values={this.state.translationsVars} /></p>

                    <Container fluid className="p-0 mb-4 d-flex flex-row">
                        <div className="bit-smiley-1"></div>
                        <div className="bit-smiley-label"><FormattedMessage id="interesttest.label.smiley1" /></div>
                    </Container>
                    <Container fluid className="p-0 mb-4 d-flex flex-row">
                        <div className="bit-smiley-2"></div>
                        <div className="bit-smiley-label"><FormattedMessage id="interesttest.label.smiley2" /></div>
                    </Container>
                    <Container fluid className="p-0 mb-4 d-flex flex-row">
                        <div className="bit-smiley-3"></div>
                        <div className="bit-smiley-label"><FormattedMessage id="interesttest.label.smiley3" /></div>
                    </Container>

                    <p><FormattedMessage id="interesttest.label.statement2" values={this.state.translationsVars} /></p>
                    {/* Confirm resetstart test */}
                    <Confirm open={this.state.confirmrestart} toggle={this.toggleConfirmRestart} no={this.toggleConfirmRestart} yes={this.restart} headertext="interesttest.confirm.restart.headertext" bodytext="interesttest.confirm.restart.bodytext" />

                    <Container fluid className="p-0 mb-4 d-flex flex-row">
                        {
                            !this.state.bitData.bit.currentQuestion &&
                            <Button color="primary" onClick={this.handleStart} ><FormattedMessage id="interesttest.button.start" /></Button>
                        }
                        {
                            this.state.bitData.bit.currentQuestion &&
                            <Button color="primary" className="me-2" onClick={this.handleRestart} ><FormattedMessage id="interesttest.button.restart" /></Button>
                        }
                        {this.state.bitData.bit.currentQuestion &&
                            <Button color="primary" onClick={this.handleStart} ><FormattedMessage id="interesttest.button.continue" /></Button>
                        }
                    </Container>
                </Container>;
        } else if (this.props.executionId === -1) {
            content =
                <Container fluid className="p-0 fw-bold">
                    <FormattedMessage id="interesttest.label.noavailable" />
                </Container>;
        }

        return (
            <Container fluid role="main" className={classnames("content-margin", { 'bit-iframe-container': !cssContainerResult, 'bit-iframe-container-result': cssContainerResult })}>
                <h1><FormattedMessage id="interesttest.label.interesttest" /></h1>
                {/* Select execution */}
                <ExecutionSelect className="mb-3 p-0" executionId={this.props.executionId} past={true} />
                {content}
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        results: state.interesttest.results ? state.interesttest.results : null,
        loading: state.interesttest.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetInteresttest: (token, executionId) => {
            dispatch(interesttestActions.getStudentsInteresttest(token, executionId))
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(InterestTestPage));