import ENV from '../config/env';

export const schoolsService = {
    createSchool,
    updateSchool,
    deleteSchool,
    getSchools,
    getSchoolsContactPerson,
    getSchoolsForUser,
    getSchoolsTeacher,
    getSchoolsStudents,
    getSchoolyears,
    getSchooltypes,
    getSchoolSettings,
    saveSchoolSetting,
};

async function createSchool(token, school) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ school })
    };

    return fetch(ENV.apiUrl + '/schools', requestOptions)
        .then(response => {
            if (!response.ok && response.status !== 409) {
                return Promise.reject(response.statusText);
            } else if (!response.ok && response.status === 409) {
                return response.json();
            }
            
            return response.json();
        });
}

async function updateSchool(token, school) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ school })
    };

    return fetch(ENV.apiUrl + '/schools', requestOptions)
        .then(response => {
            if (!response.ok && response.status !== 409) {
                return Promise.reject(response.statusText);
            } else if (!response.ok && response.status === 409) {
                return response.json();
            }

            return response;
        });
}

async function deleteSchool(token, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/schools/' + id, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;
        });
}

async function getSchools(token, order, desc, enabled, page, pagelimit) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };
    enabled = enabled !== null ? ("&enabled=" + enabled) : "";
    page = page !== null ? ("&page=" + page) : "";
    pagelimit = pagelimit !== null ? ("&pagelimit=" + pagelimit) : "";

    return fetch(ENV.apiUrl + '/schools?order=' + order + "&desc=" + (desc ? "1" : "0") + enabled + page + pagelimit, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}
async function getSchoolsForUser(token, order, desc, enabled, search, page, pagelimit, schooltypeId, departmentId, userSchoolsOnly) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };
    enabled = enabled !== null ? ("&enabled=" + enabled) : "";
    search = search ? "&search=" + search : "";
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";
    schooltypeId = schooltypeId ? "&schooltype_id=" + schooltypeId : "";
    departmentId = departmentId ? "&department_id=" + departmentId : "";
    userSchoolsOnly = userSchoolsOnly ? "&user_schools_only=" + userSchoolsOnly : "";

    return fetch(ENV.apiUrl + '/schools/foruser?order=' + order + "&desc=" + (desc ? "1" : "0") + enabled + search + page + pagelimit + schooltypeId + departmentId + userSchoolsOnly, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}

async function getSchoolsContactPerson(token, schoolId, procedureId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/schools/contactperson/' + schoolId + '?procedure=' + procedureId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}

async function getSchoolsTeacher(token, schoolId, order, desc, search, page, pagelimit) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "&order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    search = search ? "&search=" + search : "";
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";

    return fetch(ENV.apiUrl + '/schools/teacher/' + schoolId + '?' + order + desc + search + page + pagelimit, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}
async function getSchoolsStudents(token, schoolId, order, desc, search, page, pagelimit, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "&order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    search = search ? "&search=" + search : "";
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";

    let schoolyearId = '';
    let procedureId = '';

    filter.forEach(f => {
        Object.keys(f).forEach(key => {
            if (key === 'schoolyearId') {
                schoolyearId = f.schoolyearId && f.schoolyearId >= 0 ? "&schoolyear=" + f.schoolyearId : "";
            } else if (key === 'procedureId') {
                procedureId = f.procedureId && f.procedureId >= 0 ? "&procedure=" + f.procedureId : "";
            }
        });
    });    

    let isNotInOtherExecution = filter.isNotInOtherExecution && filter.isNotInOtherExecution >= 0 ? "&isnotinotherexecution=" + filter.isNotInOtherExecution : "";

    return fetch(ENV.apiUrl + '/schools/students/' + schoolId + '?' + order + desc + search + page + pagelimit + schoolyearId + procedureId + isNotInOtherExecution, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}

async function getSchoolyears(token, order, desc) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "?order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");

    return fetch(ENV.apiUrl + '/schoolyears' + order + desc, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}

async function getSchooltypes(token, order, desc) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "?order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");

    return fetch(ENV.apiUrl + '/schooltype' + order + desc, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}

async function getSchoolSettings(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json' 
        },
    };

    return fetch(ENV.apiUrl + '/schools/settings', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response.json();
        })
        .then(response => {
            return response;
        });
}

function saveSchoolSetting(token, settingKey, settingValue) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ settingKey, settingValue })
    };

    return fetch(ENV.apiUrl + '/schools/settings', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText); // TODO: maybe return the whole response, instead just the statustext
            }

            return response;
        });
}
