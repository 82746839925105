import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';

class SponsorPage extends Component {

    render() {
        return (
            <Container fluid role="main" className="content-margin">
                <h1><FormattedMessage id="sponsor.label.sponsor" /></h1>
                <Row>
                    <Col xs="12" className="border-top pt-3">
                        <h3><FormattedMessage id="sponsor.label.hessen" /></h3>
                        <img className="sponsor-logo" src={require('../assets/images/Hessen_Logo.png')} alt={this.props.intl.formatMessage({ id: "sponsor.alt.hessen" })} width="auto" height="75%" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="border-top pt-3">
                        <h3><FormattedMessage id="sponsor.label.berufsorientierung" /></h3>
                        <img className="sponsor-logo" src={require('../assets/images/Berufsorientierung_Logo.png')} alt={this.props.intl.formatMessage({ id: "sponsor.alt.berufsorientierung" })} width="auto" height="50%" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="border-top pt-3">
                        <h3><FormattedMessage id="sponsor.label.bildungsketten" /></h3>
                        <img className="sponsor-logo" src={require('../assets/images/Bildungsketten_Logo.png')} alt={this.props.intl.formatMessage({ id: "sponsor.alt.bildungsketten" })} width="auto" height="50%" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="border-top pt-3">
                        <h3><FormattedMessage id="sponsor.label.bundesministerium" /></h3>
                        <img className="sponsor-logo" src={require('../assets/images/Bundesministerium_BuF_Logo.png')} alt={this.props.intl.formatMessage({ id: "sponsor.alt.bundesministerium" })} width="auto" height="75%" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="border-top pt-3">
                        <h3><FormattedMessage id="sponsor.label.bundesagentur" /></h3>
                        <img className="sponsor-logo" src={require('../assets/images/Agentur_Arbeit_Logo.png')} alt={this.props.intl.formatMessage({ id: "sponsor.alt.bundesagentur" })} width="auto" height="50%" />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="border-top pt-3">
                        <h3><FormattedMessage id="sponsor.label.bwhw" /></h3>
                        <img className="sponsor-logo" src={require('../assets/images/Logo_BWHW2016_160506.png')} alt={this.props.intl.formatMessage({ id: "sponsor.alt.bwhw" })} width="auto" height="50%" />
                    </Col>
                </Row>

            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SponsorPage));