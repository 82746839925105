import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Form, Label, Input, Alert, Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { loginActions } from '../actions';
import ImpressumPage from './ImpressumPage';
import PasswordResetModal from '../components/PasswordResetModal';
import Loading from '../components/Loading';

import config from '../config/config';
import PrivacypolicyModal from '../components/PrivacypolicyModal';
var qs = require('qs');

class LoginPage extends Component {
    static propTypes = {
        // redux state
        loginError: PropTypes.any,
        sendResetPwdMailError: PropTypes.string,
        sendResetPwdMailSuccess: PropTypes.bool,
        privacypolicy: PropTypes.bool,

        // redux dispatch
        dispatchLogin: PropTypes.func.isRequired,
        dispatchSendResetPwdMail: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            login: '',
            password: '',
            impressumVisible: false,
            alertVisible: false,
            alertMessage: '',
            alertType: 'failure',
            resetPwdModalOpen: false,
        };
    }

    componentDidMount() {
        // prefill login data with url parameters
        if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).u) {
            if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).p) {
                const login = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).u.trim();
                const password = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).p.trim();
                this.setState({ login: login, password: password });
            }
        }
    }

    componentDidUpdate(prevProps) {
        // prefill login data with url parameters
        if (prevProps.location.search !== this.props.location.search) {
            if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).u) {
                if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).p) {
                    const login = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).u.trim();
                    const password = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).p.trim();
                    this.setState({ login: login, password: password });
                }
            }
        }

        if (this.props.loginError && prevProps.loginError !== this.props.loginError) {
            if (this.props.loginError.toString() === '401') {
                this.showFailureAlert('loginpage.alert.login-error');
            } else {
                this.showFailureAlert('loginpage.alert.server-unavailable');
            }
        }
        else if (this.props.sendResetPwdMailError && prevProps.sendResetPwdMailError !== this.props.sendResetPwdMailError) {
            this.showFailureAlert('loginpage.alert.resetmail-error');
        }
        else if (prevProps.sendResetPwdMailSuccess !== this.props.sendResetPwdMailSuccess &&
            !this.props.sendResetPwdMailError && this.props.sendResetPwdMailSuccess) {
            this.setState({ resetPwdModalOpen: false });
            this.showSuccessAlert('loginpage.alert.resetmail-success');
        }
    }


    // event handlers
    handleLoginChange = (e) => {
        this.setState({ login: e.target.value.trim() });
    };

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value.trim() });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        // get input from state
        const login = this.state.login.trim();
        const password = this.state.password.trim();

        // dispatch action
        this.props.dispatchLogin(login, password);
    };

    handleResetPassword = (e) => {
        this.setState({ resetPwdModalOpen: !this.state.resetPwdModalOpen });
    };

    handleResetPasswordSubmit = (login) => {
        this.props.dispatchSendResetPwdMail(login);
    };

    handleCloseResetPassword = () => {
        this.setState({ resetPwdModalOpen: false })
    };

    handleShowImpressum = (e) => {
        this.setState({ impressumVisible: !this.state.impressumVisible });
    };

    handleHideAlert = (e) => {
        this.setState({ alertVisible: false });
    };

    showSuccessAlert(msg) {
        this.setState({ alertVisible: true, alertMessage: msg, alertType: 'success' });
        setTimeout(this.handleHideAlert, config.alertTimeOut);
    }

    showFailureAlert(msg) {
        this.setState({ alertVisible: true, alertMessage: msg, alertType: 'danger' });
        setTimeout(this.handleHideAlert, config.alertTimeOut);
    }

    handlePrivacypolicy = (accept) => {
        if (accept) {
            this.props.dispatchPrivacypolicy(this.props.token);
        } else {
            this.props.dispatchLogout();
        }

    }

    render() {
        return (
            <Container fluid role="main">
                <Alert isOpen={this.state.alertVisible} toggle={this.handleHideAlert} color={this.state.alertType} className="fixed-top text-center"><FormattedMessage id={this.state.alertMessage} /></Alert>
                <PasswordResetModal open={this.state.resetPwdModalOpen} handleSubmit={this.handleResetPasswordSubmit} handleCancel={this.handleCloseResetPassword} />

                <PrivacypolicyModal open={this.props.privacypolicy} handlePrivacypolicy={this.handlePrivacypolicy.bind(this)} />

                {this.props.isSetPrivacypolicy && <Loading absolute />}

                <Container fluid>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Row className="pt-2 pb-0">
                            <Col className="mb-3" xs="12" sm={{ size: 4, offset: 4 }} md={{ size: 4, offset: 4 }} lg={{ size: 2, offset: 5 }} xl={{ size: 2, offset: 5 }}>
                                <img src={require('../assets/images/KomPo7_logo.png')} alt={this.props.intl.formatMessage({ id: "loginpage.alt.logo-kompo7" })} width="100%" height="auto" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }} xl={{ size: 4, offset: 4 }} className="mx-auto py-2 px-4 bg-primary text-white text-uppercase fw-bold">
                                <FormattedMessage id="loginpage.header.login" />
                            </Col>
                        </Row>
                        <Row className="p-0">
                            <Col xs="12" sm="8" md="6" lg="4" xl="4" className="px-4 pt-3 pb-0 m-0 mx-auto border-start border-end border-primary">
                                <div className="mb-1">
                                    <Label for="inputLogin"><FormattedMessage id="loginpage.label.login" /></Label>
                                    <Input aria-required type="text" name="inputLogin" id="inputLogin" value={this.state.login} autoComplete="off" onChange={this.handleLoginChange} tabIndex="1" />
                                </div>
                                <div className="mb-1">
                                    <Label for="inputPassword" className="mt-2"><FormattedMessage id="loginpage.label.password" /></Label>
                                    <Input aria-required type="password" name="inputPassword" id="inputPassword" value={this.state.password} autoComplete="off" onChange={this.handlePasswordChange} tabIndex="2" />
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-0">
                            <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }} xl={{ size: 4, offset: 4 }} className="pb-2 d-flex justify-content-between mx-auto  border-start border-end border-bottom border-primary">
                                <Container className="d-flex flex-column align-items-start  ps-2 p-0">
                                    <a className="text-secondary" name="forgotpassword" tabIndex="4" onClick={this.handleResetPassword}><FormattedMessage id="loginpage.button.forgotpassword" /></a>
                                    <a className="text-secondary" onClick={this.handleShowImpressum} name="impressum" tabIndex="5  "><FormattedMessage id="loginpage.button.impressum" /></a>
                                </Container>
                                <Container className="d-flex justify-content-end px-2 py-0">
                                    <div>
                                        <Button color="primary" className="mt-2 px-4" type="submit" tabIndex="3">
                                            <FormattedMessage id="loginpage.button.login" />
                                        </Button>
                                    </div>
                                </Container>
                            </Col>
                        </Row>
                        <Row className="py-2 bottom border-top mx-3 mt-5">
                            <Col xs="12" id="login-sponsor" className="d-flex flew-column justify-content-center">
                                <img src={require('../assets/images/Hessen_Logo_k.png')} alt={this.props.intl.formatMessage({ id: "loginpage.alt.hessen" })} />
                                <img src={require('../assets/images/Berufsorientierung_Logo_k.png')} alt={this.props.intl.formatMessage({ id: "loginpage.alt.berufsorientierung" })} />
                                <img src={require('../assets/images/Bildungsketten_Logo_k.png')} alt={this.props.intl.formatMessage({ id: "loginpage.alt.bildungsketten" })} />
                                <img src={require('../assets/images/Bundesministerium_BuF_Logo_k.png')} alt={this.props.intl.formatMessage({ id: "loginpage.alt.bundesministerium" })} />
                                <img src={require('../assets/images/Agentur_Arbeit_Logo_k.png')} alt={this.props.intl.formatMessage({ id: "loginpage.alt.bundesagentur" })} />
                                <img src={require('../assets/images/BWHW_k.png')} alt={this.props.intl.formatMessage({ id: "loginpage.alt.bwhw" })} />
                            </Col>
                        </Row>
                        <Row className="p-0 my-0 mx-3">
                            <Col xs="12" className="text-center small">
                                <FormattedMessage id="loginpage.label.logos" />
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <Modal isOpen={this.state.impressumVisible} toggle={this.handleShowImpressum} size="lg" backdrop={'static'}>
                    <ModalHeader toggle={this.handleShowImpressum}><FormattedMessage id="loginpage.modaltitle.impressum" /></ModalHeader>
                    <ModalBody>
                        <ImpressumPage />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleShowImpressum} name="close"><FormattedMessage id="button.close" /></Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        loginError: state.auth.loginError,
        sendResetPwdMailError: state.auth.sendResetPwdMailError,
        sendResetPwdMailSuccess: state.auth.sendResetPwdMailSuccess,
        isSetPrivacypolicy: state.auth.isSetPrivacypolicy
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchLogin: (login, password) => {
            dispatch(loginActions.login(login, password));
        },
        dispatchLogout: () => {
            dispatch(loginActions.logout());
        },
        dispatchPrivacypolicy: (token) => {
            dispatch(loginActions.setPrivacypolicy(token));
        },
        dispatchSendResetPwdMail: (login) => {
            dispatch(loginActions.sendResetPasswordMail(login));
        }
    };
};

export default injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage)));