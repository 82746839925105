import * as React from 'react';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime, FormattedRelativeTime } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Label, FormText, Alert } from 'reactstrap';
import Confirm from './Confirm';
import { inquiryService } from '../services';
import config from '../config/config';
import SchoolSelect from './SchoolSelect';
import DisplaySchool from './DisplaySchool';
import { schoolsActions } from '../actions';
import moment from 'moment';
import { replaceQuotationMarks, getCaretPos, setCaretPos } from '../utils/Tools';

class ExecutionsInquiryModal extends React.Component {

    static propTypes = {
        execution: PropTypes.object,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        save: PropTypes.func,
        edit: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            execution: {},
            school: {},
            open: false,
            neededobserver: 1,
            text: "",
            existInquiry: null,
            invalid: { schoolId: false },
            alertVisible: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.execution && prevProps.execution !== this.props.execution) {
            this.setState({ existInquiry: null, text: "", neededobserver: 1, invalid: { schoolId: false }, school: {} });
            this.props.dispatchGetSchools(this.props.auth.token);
        }
    }

    componentDidMount() {

    }

    createNeededobserver() {
        let items = [];
        for (let index = 1; index <= 10; index++) {
            items.push(<option key={index} value={index} >{index}</option>);
        }
        return items;
    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            this.props.toggle();
        } else if (type === "send") {
            if (this.state.school && this.state.school.id >= 0) {
                this.toggleConfirm();
            } else {
                this.setState({ invalid: { schoolId: true }, alertVisible: true });
                setTimeout(this.handleHideError, config.alertTimeOut);

            }
        }
    }

    handleNeededObserver = async (e) => {
        this.setState({ neededobserver: parseInt(e.target.value, 10) })
    }

    handleText = async (e) => {
        const cp = getCaretPos(e.target);
        const domEle = e.target;
        this.setState({ text: replaceQuotationMarks(e.target.value) }, () => { setCaretPos(domEle, cp); })
    }


    toggleConfirm = async (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    send = (e) => {
        if (this.props.auth && this.props.execution) {
            inquiryService.createInquiry(this.props.auth.token, { executions_id: this.props.execution.id, schools_id: this.state.school.id, neededobserver: this.state.neededobserver, text: this.state.text, });
            this.toggleConfirm();
            this.props.toggle();
        }
    }

    handleHideError = (e) => {
        this.setState({ alertVisible: false });
    };

    handleSchool = async (school) => {
        await this.setState({ school: school || {}, invalid: { schoolId: school && school.id !== -1 ? false : true }, existInquiry: null });

        if (school) {
            let data = await inquiryService.getInquiryBySchoolIdExecutionId(this.props.auth.token, school.id, this.props.execution.id, "send", true, 1, 999999999);
            if (data && data.data) {
                this.setState({ existInquiry: data.data[0] });
            }
        }
    }

    render() {

        let startdatetime = this.props.execution && this.props.execution.start ? moment(this.props.execution.start.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let enddatetime = this.props.execution && this.props.execution.end ? moment(this.props.execution.end.replace(" ", "T"), config.dateFormat.format, true) : moment();
        return (
            <Container>
                <Alert isOpen={this.state.alertVisible} toggle={this.handleHideError} color="danger" className="fixed-top text-center"><FormattedMessage id="executioninquirymodal.alert.send" /></Alert>

                <Confirm no={this.toggleConfirm} yes={this.send} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.inquiry.send" bodytext="confirm.body.inquiry.send" />
                <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} className="modal-height-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                    <ModalHeader ><FormattedMessage id="executioninquirymodal.titel.inquiry" /></ModalHeader>

                    <ModalBody>
                        <Container fluid className="pb-3 px-3">
                            <Row>
                                <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                    <FormattedMessage id="executioninquirymodal.label.school" />
                                </Col>
                                <Col className="p-1" xs="6" sm="6" md="9">
                                    {this.props.execution && this.props.execution.school && <DisplaySchool school={this.props.execution.school} name="ei" />}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                    <FormattedMessage id="executioninquirymodal.label.startdate" />
                                </Col>
                                <Col className="p-1" xs="6" sm="6" md="3">
                                    <FormattedDate value={startdatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={startdatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                </Col>
                                <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                    <FormattedMessage id="executioninquirymodal.label.enddate" />
                                </Col>
                                <Col className="p-1" xs="6" sm="6" md="3">
                                    <FormattedDate value={enddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={enddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="border-top p-0 pt-3">
                            <Row>
                                <Col xs="12" md="6">
                                    <div className="mb-1">
                                        { /* Select School */}
                                        <SchoolSelect feedbacklabel="executioninquirymodal.feedback.school" schools={this.props.schools} handleSchool={this.handleSchool.bind(this)} schoolId={this.state.school ? this.state.school.id : -1} filter={this.props.execution && this.props.execution.school ? [this.props.execution.school.id] : []} className="m-0 p-0" label="executioninquirymodal.label.school" withEmpty invalid={this.state.invalid.schoolId} />
                                        {
                                            this.state.existInquiry &&
                                            <FormText className="ps-1" style={{ display: 'block' }}>
                                                <FormattedMessage id="executioninquirymodal.label.inquirysenton" /> <FormattedDate value={moment(this.state.existInquiry.send.replace(" ", "T"), config.dateFormat.format, true)} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={moment(this.state.existInquiry.send.replace(" ", "T"), config.dateFormat.format, true)} hour={config.dateFormat.hour} minute={config.dateFormat.minute} />{/* {" ("}<FormattedRelativeTime value={Date.now() - moment(this.state.existInquiry.send.replace(" ", "T"), config.dateFormat.format, true).date()} />{")"} */}
                                            </FormText>
                                        }
                                        {
                                            this.state.existInquiry && this.state.existInquiry.rejected &&
                                            <FormText className="ps-1" style={{ display: 'block' }}>
                                                <div className="text-danger"><FormattedMessage id="executioninquirymodal.label.inquiryrejectedon" /> <FormattedDate value={moment(this.state.existInquiry.rejected.replace(" ", "T"), config.dateFormat.format, true)} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={moment(this.state.existInquiry.rejected.replace(" ", "T"), config.dateFormat.format, true)} hour={config.dateFormat.hour} minute={config.dateFormat.minute} />{/* {" ("}<FormattedRelativeTime value={moment(this.state.existInquiry.rejected.replace(" ", "T"), config.dateFormat.format, true)} />{")"} */}</div>
                                            </FormText>
                                        }
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <Label className="fw-bold" for="neededobserver"><FormattedMessage id="executioninquirymodal.label.neededobserver" /></Label>
                                    <Input bsSize="sm" type="select" name="neededobserver" id="neededobserver" value={this.state.neededobserver} onChange={this.handleNeededObserver}>
                                        {this.createNeededobserver()}
                                    </Input>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="mt-2">
                                    <Label className="fw-bold" for="text"><FormattedMessage id="executioninquirymodal.label.message" /> </Label>
                                    <Input type="textarea" name="text" id="text" value={this.state.text} onChange={this.handleText} />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.cancel" /></Button>
                        <Button type="submit" color="primary" onClick={this.handleSubmit.bind(this, "send")}><FormattedMessage id="button.send" /></Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schools: state.schools.schools ? state.schools.schools : [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchools: (token) => {
            dispatch(schoolsActions.getSchools(token, "name", false, null, null, null));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionsInquiryModal));