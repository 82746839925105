import { competencesService } from '../services';
import { actionTypes } from './types';

export const competencesActions = {
    getCompetences,
    getCompetencesStudentObserver,
    createUsersCompetences,
    getStudentCompetencesStudentObserver,
    checkOutUsersCompetences,
    checkInUsersCompetences,
    getCompetencesKompog,
    createUsersCompetencesKompog
};

function getCompetences(token, executionId, studentsId, honorarkraftId, order, desc, enabled) {
    return dispatch => {
        dispatch(request({ getCompetences }));

        competencesService.getCompetences(token, executionId, studentsId, honorarkraftId, order, desc, enabled)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(competences) { return { type: actionTypes.COMPETENCES_REQUEST, competences } }
    function success(competences) { return { type: actionTypes.COMPETENCES_SUCCESS, competences } }
    function failure(error) { return { type: actionTypes.COMPETENCES_FAILURE, error } }
}

function getCompetencesKompog(token, executionId, studentsId) {
    return dispatch => {
        dispatch(request({ getCompetencesKompog }));

        competencesService.getCompetencesKompog(token, executionId, studentsId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(competences) { return { type: actionTypes.COMPETENCES_REQUEST, competences }; }
    function success(competences) { return { type: actionTypes.COMPETENCES_SUCCESS, competences }; }
    function failure(error) { return { type: actionTypes.COMPETENCES_FAILURE, error }; }
}

function getCompetencesStudentObserver(token, executionId, studentsId, honorarkraftId, order, desc, enabled) {
    return dispatch => {
        dispatch(request({ getCompetencesStudentObserver }));

        competencesService.getCompetencesStudentObserver(token, executionId, studentsId,honorarkraftId, order, desc, enabled)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(competences) { return { type: actionTypes.COMPETENCES_STUDENT_OBSERVER_REQUEST, competences } }
    function success(competences) { return { type: actionTypes.COMPETENCES_STUDENT_OBSERVER_SUCCESS, competences } }
    function failure(error) { return { type: actionTypes.COMPETENCES_STUDENT_OBSERVER_FAILURE, error } }
}

function getStudentCompetencesStudentObserver(token, executionId, order, desc, enabled) {
    return dispatch => {
        dispatch(request({ getStudentCompetencesStudentObserver }));

        competencesService.getStudentCompetencesStudentObserver(token, executionId, order, desc, enabled)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(competences) { return { type: actionTypes.COMPETENCES_STUDENT_OBSERVER_REQUEST, competences } }
    function success(competences) { return { type: actionTypes.COMPETENCES_STUDENT_OBSERVER_SUCCESS, competences } }
    function failure(error) { return { type: actionTypes.COMPETENCES_STUDENT_OBSERVER_FAILURE, error } }
}

function createUsersCompetences(token, executionId, studentId, honorarkraftId, rating) {
    return dispatch => {
        dispatch(request({ createUsersCompetences }));

        competencesService.createUsersCompetences(token, executionId, studentId, honorarkraftId, rating)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.COMPETENCES_CREATE_USERS_REQUEST, status } }
    function success(status) { return { type: actionTypes.COMPETENCES_CREATE_USERS_SUCCESS, status } }
    function failure(status) { return { type: actionTypes.COMPETENCES_CREATE_USERS_FAILURE, status } }
}

function createUsersCompetencesKompog(token, executionId, studentId, honorarkraftId, competences) {
    return dispatch => {
        dispatch(request({ createUsersCompetencesKompog }));

        competencesService.createUsersCompetencesKompog(token, executionId, studentId, honorarkraftId, competences)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.COMPETENCES_CREATE_USERS_KOMPOG_REQUEST, status } }
    function success(status) { return { type: actionTypes.COMPETENCES_CREATE_USERS_KOMPOG_SUCCESS, status } }
    function failure(status) { return { type: actionTypes.COMPETENCES_CREATE_USERS_KOMPOG_FAILURE, status } }
}

function checkOutUsersCompetences(token, executionId, studentId, editorId) {
    return dispatch => {
        dispatch(request({ checkOutUsersCompetences }));

        competencesService.checkOutUsersCompetences(token, executionId, studentId, editorId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(response) { return { type: actionTypes.COMPETENCES_CHECK_OUT_REQUEST, response } }
    function success(response) { return { type: actionTypes.COMPETENCES_CHECK_OUT_SUCCESS, response } }
    function failure(response) { return { type: actionTypes.COMPETENCES_CHECK_OUT_FAILURE, response } }
}

function checkInUsersCompetences(token, executionId, studentId, editorId) {
    return dispatch => {
        dispatch(request({ checkInUsersCompetences }));

        competencesService.checkInUsersCompetences(token, executionId, studentId, editorId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(response) { return { type: actionTypes.COMPETENCES_CHECK_IN_REQUEST, response } }
    function success(response) { return { type: actionTypes.COMPETENCES_CHECK_IN_SUCCESS, response } }
    function failure(response) { return { type: actionTypes.COMPETENCES_CHECK_IN_FAILURE, response } }
}
