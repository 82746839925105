import { loginService } from '../services';
import { actionTypes } from './types';

export const loginActions = {
    login,
    logout,
    sendResetPasswordMail,
    setPrivacypolicy
};

function login(login, password) {
    return dispatch => {
        dispatch(request({ login }));

        loginService.login(login, password)
            .then(
            response => {               
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
            }
            );
    };

    function request(login) { return { type: actionTypes.LOGIN_REQUEST, login } }
    function success(auth) { return { type: actionTypes.LOGIN_SUCCESS, auth } }
    function failure(error) { return { type: actionTypes.LOGIN_FAILURE, error } }
}

function logout() {
    loginService.logout();
    return { type: actionTypes.LOGOUT_SUCCESS };
}

function sendResetPasswordMail(login) {
    return dispatch => {
        dispatch(request({ login }));

        loginService.sendResetPasswordMail(login)
            .then(
            response => {  
                dispatch(success());
            },
            error => {
                dispatch(failure(error));
            }
            );
    };

    function request(login) { return { type: actionTypes.SEND_RESET_PWD_MAIL_REQUEST, login } }
    function success() { return { type: actionTypes.SEND_RESET_PWD_MAIL_SUCCESS } }
    function failure(error) { return { type: actionTypes.SEND_RESET_PWD_MAIL_FAILURE, error } }
}

function setPrivacypolicy(token) {
    return dispatch => {
        dispatch(request());

        loginService.setPrivacypolicy(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.PRIVACYPOLICY_REQUEST } }
    function success(response) { return { type: actionTypes.PRIVACYPOLICY_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.PRIVACYPOLICY_FAILURE, error } }
}