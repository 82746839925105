import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Label, Button, Alert } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import ExecutionsPastCheckbox from '../components/ExecutionsPastCheckbox';
import SchoolSelect from '../components/SchoolSelect';
import StudentsButtons from '../components/StudentsButtons';
import Observations from '../components/Observations';
import ExternalAssessment from '../components/ExternalAssessment';
import { schoolsActions, filterActions, executionsActions, observationsActions, competencesActions } from '../actions';
import HonorarkraftSelect from '../components/HonorarkraftSelect';
import Authorization from '../components/Authorization';
import cloneDeep from 'lodash/cloneDeep';
import config from '../config/config';
import Loading from '../components/Loading';
import { isEquivalent, replaceQuotationMarks, getCaretPos, setCaretPos } from '../utils/Tools'

class ObservationsEvaluationsPage extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);
        this.state = {
            text: '',
            withAlert: false,
            alert: { visible: false, textId: "alert.save", color: "success" },
            rating: [],
        }
        this.callFunctionAfterSave = { func: null, parameter: null };
    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        if (this.props.executionId && this.props.executionId > 0) {
            this.props.dispatchGetExecutionsHonorarkraft(this.props.auth.token, this.props.executionId);
        }

        if (this.props.executionId > -1 && this.props.studentId > -1) {
            this.props.dispatchGetObservation(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
            this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
        }

        this.props.history.block((location, action) => {
            this.callFunctionAfterSave = { func: this.goToNextPage.bind(this), parameter: location };
            this.doSave(true);
        });
    }

    goToNextPage(location) {
        //check in
        if (this.props.executionId && this.props.studentId && this.props.checkOutStatus) {
            this.props.dispatchCheckInUsersCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.auth.user_id);
        }

        //Remove the confirm dialog          
        this.props.history.block((location, action) => {
            return true;
        });
        //push the new page
        this.props.history.push(location);

    }

    componentDidUpdate(prevProps) {

        if (this.props.executionId !== prevProps.executionId) {
            if (this.props.executionId > 0) {
                this.props.dispatchGetExecutionsHonorarkraft(this.props.auth.token, this.props.executionId);
                this.props.dispatchHonorarkraft(null);
            } else {
                this.props.dispatchHonorarkraft(null);
            }
        }
        if (this.props.studentId > -1 && this.props.studentId !== prevProps.studentId) {
            if (this.props.executionId > -1 && this.props.studentId > -1) {
                this.props.dispatchGetObservation(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
                this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
            }
        }

        if (this.props.observations !== prevProps.observations && this.props.observations) {
            this.setState({ text: this.props.observations.text });
        }

        if (this.props.rating !== prevProps.rating && this.props.rating) {
            this.setState({ rating: this.props.rating });
        }

        //if saved successfully
        if (prevProps.observationsCreateStatus !== this.props.observationsCreateStatus
            || prevProps.observationsUpdateStatus !== this.props.observationsUpdateStatus
            || prevProps.observationsError !== this.props.observationsError
        ) {
            if ((this.props.observationsCreateStatus && this.props.observationsCreateStatus.ok) ||
                (this.props.observationsUpdateStatus && this.props.observationsUpdateStatus.ok)) {
                //call saved function
                if (typeof this.callFunctionAfterSave.func === "function") {
                    if (this.callFunctionAfterSave.parameter !== null) {
                        this.callFunctionAfterSave.func(this.callFunctionAfterSave.parameter);
                    } else {
                        this.callFunctionAfterSave.func();
                    }

                }
                this.callFunctionAfterSave = { func: null, parameter: null };
                if (this.state.withAlert) {
                    this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                    setTimeout(this.handleHideAlert, config.alertTimeOut);
                }

            } else if ((this.props.observationsCreateStatus && !this.props.observationsCreateStatus.ok && this.props.observationsError && prevProps.observationsError !== this.props.observationsError) ||
                (this.props.observationsUpdateStatus && !this.props.observationsUpdateStatus.ok && this.props.observationsError && prevProps.observationsError !== this.props.observationsError)) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }
        }

        if (prevProps.competencesCreateStatus !== this.props.competencesCreateStatus && this.props.competencesCreateStatus === 'save-error') {
            this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
            setTimeout(this.handleHideAlert, config.alertTimeOut);
        }

        if (this.props.loadingCheckOut !== prevProps.loadingCheckOut && this.props.loadingCheckOut === false) {
            if (this.props.checkOutStatus === 'checkout-error') {
                this.setState({ alert: { visible: true, textId: "alert.checkout-error", color: "danger" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            } else if (this.props.checkOutStatus === 'checkout-complete') {
                this.setState({ alert: { visible: true, textId: "alert.checkout-complete", color: "success" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);

                if (!this.props.checkOut && this.props.studentId > -1 && this.props.executionId > -1 && this.props.studentId > -1) {
                    this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
                }
            }
        }
    }

    handlePastExecution = () => {
        //if not saved straight
        if (!this.props.saving) {
            if ((this.props.checkOut || this.props.observationsevaluations === 1) && this.props.studentId !== -1) {
                this.callFunctionAfterSave = { func: this.props.dispatchPastExecutions, parameter: null };
                this.doSave(true);
            } else {
                this.props.dispatchPastExecutions();
            }
        }
    }

    handleSchool = (school) => {
        //if not saved straight
        if (!this.props.saving) {
            if ((this.props.checkOut || this.props.observationsevaluations === 1) && this.props.studentId !== -1) {
                this.callFunctionAfterSave = { func: this.props.dispatchSchool, parameter: school };
                this.doSave(true);
            } else {
                this.props.dispatchSchool(school);
            }
        }
    }

    handleExecution = (execution) => {
        //if not saved straight
        if (!this.props.saving) {
            if ((this.props.checkOut || this.props.observationsevaluations === 1) && this.props.studentId !== -1) {
                this.callFunctionAfterSave = { func: this.props.dispatchExecution, parameter: execution };
                this.doSave(true);
            } else {
                this.props.dispatchExecution(execution);
            }
        }
    }

    handleHonorarkraft = (honorarkraft) => {
        //if not saved straight
        if (!this.props.saving) {
            if ((this.props.checkOut || this.props.observationsevaluations === 1) && this.props.studentId !== -1) {
                this.callFunctionAfterSave = { func: this.props.dispatchHonorarkraft, parameter: honorarkraft };
                this.doSave(true);
            } else {
                this.props.dispatchHonorarkraft(honorarkraft);
            }
        }
    }

    handleStudent = (student) => {
        //if not saved straight
        if (!this.props.saving) {
            if ((this.props.checkOut || this.props.observationsevaluations === 1) && this.props.studentId !== -1) {
                this.callFunctionAfterSave = { func: this.props.dispatchStudent, parameter: student };
                this.doSave(true);
            } else {
                this.props.dispatchStudent(student);
            }
        }
    }

    handleObservationsevaluations = (value) => {
        //if not saved straight
        if (!this.props.saving) {
            if ((this.props.checkOut || this.props.observationsevaluations === 1) && this.props.studentId !== -1) {
                this.callFunctionAfterSave = { func: this.props.dispatchObservationsevaluations, parameter: value };
                this.doSave(true);
            } else {
                this.props.dispatchObservationsevaluations(value);
            }
        }
    }


    handleSave = () => {
        //if not saved straight
        if (!this.props.saving) {
            this.doSave(false);
        }
    }

    handleCheckOut = () => {
        if (!this.props.saving) {
            this.props.dispatchCheckOutUsersCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.auth.user_id);
        }
    }

    doSave = (autosave) => {

        //if not saved straight
        if (!this.props.saving) {
            //if execution and a student selected
            if (this.props.executionId > -1 && this.props.studentId > -1 && this.props.editable) {
                //no autosave if a honorarkraft selected
                if (!(autosave && this.props.honorarkraftId > -1)) {

                    const text = cloneDeep(this.state.text);
                    const rating = cloneDeep(this.state.rating);
                    const props = cloneDeep(this.props);
                    this.setState({ withAlert: !autosave });
                    //observations
                    if (this.props.observationsevaluations === 1) {
                        //save only if the text has changed or manual save
                        if (props.observations && (props.observations.text !== this.state.text || !autosave)) {
                            if (props.create) {
                                this.props.dispatchCreateObservation(props.auth.token, props.executionId, props.studentId, text);
                            } else {
                                this.props.dispatchUpdateObservation(props.auth.token, props.executionId, props.studentId, text);
                            }

                        }
                        //competences
                    } else if (this.props.observationsevaluations === 2) {
                        if (!isEquivalent(props.rating, this.state.rating) || !autosave) {
                            this.props.dispatchCreateUsersCompetences(props.auth.token, props.executionId, props.studentId, props.honorarkraftId, rating);
                        }
                    }
                }
            }

            //if there was no attempt to save
            if (typeof this.callFunctionAfterSave.func === "function") {
                if (this.callFunctionAfterSave.parameter !== null) {
                    this.callFunctionAfterSave.func(this.callFunctionAfterSave.parameter);
                } else {
                    this.callFunctionAfterSave.func();
                }
            }
            this.callFunctionAfterSave = { func: null, parameter: null };
        }

    }

    handleObservationsText = (e) => {
        const cp = getCaretPos(e.target);
        const domEle = e.target;
        this.setState({ text: replaceQuotationMarks(e.target.value) }, () => { setCaretPos(domEle, cp); });
    }

    handleHideAlert = (e) => {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    };

    handleCheckbox = (id, value) => {
        let newState = cloneDeep(this.state);
        let changed = false;

        newState.rating.forEach(rating => {
            if (rating && rating.competences_id === id) {
                // if the checkbox is already checked
                // remove the rating
                if (rating.rating === value || rating.rating2 === value) {
                    if (rating.rating === value) rating.rating = 0;
                    if (rating.rating2 === value) rating.rating2 = 0;
                    changed = true;
                } else {
                    // otherwise check for allowed set
                    if (value === 1) {
                        if (rating.rating === 0 || rating.rating === 2) { rating.rating = 1; changed = true; }
                        else if (rating.rating2 === 0 || rating.rating2 === 2) { rating.rating2 = 1; changed = true; }
                    } else if (value === 2) {
                        if (rating.rating === 0 || rating.rating === 1) { rating.rating = 2; changed = true; }
                        else if (rating.rating2 === 0 || rating.rating2 === 1) { rating.rating2 = 2; changed = true; }
                    } else if (value === 3) {
                        if (rating.rating === 0 || rating.rating === 4) { rating.rating = 3; changed = true; }
                        else if (rating.rating2 === 0 || rating.rating2 === 4) { rating.rating2 = 3; changed = true; }
                    } else if (value === 4) {
                        if (rating.rating === 0 || rating.rating === 3) { rating.rating = 4; changed = true; }
                        else if (rating.rating2 === 0 || rating.rating2 === 3) { rating.rating2 = 4; changed = true; }
                    }
                }
            }
        });

        if (!changed) {
            newState.rating.push({ competences_id: id, observations: '', rating: value, rating2: 0 });
        }

        this.setState(newState);
    }



    handleCompetencesObservation = (id, e) => {
        let newState = cloneDeep(this.state);
        let changed = false;

        newState.rating.forEach(rating => {
            if (rating && rating.competences_id === id) {
                rating.observations = replaceQuotationMarks(e.target.value)

                // if the observations text is empty remove the ratings 
                if (rating.observations.length === 0) {
                    rating.rating = 0;
                    rating.rating2 = 0;
                }
                changed = true;
            }
        });

        if (!changed) {
            newState.rating.push({ competences_id: id, observations: replaceQuotationMarks(e.target.value), rating: 0, rating2: 0 });
        }
        const cp = getCaretPos(e.target);
        const domEle = e.target;
        this.setState(newState, () => { setCaretPos(domEle, cp); });
    }

    render() {
        return (
            <Container fluid role="main" className="content-margin">

                <Alert
                    isOpen={this.state.alert.visible}
                    toggle={this.handleHideAlert}
                    color={this.state.alert.color}
                    className="fixed-top text-center">
                    <FormattedMessage id={this.state.alert.textId} />
                </Alert>

                <h1 ><FormattedMessage id="observationsevaluations.label.observationsevaluations" /></h1>
                <Row>
                    <Col xs="12" className="my-3">
                        <ExecutionsPastCheckbox handlePast={this.handlePastExecution.bind(this)} past={this.props.pastExecution} />
                    </Col>
                </Row>
                { /* Select School / Execution */}
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3" className={this.props.schools.length > 1 ? "" : "d-none"}>
                        <SchoolSelect className="mb-3 p-0" withEmpty label="observationsevaluations.label.school" schools={this.props.schools} handleSchool={this.handleSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3">
                        <ExecutionSelect className="mb-3 p-0" withEmpty label="observationsevaluations.label.execution" executionId={this.props.executionId} schoolId={this.props.schoolId} onlyInvolved handleExecution={this.handleExecution.bind(this)} onlyKompo7={true} />
                    </Col>
                    <Authorization id="observations-evaluations-honorarkraftselect">
                        <Col xs="12" sm="6" md="6" lg="6" xl="3">
                            <HonorarkraftSelect className={this.props.executionId > 0 && this.props.honorarkraft.length > 0 ? "mb-3 p-0" : "d-none"} noAutoSave withEmpty label="observationsevaluations.label.honorarkraft" honorarkraft={this.props.honorarkraft} handleHonorarkraft={this.handleHonorarkraft.bind(this)} honorarkraftId={this.props.honorarkraftId} />
                        </Col>
                    </Authorization>
                </Row>

                {this.props.honorarkraftId > 0 &&
                    <Row>
                        <Col className="small pb-3 text-danger" xs="12"><FormattedMessage id='observationsevaluations.noautosave' /></Col>
                    </Row>
                }
                {/* Button observations and conferences */}
                <Row>
                    <Col xs="12" sm="6" className="text-center radiobutton-container">
                        <input type="radio" className="m-0 p-0 radiobutton-invert-input" disabled={this.props.saving} checked={this.props.observationsevaluations === 1} name="btnObservatinsConference" id="btnObservations" onChange={this.handleObservationsevaluations.bind(this, 1)} />
                        <Label for="btnObservations" className="radiobutton-invert" ><FormattedMessage id="observationsevaluations.button.observations" /></Label>
                    </Col>
                    <Col xs="12" sm="6" className="text-center radiobutton-container">
                        <input type="radio" className="m-0 p-0 radiobutton-invert-input" disabled={this.props.saving} checked={this.props.observationsevaluations === 2} name="btnObservatinsConference" id="btnConferences" onChange={this.handleObservationsevaluations.bind(this, 2)} />
                        <Label for="btnConferences" className="radiobutton-invert" ><FormattedMessage id="observationsevaluations.button.conferences" /></Label>
                    </Col>
                </Row>

                { /* Students */}
                <StudentsButtons className="mt-4" disabled={this.props.loading} handleStudent={this.handleStudent.bind(this)} editable={(this.props.editable && !this.props.saving && !this.props.loading)} />
                { /* Observations */}
                {
                    this.props.observationsevaluations === 1 && this.props.studentId > -1 && !this.props.loading &&
                    <Observations
                        text={this.state.text}
                        handleText={this.handleObservationsText.bind(this)}
                        editable={(this.props.editable && !this.props.saving && this.props.honorarkraftId === -1)} />
                }
                { /* Evalulations */}
                {
                    this.props.observationsevaluations === 2 && this.props.competences.length > 0 && this.props.studentId > -1 && !this.props.loading &&
                    <ExternalAssessment
                        competences={this.props.competences}
                        modified={this.props.modified}
                        editable={(this.props.editable && !this.props.saving && this.props.checkOut && this.props.checkOutUserId === this.props.auth.user_id)}
                        checkOutPossible={this.props.editable && !this.props.saving}
                        rating={this.state.rating}
                        observations={this.props.observations}
                        handleCheckbox={this.handleCheckbox.bind(this)}
                        handleObservation={this.handleCompetencesObservation.bind(this)}
                        handleCheckOut={this.handleCheckOut.bind(this, true)}
                        handleSave={this.handleSave.bind(this, true)}
                        checkOutDateTime={this.props.checkOutDatetime}
                        checkOutUserName={this.props.checkOutUserName}
                    />
                }
                {
                    this.props.loading && <div style={{ height: "14.5rem", width: '100%' }}><Loading /></div>
                }
                {
                    this.props.studentId > -1 &&
                    <Container className="d-flex justify-content-end p-0" fluid>
                        <Button type="submit"
                            disabled={!(this.props.editable && !this.props.saving && !this.props.loading && ((this.props.honorarkraftId === -1 && this.props.observationsevaluations === 1) || (this.props.observationsevaluations === 2 && this.props.checkOut)))}
                            color="primary"
                            className="mt-3"
                            onClick={this.handleSave.bind(this, true)}>
                            <FormattedMessage id="button.save" />
                        </Button>
                    </Container>
                }
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pastExecution: state.filter.executions_past,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        studentId: state.filter.student && state.filter.student.id ? state.filter.student.id : -1,
        observationsevaluations: state.filter.observationsevaluations,
        honorarkraft: state.executions.honorarkraft ? state.executions.honorarkraft : [],
        honorarkraftId: state.filter.honorarkraft ? state.filter.honorarkraft.id : -1,
        loading: state.observations.loading || state.competences.loading || state.executions.loadingstudentsforuser || state.executions.loading || state.executions.loadingHonorarkraft || state.competences.loadingCreateCompetences,
        saving: state.observations.saving || state.competences.saving,
        observations: state.observations.observations,
        editable: (state.filter.observationsevaluations === 1 && state.observations.editable) || (state.filter.observationsevaluations === 2 && state.competences.editable),
        observationsError: state.observations.observationsError === '' ? true : false,
        observationsUpdateStatus: state.observations.updateStatus,
        observationsCreateStatus: state.observations.createStatus,
        competences: state.competences.competences,
        rating: state.competences.rating,
        competencesCreateStatus: state.competences.createStatus,
        modified: state.competences.modified,
        create: state.observations.create,
        checkOut: state.competences.checkOut,
        checkOutUserId: state.competences.checkOutUserId,
        checkOutUserName: state.competences.checkOutUserName,
        checkOutDatetime: state.competences.checkOutDatetime,
        checkOutStatus: state.competences.checkOutStatus,
        loadingCheckOut: state.competences.loadingCheckOut
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchObservationsevaluations: (observationsevaluations) => {
            dispatch(filterActions.observationsevaluations(observationsevaluations));
        },
        dispatchGetExecutionsHonorarkraft: (token, executionId) => {
            dispatch(executionsActions.getExecutionsHonorarkraft(token, executionId, "lastname", false, null));
        },
        dispatchHonorarkraft: (honorarkraft) => {
            dispatch(filterActions.honorarkraft(honorarkraft));
        },
        dispatchPastExecutions: () => {
            dispatch(filterActions.executions_past());
        },
        dispatchExecution: (execution) => {
            dispatch(filterActions.execution(execution));
        },
        dispatchStudent: (student) => {
            dispatch(filterActions.student(student));
        },
        dispatchGetObservation: (token, executionId, studentId, honorarkraftId) => {
            dispatch(observationsActions.getObservation(token, executionId, studentId, honorarkraftId));
        },
        dispatchCreateObservation: (token, executionId, studentId, text) => {
            dispatch(observationsActions.createObservation(token, executionId, studentId, text));
        },
        dispatchUpdateObservation: (token, executionId, studentId, text) => {
            dispatch(observationsActions.updateObservation(token, executionId, studentId, text));
        },
        dispatchGetCompetences: (token, executionId, studentId, honorarkraftId) => {
            dispatch(competencesActions.getCompetences(token, executionId, studentId, honorarkraftId, "", false, true));
        },
        dispatchCreateUsersCompetences: (token, executionId, studentId, honorarkraftId, rating) => {
            dispatch(competencesActions.createUsersCompetences(token, executionId, studentId, honorarkraftId, rating));
        },
        dispatchCheckOutUsersCompetences: (token, executionId, studentId, editorId) => {
            dispatch(competencesActions.checkOutUsersCompetences(token, executionId, studentId, editorId));
        },
        dispatchCheckInUsersCompetences: (token, executionId, studentId, editorId) => {
            dispatch(competencesActions.checkInUsersCompetences(token, executionId, studentId, editorId));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ObservationsEvaluationsPage));