import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class HonorarkraftSelect extends React.Component {


    static propTypes = {
        honorarkraftId: PropTypes.number,
        handleHonorarkraft: PropTypes.func,
        honorarkraft: PropTypes.array,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        withEmpty: PropTypes.bool,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        filter: PropTypes.array,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        honorarkraft: [],
        honorarkraftId: -1,
        withEmpty: false,
        invalid: false,
        filter: [],
        disabled: false,
        label: "honorarkraftselect.label.default"
    };

    constructor(props) {
        super(props);
        this.state = {
            honorarkraft: [],
        }
    }

    componentDidMount() {
        if (this.props.honorarkraft.length > 0) {
            this.setState({ honorarkraft: this.props.honorarkraft.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });
        } else {
            this.setState({ honorarkraft: [] })
        }
        if (!this.props.withEmpty && this.props.honorarkraftId === -1 && this.props.honorarkraft.length > 0) {
            this.props.handleHonorarkraft(this.props.honorarkraft[0]);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.honorarkraft !== this.props.honorarkraft) {
            if (this.props.honorarkraft.length > 0) {
                this.setState({ honorarkraft: this.props.honorarkraft.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });
            } else {
                this.setState({ honorarkraft: [] })
            }
            if (!this.props.withEmpty && this.props.honorarkraftId === -1 && this.props.honorarkraft.length > 0) {
                this.props.handleHonorarkraft(this.props.honorarkraft[0]);
            }
        }
    }

    createHonorarkraftItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "honorarkraftselect.option.pleaseselect" })}</option>);
        this.state.honorarkraft.forEach((hk) => {
            items.push(<option key={hk.id} value={hk.id} >{hk.firstname + " " + hk.lastname}</option>);
        })
        return items;
    }

    handleHonorarkraft = async (e) => {
        let id = parseInt(e.target.value, 10);
        let hk = this.state.honorarkraft.filter(el => el.id === id)[0] || null;
        await this.props.handleHonorarkraft(hk);
    }

    render() {
        return (
            <Container fluid className={this.state.honorarkraft.length > 1 || this.props.withEmpty ? this.props.className : "d-none " + this.props.className}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "honorarkraftselect.label.default" ? "fw-bold" : "d-none"} for="honorarkraft"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled || this.props.saving} invalid={this.props.invalid} bsSize="sm" type="select" name="honorarkraft" id="honorarkraft" value={this.props.honorarkraftId} onChange={this.handleHonorarkraft}>
                        {this.createHonorarkraftItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        saving: state.observations.saving || state.competences.saving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(HonorarkraftSelect));