import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import LoginPage from './LoginPage';
import MainPage from './MainPage';
import Offline from '../components/Offline';
import { CheckAuthorization } from '../models/Authorization';

export class App extends Component {
    static propTypes = {
        // redux state
        token: PropTypes.string,
        // redux dispatch
        // ...
    };

    componentDidUpdate(prevProps) {
        if (prevProps.privacypolicy !== this.props.privacypolicy && this.props.privacypolicy === 1) {
            sessionStorage.setItem('user', JSON.stringify(this.props.user));
        }
    }

    render() {
        let page = <LoginPage />;
        if (this.props.token && (this.props.privacypolicy || CheckAuthorization("login-skip-privacy-policy", this.props.user.roles))) {
            page = <MainPage />;
        } else if (this.props.token && !this.props.privacypolicy) {
            page = <LoginPage privacypolicy />;
        }

        return (
            <Offline>
                <Container fluid>
                    {
                        page
                    }
                </Container>
            </Offline>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        privacypolicy: state.auth.privacypolicy,
        user: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App)));
