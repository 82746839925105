import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Table, Label } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import ProcedureSelect from '../components/ProcedureSelect';
import ExecutionsPastCheckbox from '../components/ExecutionsPastCheckbox';
import SchoolSelect from '../components/SchoolSelect';
import GeneratePDF from '../components/GeneratePDF';
import Loading from '../components/Loading';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import { schoolsActions, filterActions, executionsActions, competencesActions, skillsProfilesActions, kompoGResultActions } from '../actions';
import { generateResultsSchoolPDF } from '../pdf';
import { generateMyResultPDF } from '../pdf/MyResultsPDF';
import hookImg from '../assets/images/Haken.png';
import emptyImg from '../assets/images/Empty.png';


class ResultsSchoolPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: { visible: false, textId: "alert.save", color: "success" },
            order: "lastname",
            desc: false,
            enabled: true,
            pdfLoading: false,
            th: [
                { text: "results-school.th.firstname", order: "firstname", width: "15%" },
                { text: "results-school.th.lastname", order: "lastname", width: "15%" },
                { text: "results-school.th.class", order: "class", width: "10%" },
                { text: "results-school.th.approved", order: "approved", width: "14%" },
                { text: "results-school.th.selfassessment", order: "selfassessment", width: "20%" },
                { text: "results-school.th.interesttest", order: "interesttest", width: "16%" },
                { text: "results-school.th.action", className: "table-border-left-action", width: "10%" },
            ],
            tbshow: ["firstname", "lastname", "class", "icon_approved", "icon_selfassessment", "icon_interesttest"],
            tb: [],
            students: [],
            procedures: [],
            thKompog: [
                { text: "results.kompog.th.firstname", order: "firstname", width: "10%" },
                { text: "results.kompog.th.lastname", order: "lastname", width: "10%" },
                { text: "results.kompog.th.competences", width: "8%" },
                { text: "results.kompog.th.interests", width: "8%" },
                { text: "results.kompog.th.motivations", width: "8%" },
                { text: "results.kompog.th.results", width: "8%" },
                { text: "results.kompog.th.impulses", width: "8%" },
                { text: "results.kompog.th.recommendation.school", width: "8%" },
                { text: "results.kompog.th.actions", width: "10%" },
            ],
            tbKompog: [],
            tbshowKompog: ["firstname", "lastname", "icon_competences_completed", "icon_interests_completed", "icon_motivations_completed",
                "icon_results_completed", "icon_impulses_completed", "icon_feedbackTeacher"],
            pdf_kompoG_disabled: true,
        }
    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        if (this.props.executionProcedure === 1) {//Kompo7
            this.props.dispatchGetExecutionsResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, {});
            this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId);
        } else if (this.props.executionProcedure === 2) {//Kompog
            this.props.dispatchGetExecutionsResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, {});
            this.props.dispatchGetKompogResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.executionId !== this.props.executionId) {
            if (this.props.executionProcedure === 1) {//Kompo7
                this.props.dispatchGetExecutionsResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, {});
                this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId);
            } else if (this.props.executionProcedure === 2) {//Kompog
                this.props.dispatchGetExecutionsResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, {});
                this.props.dispatchGetKompogResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc);
            }
        }

        if (this.props.results !== prevProps.results) {
            if (this.props.executionProcedure === 1) {//Kompo7
                this.setState({ tb: this.convertDataToTableBodyFormat(this.props.results) });
            } else if (this.props.executionProcedure === 2) {//Kompog
                if (this.props.results.length > 0) {
                    let formattedData = this.convertDataToTableBodyFormatKompoG(this.props.results);
                    this.setState({ students: formattedData });
                } else {
                    this.setState({ students: [] });
                }
            }
        }

        if (prevProps.executions !== this.props.executions) {
            this.getExecutionProcedures(this.props.executions);
        }

        if (this.props.kompoGSaveRelease !== prevProps.kompoGSaveRelease) {
            if (this.props.kompoGSaveRelease) {
                this.props.dispatchGetExecutionsResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, {});
            }
        }

        if (this.props.studentResults !== prevProps.studentResults) {
            let pdf_kompoG_disabled = true;
            this.props.studentResults.forEach(s => {
                if (s.releaseTeacher) {
                    pdf_kompoG_disabled = false;
                }
            });
            this.setState({ pdf_kompoG_disabled: pdf_kompoG_disabled });
        }
    }

    getExecutionProcedures(executions) {
        executions.forEach(ex => {
            if (!this.state.procedures.some(item => item.id === ex.procedure.id)) {
                let array = this.state.procedures;
                array.push(ex.procedure);
                this.setState({ procedures: array });
            }
        });
    }

    convertDataToTableBodyFormat(results) {
        let resultsCopy = JSON.parse(JSON.stringify(results));
        for (let i = 0; i < resultsCopy.length; i++) {
            //set icon for approved
            if (resultsCopy[i].skillsProfile && resultsCopy[i].skillsProfile.approved === 1) {
                resultsCopy[i].icon_approved = hookImg;
            } else {
                resultsCopy[i].icon_approved = emptyImg;
            }
            //set icon for selfassessment
            if (resultsCopy[i].selfassessment === 1) {
                resultsCopy[i].icon_selfassessment = hookImg;
            } else {
                resultsCopy[i].icon_selfassessment = emptyImg;
            }
            //set icon for interesttest
            if (resultsCopy[i].interesttest === 1) {
                resultsCopy[i].icon_interesttest = hookImg;
            } else {
                resultsCopy[i].icon_interesttest = emptyImg;
            }
        }
        return resultsCopy;
    }

    convertDataToTableBodyFormatKompoG(results) {
        let studentsCopy = JSON.parse(JSON.stringify(results));
        for (let i = 0; i < studentsCopy.length; i++) {
            if (studentsCopy[i].competences_completed) {
                studentsCopy[i].icon_competences_completed = hookImg;
            } else {
                studentsCopy[i].icon_competences_completed = emptyImg;
            }
            if (studentsCopy[i].interests_completed) {
                studentsCopy[i].icon_interests_completed = hookImg;
            } else {
                studentsCopy[i].icon_interests_completed = emptyImg;
            }
            if (studentsCopy[i].motivations_completed) {
                studentsCopy[i].icon_motivations_completed = hookImg;
            } else {
                studentsCopy[i].icon_motivations_completed = emptyImg;
            }
            if (studentsCopy[i].results_completed) {
                studentsCopy[i].icon_results_completed = hookImg;
            } else {
                studentsCopy[i].icon_results_completed = emptyImg;
            }
            if (studentsCopy[i].impulses_completed) {
                studentsCopy[i].icon_impulses_completed = hookImg;
            } else {
                studentsCopy[i].icon_impulses_completed = emptyImg;
            }
            if (studentsCopy[i].feedbackTeacher !== "") {
                studentsCopy[i].icon_feedbackTeacher = hookImg;
            } else {
                studentsCopy[i].icon_feedbackTeacher = emptyImg;
            }
        }
        return studentsCopy;
    }

    //Kompo7
    handlePDF = async () => {
        await this.setState({ pdfLoading: true });
        setTimeout(async () => {
            await generateResultsSchoolPDF(this.props, this.state, this.props.results.filter((el) => { return el.skillsProfile && el.skillsProfile.approved === 1 }));
            await this.setState({ pdfLoading: false });
        }, 100);
    }

    //Kompo7
    handleTablePrintPDF = async (id) => {
        await this.setState({ pdfLoading: true });

        setTimeout(async () => {
            await generateResultsSchoolPDF(this.props, this.state, this.props.results.filter((el) => { return el.id === id }));
            await this.setState({ pdfLoading: false });
        }, 100, id);

    }

    //KompoG
    handleKompogPDF = async (id) => {
        await this.setState({ pdfLoading: true });
        let studentResults = this.props.studentResults.find(s => s.id === id.toString());
        let documentname = this.props.intl.formatMessage({ id: "myresultPage.kompoG.label" });

        setTimeout(async () => {
            generateMyResultPDF(this.props, this.state, [studentResults]).download(documentname);
            await this.setState({ pdfLoading: false });
        }, 100, id);

    }

    //KompoG
    handleKompogTablePrintPDF = async (id) => {
        await this.setState({ pdfLoading: true });

        let studentResults = [];
        this.props.studentResults.forEach(s => {
            if (s.releaseTeacher) {
                studentResults.push(s);
            }
        });
        let documentname = this.props.intl.formatMessage({ id: "myresultPage.kompoG.label" });
        setTimeout(async () => {
            // if (studentResults !== undefined){
            generateMyResultPDF(this.props, this.state, studentResults).download(documentname);
            // }
            await this.setState({ pdfLoading: false });
        }, 100, id);

    }

    order = async (order, desc, e) => {
        await this.setState({ order: order, desc: desc });
        this.props.dispatchGetExecutionsResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, {});
        if (this.props.executionProcedure === 2) {//Kompog
            this.props.dispatchGetKompogResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc);
        }
    }

    resetSkillsProfile = async (result) => {
        await this.props.dispatchApprovedSkillsProfile(this.props.auth.token, 0, result, 0);
        this.props.dispatchGetExecutionsResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, {});
    }

    resetKompogProfile = async (result) => {
        if (result) {
            let feedback = [];
            feedback.push({ id: result.id, feedbackTeacher: result.feedbackTeacher, releaseTeacher: 0 });
            this.props.dispatchUpdateResultTeacher(this.props.auth.token, this.props.executionId, feedback);
        }
    }

    render() {
        return (
            <Container fluid role="main" className="content-margin">

                {/* PDF LOADING */}
                {this.state.pdfLoading && <Loading absolute />}

                <h1><FormattedMessage id="results-school.title" /></h1>
                <Row>
                    <Col xs="12" className="my-3">
                        <ExecutionsPastCheckbox />
                    </Col>
                </Row>
                { /* Select School / Execution */}
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3" className={this.props.schools.length > 1 ? "" : "d-none"}>
                        <SchoolSelect className="mb-3 p-0" withEmpty label="results-school.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                    {/* Select Procedure */}
                    {(this.state.procedures && this.state.procedures.length > 1) &&
                        <Col xs="12" sm="6" md="6" lg="6" xl="3">
                            <ProcedureSelect className="m-0 p-0" label="studentadministration.label.procedures" name="procedures" withEmpty procedures={this.state.procedures ? this.state.procedures : []} procedureId={this.props.procedureId} handleProcedures={this.props.dispatchProcedure} />
                        </Col>
                    }
                    <Col xs="12" sm="6" md="6" lg="6" xl="3">
                        <ExecutionSelect className="mb-3 p-0" withEmpty label="results-school.label.execution" executionId={this.props.executionId} schoolId={this.props.schoolId} procedureId={this.props.procedureId} />
                    </Col>

                </Row>

                {this.props.executionProcedure === 1 &&
                    <div>
                        {/* PDF */}
                        {!this.props.loading && <Row className={this.props.results.length > 0 ? "pt-2" : "d-none"}>
                            <Col xs="12">
                                <Container fluid className="p-0 mb-4">
                                    <GeneratePDF
                                        className="p-0 m-0 d-flex flex-row"
                                        handlePDF={this.handlePDF.bind(this, this.props.intl.formatMessage({ id: "results-school.print.filename" }, this.state.translationsVars))}
                                        disabled={this.props.results.filter((el) => { return el.skillsProfile && el.skillsProfile.approved === 1 }).length === 0} />
                                </Container>
                            </Col>
                        </Row>
                        }
                        { /* Table */}
                        {this.props.executionId !== -1 &&
                            <Container fluid className="m-0 p-0 border">
                                <Table striped bordered className="m-0 table-fixed p-0 w-100">
                                    <TableHead data={this.state.th} order={this.order} orderDefault={this.state.order} />
                                    <TableBody
                                        loading={this.props.loading}
                                        data={this.state.tb}
                                        show={this.state.tbshow}
                                        print={this.handleTablePrintPDF}
                                        resetSkillsProfile={this.resetSkillsProfile}
                                    />
                                </Table>

                            </Container>
                        }
                        {this.props.executionId === -1 &&
                            <div>
                                <br />
                                <Label className="fw-bold">
                                    <FormattedMessage id="results-school.table.empty" />
                                </Label>
                            </div>
                        }
                    </div>}
                {this.props.executionProcedure === 2 &&
                    <div role="main">
                        {/* PDF */}
                        {!this.props.loading && <Row className={this.props.results.length > 0 ? "pt-2" : "d-none"}>
                            <Col xs="12">
                                <Container fluid className="p-0 mb-4">
                                    <GeneratePDF
                                        className="p-0 m-0 d-flex flex-row"
                                        handlePDF={this.handleKompogTablePrintPDF.bind(this, this.props.intl.formatMessage({ id: "results-school.print.filename" }, this.state.translationsVars))}
                                        disabled={this.state.pdf_kompoG_disabled}
                                    />
                                </Container>
                            </Col>
                        </Row>
                        }
                        { /* Table */}
                        <Container fluid className="m-0 p-0 border">
                            <Table striped bordered className="m-0 p-0">
                                <TableHead data={this.state.thKompog} order={this.order} orderDefault={this.state.order} />
                                <TableBody data={this.state.students}
                                    loading={this.props.loading}
                                    show={this.state.tbshowKompog}
                                    printG={this.handleKompogPDF}
                                    resetProfile={this.resetKompogProfile} />
                            </Table>
                        </Container>
                    </div>} {/*end of kompog*/}
            </Container >
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        executions: state.executions.executions ? state.executions.executions : [],
        executionId: state.filter.execution && state.filter.execution.id ? state.filter.execution.id : -1,
        executionProcedure: state.filter.execution && state.filter.execution.procedure ? state.filter.execution.procedure.id : -1,
        execution: state.filter.execution,
        results: state.executions.results ? state.executions.results : [],
        competences: state.competences.competences,
        loading: state.competences.loading || state.executions.loadingResults,
        procedures: state.procedures ? state.procedures : [],
        procedureId: state.filter.procedure /*&& state.filter.procedure.id*/ ? state.filter.procedure.id : -1,
        students: state.executions.studentsforuser,
        kompoGSaveRelease: state.kompoGResult.saveStatus,
        studentResults: state.kompoGResult.results,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetCompetences: (token, executionId) => {
            dispatch(competencesActions.getCompetences(token, executionId, null, null, null, null, null));
        },
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, false));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetExecutionsResults: (token, executionId, order, desc, filter) => {
            dispatch(executionsActions.getExecutionsResults(token, executionId, order, desc, filter));
        },
        dispatchApprovedSkillsProfile: (token, skillsProfile, honorarkraftId, approved) => {
            dispatch(skillsProfilesActions.approvedSkillsProfile(token, skillsProfile, honorarkraftId, approved))
        },
        dispatchProcedure: (procedure) => {
            dispatch(filterActions.procedure(procedure));
        },
        dispatchGetStudentsForUser: (token, executionId) => {
            dispatch(executionsActions.getStudentsForUser(token, executionId, -1));
        },
        dispatchGetStudents: (token, executionId, procedureId) => {
            dispatch(executionsActions.getStudents(token, executionId, procedureId));
        },
        dispatchUpdateResultTeacher: (token, executionId, feedback) => {
            dispatch(kompoGResultActions.updateResultTeacher(token, executionId, feedback));
        },
        dispatchGetKompogResults: (token, executionId, order, desc) => {
            dispatch(kompoGResultActions.getAllPupilResults(token, executionId, order, desc));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsSchoolPage));