import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import DisplaySchoolyears from './DisplaySchoolyears'

class SchoolyearsSelect extends React.Component {


    static propTypes = {
        schoolyearsId: PropTypes.number,
        handleSchoolyears: PropTypes.func,
        schoolyears: PropTypes.array,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        withEmpty: PropTypes.bool,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        disabled: PropTypes.bool,
        short: PropTypes.bool,
        withLabel: PropTypes.bool,
        autoSelect: PropTypes.bool
    };

    static defaultProps = {
        schoolyears: [],
        schoolyearsId: -1,
        withEmpty: false,
        invalid: false,
        disabled: false,
        label: "schoolyearsselect.label.default",
        short: false,
        autoSelect: false
    };

    componentDidMount() {
        this.autoSelection(this.props);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.schoolyears !== this.props.schoolyears || prevProps.schoolyearsId !== this.props.schoolyearsId) {
            this.autoSelection(this.props);
        }
    }

    //select the current schoolyear if the current selection id is -1 and autoSelection is true
    autoSelection(props) {
        if (props.schoolyearsId === -1 && props.schoolyears.length > 0) {
            let index = 0;
            if (props.autoSelect) {
                let d = new Date();
                //until august
                if (d.getMonth() <= 6) {
                    props.schoolyears.forEach((schoolyear, i) => {
                        if (schoolyear.end === d.getFullYear()) {
                            index = i;
                        }
                    })
                    //from august
                } else {
                    props.schoolyears.forEach((schoolyear, i) => {
                        if (schoolyear.start === d.getFullYear()) {
                            index = i;
                        }
                    })
                }
            }
            if (!props.withEmpty || props.autoSelect) {
                props.handleSchoolyears(props.schoolyears[index]);
            }
        }
    }

    createSchoolyearsItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "schoolyearsselect.option.pleaseselect" })}</option>);

        this.props.schoolyears.forEach((schoolyear) => {
            items.push(<option key={schoolyear.id} value={schoolyear.id} >
                {this.props.short ? (schoolyear.start - 2000) + " / " + (schoolyear.end - 2000) : schoolyear.start + " / " + schoolyear.end}
                {/* <DisplaySchoolyears schoolyears={schoolyear} short={this.props.short} /> */}
            </option>);
        });

        return items;
    }

    handleSchoolyears = async (e) => {
        let id = parseInt(e.target.value, 10);
        let schoolyear = this.props.schoolyears.filter(el => el.id === id)[0] || { id: -1, start: 0, end: 0 };
        await this.props.handleSchoolyears(schoolyear);
    }

    render() {
        return (
            <Container fluid className={this.props.schoolyears.length > 1 || this.props.withEmpty ? this.props.className : "d-none "}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "schoolyearsselect.label.default" || this.props.withLabel ? "fw-bold" : "d-none"} for="schoolyears"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled} invalid={this.props.invalid} bsSize="sm" type="select" name="schoolyears" id="schoolyears" value={this.props.schoolyearsId} onChange={this.handleSchoolyears}>
                        {this.createSchoolyearsItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchoolyearsSelect));