import ENV from '../config/env';

export const observationsService = {
    getObservation,
    createObservation,
    updateObservation

};

/**
* GET OBSERVATION FOR A STUDENT
*/
async function getObservation(token, executionId, usersId, honorarkraftId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";

    return fetch(ENV.apiUrl + '/observations/' + executionId + honorarkraftId + '?users_id=' + usersId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}


/**
* CREATE OBSERVATION
*/
async function createObservation(token, executionId, usersId, text) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ users_id: usersId, text: text })
    };

    return fetch(ENV.apiUrl + '/observations/' + executionId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });
}


/**
* UPDATE EXECUTION
*/
async function updateObservation(token, executionId, usersId, text) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ users_id: usersId, text: text })
    };

    return fetch(ENV.apiUrl + '/observations/' + executionId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}
