import { inquiryService } from '../services';
import { actionTypes } from './types';

export const inquiryActions = {
    createInquiry,
    getInquiryBySchoolId,
    getInquiryBySchoolIdExecutionId,
    getSendInquiryBySchoolId,
    updateInquiry,
    getCountOfOpenInquirys
};

function createInquiry(token, inquiry) {
    return dispatch => {
        dispatch(request());

        inquiryService.createUser(token, inquiry)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.INQUIRY_CREATE_REQUEST } }
    function success(response) { return { type: actionTypes.INQUIRY_CREATE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.INQUIRY_CREATE_FAILURE, error } }
}


function updateInquiry(token, inquiry) {
    return dispatch => {
        dispatch(request());

        inquiryService.updateInquiry(token, inquiry)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.INQUIRY_UPDATE_REQUEST } }
    function success(response) { return { type: actionTypes.INQUIRY_UPDATE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.INQUIRY_UPDATE_FAILURE, error } }
}

function getInquiryBySchoolId(token, schoolId, order, desc, page, pagelimit, rejected, past) {
    return dispatch => {
        dispatch(request());

        inquiryService.getInquiryBySchoolId(token, schoolId, order, desc, page, pagelimit, rejected, past)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.INQUIRY_OF_SCHOOL_REQUEST } }
    function success(inquiry) { return { type: actionTypes.INQUIRY_OF_SCHOOL_SUCCESS, inquiry } }
    function failure(error) { return { type: actionTypes.INQUIRY_OF_SCHOOL_FAILURE, error } }
}

function getInquiryBySchoolIdExecutionId(token, schoolId, executionId, order, desc, page, pagelimit, rejected, past) {
    return dispatch => {
        dispatch(request());

        inquiryService.getInquiryBySchoolIdExecutionId(token, schoolId, executionId, order, desc, page, pagelimit, rejected, past)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.INQUIRY_OF_SCHOOL_AND_EXECUTION_REQUEST } }
    function success(inquiry) { return { type: actionTypes.INQUIRY_OF_SCHOOL_AND_EXECUTION_SUCCESS, inquiry } }
    function failure(error) { return { type: actionTypes.INQUIRY_OF_SCHOOL_AND_EXECUTION_FAILURE, error } }
}

function getSendInquiryBySchoolId(token, schoolId, order, desc, page, pagelimit, rejected, past) {
    return dispatch => {
        dispatch(request());

        inquiryService.getSendInquiryBySchoolId(token, schoolId, order, desc, page, pagelimit, rejected, past)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.INQUIRY_SEND_OF_SCHOOL_REQUEST } }
    function success(inquiry) { return { type: actionTypes.INQUIRY_SEND_OF_SCHOOL_SUCCESS, inquiry } }
    function failure(error) { return { type: actionTypes.INQUIRY_SEND_OF_SCHOOL_FAILURE, error } }
}

function getCountOfOpenInquirys(token) {
    return dispatch => {
        dispatch(request());

        inquiryService.getCountOfOpenInquirys(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.INQUIRY_COUNT_OPEN_REQUEST } }
    function success(inquiry) { return { type: actionTypes.INQUIRY_COUNT_OPEN_SUCCESS, inquiry } }
    function failure(error) { return { type: actionTypes.INQUIRY_COUNT_OPEN_FAILURE, error } }
}
