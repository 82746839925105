import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class MonthYearSelector extends React.Component {
    static propTypes = {
        handleMonth: PropTypes.func,
        handleYear: PropTypes.func,
        year: PropTypes.number,
        month: PropTypes.number,
        startYear: PropTypes.number,
        futureYears: PropTypes.number,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        label: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            month: props.month,
            year: props.year,
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.year !== this.props.year) {
            this.setState({ year: this.props.year });
        }
        if (prevProps.month !== this.props.month) {
            this.setState({ month: this.props.month });
        }
    }

    getMonthSelectOptions() {
        let monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        let options = [];
        for (let i = 0; i < 12; i++) {
            options.push(
                <option key={i} value={i + 1}>{monthNames[i]}</option>
            );
        }
        return options;
    }

    getYearSelectOptions() {
        let startYear = this.props.startYear;
        let currentYear = new Date().getFullYear();
        let startNowDiff = currentYear - startYear;
        let numFutureYears = this.props.futureYears;
        let options = [];
        for (let i = 0; i < startNowDiff + numFutureYears; i++) {
            let y = startYear + i;

            options.push(
                <option key={i} value={y}>{y}</option>
            );
        }
        return options;
    }

    handleMonth = async (e) => {
        let month = parseInt(e.target.value, 10);
        await this.setState({ month: month });

        this.props.handleMonth(month);
    };

    handleYear = async (e) => {
        let year = parseInt(e.target.value, 10);

        await this.setState({ year: year });

        this.props.handleYear(year);
    };

    render() {
        return (
            <Container fluid className={this.props.className}>
                <Label className="fw-bold" for="month"><FormattedMessage className="flex-wrap" id={this.props.label} /></Label>
                <div className="d-flex">
                    <div className="w-50">
                        <Input disabled={this.props.disabled} bsSize="sm" type="select" name="month" id="month" value={this.state.month} onChange={this.handleMonth.bind(this)}>
                            {this.getMonthSelectOptions()}
                        </Input>
                    </div>
                    <div className="ms-2 w-50">
                        <Input disabled={this.props.disabled} bsSize="sm" type="select" name="year" id="year" value={this.state.year} onChange={this.handleYear.bind(this)}>
                            {this.getYearSelectOptions()}
                        </Input>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthYearSelector));