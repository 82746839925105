import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button, UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Line, Bar } from 'react-chartjs-2';
import { schoolsActions, statisticActions, departmentsActions, filterActions, kompo7RatingActions } from '../actions';
import SchoolSelect from '../components/SchoolSelect';
import DepartmentSelect from '../components/DepartmentSelect';
import SchooltypeSelect from '../components/SchooltypeSelect';
import ProcedureSelect from '../components/ProcedureSelect';
import Authorization from '../components/Authorization';
import MonthYearSelector from '../components/MonthYearSelector';
import { generateDepartmentStatisticsPDF } from '../pdf';
import Loading from '../components/Loading';
import translationsVars from '../translations/translationsVars';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

class StatisticPage extends Component {
    constructor(props) {
        super(props);

        let now = new Date();
        let past = new Date();
        past.setMonth(past.getMonth() - 5);
        //this.togglePopOver = this.togglePopOver.bind(this);
        this.getPopOver = this.getPopOver.bind(this);
        this.state = {
            executionsPerMonthData: { labels: [], datasets: [] },
            startDate: this.getFormattedMonthYearDate(past.getMonth() + 1, past.getFullYear()),
            endDate: this.getFormattedMonthYearDate(now.getMonth() + 1, now.getFullYear()),
            startMonth: past.getMonth() + 1,
            startYear: past.getFullYear(),
            endMonth: now.getMonth() + 1,
            endYear: now.getFullYear(),
            maxYAxis: 0,
            barDataset: { labels: [], datasets: [] },
            popoverOpen: false,
            procedures: []
        };

    }

    componentDidMount() {
        this.props.dispatchGetDepartments(this.props.token);
        this.props.dispatchGetSchooltypes(this.props.token);
        this.props.dispatchGetSchools(this.props.token);
    }

    componentWillUnmount() {
        if (this.props.procedureId > 0) {
            this.props.dispatchProcedure(-1);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.schools !== this.props.schools || prevProps.departmentId !== this.props.departmentId || prevProps.schooltypeId !== this.props.schooltypeId || prevProps.schoolId !== this.props.schoolId || prevProps.procedureId !== this.props.procedureId || prevProps.procedureUser !== this.props.procedureUser) {
            let procedure = this.props.procedureUser === 0 ? this.props.procedureId : this.props.procedureUser;
            this.props.dispatchGetStatistic(this.props.token, {
                schoolId: this.props.schoolId ? this.props.schoolId : -1,
                departmentId: this.props.departmentId ? this.props.departmentId : -1,
                schooltypeId: this.props.schooltypeId ? this.props.schooltypeId : -1,
                procedure: procedure,
                start: this.state.startDate,
                end: this.state.endDate
            });
            this.props.dispatchGetRatingResults(this.props.token, {
                schoolId: this.props.schoolId ? this.props.schoolId : -1,
                departmentId: this.props.departmentId ? this.props.departmentId : -1,
                schooltypeId: this.props.schooltypeId ? this.props.schooltypeId : -1,
                procedure: procedure,
                start: this.state.startDate,
                end: this.state.endDate
            });
        }

        if (this.props.schools !== prevProps.schools && this.props.schools.length > 0) {
            let arrayProcedures = JSON.parse(JSON.stringify(this.state.procedures));
            this.props.schools.forEach(school => {
                school.procedures.forEach(p => {
                    if (!arrayProcedures.some(item => item.id === p.id)) {
                        arrayProcedures.push(p);
                    }
                });
            });
            this.setState({ procedures: arrayProcedures });
        }

        if (prevProps.statistic !== this.props.statistic) {
            if (this.props.statistic && this.props.statistic.executions && this.props.statistic.executions.executions_per_month) {
                this.updateExecutionsPerMonthChart(this.props.statistic.executions.executions_per_month);
            }
        }

        if (prevProps.ratingresults !== this.props.ratingresults) {
            this.setState({ barDataset: this.getBarDataset(this.props.ratingresults) });
        }
    }

    updateExecutionsPerMonthChart(executionsPerMonth) {
        let months = executionsPerMonth.map(e => {
            let m = new Date(e.month);
            return ((m.getMonth() + 1 <= 9 ? '0' + (m.getMonth() + 1) : (m.getMonth() + 1)) + '.' + m.getFullYear());
        });
        let executions = executionsPerMonth.map(e => e.executions);

        let data = {
            labels: months,
            datasets: [{
                label: "",
                data: executions,
                fill: true,
                borderColor: 'rgba(56, 57, 128, 1)',
                backgroundColor: 'rgba(56, 57, 128, 0.2)',
            }]
        }

        // find the max execution count
        let max = 0;
        for (let i = 0; i < executions.length; i++) {
            if (executions[i] > max) {
                max = executions[i];
            }
        }

        this.setState({ executionsPerMonthData: data, executionsPerMonthDataMax: max });
    }

    updateExecutionsPerMonthData(endDate = "", startDate = "") {
        let procedure = this.props.procedureUser === 0 ? this.props.procedureId : this.props.procedureUser;
        this.props.dispatchGetStatistic(this.props.token, {
            schoolId: this.props.schoolId ? this.props.schoolId : -1,
            departmentId: this.props.departmentId ? this.props.departmentId : -1,
            schooltypeId: this.props.schooltypeId ? this.props.schooltypeId : -1,
            procedure: procedure,
            start: startDate === "" ? this.state.startDate : startDate,
            end: endDate === "" ? this.state.endDate : endDate,
        });
        this.props.dispatchGetRatingResults(this.props.token, {
            schoolId: this.props.schoolId ? this.props.schoolId : -1,
            departmentId: this.props.departmentId ? this.props.departmentId : -1,
            schooltypeId: this.props.schooltypeId ? this.props.schooltypeId : -1,
            procedure: procedure,
            start: startDate === "" ? this.state.startDate : startDate,
            end: endDate === "" ? this.state.endDate : endDate,
        });
    }

    handleSchool = (school) => {
        this.props.dispatchSchool(school);
        this.updateExecutionsPerMonthData();
    }

    handleStartMonth = (month) => {
        let start = this.getFormattedMonthYearDate(month, this.state.startYear)
        this.setState({ startMonth: month, startDate: start });

        this.updateExecutionsPerMonthData("", start);
    };

    handleStartYear = (year) => {
        let start = this.getFormattedMonthYearDate(this.state.startMonth, year)
        this.setState({ startYear: year, startDate: start });

        this.updateExecutionsPerMonthData("", startDate);
    };

    handleEndMonth = (month) => {
        let end = this.getFormattedMonthYearDate(month, this.state.endYear)
        this.setState({ endMonth: month, endDate: end });

        this.updateExecutionsPerMonthData(end, "");
    };

    handleEndYear = (year) => {
        let end = this.getFormattedMonthYearDate(this.state.endMonth, year)
        this.setState({ endYear: year, endDate: end });

        this.updateExecutionsPerMonthData(end, "");
    };

    handlePDF = () => {
        if (this.props.departmentId > -1) {

            let schools = [];
            this.props.statistic.teachers.schools.forEach((s) => {
                let tmpSchool = s;
                let school = this.props.statistic.schools.schools.filter((t) => { return t.id === tmpSchool.id });
                let support = school.length >= 1 ? school[0].support : 0
                tmpSchool['support'] = support
                schools.push(tmpSchool);

            });

            let stats = {
                department: this.props.statistic.schools.department,
                school_stats: schools,
                total: {
                    total_certified_teachers: this.props.statistic.teachers.total.certified,
                    total_executions_cooperation: this.props.statistic.schools.support.total,
                    total_executions_standalone: this.props.statistic.schools.participating.total - this.props.statistic.schools.support.total,
                }
            }
            generateDepartmentStatisticsPDF(this.props, this.state, stats);
        }
    };

    getFormattedMonthYearDate(month, year) {
        let formattedMY = year + '-';
        if (month < 10) {
            formattedMY += '0';
        }
        formattedMY += month;
        return formattedMY;
    }

    getStepSize(maxValue) {
        if (maxValue <= 5)
            return 1;
        else if (maxValue < 20)
            return 2;
        else
            return 5;
    }

    getBarDataset(ratingresults) {

        const ratingsQuestions = [3, 4, 5, 6]

        let data = [[0, 0, 0, 0], [0, 0, 0, 0], [0, 0, 0, 0]];
        ratingresults.forEach((rating) => {
            let index = ratingsQuestions.indexOf(rating.ratingsquestions_id);
            if (index >= 0 && rating.rating > 0) {
                data[rating.rating - 1][index]++;
            }
        });

        let ds = {
            labels: ['', '', '', ''],
            datasets: [
                {
                    label: this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-1" }),
                    data: data[0],
                    borderColor: 'rgba(56,57,128,0.75)',
                    backgroundColor: 'rgba(56,57,128,0.75)',
                },
                {
                    label: this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-2" }),
                    data: data[1],
                    borderColor: 'rgba(56, 57, 128, 0.2)',
                    backgroundColor: 'rgba(56, 57, 128, 0.2)',
                },
                {
                    label: this.props.intl.formatMessage({ id: "kompo7ratingresults.label.rating-3" }),
                    data: data[2],
                    borderColor: 'rgba(227,6,19,0.75)',
                    backgroundColor: 'rgba(227,6,19,0.75)',
                }
            ]
        };

        //get max value for y-axcis
        let max = 0;
        data.forEach((d) => {
            max += d[0];
        })
        this.setState({ maxYAxis: max === 0 ? 1 : max + 1 });

        return ds;
    }

    getBarStepSize(maxValue) {

        if (maxValue < 15) {
            return 1;
        }
        else if (maxValue < 50) {
            return 5
        }
        else if (maxValue < 150) {
            return 10
        }
        else if (maxValue < 500) {

            return 25
        }
        else {
            return 50;
        }
    }

    getBarMax() {
        if (this.state.maxYAxis % this.getBarStepSize(this.state.maxYAxis) > 0) {
            return this.state.maxYAxis + (this.getBarStepSize(this.state.maxYAxis) - this.state.maxYAxis % this.getBarStepSize(this.state.maxYAxis));
        } else {
            return this.state.maxYAxis;
        }
    }

    getTeachersForSchoolTypes(types) {
        let tmp = {
            total: 0,
            certified: 0
        };

        this.props.statistic.teachers.schooltypes.forEach($st => {
            if (types.indexOf($st.id) !== -1) {
                tmp.total += $st.total;
                tmp.certified += $st.certified;
            }
        })
        return tmp;
    }

    getStudentsForSchoolTypes(types) {
        let tmp = {
            total: 0
        };

        this.props.statistic.students.schooltypes.forEach($st => {
            if (types.indexOf($st.id) !== -1) {
                tmp.total += $st.total;
            }
        })
        return tmp;
    }

    getExecutionsForSchoolTypes(types) {
        let tmp = {
            total: 0
        };

        this.props.statistic.executions.schooltypes.forEach($st => {
            if (types.indexOf($st.id) !== -1) {
                tmp.total += $st.total;
            }
        })
        return tmp;
    }

    getNewSchoolsForSchoolTypes(types) {
        let tmp = {
            total: 0
        };

        this.props.statistic.schools.new.schooltypes.forEach($st => {
            if (types.indexOf($st.id) !== -1) {
                tmp.total += $st.total;
            }
        })
        return tmp;
    }

    getParticipatingSchoolsForSchoolTypes(types) {
        let tmp = {
            total: 0
        };

        this.props.statistic.schools.participating.schooltypes.forEach($st => {
            if (types.indexOf($st.id) !== -1) {
                tmp.total += $st.total;
            }
        })
        return tmp;
    }

    getSupportSchoolsForSchoolTypes(types) {
        let tmp = {
            total: 0
        };

        this.props.statistic.schools.support.schooltypes.forEach($st => {
            if (types.indexOf($st.id) !== -1) {
                tmp.total += $st.total;
            }
        })
        return tmp;
    }

    getCooperationSchoolsForSchoolTypes(types) {
        let tmp = {
            total: 0
        };

        this.props.statistic.schools.cooperation.schooltypes.forEach($st => {
            if (types.indexOf($st.id) !== -1) {
                tmp.total += $st.total;
            }
        })
        return tmp;
    }

    togglePopOver(id) {
        this.setState({
            popoverOpen: this.state.popoverOpen !== id ? id : -1
        });
    }

    getPopOver(id, header, body) {

        return (
            <div>
                <Button className={"info"} color="link" name={"Popover" + id} id={"Popover" + id} onClick={this.togglePopOver.bind(this, id)}></Button>
                <UncontrolledPopover trigger="focus" container={"body"} placement={this.props.placement} aria-live="polite" aria-atomic="true" aria-hidden={!this.state.popoverOpen} /*isOpen={id === this.state.popoverOpen}*/ target={"Popover" + id} /*toggle={this.togglePopOver.bind(this, id)}*/>
                    <PopoverHeader><FormattedMessage id={header}></FormattedMessage></PopoverHeader>
                    <PopoverBody>
                        <FormattedMessage id={body} values={translationsVars}></FormattedMessage>
                    </PopoverBody>
                </UncontrolledPopover>
            </div >
        );
    }

    render() {
        return (
            <Container fluid role="main" className="content-margin">
                <h1><FormattedMessage id="statistic.title" /></h1>
                {
                    this.props.statistic &&
                    <Container fluid className="p-0 m-0 bg-white A pb-1 mb-1">
                        <Row className="mt-0">
                            { /* Select School */}
                            <Col xs="12" sm="6" md="6" lg="6" xl="4">
                                <SchoolSelect withEmpty={this.props.schools.length > 1} emptyLabel="statistic.schoolfilter.overall" schools={this.props.schools} handleSchool={this.handleSchool.bind(this)} schoolId={this.props.schoolId ? this.props.schoolId : -1} className="m-0 p-0" label="statistic.label.school" />
                            </Col>
                            { /* Select Department */}
                            <Authorization id="statistic-filter-department">
                                <Col xs="12" sm="6" md="6" lg="6" xl="4">
                                    <DepartmentSelect className={this.props.departments.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="useradministration.label.department" departments={this.props.departments} handleDepartment={this.props.dispatchDepartment.bind(this)} departmentId={this.props.departmentId} />
                                </Col>
                            </Authorization>
                            { /* Select Schooltype */}
                            <Authorization id="statistic-filter-schooltype">
                                <Col xs="12" sm="6" md="6" lg="6" xl="4">
                                    <SchooltypeSelect className={this.props.schooltypes.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="useradministration.label.schooltype" schooltypes={this.props.schooltypes} handleSchooltype={this.props.dispatchSchooltype.bind(this)} schooltypeId={this.props.schooltypeId} />
                                </Col>
                            </Authorization>
                        </Row>

                        <Row className={this.props.departmentId > -1 ? '' : 'mb-5'}>
                            <Col xs="12" md="6" lg="4" xl="4" className="m-0 p-0 px-3">
                                <MonthYearSelector label="statistic.timespanfilter.start" year={this.state.startYear} month={this.state.startMonth} startYear={2018} futureYears={3} handleYear={this.handleStartYear.bind(this)} handleMonth={this.handleStartMonth.bind(this)} />
                            </Col>
                            <Col xs="12" md="6" lg="4" xl="4" className="m-0 p-0 px-3">
                                <MonthYearSelector label="statistic.timespanfilter.end" year={this.state.endYear} month={this.state.endMonth} startYear={2018} futureYears={3} handleYear={this.handleEndYear.bind(this)} handleMonth={this.handleEndMonth.bind(this)} />
                            </Col>
                            {/* Select Procedure */}
                            <Authorization id="executions-show-procedure" >
                                <Col xs="12" sm="6" md="6" lg="6" xl="4">
                                    <ProcedureSelect className="m-0 p-0" label="studentadministration.label.procedures" name="procedures" withEmpty procedures={this.state.procedures ? this.state.procedures : []} procedureId={this.props.procedureId} handleProcedures={this.props.dispatchProcedure} />
                                </Col>
                            </Authorization>

                        </Row>

                        {this.props.departmentId > -1 &&
                            <Authorization id="statistic-statistic-departmentstats">
                                <Row className="mt-4 mb-5">
                                    <Col xs="12" sm="6" md="6" lg="6" xl="4" className="m-0 p-0 ms-3">
                                        <Button color="primary" onClick={this.handlePDF}><FormattedMessage id="statistic.button.pdf" /></Button>
                                    </Col>
                                </Row>
                            </Authorization>
                        }

                    </Container>
                }

                {
                    this.props.loading ?
                        <Loading /> : null
                }
                {
                    this.props.statistic && !this.props.loading &&
                    <Container fluid className="p-0 m-0">
                        <Row className="pt-0 ms-1 me-4 stats-row top">
                            <Col xs="4" className="m-0 p-0">

                            </Col>
                            <Col xs="2" className="m-0 py-0 px-1 fw-bold text-center border-end border-start">
                                <FormattedMessage id="statistic.statistic.total.label"></FormattedMessage>
                            </Col>
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 py-0 px-1 fw-bold text-center border-end ">
                                    <FormattedMessage id="statistic.statistic.foerderschule.label"></FormattedMessage>
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-center border-end">
                                    <FormattedMessage id="statistic.statistic.hr.label"></FormattedMessage>
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-center">
                                    <FormattedMessage id="statistic.statistic.gymnasien.label"></FormattedMessage>
                                </Col>
                            }
                        </Row>
                        <Authorization id="statistic-statistic-schools">
                            <Row className="pt-0 ms-1 me-4 stats-row">
                                <Col xs="4" className="m-0 p-1 fw-bold">
                                    <FormattedMessage id="statistic.statistic.new-school.label"></FormattedMessage>
                                    {this.getPopOver(1, 'statistic.description', 'statistic.description.new-school')}
                                </Col>
                                <Col xs="2" className="m-0 py-0 p-1 fw-bold text-end border-end border-start">
                                    {this.props.statistic.schools.new.total}
                                </Col>
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                        {this.getNewSchoolsForSchoolTypes([3]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                        {this.getNewSchoolsForSchoolTypes([1, 2, 5, 6, 8]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                        {this.getNewSchoolsForSchoolTypes([4]).total}
                                    </Col>
                                }
                            </Row>
                            <Row className="pt-0 ms-1 me-4 stats-row">
                                <Col xs="4" className="m-0 p-1 fw-bold">
                                    <FormattedMessage id="statistic.statistic.school.label"></FormattedMessage>
                                    {this.getPopOver(2, 'statistic.description', 'statistic.description.school')}
                                </Col>
                                <Col xs="2" className="m-0 py-0 p-1 fw-bold text-end border-end border-start">
                                    {this.props.statistic.schools.participating.total}
                                </Col>
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                        {this.getParticipatingSchoolsForSchoolTypes([3]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                        {this.getParticipatingSchoolsForSchoolTypes([1, 2, 5, 6, 8]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                        {this.getParticipatingSchoolsForSchoolTypes([4]).total}
                                    </Col>
                                }
                            </Row>
                            <Row className="pt-0 ms-1 me-4 stats-row">
                                <Col xs="4" className="m-0 p-1 ps-4">
                                    <FormattedMessage id="statistic.statistic.schools-with-support.label"></FormattedMessage>
                                    {this.getPopOver(3, 'statistic.description', 'statistic.description.schools-with-support')}
                                </Col>
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                    {this.props.statistic.schools.support.total}
                                </Col>
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                        {this.getSupportSchoolsForSchoolTypes([3]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                        {this.getSupportSchoolsForSchoolTypes([1, 2, 5, 6, 8]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                        {this.getSupportSchoolsForSchoolTypes([4]).total}
                                    </Col>
                                }
                            </Row>
                            <Row className="pt-0 ms-1 me-4 stats-row">
                                <Col xs="4" className="m-0 p-1 ps-4">
                                    <FormattedMessage id="statistic.statistic.schools-without-support.label"></FormattedMessage>
                                    {this.getPopOver(4, 'statistic.description', 'statistic.description.schools-without-support')}
                                </Col>
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                    {this.props.statistic.schools.participating.total - this.props.statistic.schools.support.total}
                                </Col>
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                        {this.getParticipatingSchoolsForSchoolTypes([3]).total - this.getSupportSchoolsForSchoolTypes([3]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                        {this.getParticipatingSchoolsForSchoolTypes([1, 2, 5, 6, 8]).total - this.getSupportSchoolsForSchoolTypes([1, 2, 5, 6, 8]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                        {this.getParticipatingSchoolsForSchoolTypes([4]).total - this.getSupportSchoolsForSchoolTypes([4]).total}
                                    </Col>
                                }
                            </Row>
                            <Row className="pt-0 ms-1 me-4 stats-row">
                                <Col xs="4" className="m-0 p-1 fw-bold">
                                    <FormattedMessage id="statistic.statistic.schools-cooperating.label"></FormattedMessage>
                                    {this.getPopOver(5, 'statistic.description', 'statistic.description.schools-cooperating')}
                                </Col>
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                    {this.props.statistic.schools.cooperation.total}
                                </Col>
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                        {this.getCooperationSchoolsForSchoolTypes([3]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                        {this.getCooperationSchoolsForSchoolTypes([1, 2, 5, 6, 8]).total}
                                    </Col>
                                }
                                {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                    <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                        {this.getCooperationSchoolsForSchoolTypes([4]).total}
                                    </Col>
                                }
                            </Row>
                        </Authorization>
                        <Row className="pt-0 ms-1 me-4 stats-row">
                            <Col xs="4" className="m-0 p-1 fw-bold">
                                <FormattedMessage id="statistic.statistic.executions.label"></FormattedMessage>
                                {this.getPopOver(6, 'statistic.description', 'statistic.description.executions')}
                            </Col>
                            <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                {this.props.statistic.executions.total}
                            </Col>
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                    {this.getExecutionsForSchoolTypes([3]).total}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                    {this.getExecutionsForSchoolTypes([1, 2, 5, 6, 8]).total}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                    {this.getExecutionsForSchoolTypes([4]).total}
                                </Col>
                            }
                        </Row>
                        <Row className="pt-0 ms-1 me-4 stats-row">
                            <Col xs="4" className="m-0 p-1 fw-bold">
                                <FormattedMessage id="statistic.statistic.teacher.label"></FormattedMessage>
                                {this.getPopOver(7, 'statistic.description', 'statistic.description.teacher')}
                            </Col>
                            <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                {this.props.statistic.teachers.total.total}
                            </Col>
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                    {this.getTeachersForSchoolTypes([3]).total}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                    {this.getTeachersForSchoolTypes([1, 2, 5, 6, 8]).total}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                    {this.getTeachersForSchoolTypes([4]).total}
                                </Col>
                            }
                        </Row>
                        <Row className="pt-0 ms-1 me-4 stats-row">
                            <Col xs="4" className="m-0 p-1 ps-4">
                                <FormattedMessage id="statistic.statistic.teacher-certified.label"></FormattedMessage>
                                {this.getPopOver(8, 'statistic.description', 'statistic.description.teacher-certified')}
                            </Col>
                            <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                {this.props.statistic.teachers.total.certified}
                            </Col>
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                    {this.getTeachersForSchoolTypes([3]).certified}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                    {this.getTeachersForSchoolTypes([1, 2, 5, 6, 8]).certified}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                    {this.getTeachersForSchoolTypes([4]).certified}
                                </Col>
                            }
                        </Row>
                        <Row className="pt-0 ms-1 me-4 stats-row">
                            <Col xs="4" className="m-0 p-1 ps-4">
                                <FormattedMessage id="statistic.statistic.teacher-not-certified.label"></FormattedMessage>
                                {this.getPopOver(9, 'statistic.description', 'statistic.description.teacher-not-certified')}
                            </Col>
                            <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                {this.props.statistic.teachers.total.total - this.props.statistic.teachers.total.certified}
                            </Col>
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                    {this.getTeachersForSchoolTypes([3]).total - this.getTeachersForSchoolTypes([3]).certified}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                    {this.getTeachersForSchoolTypes([1, 2, 5, 6, 8]).total - this.getTeachersForSchoolTypes([1, 2, 5, 6, 8]).certified}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                    {this.getTeachersForSchoolTypes([4]).total - this.getTeachersForSchoolTypes([4]).certified}
                                </Col>
                            }
                        </Row>
                        <Row className="pt-0 ms-1 me-4 stats-row">
                            <Col xs="4" className="m-0 p-1 fw-bold">
                                <FormattedMessage id="statistic.statistic.students.label"></FormattedMessage>
                                {this.getPopOver(10, 'statistic.description', 'statistic.description.students')}
                            </Col>
                            <Col xs="2" className="m-0 p-1 fw-bold text-end border-end border-start">
                                {this.props.statistic.students.total}
                            </Col>
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end ">
                                    {this.getStudentsForSchoolTypes([3]).total}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end border-end">
                                    {this.getStudentsForSchoolTypes([1, 2, 5, 6, 8]).total}
                                </Col>
                            }
                            {this.props.schooltypeId === -1 && this.props.schoolId === -1 &&
                                <Col xs="2" className="m-0 p-1 fw-bold text-end">
                                    {this.getStudentsForSchoolTypes([4]).total}
                                </Col>
                            }
                        </Row>

                        { /* Executions per month chart */}
                        <Authorization id="statistic-statistic-executions">
                            <Row className="pt-1 pb-1 ms-1 mt-4 me-4">
                                <Col xs="8" md="3" lg="3" xl="3" className="m-0 p-0">
                                    <Row className="stats-row-title"><Col><FormattedMessage id="statistic.chart.executionspermonth.label" /></Col></Row>
                                </Col>
                            </Row>
                            {this.state.executionsPerMonthData &&
                                <div id="statisticExecutionsChart">
                                    <Line data={this.state.executionsPerMonthData}
                                        options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                y: {
                                                    ticks: {
                                                        beginAtZero: true,
                                                        stepSize: this.getStepSize(this.state.executionsPerMonthDataMax),
                                                        max: Math.ceil((this.state.executionsPerMonthDataMax + 2) / 5) * 5,
                                                    }
                                                }
                                            },
                                            elements: {
                                                line: {
                                                    tension: 0, // disables bezier curves
                                                }
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                }
                                            }
                                        }}
                                    />
                                </div>}
                        </Authorization>
                    </Container>

                }
                {
                    this.props.ratingresults && !this.props.loading &&
                    <Authorization id="statistic-ratings">
                        <Container fluid className="p-0 m-0">
                            {/* RATINGS */}
                            <Row className="pt-1 pb-1 mt-4 me-4 ms-1">
                                <Col xs="8" md="3" lg="3" xl="3" className="m-0 p-0">
                                    <Row className="stats-row-title"><Col><FormattedMessage id="statistic.kompo7ratingresults.label" /></Col></Row>
                                </Col>
                            </Row>
                            <Row className="pt-1 me-4">
                                <Col xs="12" className="d-inline ms-1">
                                    <div className="kompo7ratingresults-rating-1 ps-3 me-4"><FormattedMessage id="kompo7ratingresults.label.rating-1" /></div>
                                    <div className="kompo7ratingresults-rating-2 ps-3 me-4"><FormattedMessage id="kompo7ratingresults.label.rating-2" /></div>
                                    <div className="kompo7ratingresults-rating-3 ps-3"><FormattedMessage id="kompo7ratingresults.label.rating-3" /></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" className="pt-3 kompo7ratingresults-bar">
                                    {/* Bar Chart */}
                                    <Bar data={this.state.barDataset}
                                        options={
                                            {
                                                maintainAspectRatio: false,
                                                scales: {
                                                    y: {
                                                        ticks: {
                                                            // display: false,
                                                            beginAtZero: true,
                                                            stepSize: this.getBarStepSize(this.state.maxYAxis),
                                                            max: this.getBarMax(),
                                                        },
                                                        stacked: true
                                                    },
                                                    x: {
                                                        stacked: true,
                                                        ticks: {
                                                            maxRotation: 0,
                                                            minRotation: 0,
                                                            autoSkip: false,
                                                        },
                                                        display: true,
                                                    },
                                                },
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    }
                                                }
                                            }
                                        }

                                    />
                                </Col>
                            </Row>
                            {/* Labels for x-axis */}

                            <Row>
                                <Col xs="12" className="kompo7ratingresults-xaxes">
                                    <div><FormattedMessage id="kompo7ratingresults.question-3" /></div>
                                    <div><FormattedMessage id="kompo7ratingresults.question-4" /></div>
                                    <div><FormattedMessage id="kompo7ratingresults.question-5" /></div>
                                    <div><FormattedMessage id="kompo7ratingresults.question-6" /></div>
                                </Col>
                            </Row>

                        </Container>
                    </Authorization>
                }
            </Container>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        token: state.auth.token,
        procedureUser: state.auth.procedure,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        departments: state.departments.departments,
        departmentId: state.filter.department && state.filter.department.id ? state.filter.department.id : -1,
        schooltypes: state.schools.schooltypes,
        schooltypeId: state.filter.schooltype && state.filter.schooltype.id ? state.filter.schooltype.id : -1,
        statistic: state.statistic.statistic,
        departmentStatistic: state.statistic.departmentStatistic,
        ratingresults: state.kompo7Rating.ratingresults && state.kompo7Rating.ratingresults.ratings ? state.kompo7Rating.ratingresults.ratings : null,
        loading: state.kompo7Rating.loading || state.statistic.loading ? 1 : 0,
        procedures: state.procedures ? state.procedures : [],
        procedureId: state.filter.procedure ? state.filter.procedure.id : -1,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchools: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, true));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetStatistic: (token, filter) => {
            dispatch(statisticActions.getStatistic(token, filter));
        },
        // dispatchGetDepartmentStatistic: (token, departmentId) => {
        //     dispatch(statisticActions.getDepartmentStatistic(token, departmentId));
        // },
        dispatchGetDepartments: (token) => {
            dispatch(departmentsActions.getDepartments(token, "name", false, null, null, null));
        },
        dispatchGetSchooltypes: (token) => {
            dispatch(schoolsActions.getSchooltypes(token, "name", false));
        },
        dispatchDepartment: (department) => {
            dispatch(filterActions.department(department));
        },
        dispatchSchooltype: (schooltype) => {
            dispatch(filterActions.schooltype(schooltype));
        },
        dispatchGetRatingResults: (token, filter) => {
            dispatch(kompo7RatingActions.getRatingResults(token, filter));
        },
        dispatchProcedure: (procedure) => {
            dispatch(filterActions.procedure(procedure));
        }
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(StatisticPage));