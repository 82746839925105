import { schoolsService } from '../services';
import { actionTypes } from './types';

export const schoolsActions = {
    createSchool,
    updateSchool,
    deleteSchool,
    getSchools,
    getSchoolsContactPerson,
    getSchoolsForUser,
    getSchoolsTeacher,
    getSchoolsStudents,
    getSchoolyears,
    getSchooltypes,
    getSchoolSettings,
    saveSchoolSetting,
};

function createSchool(token, school) {
    return dispatch => {
        dispatch(request());

        schoolsService.createSchool(token, school)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.SCHOOLS_CREATE_SCHOOL_REQUEST } }
    function success(response) { return { type: actionTypes.SCHOOLS_CREATE_SCHOOL_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.SCHOOLS_CREATE_SCHOOL_FAILURE, error } }
}

function updateSchool(token, school) {
    return dispatch => {
        dispatch(request());

        schoolsService.updateSchool(token, school)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.SCHOOLS_UPDATE_SCHOOL_REQUEST } }
    function success(response) { return { type: actionTypes.SCHOOLS_UPDATE_SCHOOL_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.SCHOOLS_UPDATE_SCHOOL_FAILURE, error } }
}

function deleteSchool(token, schoolId) {
    return dispatch => {
        dispatch(request());

        schoolsService.deleteSchool(token, schoolId)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.SCHOOLS_DELETE_SCHOOL_REQUEST } }
    function success(response) { return { type: actionTypes.SCHOOLS_DELETE_SCHOOL_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.SCHOOLS_DELETE_SCHOOL_FAILURE, error } }
}

function getSchools(token, order, desc, enabled, page, pagelimit) {
    return dispatch => {
        dispatch(request({ getSchools }));

        schoolsService.getSchools(token, order, desc, enabled, page, pagelimit)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(schools) { return { type: actionTypes.SCHOOLS_REQUEST, schools } }
    function success(schools) { return { type: actionTypes.SCHOOLS_SUCCESS, schools } }
    function failure(error) { return { type: actionTypes.SCHOOLS_FAILURE, error } }
}

function getSchoolsForUser(token, order, desc, enabled, search, page, pageLimit, schooltypeId, departmentId, userSchoolsOnly) {
    return dispatch => {
        dispatch(request({ getSchoolsForUser }));

        schoolsService.getSchoolsForUser(token, order, desc, enabled, search, page, pageLimit, schooltypeId, departmentId, userSchoolsOnly)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(schools) { return { type: actionTypes.SCHOOLS_FOR_USER_REQUEST, schools } }
    function success(schools) { return { type: actionTypes.SCHOOLS_FOR_USER_SUCCESS, schools } }
    function failure(error) { return { type: actionTypes.SCHOOLS_FOR_USER_FAILURE, error } }
}

function getSchoolsContactPerson(token, schoolId, procedureId) {
    return dispatch => {
        dispatch(request({ getSchoolsContactPerson }));

        schoolsService.getSchoolsContactPerson(token, schoolId, procedureId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(schools) { return { type: actionTypes.SCHOOLS_CONTACTPERSON_REQUEST, schools } }
    function success(schools) { return { type: actionTypes.SCHOOLS_CONTACTPERSON_SUCCESS, schools } }
    function failure(error) { return { type: actionTypes.SCHOOLS_CONTACTPERSON_FAILURE, error } }
}

function getSchoolsTeacher(token, schoolId, order, desc, search, page, pagelimit) {
    return dispatch => {
        dispatch(request({ getSchoolsTeacher }));

        schoolsService.getSchoolsTeacher(token, schoolId, order, desc, search, page, pagelimit)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(schools) { return { type: actionTypes.SCHOOLS_TEACHER_REQUEST, schools } }
    function success(schools) { return { type: actionTypes.SCHOOLS_TEACHER_SUCCESS, schools } }
    function failure(error) { return { type: actionTypes.SCHOOLS_TEACHER_FAILURE, error } }
}

function getSchoolsStudents(token, schoolId, order, desc, search, page, pagelimit, filter) {
    return dispatch => {
        dispatch(request({ getSchoolsStudents }));

        schoolsService.getSchoolsStudents(token, schoolId, order, desc, search, page, pagelimit, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(schools) { return { type: actionTypes.SCHOOLS_STUDENTS_REQUEST, schools } }
    function success(schools) { return { type: actionTypes.SCHOOLS_STUDENTS_SUCCESS, schools } }
    function failure(error) { return { type: actionTypes.SCHOOLS_STUDENTS_FAILURE, error } }
}

function getSchoolyears(token, order, desc) {
    return dispatch => {
        dispatch(request({ getSchoolyears }));

        schoolsService.getSchoolyears(token, order, desc)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(schoolyears) { return { type: actionTypes.SCHOOLS_YEARS_REQUEST, schoolyears } }
    function success(schoolyears) { return { type: actionTypes.SCHOOLS_YEARS_SUCCESS, schoolyears } }
    function failure(error) { return { type: actionTypes.SCHOOLS_YEARS_FAILURE, error } }
}

function getSchooltypes(token, order, desc) {
    return dispatch => {
        dispatch(request({ getSchooltypes }));

        schoolsService.getSchooltypes(token, order, desc)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(schooltypes) { return { type: actionTypes.SCHOOLS_TYPES_REQUEST, schooltypes } }
    function success(schooltypes) { return { type: actionTypes.SCHOOLS_TYPES_SUCCESS, schooltypes } }
    function failure(error) { return { type: actionTypes.SCHOOLS_TYPES_FAILURE, error } }
}

function getSchoolSettings(token) {
    return dispatch => {
        dispatch(request());

        schoolsService.getSchoolSettings(token)
            .then(
            response => {               
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
            }
            );
    };

    function request() { return { type: actionTypes.SCHOOLS_SETTINGS_REQUEST } }
    function success(settings) { return { type: actionTypes.SCHOOLS_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: actionTypes.SCHOOLS_SETTINGS_FAILURE, error } }
}

function saveSchoolSetting(token, settingKey, settingValue) {
    return dispatch => {
        dispatch(request());

        schoolsService.saveSchoolSetting(token, settingKey, settingValue)
            .then(
            response => {               
                dispatch(success());
            },
            error => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
            }
            );
    };

    function request() { return { type: actionTypes.SCHOOLS_SETTINGS_SAVE_SETTING_REQUEST } }
    function success() { return { type: actionTypes.SCHOOLS_SETTINGS_SAVE_SETTING_SUCCESS } }
    function failure(error) { return { type: actionTypes.SCHOOLS_SETTINGS_SAVE_SETTING_FAILURE, error } }
}