import { actionTypes } from '../actions/types';

const initialState = {
    executions: [],
    executionstatus: [],
    students: [],
    studentsforuser: [],
    groups: [],
    observers: [],
    honorarkraft: [],
    loading: false,
    loadingResults: false,
    loadingHonorarkraft: false,
    loadingstudentsforuser: false,
    loadingError: '',
    createExecutionState: 0,
    updateExecutionState: 0,
    deleteExecutionState: 0,
    createGroupState: 0,
    executioncooptype: [],
    total_pages: 1,
    total: 0,
    foreignUsers: [],
    results: [],
};

const executionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EXECUTIONS_OF_SCHOOL_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_OF_SCHOOL_SUCCESS:
            return {
                ...state,
                executions: action.executions ? action.executions.data : [],
                total_pages: action.executions ? action.executions.total_pages : 1,
                total: action.executions ? action.executions.total : 0,
                loading: false
            };
        case actionTypes.EXECUTIONS_OF_SCHOOL_FAILURE:
            return {
                ...state,
                executions: [],
                total_pages: 1,
                total: 0,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_SUCCESS:
            return {
                ...state,
                executions: action.executions ? action.executions.data : [],
                total_pages: action.executions ? action.executions.total_pages : 1,
                total: action.executions ? action.executions.total : 0,
                loading: false
            };
        case actionTypes.EXECUTIONS_FAILURE:
            return {
                ...state,
                executions: [],
                total: 0,
                total_pages: 1,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_STATUS_SUCCESS:
            return {
                ...state,
                executionstatus: action.status ? action.status.data : [],
                loading: false
            };
        case actionTypes.EXECUTIONS_STATUS_FAILURE:
            return {
                ...state,
                executionstatus: [],
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_GROUPS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.groups ? action.groups.data : [],
                loading: false,
            };
        case actionTypes.EXECUTIONS_GROUPS_FAILURE:
            return {
                ...state,
                groups: [],
                loading: false,
                loadingError: action.error,
            };

        case actionTypes.EXECUTIONS_CREATE_GROUPS_REQUEST:
            return {
                ...state,
                createGroupState: 0,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_CREATE_GROUPS_SUCCESS:
            return {
                ...state,
                createGroupState: 1,
                loading: false,
            };
        case actionTypes.EXECUTIONS_CREATE_GROUPS_FAILURE:
            return {
                ...state,
                createGroupState: 2,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_OBSERVERS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_OBSERVERS_SUCCESS:
            return {
                ...state,
                observers: action.observers ? action.observers.data : [],
                loading: false,
            };
        case actionTypes.EXECUTIONS_OBSERVERS_FAILURE:
            return {
                ...state,
                observers: [],
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_STUDENTS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_STUDENTS_SUCCESS:
            return {
                ...state,
                students: action.students ? action.students.data : [],
                loading: false,
            };
        case actionTypes.EXECUTIONS_STUDENTS_FAILURE:
            return {
                ...state,
                students: [],
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_STUDENTS_FOR_USER_REQUEST:
            return {
                ...state,
                loadingstudentsforuser: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_STUDENTS_FOR_USER_SUCCESS:
            return {
                ...state,
                studentsforuser: action.students ? action.students.data : [],
                loadingstudentsforuser: false,
            };
        case actionTypes.EXECUTIONS_STUDENTS_FOR_USER_FAILURE:
            return {
                ...state,
                studentsforuser: [],
                loadingstudentsforuser: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_CREATE_EXECUTION_REQUEST:
            return {
                ...state,
                createExecutionState: 0,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_CREATE_EXECUTION_SUCCESS:
            return {
                ...state,
                createExecutionState: 1,
                loading: false,
            };
        case actionTypes.EXECUTIONS_CREATE_EXECUTION_FAILURE:
            return {
                ...state,
                createExecutionState: 2,
                loading: false,
            };
        case actionTypes.EXECUTIONS_UPDATE_EXECUTION_REQUEST:
            return {
                ...state,
                updateExecutionState: 0,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_UPDATE_EXECUTION_SUCCESS:
            return {
                ...state,
                updateExecutionState: 1,
                loading: false,
            };
        case actionTypes.EXECUTIONS_UPDATE_EXECUTION_FAILURE:
            return {
                ...state,
                updateExecutionState: 2,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_DELETE_EXECUTION_REQUEST:
            return {
                ...state,
                deleteExecutionState: 0,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_DELETE_EXECUTION_SUCCESS:
            return {
                ...state,
                deleteExecutionState: 1,
                loading: false,
            };
        case actionTypes.EXECUTIONS_DELETE_EXECUTION_FAILURE:
            return {
                ...state,
                deleteExecutionState: 2,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_COOPTYPE_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_COOPTYPE_SUCCESS:
            return {
                ...state,
                executioncooptype: action.cooptype ? action.cooptype.data : [],
                loading: false,
            };
        case actionTypes.EXECUTIONS_COOPTYPE_FAILURE:
            return {
                ...state,
                executioncooptype: [],
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_HONORARKRAFT_REQUEST:
            return {
                ...state,
                loadingHonorarkraft: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_HONORARKRAFT_SUCCESS:
            return {
                ...state,
                honorarkraft: action.honorarkraft ? action.honorarkraft : [],
                loadingHonorarkraft: false,
            };
        case actionTypes.EXECUTIONS_HONORARKRAFT_FAILURE:
            return {
                ...state,
                honorarkraft: [],
                loadingHonorarkraft: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_FOREIGNUSERS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_FOREIGNUSERS_SUCCESS:
            return {
                ...state,
                foreignUsers: action.users ? action.users : [],
                loading: false,
            };
        case actionTypes.EXECUTIONS_FOREIGNUSERS_FAILURE:
            return {
                ...state,
                foreignUsers: [],
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.EXECUTIONS_RESULTS_REQUEST:
            return {
                ...state,
                loadingResults: true,
                loadingError: '',
            };
        case actionTypes.EXECUTIONS_RESULTS_SUCCESS:
            return {
                ...state,
                results: action.results ? action.results : [],
                loadingResults: false,
            };
        case actionTypes.EXECUTIONS_RESULTS_FAILURE:
            return {
                ...state,
                results: [],
                loadingResults: false,
                loadingError: action.error,
            };
        default:
            return state;
    }
};

export default executionsReducer;
