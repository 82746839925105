import { replaceDateTimeSpecialChars } from '../utils/Tools'
import moment from 'moment';
import config from '../config/config';
//595pt x 842pt
export const footer = (props, landscape = false, currentPage = null, pageCount = null, datetime = true) => {
    let footer =
        [
            {
                //LOGOS
                image: 'K7v_Logoleiste',
                width: 391,
                absolutePosition: { x: landscape ? 225 : 103, y: 8 }
            }

        ];

    if (datetime) {
        footer.push({
            //DATETIME
            text: replaceDateTimeSpecialChars(props.intl.formatDate(moment(), { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }) + " " + props.intl.formatTime(moment(), { hour: config.dateFormat.hour, minute: config.dateFormat.minute })),
            absolutePosition: { x: 40, y: 0 },
            fontSize: 8,
        });
    }

    //Page numbers
    if (currentPage && pageCount) {
        footer.push({
            text: currentPage.toString() + ' / ' + pageCount,
            fontSize: 8,
            alignment: "right",
            margin: [40, 0]
        });
    }

    return footer;
}
