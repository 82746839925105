import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button, Label, Alert } from 'reactstrap';
import SingleInputModal from '../components/SingleInputModal';
import PasswordInputModal from '../components/PasswordInputModal';
import { settingsActions } from '../actions';
import config from '../config/config';
import Loading from '../components/Loading';

class SettingsPage extends Component {
    static propTypes = {
        settings: PropTypes.array.isRequired,
        loading: PropTypes.bool,
        loadingError: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedSetting: null,
            openInputModal: false,
            openPwdInputModal: false,
            alertVisible: false,
            alertTextId: '',
            alertColor: 'success',
        }
    }

    componentDidMount() {
        this.props.dispatchGetSettings(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.settingSaveState !== this.props.settingSaveState) {
            if (this.props.settingSaveState === 1) {
                this.setState({
                    alertVisible: true,
                    alertTextId: 'settingspage.save.success',
                    alertColor: 'success',
                    openInputModal: false,
                    openPwdInputModal: false
                });
                setTimeout(this.handleHideAlert, config.alertTimeOut);

                this.props.dispatchGetSettings(this.props.auth.token);

            } else if (this.props.settingSaveState === 2) {
                this.setState({ alertVisible: true, alertTextId: 'settingspage.save.failure.' + this.props.loadingError, alertColor: 'danger' });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }
        }
    }

    handleEdit(settingKey) {
        let selectedSetting = this.props.settings.filter((s) => { return s.key === settingKey })[0];

        if (selectedSetting.type === 'password')
            this.setState({ selectedSetting, openPwdInputModal: true });
        else
            this.setState({ selectedSetting, openInputModal: true });
    }

    handleInputModalConfirm(settingKey, settingValue) {
        // dispatch action to save the setting
        this.props.dispatchSaveSetting(this.props.auth.token, settingKey, settingValue);
    }

    handlePasswordModalConfirm(oldPassword, newPassword) {
        this.props.dispatchSavePassword(this.props.auth.token, oldPassword, newPassword);
    }

    handleInputModalCancel() {
        this.setState({ openInputModal: false, openPwdInputModal: false, selectedSetting: null });
    }

    filterValue(settingValue, settingType) {
        if (settingType === 'password') {
            return '••••••';
        }
        return settingValue;
    }

    handleHideAlert = (e) => {
        this.setState({ alertVisible: false });
    };

    render() {
        return (
            <Container fluid role="main" className="content-margin">
                <Alert isOpen={this.state.alertVisible} toggle={this.handleHideAlert} color={this.state.alertColor} className="fixed-top text-center"><FormattedMessage id={this.state.alertTextId} /></Alert>

                <h1 className="mb-4"><FormattedMessage id="settingspage.title" /></h1>

                {this.props.loading && <Loading />}

                {
                    !this.props.loading && this.props.settings.map(s => {
                        return <Row key={s.key} className="pt-3 pb-3 prop-row">
                            <Col xs="6" sm="4" md="4" lg="4" xl="2">
                                <Row className="prop-row-title"><Col><FormattedMessage id={s.title} /></Col></Row>
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="6" xl="3">
                                <div className="mb-1">
                                    {this.filterValue(s.value, s.type)}
                                </div>

                                {s.editable && <Label className="d-none" for={"SettingEdit" + s.key}><FormattedMessage id="settingspage.label.edit" /></Label>}
                                {s.editable && <Button name={"SettingEdit" + s.key} block className="edit" color="link" onClick={() => this.handleEdit(s.key)}></Button>}
                            </Col>
                        </Row>
                    })
                }

                <SingleInputModal open={this.state.openInputModal} setting={this.state.selectedSetting} handleConfirm={this.handleInputModalConfirm.bind(this)} handleCancel={this.handleInputModalCancel.bind(this)} loading={this.props.loading} />
                <PasswordInputModal open={this.state.openPwdInputModal} setting={this.state.selectedSetting} handleConfirm={this.handlePasswordModalConfirm.bind(this)} handleCancel={this.handleInputModalCancel.bind(this)} loading={this.props.loading} />

            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        settings: state.settings.settings,
        settingSaveState: state.settings.settingSaveState,
        loading: state.settings.loading,
        loadingError: state.settings.loadingError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSettings: (token) => {
            dispatch(settingsActions.getSettings(token));
        },
        dispatchSaveSetting: (token, settingKey, settingValue) => {
            dispatch(settingsActions.saveSetting(token, settingKey, settingValue));
        },
        dispatchSavePassword: (token, oldPassword, newPassword) => {
            dispatch(settingsActions.savePassword(token, oldPassword, newPassword));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPage));