import React from 'react';

const translationsVars = {
    filename: "", //must be set on page if necessary
    name: "", //must be set on page if necessary
    br: <br />,
    n: "\n",
    t: <code> </code>,
    wwwberufetv: <a href="http://www.berufe.tv" target="blank">www.berufe.tv</a>,
    wwwplanetberufede: <a href="http://www.planet-beruf.de" target="blank">www.planet-beruf.de</a>
}

export default translationsVars;