import { actionTypes } from '../actions/types';

const initialState = {
    departments: [],
    departmentsError: '',
    total: 0,
    total_pages: 1,
};

const departmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEPARTMENTS_REQUEST:
            return {
                ...state,
                departmentsError: '',
            };
        case actionTypes.DEPARTMENTS_SUCCESS:
            return {
                ...state,
                departments: action.departments ? action.departments.data : [],
                total: action.departments ? action.departments.total : 1,
                total_pages: action.departments ? action.departments.total_pages : 0,
                page_limit: action.departments ? action.departments.page_limit : 9999999,
                departmentsError: '',
            };
        case actionTypes.DEPARTMENTS_FAILURE:
            return {
                ...state,
                departmentsError: action.error,
                departments: [],
                total: 0,
                total_pages: 1,
                page_limit: 9999999,
            };
        default:
            return state;
    }
};

export default departmentsReducer;
