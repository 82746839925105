import ENV from '../config/env';

export const skillsProfilesService = {
    getSkillsProfile,
    getSkillsProfileStudent,
    createSkillsProfile,
    updateSkillsProfile,
    approvedSkillsProfile

};

function getSkillsProfile(token, executionId, studentsId, honorarkraftId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";


    return fetch(ENV.apiUrl + '/skillsprofiles/' + executionId + '/' + studentsId + honorarkraftId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

function getSkillsProfileStudent(token, executionId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };


    return fetch(ENV.apiUrl + '/skillsprofilesstudent/' + executionId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}


/**
* CREATE SKILLSPROFILE
*/
function createSkillsProfile(token, executionId, studentsId, honorarkraftId, skillsProfile) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ skillsProfile })
    };

    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";

    return fetch(ENV.apiUrl + '/skillsprofiles/' + executionId + '/' + studentsId + honorarkraftId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response);
            }

            return response;

        });

}

/**
* UPDATE SKILLSPROFILE
*/
function updateSkillsProfile(token, honorarkraftId, skillsProfile) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ skillsProfile })
    };

    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";

    return fetch(ENV.apiUrl + '/skillsprofiles/' + skillsProfile.id + honorarkraftId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

/**
* APPROVED SKILLSPROFILE 
*/
function approvedSkillsProfile(token, honorarkraftId, skillsProfile, approved) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ skillsProfile })
    };

    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";

    return fetch(ENV.apiUrl + '/skillsprofiles/approved/' + skillsProfile.id + honorarkraftId + '/' + approved, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

