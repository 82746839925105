let ENV;
if (process.env.NODE_ENV === 'production') {
    ENV = {
        //apiUrl: 'https://benntec-quiz-app.de/kompo7/public/api/v1',
        apiUrl: '%REPLACE_URL%api/v1',

        //subpath: '/kompo7',
        subpath: '',

        //bitUrl: 'https://benntec-quiz-app.de/dev.kompo7.bit/public',
        bitUrl: '%REPLACE_BIT%'
    }
} else {
    ENV = {
        //apiUrl: 'https://benntec-quiz-app.de/dev.kompo7/public/api/v1',
        apiUrl: 'http://localhost:8080/api/v1',

        subpath: '',
        bitUrl: 'https://benntec-quiz-app.de/dev.kompo7.bit/public',
    }
}
export default ENV;
