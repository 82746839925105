import { actionTypes } from '../actions/types';

const initialState = {
    competences: [],
    loading: false,
    loadingstudentObserverRating: false,
    loadingCreateCompetences: false,
    loadingCheckOut: false,
    rating: [],
    editable: false,
    modified: null,
    competencesError: '',
    createStatus: null,
    checkOutStatus: null,
    studentObserverRating: {},
    saving: false,
    checkOut: false,
    checkOutUserId: 0,
    checkOutUserName: '',
    checkOutDatetime: ''
};

const competencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPETENCES_REQUEST:
            return {
                ...state,
                competencesError: '',
                loading: true,
            };
        case actionTypes.COMPETENCES_SUCCESS:
            return {
                ...state,
                loading: false,
                competences: action.competences ? action.competences.competences : [],
                rating: action.competences ? action.competences.rating : [],
                editable: action.competences ? action.competences.editable : false,
                modified: action.competences && action.competences.rating && action.competences.rating.length > 0 ? action.competences.rating[0].modified : null,
                competencesError: '',
                checkOut: action.competences ? action.competences.check_out : false,
                checkOutUserId: action.competences ? action.competences.check_out_users_id : 0,
                checkOutUserName: action.competences ? action.competences.check_out_users_name : '',
                checkOutDatetime: action.competences ? action.competences.check_out_datetime : '',
            };
        case actionTypes.COMPETENCES_FAILURE:
            return {
                ...state,
                loading: false,
                competencesError: action.error,
                competences: [],
                rating: [],
                editable: false,
                modified: null,
                checkOut: false,
                checkOutUserId: 0,
                checkOutUserName: '',
                checkOutDatetime: ''
            };
        case actionTypes.COMPETENCES_KOMPOG_REQUEST:
            return {
                ...state,
                competencesError: '',
                loading: true,
            };
        case actionTypes.COMPETENCES_KOMPOG_SUCCESS:
            return {
                ...state,
                loading: false,
                competences: action.competences ? action.competences.competences : [],
                editable: action.competences ? action.compettences.editable : false,
                modified: null,
                competencesError: '',
                checkOut: action.competences ? action.competences.check_out : false,
                checkOutUserId: action.competences ? action.competences.check_out_users_id : 0,
                checkOutUserName: action.competences ? action.competences.check_out_user_name : '',
                checkOutDatetime: action.competences ? action.competences.check_out_datetime : ''
            };
        case actionTypes.COMPETENCES_KOMPOG_FAILURE:
            return {
                ...state,
                loading: false,
                competencesError: action.error,
                competences: [],
                editable: false,
                modified: null,
                checkOut: false,
                checkOutUserId: 0,
                checkOutUserName: '',
                checkOutDatetime: ''
            };
        case actionTypes.COMPETENCES_STUDENT_OBSERVER_REQUEST:
            return {
                ...state,
                loadingstudentObserverRating: true,
                competencesError: '',

            };
        case actionTypes.COMPETENCES_STUDENT_OBSERVER_SUCCESS:
            return {
                ...state,
                loadingstudentObserverRating: false,
                competences: action.competences ? action.competences.competences : [],
                studentObserverRating: action.competences ? action.competences.rating : [],
                editable: false,
                modified: null,
                competencesError: '',
            };
        case actionTypes.COMPETENCES_STUDENT_OBSERVER_FAILURE:
            return {
                ...state,
                loadingstudentObserverRating: false,
                competencesError: action.error,
                competences: [],
                studentObserverRating: {},
                editable: false,
                modified: null,
            };
        case actionTypes.COMPETENCES_CREATE_USERS_REQUEST:
            return {
                ...state,
                competencesError: '',
                loadingCreateCompetences: true,
                createStatus: null,
                saving: true,
            };
        case actionTypes.COMPETENCES_CREATE_USERS_SUCCESS:
            return {
                ...state,
                competencesError: '',
                loadingCreateCompetences: false,
                createStatus: action.status,
                saving: false,
                checkOut: false,
                checkOutUserId: 0,
                checkOutUserName: '',
                checkOutDatetime: ''
            };
        case actionTypes.COMPETENCES_CREATE_USERS_FAILURE:
            return {
                ...state,
                competencesError: action.error,
                loadingCreateCompetences: false,
                createStatus: action.status,
                saving: false,
                checkOut: false,
                checkOutUserId: 0,
                checkOutUserName: '',
                checkOutDatetime: ''
            };
        case actionTypes.COMPETENCES_CREATE_USERS_KOMPOG_REQUEST:
            return {
                ...state,
                competencesError: '',
                loadingCreateCompetences: true,
                createStatus: null,
                saving: true
            };
        case actionTypes.COMPETENCES_CREATE_USERS_KOMPOG_SUCCESS:
            return {
                ...state,
                competencesError: '',
                loadingCreateCompetences: false,
                createStatus: action.status,
                saving: false,
                checkOut: false,
                checkOutUserId: 0,
                checkOutUserName: '',
                checkOutDatetime: ''
            };
        case actionTypes.COMPETENCES_CREATE_USERS_KOMPOG_FAILURE:
            return {
                ...state,
                competencesError: action.error,
                loadingCreateCompetences: false,
                createStatus: action.status,
                saving: false,
                checkOut: false,
                checkOutUserId: 0,
                checkOutUserName: '',
                checkOutDatetime: ''
            };

        case actionTypes.COMPETENCES_CHECK_OUT_REQUEST:
            return {
                ...state,
                loadingCheckOut: true,
                checkOutStatus: null
            };
        case actionTypes.COMPETENCES_CHECK_OUT_SUCCESS:
            if (action.response.status === 'checkout-complete') {
                return {
                    ...state,
                    loadingCheckOut: false,
                    checkOutStatus: action.response.status,
                    checkOut: action.response.check_out,
                    checkOutUserId: action.response.check_out_users_id,
                    checkOutUserName: action.response.check_out_user_name,
                    checkOutDatetime: action.response.check_out_datetime
                };
            } else {
                return {
                    ...state,
                    loadingCheckOut: false,
                    checkOutStatus: action.response.status
                };
            }

        case actionTypes.COMPETENCES_CHECK_OUT_FAILURE:
            return {
                ...state,
                loadingCheckOut: false,
                checkOutStatus: action.status
            };

        case actionTypes.COMPETENCES_CHECK_IN_REQUEST:
            return {
                ...state,
                loadingCheckOut: true,
            };
        case actionTypes.COMPETENCES_CHECK_IN_SUCCESS:

            return {
                ...state,
                loadingCheckOut: false,
                checkOutStatus: null,
                checkOut: false,
                checkOutUserId: 0,
                checkOutUserName: '',
                checkOutDatetime: ''
            };


        case actionTypes.COMPETENCES_CHECK_IN_FAILURE:
            return {
                ...state,
                loadingCheckOut: false,
                checkOutStatus: action.status
            };

        default:
            return state;
    }
};

export default competencesReducer;
