import { actionTypes } from './types';
import { kompoGResultService } from '../services/kompoGResultService';

export const kompoGResultActions = {
    getResult,
    getAllPupilResults,
    saveResult,
    updateResultTeacher
};

function getResult(token, executionId, studentsId, honorarkraftId) {
    return dispatch => {
        dispatch(request({getResult}));

        kompoGResultService.getResult(token, executionId, studentsId, honorarkraftId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.KOMPOGRESULT_REQUEST } }
    function success(result) {return { type: actionTypes.KOMPOGRESULT_SUCCESS, result } }
    function failure(error) {return { type: actionTypes.KOMPOGRESULT_FAILURE, error } }
}

function getAllPupilResults(token, executionId, order, desc) {
    return dispatch => {
        dispatch(request({getAllPupilResults}));

        kompoGResultService.getAllPupilResults(token, executionId, order, desc)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.KOMPOGRESULT_ALLPUPILS_REQUEST } }
    function success(results) {return { type: actionTypes.KOMPOGRESULT_ALLPUPILS_SUCCESS, results } }
    function failure(error) {return { type: actionTypes.KOMPOGRESULT_ALLPUPILS_FAILURE, error } }
}


function saveResult(token, executionId, result) {
    return dispatch => {
        dispatch(request({saveResult}));

        kompoGResultService.saveResult(token, executionId, result)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) {return { type: actionTypes.KOMPOGRESULT_SAVE_REQUEST, status }}
    function success(status) {return { type: actionTypes.KOMPOGRESULT_SAVE_SUCCESS, status }}
    function failure(status) {return { type: actionTypes.KOMPOGRESULT_SAVE_FAILURE, status }}
}

function updateResultTeacher(token, executionId, feedback) {
    return dispatch => {
        dispatch(request({updateResultTeacher}));

        kompoGResultService.updateResultTeacher(token, executionId, feedback)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) {return { type: actionTypes.KOMPOGRESULT_FEEDBACK_REQUEST, status }}
    function success(status) {return { type: actionTypes.KOMPOGRESULT_FEEDBACK_SUCCESS, status }}
    function failure(status) {return { type: actionTypes.KOMPOGRESULT_FEEDBACK_FAILURE, status }}
}
