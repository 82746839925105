import ENV from '../config/env';
import fileDownload from 'js-file-download';

export const documentsService = {
    getDocuments,
    createDocumentsStructur,
    updateDocumentsStructur,
    deleteDocumentsStructur,
    sortDocumentsStructur,
    deleteDocument,
    createDocument,
    updateDocument,
    sortDocuments,
    download
};

async function getDocuments(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/documents', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });
}


async function createDocument(token, document, importData) {

    const data = {
        document: document,
        importData: importData
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(ENV.apiUrl + '/documents', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response;
        });
}

async function updateDocument(token, document, importData) {

    const data = {
        document: document,
        importData: importData
    };

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };

    return fetch(ENV.apiUrl + '/documents', requestOptions)
        .then(response => {
            if (!response.ok && response.status !== 409) {
                return Promise.reject(response.statusText);
            } else if (!response.ok && response.status === 409) {
                return response.json();
            }

            return response;
        });
}

async function deleteDocument(token, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/documents/' + id, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;
        });
}

async function sortDocuments(token, documents) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(documents)
    };

    return fetch(ENV.apiUrl + '/documents/sort', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response;
        });
}

async function createDocumentsStructur(token, structur) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(structur)
    };

    return fetch(ENV.apiUrl + '/documents/structur', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response;
        });
}

async function updateDocumentsStructur(token, structur) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(structur)
    };

    return fetch(ENV.apiUrl + '/documents/structur', requestOptions)
        .then(response => {
            if (!response.ok && response.status !== 409) {
                return Promise.reject(response.statusText);
            } else if (!response.ok && response.status === 409) {
                return response.json();
            }

            return response;
        });
}

async function sortDocumentsStructur(token, structurs) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(structurs)
    };

    return fetch(ENV.apiUrl + '/documents/structur/sort', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response;
        });
}

async function deleteDocumentsStructur(token, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/documents/structur/' + id, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;
        });
}

function download(token, doc) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream',
            'Authorization': 'Bearer ' + token
        },
    };

    return fetch(ENV.apiUrl + '/documents/download/' + doc.id, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.blob();

        }).then(response => {
            fileDownload(response, doc.filename, 'application/octet-stream');    
            return;
        });
}