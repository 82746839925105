import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button, Label, Alert } from 'reactstrap';
import SingleInputModal from '../components/SingleInputModal';
import config from '../config/config';
import { schoolsActions } from '../actions';
import Loading from '../components/Loading';

class SchoolPage extends Component {
    static propTypes = {
        settings: PropTypes.array.isRequired,
        loading: PropTypes.bool,
        loadingError: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedSetting: null,
            openInputModal: false,
            alertVisible: false,
            alertTextId: '',
            alertColor: 'success',
        }
    }

    componentDidMount() {
        this.props.dispatchGetSchooltypes(this.props.auth.token);
        this.props.dispatchGetSchoolSettings(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.settingSaveState !== this.props.settingSaveState) {
            if (this.props.settingSaveState === 1) {
                this.setState({
                    alertVisible: true,
                    alertTextId: 'school.save.success',
                    alertColor: 'success',
                    openInputModal: false
                });
                setTimeout(this.handleHideAlert, config.alertTimeOut);

                this.props.dispatchGetSchoolSettings(this.props.auth.token);

            } else if (this.props.settingSaveState === 2) {
                this.setState({ alertVisible: true, alertTextId: 'school.save.failure.' + this.props.loadingError, alertColor: 'danger' });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }
        }
    }

    handleEdit(settingKey) {
        let selectedSetting = this.props.settings.filter((s) => { return s.key === settingKey })[0];

        this.setState({ selectedSetting, openInputModal: true });
    }

    handleInputModalConfirm(settingKey, settingValue) {
        // dispatch action to save the setting
        this.props.dispatchSaveSchoolSetting(this.props.auth.token, settingKey, settingValue);
    }

    handleInputModalCancel() {
        this.setState({ openInputModal: false, selectedSetting: null });
    }

    handleHideAlert = (e) => {
        this.setState({ alertVisible: false });
    };

    getFormattedValue(setting) {
        if (setting.value === null || setting.value === '') {
            return '-';
        }
        if (setting.displayvalue) {
            return <FormattedMessage id={setting.displayvalue} />;
        }
        return setting.value;
    }

    render() {
        return (
            <Container fluid role="main" className="content-margin">
                <Alert isOpen={this.state.alertVisible} toggle={this.handleHideAlert} color={this.state.alertColor} className="fixed-top text-center"><FormattedMessage id={this.state.alertTextId} /></Alert>

                <h1 className="mb-4"><FormattedMessage id="school.title" /></h1>

                {this.props.loading && <Loading />}
                {
                    !this.props.loading && this.props.settings.map(s => {
                        return <Row key={s.key} className="pt-3 pb-3 prop-row">
                            <Col xs="6" sm="4" md="4" lg="4" xl="2">
                                <Row className="prop-row-title"><Col><FormattedMessage id={s.title} /></Col></Row>
                            </Col>
                            <Col xs="12" sm="6" md="6" lg="6" xl="3">
                                <div className="mb-1">
                                    {this.getFormattedValue(s)}
                                </div>

                                {s.editable && <Label className="d-none" for={"SettingEdit" + s.key}><FormattedMessage id="school.label.edit" /></Label>}
                                {s.editable && <Button name={"SettingEdit" + s.key} block className="edit" color="link" onClick={() => this.handleEdit(s.key)}></Button>}
                            </Col>
                        </Row>
                    })
                }

                <SingleInputModal open={this.state.openInputModal} setting={this.state.selectedSetting} handleConfirm={this.handleInputModalConfirm.bind(this)} handleCancel={this.handleInputModalCancel.bind(this)} loading={this.props.loading} schooltypes={this.props.schooltypes} />

            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        settings: state.schools.settings,
        settingSaveState: state.schools.settingSaveState,
        loading: state.schools.settingsLoading,
        loadingError: state.schools.settingsLoadingError,
        schooltypes: state.schools.schooltypes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolSettings: (token) => {
            dispatch(schoolsActions.getSchoolSettings(token));
        },
        dispatchSaveSchoolSetting: (token, settingKey, settingValue) => {
            dispatch(schoolsActions.saveSchoolSetting(token, settingKey, settingValue));
        },
        dispatchGetSchooltypes: (token) => {
            dispatch(schoolsActions.getSchooltypes(token, "name", false));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchoolPage));