import ENV from '../config/env';

export const departmentsService = {
    getDepartments,
};

async function getDepartments(token, order, desc, enabled, page, pagelimit) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };
    enabled = enabled !== null ? ("&enabled=" + enabled) : "";
    page = page !== null ? ("&page=" + page) : "";
    pagelimit = pagelimit !== null ? ("&pagelimit=" + pagelimit) : "";

    return fetch(ENV.apiUrl + '/departments?order=' + order + "&desc=" + (desc ? "1" : "0") + enabled + page + pagelimit, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });

}

