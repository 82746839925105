import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isAllowedCombination } from '../models/Authorization';

class RolesCheckbox extends React.Component {


    static propTypes = {
        selectedRoles: PropTypes.array,
        handleRoles: PropTypes.func,
        roles: PropTypes.array,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        filter: PropTypes.array,
        disabled: PropTypes.bool,
        checkAllowedCombination: PropTypes.bool,
        showAsRadio: PropTypes.bool
    };

    static defaultProps = {
        roles: [],
        withEmpty: false,
        invalid: false,
        filter: [],
        disabled: false,
        label: "roles.label.default",
        checkAllowedCombination: true,
        showAsRadio: false
    };

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
        }
    }

    componentDidMount() {
        if (this.props.roles.length > 0) {
            this.setState({ roles: this.props.roles.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });
        } else {
            this.setState({ roles: [] })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.roles !== this.props.roles) {
            if (this.props.roles.length > 0) {
                this.setState({ roles: this.props.roles.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });
            } else {
                this.setState({ roles: [] })
            }
        }
    }

    createRolesItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "roles.option.pleaseselect" })}</option>);
        this.state.roles.forEach((role) => {
            items.push(<option key={role.id} value={role.id} >{this.props.intl.formatMessage({ id: role.name })}</option>);
        })
        return items;
    }

    handleRole = async (e) => {
        let id = parseInt(e.target.value, 10);
        let roles = [];
        let role = this.state.roles.filter(el => el.id === id)[0] || null;
        if (this.props.showAsRadio) {
            roles.push(role);
        } else {
            //check if role already selected
            this.props.selectedRoles.forEach((r) => {
                if (role === null || r.id !== role.id) {
                    roles.push(r);
                } else if (r.id === role.id) {
                    role = null;
                }
            });
            //if role not selected 
            if (role) {
                roles.push(role);
            }
        }
        await this.props.handleRoles(roles);
    }

    getChecked = (role) => {
        if (this.props.selectedRoles && role) {
            return this.props.selectedRoles.filter((sr) => {
                return sr.id === role.id;
            }).length > 0;
        } else {
            return false;
        }
    }

    render() {
        return (
            <Container fluid className={this.state.roles.length > 1 ? this.props.className : "d-none " + this.props.className}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "roles.label.default" ? "fw-bold" : "d-none"} for="roles"><FormattedMessage id={this.props.label} /></Label>
                    <Row className="m-1">
                        {
                            this.state.roles.map((role, index) => {
                                return (
                                    <Col xs="12" md="6" lg="6" key={index} className="mb-0 pb-0">
                                        <Label className="mb-0 pb-0">
                                            <Input invalid={this.props.invalid} disabled={this.props.checkAllowedCombination && !isAllowedCombination(this.props.selectedRoles, role)} checked={this.getChecked(role)} type={this.props.showAsRadio ? "radio" : "checkbox"} name="roles" id="roles" value={role.id} onChange={this.handleRole.bind(this)} />
                                            <FormattedMessage id={role.name} />
                                        </Label>

                                    </Col>
                                )
                            })
                        }
                    </Row>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <Row className="mt-0 pt-0">
                            <Col xs="12" className="mt-0 pt-0 invalid-feedback d-block" >
                                <div className="ps-1"><FormattedMessage id={this.props.feedbacklabel} /></div>
                            </Col>
                        </Row>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesCheckbox));