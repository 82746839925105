import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Table, Label, Input, Row, Col, Alert } from 'reactstrap';
import Confirm from '../components/Confirm';
import InquiryModal from '../components/InquiryModal';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import SchoolSelect from '../components/SchoolSelect';
import Pages from '../components/Pages';
import config from '../config/config';
import { schoolsActions, filterActions, inquiryActions } from '../actions';

class InquiryReceivedPage extends Component {
    static propTypes = {
        // redux dispatch
        dispatchRejected: PropTypes.func.isRequired,
        dispatchPastInquiry: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            totalpages: 1,
            pages: 5,
            pagelimit: 10,
            loading: false,
            confirm: false,
            editData: null,
            deleteId: null,
            schools: [],
            order: "",
            desc: false,
            search: "",
            th: [
                { text: "inquiryreceived.th.school", order: "school_name", width: "20%" },
                { text: "inquiryreceived.th.startdate", order: "start", width: "15%" },
                { text: "inquiryreceived.th.enddate", order: "end", width: "15%" },
                { text: "inquiryreceived.th.senddate", order: "send", width: "15%" },
                { text: "inquiryreceived.th.rejecteddate", order: "rejected", width: "15%" },
                { text: "inquiryreceived.th.state", order: "state", width: "10%" },
                { text: "inquiryreceived.th.action", className: "table-border-left-action", width: "10%" }
            ],
            tb: [],
            tbshow: ["school", "execution.start", "execution.end", "send", "rejected", "state"],
            openModalEdit: false,
            alert: { visible: false, textId: "alert.save", color: "success" },
        }

    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.schoolId !== this.props.schoolId || prevProps.rejected !== this.props.rejected || prevProps.past !== this.props.past) {
            this.getData();
        }

        /* alert create and update */
        if (prevProps.updateInquiryState !== this.props.updateInquiryState) {
            if (this.props.updateInquiryState === 1) {
                this.setState({ openModalEdit: false, alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.updateInquiryState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }

            //Update count of open inquirys
            this.props.dispatchGetCountOfOpenInquirys(this.props.auth.token);
        }

        if (prevProps.inquiry !== this.props.inquiry) {
            let tmpInquiry = this.props.inquiry;

            // replace state number with text
            tmpInquiry.forEach((el) => {
                el.state = this.props.intl.formatMessage({ id: "inquiry.state." + el.state });
            });
            this.setState({ tb: tmpInquiry });
        }
    }

    hideAlert() {
        this.setState({ alert: { visible: false, textId: "alert.save", color: "success" } })
    }

    getData() {
        this.props.dispatchGetInquiryBySchoolId(this.props.auth.token, this.props.schoolId, this.state.order, this.state.desc, this.state.page, this.state.pagelimit, !this.props.rejected, this.props.past);
    }


    handleRejected = (e) => {
        this.props.dispatchRejected();
    }

    handlePast = (e) => {
        this.props.dispatchPastInquiry();
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    order = (order, desc, e) => {
        this.setState({ order: order, desc: desc });
        this.props.dispatchGetInquiryBySchoolId(this.props.auth.token, this.props.schoolId, order, desc, this.state.page, this.state.pagelimit, !this.props.rejected, this.props.past);
    }

    handleEdit = (id) => {
        this.handleOpenModalEdit(id)
    }

    handleOpenModalEdit = (id, e) => {

        let index = this.state.tb.map((value) => { return value.id }).indexOf(id);
        if (id === -1 || index === -1) {
            this.setState({ editData: null })
        } else {
            this.setState({ editData: this.state.tb[index] });
        }
        this.setState({
            openModalEdit: !this.state.openModalEdit
        });
    }

    save(inquiry) {
        if (inquiry && inquiry.id) {
            this.props.dispatchUpdateInquiry(this.props.auth.token, inquiry);
        }
    }

    handlePage = (page) => {
        this.setState({ page: page });
        this.props.dispatchGetInquiryBySchoolId(this.props.auth.token, this.props.schoolId, this.state.order, this.state.desc, page, this.state.pagelimit, !this.props.rejected, this.props.past);
    }

    render() {


        return (
            <Container fluid role="main" className="content-margin">
                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                <Confirm open={this.state.confirm} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.delete} headertext="confirm.header.delete" bodytext="confirm.body.delete" />
                <h1><FormattedMessage id="inquiryreceived.label.inquiry" /></h1>
                { /* Select School */}
                <Row>
                    <Col xs="12" sm="4" md="3">
                        <SchoolSelect className="mb-3 p-0" label="inquiryreceived.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                </Row>
                {/* Filter */}
                <Container fluid className="ps-4">
                    <Row>
                        <Col xs="12" md="3">
                            <Label for="rejected">
                                <Input id="rejected" name="rejected" type="checkbox" defaultChecked={this.props.rejected} value={this.props.rejected} onChange={this.handleRejected} />
                                <FormattedMessage id="inquiryreceived.label.rejected" />
                            </Label>
                        </Col>
                        <Col xs="12" md="3">
                            <Label for="past">
                                <Input id="past" name="past" type="checkbox" defaultChecked={this.props.past} value={this.props.past} onChange={this.handlePast} />
                                <FormattedMessage id="inquiryreceived.label.past" />
                            </Label>
                        </Col>
                    </Row>
                </Container>

                { /* Table */}
                <Container fluid className="m-0 p-0 border">
                    <Table striped bordered className="m-0 p-0">
                        <TableHead data={!this.props.rejected ? this.state.th.filter((el, i) => { return i !== 4 }) : this.state.th} order={this.order} />
                        <TableBody loading={this.props.loading} data={this.state.tb} show={!this.props.rejected ? this.state.tbshow.filter((el, i) => { return i !== 4 }) : this.state.tbshow} edit={this.handleEdit.bind(this)} checkEditable />
                    </Table>
                </Container>

                { /* Pages */}
                <Container fluid className="d-flex justify-content-end mt-3 p-0">
                    <Pages pages={parseInt(this.state.pages, 10)} totalpages={parseInt(this.state.totalpages, 10)} page={parseInt(this.state.page, 10)} changePage={this.handlePage} />
                </Container>

                { /* MODAL for edit */}
                <InquiryModal open={this.state.openModalEdit} toggle={this.handleOpenModalEdit} inquiry={this.state.editData} schoolId={this.props.schoolId} save={this.save.bind(this)} />

            </Container>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        rejected: state.filter.inquiry_rejected,
        past: state.filter.inquiry_past,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        inquiry: state.inquiry.inquiryOfSchool ? state.inquiry.inquiryOfSchool : [],
        total: state.inquiry.total ? state.inquiry.total : [],
        total_pages: state.inquiry.total_pages ? state.inquiry.total_pages : [],
        loading: state.inquiry.loading,
        updateInquiryState: state.inquiry.updateInquiryState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchRejected: () => {
            dispatch(filterActions.inquiry_rejected());
        },
        dispatchPastInquiry: () => {
            dispatch(filterActions.inquiry_past());
        },
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, true));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetInquiryBySchoolId: (token, schoolId, order, desc, page, pagelimit, rejected, past) => {
            dispatch(inquiryActions.getInquiryBySchoolId(token, schoolId, order, desc, page, pagelimit, rejected, past));
        },
        dispatchUpdateInquiry: (token, inquiry) => {
            dispatch(inquiryActions.updateInquiry(token, inquiry));
        },
        dispatchGetCountOfOpenInquirys: (token) => {
            dispatch(inquiryActions.getCountOfOpenInquirys(token));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(InquiryReceivedPage));