import { actionTypes } from '../actions/types';

const initialState = {
    settings: [],
    loading: false,
    loadingError: '',
    settingSaveState: 0,
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETTINGS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.settings ? action.settings : [],
                loading: false,
            };
        case actionTypes.SETTINGS_FAILURE:
            return {
                ...state,
                settings: [],
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.SETTINGS_SAVE_SETTING_REQUEST:
            return {
                ...state,
                settingSaveState: 0,
                loading: true,
                loadingError: '',
            };
        case actionTypes.SETTINGS_SAVE_SETTING_SUCCESS:
            return {
                ...state,
                settingSaveState: 1,
                loading: false,
            };
        case actionTypes.SETTINGS_SAVE_SETTING_FAILURE:
            return {
                ...state,
                settingSaveState: 2,
                loading: false,
                loadingError: action.error,
            };
        case actionTypes.SETTINGS_SAVE_PASSWORD_REQUEST:
            return {
                ...state,
                settingSaveState: 0,
                loading: true,
                loadingError: '',
            };
        case actionTypes.SETTINGS_SAVE_PASSWORD_SUCCESS:
            return {
                ...state,
                settingSaveState: 1,
                loading: false,
            };
        case actionTypes.SETTINGS_SAVE_PASSWORD_FAILURE:
            return {
                ...state,
                settingSaveState: 2,
                loading: false,
                loadingError: action.error,
            };
        default:
            return state;
    }
};

export default settingsReducer;
