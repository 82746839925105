import React, { Component } from 'react';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Col, Row, FormText } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
// import ReactPasswordStrength from 'react-password-strength';
import PasswordStrengthBar from 'react-password-strength-bar';
import PropTypes from 'prop-types';

class PasswordInputModal extends Component {
    static propTypes = {
        setting: PropTypes.object, // { key: 'firstname', title: 'Vorname:', value: 'Max', type: 'string' }
        handleConfirm: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            valid: false,
            validRepeat: false,
            passwordScore: -1,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.setting !== this.props.setting) {
            this.setState({
                oldPassword: '',
                newPassword: '',
                newPasswordRepeat: '',
                valid: false,
                validRepeat: false,
                passwordScore: -1,
            });
        }
    }

    handleOldPwdChange(e) {
        this.setState({ oldPassword: e.target.value });
    }

    handlePwdChange(e) {
        this.setState({ newPassword: e.target.value, valid: this.inputValid(e.target.value) });
    }

    handleChangeScore = (score, feedback) => {
        this.setState({ passwordScore: score })
    }

    handlePwdRepeat(e) {
        let areEqual = e.target.value === this.state.newPassword;
        this.setState({
            newPasswordRepeat: e.target.value,
            validRepeat: this.inputValid(e.target.value) && areEqual,
        });
    }

    getPwdScoreText(score) {
        let scoreToTextAndStyle = [{ text: '-', style: '' }];
        for (let i = 0; i < 5; i++) {
            scoreToTextAndStyle.push({
                text: this.props.intl.formatMessage({ id: 'passwordinputmodal.password.strength.' + i }),
                style: 'pwd-strength-' + i
            });
        }
        return <span className={scoreToTextAndStyle[score + 1].style + ' small pwd-strength-text-label'}>{scoreToTextAndStyle[score + 1].text}</span>;
    }

    inputValid(value) {
        // trim the inserted value first
        let trimmedValue = value.trim();

        if (this.props.setting.constraints) {
            for (let i = 0; i < this.props.setting.constraints.length; i++) {
                let c = this.props.setting.constraints[i];

                if (!this.checkConstraint(trimmedValue, c)) {
                    return false;
                }
            }
        }

        // always check for empty string
        return trimmedValue !== '';
    }

    checkConstraint(value, constraint) {
        if (constraint.type === 'maxlength') {
            return value.length <= constraint.value;
        } else if (constraint.type === 'minlength') {
            return value.length >= constraint.value;
        } else if (constraint.type === 'regex') {
            var re = new RegExp(constraint.value);
            return re.test(value);
        }
        return true;
    }

    render() {
        if (this.props.setting === null) {
            return null;
        }

        const inputProps = {
            placeholder: "",
            autoFocus: false,
            className: 'form-control-sm form-control pwd-strength-input',
        };

        return (
            <Container>
                <Modal isOpen={this.props.open} size="lg" className="modal-height-100 modal-width-100" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.open} >
                    <ModalHeader><FormattedMessage id="passwordinputmodal.title" /></ModalHeader>

                    <ModalBody>
                        <Row className="mb-4">
                            <Col xs="5">
                                <FormattedMessage id="passwordinputmodal.oldpassword.title" />
                            </Col>
                            <Col xs="7">
                                <Input autoFocus bsSize="sm" name="oldPassword" id="oldPassword" type="password" value={this.state.oldPassword} onChange={this.handleOldPwdChange.bind(this)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="5">
                                <FormattedMessage id="passwordinputmodal.newpassword.title" />
                            </Col>
                            <Col xs="7">
                                <div className="mb-1">
                                    <Input
                                        type="password"
                                        bsSize="sm"
                                        value={this.state.newPassword}
                                        onChange={this.handlePwdChange.bind(this)}
                                        minLength={6}
                                        name="newPassword"
                                        id="newPassword"
                                    />
                                    <PasswordStrengthBar
                                        password={this.state.newPassword}
                                        minLength={6}
                                        onChangeScore={this.handleChangeScore.bind(this)}
                                    />
                                    <Row>
                                        <Col>
                                            {
                                                this.props.setting.constraints.length > 0 &&
                                                this.props.setting.constraints.map((c, index) =>
                                                    <FormText key={c.type + index} style={{ display: 'block' }}>
                                                        <FormattedMessage id={'settings.constraints.title.' + c.type} />
                                                        {c.value}
                                                    </FormText>)
                                            }
                                        </Col>

                                        {
                                            //this.state.newPassword !== '' && this.state.passwordScore > -1 &&
                                            <Col style={{ textAlign: 'right' }}>
                                                <span className="small pwd-strength-label">
                                                    <FormattedMessage id="passwordinputmodal.password.strength.label" />&nbsp;
                                                </span>

                                                {this.getPwdScoreText(this.state.passwordScore)}
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="5">
                                <FormattedMessage id="passwordinputmodal.repeatpassword.title" />
                            </Col>
                            <Col xs="7">
                                <Input bsSize="sm" valid={this.state.validRepeat} name="repeatPassword" id="repeatPassword" type="password" value={this.state.newPasswordRepeat} onChange={this.handlePwdRepeat.bind(this)} />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button disabled={this.props.loading} color="primary" onClick={this.props.handleCancel}><FormattedMessage id="button.cancel" /></Button>
                        <Button disabled={this.state.oldPassword.length === 0 || !this.state.valid || !this.state.validRepeat || this.props.loading /*|| !this.state.changed*/} color="primary" onClick={() => this.props.handleConfirm(this.state.oldPassword, this.state.newPassword)}><FormattedMessage id="button.save" /></Button>
                    </ModalFooter>
                </Modal>
            </Container >
        );
    }
}

export default injectIntl(PasswordInputModal);