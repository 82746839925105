import { departmentsService } from '../services';
import { actionTypes } from './types';

export const departmentsActions = {
    getDepartments,
};

function getDepartments(token, order, desc, enabled, page, pagelimit) {
    return dispatch => {
        dispatch(request({ getDepartments }));

        departmentsService.getDepartments(token, order, desc, enabled, page, pagelimit)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(departments) { return { type: actionTypes.DEPARTMENTS_REQUEST, departments } }
    function success(departments) { return { type: actionTypes.DEPARTMENTS_SUCCESS, departments } }
    function failure(error) { return { type: actionTypes.DEPARTMENTS_FAILURE, error } }
}
