import {authorizationList, allowdRolesCombinations} from '../config/authorization';



export const CheckAuthorization = (id, roles, procedure = 0) => {
    let newProcedureId = 0;
    if (procedure === 2) { newProcedureId = 8; }
    if (procedure === 1) { newProcedureId = 9; }
    if (authorizationList && roles) {
        let auth = false;
        roles.forEach((role) => {
            if (authorizationList[id] && authorizationList[id].indexOf(role) > -1 && authorizationList[id].indexOf(newProcedureId) > -1) {
                auth =  true;               
            } else if (!authorizationList[id]) {
                console.log('Error-Authorization: ID "' + id + '" not found.');
            }            
        })
        return auth;
    }
    return false;

};

export const isAllowedCombination = (roles, role = null) => {

    let isAllowed = true;

    if (Array.isArray(roles)) {
        let tmpRoles = JSON.parse(JSON.stringify(roles));
        if (role) {
            tmpRoles.push(role);
        }
        tmpRoles.forEach((role) => {
            let checkroles = tmpRoles.filter((r) => { return role.id !== r.id })
            checkroles.forEach((checkrole) => {
                if (allowdRolesCombinations[role.id].indexOf(checkrole.id) === -1) {
                    isAllowed = false;
                }
            })
        })
    }
    return isAllowed;
}
