import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { Container, Label, Button, Alert, Row, Col } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import Confirm from '../components/Confirm';
import GeneratePDF from '../components/GeneratePDF';
import config from '../config/config';
import moment from 'moment';
import PropTypes from 'prop-types';
import { competencesActions } from '../actions';
import translationsVars from '../translations/translationsVars';
import { generateSelfAssessmentPDF } from '../pdf';
import Loading from '../components/Loading';


class SelfAssessmentPage extends Component {

    static propTypes = {
        dispatchGetCompetences: PropTypes.func.isRequired,
        dispatchCreateUsersCompetences: PropTypes.func.isRequired,
        executionId: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            order: "",
            desc: false,
            enabled: true,
            studentsId: -1,
            modified: null,
            confirm: false,
            note: false,
            rating: [],
            alert: { visible: false, textId: "alert.save", color: "success" },
            translationsVars: translationsVars,
            firstLoad: true,
        }

    }

    componentDidMount() {
        //this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, this.state.enabled);
        this.setState({ translationsVars: { ...this.state.translationsVars, filename: this.props.auth.firstname + "_" + this.props.auth.lastname } });

        //Add confirm dialog if the user change the page
        this.props.history.block((location, action) => {
            if (this.state.rating.length > 0 && this.props.editable) {
                this.setState({ nextPage: location });
                this.toggleNote();
                return false;
            } else {
                return true;
            }
        });
    }

    //called when the user want to change the page(route)
    goToNextPage() {
        if (this.state.nextPage) {
            //Remove the confirm dialog          
            this.props.history.block((location, action) => {
                return true;
            });
            //push the new page
            this.props.history.push(this.state.nextPage);
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.executionId !== -1 && (this.state.firstLoad || prevProps.executionId !== this.props.executionId)) {
            this.setState({ firstLoad: false });

            this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, this.state.enabled);
        }

        if (prevProps.rating !== this.props.rating) {
            this.setState({ rating: this.props.rating });
        }

        if (prevProps.createStatus !== this.props.createStatus) {
            if (this.props.createStatus && this.props.createStatus.ok) {
                this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
                this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, this.state.enabled);
            } else if (this.props.createStatus) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }

        }
    }

    handleHideAlert = (e) => {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    };

    handleCheckbox = async (id, value) => {
        let newState = { ...this.state }
        let changed = false;

        this.state.rating.forEach(rating => {
            if (rating && rating.competences_id === id) {
                if (rating.rating === value) {
                    rating.rating = 0;
                } else {
                    rating.rating = value;
                }
                changed = true;
            }
        });

        if (!changed) {
            newState.rating.push({ competences_id: id, rating: value });
        }
        await this.setState(newState);
    }

    save = () => {
        this.props.dispatchCreateUsersCompetences(this.props.auth.token, this.props.executionId, this.state.rating);
    }

    handleSave = () => {
        let selected = 0;
        selected = this.state.rating.filter((el) => (el.rating > 0)).length;

        //check for all elements are seleceted
        if (selected === this.props.competences.length) {
            this.toggleConfirm();
        } else {
            this.setState({ alert: { visible: true, textId: "selfassessment.alert.notallselect", color: "danger" } });
            setTimeout(this.handleHideAlert, config.alertTimeOut);
        }
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }
    toggleNote = (e) => {
        this.setState({
            note: !this.state.note
        });
    }

    handlePDF = async () => {
        generateSelfAssessmentPDF(this.props, this.state);
    }

    render() {
        // no execution available and no loading in progress 
        if (this.props.executionId === -1 && !this.props.loading) {
            return (
                <Container fluid role="main" className="content-margin">
                    <Container fluid className="p-0 fw-bold">
                        <h1 ><FormattedMessage id="selfassessment.label.selfassesment" /></h1>

                        { /* Select Execution */}
                        <Row>
                            <Col xs="12" sm="6" md="6" lg="6" xl="3">
                                <ExecutionSelect className="mb-3 p-0" executionId={this.props.executions_id} past={true} />
                            </Col>
                        </Row>
                        <FormattedMessage id="selfassessment.label.noavailable" />
                    </Container>
                </Container>
            )

        } else {

            let datetime = null;
            if (this.props.modified) {
                datetime = moment(this.props.modified.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.modified.replace(" ", "T"), config.dateFormat.format, true) : null;
            }
            return (
                <Container fluid role="main" className="content-margin">
                    {/* Confirm save */}
                    <Confirm cancel save={this.save} dontsave={this.toggleConfirm} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="selfassessment.confirm.body.save" />

                    {/* Confirm note */}
                    <Confirm no={this.toggleNote} yes={this.goToNextPage.bind(this)} open={this.state.note} toggle={this.toggleNote} headertext="confirm.header.note" bodytext="selfassessment.confirm.body.note" />

                    {/* Alert */}
                    <Alert isOpen={this.state.alert.visible} toggle={this.handleHideAlert} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                    <h1 ><FormattedMessage id="selfassessment.label.selfassesment" /></h1>

                    {/* Select execution */}
                    <ExecutionSelect className="mb-3 p-0" executionId={this.props.executionId} past={true} />

                    <Container fluid className="sticky2 bg-white m-0 px-3">
                        <Row>
                            <Col xs="12" sm="6" className="p-0">
                                {/* Modified */
                                    this.props.modified &&
                                    <Container fluid className={"small p-1 py-0 m-0 justify-content-start d-flex"}>
                                        <FormattedMessage id="selfassessment.label.modified" />&nbsp;<FormattedDate value={datetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={datetime} hour={config.dateFormat.hour} minute={config.dateFormat.minute} />
                                    </Container>
                                }
                            </Col>
                            <Col xs="12" sm="6" className="p-0">
                                {/* PDF */}
                                <Container fluid className="p-0 mb-4 m-0">
                                    <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this)} />
                                </Container>
                            </Col>
                        </Row>
                        {/* Table Head */}
                        <Row>
                            <Col xs="4" className="text-uppercase border fw-bold d-flex flex-column justify-content-end px-2">
                                <FormattedMessage id="selfassessment.label.competence" />
                            </Col>
                            <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_01.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="selfassessment.label.rating-1" />
                            </Col>
                            <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_02.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="selfassessment.label.rating-2" />
                            </Col>
                            <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_03.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="selfassessment.label.rating-3" />
                            </Col>
                            <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_04.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="selfassessment.label.rating-4" />
                            </Col>
                        </Row>
                    </Container>
                    {/* Table */}
                    {!this.props.loading ?
                        <Container fluid className="mb-4 px-3">
                            {
                                this.props.competences.map((trData, trIndex) => {
                                    return <Row key={trIndex} className="row-striped" >
                                        <Col xs="4" className="border py-2" >
                                            <p><FormattedMessage id={trData.description} /></p>
                                            <p className="text-uppercase text-ellipse text-italic mb-0 pb-0"><em><FormattedMessage id={trData.name} /></em></p>
                                        </Col >
                                        <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                            <Label className={"competences" + (!this.props.editable ? " disabled" : "")} for={"1_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /> <FormattedMessage id="selfassessment.label.rating-1" /></div>}
                                                <input type="checkbox" disabled={!this.props.editable} checked={this.state.rating.filter((el) => (el.competences_id === trData.id && el.rating === 1)).length > 0} name={trData.id} id={"1_" + trData.id} value="1" onChange={this.handleCheckbox.bind(this, trData.id, 1)} />
                                                <span className="checkmark"></span>
                                            </Label>
                                        </Col>
                                        <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                            <Label className={"competences" + (!this.props.editable ? " disabled" : "")} for={"2_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /> <FormattedMessage id="selfassessment.label.rating-2" /></div>}
                                                <input type="checkbox" disabled={!this.props.editable} checked={this.state.rating.filter((el) => (el.competences_id === trData.id && el.rating === 2)).length > 0} name={trData.id} id={"2_" + trData.id} value="2" onChange={this.handleCheckbox.bind(this, trData.id, 2)} />
                                                <span className="checkmark"></span>
                                            </Label>
                                        </Col>
                                        <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                            <Label className={"competences" + (!this.props.editable ? " disabled" : "")} for={"3_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /> <FormattedMessage id="selfassessment.label.rating-3" /></div>}
                                                <input type="checkbox" disabled={!this.props.editable} checked={this.state.rating.filter((el) => (el.competences_id === trData.id && el.rating === 3)).length > 0} name={trData.id} id={"3_" + trData.id} value="3" onChange={this.handleCheckbox.bind(this, trData.id, 3)} />
                                                <span className="checkmark"></span>
                                            </Label>
                                        </Col>
                                        <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                            <Label className={"competences" + (!this.props.editable ? " disabled" : "")} for={"4_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /><FormattedMessage id="selfassessment.label.rating-4" /></div>}
                                                <input type="checkbox" disabled={!this.props.editable} checked={this.state.rating.filter((el) => (el.competences_id === trData.id && el.rating === 4)).length > 0} name={trData.id} id={"4_" + trData.id} value="4" onChange={this.handleCheckbox.bind(this, trData.id, 4)} />
                                                <span className="checkmark"></span>
                                            </Label>
                                        </Col>
                                    </Row>
                                })
                            }
                        </Container>
                        : <Loading />}
                    <Container fluid className="p-0 mb-4 d-flex justify-content-end flex-row">
                        <Button color="primary" onClick={this.handleSave} disabled={!(this.props.editable && !this.props.saving)}><FormattedMessage id="button.save" /></Button>
                    </Container>
                </Container>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        competences: state.competences.competences,
        loading: state.competences.loading,
        rating: state.competences.rating,
        editable: state.competences.editable,
        modified: state.competences.modified,
        createStatus: state.competences.createStatus,
        saving: state.competences.saving
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetCompetences: (token, executionId, order, desc, enabled) => {
            dispatch(competencesActions.getCompetences(token, executionId, null, null, order, desc, enabled));
        },
        dispatchCreateUsersCompetences: (token, executionId, rating) => {
            dispatch(competencesActions.createUsersCompetences(token, executionId, null, null, rating));
        }
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SelfAssessmentPage));