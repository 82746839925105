import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Table, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import ExecutionSelect from '../components/ExecutionSelect';
import { executionsActions, filterActions, schoolsActions } from '../actions';
import hookImg from '../assets/images/Haken.png';
import emptyImg from '../assets/images/Empty.png';
import SchoolSelect from '../components/SchoolSelect';

class SelfAssessmentOverviewPage extends Component {
    static propTypes = {
        dispatchGetStudentsForUser: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            th: [
                { text: "selfassessmentoverview.th.sa_completed", width: "15%" },
                { text: "selfassessmentoverview.th.it_completed", width: "15%" },
                { text: "selfassessmentoverview.th.firstname", width: "30%" },
                { text: "selfassessmentoverview.th.lastname", width: "30%" },
                { text: "selfassessmentoverview.th.class", width: "10%" },
            ],
            tb: [],
            tbshow: ["icon_selfassessment_completed", "icon_interesttest_completed", "firstname", "lastname", "class"],
            order: '',
            desc: false,
            students: [],
        };
    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        if (this.props.executionId > -1) {
            this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.executionId !== this.props.executionId) {
            // await this.setState();
            this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId);
        }
        if (prevProps.students !== this.props.students) {
            if (this.props.students.length > 0) {
                let formattedData = this.convertDataToTableBodyFormat(this.props.students);
                this.setState({ students: formattedData });
            } else {
                this.setState({ students: [] });
            }
        }
    }

    convertDataToTableBodyFormat(students) {
        let studentsCopy = JSON.parse(JSON.stringify(students));
        for (let i = 0; i < studentsCopy.length; i++) {
            if (studentsCopy[i].selfassessment_completed) {
                studentsCopy[i].icon_selfassessment_completed = hookImg;
            } else {
                studentsCopy[i].icon_selfassessment_completed = emptyImg;
            }
            if (studentsCopy[i].interesttest_completed) {
                studentsCopy[i].icon_interesttest_completed = hookImg;
            } else {
                studentsCopy[i].icon_interesttest_completed = emptyImg;
            }
        }
        return studentsCopy;
    }

    render() {
        return (
            <Container fluid role="main" className="content-margin">
                <h1><FormattedMessage id="selfassessmentoverview.label.selfassessmentoverview" /></h1>

                { /* Select Execution */}
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3" className={this.props.schools.length > 1 ? "" : "d-none"}>
                        <SchoolSelect className="mb-3 p-0" withEmpty label="observationsevaluations.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3">
                        <ExecutionSelect withEmpty className="mb-3 p-0" label="selfassessmentoverview.label.execution" executionId={this.props.executionId} schoolId={this.props.schoolId} onlyInvolved onlyKompo7={true} />
                    </Col>
                </Row>

                { /* Table */}
                <Container fluid className="m-0 p-0 border">
                    <Table striped bordered className="m-0 p-0">
                        <TableHead data={this.state.th} />
                        <TableBody loading={this.props.loading} data={this.state.students} show={this.state.tbshow} />
                    </Table>
                </Container>

                {/* Descriptions */}
                {/*<Row className={this.state.students.length > 0 ? "small fw-bold mb-4 mt-0 pt-0" : "d-none"}>
                    <Col className="pt-0 mt-0" xs="12">
                        <FormattedMessage id="selfassessmentoverview.description.completed" />
                        <img className="description-selfassessment-completed" src={hookImg} alt={this.props.intl.formatMessage({ id: "selfassessmentoverview.description.alt.completed" })} />
                    </Col>
                </Row>
                */}
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        students: state.executions.studentsforuser,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null));
        },
        dispatchGetStudentsForUser: (token, executionId) => {
            dispatch(executionsActions.getStudentsForUser(token, executionId));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SelfAssessmentOverviewPage));