import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Input, Button, Table, Alert } from 'reactstrap';
import { schoolsActions, departmentsActions, filterActions } from '../actions';
import DepartmentSelect from '../components/DepartmentSelect';
import SchooltypeSelect from '../components/SchooltypeSelect';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import Pages from '../components/Pages';
import SchoolAdministrationModal from '../components/SchoolAdministrationModal';
import Confirm from '../components/Confirm';
import config from '../config/config';

class SchoolAdministrationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            th: [
                { text: "schooladministration.th.name", order: "name" },
                { text: "schooladministration.th.street", order: "street" },
                { text: "schooladministration.th.place", order: "place" },
                { text: "schooladministration.th.email", order: "email" },
                { text: "schooladministration.th.department", order: "department", hidebyDepartmentSelection: true },
                { text: "schooladministration.th.schooltype", order: "schooltype", hidebySchooltypeSelection: true },
                { text: "schooladministration.th.action", className: "table-border-left-action", width: 10 },
            ],
            tb: [],
            tbshow: [
                { value: "name" },
                { value: "street" },
                { value: "place" },
                { value: "email" },
                { value: "department", hidebyDepartmentSelection: true },
                { value: "schooltype", hidebySchooltypeSelection: true },
            ],
            order: 'name',
            desc: false,
            page: 1,
            totalpages: 1,
            pages: 5,
            pagelimit: 10,
            search: '',
            openModalNewEdit: false,
            deleteId: -1,
            editData: null,
            alert: { visible: false, textId: "alert.save", color: "success" },
            searchTimer: null,
        };
    }

    componentDidMount() {
        this.props.dispatchGetDepartments(this.props.auth.token);
        this.props.dispatchGetSchooltypes(this.props.auth.token);
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.departmentId !== this.props.departmentId || prevProps.schooltypeId !== this.props.schooltypeId) {
            this.getData();
        }

        if (prevProps.total !== this.props.total) {
            //Check if the page still exists
            if (this.state.page > Math.ceil(this.props.total / this.state.pagelimit)) {
                let newPage = Math.ceil(this.props.total / this.state.pagelimit);
                this.setState({ page: newPage <= 0 ? 1 : newPage });
            }
        }

        /* alert delete */
        if (prevProps.deleteSchoolState !== this.props.deleteSchoolState) {
            if (this.props.deleteSchoolState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.delete", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.deleteSchoolState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.delete-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* alert create and update */
        if (prevProps.createSchoolState !== this.props.createSchoolState || prevProps.updateSchoolState !== this.props.updateSchoolState) {
            if (this.props.createSchoolState === 1 || this.props.updateSchoolState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.createSchoolState === 2 || this.props.updateSchoolState === 2) {
                if (this.props.createError === 'email' || this.props.updateError === 'email') {
                    this.setState({ alert: { visible: true, textId: "useradministration.alert.email", color: "danger" } });
                } else {
                    this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                }
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }
    }

    /* CLICK EDIT */
    handleEdit = (id) => {
        this.handleOpenModalNewEdit(id)
    }

    handleOpenModalNewEdit = (id, e) => {
        let index = this.props.schools.map((value) => { return value.id }).indexOf(id);
        if (id === -1 || index === -1) {
            this.setState({ editData: null })
        } else {
            this.setState({ editData: this.props.schools[index] });
        }
        this.setState({
            openModalNewEdit: !this.state.openModalNewEdit
        });
    }

    /* CLICK DELETE */
    handleDelete = (id) => {
        this.setState({ deleteId: id });
        this.toggleConfirm();
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    handlePage = (page) => {
        this.setState({ page: page });
        this.props.dispatchGetSchoolsForUser(
            this.props.auth.token,
            this.state.order,
            this.state.desc,
            this.state.search,
            page,
            this.state.pagelimit,
            this.props.schooltypeId,
            this.props.departmentId,
        );
    }

    getData() {
        this.props.dispatchGetSchoolsForUser(
            this.props.auth.token,
            this.state.order,
            this.state.desc,
            this.state.search,
            this.state.page,
            this.state.pagelimit,
            this.props.schooltypeId,
            this.props.departmentId,
        );
    }

    /* Hide Alert */
    hideAlert() {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    }

    search = (e) => {
        let search = e.target.value;
        let page = 1;
        this.setState({ search: search, page: page });
        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
        }
        //start search after 500ms
        this.searchTimer = setTimeout(() => {
            this.props.dispatchGetSchoolsForUser(
                this.props.auth.token,
                this.state.order,
                this.state.desc,
                search,
                page,
                this.state.pagelimit,
                this.props.schooltypeId,
                this.props.departmentId,
            );
        }, 500);
    }

    order = (order, desc, e) => {
        this.setState({ order: order, desc: desc });
        this.props.dispatchGetSchoolsForUser(
            this.props.auth.token,
            order,
            desc,
            this.state.search,
            this.state.page,
            this.state.pagelimit,
            this.props.schooltypeId,
            this.props.departmentId,
        );
    }

    getTableHead() {
        let tmpTh = [];
        let width = 0;
        let countwidth = 0;
        this.state.th.forEach((th) => {
            if ((!th.hidebyDepartmentSelection || (th.hidebyDepartmentSelection && this.props.departmentId === -1)) &&
                (!th.hidebySchooltypeSelection || (th.hidebySchooltypeSelection && this.props.schooltypeId === -1))) {
                tmpTh.push(JSON.parse(JSON.stringify(th)));
                if (th.width) {
                    width += th.width;
                    countwidth++;
                }
            }
        });

        let newwidth = (100 - width) / (tmpTh.length - countwidth);
        tmpTh.forEach((th) => {
            if (th.width) {
                th.width = th.width + '%';
            } else {
                th.width = newwidth + '%';
            }
        });
        return tmpTh;
    }

    getTableShow() {
        let tmpShow = [];
        this.state.tbshow.forEach((ts) => {
            if ((!ts.hidebyDepartmentSelection || (ts.hidebyDepartmentSelection && this.props.departmentId === -1)) &&
                (!ts.hidebySchooltypeSelection || (ts.hidebySchooltypeSelection && this.props.schooltypeId === -1))) {
                tmpShow.push(ts.value ? ts.value : '');
            }
        });
        return tmpShow;
    }

    save(school) {
        if (!school.id) {
            this.props.dispatchCreateSchool(this.props.auth.token, school);
        } else {
            this.props.dispatchUpdateSchool(this.props.auth.token, school);
        }
    }

    delete = (e) => {
        if (this.state.deleteId) {
            this.props.dispatchDeleteSchool(this.props.auth.token, this.state.deleteId);
        }
        this.toggleConfirm();
    }

    render() {
        return (
            <Container fluid role="main" className="content-margin">
                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                {/* Confirm delete */}
                <Confirm open={this.state.confirm} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.delete} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                <h1><FormattedMessage id="schooladministration.title" /></h1>

                {/* Search */}
                <Container fluid className="m-0 p-0 mb-3">
                    <Row>
                        <Col xs="12" md="6" lg="6" xl="4">
                            <Input type="text" id="search" bsSize="sm" name="search" placeholder={this.props.intl.formatMessage({ id: "execution.search.placeholder" })} autoComplete="off" onKeyUp={this.search} />
                        </Col>
                    </Row>
                </Container>

                <Row>
                    <Col xs="12" md="6" lg="6" xl="4" className="mb-3">
                        <Button color="primary" block onClick={this.handleOpenModalNewEdit.bind(this, -1)} id="newSchool"><FormattedMessage id="schooladministration.button.new-school" /></Button>
                    </Col>
                </Row>

                <Row>
                    { /* Select Department */}
                    <Col xs="12" sm="6" md="6" lg="6" xl="4">
                        <DepartmentSelect className={this.props.departments.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="schooladministration.label.department" departments={this.props.departments} handleDepartment={this.props.dispatchDepartment.bind(this)} departmentId={this.props.departmentId} />
                    </Col>
                    { /* Select Schooltype */}
                    <Col xs="12" sm="6" md="6" lg="6" xl="4">
                        <SchooltypeSelect className={this.props.schooltypes.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="schooladministration.label.schooltype" schooltypes={this.props.schooltypes} handleSchooltype={this.props.dispatchSchooltype.bind(this)} schooltypeId={this.props.schooltypeId} />
                    </Col>
                </Row>

                { /* Table */}
                <Container fluid className="m-0 p-0 border">
                    <Table striped bordered className="m-0 p-0 table-fixed w-100">
                        <TableHead data={this.getTableHead()} order={this.order} />
                        <TableBody
                            loading={this.props.loading}
                            className={"w-100"}
                            data={this.props.schools}
                            show={this.getTableShow()}
                            edit={this.handleEdit.bind(this)}
                            delete={this.handleDelete.bind(this)}
                        />
                    </Table>
                </Container>

                { /* Pages */}
                <Container fluid className="d-flex justify-content-end mt-3 p-0">
                    <Pages pages={parseInt(this.state.pages, 10)} totalpages={parseInt(this.props.totalPages, 10)} page={parseInt(this.state.page, 10)} changePage={this.handlePage} />
                </Container>

                { /* MODAL for new and edit */}
                <SchoolAdministrationModal open={this.state.openModalNewEdit} toggle={this.handleOpenModalNewEdit} school={this.state.editData} save={this.save.bind(this)} />
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schools: state.schools.schoolsForUser,
        totalPages: state.schools.total_pages,
        total: state.schools.total,
        schooltypes: state.schools.schooltypes,
        schooltypeId: state.filter.schooltype && state.filter.schooltype.id ? state.filter.schooltype.id : -1,
        departments: state.departments.departments,
        departmentId: state.filter.department && state.filter.department.id ? state.filter.department.id : -1,
        loading: state.schools.schoolsLoading,
        createSchoolState: state.schools.createSchoolState,
        updateSchoolState: state.schools.updateSchoolState,
        deleteSchoolState: state.schools.deleteSchoolState,
        createError: state.schools.createSchoolError,
        updateError: state.schools.updateSchoolError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token, order, desc, search, page, pageLimit, schooltypeId, departmentId) => {
            dispatch(schoolsActions.getSchoolsForUser(token, order, desc, null, search, page, pageLimit, schooltypeId, departmentId));
        },
        dispatchGetDepartments: (token) => {
            dispatch(departmentsActions.getDepartments(token, "name", false, null, null, null));
        },
        dispatchDepartment: (department) => {
            dispatch(filterActions.department(department));
        },
        dispatchGetSchooltypes: (token) => {
            dispatch(schoolsActions.getSchooltypes(token, "name", false));
        },
        dispatchSchooltype: (schooltype) => {
            dispatch(filterActions.schooltype(schooltype));
        },
        dispatchCreateSchool: (token, school) => {
            dispatch(schoolsActions.createSchool(token, school));
        },
        dispatchUpdateSchool: (token, school) => {
            dispatch(schoolsActions.updateSchool(token, school));
        },
        dispatchDeleteSchool: (token, schoolId) => {
            dispatch(schoolsActions.deleteSchool(token, schoolId));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchoolAdministrationPage));