import { executionsService } from '../services';
import { actionTypes } from './types';

export const executionsActions = {
    getExecutionsOfSchool,
    getExecutions,
    getExecutionsstatus,
    getCooperationtype,
    getObservers,
    getGroups,
    getStudents,
    getStudentsForUser,
    getExecutionsHonorarkraft,
    getForeignUsers,
    createExecution,
    createGroups,
    updateExecution,
    deleteExecution,
    getExecutionsResults,
};

function getExecutionsOfSchool(token, schoolId, order, desc, search, page, pagelimit, filter) {
    return dispatch => {
        dispatch(request());

        executionsService.getExecutionsBySchoolId(token, schoolId, order, desc, search, page, pagelimit, filter)
            .then(
                response => {
                    dispatch(success(schoolId, response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_OF_SCHOOL_REQUEST } }
    function success(schools_id, executions) { return { type: actionTypes.EXECUTIONS_OF_SCHOOL_SUCCESS, schools_id, executions } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_OF_SCHOOL_FAILURE, error } }
}

function getExecutions(token, order, desc, search, page, pagelimit, filter) {
    return dispatch => {
        dispatch(request());

        executionsService.getExecutions(token, order, desc, search, page, pagelimit, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_REQUEST } }
    function success(executions) { return { type: actionTypes.EXECUTIONS_SUCCESS, executions } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_FAILURE, error } }
}

function getObservers(token, executionId) {
    return dispatch => {
        dispatch(request());

        executionsService.getObservers(token, executionId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_OBSERVERS_REQUEST } }
    function success(observers) { return { type: actionTypes.EXECUTIONS_OBSERVERS_SUCCESS, observers } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_OBSERVERS_FAILURE, error } }
}

function getGroups(token, executionId) {
    return dispatch => {
        dispatch(request());

        executionsService.getGroups(token, executionId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_GROUPS_REQUEST } }
    function success(groups) { return { type: actionTypes.EXECUTIONS_GROUPS_SUCCESS, groups } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_GROUPS_FAILURE, error } }
}

function createGroups(token, execution, groups) {
    return dispatch => {
        dispatch(request());

        executionsService.createGroups(token, execution, groups)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_CREATE_GROUPS_REQUEST } }
    function success(response) { return { type: actionTypes.EXECUTIONS_CREATE_GROUPS_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_CREATE_GROUPS_FAILURE, error } }
}


function getStudents(token, executionId, procedureId) {
    return dispatch => {
        dispatch(request());

        executionsService.getStudents(token, executionId, procedureId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_STUDENTS_REQUEST } }
    function success(students) { return { type: actionTypes.EXECUTIONS_STUDENTS_SUCCESS, students } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_STUDENTS_FAILURE, error } }
}

function getForeignUsers(token, executionId) {
    return dispatch => {
        dispatch(request());

        executionsService.getForeignUsers(token, executionId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_FOREIGNUSERS_REQUEST } }
    function success(users) { return { type: actionTypes.EXECUTIONS_FOREIGNUSERS_SUCCESS, users } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_FOREIGNUSERS_FAILURE, error } }
}

function getStudentsForUser(token, executionId, honorarkraftId) {
    return dispatch => {
        dispatch(request());

        executionsService.getStudentsForUser(token, executionId, honorarkraftId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_STUDENTS_FOR_USER_REQUEST } }
    function success(students) { return { type: actionTypes.EXECUTIONS_STUDENTS_FOR_USER_SUCCESS, students } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_STUDENTS_FOR_USER_FAILURE, error } }
}

function createExecution(token, execution) {
    return dispatch => {
        dispatch(request());

        executionsService.createExecution(token, execution)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_CREATE_EXECUTION_REQUEST } }
    function success(response) { return { type: actionTypes.EXECUTIONS_CREATE_EXECUTION_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_CREATE_EXECUTION_FAILURE, error } }
}

function updateExecution(token, execution) {
    return dispatch => {
        dispatch(request());

        executionsService.updateExecution(token, execution)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_UPDATE_EXECUTION_REQUEST } }
    function success(response) { return { type: actionTypes.EXECUTIONS_UPDATE_EXECUTION_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_UPDATE_EXECUTION_FAILURE, error } }
}

function deleteExecution(token, id) {
    return dispatch => {
        dispatch(request());

        executionsService.deleteExecutions(token, id)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_DELETE_EXECUTION_REQUEST } }
    function success(response) { return { type: actionTypes.EXECUTIONS_DELETE_EXECUTION_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_DELETE_EXECUTION_FAILURE, error } }
}

function getExecutionsstatus(token) {
    return dispatch => {
        dispatch(request());

        executionsService.getExecutionsstatus(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_STATUS_REQUEST } }
    function success(status) { return { type: actionTypes.EXECUTIONS_STATUS_SUCCESS, status } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_STATUS_FAILURE, error } }
}

function getCooperationtype(token) {
    return dispatch => {
        dispatch(request());

        executionsService.getCooperationtype(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_COOPTYPE_REQUEST } }
    function success(cooptype) { return { type: actionTypes.EXECUTIONS_COOPTYPE_SUCCESS, cooptype } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_COOPTYPE_FAILURE, error } }
}

function getExecutionsHonorarkraft(token, executionId, order, desc, filter) {
    return dispatch => {
        dispatch(request());

        executionsService.getExecutionsHonorarkraft(token, executionId, order, desc, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_HONORARKRAFT_REQUEST } }
    function success(honorarkraft) { return { type: actionTypes.EXECUTIONS_HONORARKRAFT_SUCCESS, honorarkraft } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_HONORARKRAFT_FAILURE, error } }
}

function getExecutionsResults(token, executionId, order, desc, filter) {
    return dispatch => {
        dispatch(request());
        executionsService.getExecutionsResults(token, executionId, order, desc, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.EXECUTIONS_RESULTS_REQUEST } }
    function success(results) { return { type: actionTypes.EXECUTIONS_RESULTS_SUCCESS, results } }
    function failure(error) { return { type: actionTypes.EXECUTIONS_RESULTS_FAILURE, error } }
}