import * as React from 'react';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Col, Row, FormText } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { isEmail } from '../utils/Tools';
import SchooltypeSelect from './SchooltypeSelect';

class SingleInputModal extends React.Component {
    static propTypes = {
        setting: PropTypes.object, // { key: 'firstname', title: 'Vorname:', value: 'Max', type: 'string' }
        handleConfirm: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        schooltypes: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            inputValueDoubleCheck: '',
            valid: false,
            validDoubleCheck: false,
            changed: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.setting !== this.props.setting) {
            this.setState({
                inputValue: this.props.setting ? this.props.setting.value : '',
                inputValueDoubleCheck: '',
                valid: this.props.setting ? this.inputValid(this.props.setting.value) : false,
                validDoubleCheck: false,
                changed: false
            });
        }
    }

    inputValid(value) {
        if (value == null) value = '';

        // trim the inserted value first
        let trimmedValue = value.toString().trim();

        if (this.props.setting.constraints) {
            for (let i = 0; i < this.props.setting.constraints.length; i++) {
                let c = this.props.setting.constraints[i];

                if (!this.checkConstraint(trimmedValue, c)) {
                    return false;
                }
            }
        }

        if (!this.props.setting.empty)
            return trimmedValue !== '';

        return true;
    }

    checkConstraint(value, constraint) {
        if (constraint.type === 'maxlength') {
            return value.length <= constraint.value;
        } else if (constraint.type === 'minlength') {
            return value.length >= constraint.value;
        } else if (constraint.type === 'regex') {
            var re = new RegExp(constraint.value, constraint.flags);
            return re.test(value);
        } else if (constraint.type === 'email') {
            return isEmail(value);
        }
        return true;
    }

    handleValueChange(e) {
        this.setState({
            inputValue: e.target.value,
            valid: this.inputValid(e.target.value),
            changed: e.target.value !== this.state.inputValue
        });
    }

    handleValueChangeDoubleCheck(e) {
        let isEqual = this.state.inputValue === e.target.value;

        this.setState({
            inputValueDoubleCheck: e.target.value,
            validDoubleCheck: this.inputValid(e.target.value) && isEqual,
        });
    }

    getInputElementForSettingType() {
        if (this.props.setting.type === 'schooltype') {
            let changeHandler = (st) => {
                let e = { target: { value: st.id } };
                this.handleValueChange(e);
            };

            return <SchooltypeSelect className={this.props.schooltypes.length > 1 ? "mb-3 p-0" : "d-none"} schooltypes={this.props.schooltypes} handleSchooltype={changeHandler.bind(this)} schooltypeId={Number.isInteger(this.state.inputValue) ? this.state.inputValue : -1} />
        }

        // standard text input element
        return <Input autoFocus bsSize="sm" valid={this.state.valid} name="setting" id="setting" type={this.props.setting.type} value={this.state.inputValue} onChange={this.handleValueChange.bind(this)} />;
    }

    getConstraintDescription(constraint) {
        if (constraint.type === 'regex') {
            return 'settings.constraints.title.' + constraint.type + '.' + constraint.title;
        }
        return 'settings.constraints.title.' + constraint.type;
    }

    render() {
        if (this.props.setting === null) {
            return null;
        }

        return (
            <Container>
                <Modal isOpen={this.props.open} size="md" className="modal-height-100 modal-width-100" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.open} >
                    <ModalHeader><FormattedMessage id="singleinputmodal.title" /></ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col>
                                <FormattedMessage id={this.props.setting.title} />
                            </Col>
                            <Col>
                                <div className="mb-1">
                                    {this.getInputElementForSettingType()}
                                    {
                                        this.props.setting.constraints.length > 0 &&
                                        this.props.setting.constraints.map((c, index) =>
                                            <FormText key={c.type + index} className="ps-1" style={{ display: 'block' }}>
                                                <FormattedMessage id={this.getConstraintDescription(c)} />
                                                {c.type !== 'regex' ? c.value : ''}
                                            </FormText>)
                                    }
                                </div>
                            </Col>
                        </Row>
                        {
                            this.props.setting.doublecheck &&
                            <Row>
                                <Col>
                                    <FormattedMessage id="singleinputmodal.doublecheck.title" />
                                </Col>
                                <Col>
                                    <Input bsSize="sm" valid={this.state.validDoubleCheck} name="settingDoubleCheck" id="settingDoubleCheck" type={this.props.setting.type} value={this.state.inputValueDoubleCheck} onChange={this.handleValueChangeDoubleCheck.bind(this)} />
                                </Col>
                            </Row>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button disabled={this.props.loading} color="primary" onClick={this.props.handleCancel}><FormattedMessage id="button.cancel" /></Button>
                        <Button disabled={!this.state.valid || (this.props.setting.doublecheck && !this.state.validDoubleCheck) || this.props.loading /*|| !this.state.changed*/} color="primary" onClick={() => this.props.handleConfirm(this.props.setting.key, this.state.inputValue)}><FormattedMessage id="button.save" /></Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

export default injectIntl(SingleInputModal);