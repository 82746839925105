import print from '../assets/print'
import config from '../config/config'

export const Images = {
    K7v_Logoleiste: print.K7v_Logoleiste,
    bwhw_logo: print.bwhw_logo,
    olov_keyvisual_quer_rgb: print.olov_keyvisual_quer_rgb,
    olov_logo_rz: print.olov_logo_rz,
    Cutting_line: print.Cutting_line,
    student: config.icons.print.student,
    observer: config.icons.print.observer,
    hook: print.hook,
    cross: print.cross,
    smiley1: print.smiley1,
    smiley2: print.smiley2,
    smiley3: print.smiley3,
    smiley4: print.smiley4,
};