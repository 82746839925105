import { actionTypes } from './types';

export const filterActions = {
    inquiry_rejected,
    inquiry_past,
    executions_past,
    executions_cooperation,
    execution,
    school,
    student,
    department,
    schooltype,
    schoolyear,
    observationsevaluations,
    role,
    honorarkraft,
    certified,
    procedure,
    procedureIntern
};

function inquiry_rejected() {
    return { type: actionTypes.FILTER_INQUIRY_REJECTED };
}

function inquiry_past() {
    return { type: actionTypes.FILTER_INQUIRY_PAST };
}

function executions_past() {
    return { type: actionTypes.FILTER_EXECUTIONS_PAST };
}
function executions_cooperation() {
    return { type: actionTypes.FILTER_EXECUTIONS_COOPERATION };
}

function execution(execution) {
    return { type: actionTypes.FILTER_EXECUTION, execution };
}
function school(school) {
    return { type: actionTypes.FILTER_SCHOOL, school };
}
function department(department) {
    return { type: actionTypes.FILTER_DEPARTMENT, department };
}
function schooltype(schooltype) {
    return { type: actionTypes.FILTER_SCHOOLTYPE, schooltype };
}
function schoolyear(schoolyear) {
    return { type: actionTypes.FILTER_SCHOOLYEAR, schoolyear };
}
function student(student) {
    return { type: actionTypes.FILTER_STUDENT, student };
}
function observationsevaluations(observationsevaluations) {
    return { type: actionTypes.FILTER_OBSERVATIONSEVALUATIONS, observationsevaluations };
}
function role(role) {
    return { type: actionTypes.FILTER_ROLE, role };
}
function honorarkraft(honorarkraft) {
    return { type: actionTypes.FILTER_HONORARKRAFT, honorarkraft };
}
function certified(certified) {
    return { type: actionTypes.FILTER_CERTIFIED, certified };
}
function procedure(procedure) {
    return { type: actionTypes.FILTER_PROCEDURE, procedure };
}
function procedureIntern(procedure) {
    return { type: actionTypes.FILTER_PROCEDURE_INTERN, procedure };
}