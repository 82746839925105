import { documentsService } from '../services';
import { actionTypes } from './types';

export const documentsActions = {
    getDocuments,
    createDocument,
    updateDocument,
    deleteDocument,
    sortDocuments,
    createDocumentsStructur,
    updateDocumentsStructur,
    deleteDocumentsStructur,
    sortDocumentsStructur,
    download,
};

function getDocuments(token) {
    return dispatch => {
        dispatch(request({ getDocuments }));

        documentsService.getDocuments(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.DOCUMENTS_REQUEST, status } }
    function success(structur) { return { type: actionTypes.DOCUMENTS_SUCCESS, structur } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_SUCCESS, error } }
}

function createDocumentsStructur(token, structur) {
    return dispatch => {
        dispatch(request());

        documentsService.createDocumentsStructur(token, structur)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_STRUCTUR_CREATE_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_STRUCTUR_CREATE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_STRUCTUR_CREATE_FAILURE, error } }
}

function updateDocumentsStructur(token, structur) {
    return dispatch => {
        dispatch(request());

        documentsService.updateDocumentsStructur(token, structur)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_STRUCTUR_UPDATE_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_STRUCTUR_UPDATE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_STRUCTUR_UPDATE_FAILURE, error } }
}

function deleteDocumentsStructur(token, id) {
    return dispatch => {
        dispatch(request());

        documentsService.deleteDocumentsStructur(token, id)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_STRUCTUR_DELETE_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_STRUCTUR_DELETE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_STRUCTUR_DELETE_FAILURE, error } }
}

function sortDocumentsStructur(token, structurs) {
    return dispatch => {
        dispatch(request());

        documentsService.sortDocumentsStructur(token, structurs)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_STRUCTUR_SORT_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_STRUCTUR_SORT_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_STRUCTUR_SORT_FAILURE, error } }
}

function createDocument(token, document, importData) {
    return dispatch => {
        dispatch(request());

        documentsService.createDocument(token, document, importData)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_CREATE_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_CREATE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_CREATE_FAILURE, error } }
}

function updateDocument(token, document, importData) {
    return dispatch => {
        dispatch(request());

        documentsService.updateDocument(token, document, importData)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_UPDATE_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_UPDATE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_UPDATE_FAILURE, error } }
}

function deleteDocument(token, id) {
    return dispatch => {
        dispatch(request());

        documentsService.deleteDocument(token, id)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_DELETE_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_DELETE_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_DELETE_FAILURE, error } }
}

function sortDocuments(token, documents) {
    return dispatch => {
        dispatch(request());

        documentsService.sortDocuments(token, documents)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.DOCUMENTS_SORT_REQUEST } }
    function success(response) { return { type: actionTypes.DOCUMENTS_SORT_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_SORT_FAILURE, error } }
}

function download(token, dir) {
    return dispatch => {
        dispatch(request({ download }));

        documentsService.download(token, dir)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(file) { return { type: actionTypes.DOCUMENTS_DOWNLOAD_REQUEST, file } }
    function success(result) { return { type: actionTypes.DOCUMENTS_DOWNLOAD_SUCCESS, result } }
    function failure(error) { return { type: actionTypes.DOCUMENTS_DOWNLOAD_FAILURE, error } }
}
