import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Label, Button, Alert } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import Confirm from '../components/Confirm';
import config from '../config/config';
import { kompo7RatingActions } from '../actions';
import Loading from '../components/Loading';
import { replaceQuotationMarks, getCaretPos, setCaretPos } from '../utils/Tools';

class Kompo7RatingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rating: [],
            ratingMessage: '',
            confirm: false,
            alert: { visible: false, textId: "alert.save", color: "success" },
        };
    }

    componentDidMount() {
        if (this.props.executionId > -1)
            this.props.dispatchGetRating(this.props.auth.token, this.props.executionId);
    }


    componentDidUpdate(prevProps) {
        if (prevProps.executionId !== this.props.executionId) {
            // await this.setState();
            this.props.dispatchGetRating(this.props.auth.token, this.props.executionId);
        }
        if (prevProps.rating !== this.props.rating) {
            // await this.setState();
            this.setState({ rating: this.props.rating, ratingMessage: this.props.ratingMessage });
        }

        if (prevProps.createStatus !== this.props.createStatus) {
            // await this.setState();
            if (this.props.createStatus && this.props.createStatus.ok) {
                this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
                this.props.dispatchGetRating(this.props.auth.token, this.props.executionId);
            } else if (this.props.createStatus) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }
        }
    }

    save = () => {
        this.props.dispatchCreateUserRating(this.props.auth.token, this.props.executionId, this.state.rating, this.state.ratingMessage);
    }

    handleCheckbox = async (id, value) => {
        let newState = { ...this.state }
        let changed = false;

        this.state.rating.forEach(rating => {
            if (rating && rating.ratingsquestions_id === id) {
                if (rating.rating === value) {
                    rating.rating = 0;
                } else {
                    rating.rating = value;
                }
                changed = true;
            }
        });

        if (!changed) {
            newState.rating.push({ ratingsquestions_id: id, rating: value });
        }
        await this.setState(newState);
    }

    handleText = async (e) => {
        const cp = getCaretPos(e.target);
        const domEle = e.target;
        this.setState({ ratingMessage: replaceQuotationMarks(e.target.value) }, () => { setCaretPos(domEle, cp); })
    }

    handleSave = () => {
        //check for all elements are seleceted
        if (this.state.rating.length === this.props.rating.length) {
            this.toggleConfirm();
        } else {
            this.setState({ alert: { visible: true, textId: "kompo7rating.alert.notallselect", color: "danger" } });
            setTimeout(this.handleHideAlert, config.alertTimeOut);
        }
    }

    handleHideAlert = (e) => {
        this.setState({ alert: { visible: false, textId: "alert.save", color: "success" } });
    };

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    getRatingCols(rating) {
        let cols = [];

        for (let i = 1; i <= 3; i++) {
            cols.push(
                <Col key={'check_col' + i} xs="2" className="p-0 kompo7rating-td border border-start-0 py-2">
                    <Label className={"kompo7rating" + (!this.props.editable ? " disabled" : "")} for={i + '_' + rating.id} >{<div className="d-none"><FormattedMessage id={rating.question} /> <FormattedMessage id={'kompo7rating.label.rating-' + i} /></div>}
                        <input type="checkbox" disabled={!this.props.editable} checked={this.state.rating.filter((el) => (el.ratingsquestions_id === rating.id && el.rating === i)).length > 0} name={rating.id} id={i + '_' + rating.id} value={i} onChange={this.handleCheckbox.bind(this, rating.id, i)} />
                        <span className="checkmark"></span>
                    </Label>
                </Col>
            );
        }

        return cols;
    }

    render() {
        // TODO: display the feedback grid only, if the selected execution is in the past



        // no execution available and no loading in progress 
        if ((this.props.executionId === -1 && !this.props.loading) || (this.props.questions === null || (this.props.questions && this.props.questions.length === 0))) {
            return (
                <Container fluid role="main" className="content-margin">
                    <Container fluid className="p-0 fw-bold">
                        <h1 style={{ textTransform: 'capitalize' }}><FormattedMessage id="kompo7rating.label.kompo7rating" /></h1>

                        { /* Select Execution */}
                        <Row>
                            <Col xs="12" sm="6" md="6" lg="6" xl="3">
                                <ExecutionSelect className="mb-3 p-0" label="kompo7rating.label.execution" executionId={this.props.executionId} past={true} />
                            </Col>
                        </Row>
                        <FormattedMessage id="kompo7rating.label.noavailable" />
                    </Container>
                </Container>
            )
        } else {
            return (
                <Container fluid role="main" className="content-margin">
                    <Confirm cancel save={this.save} dontsave={this.props.toggle} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="kompo7rating.confirm.body.save" />
                    <Alert isOpen={this.state.alert.visible} toggle={this.handleHideAlert} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                    <h1 style={{ textTransform: 'capitalize' }}><FormattedMessage id="kompo7rating.label.kompo7rating" /></h1>

                    { /* Select Execution */}
                    <Row>
                        <Col xs="12" sm="6" md="6" lg="6" xl="3">
                            <ExecutionSelect className="mb-3 p-0" label="kompo7rating.label.execution" executionId={this.props.executionId} past={true} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormattedMessage id="kompo7rating.label.hint" />
                        </Col>
                    </Row>
                    {
                        this.props.loading ? <Loading /> :
                            <div>
                                <Container fluid className="sticky2 bg-white m-0 px-3">
                                    {/* Table Head */}
                                    <Row>
                                        <Col xs="6" className="text-uppercase border fw-bold d-flex flex-column justify-content-end px-2">

                                        </Col>
                                        <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                            <img className="th-smiley mb-2" src={require('../assets/images/Smiley_01.svg').default} alt="" />
                                            <br />
                                            <FormattedMessage id="kompo7rating.label.rating-1" />
                                        </Col>
                                        <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                            <img className="th-smiley mb-2" src={require('../assets/images/Smiley_05.svg').default} alt="" />
                                            <br />
                                            <FormattedMessage id="kompo7rating.label.rating-2" />
                                        </Col>
                                        <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                            <img className="th-smiley mb-2" src={require('../assets/images/Smiley_04.svg').default} alt="" />
                                            <br />
                                            <FormattedMessage id="kompo7rating.label.rating-3" />
                                        </Col>
                                    </Row>
                                </Container>

                                {/* Table */}
                                <Container fluid className="mb-4  px-3">
                                    {
                                        this.props.questions.map((trData, trIndex) => {
                                            return <Row key={trIndex} className="row-striped" >
                                                <Col xs="6" className="border py-2" >
                                                    <p><FormattedMessage id={trData.question} /></p>
                                                </Col >

                                                {this.getRatingCols(trData)}
                                            </Row>
                                        })
                                    }
                                </Container>

                                {/* Save */}
                                <Container fluid className="p-0 mb-4 d-flex justify-content-end flex-row">
                                    <Button color="primary" onClick={this.handleSave} disabled={!this.props.editable}><FormattedMessage id="button.save" /></Button>
                                </Container>
                            </div>
                    }
                </Container>

            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        editable: state.kompo7Rating.editable,
        questions: state.kompo7Rating.questions,
        rating: state.kompo7Rating.rating,
        loading: state.kompo7Rating.loading,
        ratingMessage: state.kompo7Rating.ratingMessage,
        createStatus: state.kompo7Rating.createStatus,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetRating: (token, executionId) => {
            dispatch(kompo7RatingActions.getRatingForUser(token, executionId));
        },
        dispatchCreateUserRating: (token, executionId, rating, ratingMessage) => {
            dispatch(kompo7RatingActions.createUserRating(token, executionId, rating, ratingMessage));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Kompo7RatingPage));