import * as React from 'react';
import { Container } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Logout from './Logout';

class UserProfile extends React.Component {

    static propTypes = {
        // redux state
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        className: PropTypes.string,
        // redux dispatch
        // ...
    };

    static defaultProps = {
        className: ""
    }

    render() {

        return (
            <Container fluid className={"d-flex flex-row f-end h-100" + this.props.className.toString()}>
                <Container fluid className="w-100 text-end py-0 px-0 mx-0 text-primary fw-bold small d-flex justify-content-end align-items-center">
                    <span>{this.props.firstname} {this.props.lastname}</span>
                    <span className="border-start ms-2 ps-2" ><Logout className="text-primary border p-2 border-primary" /></span>
                </Container>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        firstname: state.auth.firstname,
        lastname: state.auth.lastname
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile));