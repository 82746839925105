import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button, Alert } from 'reactstrap';
import { documentsActions, userProfileActions } from '../actions';
import Loading from '../components/Loading';
import Authorization from '../components/Authorization';
import DocumentStructurModal from '../components/DocumentStructurModal';
import DocumentsModal from '../components/DocumentsModal';
import config from '../config/config';
import Confirm from '../components/Confirm';

class DocumentsPage extends Component {


    constructor(props) {
        super(props);
        this.props.dispatchGetDocuments(this.props.auth.token);

        this.state = {
            editmode: false,
            structurModalOpen: false,
            documentModalOpen: false,
            editNewStructur: { id: -1, name: '' },
            editNewDocument: { id: -1, name: '' },
            alert: { visible: false, textId: 'alert.save', color: 'success' },
            confirmStructur: false,
            confirmDocument: false,
            deleteStructur: null,
            deleteDocument: null,
        }
    }

    componentDidMount() {
        this.props.dispatchGetRoles(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {
        /* alert create, update, delete, sort */
        if (prevProps.createDocumentsStructurState !== this.props.createDocumentsStructurState ||
            prevProps.updateDocumentsStructurState !== this.props.updateDocumentsStructurState ||
            prevProps.deleteDocumentsStructurState !== this.props.deleteDocumentsStructurState ||
            prevProps.sortDocumentsStructurState !== this.props.sortDocumentsStructurState ||
            prevProps.sortDocumentsState !== this.props.sortDocumentsState ||
            prevProps.deleteDocumentsState !== this.props.deleteDocumentsState ||
            prevProps.createDocumentsState !== this.props.createDocumentsState ||
            prevProps.updateDocumentsState !== this.props.updateDocumentsState) {
            if (this.props.createDocumentsStructurState === 1 ||
                this.props.updateDocumentsStructurState === 1 ||
                this.props.sortDocumentsStructurState === 1 ||
                this.props.sortDocumentsState === 1 ||
                this.props.createDocumentsState === 1 ||
                this.props.updateDocumentsState === 1
            ) {
                this.setState({ structurModalOpen: false, documentModalOpen: false, alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.props.dispatchGetDocuments(this.props.auth.token);
            } else if (this.props.createDocumentsStructurState === 2 ||
                this.props.updateDocumentsStructurState === 2 ||
                this.props.sortDocumentsStructurState === 2 ||
                this.props.sortDocumentsState === 2 ||
                this.props.createDocumentsState === 2 ||
                this.props.updateDocumentsState === 2
            ) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }

            /* ALERT DELETE */
            if (this.props.deleteDocumentsStructurState === 1 ||
                this.props.deleteDocumentsState === 1) {
                this.setState({ structurModalOpen: false, documentModalOpen: false, alert: { visible: true, textId: "alert.delete", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.props.dispatchGetDocuments(this.props.auth.token);
            } else if (this.props.deleteDocumentsStructurState === 2 ||
                this.props.deleteDocumentsState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.delete-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }

        }
    }

    handleDocEdit = (doc) => {
        this.setState({
            documentModalOpen: true,
            editNewDocument: doc
        });
    }

    handleDocDelete = (doc) => {
        this.setState({ confirmDocument: true, deleteDocument: doc });
    }

    handleDocAdd = (structurId) => {
        this.setState({
            documentModalOpen: true,
            editNewDocument: { id: -1, documents_structur_id: structurId, name: '', filename: '', filename_unique: '', filesize: 0, sort: -1, version: '', roles: [] }
        });
    }

    handleStructurEdit = (structur) => {
        this.setState({
            structurModalOpen: true,
            editNewStructur: structur
        });
    }

    handleStructurDelete = (structur) => {
        this.setState({ confirmStructur: true, deleteStructur: structur });
    }

    handleStructurAdd = () => {
        this.setState({
            structurModalOpen: true,
            editNewStructur: { id: -1, name: '' }
        });
    }

    handleDownload = (doc) => {
        this.props.dispatchDownload(this.props.auth.token, doc);
    }

    doStructurAddEdit = (structur) => {
        //add
        if (structur.id === -1) {
            this.props.dispatchCreateDocumentStructur(this.props.auth.token, structur);
        } else {
            this.props.dispatchUpdateDocumentStructur(this.props.auth.token, structur);
        }
    }

    handleStructurSort = (structur1, structur2) => {
        const structur = {
            'structur-1': structur1,
            'structur-2': structur2,
        };
        this.props.dispatchSortDocumentStructur(this.props.auth.token, structur);
    }

    handleDocumentsSort = (document1, document2) => {
        const document = {
            'document-1': document1,
            'document-2': document2,
        };
        this.props.dispatchSortDocument(this.props.auth.token, document);
    }

    doDocumentAddEdit = (doc, importData) => {
        //add
        if (doc.id === -1) {
            this.props.dispatchCreateDocument(this.props.auth.token, doc, importData);
        } else {
            this.props.dispatchUpdateDocument(this.props.auth.token, doc, importData);
        }
    }

    toggleEditMode = () => {
        this.setState({ editmode: !this.state.editmode });
    }

    toggleStructurModalOpen = () => {
        this.setState({ structurModalOpen: !this.state.structurModalOpen });
    }

    toggleDocumentsModalOpen = () => {
        this.setState({ documentModalOpen: !this.state.documentModalOpen });
    }

    hideAlert() {
        this.setState({ alert: { ...this.state.alert, visible: false } })
    }

    toggleConfirm = () => {
        this.setState({ confirmDocument: false, confirmStructur: false });
    }

    doDeleteDocument = () => {
        this.props.dispatchDeleteDocument(this.props.auth.token, this.state.deleteDocument.id);
    }

    doDeleteStructur = () => {
        this.props.dispatchDeleteDocumentStructur(this.props.auth.token, this.state.deleteStructur.id);
    }

    render() {

        return (
            <Container fluid role="main" className="content-margin">
                <h1><FormattedMessage id="documents.title" /></h1>

                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                {/* Confirm delete structur */}
                <Confirm open={this.state.confirmStructur} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.doDeleteStructur.bind(this)} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                {/* Confirm delete docurment */}
                <Confirm open={this.state.confirmDocument} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.doDeleteDocument.bind(this)} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                {/* Button Editmode */}
                <Authorization id="documents-editmode">
                    <Container fluid className="m-0 p-0 mb-3">
                        <Row>
                            <Col>
                                <Button size="sm" color="primary" onClick={this.toggleEditMode.bind(this)} id="editMode">
                                    {
                                        !this.state.editmode ?
                                            <FormattedMessage id="documents.button.editmode.on" />
                                            :
                                            <FormattedMessage id="documents.button.editmode.off" />
                                    }
                                </Button>
                                {this.state.editmode &&
                                    <Button className="ms-2" size="sm" color="primary" onClick={this.handleStructurAdd.bind(this)} id="structurAdd">
                                        <FormattedMessage id="documents.button.structur.add" />
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Authorization>

                {/* Loading */}
                {this.props.loading && <Loading />}

                {
                    /* STRUCTUR */
                    !this.props.loading && this.props.structur && this.props.structur.map((structur, index) => {
                        const docs = structur.documents.filter((d) => { return this.state.editmode || d.roles.filter((r) => { return this.props.auth.roles.indexOf(r.id) !== -1 }).length > 0 });
                        if (docs.length > 0 || this.state.editmode) {
                            return <div className="mb-4" key={"doku" + index}>
                                <div className="pe-2 structur-container">
                                    <h2>{structur.name}</h2>
                                    {this.state.editmode && index > 0 &&
                                        <Authorization id="documents-structur-up" >
                                            <button className="up" onClick={this.handleStructurSort.bind(this, structur, this.props.structur[index - 1])}></button>
                                        </Authorization>
                                    }
                                    {this.state.editmode && index < this.props.structur.length - 1 &&
                                        <Authorization id="documents-structur-down" >
                                            <button className="down" onClick={this.handleStructurSort.bind(this, structur, this.props.structur[index + 1])}></button>
                                        </Authorization>
                                    }
                                    {this.state.editmode &&
                                        <Authorization id="documents-add" >
                                            <button className="add" onClick={this.handleDocAdd.bind(this, structur.id)}></button>
                                        </Authorization>
                                    }
                                    {this.state.editmode &&
                                        <Authorization id="documents-structur-edit" >
                                            <button className="edit" onClick={this.handleStructurEdit.bind(this, structur)}></button>
                                        </Authorization>
                                    }
                                    {this.state.editmode &&
                                        <Authorization id="documents-structur-delete" >
                                            <button className="delete" onClick={this.handleStructurDelete.bind(this, structur)}></button>
                                        </Authorization>
                                    }
                                </div>

                                {/* DOCUMENTS */}
                                <div className="docs-container">
                                    {
                                        docs.map((doc, i) => {
                                            return <div className="text-dark p-1 px-2 doc" key={"doc" + index + "_" + i}>
                                                <div className="link" onClick={this.handleDownload.bind(this, doc)}>{doc.name} ({doc.filesize} KB)</div>
                                                {this.state.editmode && i > 0 &&
                                                    <Authorization id="documents-up" >
                                                        <button className="up" onClick={this.handleDocumentsSort.bind(this, doc, docs[i - 1])}></button>
                                                    </Authorization>
                                                }
                                                {this.state.editmode && i < docs.length - 1 &&
                                                    <Authorization id="documents-down" >
                                                        <button className="down" onClick={this.handleDocumentsSort.bind(this, doc, docs[i + 1])}></button>
                                                    </Authorization>
                                                }
                                                {this.state.editmode &&
                                                    <Authorization id="documents-edit" >
                                                        <button className="edit" onClick={this.handleDocEdit.bind(this, doc)}></button>
                                                    </Authorization>
                                                }
                                                {this.state.editmode &&
                                                    <Authorization id="documents-delete" >
                                                        <button className="delete" onClick={this.handleDocDelete.bind(this, doc)}></button>
                                                    </Authorization>
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        }
                        return null;
                    })
                }
                {/* Structur Modal */}
                <DocumentStructurModal
                    open={this.state.structurModalOpen}
                    structur={this.state.editNewStructur}
                    toggle={this.toggleStructurModalOpen.bind(this)}
                    handleAddStructur={this.doStructurAddEdit.bind(this)}>
                </DocumentStructurModal>

                {/* Documents Modal */}
                <DocumentsModal
                    open={this.state.documentModalOpen}
                    document={this.state.editNewDocument}
                    toggle={this.toggleDocumentsModalOpen.bind(this)}
                    handleAddEditDocument={this.doDocumentAddEdit.bind(this)}
                >
                </DocumentsModal>


            </Container>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        structur: state.documents.structur,
        loading: state.documents.loading,
        createDocumentsStructurState: state.documents.createStructurState,
        createDocumentsStructurError: state.documents.createStructurError,
        updateDocumentsStructurState: state.documents.updateStructurState,
        updateDocumentsStructurError: state.documents.updateStructurError,
        deleteDocumentsStructurState: state.documents.deleteStructurState,
        deleteDocumentsStructurError: state.documents.deleteStructurError,
        sortDocumentsStructurState: state.documents.sortStructurState,
        sortDocumentsStructurError: state.documents.sortStructurError,
        sortDocumentsState: state.documents.sortDocumentState,
        sortDocumentsError: state.documents.sortDocumentError,
        deleteDocumentsState: state.documents.deleteDocumentState,
        deleteDocumentsError: state.documents.deleteDocumentError,
        createDocumentsState: state.documents.createDocumentState,
        createDocumentsError: state.documents.createDocumentError,
        updateDocumentsState: state.documents.updateDocumentState,
        updateDocumentsError: state.documents.updateDocumentError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetRoles: (token) => {
            dispatch(userProfileActions.getRoles(token));
        },
        dispatchGetDocuments: (token) => {
            dispatch(documentsActions.getDocuments(token));
        },
        dispatchCreateDocumentStructur: (token, structur) => {
            dispatch(documentsActions.createDocumentsStructur(token, structur));
        },
        dispatchUpdateDocumentStructur: (token, structur) => {
            dispatch(documentsActions.updateDocumentsStructur(token, structur));
        },
        dispatchDeleteDocumentStructur: (token, id) => {
            dispatch(documentsActions.deleteDocumentsStructur(token, id));
        },
        dispatchSortDocumentStructur: (token, structur) => {
            dispatch(documentsActions.sortDocumentsStructur(token, structur));
        },
        dispatchSortDocument: (token, document) => {
            dispatch(documentsActions.sortDocuments(token, document));
        },
        dispatchDeleteDocument: (token, id) => {
            dispatch(documentsActions.deleteDocument(token, id));
        },
        dispatchCreateDocument: (token, structur, importData) => {
            dispatch(documentsActions.createDocument(token, structur, importData));
        },
        dispatchUpdateDocument: (token, structur, importData) => {
            dispatch(documentsActions.updateDocument(token, structur, importData));
        },
        dispatchDownload: (token, doc) => {
            dispatch(documentsActions.download(token, doc));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsPage));