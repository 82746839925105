import { actionTypes } from '../actions/types';

const initialState = {
    loadingError: '',
    editable: false,
    questions: [],
    rating: [],
    ratingresults : [],
    loading: false,
    ratingMessage: '',
    createStatus: null,
};

const kompo7RatingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.KOMPO7RATING_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.KOMPO7RATING_SUCCESS:
            return {
                ...state,
                loading: false,
                questions: action.questions ? action.questions.questions : [],
                rating: action.questions ? action.questions.rating : [],
                ratingMessage: action.questions ? action.questions.rating_message : '',
                editable: action.questions ? action.questions.editable : false,               
            };
        case actionTypes.KOMPO7RATING_FAILURE:
            return {
                ...state,
                loading: false,
                loadingError: action.error,
                questions: [],
                rating: [],
                ratingMessage: '',
                editable: false,
            };
        case actionTypes.KOMPO7RATING_CREATE_REQUEST:
            return {
                ...state,
                createStatus: null,
            };
        case actionTypes.KOMPO7RATING_CREATE_SUCCESS:
            return {
                ...state,
                createStatus: action.status,
            };
        case actionTypes.KOMPO7RATING_CREATE_FAILURE:
            return {
                ...state,
                createStatus: action.status,
            };
            case actionTypes.KOMPO7RATING_RESULTS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.KOMPO7RATING_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                ratingresults: action.results ? action.results : {ratings:[],questions:[],rating_messages:[]},                       
            };
        case actionTypes.KOMPO7RATING_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                loadingError: action.error,
                ratingresults: {ratings:[],questions:[],rating_messages:[]},
            };
        default:
            return state;
    }
};

export default kompo7RatingReducer;
