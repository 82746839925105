import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';
import translationsVars from '../translations/translationsVars';

export const generateResetPwdUsersPDF = (props, state, users) => {
    return new Promise((resolve, reject) => {

        let tVars = { name: '' };
        //if only one user in array insert the name of the user in filename
        if (users.length === 1) {
            tVars = { name: '_' + users[0].firstname + '_' + users[0].lastname };
        }
        let documentname = props.intl.formatMessage({ id: 'useradministration.print.resetpwdteacherpdf.filename' }, tVars)

        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        //url to the application
        let kompo7Url = props.resetPwdUserPdfKompoUrl;

        let pages = [];
        users.forEach((user, index) => {
            let transVarsBody1 = {
                ...translationsVars,
                greeting: props.intl.formatMessage({ id: 'useradministration.print.resetpwdteacherpdf.greeting.' + user.address.id }),
                name: user.lastname,
                kompo7url: kompo7Url,
            };

            // build the access info table data
            let rows = [
                [
                    { text: props.intl.formatMessage({ id: "useradministration.print.resetpwdteacherpdf.access.fullname.label" }), border: [0, 0, 0, 0], alignment: 'right' },
                    { text: user.lastname + ', ' + user.firstname, bold: true, border: [0, 0, 0, 1] }
                ],
                [
                    { text: props.intl.formatMessage({ id: "useradministration.print.resetpwdteacherpdf.access.username.label" }), border: [0, 0, 0, 0], alignment: 'right' },
                    { text: user.username ? user.username : ' ', bold: true, border: [0, 0, 0, 1] }
                ],
                [
                    { text: props.intl.formatMessage({ id: "useradministration.print.resetpwdteacherpdf.access.password.label" }), border: [0, 0, 0, 0], alignment: 'right' },
                    { text: user.password ? user.password : props.intl.formatMessage({ id: "useradministration.print.resetpwdteacherpdf.access.alreadyloggedin" }), bold: true, border: [0, 0, 0, 1] }
                ],
            ];

            // page content
            let pageContent = [
                {
                    //Personal access title
                    text: props.intl.formatMessage({ id: 'useradministration.print.resetpwdteacherpdf.access.title' }, translationsVars),
                    bold: true,
                    margin: [0, 0, 0, 20]
                },
                {
                    //Body 1
                    text: props.intl.formatMessage({ id: 'useradministration.print.resetpwdteacherpdf.body-' + user.roles[0].id }, transVarsBody1),
                },
                {
                    margin: [0, 20, 0, 0],
                    table: {
                        dontBreakRows: true,
                        //keepWithHeaderRows: 1,
                        headerRows: 0,
                        widths: [190, "*"],
                        body: rows,
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i) {
                            return 0.5;
                        },
                    },
                    //pageBreak: 'after',
                }
            ];

            // if this is not the last page
            // add a page break property to the last element in the array
            if (index < users.length - 1)
                pageContent[pageContent.length - 1].pageBreak = 'after';

            // combine the elements of the current page with the other ones
            pages = pages.concat(pageContent);
        });

        let tmpHeader = JSON.parse(JSON.stringify(Header));
        tmpHeader.push({
            //Title
            text: props.intl.formatMessage({ id: "useradministration.print.resetpwdteacherpdf.title" }),
            absolutePosition: { x: 40, y: 65 },
            fontSize: 20,
        });
        tmpHeader.push({
            //Subtitle
            text: props.intl.formatMessage({ id: "useradministration.print.resetpwdteacherpdf.subtitle" }),
            absolutePosition: { x: 40, y: 90 },
            fontSize: 10,
            bold: true,
        });

        let docDefinition = {
            //595pt x 842pt
            pageSize: 'A4',
            pageMargins: [40, 115, 40, 65],
            images: Images,
            header: tmpHeader,
            footer: footer(props),
            content: pages
        };

        let completeCallback = function () {
            resolve();
        };

        pdfMake.createPdf(docDefinition).download(documentname, completeCallback);
    });
}