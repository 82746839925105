import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { Container, Row, Col, Collapse, Label, Button, } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import { schoolsActions, filterActions, skillsProfilesActions } from '../actions';
import config from '../config/config';
import { replaceLineBreakWithBr } from '../utils/Tools';
import moment from 'moment';
import translationsVars from '../translations/translationsVars';
import Loading from '../components/Loading';
import GeneratePDF from '../components/GeneratePDF';
import { sortProfessinalfields } from '../utils/Tools';
import { generateSkillsprofileStudentPDF } from '../pdf/SkillsprofileStudentPDF';
import { competencesActions } from '../actions';

class SkillsProfileStudentPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: { about: true, competences: true, interesttest: true, recommendation: true, results: true, professionalfield: [] },
            variantTextId: 'skillsprofile.body.variant.1.3',
            variantPrintId: 'skillsprofile.print.body.variant.1.3',
            translationsVars: translationsVars,
            order: "",
            desc: false,
            enabled: true,
        }
    }

    componentDidMount() {
        if (this.props.executionId >= 0) {
            this.props.dispatchGetSkillsProfileStudent(this.props.auth.token, this.props.executionId);
            this.props.dispatchGetStudentCompetencesStudentObserver(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, this.state.enabled);
        }
        this.setState({
            variantTextId: 'skillsprofile.body.variant.1.' + this.props.auth.address.id,
            variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.auth.address.id
        });
        this.setState({ translationsVars: { ...this.state.translationsVars, name: this.props.auth.firstname } });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.executionId !== this.props.executionId) {
            this.props.dispatchGetSkillsProfileStudent(this.props.auth.token, this.props.executionId);
            this.props.dispatchGetStudentCompetencesStudentObserver(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, this.state.enabled);
        }
        //Update variant text id for gender of student
        if (prevProps.studentAddress !== this.props.studentAddress || prevProps.skillsProfile !== this.props.skillsProfile) {
            if (this.props.skillsProfile && this.props.skillsProfile.jobvariant) {
                this.setState({
                    variantTextId: 'skillsprofile.body.variant.' + this.props.skillsProfile.jobvariant + '.' + this.props.auth.address.id,
                    variantPrintId: 'skillsprofile.print.body.variant.' + this.props.skillsProfile.jobvariant + '.' + this.props.auth.address.id
                });
            } else {
                this.setState({
                    variantTextId: 'skillsprofile.body.variant.1.' + this.props.auth.address.id,
                    variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.auth.address.id
                });
            }
        }
    }

    handleCollapse = (c, index) => {
        switch (c) {
            case 'about':
                this.setState({ collapse: { ...this.state.collapse, about: !this.state.collapse.about } })
                break;
            case 'competences':
                this.setState({ collapse: { ...this.state.collapse, competences: !this.state.collapse.competences } })
                break;
            case 'results':
                this.setState({ collapse: { ...this.state.collapse, results: !this.state.collapse.results } })
                break;
            case 'interesttest':
                this.setState({ collapse: { ...this.state.collapse, interesttest: !this.state.collapse.interesttest } })
                break;
            case 'recommendation':
                this.setState({ collapse: { ...this.state.collapse, recommendation: !this.state.collapse.recommendation } })
                break;
            case 'professionalfield':
                let pf = [...this.state.collapse.professionalfield];
                pf[index] = pf[index] ? !pf[index] : true;
                this.setState({ collapse: { ...this.state.collapse, professionalfield: pf } })
                break;
            default:
                break;
        }

    }

    handlePDF = () => {
        generateSkillsprofileStudentPDF(this.props, this.state);
    }


    render() {

        let datetime = null;
        if (this.props.skillsProfile && this.props.skillsProfile.edited) {
            datetime = moment(this.props.skillsProfile.edited.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.skillsProfile.edited.replace(" ", "T"), config.dateFormat.format, true) : null;
        }

        let interesttest = [];
        let lastPf = null;
        let countPf = 0;

        //get 3 professionalfields with rating over 33%
        this.props.skillsProfile && this.props.skillsProfile.interesttest && this.props.skillsProfile.interesttest.filter((item) => { return item.procent >= 33.3 }).forEach((pf) => {
            if (lastPf === null || countPf < 3 || lastPf.procent === pf.procent) {
                countPf++;
                lastPf = pf;
                interesttest.push(pf);
            }
        });
        interesttest.sort(sortProfessinalfields);

        return (
            <Container fluid role="main" className="content-margin">

                <h1><FormattedMessage id="skillsprofile.title.skillsprofile" /></h1>
                { /* Select School / Execution */}
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3">
                        <ExecutionSelect className="mb-3 p-0" label="skillsprofile.label.execution" executionId={this.props.executionId} past={true} />
                    </Col>
                </Row>

                {
                    this.props.loading ? <Loading /> : <div>

                        <Row className={this.props.skillsProfile && this.props.skillsProfile.approved ? "pt-0" : "d-none"}>
                            <Col xs="12" sm="6">
                                {/* edited */}
                                {datetime &&
                                    <Container fluid className={"small p-1 py-2 m-0 justify-content-start d-flex"}>
                                        <FormattedMessage id="skillsprofile.label.edited" />&nbsp;<FormattedDate value={datetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={datetime} hour={config.dateFormat.hour} minute={config.dateFormat.minute} />&nbsp;
                                        <FormattedMessage id="skillsprofile.label.editedby" />&nbsp;
                                        {this.props.skillsProfile && this.props.skillsProfile.editor ? this.props.skillsProfile.editor.firstname + ' ' + this.props.skillsProfile.editor.lastname : ''}
                                    </Container>
                                }
                            </Col>

                            {/* PDF */}
                            <Col xs="12" sm="6">
                                <Container fluid className="p-0 mb-4">
                                    <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this)} />
                                </Container>
                            </Col>
                        </Row>

                        <Container fluid className={this.props.skillsProfile && this.props.skillsProfile.approved ? "m-0 p-0" : "d-none m-0 p-0"}>
                            {/* ABOUT */}
                            <Row className="px-3">
                                <Col xs="12" className="border-bottom p-0 py-3" >
                                    <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.about ? "collapse.close" : "collapse.open"} /></Label>
                                    <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'about')} >
                                        <span className={this.state.collapse.about ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                        <FormattedMessage id="skillsprofile.header.about" />
                                    </Button>
                                </Col>
                            </Row>
                            <Collapse isOpen={this.state.collapse.about} className="border-bottom">
                                <Row className="px-3">
                                    <Col xs="12" className="text-justify py-3">
                                        <FormattedMessage id="skillsprofile.body.about" values={this.state.translationsVars} />
                                    </Col>
                                </Row>
                            </Collapse>
                            {/* RESULTS */}
                            <Row className="px-3">
                                <Col xs="12" className="border-bottom p-0 py-3" >
                                    <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.results ? "collapse.close" : "collapse.open"} /></Label>
                                    <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'results')} >
                                        <span className={this.state.collapse.results ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                        <FormattedMessage id="skillsprofile.header.results" />
                                    </Button>
                                </Col>
                            </Row>
                            <Collapse isOpen={this.state.collapse.results} className="border-bottom">

                                {/* INTERESTTEST */}
                                <Row className="px-3">
                                    <Col xs="12" className="mt-0 py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                                        <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.interesttest ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0 fw-bold" onClick={this.handleCollapse.bind(this, 'interesttest')} >
                                            <span className={this.state.collapse.interesttest ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="skillsprofile.subtitle.interesttest" values={this.state.translationsVars} />
                                        </Button>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.collapse.interesttest}>
                                    <Row className="px-3">
                                        <Col xs="12" className="m-0" style={{ paddingLeft: '1.35rem' }}>
                                            <Row>
                                                <Col xs="12" className="text-justify pb-3 border-bottom" style={{ paddingLeft: '2.5rem' }}>
                                                    {
                                                        this.props.skillsProfile && this.props.skillsProfile.interesttest !== null && this.props.skillsProfile.interesttest && interesttest.length === 0 &&
                                                        <FormattedMessage id={"professionalfield.text.nointerest." + this.props.auth.address.id} values={this.state.translationsVars} />
                                                    }
                                                    {
                                                        this.props.skillsProfile && this.props.skillsProfile.interesttest && interesttest.length === 1 &&
                                                        <FormattedMessage id={"professionalfield.text.start.single"} values={this.state.translationsVars} />
                                                    }
                                                    {
                                                        this.props.skillsProfile && this.props.skillsProfile.interesttest && interesttest.length > 1 &&
                                                        <FormattedMessage id={"professionalfield.text.start.multiple"} values={this.state.translationsVars} />
                                                    }
                                                    <i>
                                                        {
                                                            interesttest.map((pf, index) => {
                                                                if (index === interesttest.length - 1 && index === 0) {
                                                                    return <FormattedMessage id={"professionalfield." + pf.id + ".name"} key={"pf" + index} />
                                                                }
                                                                else if (index === interesttest.length - 1 && index >= 0) {
                                                                    return <span key={"pf" + index}><FormattedMessage id={"professionalfield.text.and"} /><FormattedMessage id={"professionalfield." + pf.id + ".name"} /></span>
                                                                } else if (index === 0) {
                                                                    return <FormattedMessage id={"professionalfield." + pf.id + ".name"} key={"pf" + index} />
                                                                } else {
                                                                    return <span key={"pf" + index}>, <FormattedMessage id={"professionalfield." + pf.id + ".name"} /></span>

                                                                }
                                                            })
                                                        }
                                                    </i>
                                                    {
                                                        this.props.skillsProfile && this.props.skillsProfile.interesttest && this.props.skillsProfile.interesttest.length > 0 && this.props.skillsProfile.interesttest[0].procent >= 33.3 &&
                                                        <FormattedMessage id={"professionalfield.text.end." + this.props.auth.address.id} values={this.state.translationsVars} />
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse>

                                <Row className="px-3">
                                    <Col xs="12" className="py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                                        <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.competences ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'competences')} >
                                            <span className={this.state.collapse.competences ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="skillsprofile.subtitle.competences" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.collapse.competences} className="mb-3">
                                    {/* COMPETENCES */

                                        this.props.skillsProfile && this.props.skillsProfile.competences && this.props.skillsProfile.competences.map((competence, index) => {

                                            return (

                                                <Row className="px-3" key={"competence" + index} >
                                                    <Col xs="12" className="m-0" style={{ paddingLeft: '1.35rem' }}>
                                                        <Row >
                                                            <Col xs="12" className="pt-3 fw-bold" style={{ paddingLeft: '2.5rem' }}>
                                                                <FormattedMessage id={competence.name} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12" className="pb-3" style={{ paddingLeft: '2.5rem' }}>
                                                                {
                                                                    competence.observations
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }

                                    {
                                        this.props.skillsProfile && this.props.skillsProfile.competences && this.props.skillsProfile.competences.length === 0 &&
                                        <Row className="px-3" >
                                            <Col xs="12" className="m-0" style={{ paddingLeft: '2.9rem' }}><FormattedMessage id="skillsprofile.placeholder.competences" /></Col>
                                        </Row>
                                    }

                                </Collapse>

                            </Collapse>
                            {/* RECOMMENDATION */}
                            <Row className="px-3">
                                <Col xs="12" className="py-3 px-0 border-bottom" >
                                    <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.recommendation ? "collapse.close" : "collapse.open"} /></Label>
                                    <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'recommendation')} >
                                        <span className={this.state.collapse.recommendation ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                        <FormattedMessage id="skillsprofile.header.recommendation" />
                                    </Button>
                                </Col>
                            </Row>
                            <Collapse isOpen={this.state.collapse.recommendation}>
                                {this.props.skillsProfile && this.props.skillsProfile.jobtext && <Row className="px-3">
                                    <Col xs="12" className="text-justify py-3" style={{ paddingLeft: '1.35rem' }} >
                                        {replaceLineBreakWithBr(this.props.skillsProfile.jobtext)}
                                    </Col>
                                </Row>}
                                <Row className="px-3">
                                    <Col xs="12" className="text-justify border-bottom py-3" style={{ paddingLeft: '1.35rem' }}>
                                        <FormattedMessage id={this.state.variantTextId} values={this.state.translationsVars} />
                                    </Col>
                                </Row>
                            </Collapse>
                        </Container>
                        <Container fluid className={!(this.props.skillsProfile && this.props.skillsProfile.approved === 0) ? "d-none" : "p-0 fw-bold"}>
                            <FormattedMessage id="skillsprofile.label.noavailable" />
                        </Container>
                    </div>
                }
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executions: state.executions.executions ? state.executions.executions : [],
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        skillsProfile: state.skillsProfile.skillsProfile,
        loading: state.executions.loading || state.skillsProfile.loading,
        competences: state.competences.competences,
        studentFirstname: state.auth.firstname,
        studentLastname: state.auth.lastname,
        studentClass: state.auth.class,
        studentAddress: state.auth.address.id,
        studentObserverRating: state.competences.studentObserverRating,
        studentId: state.auth.id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetSkillsProfileStudent: (token, executionId, studentId) => {
            dispatch(skillsProfilesActions.getSkillsProfileStudent(token, executionId, studentId))
        },
        dispatchGetStudentCompetencesStudentObserver: (token, executionId, order, desc, enabled) => {
            dispatch(competencesActions.getStudentCompetencesStudentObserver(token, executionId, order, desc, enabled));
        },

    };
}


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SkillsProfileStudentPage));