import { skillsProfilesService } from '../services';
import { actionTypes } from './types';

export const skillsProfilesActions = {
    getSkillsProfile,
    getSkillsProfileStudent,
    updateSkillsProfile,
    createSkillsProfile,
    approvedSkillsProfile,
};

function getSkillsProfile(token, executionId, studentsId, honorarkraftId) {
    return dispatch => {
        dispatch(request({ getSkillsProfile }));

        skillsProfilesService.getSkillsProfile(token, executionId, studentsId, honorarkraftId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(skillsProfile) { return { type: actionTypes.SKILLSPROFILE_REQUEST, skillsProfile } }
    function success(skillsProfile) { return { type: actionTypes.SKILLSPROFILE_SUCCESS, skillsProfile } }
    function failure(error) { return { type: actionTypes.SKILLSPROFILE_FAILURE, error } }
}

function getSkillsProfileStudent(token, executionId) {
    return dispatch => {
        dispatch(request({ getSkillsProfile }));

        skillsProfilesService.getSkillsProfileStudent(token, executionId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(skillsProfile) { return { type: actionTypes.SKILLSPROFILE_STUDENT_REQUEST, skillsProfile } }
    function success(skillsProfile) { return { type: actionTypes.SKILLSPROFILE_STUDENT_SUCCESS, skillsProfile } }
    function failure(error) { return { type: actionTypes.SKILLSPROFILE_STUDENT_FAILURE, error } }
}

function createSkillsProfile(token, executionId, studentsId, honorarkraftId, skillsProfile) {
    return dispatch => {
        dispatch(request({ createSkillsProfile }));

        skillsProfilesService.createSkillsProfile(token, executionId, studentsId, honorarkraftId, skillsProfile)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.SKILLSPROFILE_CREATE_REQUEST } }
    function success(status) { return { type: actionTypes.SKILLSPROFILE_CREATE_SUCCESS, status } }
    function failure(error) { return { type: actionTypes.SKILLSPROFILE_CREATE_FAILURE, error } }
}

function updateSkillsProfile(token, honorarkraftId, skillsProfile) {
    return dispatch => {
        dispatch(request({ updateSkillsProfile }));

        skillsProfilesService.updateSkillsProfile(token, honorarkraftId, skillsProfile)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.SKILLSPROFILE_UPDATE_REQUEST } }
    function success(status) { return { type: actionTypes.SKILLSPROFILE_UPDATE_SUCCESS, status } }
    function failure(error) { return { type: actionTypes.SKILLSPROFILE_UPDATE_FAILURE, error } }
}

function approvedSkillsProfile(token, honorarkraftId, skillsProfileId, approved) {
    return dispatch => {
        dispatch(request({ approvedSkillsProfile }));

        skillsProfilesService.approvedSkillsProfile(token, honorarkraftId, skillsProfileId, approved)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.SKILLSPROFILE_APPROVED_REQUEST } }
    function success(status) { return { type: actionTypes.SKILLSPROFILE_APPROVED_SUCCESS, status } }
    function failure(error) { return { type: actionTypes.SKILLSPROFILE_APPROVED_FAILURE, error } }
}
