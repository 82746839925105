import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class SchoolSelect extends React.Component {


    static propTypes = {
        schoolId: PropTypes.number,
        handleSchool: PropTypes.func,
        schools: PropTypes.array,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        withEmpty: PropTypes.bool,
        emptyLabel: PropTypes.string,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        filter: PropTypes.array,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        schools: [],
        schoolId: -1,
        withEmpty: false,
        invalid: false,
        filter: [],
        disabled: false,
        label: "schoolselect.label.default",
        emptyLabel: "schoolselect.option.pleaseselect",
    };

    constructor(props) {
        super(props);
        this.state = {
            schools: [],
            emptyLabel: "schoolselect.option.pleaseselect",
        }
    }

    componentDidMount() {
        if (this.props.emptyLabel) {
            this.setState({ emptyLabel: this.props.emptyLabel });
        }
        if (this.props.schools.length > 0) {
            this.setState({ schools: this.props.schools.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });
        } else {
            this.setState({ schools: [] })
        }
        if (!this.props.withEmpty && this.props.schoolId === -1 && this.props.schools.length > 0) {
            this.props.handleSchool(this.props.schools[0]);
        }
        if (this.props.schools.filter(el => el.id === this.props.schoolId).length === 0 && this.props.schoolId !== -1) {
            this.props.handleSchool(this.props.schools[0]);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.emptyLabel !== this.props.emptyLabel) {
            this.setState({ emptyLabel: this.props.emptyLabel });
        }

        let existsSchool = true;
        if (prevProps.schools !== this.props.schools || prevProps.filter !== this.props.filter) {
            if (this.props.schools.length > 0) {
                existsSchool = !((this.props.filter && this.props.filter.indexOf(this.props.schoolId) >= 0 && this.props.filter.length > 0));
                this.setState({
                    schools: this.props.schools.filter(el => {
                        return !(this.props.filter.indexOf(el.id) >= 0);
                    })
                });
            } else {
                this.setState({ schools: [] })
            }
            if ((!this.props.withEmpty && this.props.schoolId === -1 && this.props.schools.length > 0)) {
                this.props.handleSchool(this.props.schools[0]);
            } else if (!existsSchool) {
                this.props.handleSchool(null);
            }
        }
    }

    createSchoolItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: this.state.emptyLabel })}</option>);
        this.state.schools.forEach((school) => {
            items.push(<option key={school.id} value={school.id} >{school.name + " (" + school.street + ", " + school.postcode + " " + school.place + ")"}</option>);
        })
        return items;
    }

    handleSchool = (e) => {
        let id = parseInt(e.target.value, 10);
        if (id !== -1) {
            let school = this.state.schools.filter(el => el.id === id)[0] || null;
            if (school) {
                this.props.handleSchool(school);
            }
        } else {
            this.props.handleSchool(-1);
        }
    }

    render() {
        return (
            <Container fluid className={this.state.schools.length > 1 || this.props.withEmpty ? this.props.className : "d-none " + this.props.className}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "schoolselect.label.default" ? "fw-bold" : "d-none"} for="school"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled || this.props.saving} invalid={this.props.invalid} bsSize="sm" type="select" name="school" id="school" value={this.props.schoolId} onChange={this.handleSchool}>
                        {this.createSchoolItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        saving: state.observations.saving || state.competences.saving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchoolSelect));