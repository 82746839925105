import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Input, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Form, Alert, FormFeedback } from 'reactstrap';
import { isEquivalent, isEmail } from '../utils/Tools';
import Confirm from './Confirm';
import config from '../config/config';
import SchooltypeSelect from './SchooltypeSelect';
import { schoolsActions, departmentsActions } from '../actions';
import DepartmentSelect from './DepartmentSelect';

class SchoolAdministrationModal extends React.Component {

    static propTypes = {
        school: PropTypes.object,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        save: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            school: null,
            open: false,
            new: false,
            alert: { visible: false, textId: "schooladministrationmodal.alert.inputs", color: "success" },
            invalid: { name: false, street: false, place: false, postcode: false, phone: false, fax: false, email: false }
        }
    }

    componentDidMount() {
        this.props.dispatchGetSchooltypes(this.props.auth.token);
        this.props.dispatchGetDepartments(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {

        if (this.props.school && prevProps.school !== this.props.school) {
            let ex = JSON.parse(JSON.stringify(this.props.school));
            this.setState({ school: ex, alert: { visible: false, textId: "schooladministrationmodal.alert.inputs", color: "success" } });
        }

        if (prevProps.open !== this.props.open) {
            this.setState({ new: false });
        }

        if (this.props.school === null && !this.state.new) {
            this.setState({
                new: true,
                school: {
                    id: null,
                    editable: true,
                    schooltype: { id: -1 },
                    department: { id: -1 },
                    name: '',
                    street: '',
                    place: '',
                    postcode: '',
                    phone: '',
                    fax: '',
                    email: '',
                    homepage: '',
                },
                invalid: { schooltype: false, department: false, name: false, street: false, place: false, postcode: false, phone: false, email: false },
                alert: { visible: false, textId: "schooladministrationmodal.alert.inputs", color: "success" }
            });
        }

        if (prevProps.createSchoolState !== this.props.createSchoolState || prevProps.updateSchoolState !== this.props.updateSchoolState) {
            if (this.props.createSchoolState === 2 || this.props.updateSchoolState === 2) {
                if (this.props.createError === 'email' || this.props.updateError === 'email') {
                    this.setState({
                        invalid: {
                            schooltype: this.state.invalid.schooltype,
                            department: this.state.invalid.department,
                            name: this.state.invalid.name,
                            street: this.state.invalid.street,
                            place: this.state.invalid.place,
                            postcode: this.state.invalid.postcode,
                            phone: this.state.invalid.phone,
                            email: false
                        }
                    });
                }
            }
        }

    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            if (this.props.school && !isEquivalent(this.props.school, this.state.school)) {
                this.toggleConfirm();
            } else {
                this.props.toggle();
            }
        } else if (type === "save") {
            this.save();
        }
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    save = async (e) => {
        let newState = { ...this.state }
        let doSave = true;
        newState.confirm = false;
        if (this.state.school.schooltype.id === -1) {
            newState.invalid.schooltype = true;
            doSave = false;
        }
        if (this.state.school.department.id === -1) {
            newState.invalid.department = true;
            doSave = false;
        }
        if (this.state.school.name.length <= 1) {
            newState.invalid.name = true;
            doSave = false;
        }
        if (this.state.school.street.length <= 1) {
            newState.invalid.street = true;
            doSave = false;
        }
        if (this.state.school.place.length <= 1) {
            newState.invalid.place = true;
            doSave = false;
        }
        if (this.state.school.postcode.length <= 1) {
            newState.invalid.postcode = true;
            doSave = false;
        }
        if (this.state.school.phone.length <= 1) {
            newState.invalid.phone = true;
            doSave = false;
        }
        if (!isEmail(this.state.school.email)) {
            newState.invalid.email = true;
            doSave = false;
        }

        if (doSave) {
            this.props.save(this.state.school);
        } else {
            newState.alert = { visible: true, textId: "schooladministrationmodal.alert.inputs", color: "danger" };
            setTimeout(this.hideAlert, config.alertTimeOut);
        }
        this.setState(newState);
    }

    hideAlert = (e) => {
        this.setState({ alert: { visible: false, textId: "schooladministrationmodal.alert.inputs", color: "danger" } });
    };

    handleSchooltype = async (schooltype) => {
        let newState = { ...this.state }
        newState.school.schooltype = schooltype ? schooltype : { id: -1 };
        newState.invalid.schooltype = schooltype ? false : true;
        await this.setState(newState);
    }
    handleDepartment = async (department) => {
        let newState = { ...this.state }
        newState.school.department = department ? department : { id: -1 };
        newState.invalid.department = department ? false : true;
        await this.setState(newState);
    }
    handleName = async (e) => {
        let newState = { ...this.state }
        newState.school.name = e.target.value;
        newState.invalid.name = false;
        await this.setState(newState);
    }
    handleStreet = async (e) => {
        let newState = { ...this.state }
        newState.school.street = e.target.value;
        newState.invalid.street = false;
        await this.setState(newState);
    }
    handlePlace = async (e) => {
        let newState = { ...this.state }
        newState.school.place = e.target.value;
        newState.invalid.place = false;
        await this.setState(newState);
    }
    handlePostcode = async (e) => {
        let newState = { ...this.state }
        newState.school.postcode = e.target.value;
        newState.invalid.postcode = false;
        await this.setState(newState);
    }
    handlePhone = async (e) => {
        let newState = { ...this.state }
        newState.school.phone = e.target.value;
        newState.invalid.phone = false;
        await this.setState(newState);
    }
    handleFax = async (e) => {
        let newState = { ...this.state }
        newState.school.fax = e.target.value;
        await this.setState(newState);
    }
    handleEmail = async (e) => {
        let newState = { ...this.state }
        newState.school.email = e.target.value;
        newState.invalid.email = false;
        await this.setState(newState);
    }
    handleHomepage = async (e) => {
        let newState = { ...this.state }
        newState.school.homepage = e.target.value;
        await this.setState(newState);
    }

    render() {
        return (
            <Container>
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                <Confirm cancel save={this.save} dontsave={this.props.toggle} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="confirm.body.save" />
                <Form>
                    <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                        <ModalHeader className={this.props.school && "edit"}><FormattedMessage id={!this.props.school ? "schooladministrationmodal.title.new" : "schooladministrationmodal.title.edit"} /></ModalHeader>

                        <ModalBody className="px-4">
                            <Row className="border-bottom">
                                <Col className="fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <FormattedMessage id="schooladministrationmodal.label.schooltype" />
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <SchooltypeSelect feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.schooltype} withEmpty className="p-0" schooltypes={this.props.schooltypes} handleSchooltype={this.handleSchooltype.bind(this)} schooltypeId={this.state.school ? this.state.school.schooltype.id : -1} />
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <FormattedMessage id="schooladministrationmodal.label.department" />
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <DepartmentSelect feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.department} withEmpty className="p-0" departments={this.props.departments} handleDepartment={this.handleDepartment.bind(this)} departmentId={this.state.school ? this.state.school.department.id : -1} />
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="name" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.name" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.name} name="name" type="text" id="name" value={this.state.school && this.state.school.name} onChange={this.handleName} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.name ? "true" : "false"}><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="name" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.street" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.street} name="street" type="text" id="street" value={this.state.school && this.state.school.street} onChange={this.handleStreet} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.street ? "true" : "false"}><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="place" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.place" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.place} name="place" type="text" id="place" value={this.state.school && this.state.school.place} onChange={this.handlePlace} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.place ? "true" : "false"}><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="postcode" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.postcode" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.postcode} name="postcode" type="text" id="postcode" value={this.state.school && this.state.school.postcode} onChange={this.handlePostcode} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.postcode ? "true" : "false"}><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="phone" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.phone" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.phone} name="phone" type="text" id="phone" value={this.state.school && this.state.school.phone} onChange={this.handlePhone} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.phone ? "true" : "false"}><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="fax" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.fax" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" name="fax" type="text" id="fax" value={this.state.school && this.state.school.fax} onChange={this.handleFax} aria-required />
                                        <FormFeedback className="ps-1" invalid="false"><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="border-bottom">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="email" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.email" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.email} name="email" type="text" id="email" value={this.state.school && this.state.school.email} onChange={this.handleEmail} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.email ? "true" : "false"}><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="">
                                <Col className="fw-bold" xs="12" md="3">
                                    <Label for="homepage" className="d-flex align-items-center h-100"><FormattedMessage id="schooladministrationmodal.label.homepage" /></Label>
                                </Col>
                                <Col className="mt-1" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" name="homepage" type="text" id="homepage" value={this.state.school && this.state.school.homepage} onChange={this.handleHomepage} aria-required />
                                        <FormFeedback className="ps-1" invalid="false"><FormattedMessage id="schooladministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>

                        <ModalFooter>
                            <Button type="submit" color="primary" name="cancel" id="cancel" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.cancel" /></Button>
                            <Button type="submit" color="primary" name="save" id="save" onClick={this.handleSubmit.bind(this, "save")}><FormattedMessage id="button.save" /></Button>
                        </ModalFooter>

                    </Modal>
                </Form>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schooltypes: state.schools.schooltypes,
        departments: state.departments.departments,
        createUserState: state.userProfile.createUserState,
        updateUserState: state.userProfile.updateUserState,
        createError: state.userProfile.createError,
        updateError: state.userProfile.updateError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetDepartments: (token) => {
            dispatch(departmentsActions.getDepartments(token, "name", false, null, null, null));
        },
        dispatchGetSchooltypes: (token) => {
            dispatch(schoolsActions.getSchooltypes(token, "name", false));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchoolAdministrationModal));