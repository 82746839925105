import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Collapse, Label, Button } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import ExecutionsPastCheckbox from '../components/ExecutionsPastCheckbox';
import SchoolSelect from '../components/SchoolSelect';
import StudentsButtons from '../components/StudentsButtons';
import GeneratePDF from '../components/GeneratePDF';
import config from '../config/config';
import PropTypes from 'prop-types';
import { schoolsActions, filterActions, competencesActions, skillsProfilesActions } from '../actions';
import translationsVars from '../translations/translationsVars';
import { generateFeedbackPDF } from '../pdf';
import Loading from '../components/Loading';
import { Bar } from 'react-chartjs-2';
import { sortProfessinalfields } from '../utils/Tools';

class FeedbackPage extends Component {

    static propTypes = {
        dispatchGetCompetencesStudentObserver: PropTypes.func.isRequired,
        executionId: PropTypes.number.isRequired,
        studentId: PropTypes.number,
        studentFirstname: PropTypes.string,
        studentLastname: PropTypes.string,
        competences: PropTypes.array.isRequired,
        studentObserverRating: PropTypes.object.isRequired,
        translationsVars: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            order: "",
            desc: false,
            enabled: true,
            symbolselfassessment: "S",
            symbolexternalassessment: "F",
            translationsVars: translationsVars,
            collapse: {
                selfexternalassessment: false,
                skillsprofile: false
            },
        }

    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        if (this.props.executionId >= 0 && this.props.studentId >= 0) {
            this.props.dispatchGetCompetencesStudentObserver(this.props.auth.token, this.props.executionId, this.props.studentId, this.state.order, this.state.desc, this.state.enabled);
            this.props.dispatchGetSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId);
        }
        this.setState({ translationsVars: { ...this.state.translationsVars, filename: this.props.studentFirstname + "_" + this.props.studentLastname, name: this.props.studentFirstname, } });
    }

    componentDidUpdate(prevProps) {

        if ((prevProps.executionId !== this.props.executionId || prevProps.studentId !== this.props.studentId) && this.props.executionId >= 0 && this.props.studentId >= 0) {
            this.props.dispatchGetCompetencesStudentObserver(this.props.auth.token, this.props.executionId, this.props.studentId, this.state.order, this.state.desc, this.state.enabled);
            this.setState({ translationsVars: { ...this.state.translationsVars, filename: this.props.studentFirstname + "_" + this.props.studentLastname, name: this.props.studentFirstname, } });
            this.props.dispatchGetSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId);
        }
    }

    handlePDF = async () => {
        generateFeedbackPDF(this.props, this.state);
    }

    handleCollapse = (c) => {
        switch (c) {
            case 'selfexternalassessment':
                this.setState({ collapse: { ...this.state.collapse, selfexternalassessment: !this.state.collapse.selfexternalassessment } })
                break;
            case 'skillsprofile':
                this.setState({ collapse: { ...this.state.collapse, skillsprofile: !this.state.collapse.skillsprofile } })
                break;
            default:
                break;
        }
    }

    getBarData(bit) {
        let barData = { labels: [], datasets: [], borderWidth: 0.5 };
        const barColors = ['rgba(56,57,128,0.75)', 'rgba(56,57,128,0.75)', 'rgba(56,57,128,0.75)']; // single color

        if (bit && bit.interesttest) {
            bit.interesttest.sort(sortProfessinalfields);

            let d = [];
            let colors = [];
            bit.interesttest.forEach((pf) => {
                let p = pf.value * 100 / pf.maxValue;
                colors.push(p >= 66.6 ? barColors[0] : p >= 33.3 ? barColors[1] : barColors[2]);
                d.push(p)
                barData.labels.push(this.props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" }));
            })
            barData.datasets.push({ label: this.props.intl.formatMessage({ id: "interesttest.chart.value" }), data: d, backgroundColor: colors });
        }
        return barData;
    }

    render() {
        let configIcons = config.icons;
        let barOptions = {
            indexAxis: 'y',
            scales: {

                x: {
                    // display: false,
                    min: 0,
                    max: 100,
                    ticks: {
                        display: false,
                        stepSize: 33.4,
                        suggestedMin: 0,
                        suggestedMax: 100,
                        max: 100
                    },
                    grid: {
                        color: '#bababa',
                        offsetGridLines: true,
                        lineWidth: 1,
                    },
                    // offset: true,
                }
            },
            plugins: {
                legend: { display: false },
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let label = data.datasets[tooltipItem.datasetIndex].label || '';
                        if (label) {
                            label += ': ';
                            label += parseFloat(tooltipItem.xLabel).toFixed(0) + " %";
                        }
                        return label;
                    }
                }
            }
        };
        let barData = this.getBarData(this.props.skillsProfile);
        return (
            <Container fluid role="main" className="content-margin">
                <h1 ><FormattedMessage id="feedback.label.feedbackconversation" /></h1>
                <Row>
                    <Col xs="12" className="my-3">
                        <ExecutionsPastCheckbox />
                    </Col>
                </Row>

                { /* Select School / Execution */}
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3" className={this.props.schools.length > 1 ? "" : "d-none"}>
                        <SchoolSelect className="mb-3 p-0" withEmpty label="observationsevaluations.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3">
                        <ExecutionSelect className="mb-3 p-0" withEmpty onlyInvolved label="observationsevaluations.label.execution" executionId={this.props.executionId} schoolId={this.props.schoolId} onlyKompo7={true} />
                    </Col>
                </Row>
                { /* Students */}
                <StudentsButtons ignoreHonorarkraft className="mt-4" executionId={this.props.executionId} disabled={this.props.loading} />
                <Row className={this.props.studentId > 0 ? "pt-2" : "d-none"}>
                    <Col xs="12">
                        {/* PDF */}
                        <GeneratePDF className="p-0 m-0 mb-1 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this)} disabled={this.props.loading} />
                    </Col>
                </Row>
                {/* Show Loading */}
                {this.props.loading && <Loading />}
                {!this.props.loading &&
                    <Row className={this.props.studentId > 0 ? "p0" : "d-none"}>
                        <Col xs="12" className="py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                            <Label className="d-none" for="collapse-selfexternalassessment"><FormattedMessage id={this.state.collapse.selfexternalassessment ? "collapse.close" : "collapse.open"} /></Label>
                            <Button name="collapse-selfexternalassessment" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'selfexternalassessment')} >
                                <span className={this.state.collapse.selfexternalassessment ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="feedback.subtitle.selfexternalassessment" />
                            </Button>
                        </Col>
                    </Row>
                }
                {!this.props.loading &&
                    <Collapse isOpen={this.state.collapse.selfexternalassessment}>
                        <Container className={this.props.studentId > 0 ? "bg-white p-0" : "d-none"} fluid>

                            <Container className="bg-white m-0" fluid>
                                <Row>
                                    <Col xs="4" className="text-uppercase border fw-bold d-flex flex-column justify-content-end py-2">
                                        <FormattedMessage id="feedback.label.competence" />
                                    </Col>
                                    <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                        <img className="th-smiley mb-2" src={require('../assets/images/Smiley_01.svg').default} alt="" />
                                        <br />
                                        <FormattedMessage id="feedback.label.rating-1" />
                                    </Col>
                                    <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                        <img className="th-smiley mb-2" src={require('../assets/images/Smiley_02.svg').default} alt="" />
                                        <br />
                                        <FormattedMessage id="feedback.label.rating-2" />
                                    </Col>
                                    <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                        <img className="th-smiley mb-2" src={require('../assets/images/Smiley_03.svg').default} alt="" />
                                        <br />
                                        <FormattedMessage id="feedback.label.rating-3" />
                                    </Col>
                                    <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                        <img className="th-smiley mb-2" src={require('../assets/images/Smiley_04.svg').default} alt="" />
                                        <br />
                                        <FormattedMessage id="feedback.label.rating-4" />
                                    </Col>
                                </Row>
                            </Container>
                            <Container fluid className="mb-1" >
                                {/* Table */}
                                {
                                    this.props.competences.map((trData, trIndex) => {
                                        return <Row key={trIndex} className="row-striped">
                                            <Col xs="4" className="border py-2">
                                                <p><FormattedMessage id={trData.description} /></p>
                                                <p className="text-uppercase text-ellipse text-italic mb-0 pb-0"><em><FormattedMessage id={trData.name} /></em></p>
                                            </Col>
                                            <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 1)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 1 || el.rating2 === 1))).length > 0 ? <span className="externalassessment"></span> : ""}
                                            </Col>
                                            <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 2)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 2 || el.rating2 === 2))).length > 0 ? <span className="externalassessment"></span> : ""}
                                            </Col>
                                            <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 3)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 3 || el.rating2 === 3))).length > 0 ? <span className="externalassessment"></span> : ""}
                                            </Col>
                                            <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 4)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 4 || el.rating2 === 4))).length > 0 ? <span className="externalassessment"></span> : ""}
                                            </Col>
                                        </Row>
                                    })
                                }
                            </Container>
                            {/* Descriptions */}
                            <Row className={this.props.studentId > 0 ? "small fw-bold mb-4 mt-0 ps-3 pt-0" : "d-none"}>
                                <Col className="pt-0 mt-0" xs="12">
                                    <FormattedMessage id="feedback.description.label.selfassessment" />
                                    <img className="descriptionselfassessment" src={require('../assets/' + configIcons.student)} alt={this.props.intl.formatMessage({ id: "feedback.description.alt.externalassessment" })} />
                                    <FormattedMessage id="feedback.description.label.externalassessment" />
                                    <img className="descriptionexternalassessment" src={require('../assets/' + configIcons.observer)} alt={this.props.intl.formatMessage({ id: "feedback.description.alt.noassessment" })} />
                                    <FormattedMessage id="feedback.description.label.noassessment" />
                                </Col>
                            </Row>
                        </Container>
                    </Collapse>
                }
                {!this.props.loading &&
                    <Row className={this.props.studentId > 0 ? "p0 border-top" : "d-none"}>
                        <Col xs="12" className="py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                            <Label className="d-none" for="collapse-skillsprofile"><FormattedMessage id={this.state.collapse.skillsprofile ? "collapse.close" : "collapse.open"} /></Label>
                            <Button name="collapse-skillsprofile" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'skillsprofile')} >
                                <span className={this.state.collapse.skillsprofile ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="feedback.subtitle.skillsprofile" />
                            </Button>
                        </Col>
                    </Row>
                }
                {!this.props.loading &&
                    <Collapse isOpen={this.state.collapse.skillsprofile}>
                        <Row className={this.props.studentId > 0 ? "px-3" : "d-none"}>
                            <Col xs="12" className="m-0" style={{ paddingLeft: '1.35rem' }}>
                                <Row>
                                    <Col xs="12" className="text-justify pb-3 border-bottom" style={{ paddingLeft: '2.5rem' }}>
                                        {
                                            (this.props.skillsProfile === null || (this.props.skillsProfile && this.props.skillsProfile.interesttest === null)) &&
                                            <FormattedMessage id={"professionalfield.text.nointeresttestcompleted"} values={this.state.translationsVars} />
                                        }
                                        {
                                            this.props.skillsProfile && this.props.skillsProfile.interesttest !== null && this.props.skillsProfile.interesttest.length === 0 &&
                                            <FormattedMessage id={"professionalfield.text.nointerest." + this.props.studentAddress} values={this.state.translationsVars} />
                                        }
                                        {
                                            this.props.skillsProfile && this.props.skillsProfile.interesttest && this.props.skillsProfile.interesttest.length >= 1 &&
                                            <div className="container-bar">
                                                <Bar data={barData} responsive="false" height={100} options={barOptions} />
                                                <div id="container-smiley">
                                                    <div id="container-bit-smiley-1"></div>
                                                    <div id="container-bit-smiley-2"></div>
                                                    <div id="container-bit-smiley-3"></div>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Collapse>
                }
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        studentId: state.filter.student && state.filter.student.id ? state.filter.student.id : -1,
        studentFirstname: state.filter.student && state.filter.student.firstname ? state.filter.student.firstname : "",
        studentLastname: state.filter.student && state.filter.student.lastname ? state.filter.student.lastname : "",
        studentAddress: state.filter.student && state.filter.student.address && state.filter.student.address.id ? state.filter.student.address.id : 3,
        skillsProfile: state.skillsProfile.skillsProfile,
        competences: state.competences.competences,
        studentObserverRating: state.competences.studentObserverRating,
        loading: state.competences.loading || state.competences.loadingstudentObserverRating,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetCompetencesStudentObserver: (token, executionId, studentId, order, desc, enabled) => {
            dispatch(competencesActions.getCompetencesStudentObserver(token, executionId, studentId, null, order, desc, enabled));
        },
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetSkillsProfile: (token, executionId, studentId) => {
            dispatch(skillsProfilesActions.getSkillsProfile(token, executionId, studentId))
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedbackPage));