import * as React from 'react';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Label } from 'reactstrap';
import { isEquivalent } from '../utils/Tools';
import Confirm from './Confirm';
import config from '../config/config';
import { executionsActions, schoolsActions } from '../actions';
import MultipleSelect from './MultipleSelect';
import Authorization from '../components/Authorization';
import { CheckAuthorization } from '../models/Authorization';
import DisplaySchool from './DisplaySchool';
import SchoolyearsSelect from './SchoolyearsSelect';
import GeneratePDF from '../components/GeneratePDF';
import { generateExecutionsGroupsPDF } from '../pdf';
import moment from 'moment';
import Loading from '../components/Loading';

class ExecutionsGroupsModal extends React.Component {

    static propTypes = {
        auth: PropTypes.object.isRequired,
        observers: PropTypes.array.isRequired,
        groups: PropTypes.array.isRequired,
        students: PropTypes.array.isRequired,
        execution: PropTypes.object,
        data: PropTypes.object,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        save: PropTypes.func,
        edit: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            execution: {},
            open: false,
            groups: [],
            multipleStudents: [],
            groups_db: [],
            editGroup: 0,
            schoolyearsId: -1,
        }

    }

    componentDidMount() {

        this.props.dispatchGetSchoolyears(this.props.auth.token);

        if (this.props.execution) {
            this.props.dispatchGetObservers(this.props.auth.token, this.props.execution.id);
            this.props.dispatchGetGroups(this.props.auth.token, this.props.execution.id);
            this.props.dispatchGetStudents(this.props.auth.token, this.props.execution.id, this.props.execution.procedure.id);
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.execution && prevProps.execution !== this.props.execution) || (prevProps.open !== this.props.open && this.props.open === true)) {
            this.props.dispatchGetObservers(this.props.auth.token, this.props.execution.id);
            this.props.dispatchGetGroups(this.props.auth.token, this.props.execution.id);
            this.props.dispatchGetStudents(this.props.auth.token, this.props.execution.id, this.props.execution.procedure.id);

            this.setState({ execution: this.props.execution, editGroup: 0 });
        }
        if (prevProps.groups !== this.props.groups) {
            let editGroups = this.props.groups.length > 0 ? (this.props.groups[0] && this.props.groups[0].number ? this.props.groups[0].number : 1) : 0
            this.setState({ groups: this.props.groups, groups_db: JSON.parse(JSON.stringify(this.props.groups)), editGroup: editGroups })
        }

        if (prevProps.students !== this.props.students) {
            let multipleStudents = [];
            this.getSortStudents(this.props.students).forEach((student) => {
                multipleStudents.push({
                    id: student.id,
                    text: student.lastname + ", " + student.firstname + " (" + student.class + ")",
                    schoolyears: student.schoolyears,
                    school: this.props.execution && student.school.id !== this.props.execution.school.id ? student.school : null
                })
            });
            this.setState({ multipleStudents: multipleStudents });
        }
    }

    getSortStudents(students) {
        return students.sort((a, b) => {
            let nameA = a.lastname.toUpperCase();
            let nameB = b.lastname.toUpperCase();
            if (nameA < nameB) {
                return -1;
            } else if (nameA > nameB) {
                return 1;
            } else {
                let nameA = a.firstname.toUpperCase();
                let nameB = b.firstname.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                } else if (nameA > nameB) {
                    return 1;
                } else {
                    return 0;
                }

            }
        });
    }

    createObserversItems(grpIndex) {
        let items = [];
        items.push(<option key={0} value={0} >-</option>);
        let observers = [];

        /* dont check current group */
        let groups = this.state.groups.filter((gr, index) => { return grpIndex !== index });
        /* Filter selected observers */
        observers = this.props.observers.filter((observer) => {
            for (let i = 0; i < groups.length; i++) {
                /* users is selected  */
                if (groups[i].users_id === observer.id) {
                    return false;
                }
            }
            return true;
        });

        observers.forEach((observer, index) => {
            if (observer.procedure === 0 || (this.state.execution && observer.procedure === this.state.execution.procedure.id)) {
                items.push(<option key={grpIndex + "_" + index} value={observer.id} >{observer.lastname + ", " + observer.firstname + " (" + observer.personalnumber + ")"}</option>);
            }
        })
        return items;
    }

    createStudentsItems(grpIndex) {
        let students = [];

        /* dont check current group */
        let groups = this.state.groups.filter((gr, index) => { return grpIndex !== index });

        /* filter selected students and schoolyears */
        students = this.state.multipleStudents.filter((student) => {
            for (let i = 0; i < groups.length; i++) {
                /* users is selected  */
                if (groups[i].students.indexOf(student.id) > -1) {
                    return false;
                }
            }
            /* user is not selected and schoolyear is not selected */
            if (this.state.groups[grpIndex].students.indexOf(student.id) === -1 && student.schoolyears && student.schoolyears.id !== this.state.schoolyearsId && this.state.schoolyearsId !== -1) {
                return false;
            }

            /* user is not selected and schoolyear is not selected */
            // if (this.state.execution.procedure
            return true;
        });
        return students;
    }

    createGroupButtons() {
        let items = [];
        for (let i = 0; i < this.state.groups.length; i = i + 2) {
            items.push(
                <Button className="me-2 mt-2 executionsgroupsmodal-button" color={this.state.groups[i].number === this.state.editGroup ? "danger" : "primary"} key={"btnGroup" + i} onClick={this.editGroup.bind(this, this.state.groups[i].number)}>
                    <FormattedMessage id="executiongroupmodal.button.group" />{this.state.groups[i].number}
                </Button>
            )
        }
        return items;
    }

    editGroup(number) {
        this.setState({ editGroup: number });
    }

    createGroups() {
        let items = [];
        let groups = this.state.groups;
        let prodecureTypeKompoG = this.props.execution && this.props.execution.procedure.id === 2 ? true : false;
        for (let index = 0; index < groups.length; index = index + 2) {
            if (this.state.editGroup === groups[index].number) {
                items.push(
                    <Container key={"g" + index} fluid className="border-top px-3">
                        {index % 2 === 0 &&
                            <Row key={"row1_" + index} className="">
                                <Col key={"col1_" + index} xs="12" md="6" className="p-1 d-flex align-items-center">
                                    {/* headline and delete  */}
                                    {this.props.execution && this.props.execution.groupseditable &&
                                        <Authorization id="executionsgroupmodal-delete">
                                            <Button color="link" label="executiongroupmodal.label.student" className="p-0 m-0 me-2 delete" onClick={this.deleteGroup.bind(this, index)} alt={this.props.intl.formatMessage({ id: 'executiongroupmodal.alt.deletegroup' })}></Button>
                                        </Authorization>
                                    }
                                    <h5 className="m-0">
                                        {this.props.intl.formatMessage({ id: prodecureTypeKompoG ? 'executiongroupmodal.kompoG.label.groups' : 'executiongroupmodal.label.groups' })} {groups[index].number}
                                    </h5>
                                </Col>
                                <Col key={"col2_" + index} xs="12" md="6" className="p-1 d-flex">
                                    <Label for="room" className="d-block my-auto">
                                        <FormattedMessage id="executiongroupmodal.label.room" />
                                    </Label>
                                    <Input className="ml-1" name="room" bsSize="sm" type="text" value={groups[index].room} onChange={this.handleRoom.bind(this, index)} disabled={!(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable)} />
                                </Col>
                            </Row>
                        }
                        <Row key={"row2_" + index}>
                            {/* part group 1 */}
                            <Col key={"col1_" + index} className="" xs="12" sm="12" md="6">
                                <Row>
                                    <Col xs="12" className="border-top p-1">
                                        <Label for="selectobserver" >
                                            <FormattedMessage id={prodecureTypeKompoG ? "executiongroupmodal.label.observer.kompoG" : "executiongroupmodal.label.observer"} />
                                        </Label>
                                        <Input bsSize="sm" type="select" name="selectobserver" value={groups[index].users_id} key={"group_observer_" + groups[index].id} id={"group" + groups[index].id} onChange={this.handleObserver.bind(this, index)} disabled={!(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable)}>
                                            {this.createObserversItems(index)}
                                        </Input>
                                    </Col>
                                    <Col xs="12" className="border-top p-1 d-flex justify-content-start">

                                        <Label for="feedbackroom" className="d-block my-auto">
                                            <FormattedMessage id="executiongroupmodal.label.feedbackroom" />
                                        </Label>

                                        <Input className="ml-1 d-block" name="feedbackroom" bsSize="sm" type="text" value={groups[index].feedbackroom} onChange={this.handleFeedbackroom.bind(this, index)} disabled={!(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable)} />

                                    </Col>
                                    <Col xs="12" className="border-top p-1">
                                        <MultipleSelect className="p-0" label="executiongroupmodal.label.student" elements={this.createStudentsItems(index)} selectedElements={groups[index].students} index={index} setSelected={this.setSelectedStudents} disabled={!(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable)} />
                                    </Col>
                                </Row>
                            </Col>

                            {/* part group 2 */}
                            {groups[(index + 1)] &&
                                <Col key={"col2_" + (index + 1)} className="" xs="12" sm="12" md="6">
                                    <Row>
                                        <Col xs="12" className="border-top p-1 ">
                                            <Label for="selectobserver">
                                                <FormattedMessage id={prodecureTypeKompoG ? "executiongroupmodal.label.observer.kompoG" : "executiongroupmodal.label.observer"} />
                                            </Label>
                                            <Input bsSize="sm" type="select" name="selectobserver" value={groups[(index + 1)].users_id} key={"group_observer_" + groups[(index + 1)].id} id={"group" + groups[(index + 1)].id} onChange={this.handleObserver.bind(this, index + 1)} disabled={!(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable)}>
                                                {this.createObserversItems(index + 1)}
                                            </Input>
                                        </Col>
                                        <Col xs="12" className="border-top p-1 d-flex">
                                            <Label for="feedbackroom" className="d-block my-auto">
                                                <FormattedMessage id="executiongroupmodal.label.feedbackroom" />
                                            </Label>
                                            <Input className="ml-1" name="feedbackroom" bsSize="sm" type="text" value={groups[index + 1].feedbackroom} onChange={this.handleFeedbackroom.bind(this, index + 1)} disabled={!(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable)} />
                                        </Col>
                                        <Col xs="12" className="border-top p-1 px-0">
                                            <MultipleSelect className="p-0" label="executiongroupmodal.label.student" elements={this.createStudentsItems(index + 1)} selectedElements={groups[(index + 1)].students} index={index + 1} setSelected={this.setSelectedStudents} disabled={!(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable)} />
                                        </Col>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </Container>
                )

            }
        }
        return items;

    }

    newGroup = async (e) => {
        let groups = this.state.groups;
        let newnumber = Math.floor(groups.length / 2) + 1;
        groups.push({ id: -1, users_id: 0, students: [], number: newnumber, room: '', feedbackroom: '' });
        groups.push({ id: -1, users_id: 0, students: [], number: newnumber, room: '', feedbackroom: '' });
        this.setState({ groups: groups, editGroup: newnumber });
    }


    deleteGroup = async (groups_index, e) => {
        let groups = this.state.groups;
        await groups.splice(groups_index, 2);
        let number = 1;
        for (let i = 0; i < groups.length; i = i + 2) {
            groups[i].number = number;
            groups[(i + 1)].number = number;
            number++;
        }

        await this.setState({ groups: groups });
    }

    handleRoom = async (group_index, e) => {
        let groups = this.state.groups;
        groups[group_index].room = e.target.value;
        groups[group_index + 1].room = e.target.value;

        await this.setState({ groups: groups });
    }

    handleFeedbackroom = async (group_index, e) => {
        let groups = this.state.groups;
        groups[group_index].feedbackroom = e.target.value;

        await this.setState({ groups: groups });
    }

    handleObserver = async (group_index, e) => {
        let groups = this.state.groups;
        groups[group_index].users_id = parseInt(e.target.value, 10);

        await this.setState({ groups: groups });
    }

    handleStudent = async (group_index, e) => {
        let groups = this.state.groups;
        let id = parseInt(e.target.value, 10);

        groups[group_index].students.indexOf(id) >= 0 ? groups[group_index].students.splice(groups[group_index].students.indexOf(id), 1) : groups[group_index].students.push(id);

        await this.setState({ groups: groups });
    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            if (this.props.execution && !isEquivalent(this.state.groups_db, this.state.groups)) {
                this.toggleConfirm();
            } else {
                this.props.toggle();
            }
        } else if (type === "save") {
            this.save();
        }
    }

    handleSchoolyears = async (schoolyear) => {
        this.setState({ schoolyearsId: schoolyear.id });
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    save = (e) => {
        this.props.save(this.props.execution, this.state.groups);
        this.props.toggle();
    }

    setSelectedStudents = (selected, index) => {
        let groups = this.state.groups;
        groups[index].students = selected;
        this.setState({ groups: groups });
    }

    handlePDF = async () => {

        generateExecutionsGroupsPDF(this.props, this.state);

        if (CheckAuthorization('executionsgroupmodal-edit', this.props.auth.roles)) {
            this.props.save(this.props.execution, this.state.groups);
        }
    }

    render() {
        let startdatetime = this.state.execution.start ? moment(this.state.execution.start.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let enddatetime = this.state.execution.end ? moment(this.state.execution.end.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let prodecureTypeKompoG = this.props.execution && this.props.execution.procedure.id === 2 ? true : false;

        return (
            <Container>
                <Confirm cancel save={this.save} dontsave={this.props.toggle} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="confirm.body.save" />
                <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} className="modal-height-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                    <ModalHeader><FormattedMessage id={CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution
                        && this.props.execution.groupseditable ?
                        (prodecureTypeKompoG ? "executiongroupmodal.kompoG.titel.groupsedit" : "executiongroupmodal.titel.groupsedit")
                        : (prodecureTypeKompoG ? "executiongroupmodal.kompoG.titel.groups" : "executiongroupmodal.titel.groups")} /></ModalHeader>

                    <ModalBody id="executionsgroupsmodal">
                        <Container className="px-3">
                            <Row>
                                <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                    <FormattedMessage id="executiongroupmodal.label.school" />
                                </Col>
                                <Col className="p-1" xs="6" sm="6" md="9">
                                    <DisplaySchool school={this.state.execution.school} name="eg" />
                                </Col>
                            </Row>
                            {(this.state.execution.procedure) &&
                                <Row>
                                    <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                        <FormattedMessage id="executiongroupmodal.label.procedure" />
                                    </Col>
                                    <Col className="p-1" xs="6" sm="6" md="9">
                                        <FormattedMessage id={this.state.execution.procedure.name} />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                    <FormattedMessage id="executiongroupmodal.label.startdate" />
                                </Col>
                                <Col className="p-1" xs="6" sm="6" md="3">
                                    <FormattedDate value={startdatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={startdatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                </Col>
                                <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                    <FormattedMessage id="executiongroupmodal.label.enddate" />
                                </Col>
                                <Col className="p-1" xs="6" sm="6" md="3">
                                    <FormattedDate value={enddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={enddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                </Col>
                            </Row>
                            {/* schoolyears */}
                            <Authorization id="executionsgroupmodal-schoolyears">
                                <Row>
                                    <Col xs="12" md="4" className="border-top p-1 d-flex">
                                        <Label for="schoolyears" className="d-block my-auto">
                                            <FormattedMessage id="executiongroupmodal.label.schoolyears" />
                                        </Label>
                                    </Col>
                                    <Col xs="12" md="8" className="border-top p-1">
                                        <SchoolyearsSelect className="m-0 p-0" name="schoolyears" withEmpty schoolyears={this.props.schoolyears} schoolyearsId={this.state.schoolyearsId} handleSchoolyears={this.handleSchoolyears} />
                                    </Col>
                                </Row>
                            </Authorization>
                        </Container>



                        {this.props.execution &&

                            <Container className="border-top pt-1 pb-3 px-3">
                                <Row>
                                    <Col xs="12" id="executionsgroupsmodal-button" className="p-0">
                                        {this.createGroupButtons()}
                                        <Authorization id="executionsgroupmodal-new" >
                                            {this.props.execution.groupseditable && <Button className="mt-2" color="primary" onClick={this.newGroup}>{this.state.groups && this.state.groups.length === 0 ?
                                                <FormattedMessage id={prodecureTypeKompoG ? "executiongroupmodal.kompoG.button.newgroup" : "executiongroupmodal.button.newgroup"} /> : <span className="">+</span>}</Button>}
                                        </Authorization>
                                    </Col>
                                </Row>
                            </Container>

                        }
                        {this.props.loadingGroups ? <Loading /> : this.createGroups()}
                    </ModalBody>
                    <ModalFooter>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="w-100">
                            {/* Generate PDF */}
                            <GeneratePDF className="p-0 m-0 me-1" handlePDF={this.handlePDF.bind(this)} />
                            <div style={{ marginLeft: 'auto' }}>
                                {/* Cancel */}
                                <Button type="submit" color="primary" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id={CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) && this.props.execution && this.props.execution.groupseditable ? "button.cancel" : "button.close"} /></Button>

                                {/* SAVE */}
                                {this.props.execution && this.props.execution.groupseditable &&
                                    <Authorization id="executionsgroupmodal-edit" >
                                        <Button className="ms-1" type="submit" color="primary" onClick={this.handleSubmit.bind(this, "save")}><FormattedMessage id="button.save" /></Button>
                                    </Authorization>
                                }
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            </Container >
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        observers: state.executions.observers,
        groups: state.executions.groups,
        loadingGroups: state.executions.loading,
        students: state.executions.students,
        schoolyears: state.schools.schoolyears,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetObservers: (token, executionId) => {
            dispatch(executionsActions.getObservers(token, executionId));
        },
        dispatchGetGroups: (token, executionId) => {
            dispatch(executionsActions.getGroups(token, executionId));
        },
        dispatchGetStudents: (token, executionId, procedureId) => {
            dispatch(executionsActions.getStudents(token, executionId, procedureId));
        },
        dispatchGetSchoolyears: (token) => {
            dispatch(schoolsActions.getSchoolyears(token, "start", false));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionsGroupsModal));