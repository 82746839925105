export const actionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    SEND_RESET_PWD_MAIL_REQUEST: 'SEND_RESET_PWD_MAIL_REQUEST',
    SEND_RESET_PWD_MAIL_SUCCESS: 'SEND_RESET_PWD_MAIL_SUCCESS',
    SEND_RESET_PWD_MAIL_FAILURE: 'SEND_RESET_PWD_MAIL_FAILURE',
    PRIVACYPOLICY_REQUEST: 'PRIVACYPOLICY_REQUEST',
    PRIVACYPOLICY_SUCCESS: 'PRIVACYPOLICY_SUCCESS',
    PRIVACYPOLICY_FAILURE: 'PRIVACYPOLICY_FAILURE',

    FILTER_INQUIRY_REJECTED: 'FILTER_INQUIRY_REJECTED',
    FILTER_INQUIRY_PAST: 'FILTER_INQUIRY_PAST',
    FILTER_EXECUTIONS_PAST: 'FILTER_EXECUTIONS_PAST',
    FILTER_EXECUTIONS_COOPERATION: 'FILTER_EXECUTIONS_COOPERATION',
    FILTER_EXECUTION: 'FILTER_EXECUTION',
    FILTER_SCHOOL: 'FILTER_SCHOOL',
    FILTER_STUDENT: 'FILTER_STUDENT',
    FILTER_DEPARTMENT: 'FILTER_DEPARTMENT',
    FILTER_SCHOOLTYPE: 'FILTER_SCHOOLTYPE',
    FILTER_SCHOOLYEAR: 'FILTER_SCHOOLYEAR',
    FILTER_OBSERVATIONSEVALUATIONS: 'FILTER_OBSERVATIONSEVALUATIONS',
    FILTER_ROLE: 'FILTER_ROLE',
    FILTER_HONORARKRAFT: 'FILTER_HONORARKRAFT',
    FILTER_CERTIFIED: 'FILTER_CERTIFIED',
    FILTER_PROCEDURE: 'FILTER_PROCEDURE',
    FILTER_PROCEDURE_INTERN: 'FILTER_PROCEDURE_INTERN',

    EXECUTIONS_OF_SCHOOL_REQUEST: 'EXECUTIONS_OF_SCHOOL_REQUEST',
    EXECUTIONS_OF_SCHOOL_SUCCESS: 'EXECUTIONS_OF_SCHOOL_SUCCESS',
    EXECUTIONS_OF_SCHOOL_FAILURE: 'EXECUTIONS_OF_SCHOOL_FAILURE',

    EXECUTIONS_REQUEST: 'EXECUTIONS_REQUEST',
    EXECUTIONS_SUCCESS: 'EXECUTIONS_SUCCESS',
    EXECUTIONS_FAILURE: 'EXECUTIONS_FAILURE',

    EXECUTIONS_STATUS_REQUEST: 'EXECUTIONS_STATUS_REQUEST',
    EXECUTIONS_STATUS_SUCCESS: 'EXECUTIONS_STATUS_SUCCESS',
    EXECUTIONS_STATUS_FAILURE: 'EXECUTIONS_STATUS_FAILURE',

    EXECUTIONS_COOPTYPE_REQUEST: 'EXECUTIONS_COOPTYPE_REQUEST',
    EXECUTIONS_COOPTYPE_SUCCESS: 'EXECUTIONS_COOPTYPE_SUCCESS',
    EXECUTIONS_COOPTYPE_FAILURE: 'EXECUTIONS_COOPTYPE_FAILURE',

    EXECUTIONS_OBSERVERS_REQUEST: 'EXECUTIONS_OBSERVERS_REQUEST',
    EXECUTIONS_OBSERVERS_SUCCESS: 'EXECUTIONS_OBSERVERS_SUCCESS',
    EXECUTIONS_OBSERVERS_FAILURE: 'EXECUTIONS_OBSERVERS_FAILURE',

    EXECUTIONS_GROUPS_REQUEST: 'EXECUTIONS_GROUPS_REQUEST',
    EXECUTIONS_GROUPS_SUCCESS: 'EXECUTIONS_GROUPS_SUCCESS',
    EXECUTIONS_GROUPS_FAILURE: 'EXECUTIONS_GROUPS_FAILURE',

    EXECUTIONS_CREATE_GROUPS_REQUEST: 'EXECUTIONS_CREATE_GROUPS_REQUEST',
    EXECUTIONS_CREATE_GROUPS_SUCCESS: 'EXECUTIONS_CREATE_GROUPS_SUCCESS',
    EXECUTIONS_CREATE_GROUPS_FAILURE: 'EXECUTIONS_CREATE_GROUPS_FAILURE',

    EXECUTIONS_STUDENTS_REQUEST: 'EXECUTIONS_STUDENTS_REQUEST',
    EXECUTIONS_STUDENTS_SUCCESS: 'EXECUTIONS_STUDENTS_SUCCESS',
    EXECUTIONS_STUDENTS_FAILURE: 'EXECUTIONS_STUDENTS_FAILURE',

    EXECUTIONS_STUDENTS_FOR_USER_REQUEST: 'EXECUTIONS_STUDENTS_FOR_USER_REQUEST',
    EXECUTIONS_STUDENTS_FOR_USER_SUCCESS: 'EXECUTIONS_STUDENTS_FOR_USER_SUCCESS',
    EXECUTIONS_STUDENTS_FOR_USER_FAILURE: 'EXECUTIONS_STUDENTS_FOR_USER_FAILURE',

    EXECUTIONS_CREATE_EXECUTION_REQUEST: 'EXECUTIONS_CREATE_EXECUTION_REQUEST',
    EXECUTIONS_CREATE_EXECUTION_SUCCESS: 'EXECUTIONS_CREATE_EXECUTION_SUCCESS',
    EXECUTIONS_CREATE_EXECUTION_FAILURE: 'EXECUTIONS_CREATE_EXECUTION_FAILURE',

    EXECUTIONS_UPDATE_EXECUTION_REQUEST: 'EXECUTIONS_UPDATE_EXECUTION_REQUEST',
    EXECUTIONS_UPDATE_EXECUTION_SUCCESS: 'EXECUTIONS_UPDATE_EXECUTION_SUCCESS',
    EXECUTIONS_UPDATE_EXECUTION_FAILURE: 'EXECUTIONS_UPDATE_EXECUTION_FAILURE',

    EXECUTIONS_DELETE_EXECUTION_REQUEST: 'EXECUTIONS_DELETE_EXECUTION_REQUEST',
    EXECUTIONS_DELETE_EXECUTION_SUCCESS: 'EXECUTIONS_DELETE_EXECUTION_SUCCESS',
    EXECUTIONS_DELETE_EXECUTION_FAILURE: 'EXECUTIONS_DELETE_EXECUTION_FAILURE',

    EXECUTIONS_HONORARKRAFT_REQUEST: 'EXECUTIONS_HONORARKRAFT_REQUEST',
    EXECUTIONS_HONORARKRAFT_SUCCESS: 'EXECUTIONS_HONORARKRAFT_SUCCESS',
    EXECUTIONS_HONORARKRAFT_FAILURE: 'EXECUTIONS_HONORARKRAFT_FAILURE',

    EXECUTIONS_FOREIGNUSERS_REQUEST: 'EXECUTIONS_FOREIGNUSERS_REQUEST',
    EXECUTIONS_FOREIGNUSERS_SUCCESS: 'EXECUTIONS_FOREIGNUSERS_SUCCESS',
    EXECUTIONS_FOREIGNUSERS_FAILURE: 'EXECUTIONS_FOREIGNUSERS_FAILURE',

    EXECUTIONS_RESULTS_REQUEST: 'EXECUTIONS_RESULTS_REQUEST',
    EXECUTIONS_RESULTS_SUCCESS: 'EXECUTIONS_RESULTS_SUCCESS',
    EXECUTIONS_RESULTS_FAILURE: 'EXECUTIONS_RESULTS_FAILURE',

    COMPETENCES_REQUEST: 'COMPETENCES_REQUEST',
    COMPETENCES_SUCCESS: 'COMPETENCES_SUCCESS',
    COMPETENCES_FAILURE: 'COMPETENCES_FAILURE',
    COMPETENCES_KOMPOG_REQUEST: 'COMPETENCES_KOMPOG_REQUEST',
    COMPETENCES_KOMPOG_SUCCESS: 'COMPETENCES_KOMPOG_SUCCESS',
    COMPETENCES_KOMPOG_FAILURE: 'COMPETENCES_KOMPOG_FAILURE',
    COMPETENCES_STUDENT_OBSERVER_REQUEST: 'COMPETENCES_STUDENT_OBSERVER_REQUEST',
    COMPETENCES_STUDENT_OBSERVER_SUCCESS: 'COMPETENCES_STUDENT_OBSERVER_SUCCESS',
    COMPETENCES_STUDENT_OBSERVER_FAILURE: 'COMPETENCES_STUDENT_OBSERVER_FAILURE',
    COMPETENCES_STUDENT_STUDENT_OBSERVER_REQUEST: 'COMPETENCES_STUDENT_STUDENT_OBSERVER_REQUEST',
    COMPETENCES_STUDENT_STUDENT_OBSERVER_SUCCESS: 'COMPETENCES_STUDENT_STUDENT_OBSERVER_SUCCESS',
    COMPETENCES_STUDENT_STUDENT_OBSERVER_FAILURE: 'COMPETENCES_STUDENT_STUDENT_OBSERVER_FAILURE',
    COMPETENCES_CREATE_USERS_REQUEST: 'COMPETENCES_CREATE_USERS_REQUEST',
    COMPETENCES_CREATE_USERS_SUCCESS: 'COMPETENCES_CREATE_USERS_SUCCESS',
    COMPETENCES_CREATE_USERS_FAILURE: 'COMPETENCES_CREATE_USERS_FAILURE',
    COMPETENCES_CREATE_USERS_KOMPOG_REQUEST: 'COMPETENCES_CREATE_USERS_KOMPOG_REQUEST',
    COMPETENCES_CREATE_USERS_KOMPOG_SUCCESS: 'COMPETENCES_CREATE_USERS_KOMPOG_SUCCESS',
    COMPETENCES_CREATE_USERS_KOMPOG_FAILURE: 'COMPETENCES_CREATE_USERS_KOMPOG_FAILURE',
    COMPETENCES_CHECK_OUT_REQUEST: 'COMPETENCES_CHECK_OUT_REQUEST',
    COMPETENCES_CHECK_OUT_SUCCESS: 'COMPETENCES_CHECK_OUT_SUCCESS',
    COMPETENCES_CHECK_OUT_FAILURE: 'COMPETENCES_CHECK_OUT_FAILURE',    
    COMPETENCES_CHECK_IN_REQUEST: 'COMPETENCES_CHECK_IN_REQUEST',
    COMPETENCES_CHECK_IN_SUCCESS: 'COMPETENCES_CHECK_IN_SUCCESS',
    COMPETENCES_CHECK_IN_FAILURE: 'COMPETENCES_CHECK_In_FAILURE',    

    OBSERVATIONS_REQUEST: 'OBSERVATIONS_REQUEST',
    OBSERVATIONS_SUCCESS: 'OBSERVATIONS_SUCCESS',
    OBSERVATIONS_FAILURE: 'OBSERVATIONS_FAILURE',
    OBSERVATIONS_CREATE_USERS_REQUEST: 'OBSERVATIONS_CREATE_USERS_REQUEST',
    OBSERVATIONS_CREATE_USERS_SUCCESS: 'OBSERVATIONS_CREATE_USERS_SUCCESS',
    OBSERVATIONS_CREATE_USERS_FAILURE: 'OBSERVATIONS_CREATE_USERS_FAILURE',
    OBSERVATIONS_UPDATE_USERS_REQUEST: 'OBSERVATIONS_UPDATE_USERS_REQUEST',
    OBSERVATIONS_UPDATE_USERS_SUCCESS: 'OBSERVATIONS_UPDATE_USERS_SUCCESS',
    OBSERVATIONS_UPDATE_USERS_FAILURE: 'OBSERVATIONS_UPDATE_USERS_FAILURE',

    SCHOOLS_CREATE_SCHOOL_REQUEST: 'SCHOOLS_CREATE_SCHOOL_REQUEST',
    SCHOOLS_CREATE_SCHOOL_SUCCESS: 'SCHOOLS_CREATE_SCHOOL_SUCCESS',
    SCHOOLS_CREATE_SCHOOL_FAILURE: 'SCHOOLS_CREATE_SCHOOL_FAILURE',
    SCHOOLS_UPDATE_SCHOOL_REQUEST: 'SCHOOLS_UPDATE_SCHOOL_REQUEST',
    SCHOOLS_UPDATE_SCHOOL_SUCCESS: 'SCHOOLS_UPDATE_SCHOOL_SUCCESS',
    SCHOOLS_UPDATE_SCHOOL_FAILURE: 'SCHOOLS_UPDATE_SCHOOL_FAILURE',
    SCHOOLS_DELETE_SCHOOL_REQUEST: 'SCHOOLS_DELETE_SCHOOL_REQUEST',
    SCHOOLS_DELETE_SCHOOL_SUCCESS: 'SCHOOLS_DELETE_SCHOOL_SUCCESS',
    SCHOOLS_DELETE_SCHOOL_FAILURE: 'SCHOOLS_DELETE_SCHOOL_FAILURE',
    SCHOOLS_REQUEST: 'SCHOOLS_REQUEST',
    SCHOOLS_SUCCESS: 'SCHOOLS_SUCCESS',
    SCHOOLS_FAILURE: 'SCHOOLS_FAILURE',
    SCHOOLS_FOR_USER_REQUEST: 'SCHOOLS_FOR_USER_REQUEST',
    SCHOOLS_FOR_USER_SUCCESS: 'SCHOOLS_FOR_USER_SUCCESS',
    SCHOOLS_FOR_USER_FAILURE: 'SCHOOLS_FOR_USER_FAILURE',
    SCHOOLS_CONTACTPERSON_REQUEST: 'SCHOOLS_CONTACTPERSON_REQUEST',
    SCHOOLS_CONTACTPERSON_SUCCESS: 'SCHOOLS_CONTACTPERSON_SUCCESS',
    SCHOOLS_CONTACTPERSON_FAILURE: 'SCHOOLS_CONTACTPERSON_FAILURE',
    SCHOOLS_TEACHER_REQUEST: 'SCHOOLS_TEACHER_REQUEST',
    SCHOOLS_TEACHER_SUCCESS: 'SCHOOLS_TEACHER_SUCCESS',
    SCHOOLS_TEACHER_FAILURE: 'SCHOOLS_TEACHER_FAILURE',
    SCHOOLS_STUDENTS_REQUEST: 'SCHOOLS_STUDENTS_REQUEST',
    SCHOOLS_STUDENTS_SUCCESS: 'SCHOOLS_STUDENTS_SUCCESS',
    SCHOOLS_STUDENTS_FAILURE: 'SCHOOLS_STUDENTS_FAILURE',
    SCHOOLS_YEARS_REQUEST: 'SCHOOLS_YEARS_REQUEST',
    SCHOOLS_YEARS_SUCCESS: 'SCHOOLS_YEARS_SUCCESS',
    SCHOOLS_YEARS_FAILURE: 'SCHOOLS_YEARS_FAILURE',
    SCHOOLS_TYPES_REQUEST: 'SCHOOLS_TYPES_REQUEST',
    SCHOOLS_TYPES_SUCCESS: 'SCHOOLS_TYPES_SUCCESS',
    SCHOOLS_TYPES_FAILURE: 'SCHOOLS_TYPES_FAILURE',
    SCHOOLS_SETTINGS_REQUEST: 'SCHOOLS_SETTINGS_REQUEST',
    SCHOOLS_SETTINGS_SUCCESS: 'SCHOOLS_SETTINGS_SUCCESS',
    SCHOOLS_SETTINGS_FAILURE: 'SCHOOLS_SETTINGS_FAILURE',
    SCHOOLS_SETTINGS_SAVE_SETTING_REQUEST: 'SCHOOLS_SETTINGS_SAVE_SETTING_REQUEST',
    SCHOOLS_SETTINGS_SAVE_SETTING_SUCCESS: 'SCHOOLS_SETTINGS_SAVE_SETTING_SUCCESS',
    SCHOOLS_SETTINGS_SAVE_SETTING_FAILURE: 'SCHOOLS_SETTINGS_SAVE_SETTING_FAILURE',

    SETTINGS_REQUEST: 'SETTINGS_REQUEST',
    SETTINGS_SUCCESS: 'SETTINGS_SUCCESS',
    SETTINGS_FAILURE: 'SETTINGS_FAILURE',
    SETTINGS_SAVE_SETTING_REQUEST: 'SETTINGS_SAVE_SETTING_REQUEST',
    SETTINGS_SAVE_SETTING_SUCCESS: 'SETTINGS_SAVE_SETTING_SUCCESS',
    SETTINGS_SAVE_SETTING_FAILURE: 'SETTINGS_SAVE_SETTING_FAILURE',
    SETTINGS_SAVE_PASSWORD_REQUEST: 'SETTINGS_SAVE_PASSWORD_REQUEST',
    SETTINGS_SAVE_PASSWORD_SUCCESS: 'SETTINGS_SAVE_PASSWORD_SUCCESS',
    SETTINGS_SAVE_PASSWORD_FAILURE: 'SETTINGS_SAVE_PASSWORD_FAILURE',

    USERPROFILE_CREATE_USER_REQUEST: 'USERPROFILE_CREATE_USER_REQUEST',
    USERPROFILE_CREATE_USER_SUCCESS: 'USERPROFILE_CREATE_USER_SUCCESS',
    USERPROFILE_CREATE_USER_FAILURE: 'USERPROFILE_CREATE_USER_FAILURE',
    USERPROFILE_UPDATE_USER_REQUEST: 'USERPROFILE_UPDATE_USER_REQUEST',
    USERPROFILE_UPDATE_USER_SUCCESS: 'USERPROFILE_UPDATE_USER_SUCCESS',
    USERPROFILE_UPDATE_USER_FAILURE: 'USERPROFILE_UPDATE_USER_FAILURE',
    USERPROFILE_DELETE_USER_REQUEST: 'USERPROFILE_DELETE_USER_REQUEST',
    USERPROFILE_DELETE_USER_SUCCESS: 'USERPROFILE_DELETE_USER_SUCCESS',
    USERPROFILE_DELETE_USER_FAILURE: 'USERPROFILE_DELETE_USER_FAILURE',
    USERPROFILE_RESET_USER_REQUEST: 'USERPROFILE_RESET_USER_REQUEST',
    USERPROFILE_RESET_USER_SUCCESS: 'USERPROFILE_RESET_USER_SUCCESS',
    USERPROFILE_RESET_USER_FAILURE: 'USERPROFILE_RESET_USER_FAILURE',
    USERPROFILE_USERSADDRESS_REQUEST: 'USERPROFILE_USERSADDRESS_REQUEST',
    USERPROFILE_USERSADDRESS_SUCCESS: 'USERPROFILE_USERSADDRESS_SUCCESS',
    USERPROFILE_USERSADDRESS_FAILURE: 'USERPROFILE_USERSADDRESS_FAILURE',
    USERPROFILE_USERSTITLE_REQUEST: 'USERPROFILE_USERSTITLE_REQUEST',
    USERPROFILE_USERSTITLE_SUCCESS: 'USERPROFILE_USERSTITLE_SUCCESS',
    USERPROFILE_USERSTITLE_FAILURE: 'USERPROFILE_USERSTITLE_FAILURE',
    USERPROFILE_ROLES_REQUEST: 'USERPROFILE_ROLES_REQUEST',
    USERPROFILE_ROLES_SUCCESS: 'USERPROFILE_ROLES_SUCCESS',
    USERPROFILE_ROLES_FAILURE: 'USERPROFILE_ROLES_FAILURE',
    USERPROFILE_USERSFUNCTIONS_REQUEST: 'USERPROFILE_USERSFUNCTIONS_REQUEST',
    USERPROFILE_USERSFUNCTIONS_SUCCESS: 'USERPROFILE_USERSFUNCTIONS_SUCCESS',
    USERPROFILE_USERSFUNCTIONS_FAILURE: 'USERPROFILE_USERSFUNCTIONS_FAILURE',
    USERPROFILE_USERS_REQUEST: 'USERPROFILE_USERS_REQUEST',
    USERPROFILE_USERS_SUCCESS: 'USERPROFILE_USERS_SUCCESS',
    USERPROFILE_USERS_FAILURE: 'USERPROFILE_USERS_FAILURE',
    USERPROFILE_RESET_PWD_REQUEST: 'USERPROFILE_RESET_PWD_REQUEST',
    USERPROFILE_RESET_PWD_SUCCESS: 'USERPROFILE_RESET_PWD_SUCCESS',
    USERPROFILE_RESET_PWD_FAILURE: 'USERPROFILE_RESET_PWD_FAILURE',
    USERPROFILE_RESET_PWD_PDF_REQUEST: 'USERPROFILE_RESET_PWD_PDF_REQUEST',
    USERPROFILE_RESET_PWD_PDF_SUCCESS: 'USERPROFILE_RESET_PWD_PDF_SUCCESS',
    USERPROFILE_RESET_PWD_PDF_FAILURE: 'USERPROFILE_RESET_PWD_PDF_FAILURE',
    USERPROFILE_RESET_PWD_USER_PDF_REQUEST: 'USERPROFILE_RESET_PWD_USER_PDF_REQUEST',
    USERPROFILE_RESET_PWD_USER_PDF_SUCCESS: 'USERPROFILE_RESET_PWD_USER_PDF_SUCCESS',
    USERPROFILE_RESET_PWD_USER_PDF_FAILURE: 'USERPROFILE_RESET_PWD_USER_PDF_FAILURE',

    INQUIRY_OF_SCHOOL_REQUEST: 'INQUIRY_OF_SCHOOL_REQUEST',
    INQUIRY_OF_SCHOOL_SUCCESS: 'INQUIRY_OF_SCHOOL_SUCCESS',
    INQUIRY_OF_SCHOOL_FAILURE: 'INQUIRY_OF_SCHOOL_FAILURE',
    INQUIRY_OF_SCHOOL_AND_EXECUTION_REQUEST: 'INQUIRY_OF_SCHOOL_AND_EXECUTION_REQUEST',
    INQUIRY_OF_SCHOOL_AND_EXECUTION_SUCCESS: 'INQUIRY_OF_SCHOOL_AND_EXECUTION_SUCCESS',
    INQUIRY_OF_SCHOOL_AND_EXECUTION_FAILURE: 'INQUIRY_OF_SCHOOL_AND_EXECUTION_FAILURE',
    INQUIRY_CREATE_REQUEST: 'INQUIRY_CREATE_REQUEST',
    INQUIRY_CREATE_SUCCESS: 'INQUIRY_CREATE_SUCCESS',
    INQUIRY_CREATE_FAILURE: 'INQUIRY_CREATE_FAILURE',
    INQUIRY_UPDATE_REQUEST: 'INQUIRY_UPDATE_REQUEST',
    INQUIRY_UPDATE_SUCCESS: 'INQUIRY_UPDATE_SUCCESS',
    INQUIRY_UPDATE_FAILURE: 'INQUIRY_UPDATE_FAILURE',
    INQUIRY_SEND_OF_SCHOOL_REQUEST: 'INQUIRY_SEND_OF_SCHOOL_REQUEST',
    INQUIRY_SEND_OF_SCHOOL_SUCCESS: 'INQUIRY_SEND_OF_SCHOOL_SUCCESS',
    INQUIRY_SEND_OF_SCHOOL_FAILURE: 'INQUIRY_SEND_OF_SCHOOL_FAILURE',
    INQUIRY_COUNT_OPEN_REQUEST: 'INQUIRY_COUNT_OPEN_REQUEST',
    INQUIRY_COUNT_OPEN_SUCCESS: 'INQUIRY_COUNT_OPEN_SUCCESS',
    INQUIRY_COUNT_OPEN_FAILURE: 'INQUIRY_COUNT_OPEN_FAILURE',

    DEPARTMENTS_REQUEST: 'DEPARTMENTS_REQUEST',
    DEPARTMENTS_SUCCESS: 'DEPARTMENTS_SUCCESS',
    DEPARTMENTS_FAILURE: 'DEPARTMENTS_FAILURE',

    SKILLSPROFILE_REQUEST: 'SKILLSPROFILE_REQUEST',
    SKILLSPROFILE_SUCCESS: 'SKILLSPROFILE_SUCCESS',
    SKILLSPROFILE_FAILURE: 'SKILLSPROFILE_FAILURE',
    SKILLSPROFILE_STUDENT_REQUEST: 'SKILLSPROFILE_STUDENT_REQUEST',
    SKILLSPROFILE_STUDENT_SUCCESS: 'SKILLSPROFILE_STUDENT_SUCCESS',
    SKILLSPROFILE_STUDENT_FAILURE: 'SKILLSPROFILE_STUDENT_FAILURE',
    SKILLSPROFILE_UPDATE_REQUEST: 'SKILLSPROFILE_UPDATE_REQUEST',
    SKILLSPROFILE_UPDATE_SUCCESS: 'SKILLSPROFILE_UPDATE_SUCCESS',
    SKILLSPROFILE_UPDATE_FAILURE: 'SKILLSPROFILE_UPDATE_FAILURE',
    SKILLSPROFILE_CREATE_REQUEST: 'SKILLSPROFILE_CREATE_REQUEST',
    SKILLSPROFILE_CREATE_SUCCESS: 'SKILLSPROFILE_CREATE_SUCCESS',
    SKILLSPROFILE_CREATE_FAILURE: 'SKILLSPROFILE_CREATE_FAILURE',
    SKILLSPROFILE_APPROVED_REQUEST: 'SKILLSPROFILE_APPROVED_REQUEST',
    SKILLSPROFILE_APPROVED_SUCCESS: 'SKILLSPROFILE_APPROVED_SUCCESS',
    SKILLSPROFILE_APPROVED_FAILURE: 'SKILLSPROFILE_APPROVED_FAILURE',

    KOMPO7RATING_REQUEST: 'KOMPO7RATING_REQUEST',
    KOMPO7RATING_SUCCESS: 'KOMPO7RATING_SUCCESS',
    KOMPO7RATING_FAILURE: 'KOMPO7RATING_FAILURE',
    KOMPO7RATING_CREATE_REQUEST: 'KOMPO7RATING_CREATE_REQUEST',
    KOMPO7RATING_CREATE_SUCCESS: 'KOMPO7RATING_CREATE_SUCCESS',
    KOMPO7RATING_CREATE_FAILURE: 'KOMPO7RATING_CREATE_FAILURE',
    KOMPO7RATING_RESULTS_REQUEST: 'KOMPO7RATING_RESULTS_REQUEST',
    KOMPO7RATING_RESULTS_SUCCESS: 'KOMPO7RATING_RESULTS_SUCCESS',
    KOMPO7RATING_RESULTS_FAILURE: 'KOMPO7RATING_RESULTS_FAILURE',

    DOCUMENTS_REQUEST: 'DOCUMENTS_REQUEST',
    DOCUMENTS_SUCCESS: 'DOCUMENTS_SUCCESS',
    DOCUMENTS_FAILURE: 'DOCUMENTS_FAILURE',
    DOCUMENTS_STRUCTUR_CREATE_REQUEST: 'DOCUMENTS_STRUCTUR_CREATE_REQUEST',
    DOCUMENTS_STRUCTUR_CREATE_SUCCESS: 'DOCUMENTS_STRUCTUR_CREATE_SUCCESS',
    DOCUMENTS_STRUCTUR_CREATE_FAILURE: 'DOCUMENTS_STRUCTUR_CREATE_FAILURE',
    DOCUMENTS_STRUCTUR_UPDATE_REQUEST: 'DOCUMENTS_STRUCTUR_UPDATE_REQUEST',
    DOCUMENTS_STRUCTUR_UPDATE_SUCCESS: 'DOCUMENTS_STRUCTUR_UPDATE_SUCCESS',
    DOCUMENTS_STRUCTUR_UPDATE_FAILURE: 'DOCUMENTS_STRUCTUR_UPDATE_FAILURE',
    DOCUMENTS_STRUCTUR_DELETE_REQUEST: 'DOCUMENTS_STRUCTUR_DELETE_REQUEST',
    DOCUMENTS_STRUCTUR_DELETE_SUCCESS: 'DOCUMENTS_STRUCTUR_DELETE_SUCCESS',
    DOCUMENTS_STRUCTUR_DELETE_FAILURE: 'DOCUMENTS_STRUCTUR_DELETE_FAILURE',
    DOCUMENTS_STRUCTUR_SORT_REQUEST: 'DOCUMENTS_STRUCTUR_SORT_REQUEST',
    DOCUMENTS_STRUCTUR_SORT_SUCCESS: 'DOCUMENTS_STRUCTUR_SORT_SUCCESS',
    DOCUMENTS_STRUCTUR_SORT_FAILURE: 'DOCUMENTS_STRUCTUR_SORT_FAILURE',
    DOCUMENTS_CREATE_REQUEST: 'DOCUMENTS_CREATE_REQUEST',
    DOCUMENTS_CREATE_SUCCESS: 'DOCUMENTS_CREATE_SUCCESS',
    DOCUMENTS_CREATE_FAILURE: 'DOCUMENTS_CREATE_FAILURE',
    DOCUMENTS_UPDATE_REQUEST: 'DOCUMENTS_UPDATE_REQUEST',
    DOCUMENTS_UPDATE_SUCCESS: 'DOCUMENTS_UPDATE_SUCCESS',
    DOCUMENTS_UPDATE_FAILURE: 'DOCUMENTS_UPDATE_FAILURE',
    DOCUMENTS_DELETE_REQUEST: 'DOCUMENTS_DELETE_REQUEST',
    DOCUMENTS_DELETE_SUCCESS: 'DOCUMENTS_DELETE_SUCCESS',
    DOCUMENTS_DELETE_FAILURE: 'DOCUMENTS_DELETE_FAILURE',
    DOCUMENTS_SORT_REQUEST: 'DOCUMENTS_SORT_REQUEST',
    DOCUMENTS_SORT_SUCCESS: 'DOCUMENTS_SORT_SUCCESS',
    DOCUMENTS_SORT_FAILURE: 'DOCUMENTS_SORT_FAILURE',
    DOCUMENTS_DOWNLOAD_REQUEST: 'DOCUMENTS_DOWNLOAD_REQUEST',
    DOCUMENTS_DOWNLOAD_SUCCESS: 'DOCUMENTS_DOWNLOAD_SUCCESS',
    DOCUMENTS_DOWNLOAD_FAILURE: 'DOCUMENTS_DOWNLOAD_FAILURE',
    
    STATISTIC_REQUEST: 'STATISTIC_REQUEST',
    STATISTIC_SUCCESS: 'STATISTIC_SUCCESS',
    STATISTIC_FAILURE: 'STATISTIC_FAILURE',
    DEPARTMENT_STATISTIC_REQUEST: 'DEPARTMENT_STATISTIC_REQUEST',
    DEPARTMENT_STATISTIC_SUCCESS: 'DEPARTMENT_STATISTIC_SUCCESS',
    DEPARTMENT_STATISTIC_FAILURE: 'DEPARTMENT_STATISTIC_FAILURE',

    INTERESTTEST_RESULTS_REQUEST: 'INTERESTTEST_RESULTS_REQUEST',
    INTERESTTEST_RESULTS_SUCCESS: 'INTERESTTEST_RESULTS_SUCCESS',
    INTERESTTEST_RESULTS_FAILURE: 'INTERESTTEST_RESULTS_FAILURE',

    MOTIVATIONS_REQUEST: 'MOTIVATIONS_REQUEST',
    MOTIVATIONS_SUCCESS: 'MOTIVATIONS_SUCCESS',
    MOTIVATIONS_FAILURE: 'MOTIVATIONS_FAILURE',

    MOTIVATIONS_SAVE_REQUEST: 'MOTIVATIONS_SAVE_REQUEST',
    MOTIVATIONS_SAVE_SUCCESS: 'MOTIVATIONS_SAVE_SUCCESS',
    MOTIVATIONS_SAVE_FAILURE: 'MOTIVATIONS_SAVE_FAILURE',

    MOTIVATIONSRESULT_SAVE_REQUEST: 'MOTIVATIONSRESULT_SAVE_REQUEST',
    MOTIVATIONSRESULT_SAVE_SUCCESS: 'MOTIVATIONSRESULT_SAVE_SUCCESS',
    MOTIVATIONSRESULT_SAVE_FAILURE: 'MOTIVATIONSRESULT_SAVE_FAILURE',

    VERBEXERCISE_REQUEST: 'VERBEXERCISE_REQUEST',
    VERBEXERCISE_SUCCESS: 'VERBEXERCISE_SUCCESS',
    VERBEXERCISE_FAILURE: 'VERBEXERCISE_FAILURE',

    VERBEXERCISE_SAVE_REQUEST: 'VERBEXERCISE_SAVE_REQUEST',
    VERBEXERCISE_SAVE_SUCCESS: 'VERBEXERCISE_SAVE_SUCCESS',
    VERBEXERCISE_SAVE_FAILURE: 'VERBEXERCISE_SAVE_FAILURE',

    VERBEXERCISE_DELETE_REQUEST: 'VERBEXERCISE_DELETE_REQUEST',
    VERBEXERCISE_DELETE_SUCCESS: 'VERBEXERCISE_DELETE_SUCCESS',
    VERBEXERCISE_DELETE_FAILURE: 'VERBEXERCISE_DELETE_FAILURE',

    VERBEXERCISERESULT_SAVE_REQUEST: 'VERBEXERCISERESULT_SAVE_REQUEST',
    VERBEXERCISERESULT_SAVE_SUCCESS: 'VERBEXERCISERESULT_SAVE_SUCCESS',
    VERBEXERCISERESULT_SAVE_FAILURE: 'VERBEXERCISERESULT_SAVE_FAILURE',

    KOMPOGRESULT_REQUEST: 'KOMPOGRESULT_REQUEST',
    KOMPOGRESULT_SUCCESS: 'KOMPOGRESULT_SUCCESS',
    KOMPOGRESULT_FAILURE: 'KOMPOGRESULT_FAILURE',

    KOMPOGRESULT_ALLPUPILS_REQUEST: 'KOMPOGRESULT_ALLPUPILS_REQUEST',
    KOMPOGRESULT_ALLPUPILS_SUCCESS: 'KOMPOGRESULT_ALLPUPILS_SUCCESS',
    KOMPOGRESULT_ALLPUPILS_FAILURE: 'KOMPOGRESULT_ALLPUPILS_FAILURE',

    KOMPOGRESULT_SAVE_REQUEST: 'KOMPOGRESULT_SAVE_REQUEST',
    KOMPOGRESULT_SAVE_SUCCESS: 'KOMPOGRESULT_SAVE_SUCCESS',
    KOMPOGRESULT_SAVE_FAILURE: 'KOMPOGRESULT_SAVE_FAILURE',

    KOMPOGRESULT_FEEDBACK_REQUEST: 'KOMPOGRESULT_FEEDBACK_REQUEST',
    KOMPOGRESULT_FEEDBACK_SUCCESS: 'KOMPOGRESULT_FEEDBACK_SUCCESS',
    KOMPOGRESULT_FEEDBACK_FAILURE: 'KOMPOGRESULT_FEEDBACK_FAILURE',
}