import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';
import config from '../config/config';
import moment from 'moment';
import { replaceDateTimeSpecialChars } from '../utils/Tools'

export const generateExecutionsGroupsPDF = (props, state) => {
    let documentname = props.intl.formatMessage({ id: "executiongroupmodal.print.filename" + (state.execution.procedure.id !== 1 ? ".kompoG" : "") }, state.translationsVars);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let content = [];
    let groups = state.groups;
    let table = [];
    for (let index = 0; index < groups.length; index++) {

        let observer = props.observers.filter((observer) => {
            let tmp = false;
            if (groups[index].users_id === observer.id) {
                tmp = true;
            }
            return tmp;
        });

        if (index % 2 === 0) {
            table = [];

            //tablehead              
            table.push(
                [
                    {
                        text: props.intl.formatMessage({ id: 'executiongroupmodal.print.group' + (state.execution.procedure.id !== 1 ? ".kompoG" : "") }) + " " + groups[index].number,
                        fontSize: 12,
                        bold: true,
                        color: '#FFFFFF',
                        fillColor: [67, 53, 140],
                    },
                    {
                        text: props.intl.formatMessage({ id: 'executiongroupmodal.print.room' }) + " " + (groups[index].room ? groups[index].room : "-"),
                        fontSize: 12,
                        color: '#FFFFFF',
                        fillColor: [67, 53, 140],
                        alignment: 'right',
                    }
                ]
            )

        }

        //empty row
        if (index % 2 === 1) {
            table.push([{
                colSpan: 2,
                margin: [0, 5, 0, 4],
                table: {
                    widths: ["*"],
                    body: [[""]]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0) ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                },

            }])
        }

        //tablebody
        table.push(
            [
                {
                    text: props.intl.formatMessage({ id: 'executiongroupmodal.print.observer' + (state.execution.procedure.id !== 1 ? ".kompoG" : "") }) + " " + (observer.length > 0 ? observer[0].firstname + " " + observer[0].lastname : "-"),
                    fontSize: 12,
                    bold: true,
                },
                {
                    text: props.intl.formatMessage({ id: 'executiongroupmodal.print.feedbackroom' }) + " " + (groups[index].feedbackroom ? groups[index].feedbackroom : "-"),
                    fontSize: 12,
                    alignment: 'right',
                },
            ]
        )

        if (state.multipleStudents) {
            for (let j = 0; j < state.multipleStudents.length; j++) {
                let student = state.multipleStudents[j];
                /* user is selected  */
                if (groups[index].students.indexOf(student.id) > -1) {
                    table.push(
                        [
                            {
                                text: student.text + "\n" + (student.school ? student.school.name : ""),
                                fontSize: 12,
                                margin: [0, 5, 0, 0]
                            },
                            {
                                text: " ",
                                fontSize: 12,
                                margin: [0, 5, 0, 0]
                            }
                        ]
                    )
                }
            }
        }


        if (index % 2 === 1) {
            content.push({
                table: {
                    dontBreakRows: true,
                    headerRows: 1,
                    widths: ["70%", "*"],
                    body: table
                },
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i) {
                        return 0.5;
                    },
                }
            })
        }

        //page break after each group without the last one
        if (index % 2 === 1 && index < groups.length - 1) {
            content.push({ text: "", pageBreak: 'after' })
        }

    }

    let startdatetime = state.execution.start ? moment(state.execution.start.replace(" ", "T"), config.dateFormat.format, true) : moment();
    let enddatetime = state.execution.end ? moment(state.execution.end.replace(" ", "T"), config.dateFormat.format, true) : moment();
    startdatetime = props.intl.formatDate(startdatetime, { weekday: config.dateFormat.weekday }) + "." + replaceDateTimeSpecialChars(props.intl.formatDate(startdatetime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }));
    enddatetime = props.intl.formatDate(enddatetime, { weekday: config.dateFormat.weekday }) + "." + replaceDateTimeSpecialChars(props.intl.formatDate(enddatetime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }));

    let tmpHeader = JSON.parse(JSON.stringify(Header));
    tmpHeader.push({
        //title
        text: props.intl.formatMessage({ id: "executiongroupmodal.print.title" + (state.execution.procedure.id !== 1 ? ".kompoG" : "") }),
        absolutePosition: { x: 40, y: 65 },
        fontSize: 20,
    });
    tmpHeader.push({
        //executions date
        text: props.intl.formatMessage({ id: "executiongroupmodal.print.date" }),
        absolutePosition: { x: 40, y: 100 },
        fontSize: 12,
        bold: true,
    });
    tmpHeader.push({
        //executions date
        text: startdatetime + " - " + enddatetime,
        absolutePosition: { x: 220, y: 100 },
        fontSize: 12,

    });
    tmpHeader.push({
        //school
        text: props.intl.formatMessage({ id: "executiongroupmodal.print.school" }),
        absolutePosition: { x: 40, y: 115 },
        fontSize: 12,
        bold: true
    });
    tmpHeader.push({
        //school
        text: state.execution.school.name + "\n" + state.execution.school.street + ", " + state.execution.school.place,
        absolutePosition: { x: 220, y: 115 },
        fontSize: 12,
    });

    tmpHeader.push({
        //procedure
        text: props.intl.formatMessage({ id: "executiongroupmodal.print.procedure" }),
        absolutePosition: { x: 40, y: 145 },
        fontSize: 12,
        bold: true
    });
    tmpHeader.push({
        //procedure
        text: state.execution.procedure.name,
        absolutePosition: { x: 220, y: 145 },
        fontSize: 12,
    });

    let docDefinition = {
        //595pt x 842pt
        //pageOrientation: 'landscape',
        pageSize: 'A4',
        pageMargins: [40, 165, 40, 65],
        images: Images,
        header: tmpHeader,
        footer: footer(props),
        content: content

    }
    pdfMake.createPdf(docDefinition).download(documentname);
}