import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Label, Input, } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { filterActions } from '../actions';

class ExecutionsCooperationCheckbox extends React.Component {


    static propTypes = {
        // redux dispatch
        dispatchCooperationExecutions: PropTypes.func.isRequired,
    };


    handleCooperation = async (e) => {
        this.props.dispatchCooperationExecutions();
    }

    render() {
        return (
            <Label for="cooperation" className="ms-4">
                <Input id="cooperation" name="cooperation" type="checkbox" defaultChecked={this.props.cooperation} value={this.props.cooperation} onChange={this.handleCooperation} />
                <FormattedMessage id="executioncooperationcheckbox.label.cooperation" />
            </Label>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        cooperation: state.filter.executions_cooperation
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchCooperationExecutions: () => {
            dispatch(filterActions.executions_cooperation());
        }
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionsCooperationCheckbox));