import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ENV from '../config/env';

import { loginActions } from '../actions';

class Logout extends React.Component {

    static propTypes = {
        // redux dispatch
        dispatchLogout: PropTypes.func.isRequired,
        className: PropTypes.string,
        tabIndex: PropTypes.string
    };

    handleLogout = (e) => {
        this.props.dispatchLogout();
    };

    render() {
        return (
            <a href={ENV.subpath + "/#"} onClick={this.handleLogout} name="logout" className={this.props.className} tabIndex={this.props.tabIndex}><FormattedMessage id="logout.button.logout" /></a>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchLogout: () => {
            dispatch(loginActions.logout());
        }
    };
};

export default injectIntl(connect(
    null,
    mapDispatchToProps
)(Logout));