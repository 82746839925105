import { actionTypes } from '../actions/types';

const initialState = {
    result: [],
    results: [],
    loading: false,
    error: '',
    saving: false,
    saveStatus: null
};

const kompoGResultReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.KOMPOGRESULT_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case actionTypes.KOMPOGRESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.result ? action.result : [],
                error: ''
            };
        case actionTypes.KOMPOGRESULT_FAILURE:
            return {
                ...state,
                loading: false,
                result: [],
                error: action.error
            };
        case actionTypes.KOMPOGRESULT_ALLPUPILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case actionTypes.KOMPOGRESULT_ALLPUPILS_SUCCESS:
            return {
                ...state,
                loading: false,
                results: action.results ? action.results : [],
                error: ''
            };
        case actionTypes.KOMPOGRESULT_ALLPUPILS_FAILURE:
            return {
                ...state,
                loading: false,
                results: [],
                error: action.error
            };
        case actionTypes.KOMPOGRESULT_SAVE_REQUEST:
            return {
                ...state,
                error: '',
                loading: true,
                saving: true,
                saveStatus: null
            };
        case actionTypes.KOMPOGRESULT_SAVE_SUCCESS:
            return {
                ...state,
                error: '',
                saveStatus: true,
                loading: false,
                saving: false
            };
        case actionTypes.KOMPOGRESULT_SAVE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                saveStatus: false,
                saving: false
            };
        case actionTypes.KOMPOGRESULT_FEEDBACK_REQUEST:
            return {
                ...state,
                error: '',
                loading: true,
                saving: true,
                saveStatus: null
            };
        case actionTypes.KOMPOGRESULT_FEEDBACK_SUCCESS:
            return {
                ...state,
                error: '',
                saveStatus: true,
                loading: false,
                saving: false
            };
        case actionTypes.KOMPOGRESULT_FEEDBACK_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                saveStatus: false,
                saving: false
            };
        default:
            return state;
    }
};

export default kompoGResultReducer;
