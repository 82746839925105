import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class RolesSelect extends React.Component {


    static propTypes = {
        roleId: PropTypes.number,
        handleRole: PropTypes.func,
        roles: PropTypes.array,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        withEmpty: PropTypes.bool,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        filter: PropTypes.array,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        roles: [],
        roleId: -1,
        withEmpty: false,
        invalid: false,
        filter: [],
        disabled: false,
        label: "roles.label.default"
    };

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
        }
    }

    componentDidMount() {
        if (this.props.roles.length > 0) {
            this.setState({ roles: this.props.roles.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });
        } else {
            this.setState({ roles: [] })
        }
        if (!this.props.withEmpty && this.props.roleId === -1 && this.props.roles.length > 0) {
            this.props.handleRole(this.props.roles[0]);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.roles !== this.props.roles) {
            if (this.props.roles.length > 0) {
                this.setState({ roles: this.props.roles.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });
            } else {
                this.setState({ roles: [] })
            }
            if (!this.props.withEmpty && this.props.roleId === -1 && this.props.roles.length > 0) {
                this.props.handleRole(this.props.roles[0]);
            }
        }
    }

    createRolesItems() {
        let items = [];
        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "roles.option.pleaseselect" })}</option>);
        this.state.roles.forEach((role) => {
            items.push(<option key={role.id} value={role.id} >{this.props.intl.formatMessage({ id: role.name })}</option>);
        })
        return items;
    }

    handleRole = async (e) => {
        let id = parseInt(e.target.value, 10);
        let role = this.state.roles.filter(el => el.id === id)[0] || null;
        await this.props.handleRole(role);
    }

    render() {
        return (
            <Container fluid className={this.state.roles.length > 1 || this.props.withEmpty ? this.props.className : "d-none " + this.props.className}>
                <div className="mb-1">
                    <Label className={this.props.label !== "roles.label.default" ? "fw-bold" : "d-none"} for="roles"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled} invalid={this.props.invalid} bsSize="sm" type="select" name="roles" id="roles" value={this.props.roleId} onChange={this.handleRole}>
                        {this.createRolesItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesSelect));