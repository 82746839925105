import ENV from '../config/env';

export const executionsService = {
    getExecutions,
    getExecutionsBySchoolId,
    deleteExecutions,
    createExecution,
    updateExecution,
    getObservers,
    getStudents,
    getGroups,
    createGroups,
    getExecutionsstatus,
    getCooperationtype,
    getStudentsForUser,
    getExecutionsHonorarkraft,
    getForeignUsers,
    getExecutionsResults
};

/**
* ALL EXECUTIONS
*/
async function getExecutions(token, order, desc, search, page, pagelimit, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    search = search ? "&search=" + search : "";
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";
    let past = "&past=" + (filter.past ? "1" : "0");
    let cooperation = "&cooperation=" + (filter.cooperation ? "1" : "0");
    let departmentId = filter.departmentId ? "&department=" + filter.departmentId : "";
    let schooltypeId = filter.schooltypeId ? "&schooltype=" + filter.schooltypeId : "";
    let onlyInvolved = filter.onlyInvolved ? "&onlyInvolved=" + (filter.onlyInvolved ? "1" : "0") : "";
    let procedure = filter.procedure ? filter.procedure > 0 ? "&procedure=" + filter.procedure : "" : "";
    return fetch(ENV.apiUrl + '/executions?' + order + desc + search + page + pagelimit + past + departmentId + schooltypeId + cooperation + onlyInvolved + procedure, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }
            return response.json();


        }).then(response => {
            return response;
        });

}

async function getExecutionsBySchoolId(token, schoolId, order, desc, search, page, pagelimit, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    search = search ? "&search=" + search : "";
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";
    let past = "&past=" + (filter.past ? "1" : "0");
    let cooperation = "&cooperation=" + (filter.cooperation ? "1" : "0");
    let departmentId = filter.departmentId ? "&department=" + filter.departmentId : "";
    let schooltypeId = filter.schooltypeId ? "&schooltype=" + filter.schooltypeId : "";
    let onlyInvolved = filter.onlyInvolved ? "&onlyInvolved=" + (filter.onlyInvolved ? "1" : "0") : "";
    let procedure = filter.procedure ? filter.procedure > 0 ? "&procedure=" + filter.procedure : "" : "";

    return fetch(ENV.apiUrl + '/executions/' + schoolId + '?' + order + desc + search + page + pagelimit + past + departmentId + schooltypeId + cooperation +  onlyInvolved + procedure, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }
            return response.json();


        }).then(response => {
            return response;
        });

}

/**
* DELETE EXECUTION
*/
async function deleteExecutions(token, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/executions/' + id, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

/**
* CREATE EXECUTION
*/
async function createExecution(token, execution) {

    let start = execution.start.format();
    let end = execution.end.format();

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ schools_id: execution.school.id, users_id: execution.contactperson.id, cooperationtype_id: execution.cooperationtype.id, executionsstatus_id: execution.executionsstatus.id, start: start, end: end, procedure: execution.procedureId })
    };

    return fetch(ENV.apiUrl + '/executions', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

/**
* UPDATE EXECUTION
*/
async function updateExecution(token, execution) {

    let start = execution.start.format();
    let end = execution.end.format();

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({schools_id: execution.school.id, users_id: execution.contactperson.id, cooperationtype_id: execution.cooperationtype.id, executionsstatus_id: execution.executionsstatus.id, start: start, end: end, procedure: execution.procedure.id })
    };

    return fetch(ENV.apiUrl + '/executions/' + execution.id, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

/**
* EXECUTIONS OBSERVERS
*/
async function getObservers(token, executionsId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/executions/observers/' + executionsId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

/**
* EXECUTIONS STUDENTS
*/
async function getStudents(token, executionsId, procedureId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/executions/students/' + executionsId + '/' + procedureId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}
/**
* EXECUTIONS STUDENTS FOR USER
*/
async function getStudentsForUser(token, executionsId, honorarkraftId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    honorarkraftId = honorarkraftId > 0 ? '/' + honorarkraftId : "";
    return fetch(ENV.apiUrl + '/executions/studentsforuser/' + executionsId + honorarkraftId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

/**
* EXECUTIONS GROUPS
*/
async function getGroups(token, executionsId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/executions/groups/' + executionsId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}
/**
* CREATE GROUPS
*/
async function createGroups(token, execution, groups) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ groups: groups })
    };

    return fetch(ENV.apiUrl + '/executions/groups/' + execution.id, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

/**
* EXECUTIONS STATUS
*/
async function getExecutionsstatus(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/executions/status/', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

/**
* EXECUTIONS COOPERATIONTYPE
*/
async function getCooperationtype(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/executions/cooperationtype/', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

/**
* EXECUTIONS HONORARKRAFT
*/
async function getExecutionsHonorarkraft(token, executionsId, order, desc, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "?order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");

    return fetch(ENV.apiUrl + '/executions/honorarkraft/' + executionsId + order + desc, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

/**
* EXECUTIONS FOREIGNUSERS
*/
async function getForeignUsers(token, executionsId, order, desc, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "?order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");

    return fetch(ENV.apiUrl + '/executions/foreignusers/' + executionsId + order + desc, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

/**
* EXECUTIONS RESULTS
*/
async function getExecutionsResults(token, executionsId, order, desc, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "?order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");

    return fetch(ENV.apiUrl + '/executions/results/' + executionsId + order + desc, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}


