import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Col, Row, Label } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
//import { isEmail } from '../utils/Tools';
import translationsVars from '../translations/translationsVars';

class PasswordResetModal extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            login: '',
            valid: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.setState({
                login: '',
                valid: false,
            });
        }
    }

    handleLoginChange(e) {
        this.setState({
            login: e.target.value,
            valid: this.inputValid(e.target.value)
        });
    }

    inputValid(value) {
        // trim the inserted value first
        let trimmedValue = value.trim();

        //if (!isEmail(trimmedValue))
        //    return false;

        // always check for empty string
        return trimmedValue !== '';
    }

    render() {
        return (
            <Container>
                <Modal isOpen={this.props.open} size="md" className="modal-height-100 modal-width-100" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.open} >
                    <ModalHeader><FormattedMessage id="passwordresetmodal.title" /></ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col><p><FormattedMessage id="passwordresetmodal.hint" values={translationsVars} /></p></Col>
                        </Row>
                        <div className="mb-1">
                            <Label for="inputLogin"><FormattedMessage id="passwordresetmodal.login.title" /></Label>
                            <Input aria-required type="text" name="inputLogin" id="inputLogin" value={this.state.login} autoComplete="off" onChange={this.handleLoginChange.bind(this)} tabIndex="1" />
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button disabled={this.props.isSendingMail} color="primary" onClick={this.props.handleCancel}><FormattedMessage id="button.cancel" /></Button>
                        <Button disabled={!this.state.valid || this.props.isSendingMail} color="primary" onClick={() => this.props.handleSubmit(this.state.login)}><FormattedMessage id="button.send" /></Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isSendingMail: state.auth.isSendingMail,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordResetModal));
