import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';
// import moment from 'moment';
// import config from '../config/config';
//import { replaceDateTimeSpecialChars } from '../utils/Tools'
import translationsVars from '../translations/translationsVars';

export const generateResetPwdStudentsPDF = (props, state) => {
    return new Promise((resolve, reject) => {
        let documentname = props.intl.formatMessage({ id: "execution.print.resetpwdpdf.filename" }, state.translationsVars)
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        //let execution = props.resetPwdPdfExecution;
        //let starttime = moment(execution.start.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(execution.start.replace(" ", "T"), config.dateFormat.format, true) : null;
        //let endtime = moment(execution.end.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(execution.end.replace(" ", "T"), config.dateFormat.format, true) : null;
        //let executionStart = replaceDateTimeSpecialChars(props.intl.formatDate(starttime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }));
        //let executionEnd = replaceDateTimeSpecialChars(props.intl.formatDate(endtime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }));
        let kompo7Url = props.resetPwdPdfKompoUrl;

        let pages = [];
        props.resetPwdPdfStudents.forEach((student, index) => {
            /*             let transVarsBody1 = {
                            ...translationsVars,
                            school: student.school.name,
                            executionstartdate: executionStart,
                            executionenddate: executionEnd,
                            conversationdate: executionEnd,
                            gender: props.intl.formatMessage({ id: 'execution.print.resetpwdpdf.gender.' + student.address.id }),
                        }; */

            let transVarsBody2 = {
                ...translationsVars,
                greeting: props.intl.formatMessage({ id: 'execution.print.resetpwdpdf.greeting.' + student.address.id }),
                name: student.firstname,
                kompo7url: kompo7Url,
            };

            // build the access info table data
            let rows = [
                [
                    { text: props.intl.formatMessage({ id: "execution.print.resetpwdpdf.access.fullname.label" }), border: [0, 0, 0, 0], alignment: 'right' },
                    { text: student.lastname + ', ' + student.firstname, bold: true, border: [0, 0, 0, 1] }
                ],
                [
                    { text: props.intl.formatMessage({ id: "execution.print.resetpwdpdf.access.class.label" }), border: [0, 0, 0, 0], alignment: 'right' },
                    { text: student.class ? student.class : ' ', bold: true, border: [0, 0, 0, 1] }
                ],
                [
                    { text: props.intl.formatMessage({ id: "execution.print.resetpwdpdf.access.username.label" }), border: [0, 0, 0, 0], alignment: 'right' },
                    { text: student.username ? student.username : ' ', bold: true, border: [0, 0, 0, 1] }
                ],
                [
                    { text: props.intl.formatMessage({ id: "execution.print.resetpwdpdf.access.password.label" }), border: [0, 0, 0, 0], alignment: 'right' },
                    { text: student.password ? student.password : props.intl.formatMessage({ id: "execution.print.resetpwdpdf.access.alreadyloggedin" }), bold: true, border: [0, 0, 0, 1] }
                ],
            ];

            // page content
            let pageContent = [
                /*                  {
                                    //Body 1
                                    text: props.intl.formatMessage({ id: 'execution.print.resetpwdpdf.body1' }, transVarsBody1),
                                    alignment: 'justify',
                                },  */
                /* {
                    //Cutting line
                    image: 'Cutting_line',
                    width: 515,
                    margin: [0, 30, 0, 10]
                }, */
                /*                 {
                                    //Personal access title
                                    text: props.intl.formatMessage({ id: 'execution.print.resetpwdpdf.access.title' }, translationsVars),
                                    bold: true,
                                    margin: [0, 0, 0, 20]
                                }, */
                {
                    //Body 2
                    text: props.intl.formatMessage({ id: 'execution.print.resetpwdpdf.body2' }, transVarsBody2),
                },
                {
                    margin: [0, 20, 0, 0],
                    table: {
                        dontBreakRows: true,
                        headerRows: 0,
                        widths: [190, "*"],
                        body: rows,
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i) {
                            return 0.5;
                        },
                    },
                }
            ];

            // if this is not the last page
            // add a page break property to the last element in the array
            if (index < props.resetPwdPdfStudents.length - 1)
                pageContent[pageContent.length - 1].pageBreak = 'after';

            // combine the elements of the current page with the other ones
            pages = pages.concat(pageContent);
        });

        let tmpHeader = JSON.parse(JSON.stringify(Header));
        tmpHeader.push({
            //Title
            text: props.intl.formatMessage({ id: "execution.print.resetpwdpdf.title" }),
            absolutePosition: { x: 40, y: 65 },
            fontSize: 20,
        });
        tmpHeader.push({
            //Subtitle
            text: props.intl.formatMessage({ id: 'execution.print.resetpwdpdf.access.title' }, translationsVars),
            //text: props.intl.formatMessage({ id: "execution.print.resetpwdpdf.subtitle" }),
            absolutePosition: { x: 40, y: 90 },
            fontSize: 10,
            bold: true,
        });

        let docDefinition = {
            //595pt x 842pt
            pageSize: 'A4',
            pageMargins: [40, 115, 40, 65],
            images: Images,
            header: tmpHeader,
            footer: footer(props),
            content: pages,

        };

        let completeCallback = function () {
            resolve();
        };

        pdfMake.createPdf(docDefinition).download(documentname, completeCallback);
    });
}