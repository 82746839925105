import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';
import config from '../config/config';
import moment from 'moment';

export const generateForeignUsersPDF = (props, state) => {

    let documentname = props.intl.formatMessage({ id: "execution.print.foreignusers.filename" }, state.translationsVars);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let content = [];

    //Observer
    let tableObserver = [];
    //tablehead              
    tableObserver.push(
        [
            {
                text: props.intl.formatMessage({ id: 'execution.print.foreignusers.observer' }),
                fontSize: 12,
                bold: true,
                color: '#FFFFFF',
                fillColor: [67, 53, 140],
            }
        ]
    )

    props.foreignUsers.observer && props.foreignUsers.observer.forEach(observer => {
        //tablebody
        tableObserver.push(
            [
                {
                    text: observer.firstname + " " + observer.lastname,
                    fontSize: 12,
                    margin: [0, 0, 0, 0]
                },
            ]
        )

    });

    //Students
    let tableStudents = [];
    //tablehead              
    tableStudents.push(
        [
            {
                text: props.intl.formatMessage({ id: 'execution.print.foreignusers.students' }),
                fontSize: 12,
                bold: true,
                color: '#FFFFFF',
                fillColor: [67, 53, 140],
                margin: [0, 0, 0, 0]
            }
        ]
    )

    props.foreignUsers.students && props.foreignUsers.students.forEach(student => {
        //tablebody
        tableStudents.push(
            [
                {
                    text: student.firstname + " " + student.lastname,
                    fontSize: 12,
                    margin: [0, 0, 0, 0]
                },
            ]
        )

    });


    content.push({
        table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ["*"],
            body: tableObserver,
        },
        layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
                return 0.5;
            },
            vLineWidth: function (i) {
                return 0.5;
            },
        },
        margin: [0, 10, 0, 0]
    });
    content.push({
        table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ["*"],
            body: tableStudents,
        },
        layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
                return 0.5;
            },
            vLineWidth: function (i) {
                return 0.5;
            },
        },
        margin: [0, 10, 0, 0]
    })



    let startdatetime = state.foreignUsersExecution.start ? moment(state.foreignUsersExecution.start.replace(" ", "T"), config.dateFormat.format, true): moment();
    let enddatetime = state.foreignUsersExecution.end ? moment(state.foreignUsersExecution.end.replace(" ", "T"), config.dateFormat.format, true) : moment();
    startdatetime = props.intl.formatDate(startdatetime, { weekday: config.dateFormat.weekday }) + "." + props.intl.formatDate(startdatetime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day });
    enddatetime = props.intl.formatDate(enddatetime, { weekday: config.dateFormat.weekday }) + "." + props.intl.formatDate(enddatetime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day });

    let tmpHeader = JSON.parse(JSON.stringify(Header));
    tmpHeader.push({
        //title
        text: props.intl.formatMessage({ id: "execution.print.foreignusers.title" }),
        absolutePosition: { x: 40, y: 65 },
        fontSize: 20,
    });
    tmpHeader.push({
        //executions date
        text: props.intl.formatMessage({ id: "execution.print.foreignusers.date" }),
        absolutePosition: { x: 40, y: 100 },
        fontSize: 12,
        bold: true,
    });
    tmpHeader.push({
        //executions date
        text: startdatetime + " - " + enddatetime,
        absolutePosition: { x: 220, y: 100 },
        fontSize: 12,

    });
    tmpHeader.push({
        //school
        text: props.intl.formatMessage({ id: "execution.print.foreignusers.school" }),
        absolutePosition: { x: 40, y: 115 },
        fontSize: 12,
        bold: true
    });
    tmpHeader.push({
        //school
        text: state.foreignUsersExecution.school.name + "\n" + state.foreignUsersExecution.school.street + ", " + state.foreignUsersExecution.school.place,
        absolutePosition: { x: 220, y: 115 },
        fontSize: 12,
    });

    let docDefinition = {
        //595pt x 842pt
        //pageOrientation: 'landscape',
        pageSize: 'A4',
        pageMargins: [40, 150, 40, 65],
        images: Images,
        header: tmpHeader,
        footer: footer(props),
        content: content

    }
    pdfMake.createPdf(docDefinition).download(documentname);
}