import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class CertifiedSelect extends React.Component {


    static propTypes = {
        certified: PropTypes.number,
        handleCertified: PropTypes.func,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        certified: null,
        invalid: false,
        disabled: false,
        label: "certified.label.default"
    };

    constructor(props) {
        super(props);
        this.state = {
            certified: [

            ],
        }
    }


    handleCertified = async (e) => {
        let certified = parseInt(e.target.value, 10) !== -1 ? parseInt(e.target.value, 10) : null;
        await this.props.handleCertified(certified);
    }

    render() {
        return (
            <Container fluid className={this.props.className}>
                <div className="mb-1">
                    <Label className={this.props.label !== "certified.label.default" ? "fw-bold" : "d-none"} for="certified"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled} invalid={this.props.invalid} bsSize="sm" type="select" name="certified" id="certified" value={this.props.certified === null ? -1 : this.props.certified} onChange={this.handleCertified}>
                        <option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "certified.label.empty" })}</option>
                        <option key={0} value={0} >{this.props.intl.formatMessage({ id: "certified.label.without.certified" })}</option>
                        <option key={1} value={1} >{this.props.intl.formatMessage({ id: "certified.label.with.certified" })}</option>
                    </Input>
                </div>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(CertifiedSelect));