import * as React from 'react';
import { Container } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Loading extends React.Component {

    static propTypes = {
        absolute: PropTypes.bool,
    }

    static defaultProps = {
        absolute: false,
    }

    render() {
        if (this.props.absolute) {
            return (
                <div className="loader-absolute">
                    <Container fluid className="loader"></Container>
                </div>
            );
        } else {
            return (
                <Container fluid className="loader"></Container>
            );
        }
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Loading));