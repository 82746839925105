import * as React from 'react';
import { Container, Button, Label } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class GeneratePDF extends React.Component {

    static propTypes = {
        handlePDF: PropTypes.func,
        className: PropTypes.string,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
    }

    render() {
        return (
            <Container className={this.props.className} fluid>
                <Label className="d-none" for="pdf"><FormattedMessage id="button.pdf" /></Label>
                <Button color="primary" name="pdf" onClick={this.props.handlePDF} disabled={this.props.disabled}><FormattedMessage id="button.pdf" /></Button>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneratePDF));