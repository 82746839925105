//595pt x 842pt
export const Header = [
    //81% of original image size
    {
        //OLOV LOGO
        image: 'olov_logo_rz',
        width: 106,
        absolutePosition: { x: 40, y: 1 }
    },
    {
        //COGWHEEL
        image: 'olov_keyvisual_quer_rgb',
        width: 219,
        absolutePosition: { x: 160, y: 1 }
    },
    {
        //BWHW LOGO
        image: 'bwhw_logo',
        width: 156,
        absolutePosition: { x: 399, y: 3 }
    }

];

export const HeaderLandscape = [
    //81% of original image size
    {
        //OLOV LOGO
        image: 'olov_logo_rz',
        width: 106,
        absolutePosition: { x: 40, y: 1 }
    },
    {
        //COGWHEEL
        image: 'olov_keyvisual_quer_rgb',
        width: 219,
        absolutePosition: { x: 311, y: 1 }
    },
    {
        //BWHW LOGO
        image: 'bwhw_logo',
        width: 156,
        absolutePosition: { x: 646, y: 3 }
    }
];