import ENV from '../config/env';

export const kompo7RatingService = {
    getRatingForUser,
    createUserRating,
    getRatingResults
};

async function getRatingForUser(token, executionId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/kompo7rating/' + executionId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });
}

/**
* CREATE USERRATING
*/
async function createUserRating(token, executionId, rating, ratingMessage) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ rating: rating, rating_message: ratingMessage })
    };

    return fetch(ENV.apiUrl + '/kompo7rating/' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }

            return response;
        });

}

/**
* GET RATING RESULTS
*/
async function getRatingResults(token, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };
    let schoolId = filter.schoolId ? ('&school=' + filter.schoolId) : '';
    let start = filter.start ? ('&start=' + filter.start) : '';
    let end = filter.end ? ('&end=' + filter.end) : '';
    let departmentId = filter.departmentId ? ('&department=' + filter.departmentId) : '';
    let schooltypeId = filter.schooltypeId ? ('&schooltype=' + filter.schooltypeId) : '';
    let schoolyear = filter.schoolyear ? ('&schoolyear=' + filter.schoolyear) : '';
    let procedure = filter.procedure ? ('&procedure=' + filter.procedure) : '';


    return fetch(ENV.apiUrl + '/kompo7rating?' + schoolId + start + end + departmentId + schooltypeId + schoolyear + procedure, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });
}