import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import config from '../config/config';
import { filterActions, executionsActions } from '../actions';

export class ExecutionSelect extends React.Component {

    static propTypes = {
        dispatchExecution: PropTypes.func.isRequired,
        past: PropTypes.bool,
        onlyInvolved: PropTypes.bool,
        label: PropTypes.string,
        feedbacklabel: PropTypes.string,
        executionId: PropTypes.number,
        handleExecutionId: PropTypes.func,
        handleExecution: PropTypes.func,
        order: PropTypes.string,
        desc: PropTypes.bool,
        withEmpty: PropTypes.bool,
        className: PropTypes.string,
        invalid: PropTypes.bool,
        filter: PropTypes.array,
        disabled: PropTypes.bool,
        withTime: PropTypes.bool,
        schoolId: PropTypes.number,
        procedureId: PropTypes.number,
        onlyKompo7: PropTypes.bool
    };

    static defaultProps = {
        order: "start",
        desc: false,
        excutionlId: -1,
        onlyInvolved: false,
        dataEnabled: null,
        withEmpty: false,
        invalid: false,
        filter: [],
        disabled: false,
        withTime: false,
        schoolId: null,
        label: "executionselect.label.default",
        onlyKompo7: false,
    };

    constructor(props) {
        super(props);

        if (props.executions.length > 0) {
            this.state = {
                executions: props.executions.filter(el => !(props.filter.indexOf(el.id) >= 0))
            };
        } else {
            this.state = {
                executions: [],
            }
        }
    }

    componentDidMount() {
        this.getExecutions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.schoolId !== prevProps.schoolId
            || this.props.past !== prevProps.past
            || this.props.pastFilter !== prevProps.pastFilter
            || this.props.procedureId !== prevProps.procedureId) {
            // await this.setState();
            this.getExecutions();
        }

        if (prevProps.executions !== this.props.executions) {
            if (this.props.executions.length > 0) {
                this.setState({ executions: this.props.executions.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) })
                if (!this.props.withEmpty && this.props.executionId < 0) {
                    this.props.dispatchExecution(this.props.executions[0]);
                } else if (this.props.executionId > 0 && this.props.executions.filter((ex) => { return ex.id === this.props.executionId }).length === 0) {
                    this.props.dispatchExecution(null);
                }
            } else {
                this.props.dispatchExecution(null);
                this.setState({ executions: [] });
            }
        }
    }

    getExecutions() {
        if (this.props.schoolId && this.props.schoolId !== -1) {
            this.props.dispatchGetExecutionsOfSchool(this.props.auth.token, this.props.schoolId, this.props.order, this.props.desc, "", 1, 0, { past: this.props.past ? this.props.past : this.props.pastFilter, onlyInvolved: this.props.onlyInvolved, procedure: this.props.procedureId });
        } else {
            this.props.dispatchGetExecutions(this.props.auth.token, this.props.order, this.props.desc, "", 1, 0, { past: this.props.past ? this.props.past : this.props.pastFilter, onlyInvolved: this.props.onlyInvolved, procedure: this.props.procedureId });
        }
    }

    createExecutionItems() {
        let items = [];

        this.props.withEmpty && items.push(<option key={-1} value={-1}>{this.props.intl.formatMessage({ id: "executionselect.option.pleaseselect" })}</option>);
        let showProcedure = this.props.auth && this.props.auth.procedure && this.props.auth.procedure > 0 ? false : true;
        this.state.executions.forEach((execution) => {
            let start = moment(execution.start.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(execution.start.replace(" ", "T"), config.dateFormat.format, true) : null;
            let end = moment(execution.end.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(execution.end.replace(" ", "T"), config.dateFormat.format, true) : null;
            let procedure = showProcedure && !this.props.onlyKompo7 ? " [" + execution.procedure.name + "]" : "";
            let show = this.props.onlyKompo7 && execution.procedure.id === 2 ? false : true;
            if (show && start !== null && end !== null) {
                items.push(<option key={execution.id} value={execution.id} >
                    {this.props.intl.formatDate(start, { weekday: config.dateFormat.weekday })}. {this.props.intl.formatDate(start, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day })}{this.props.withTime && " " + this.props.intl.formatTime(start, { hour: config.dateFormat.hour, minute: config.dateFormat.minute })}
                    &nbsp;-&nbsp;
                    {this.props.intl.formatDate(end, { weekday: config.dateFormat.weekday })}. {this.props.intl.formatDate(end, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day })}{this.props.withTime && " " + this.props.intl.formatTime(end, { hour: config.dateFormat.hour, minute: config.dateFormat.minute })}
                    {procedure}
                </option>);
            }
        })
        return items;
    }

    handleExecutionId = (e) => {
        let id = parseInt(e.target.value, 10);
        let execution = this.state.executions.filter(el => el.id === id)[0] || null;
        if (this.props.handleExecution) {
            this.props.handleExecution(execution);
        } else {
            this.props.dispatchExecution(execution);
        }

    }

    render() {
        return (
            <Container fluid className={this.state.executions.length > 1 || this.props.withEmpty ? this.props.className : "d-none " + this.props.className}>
                <div className="m-0 p-0 mb-1">
                    <Label className={this.props.label !== "executionselect.label.default" ? "fw-bold" : "d-none"} for="execution"><FormattedMessage id={this.props.label} /></Label>
                    <Input disabled={this.props.disabled || this.props.saving} invalid={this.props.invalid} bsSize="sm" type="select" name="execution" id="execution" value={this.props.executionId} onChange={this.handleExecutionId}>
                        {this.createExecutionItems()}
                    </Input>
                    {this.props.invalid && this.props.feedbacklabel &&
                        <FormFeedback className="ps-1" invalid="true"><FormattedMessage id={this.props.feedbacklabel} /></FormFeedback>
                    }
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution && state.filter.execution.id ? state.filter.execution.id : -1,
        pastFilter: state.filter.executions_past,
        executions: state.executions.executions,
        saving: state.observations.saving || state.competences.saving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetExecutionsOfSchool: (token, schoolId, order, desc, search, page, pagelimit, filter) => {
            dispatch(executionsActions.getExecutionsOfSchool(token, schoolId, order, desc, search, page, pagelimit, filter));
        },
        dispatchGetExecutions: (token, order, desc, search, page, pagelimit, filter) => {
            dispatch(executionsActions.getExecutions(token, order, desc, search, page, pagelimit, filter));
        },
        dispatchExecution: (execution) => {
            dispatch(filterActions.execution(execution));
        }
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionSelect));