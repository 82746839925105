import ENV from '../config/env';

export const uploadService = {
    uploadFile,
};

/**
* UPLOAD FILE
*/
async function uploadFile(token, uploadUrl, file) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Cache-Control': 'no-cache',
        },
        body: file
    };

    return fetch(ENV.apiUrl + uploadUrl, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        });
}
