import * as React from 'react';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Form, Label, Collapse } from 'reactstrap';
import config from '../config/config';
import MultipleSelect from './MultipleSelect';
import moment from 'moment';
import DisplaySchool from './DisplaySchool';


class InquirySendModal extends React.Component {

    static propTypes = {
        inquiry: PropTypes.object,
        schoolId: PropTypes.number,
        open: PropTypes.bool,
        toggle: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            confirmReject: false,
            confirmAccept: false,
            inquiry: {},
            open: false,
            multipleObserver: [],
            multipleStudents: [],
            selectedObserver: [],
            selectedStudents: [],
            collapse: true,
            textReceiver: '',
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.inquiry && prevProps.inquiry !== this.props.inquiry) {
            let multipleStudents = [];
            let selectedStudents = []
            this.props.inquiry.students.forEach((student) => {
                multipleStudents.push({ id: student.id, text: student.lastname + ", " + student.firstname + " (" + student.class + ")" })
                selectedStudents.push(student.id);
            });
            let multipleObserver = [];
            let selectedObserver = []
            this.props.inquiry.observer.forEach((observer) => {
                multipleObserver.push({ id: observer.id, text: observer.lastname + ", " + observer.firstname })
                selectedObserver.push(observer.id);
            });
            this.setState({ multipleStudents: multipleStudents })
            this.setState({ schoolId: this.props.inquiry.school.id, multipleStudents: multipleStudents, multipleObserver: multipleObserver, selectedObserver: selectedObserver, selectedStudents: selectedStudents });
        }
    }

    reject = (e) => {
        let inquiry = this.state.inquiry;
        inquiry.rejected = new moment().format();
        this.setState({ inquiry: inquiry });
        this.props.save(this.state.inquiry);
    }

    handleCollapse = (e) => {
        this.setState({ collapse: !this.state.collapse })
    }


    render() {

        let startdatetime = this.props.inquiry && this.props.inquiry.execution && this.props.inquiry.execution.start ? moment(this.props.inquiry.execution.start.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let enddatetime = this.props.inquiry && this.props.inquiry.execution && this.props.inquiry.execution.end ? moment(this.props.inquiry.execution.end.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let senddatetime = this.props.inquiry && this.props.inquiry.send && this.props.inquiry.send ? moment(this.props.inquiry.send.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let rejecteddatetime = this.props.inquiry && this.props.inquiry.rejected && this.props.inquiry.rejected ? moment(this.props.inquiry.rejected.replace(" ", "T"), config.dateFormat.format, true) : null;
        let replydatetime = this.props.inquiry && this.props.inquiry.reply && this.props.inquiry.reply ? moment(this.props.inquiry.reply.replace(" ", "T"), config.dateFormat.format, true) : null;

        return (
            <Container>
                <Form>
                    <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                        <ModalHeader className={this.props.inquiry && "edit"}><FormattedMessage id="inquirysendmodal.title.inquiry" /></ModalHeader>

                        <ModalBody>
                            <Container fluid className="px-3">
                                <Row>
                                    <Col className="my-0 py-0 p-1">
                                        <Label className="d-none" for="collapse"><FormattedMessage id={this.state.collapse ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse" color="link" className="btn-collapse m-0 p-0" onClick={this.handleCollapse} ><FormattedMessage id="inquirymodal.collapse.text" /><span className={this.state.collapse ? "collapse-open ms-2" : "collapse-close ms-2"}></span></Button>
                                    </Col>
                                </Row>
                            </Container>
                            <Collapse className="m-0 p-0 px-3" isOpen={this.state.collapse}>
                                <Container fluid className="pb-3">
                                    {/* School */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirysendmodal.label.school" />
                                        </Col>
                                        <Col className="p-1">
                                            {this.props.inquiry && this.props.inquiry.school &&
                                                <DisplaySchool school={this.props.inquiry.school} name="im" />
                                            }
                                        </Col>
                                    </Row>
                                    {/* start- enddate */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirysendmodal.label.startdate" />
                                        </Col>
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={startdatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={startdatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirysendmodal.label.enddate" />
                                        </Col>
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={enddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={enddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                    </Row>
                                    {/* send */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirysendmodal.label.inquirysenton" />
                                        </Col>
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={senddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={senddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>

                                    </Row>
                                    {/* needed observer */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="6">
                                            <FormattedMessage id="inquirysendmodal.label.neededobserver" />
                                        </Col>
                                        <Col className="p-1">
                                            {this.props.inquiry && this.props.inquiry.neededobserver}
                                        </Col>
                                    </Row>
                                    {/* mymessage */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="12" sm="12" md="12">
                                            <FormattedMessage id="inquirysendmodal.label.mymessage" />
                                        </Col>
                                        <Col className="p-1" xs="12">
                                            <Container fluid className="container-text-scroll-y m-0 py-1 px-0">
                                                {this.props.inquiry && this.props.inquiry.text}
                                            </Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </Collapse>

                            <Container fluid className="border-top px-3">
                                <Row>
                                    <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                        <FormattedMessage id="inquirysendmodal.label.inquiryrejectedon" />
                                    </Col>
                                    {rejecteddatetime ?
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={rejecteddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={rejecteddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                        :
                                        <Col className="p-1">-</Col>}
                                    <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                        <FormattedMessage id="inquirysendmodal.label.inquiryaccepted" />
                                    </Col>
                                    {replydatetime ?
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={replydatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={replydatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                        :
                                        <Col className="p-1">-</Col>}
                                </Row>
                                {/* observer students */}
                                <Row className="border-top">
                                    <Col xs="12" md="6">
                                        <Row>
                                            <Col className="p-1" xs="12"><FormattedMessage id="inquirysendmodal.label.observer" /></Col>
                                            <Col className="p-1" xs="12">
                                                <MultipleSelect className="p-0" index="observer" elements={this.state.multipleObserver} key="observer" selectedElements={this.state.selectedObserver} disabled={true} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Row>
                                            <Col className="p-1" xs="12"><FormattedMessage id="inquirysendmodal.label.students" /></Col>
                                            <Col className="p-1" xs="12">
                                                <MultipleSelect className="p-0" index="students" elements={this.state.multipleStudents} key="students" selectedElements={this.state.selectedStudents} disabled={true} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/*receiver message */}
                                <Row className="border-top">
                                    <Col className="p-1 fw-bold" xs="12" sm="12" md="12">
                                        <FormattedMessage id="inquirysendmodal.label.message" />
                                    </Col>
                                    <Col className="p-1" xs="12">
                                        {
                                            <Container fluid className="container-text-scroll-y m-0 py-1 px-0">
                                                {this.props.inquiry && this.props.inquiry.text_receiver}
                                            </Container>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" name="close" color="primary" onClick={this.props.toggle}><FormattedMessage id="button.close" /></Button>
                        </ModalFooter>

                    </Modal>
                </Form>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(InquirySendModal));