import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Button, Alert, Row, Col, Input } from 'reactstrap';
// import Confirm from '../components/Confirm';
import config from '../config/config';
// import moment from 'moment';
import GeneratePDF from '../components/GeneratePDF';
import PropTypes from 'prop-types';
import ExecutionSelect from '../components/ExecutionSelect';
import Loading from '../components/Loading';
import translationsVars from '../translations/translationsVars';
import { competencesActions, kompoGResultActions } from '../actions';
import { generateMyResultPDF } from '../pdf/MyResultsPDF';

class MyResultsPage extends Component {

    static propTypes = {
        dispatchGetCompetences: PropTypes.func.isRequired,
        executionId: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            studentsId: -1,
            confirm: false,
            alert: { visible: false, textId: "alert.save", color: "success" },
            translationsVars: translationsVars,
            firstLoad: true,
            text1Length: 0,
            text2Length: 0,
            text3Length: 0,
            saved: false,
            competences: [],
            bestCompetences: [],
            result: { typesSel: [-1, -1, -1], types: [[]], competences: [-1, -1, -1], answer1: "", answer2: "", answer3: "", feedbackTeacher: "", releaseTeacher: 0, motivations: [], interests: [] },
            // checkU : ['verb-exercise-type-handcraft','verb-exercise-type-social', 'verb-exercise-type-research', 'verb-exercise-type-creative',
            //     'verb-exercise-type-proof', 'verb-exercise-type-entrepreneurial', 'verb-exercise-type-managing', 'verb-exercise-type-technical'],
            // checkU1: "",
            // checkU2: "",
            // checkU3: "",
        };
    }

    componentDidMount() {
        this.setState({ translationsVars: { ...this.state.translationsVars, filename: this.props.auth.firstname + "__" + this.props.auth.lastname } });
    }

    componentWillUnmount() {
        //     document.removeEventListener('keydown', this._handleKeyDown);
    }



    componentDidUpdate(prevProps) {

        if (this.props.executionId !== -1 && (this.state.firstLoad || prevProps.executionId !== this.props.executionId)) {
            this.setState({ firstLoad: false });
            this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, null);
            this.props.dispatchGetResult(this.props.auth.token, this.props.executionId);
        }

        if (prevProps.competences !== this.props.competences) {
            this.setState({ competences: this.props.competences });
            this.calcBestCompetences(this.props.competences);
        }

        if (prevProps.result !== this.props.result) {
            let result = { ...this.props.result };
            let update = false;
            //when bestCompetences changed then modify result
            if (this.state.bestCompetences.find(e => e > 0) !== undefined) {
                let test = (rc) => {
                    return this.state.bestCompetences.find(bc => bc === rc[prop]) === undefined;
                };
                for (var prop in result.competences) {
                    if (test(result.competences) && result.competences[prop] !== -1) {
                        result.competences[prop] = -1;
                        update = true
                    }
                }
                //sort value "0" to end and the rest alphabetically
                let entries = Object.entries(result.competences);
                let sorted = entries.sort((a, b) => a[1] === 0 ? (a[1] === b[1] ? -1 : 1) : -1);
                let idx = 0;
                sorted.forEach((s) => {
                    result.competences[idx] = s[1];
                    idx++;
                });
            }
            this.setState({ result: result });
            if (update) {
                this.props.dispatchSaveResult(this.props.auth.token, this.props.executionId, result);
            }
        }

        if (prevProps.saveStatus !== this.props.saveStatus) {
            if (this.props.saveStatus) {
                this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            } else {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }
        }
    }

    calcBestCompetences = (competences) => {
        let competenceList = [];
        competences.forEach((c) => {
            for (let assessElem of c.assessments) {
                if (assessElem.params.rating < 3) {
                    competenceList.push(c.id);
                    break;
                };
            }
        })
        this.setState({ bestCompetences: competenceList })
    }

    handleSave() {
        // this.props.setState({ saved: true });
        this.props.dispatchSaveResult(this.props.auth.token, this.props.executionId, this.state.result);
    }

    handleHideAlert = (e) => {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    };

    handlePDF() {
        const result = { ...this.state.result }
        const types = result.types[0];
        const typesSel = result.typesSel;

        let competences = [];
        for (let idx = 0; idx < 3; idx++) {
            if (this.state.result.competences[idx]) {
                let comp = this.props.competences.find(e => e.id === this.state.result.competences[idx]);
                if (comp) {
                    competences.push(comp.competence);
                }
            }
        }
        let motivations = [];
        result.motivations.forEach(m => {
            motivations.push(m.motivation);
        })

        let preferences = [];
        if (typesSel.length > 0) {
            let res = types.find(e => e.id === typesSel[0]);
            if (res) {
                preferences.push(res.name);
            }
        }
        if (typesSel.length > 1) {
            let res = types.find(e => e.id === typesSel[1]);
            if (res) {
                preferences.push(res.name);
            }
        }
        if (typesSel.length > 2) {
            let res = types.find(e => e.id === typesSel[2]);
            if (res) {
                preferences.push(res.name);
            }
        }
        const content = [{
            username: this.props.auth.firstname + " " + this.props.auth.lastname,
            preferences: preferences,
            competences: competences,
            interests: result.interests,
            motivations: motivations,
            answer1: result.answer1,
            answer2: result.answer2,
            answer3: result.answer3,
            feedbackTeacher: result.feedbackTeacher,
            releaseTeacher: result.releaseTeacher,
        }];

        let documentname = this.props.intl.formatMessage({ id: "myresultPage.kompoG.label" });
        generateMyResultPDF(this.props, this.state, content).download(documentname);
    }

    handleChangeText1(e) {
        let tmp = { ...this.state.result };
        tmp.answer1 = e.target.value;
        this.setState({ result: tmp });
    }

    handleChangeText2(e) {
        let tmp = { ...this.state.result };
        tmp.answer2 = e.target.value;
        this.setState({ result: tmp });
    }

    handleChangeText3(e) {
        let tmp = { ...this.state.result };
        tmp.answer3 = e.target.value;
        this.setState({ result: tmp });
    }

    handleChangeCompetence(e, idx) {
        let tmp = { ...this.state.result };
        tmp.competences[idx] = parseInt(e);
        this.setState({ result: tmp });
    }

    handleChangeVerbs(e, idx) {
        let tmp = { ...this.state.result };
        tmp.typesSel[idx] = parseInt(e);
        this.setState({ result: tmp });
    }

    render() {
        if (this.props.executionId === -1 && !this.props.loading) {
            return (
                <Container fluid className="content-margin">
                    <Container fluid className="p-0 fw-bold">
                        <h1><FormattedMessage id="myresultPage.label" /></h1>

                        {/* Select Execution */}
                        <Row>
                            <Col xs="12" sm="6" md="6" lg="6" xl="3">
                                <ExecutionSelect className="mb-3 p-0" executionId={this.props.executions_id} past={true} />
                            </Col>
                        </Row>
                        <FormattedMessage id="myresultPage.noavailable" />
                    </Container>
                </Container>
            );
        } else {
            return (
                <Container fluid role="main" className="content-margin">
                    {/* Alert */}
                    < Alert isOpen={this.state.alert.visible} toggle={this.handleHideAlert} color={this.state.alert.color} className="fixed-top text-center" > <FormattedMessage id={this.state.alert.textId} /></Alert >

                    <h1><FormattedMessage id="myresultPage.label" /></h1>
                    <Container className="sticky2 mb-1 bg-white px-3">
                        <Row className="p-0 mb-4 m-0">
                            <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this)} />
                        </Row>
                    </Container>
                    {/* Table body */}
                    {
                        !this.props.loadingCompetences ?
                            <Container fluid xs="12" sm="12" md="12" className="myresults-container px-3" id="myResultPage">
                                <Row className="mb-2">
                                    <Col>
                                        {/** Meine beruflichen Vorlieben*/}
                                        <Row className="result-boxes-headline me-1" ><FormattedMessage id="myresultPage.preferences" /></Row>
                                        <Row className="result-boxes me-1">
                                            <Input disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} type={"select"} className="result-box-select mb-1" value={this.state.result.typesSel.length >= 1 && this.state.result.typesSel[0] > 0 ? this.state.result.typesSel[0] : "DEFAULT"} onChange={(e) => { this.handleChangeVerbs(e.target.value, 0) }} >
                                                <option value="DEFAULT" disabled>{this.props.intl.formatMessage({ id: "myresultPage.checkU.select" })}</option>
                                                {this.state.result.types && this.state.result.types.length > 0 && this.state.result.types[0].map((c, index) => {
                                                    if (c.id !== this.state.result.typesSel[1] && c.id !== this.state.result.typesSel[2]) {
                                                        return <option key={"checkU_" + index} value={c.id} >{this.props.intl.formatMessage({ id: c.name })}</option>
                                                    }
                                                    return null;
                                                })}
                                            </Input>
                                            <Input disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} type={"select"} className="result-box-select mb-1" value={this.state.result.typesSel.length >= 2 && this.state.result.typesSel[1] > 0 ? this.state.result.typesSel[1] : "DEFAULT"} onChange={(e) => { this.handleChangeVerbs(e.target.value, 1) }} >
                                                <option value="DEFAULT" disabled>{this.props.intl.formatMessage({ id: "myresultPage.checkU.select" })}</option>
                                                {this.state.result.types && this.state.result.types.length > 0 && this.state.result.types[0].map((c, index) => {
                                                    if (c.id !== this.state.result.typesSel[0] && c.id !== this.state.result.typesSel[2]) {
                                                        return <option key={"checkU1_" + index} value={c.id} >{this.props.intl.formatMessage({ id: c.name })}</option>
                                                    }
                                                    return null;
                                                })}
                                            </Input>
                                            <Input disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} type={"select"} className="result-box-select mb-1" value={this.state.result.typesSel.length >= 3 && this.state.result.typesSel[2] > 0 ? this.state.result.typesSel[2] : "DEFAULT"} onChange={(e) => { this.handleChangeVerbs(e.target.value, 2) }} >
                                                <option value="DEFAULT" disabled >{this.props.intl.formatMessage({ id: "myresultPage.checkU.select" })}</option>
                                                {this.state.result.types && this.state.result.types.length > 0 && this.state.result.types[0].map((c, index) => {
                                                    if (c.id !== this.state.result.typesSel[0] && c.id !== this.state.result.typesSel[1]) {
                                                        return <option key={"checkU2_" + index} value={c.id} >{this.props.intl.formatMessage({ id: c.name })}</option>
                                                    }
                                                    return null;
                                                })}
                                            </Input>
                                        </Row>
                                    </Col>
                                    <Col >
                                        {/** Meine Kompetenzen */}
                                        <Row className="result-boxes-headline ms-1" ><FormattedMessage id="myresultPage.competences" /></Row>
                                        <Row className="result-boxes ms-1">
                                            {this.state.bestCompetences.length > 0 ?
                                                <Input disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} type={"select"} className="result-box-select mb-1" value={this.state.bestCompetences.find(e => e === this.state.result.competences[0]) ? this.state.result.competences[0] : 0} onChange={(e) => { this.handleChangeCompetence(e.target.value, 0) }}>
                                                    <option value={0} disabled >{this.props.intl.formatMessage({ id: "myresultPage.competences.select" })}</option>
                                                    {this.state.competences.map((c, index) => {
                                                        if (this.state.bestCompetences.find(e => e === c.id) && (c.id === 0 || (this.state.result.competences[1] !== c.id && this.state.result.competences[2] !== c.id))) {
                                                            return <option key={"competences0_" + index} value={c.id}>{this.props.intl.formatMessage({ id: c.competence })}</option>
                                                        }
                                                        return null;
                                                    })}
                                                </Input>
                                                : <Input disabled className="result-box-select mb-1"></Input>}
                                            {this.state.bestCompetences.length > 1 ?
                                                <Input disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} type={"select"} className="result-box-select mb-1" value={this.state.bestCompetences.find(e => e === this.state.result.competences[1]) ? this.state.result.competences[1] : 0} onChange={(e) => { this.handleChangeCompetence(e.target.value, 1) }}>
                                                    <option value={0} disabled>{this.props.intl.formatMessage({ id: "myresultPage.competences.select" })}</option>
                                                    {this.state.competences.map((c, index) => {
                                                        if (this.state.bestCompetences.find(e => e === c.id) && (c.id === 0 || (this.state.result.competences[2] !== c.id && this.state.result.competences[0] !== c.id))) {
                                                            return <option key={"competences1_" + index} value={c.id}>{this.props.intl.formatMessage({ id: c.competence })}</option>
                                                        }
                                                        return null;
                                                    })}
                                                </Input>
                                                : <Input disabled className="result-box-select mb-1"></Input>}
                                            {this.state.bestCompetences.length > 2 ?
                                                <Input disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} type={"select"} className="result-box-select" value={this.state.bestCompetences.find(e => e === this.state.result.competences[2]) ? this.state.result.competences[2] : 0} onChange={(e) => { this.handleChangeCompetence(e.target.value, 2) }}>
                                                    <option value={0} disabled >{this.props.intl.formatMessage({ id: "myresultPage.competences.select" })}</option>
                                                    {this.state.competences.map((c, index) => {
                                                        if (this.state.bestCompetences.find(e => e === c.id) && (c.id === 0 || (this.state.result.competences[1] !== c.id && this.state.result.competences[0] !== c.id))) {
                                                            return <option key={"competences2_" + index} value={c.id}>{this.props.intl.formatMessage({ id: c.competence })}</option>
                                                        }
                                                        return null;
                                                    })}
                                                </Input>
                                                : <Input disabled className="result-box-select"></Input>}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                    <Col>
                                        {/** Meine Interessen*/}
                                        <Row className="result-boxes-headline red me-1" ><FormattedMessage id="myresultPage.interests" /></Row>
                                        <Row className="result-boxes me-1">
                                            <ul className="result-box-list">
                                                {this.state.result.interests.map((verb, index) => {
                                                    return <li key={"verb" + index}><span>{verb === "" ? "" : this.props.intl.formatMessage({ id: verb })}</span></li>
                                                })}

                                            </ul>
                                        </Row>
                                    </Col>
                                    {/* Meine Motivationen */}
                                    <Col >
                                        <Row className="result-boxes-headline red ms-1" ><FormattedMessage id="myresultPage.motivation.label1" /></Row>
                                        <Row className="result-boxes ms-1">
                                            <ul className="result-box-list">
                                                {this.state.result.motivations.map((mot, index) => (
                                                    <li key={"mot" + index}><span>{this.props.intl.formatMessage({ id: mot.motivation })}</span></li>
                                                ))}
                                            </ul>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="result-boxes-headline mt-4" ><FormattedMessage id="myresultPage.conclusion" /></Row>
                                <Row className="result-boxes bottom">
                                    <Col>
                                        <Row className="myresult-text pt-1"><FormattedMessage id="myresultPage.conclusion.label1" />{/*&nbsp;({this.state.text1Length}/259)*/}</Row>
                                        <Row>
                                            <textarea disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} className="result-box-textarea" value={this.state.result.answer1} onChange={this.handleChangeText1.bind(this)} />
                                        </Row>
                                        <Row className="myresult-text pt-3"><FormattedMessage id="myresultPage.conclusion.label2" />{/*&nbsp;({this.state.text2Length}/259)*/}</Row>
                                        <Row >
                                            <textarea disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} className="result-box-textarea" value={this.state.result.answer2} onChange={this.handleChangeText2.bind(this)} />
                                        </Row>
                                        <Row className="myresult-text pt-3"><FormattedMessage id="myresultPage.conclusion.label3" />{/*&nbsp;({this.state.text3Length}/259)*/}</Row>
                                        <Row >
                                            <textarea disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} className="result-box-textarea" value={this.state.result.answer3} onChange={this.handleChangeText3.bind(this)} />
                                        </Row>
                                    </Col>
                                </Row>
                                {this.state.result.releaseTeacher ?
                                    <div>
                                        <Row className="result-boxes-headline mt-4"><FormattedMessage id="myresultPage.feedback.teacher" /></Row>
                                        <Row className="result-boxes bottom">
                                            <p>{this.state.result.feedbackTeacher} </p>
                                        </Row>
                                    </div> : null
                                }
                            </Container>
                            : <Loading />
                    }

                    <Container fluid className="px-3 d-flex justify-content-end flex-row mt-4" >
                        <Button disabled={this.state.result && this.state.result.releaseTeacher === 1 ? true : false} color="primary" onClick={this.handleSave.bind(this)} ><FormattedMessage id="button.save" /></Button>
                    </Container>
                </Container >
            );
        }
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        loadingCompetences: state.competences.loading,
        competences: state.competences.competences,
        result: state.kompoGResult.result,
        saveStatus: state.kompoGResult.saveStatus,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetCompetences: (token, executionId, studentsId) => {
            dispatch(competencesActions.getCompetencesKompog(token, executionId, studentsId));
        },
        dispatchGetResult: (token, executionId) => {
            dispatch(kompoGResultActions.getResult(token, executionId, null, null));
        },
        dispatchSaveResult: (token, executionId, result) => {
            dispatch(kompoGResultActions.saveResult(token, executionId, result));
        }
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MyResultsPage));

