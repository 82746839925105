import * as React from 'react';
import { Container, Button, Popover, PopoverHeader, PopoverBody, Row, Col, Label } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class DisplaySchool extends React.Component {

    static propTypes = {
        school: PropTypes.object,
        name: PropTypes.string.isRequired,
        withLabel: PropTypes.bool,
        container: PropTypes.string,
        classNamePopover: PropTypes.string,
        placement: PropTypes.string,
        className: PropTypes.string,
    }

    static defaultProps = {
        withLabel: true,
        container: null,
        classNamePopover: "popover-school",
        placement: "bottom"
    }

    constructor(props) {
        super(props);

        this.togglePopOver = this.togglePopOver.bind(this);
        this.state = {
            popoverOpen: false
        };
    }

    togglePopOver() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        if (this.props.school) {
            return (
                <Container className={"display-school m-0 p-0 " + this.props.className}>
                    <Label className="d-none" for={"Popover" + this.props.school.id + this.props.name}>
                        <FormattedMessage id="displayschool.label.info" />
                    </Label>
                    <Button className={this.props.withLabel ? "me-2 info" : "info"} color="link" name={"Popover" + this.props.school.id + this.props.name} id={"Popover" + this.props.school.id + this.props.name} onClick={this.togglePopOver}></Button>
                    {this.props.withLabel && <div className="display-school"><span className="align-middle">{this.props.school.name}</span></div>}
                    <Popover className={this.props.classNamePopover} container={this.props.container ? this.props.container : "body"} placement={this.props.placement} aria-live="polite" aria-atomic="true" aria-hidden={!this.state.popoverOpen} isOpen={this.state.popoverOpen} target={"Popover" + this.props.school.id + this.props.name} toggle={this.togglePopOver}>
                        <PopoverHeader>{this.props.school.name}</PopoverHeader>
                        <PopoverBody>
                            <Row className="mx-1 border-bottom">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.schooltype" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7"><FormattedMessage id={this.props.school.schooltype.name} /></Col>
                            </Row>
                            <Row className="mx-1 border-bottom">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.street" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7">{this.props.school.street}</Col>
                            </Row>
                            <Row className="mx-1 border-bottom">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.postcode" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7">{this.props.school.postcode}</Col>
                            </Row>
                            <Row className="mx-1 border-bottom">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.place" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7">{this.props.school.place}</Col>
                            </Row>
                            <Row className="mx-1 border-bottom">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.phone" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7">{this.props.school.phone}</Col>
                            </Row>
                            <Row className="mx-1 border-bottom">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.fax" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7">{this.props.school.fax}</Col>
                            </Row>
                            <Row className="mx-1 border-bottom">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.email" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7">{this.props.school.email}</Col>
                            </Row>
                            <Row className="mx-1">
                                <Col className="mx-0 px-0 fw-bold" xs="12" md="5"><FormattedMessage id="displayschool.homepage" /></Col>
                                <Col className="mx-0 px-0" xs="12" md="7">{this.props.school.homepage}</Col>
                            </Row>
                        </PopoverBody>
                    </Popover>
                </Container>
            );
        }
        return null;
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplaySchool));