import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Button, Alert, Row, Col, Input } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import Confirm from '../components/Confirm';
import GeneratePDF from '../components/GeneratePDF';
import config from '../config/config';
// import moment from 'moment';
import PropTypes from 'prop-types';
import { competencesActions, kompoGResultActions } from '../actions';
import translationsVars from '../translations/translationsVars';
import Loading from '../components/Loading';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { HeaderLandscape } from '../pdf/HeaderPDF';
import { Images } from '../pdf/ImagesPDF';
import { footer } from '../pdf/FooterPDF';


class SelfAssessmentPageKomPoG extends Component {

    static propTypes = {
        dispatchGetCompetences: PropTypes.func.isRequired,
        dispatchCreateUsersCompetences: PropTypes.func.isRequired,
        executionId: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            order: "",
            desc: false,
            enabled: true,
            studentsId: -1,
            modified: null,
            confirm: false,
            note: false,
            competences: [],
            alert: { visible: false, textId: "alert.save", color: "success" },
            translationsVars: translationsVars,
            firstLoad: true,
        };
    }

    componentDidMount() {
        this.setState({ translationsVars: { ...this.state.translationsVars, filename: this.props.auth.firstname + '_' + this.props.auth.lastname } });
    }

    componentWillUnmount() {
    }

    buildTableBody = (assessments, idx) => {
        let row = [];
        assessments.forEach((trData2) => {
            row.push([{ text: this.props.intl.formatMessage({ id: trData2.assessment }), fontSize: 10, fillColor: idx % 2 ? null : '#E8E8E8' },
            { text: trData2.params.rating === 1 ? "X" : " ", fontSize: 10, fillColor: idx % 2 ? null : '#E8E8E8' },
            { text: trData2.params.rating === 2 ? "X" : " ", fontSize: 10, fillColor: idx % 2 ? null : '#E8E8E8' },
            { text: trData2.params.rating === 3 ? "X" : " ", fontSize: 10, fillColor: idx % 2 ? null : '#E8E8E8' },
            { text: trData2.params.rating === 4 ? "X" : " ", fontSize: 10, fillColor: idx % 2 ? null : '#E8E8E8' }]);
        });
        return row;
    }

    getTable = (assessments, idx) => {
        return {
            style: 'titleInnerTable',
            layout: {
                paddingRight: function (i, node) { return 21; },
            },
            table: {
                widths: [89, 6, 6, 6, 5],
                body: this.buildTableBody(assessments, idx)
            }
        }
    }

    handlePDF() {
        let documentname = this.props.intl.formatMessage({ id: "selfassessmentkompog.label.selfassessment" });

        let rows = [];
        rows.push([
            [{ text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.competence" }), bold: true, fontSize: 10 }],
            {
                style: 'titleInnerTable',
                layout: {
                    paddingRight: function (i, node) { return 21; },
                },
                table: {
                    widths: [89, 6, 6, 6, 5],
                    body: [[{ text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.selfassessment-th.sep" }), bold: true, fontSize: 10, margin: [0, 0, 10, 0], },
                    { text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.plusplus" }), fontSize: 8, },
                    { text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.plus" }), fontSize: 10, },
                    { text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.minus" }), fontSize: 10, },
                    { text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.minusminus" }), fontSize: 10, }

                    ]]
                }
            },
            [{ text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.example" }), bold: true, fontSize: 10 }],
            [{ text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.additions" }), bold: true, fontSize: 10 }],
        ]);

        this.props.competences.forEach((trData, trIndex) => {
            rows.push([
                { text: (this.props.intl.formatMessage({ id: trData.competence + ".sep" })).toUpperCase(), fontSize: 10, hyphen: "auto", fillColor: trIndex % 2 ? null : '#E8E8E8' },
                this.getTable(trData.assessments, trIndex),
                { text: trData.params.example_freetext, fontSize: 10, fillColor: trIndex % 2 ? null : '#E8E8E8' },
                { text: trData.params.additions_freetext, fontSize: 10, fillColor: trIndex % 2 ? null : '#E8E8E8' },
            ]
            );
        });


        try {
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            let docDefinition = {
                pageOrientation: 'landscape',
                pageSize: 'A4',
                images: Images,
                header: HeaderLandscape,
                footer: (currentPage, pageCount) => { return footer(this.props, true, currentPage, pageCount, true) },
                pageMargins: [40, 70, 40, 70],
                content: [
                    {
                        text: this.props.intl.formatMessage({ id: "selfassessmentkompog.label.selfassessment" }), fontSize: 20, fontWeight: 'bold', color: '#d11b28', lineHeight: 1.5
                    },
                    {
                        text: this.props.intl.formatMessage({ id: 'roles.student' }) + ": " + this.props.auth.firstname + " " + this.props.auth.lastname, lineHeight: 1.5
                    },
                    {
                        table: {
                            //   headerRows: 1,
                            dontBreakRows: true,
                            widths: ['12%', '32%', '28%', '28%'],
                            body: rows,
                        }
                    }
                ],
                styles: {
                    titleInnerTable: { margin: [-6, -3] }
                }
            }
            pdfMake.createPdf(docDefinition).download(documentname);
        } catch (error) {
            console.error('Error: create_pdf(' + documentname + ')', error);
        }
    }

    // called when the user want to change the page(route)
    goToNextPage() {
        if (this.state.nextPage) {
            // Remove the confirm dialog
            this.props.history.block((location, action) => {
                return true;
            });
            // push the new page
            this.props.history.push(this.state.nextPage);
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.executionId !== -1 && (this.state.firstLoad || prevProps.executionId !== this.props.executionId)) {
            this.setState({ firstLoad: false });

            this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, this.state.enabled);
            this.props.dispatchGetResult(this.props.auth.token, this.props.executionId);
        }

        if (prevProps.competences !== this.props.competences) {
            this.setState({ competences: this.props.competences });
        }

        if (prevProps.createStatus !== this.props.createStatus) {
            if (this.props.createStatus && this.props.createStatus.ok) {
                this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
                this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc, this.state.enabled);
            } else if (this.props.createStatus) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }
        }
    }

    handleHideAlert = (e) => {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    };

    handleCheckbox = async (competence_id, assessment_id, value) => {
        let newState = { ...this.state };
        let changed = false;

        newState.competences.forEach(competence => {
            if (competence && competence.id === competence_id) {
                competence.assessments.forEach(assessment => {
                    if ((assessment && assessment.params) && assessment.id === assessment_id) {
                        if (assessment.params.rating === value) {
                            assessment.params.rating = 0;
                        } else {
                            assessment.params.rating = value;
                        }
                        changed = true;
                    }
                });
            }
        });

        if (!changed) {
            newState.competences.forEach(competence => {
                if (competence.id === competence_id) {
                    competence.assessments.forEach(assessment => {
                        if (assessment.id === assessment_id) {
                            if (!assessment.params) {
                                assessment.params = {};
                            }
                            Object.assign(assessment.params, { rating: value });
                        }
                    });
                }
            });
        }

        this.setState({ competences: newState.competences });
    }

    handleExampleInput = async (value, competence_id) => {
        let newState = { ...this.state };
        let changed = false;

        newState.competences.forEach(competence => {
            if (competence.id === competence_id) {
                if (competence.params && competence.params.example_freetext) {
                    competence.params.example_freetext = value;
                    changed = true;
                }
            }
        });

        if (!changed) {
            newState.competences.forEach(competence => {
                if (competence.id === competence_id) {
                    if (!competence.params) {
                        competence.params = {};
                    }
                    Object.assign(competence.params, { example_freetext: value });
                }
            });
        }
        this.setState({ competences: newState.competences });
    }

    handleAdditionsInput = async (value, competence_id) => {
        let newState = { ...this.state };
        let changed = false;

        newState.competences.forEach(competence => {
            if (competence.params && competence.id === competence_id) {
                if (competence.params.additions_freetext) {
                    competence.params.additions_freetext = value;
                    changed = true;
                }
            }
        });

        if (!changed) {
            newState.competences.forEach(competence => {
                if (competence.id === competence_id) {
                    if (!competence.params) {
                        competence.params = {};
                    }
                    Object.assign(competence.params, { additions_freetext: value });
                }
            });
        }

        this.setState({ competences: newState.competences });
    };

    save = () => {
        this.props.dispatchCreateUsersCompetences(this.props.auth.token, this.props.executionId, this.state.competences);
        // this.props.dispatchCreateUsersCompetences(this.props.auth.token, 774, this.state.competences);
    };

    handleSave = () => {
        // let allSelected = true;
        // this.state.competences.forEach(competence => {
        //     competence.assessments.forEach(assessment => {
        //         if (!assessment.params) {
        //             allSelected = false;
        //         }
        //     });
        // });

        // // check if all elements are selected
        // if (allSelected) {
        //     // this.toggleConfirm();
        //     this.save();
        // } else {
        //     this.setState({ alert: { visible: true, textId: 'selfassessment.alert.notallselect', color: 'danger' } });
        //     setTimeout(this.handleHideAlert, config.alertTimeOut);
        // }

        this.save();
    };

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    };
    toggleNote = (e) => {
        this.setState({
            note: !this.state.note
        });
    };

    render() {
        if (this.props.executionId === -1 && !this.props.loading) {
            return (
                <Container fluid role="main" className="content-margin p-3">
                    <Container fluid className="p-0 fw-bold">
                        <h1><FormattedMessage id="selfassessmentkompog.label.selfassessment" /></h1>

                        {/* Select Execution */}
                        <Row>
                            <Col xs="12" sm="6" lg="6" xl="3">
                                <ExecutionSelect className="mb-3 p-0" executionId={this.props.executions_id} past={true} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            );
        } else {
            return (
                <Container fluid role="main" className="content-margin">

                    {/* Confirm save */}
                    <Confirm cancel save={this.save} dontsave={this.toggleConfirm} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="selfassessment.confirm.body.save" />

                    {/* Confirm note */}
                    <Confirm no={this.toggleNote} yes={this.goToNextPage.bind(this)} open={this.state.note} toggle={this.toggleNote} headertext="confirm.header.note" bodytext="selfassessment.confirm.body.note" />

                    {/* Alert */}
                    <Alert isOpen={this.state.alert.visible} toggle={this.handleHideAlert} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                    <h1><FormattedMessage id="selfassessmentkompog.label.selfassessment" /></h1>

                    <Container fluid className="sticky2 bg-white m-0 th-assessment-container px-3">
                        <Row className="p-0 mb-4">
                            {/* <Col xs="12" sm="6" className="p-0">
                                <FormattedMessage id="motivationExercise.task" values={{br: <br />}} />  
                            </Col> */}
                            {/* <Col xs="12" sm="6" className="p-0">
                                {/* PDF */}
                            {/* <Container fluid className="p-0 mb-4 m-0"> */}
                            <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this)} />
                            {/* </Container> */}
                            {/* </Col> */}
                            {/* <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind()} /> */}
                        </Row>
                        {/* Table Head */}
                        <Row>
                            <Col xs="2" md="1" className="border py-2 fw-bold text-ellipse">
                                <FormattedMessage id="selfassessmentkompog.label.competence" />
                            </Col>
                            <Col xs="6" sm="5" md="5"  >
                                <Row className="h-100">
                                    <Col xs="6" sm="6" md="6" className="border border-start-0 py-2 fw-bold text-ellipse">
                                        <FormattedMessage id="selfassessmentkompog.label.selfassessment-th" />
                                    </Col>
                                    <Col xs sm md className="th-competence-container border border-start-0 py-2 fw-bold ">
                                        <FormattedMessage id="selfassessmentkompog.label.plusplus" />
                                    </Col>
                                    <Col xs sm md className="th-competence-container border border-start-0 py-2 fw-bold">
                                        <FormattedMessage id="selfassessmentkompog.label.plus" />
                                    </Col>
                                    <Col xs sm md className="th-competence-container border border-start-0 py-2 fw-bold">
                                        <FormattedMessage id="selfassessmentkompog.label.minus" />
                                    </Col>
                                    <Col xs sm md className="th-competence-container border border-start-0 py-2 fw-bold">
                                        <FormattedMessage id="selfassessmentkompog.label.minusminus" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="2" md="3" className="border border-start-0 py-2 fw-bold text-ellipse">
                                <FormattedMessage id="selfassessmentkompog.label.example" />
                            </Col>
                            <Col xs="2" md="3" className="border border-start-0 py-2 fw-bold text-ellipse">
                                <FormattedMessage id="selfassessmentkompog.label.additions" />
                            </Col>
                        </Row>
                    </Container>
                    {/* Table body */}
                    {!this.props.loading ?
                        <Container fluid className="mb-4 th-assessment-container px-3">
                            {
                                this.props.competences.map((trData, trIndex) => {
                                    return <Row key={trIndex} className="row-striped h-100">
                                        <Col xs="2" md="1" className="border py-2 competence-kompog-table-body-label">
                                            <p className="competence-kompog-table-body-label-p"><FormattedMessage id={trData.competence} /></p>
                                        </Col>

                                        <Col xs="6" sm="5" md="5" className="border-start-0 th-assessment-column">
                                            {
                                                trData.assessments.map((trData2, trIndex2) => {
                                                    return <Row key={trIndex2} className="innerRow">
                                                        <Col xs="6" sm="6" md="6" className="border py-2 text-ellipse ">
                                                            <FormattedMessage id={trData2.assessment} />
                                                        </Col>
                                                        <Col xs sm md className="competences-td border py-2 ">
                                                            <Label className={"competences" + (!this.props.editable ? ' disabled' : '')} for={'1_' + trData2.id}>{<div className="d-none"><FormattedMessage id={trData.competence} /><FormattedMessage id="selfassessmentkompog.label.plusplus" /></div>}
                                                                <input type="checkbox" disabled={!this.props.editable || (this.props.result && this.props.result.releaseTeacher === 1)} checked={trData2.params.rating === 1} id={'1_' + trData2.id} name={trData2.id} value="1" onChange={this.handleCheckbox.bind(this, trData.id, trData2.id, 1)} />
                                                                <span className='checkmark'></span>
                                                            </Label>
                                                        </Col>
                                                        <Col xs sm md className="competences-td  border py-2 ">
                                                            <Label className={"competences" + (!this.props.editable ? ' disabled' : '')} for={'2_' + trData2.id}>{<div className="d-none"><FormattedMessage id={trData.competence} /><FormattedMessage id="selfassessmentkompog.label.plus" /></div>}
                                                                <input type="checkbox" disabled={!this.props.editable || (this.props.result && this.props.result.releaseTeacher === 1)} checked={trData2.params.rating === 2} id={'2_' + trData2.id} name={trData2.id} value="2" onChange={this.handleCheckbox.bind(this, trData.id, trData2.id, 2)} />
                                                                <span className='checkmark'></span>
                                                            </Label>
                                                        </Col>
                                                        <Col xs sm md className="competences-td border py-2 ">
                                                            <Label className={"competences" + (!this.props.editable ? ' disabled' : '')} for={'3_' + trData2.id}>{<div className='d-none'><FormattedMessage id={trData.competence} /><FormattedMessage id="selfassessmentkompog.label.minus" /></div>}
                                                                <input type="checkbox" disabled={!this.props.editable || (this.props.result && this.props.result.releaseTeacher === 1)} checked={trData2.params.rating === 3} id={'3_' + trData2.id} name={trData2.id} value="3" onChange={this.handleCheckbox.bind(this, trData.id, trData2.id, 3)} />
                                                                <span className='checkmark'></span>
                                                            </Label>
                                                        </Col>
                                                        <Col xs sm md className="competences-td border py-2 ">
                                                            <Label className={"competences" + (!this.props.editable ? ' disabled' : '')} for={'4_' + trData2.id}>{<div className='d-none'><FormattedMessage id={trData.competence} /><FormattedMessage id="selfassessmentkompog.label.minusminus" /></div>}
                                                                <input type="checkbox" disabled={!this.props.editable || (this.props.result && this.props.result.releaseTeacher === 1)} checked={trData2.params.rating === 4} id={'4_' + trData2.id} name={trData2.id} value="4" onChange={this.handleCheckbox.bind(this, trData.id, trData2.id, 4)} />
                                                                <span className='checkmark'></span>
                                                            </Label>
                                                        </Col>
                                                    </Row>
                                                })
                                            }
                                        </Col>

                                        <Col xs="2" md="3" className="competences-td border">
                                            <Input
                                                type="textarea"
                                                innerRef="exampleFreetext"
                                                ref="exampleFreetext"
                                                disabled={!(this.props.editable) || (this.props.result && this.props.result.releaseTeacher === 1)}
                                                name="exampleFreetext"
                                                id="exampleFreetext"
                                                placeholder={this.props.intl.formatMessage({ id: "selfassessmentkompog.task.school" })}
                                                style={{ height: "100%", width: "100%", border: "none", backgroundColor: "transparent", resize: "none" }}
                                                value={trData.params.example_freetext}
                                                onChange={(e) => { this.handleExampleInput(e.target.value, trData.id) }}

                                            />
                                        </Col>

                                        <Col xs="2" md="3" className="competences-td border">
                                            <Input
                                                type="textarea"
                                                innerRef="additionsFreetext"
                                                ref="additionsFreetext"
                                                disabled={!(this.props.editable) || (this.props.result && this.props.result.releaseTeacher === 1)}
                                                name="additionsFreetext"
                                                id="additionsFreetext"
                                                placeholder={this.props.intl.formatMessage({ id: "selfassessmentkompog.task.team" })}
                                                style={{ height: "100%", width: "100%", border: "none", backgroundColor: "transparent", resize: "none" }}
                                                value={trData.params.additions_freetext}
                                                onChange={(e) => { this.handleAdditionsInput(e.target.value, trData.id) }}
                                            />
                                        </Col>
                                    </Row>
                                })
                            }
                        </Container>
                        : <Loading />}
                    <Container fluid className="p-0 mb-4 d-flex justify-content-end flex-row">
                        <Button color="primary" onClick={this.handleSave} disabled={!(this.props.editable && !this.props.saving) || (this.props.result && this.props.result.releaseTeacher === 1)}><FormattedMessage id="button.save" /></Button>
                    </Container>

                </Container>
            );
        }
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        competences: state.competences.competences,
        loading: state.competences.loading,
        rating: state.competences.rating,
        editable: state.competences.editable,
        modified: state.competences.modified,
        createStatus: state.competences.createStatus,
        saving: state.competences.saving,
        result: state.kompoGResult.result
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetCompetences: (token, executionId, order, desc, enabled) => {
            dispatch(competencesActions.getCompetencesKompog(token, executionId, null, null, order, desc, enabled));
        },
        dispatchCreateUsersCompetences: (token, executionId, competences) => {
            dispatch(competencesActions.createUsersCompetencesKompog(token, executionId, null, null, competences));
        },
        dispatchGetResult: (token, executionId) => {
            dispatch(kompoGResultActions.getResult(token, executionId, null, null));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SelfAssessmentPageKomPoG));
