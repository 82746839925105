import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardImgOverlay } from 'reactstrap';
import { Link } from 'react-router-dom';
import ImageHeader from '../components/ImageHeader';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cards: [
                {
                    link: "settings",
                    textId: "homepage.card.label.settings",
                    img: "images/homepage/Einstellungen.jpg",
                    imgaltId: "homepage.card.alt.settings"
                },
                {
                    link: "help",
                    textId: "homepage.card.label.help",
                    img: "images/homepage/Hilfe.jpg",
                    imgaltId: "homepage.card.alt.help"
                },
                {
                    link: "documents",
                    textId: "homepage.card.label.documents",
                    img: "images/homepage/Material.jpg",
                    imgaltId: "homepage.card.alt.documents"
                },
                {
                    link: "impressum",
                    textId: "homepage.card.label.impressum",
                    img: "images/homepage/Impressum.jpg",
                    imgaltId: "homepage.card.alt.impressum"
                },
                {
                    link: "sponsor",
                    textId: "homepage.card.label.sponsor",
                    img: "images/homepage/Foerderer.jpg",
                    imgaltId: "homepage.card.alt.sponsor"
                },
                {
                    link: "selfassessment",
                    textId: "homepage.card.label.self-assessment",
                    img: "images/homepage/Selbsteinschaetzung.jpg",
                    imgaltId: "homepage.card.alt.self-assessment"
                },
                {
                    link: "selfassessmentoverview",
                    textId: "homepage.card.label.self-assessment-overview",
                    img: "images/homepage/Selbsteinschaetzung.jpg",
                    imgaltId: "homepage.card.alt.self-assessment-overview"
                },
                {
                    link: "interesttest",
                    textId: "homepage.card.label.interest-test",
                    img: "images/homepage/Interessentest.jpg",
                    imgaltId: "homepage.card.alt.interest-test"
                },
                {
                    link: "skillsprofile",
                    textId: "homepage.card.label.skills-profile",
                    img: "images/homepage/Kompetenzprofil.jpg",
                    imgaltId: "homepage.card.alt.skills-profile"
                },
                {
                    link: "skillsprofilestudent",
                    textId: "homepage.card.label.skills-profile-student",
                    img: "images/homepage/Kompetenzprofil.jpg",
                    imgaltId: "homepage.card.alt.skills-profile-student"
                },
                {
                    link: "studentadministration",
                    textId: "homepage.card.label.student-administration",
                    img: "images/homepage/Schuelerverwaltung.jpg",
                    imgaltId: "homepage.card.alt.student-administration"
                },
                {
                    link: "observationsevaluations",
                    textId: "homepage.card.label.observations-evaluations",
                    img: "images/homepage/Beobachtungen.jpg",
                    imgaltId: "homepage.card.alt.observations-evaluations"
                },
                {
                    link: "useradministration",
                    textId: "homepage.card.label.user-administration",
                    img: "images/homepage/Benutzerverwaltung.jpg",
                    imgaltId: "homepage.card.alt.user-administration"
                },
                {
                    link: "kompo7rating",
                    textId: "homepage.card.label.kompo7-rating",
                    img: "images/homepage/KomPo7_Bewertung.jpg",
                    imgaltId: "homepage.card.alt.kompo7-rating"
                },
                {
                    link: "executions",
                    textId: "homepage.card.label.executions",
                    img: "images/homepage/Durchfuehrungen.jpg",
                    imgaltId: "homepage.card.alt.executions"
                },
                {
                    link: "statistic",
                    textId: "homepage.card.label.statistic",
                    img: "images/homepage/Statistik.jpg",
                    imgaltId: "homepage.card.alt.statistic"
                },
                {
                    link: "feedback",
                    textId: "homepage.card.label.feedback",
                    img: "images/homepage/Feedback.jpg",
                    imgaltId: "homepage.card.alt.feedback"
                },
                {
                    link: "results",
                    textId: "homepage.card.label.results",
                    img: "images/homepage/Ergebnisse.jpg",
                    imgaltId: "homepage.card.alt.results"
                },
                {
                    link: "resultsschool",
                    textId: "homepage.card.label.resultsschool",
                    img: "images/homepage/Ergebnisse.jpg",
                    imgaltId: "homepage.card.alt.resultsschool"
                },
                {
                    link: "school",
                    textId: "homepage.card.label.school",
                    img: "images/homepage/Schule.jpg",
                    imgaltId: "homepage.card.alt.school"
                },
                {
                    link: "schooladministration",
                    textId: "homepage.card.label.schooladministration",
                    img: "images/homepage/Schule.jpg",
                    imgaltId: "homepage.card.alt.schooladministration"
                },
                {
                    link: "inquirysend",
                    textId: "homepage.card.label.inquiry-send",
                    img: "images/homepage/Anfragen_ausgegangen.jpg",
                    imgaltId: "homepage.card.alt.inquiry-send"
                },
                {
                    link: "inquiryreceived",
                    textId: "homepage.card.label.inquiry-received",
                    img: "images/homepage/Anfragen_eingegangen.jpg",
                    imgaltId: "homepage.card.alt.inquiry-received"
                },
                {
                    link: "privacypolicy",
                    textId: "homepage.card.label.privacypolicy",
                    img: "images/homepage/Datenschutz.jpg",
                    imgaltId: "homepage.card.alt.privacypolicy"
                },
                {
                    link: "kompo7ratingresults",
                    textId: "homepage.card.label.kompo7-rating-results",
                    img: "images/homepage/KomPo7_Bewertung.jpg",
                    imgaltId: "homepage.card.alt.kompo7-rating-results"
                },
                {
                    link: "myresultspage",
                    textId: "homepage.card.label.myresultspage",
                    img: "images/homepage/kompog/Ergebnisse.jpg",
                    imgaltId: "homepage.card.alt.myresultspage"
                },
                {
                    link: "selfassessmentkompog",
                    textId: "homepage.card.label.selfassessmentkompog",
                    img: "images/homepage/kompog/Kompetenzen.jpg",
                    imgaltId: "homepage.card.alt.selfassessmentkompog"
                },
                {
                    link: "verbexercise",
                    textId: "homepage.card.label.verbexercise",
                    img: "images/homepage/kompog/Interessen.jpg",
                    imgaltId: "homepage.card.alt.verbexercise"
                },
                {
                    link: "motivationExercise",
                    textId: "homepage.card.label.motivationExercise",
                    img: "images/homepage/kompog/Motivationen.jpg",
                    imgaltId: "homepage.card.alt.motivationExercise"
                }
            ]
        }
    }

    render() {
        let lastvisit = localStorage.getItem('lastvisit') ? JSON.parse(localStorage.getItem('lastvisit')) : [];
        return (

            <Container fluid role="main" className="content-margin">
                <Row className="d-none d-md-block">
                    <Col xs="12" className="m-0">
                        <ImageHeader />
                    </Col>
                </Row>
                <h1 className="text-uppercase text-secondary my-3"><FormattedMessage id="homepage.title" /></h1>

                <Row>
                    {
                        lastvisit.map((lv) => {
                            return this.state.cards.map((card, index) => {                                
                                if (lv.toString() === ('#/' + card.link)) {
                                    return (
                                        <Col xs="12" sm="12" md="4" key={"card" + index}>
                                            <Card className="p-0 d-block">
                                                <Link to={card.link} name={card.link} className="homepage-card-link m-0 p-0" >
                                                    <CardImg className="m-0" src={require("../assets/" + card.img)} width="100%" height="auto" alt={this.props.intl.formatMessage({ id: card.imgaltId })} />
                                                    <CardImgOverlay className="p-0 d-flex flex-row align-items-end m-0">
                                                        <CardBody className="p-0 homepage-card-body">
                                                            <CardTitle className="p-0 py-3 m-0 text-uppercase text-center"><FormattedMessage id={card.textId} /><br /><div className="homepage-arrow">&rsaquo;</div></CardTitle>
                                                        </CardBody>
                                                    </CardImgOverlay>
                                                </Link>
                                            </Card>
                                        </Col>
                                    )
                                } else {
                                    return null;
                                }
    
                            })
                        }) 
                       
                    }
                </Row>

            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage));