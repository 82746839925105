import * as React from 'react';
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Form, Input, Label, Collapse } from 'reactstrap';
import { isEquivalent, replaceQuotationMarks, getCaretPos, setCaretPos } from '../utils/Tools';
import Confirm from './Confirm';
import { schoolsService } from '../services';
import config from '../config/config';
import MultipleSelect from './MultipleSelect';
import moment from 'moment';
import DisplaySchool from './DisplaySchool';
import SchoolyearsSelect from './SchoolyearsSelect';
import { schoolsActions } from '../actions';

class InquiryModal extends React.Component {

    static propTypes = {
        inquiry: PropTypes.object,
        schoolId: PropTypes.number,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        save: PropTypes.func,
        edit: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            confirmReject: false,
            confirmAccept: false,
            inquiry: {},
            open: false,
            multipleObserver: [],
            multipleStudents: [],
            collapse: true,
            textReceiver: '',
            schoolyearsId: -1
        }

    }

    componentDidMount() {
        this.props.dispatchGetSchoolyears(this.props.auth.token);
        this.getObservers();
        this.getStudents()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inquiry !== this.props.inquiry && this.props.inquiry) {
            let inq = JSON.parse(JSON.stringify(this.props.inquiry));
            this.setState({ inquiry: inq });
            this.getObservers();
            this.getStudents()
        }
        if (this.props.schoolId && prevProps.schoolId !== this.props.schoolId) {
            this.setState({ schoolId: this.props.schoolId });
            this.getObservers();
            this.getStudents()
        }
    }

    async getObservers() {
        if (this.props.schoolId) {
            let data = await schoolsService.getSchoolsTeacher(this.props.auth.token, this.props.schoolId);
            if (data && data.data) {
                let multipleObserver = [];
                data.data.forEach((observer) => {
                    multipleObserver.push({ id: observer.id, text: observer.lastname + ", " + observer.firstname })
                });
                this.setState({ multipleObserver: multipleObserver })
            }
        }
    }

    async getStudents() {
        if (this.props.schoolId) {

            let data = await schoolsService.getSchoolsStudents(this.props.auth.token, this.props.schoolId, null, null, null, null, null, [{ isNotInOtherExecution: this.state.inquiry && this.state.inquiry.execution ? this.state.inquiry.execution.id : null }]);
            if (data && data.data) {
                let multipleStudents = [];
                data.data.forEach((student) => {
                    multipleStudents.push({ id: student.id, text: student.lastname + ", " + student.firstname + " (" + student.class + ")", schoolyears: student.schoolyears })
                });
                this.setState({ multipleStudents: multipleStudents })
            } else {
                this.setState({ multipleStudents: [] })
            }
        }
    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            if (this.props.inquiry && !isEquivalent(this.props.inquiry, this.state.inquiry)) {
                this.toggleConfirm();
            } else {
                this.props.toggle();
            }
        } else if (type === "accept") {
            this.toggleConfirmAccept();
        } else if (type === "reject") {
            this.toggleConfirmReject();
        }
    }

    getMultipleStudents() {
        let students = [];

        /* filter selected students and schoolyears */
        students = this.state.multipleStudents.filter((student) => {

            /* users is selected  */
            if (this.props.inquiry && this.state.inquiry.students && this.state.inquiry.students.indexOf(student.id) > -1) {
                return true;
            }

            /* user is in select schoolyear orr schoolyear is not selected */
            if ((this.props.inquiry && this.state.inquiry.students && this.state.inquiry.students.indexOf(student.id) === -1 && student.schoolyears && student.schoolyears.id === this.state.schoolyearsId) || this.state.schoolyearsId === -1) {
                return true;
            }

            return false;
        });
        return students;

    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }
    toggleConfirmReject = (e) => {
        this.setState({
            confirmReject: !this.state.confirmReject
        });
    }
    toggleConfirmAccept = (e) => {
        this.setState({
            confirmAccept: !this.state.confirmAccept
        });
    }

    save = (e) => {
        this.props.save(this.state.inquiry);
    }

    setSelectedObserver = (selected) => {
        let inquiry = this.state.inquiry;
        inquiry.observer = selected;
        this.setState({ inquiry: inquiry });
    }
    setSelectedStudents = (selected) => {
        let inquiry = this.state.inquiry;
        inquiry.students = selected;
        this.setState({ inquiry: inquiry });
    }

    reject = async (e) => {
        let inquiry = this.state.inquiry;
        inquiry.rejected = new moment().format();
        await this.setState({ inquiry: inquiry });
        this.props.save(this.state.inquiry);
    }

    handleCollapse = (e) => {
        this.setState({ collapse: !this.state.collapse })
    }

    handleTextReceiver = async (e) => {
        let inquiry = this.state.inquiry;
        inquiry.text_receiver = replaceQuotationMarks(e.target.value);
        const cp = getCaretPos(e.target);
        const domEle = e.target;
        await this.setState({ inquiry: inquiry }, () => { setCaretPos(domEle, cp); });
    }

    handleSchoolyears = async (schoolyear) => {
        this.setState({ schoolyearsId: schoolyear.id });
    }

    render() {

        let startdatetime = this.state.inquiry.execution && this.state.inquiry.execution.start ? moment(this.state.inquiry.execution.start.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let enddatetime = this.state.inquiry.execution && this.state.inquiry.execution.end ? moment(this.state.inquiry.execution.end.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let senddatetime = this.state.inquiry.send && this.state.inquiry.send ? moment(this.state.inquiry.send.replace(" ", "T"), config.dateFormat.format, true) : moment();
        let rejecteddatetime = this.state.inquiry.rejected && this.state.inquiry.rejected ? moment(this.state.inquiry.rejected.replace(" ", "T"), config.dateFormat.format, true) : null;
        let replydatetime = this.state.inquiry.reply && this.state.inquiry.reply ? moment(this.state.inquiry.reply.replace(" ", "T"), config.dateFormat.format, true) : null;

        return (
            <Container>
                <Confirm cancel yes={this.reject} no={this.props.toggleConfirmReject} open={this.state.confirmReject} toggle={this.toggleConfirmReject} headertext="confirm.header.inquiryreject" bodytext="confirm.body.inquiryreject" />
                <Confirm cancel yes={this.save} no={this.props.toggleConfirmAccept} open={this.state.confirmAccept} toggle={this.toggleConfirmAccept} headertext="confirm.header.inquiryaccept" bodytext="confirm.body.inquiryaccept" />
                <Form>
                    <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                        <ModalHeader className={this.props.inquiry && "edit"}><FormattedMessage id={(this.props.inquiry && this.props.inquiry.editable) ? "inquirymodal.title.edit" : "inquirymodal.title.inquiry"} /></ModalHeader>

                        <ModalBody>
                            <Container fluid className="px-3">
                                <Row>
                                    <Col className="my-0 py-0 p-1">
                                        <Label className="d-none" for="collapse"><FormattedMessage id={this.state.collapse ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse" color="link" className="btn-collapse m-0 p-0" onClick={this.handleCollapse} ><FormattedMessage id="inquirymodal.collapse.text" /><span className={this.state.collapse ? "collapse-open ms-2" : "collapse-close ms-2"}></span></Button>
                                    </Col>
                                </Row>
                            </Container>
                            <Collapse className="m-0 p-0 px-3" isOpen={this.state.collapse}>
                                <Container fluid className="pb-3">
                                    {/* School */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirymodal.label.school" />
                                        </Col>
                                        <Col className="p-1">
                                            <DisplaySchool school={this.state.inquiry.school} name="im" />
                                        </Col>
                                    </Row>
                                    {/* start- enddate */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirymodal.label.startdate" />
                                        </Col>
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={startdatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={startdatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirymodal.label.enddate" />
                                        </Col>
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={enddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={enddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                    </Row>
                                    {/* send */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                            <FormattedMessage id="inquirymodal.label.inquirysenton" />
                                        </Col>
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={senddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={senddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>

                                    </Row>
                                    {/* needed observer */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="6" sm="6" md="6">
                                            <FormattedMessage id="inquirymodal.label.neededobserver" />
                                        </Col>
                                        <Col className="p-1">
                                            {this.state.inquiry.neededobserver}
                                        </Col>
                                    </Row>
                                    {/* message */}
                                    <Row>
                                        <Col className="p-1 fw-bold" xs="12" sm="12" md="12">
                                            <FormattedMessage id="inquirymodal.label.message" />
                                        </Col>
                                        <Col className="p-1" xs="12">
                                            <Container fluid className="container-text-scroll-y m-0 py-1 px-0">
                                                {this.state.inquiry.text}
                                            </Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </Collapse>

                            <Container fluid className="border-top px-3">
                                <Row>
                                    <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                        <FormattedMessage id="inquirymodal.label.inquiryrejectedon" />
                                    </Col>
                                    {rejecteddatetime ?
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={rejecteddatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={rejecteddatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                        :
                                        <Col className="p-1">-</Col>}
                                    <Col className="p-1 fw-bold" xs="6" sm="6" md="3">
                                        <FormattedMessage id="inquirymodal.label.inquiryaccepted" />
                                    </Col>
                                    {replydatetime ?
                                        <Col className="p-1" xs="6" sm="6" md="3">
                                            <FormattedDate value={replydatetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={replydatetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} />
                                        </Col>
                                        :
                                        <Col className="p-1">-</Col>}
                                </Row>

                                {/* schoolyears */}
                                <Row>
                                    <Col xs="12" md="6" className="border-top p-1 d-flex">
                                        <Label for="schoolyears" className="d-block my-auto">
                                            <FormattedMessage id="executiongroupmodal.label.schoolyears" />
                                        </Label>
                                    </Col>
                                    <Col xs="12" md="6" className="border-top p-1">
                                        <SchoolyearsSelect className="m-0 p-0" name="schoolyears" withEmpty schoolyears={this.props.schoolyears} schoolyearsId={this.state.schoolyearsId} handleSchoolyears={this.handleSchoolyears} />
                                    </Col>
                                </Row>

                                {/* observer students */}
                                <Row className="border-top">
                                    <Col xs="12" md="6">
                                        <Row>
                                            <Col className="p-1" xs="12"><FormattedMessage id="inquirymodal.label.observer" /></Col>
                                            <Col className="p-1" xs="12">
                                                <MultipleSelect className="p-0" index="observer" elements={this.state.multipleObserver} key="observer" selectedElements={this.state.inquiry.observer} setSelected={this.setSelectedObserver} disabled={!(this.props.inquiry && this.props.inquiry.editable)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="12" md="6">
                                        <Row>
                                            <Col className="p-1" xs="12"><FormattedMessage id="inquirymodal.label.students" /></Col>
                                            <Col className="p-1" xs="12">
                                                <MultipleSelect className="p-0" index="students" elements={this.getMultipleStudents()} key="students" selectedElements={this.state.inquiry.students} setSelected={this.setSelectedStudents} disabled={!(this.props.inquiry && this.props.inquiry.editable)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* my message */}
                                <Row className="border-top">
                                    <Col className="p-1 fw-bold" xs="12" sm="12" md="12">
                                        <FormattedMessage id="inquirymodal.label.mymessage" />
                                    </Col>
                                    <Col className="p-1" xs="12">
                                        <Label className="fw-bold d-none" for="text"><FormattedMessage id="inquirymodal.label.mymessage" /> </Label>
                                        <Input type="textarea" name="text" id="text" value={this.state.inquiry.text_receiver} onChange={this.handleTextReceiver.bind(this)} disabled={this.props.inquiry && !this.props.inquiry.editable} />
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                        {this.props.inquiry && this.props.inquiry.editable ?
                            <ModalFooter>
                                <Button type="submit" name="reject" color="primary" onClick={this.handleSubmit.bind(this, "reject")}><FormattedMessage id="button.reject" /></Button>
                                <Button type="submit" name="accept" color="primary" onClick={this.handleSubmit.bind(this, "accept")}><FormattedMessage id="button.accept" /></Button>
                                <Button type="submit" name="close" color="primary" onClick={this.props.toggle}><FormattedMessage id="button.close" /></Button>
                            </ModalFooter>
                            :
                            <ModalFooter>
                                <Button type="submit" name="close" color="primary" onClick={this.props.toggle}><FormattedMessage id="button.close" /></Button>
                            </ModalFooter>
                        }

                    </Modal>
                </Form>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolyears: state.schools.schoolyears,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolyears: (token) => {
            dispatch(schoolsActions.getSchoolyears(token, "start", false));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(InquiryModal));