import * as React from 'react';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Col, Row, Label } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

class PrivacypolicyModal extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
        handlePrivacypolicy: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            accept: false,
        }
    }



    handlePrivacypolicy(e) {
        this.props.handlePrivacypolicy(this.state.accept);
    }

    handleAccept(e) {
        this.setState({ accept: e.target.checked })
    }

    render() {
        if (this.props.setting === null) {
            return null;
        }

        return (
            <Container>
                <Modal isOpen={this.props.open} size="lg" backdrop={'static'} className="modal-height-100 modal-width-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.props.open} >
                    <ModalHeader><FormattedMessage id="privacypolicy.title" /></ModalHeader>
                    <ModalBody className="my-0 py-0">
                        <Row className="my-0 py-0">
                            <Col className="pravicypolicy-container px-3">
                                <p>Willkommen in der <i>KomPo</i>-App!<br /><br />

                                    Die Kompetenzfeststellung <i>KomPo</i> wird mit Hilfe einer webbasierten Software durchgeführt, die personenbezogene Daten verarbeitet. Im Vorfeld der Nutzung der <i>KomPo</i>-App wurden Sie darüber informiert, welche Daten dies genau sind. Es wird sichergestellt, dass nur Sie sowie die unmittelbar an der Kompetenzfeststellung Beteiligten Zugriff auf die erhobenen Daten haben. Die Verarbeitung und Weitergabe der Daten erfolgt verschlüsselt.
                                    Gemeinsam verantwortlich für die Datenverarbeitung sind das Hessische Ministerium für Kultus, Bildung und Chancen und die jeweilige Schule. Den behördlichen Datenschutzbeauftragten des Hessischen Ministeriums für Kultus, Bildung und Chancen erreichen Sie über Hessisches Ministerium für Kultus, Bildung und Chancen, Datenschutzbeauftragter, Luisenplatz&nbsp;10, 65185&nbsp;Wiesbaden, E-Mail:&nbsp;<a href="mailto:datenschutzbeauftragter.hkm@kultus.hessen.de">datenschutzbeauftragter.hkm@kultus.hessen.de</a>. Sie haben jederzeit das Recht, Auskunft über Ihre gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung falscher Daten, die Einschränkung der Bearbeitung von Daten, deren Richtigkeit unklar ist, ggf. die Datenübertragbarkeit oder die Löschung dieser Daten zu verlangen.
                                </p>
                                <div className="pravicypolicy-box">Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Dies ist der Hessische Beauftragte für Datenschutz und Informationsfreiheit, Gustav-Stresemann-Ring 1, 65189&nbsp;Wiesbaden, Tel.:&nbsp;0611/1408-0, <a href="https://datenschutz.hessen.de" target="blank">https://datenschutz.hessen.de</a></div>
                                <div className="d-flex px-3">
                                    <Input type="checkbox" name="accept" onChange={this.handleAccept.bind(this)} />
                                    <Label for="accept">Hiermit erkläre ich mein Einverständnis für die Verarbeitung der Daten im Rahmen der Kompetenzfeststellung mit der Software <i>KomPo</i>. Diese Einwilligung erfolgt freiwillig und kann jederzeit widerrufen werden, ohne dass davon die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung berührt wird. Erfolgt ein Widerruf, werden die digitalen Daten gelöscht und die Kompetenzfeststellung erfolgt analog.</Label>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={this.handlePrivacypolicy.bind(this)}><FormattedMessage id="button.cancel" /></Button>
                        <Button color="primary" onClick={this.handlePrivacypolicy.bind(this)} disabled={!this.state.accept}><FormattedMessage id="button.accept" /></Button>
                    </ModalFooter>
                </Modal>
            </Container >
        );
    }
}

export default injectIntl(PrivacypolicyModal);