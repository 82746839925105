import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class DisplaySchoolyears extends React.Component {

    static propTypes = {
        schoolyears: PropTypes.object,
        short: PropTypes.bool
    }

    static defaultProps = {
        short: true
    }

    render() {
        if (this.props.schoolyears) {
            return (
                this.props.short
                    ?
                    (this.props.schoolyears.start - 2000) + " / " + (this.props.schoolyears.end - 2000)
                    :
                    this.props.schoolyears.start + " / " + this.props.schoolyears.end

            );
        }
        return null;
    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplaySchoolyears));