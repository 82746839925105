import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import config from '../config/config'

import HomePage from './HomePage';
import HelpPage from './HelpPage';
import DocumentsPage from './DocumentsPage';
import ExecutionsPage from './ExecutionsPage';
import ImpressumPage from './ImpressumPage';
import InterestTestPage from './InterestTestPage';
import SchoolAdministrationPage from './SchoolAdministrationPage';
import SelfAssessmentPage from './SelfAssessmentPage';
import SelfAssessmentPageKomPoG from './SelfAssessmentPageKomPoG';
import SettingsPage from './SettingsPage';
import SkillsProfilePage from './SkillsProfilePage';
import SkillsProfileStudentPage from './SkillsProfileStudentPage';
import StatisticPage from './StatisticPage';
import StudentAdministrationPage from './StudentAdministrationPage';
import UserAdministrationPage from './UserAdministrationPage';
import Kompo7RatingPage from './Kompo7RatingPage';
import FeedbackPage from './FeedbackPage';
import ResultsPage from './ResultsPage';
import ResultsSchoolPage from './ResultsSchoolPage';
import SchoolPage from './SchoolPage';
import InquiryReceivedPage from './InquiryReceivedPage';
import InquirySendPage from './InquirySendPage';
import SponsorPage from './SponsorPage';
import ObservationsEvaluationsPage from './ObservationsEvaluationsPage';
import SelfAssessmentOverviewPage from './SelfAssessmentOverviewPage';
import InterestTestOverviewPage from './InterestTestOverviewPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import Kompo7RatingResultsPage from './Kompo7RatingResultsPage';
import VerbExercisePage from './VerbExercisePage';
import MotivationExercise from './MotivationExercise';
import MyResultsPage from './MyResultsPage';

import Menu from '../components/Menu';
import UserProfile from '../components/UserProfile';
import Authorization from '../components/Authorization';
import { CheckAuthorization } from '../models/Authorization';

class MainPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locationChanged: true,
            collapsedmenu: true,
        };

        // add a handler to the hashchange event, to kick off a re-render of the menu
        // if the routing location changed
        this.hashLocationChange = this.hashLocationChange.bind(this);
        window.addEventListener('hashchange', this.hashLocationChange);

        this.addHashVisited();
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.hashLocationChange);
    }

    hashLocationChange() {
        // just toggle the locationChanged property, to force react
        // to re-render the menu
        this.setState({ locationChanged: !this.state.locationChanged });

        this.addHashVisited();
    }

    addHashVisited() {
        //add hash to last visit for homepage 
        let ignoreHash = ['#/home', '#/'];
        let lastvisit = localStorage.getItem('lastvisit') && localStorage.getItem('lastvisituser') && localStorage.getItem('lastvisituser') === this.props.auth.personalnumber ? JSON.parse(localStorage.getItem('lastvisit')) : ['#/settings', '#/help', '#/privacypolicy'];

        if (ignoreHash.indexOf(document.location.hash) === -1 && lastvisit.indexOf(document.location.hash) === -1) {
            //add to the beginning of the array
            lastvisit.unshift(document.location.hash);
            lastvisit.pop();
        } else if (lastvisit.indexOf(document.location.hash) !== -1) {
            //move to the beginning of the array
            lastvisit.splice(lastvisit.indexOf(document.location.hash), 1);
            lastvisit.unshift(document.location.hash);
        }

        localStorage.setItem('lastvisituser', this.props.auth.personalnumber);
        localStorage.setItem('lastvisit', JSON.stringify(lastvisit));
    }

    getActiveClass(page) {
        let hashIndex = window.location.href.indexOf('#');
        let currPage = window.location.href.substr(hashIndex + 2);
        if (currPage === '') currPage = 'home';
        let activeClass = '';
        if (currPage === page)
            activeClass = 'active';
        return 'px-0 py-0 mx-4 ' + activeClass;
    }

    toggleMenu = () => {
        this.setState({ collapsedmenu: !this.state.collapsedmenu });
    }

    render() {
        /**
         * check authorization for Redirect tag
         * when link is not authorized (e.g. at login) route to home page
         */
        let checkarray = ['/', '/home', '/help', '/settings', '/impressum', '/privacypolicy', '/sponsor'];
        let check = this.props.auth && checkarray.findIndex(e => e === this.props.location.pathname) === -1
            ? !CheckAuthorization(this.props.location.pathname, this.props.auth.roles, this.props.auth.procedure) : false;
        return (
            <Container fluid className="mh-100 mx-auto px-0 py-0 m-0">

                {/* absolute menu button */}
                <div className={this.state.collapsedmenu ? "d-none" : "absolute-menu"} onClick={this.toggleMenu}>
                    <div className="menu-toggler mx-2"></div>
                </div>

                <Row className="g-0 header bg-white">
                    <Col xs="12" sm="4" md="4" lg="3" xl="2" className="px-0 py-2 m-0 h-75 bg-white d-block logo">
                        {/* <img src={require('../assets/images/KomPo7_logo.png')} alt={this.props.intl.formatMessage({ id: "main.alt.logo-kompo7" })} className="logo" /> */}
                    </Col>
                    <Col xs="12" sm="8" md="8" lg="9" xl="10" className="px-3 py-0 my-auto h-100 bg-white">
                        <UserProfile />
                    </Col>
                </Row>
                <Row className="g-0">
                    {/* fix menu */}
                    <Col xs="12" sm="4" md="4" lg="3" xl="2" className={this.state.collapsedmenu ? "m-0 px-0 py-0 d-flex left-content-direction menu " : "d-none"} >
                        <Menu locationChanged={this.state.locationChanged} toggle={this.toggleMenu} />
                    </Col>
                    <Col xs="12" sm={this.state.collapsedmenu ? "8" : "12"} md={this.state.collapsedmenu ? "8" : "12"} lg={this.state.collapsedmenu ? "9" : "12"} xl={this.state.collapsedmenu ? "10" : "12"} className={this.state.collapsedmenu ? "px-0 py-0 px-sm-3 m-0 content" : "py-0 pe-sm-3 m-0 content-left-padding"}>
                        <Route exact path='/' component={HomePage} />
                        <Route path='/home' component={HomePage} />
                        <Route path='/help' component={HelpPage} />
                        <Route path='/settings' component={SettingsPage} />
                        <Route path='/impressum' component={ImpressumPage} />
                        <Route path='/privacypolicy' component={PrivacyPolicyPage} />
                        <Route path='/sponsor' component={SponsorPage} />
                        <Authorization id="/executions" >
                            <Route path='/executions' component={ExecutionsPage} />
                        </Authorization>
                        <Authorization id="/interesttest" >
                            <Route path='/interesttest' component={InterestTestPage} />
                        </Authorization>
                        <Authorization id="/interesttestoverview" >
                            <Route path='/interesttestoverview' component={InterestTestOverviewPage} />
                        </Authorization>
                        <Authorization id="/kompo7rating" >
                            <Route path='/kompo7rating' component={Kompo7RatingPage} />
                        </Authorization>
                        <Authorization id="/kompo7ratingresults" >
                            <Route path='/kompo7ratingresults' component={Kompo7RatingResultsPage} />
                        </Authorization>
                        <Authorization id="/schooladministration" >
                            <Route path='/schooladministration' component={SchoolAdministrationPage} />
                        </Authorization>
                        <Authorization id="/selfassessment" >
                            <Route path='/selfassessment' component={SelfAssessmentPage} />
                        </Authorization>
                        <Authorization id="/selfassessmentkompog">
                            <Route path='/selfassessmentkompog' component={SelfAssessmentPageKomPoG} />
                        </Authorization>
                        <Authorization id="/motivationExercise">
                            <Route path='/motivationExercise' component={MotivationExercise} />
                        </Authorization>
                        <Authorization id="/myresultspage">
                            <Route path='/myresultspage' component={MyResultsPage} />
                        </Authorization>
                        <Authorization id="/verbexercise">
                            <Route path='/verbexercise' component={VerbExercisePage} />
                        </Authorization>
                        <Authorization id="/selfassessmentoverview" >
                            <Route path='/selfassessmentoverview' component={SelfAssessmentOverviewPage} />
                        </Authorization>
                        <Authorization id="/skillsprofile" >
                            <Route path='/skillsprofile' component={SkillsProfilePage} />
                        </Authorization>
                        <Authorization id="/skillsprofilestudent" >
                            <Route path='/skillsprofilestudent' component={SkillsProfileStudentPage} />
                        </Authorization>
                        <Authorization id="/statistic" >
                            <Route path='/statistic' component={StatisticPage} />
                        </Authorization>
                        <Authorization id="/studentadministration" >
                            <Route path='/studentadministration' component={StudentAdministrationPage} />
                        </Authorization>
                        <Authorization id="/useradministration" >
                            <Route path='/useradministration' component={UserAdministrationPage} />
                        </Authorization>
                        <Authorization id="/feedback" >
                            <Route path='/feedback' component={FeedbackPage} />
                        </Authorization>
                        <Authorization id="/observationsevaluations" >
                            <Route path='/observationsevaluations' component={ObservationsEvaluationsPage} />
                        </Authorization>
                        <Authorization id="/results" >
                            <Route path='/results' component={ResultsPage} />
                        </Authorization>
                        <Authorization id="/resultsschool" >
                            <Route path='/resultsschool' component={ResultsSchoolPage} />
                        </Authorization>
                        <Authorization id="/school" >
                            <Route path='/school' component={SchoolPage} />
                        </Authorization>
                        <Authorization id="/inquiryreceived" >
                            <Route path='/inquiryreceived' component={InquiryReceivedPage} />
                        </Authorization>
                        <Authorization id="/inquirysend" >
                            <Route path='/inquirysend' component={InquirySendPage} />
                        </Authorization>
                        <Authorization id="/documents" >
                            <Route path='/documents' component={DocumentsPage} />
                        </Authorization>
                        {check && <Redirect to="/" />}
                    </Col>
                </Row>

                <Row className="d-none d-sm-flex text-white py-2 px-0 bg-primary footer" role="contentinfo">
                    <Col xs="12" sm="12" md="2" className="small mx-2 my-auto">
                        Version: {config.version}
                    </Col>
                    <Col xs="12" sm="12" md="9" className="d-flex justify-content-center fw-bold small nav-item">
                        <div className={"footer-nav-link " + this.getActiveClass('home')}><Link to="home" name="home" ><FormattedMessage id="main.button.home" /></Link></div>
                        <div className={"footer-nav-link " + this.getActiveClass('settings')}><Link to="settings" name="settings" ><FormattedMessage id="main.button.settings" /></Link></div>
                        <div className={"footer-nav-link " + this.getActiveClass('help')}><Link to="help" name="help"><FormattedMessage id="main.button.help" /></Link></div>
                        <div className={"footer-nav-link " + this.getActiveClass('privacypolicy')}><Link to="privacypolicy" name="privacypolicy" ><FormattedMessage id="main.button.privacypolicy" /></Link></div>
                        <div className={"footer-nav-link " + this.getActiveClass('impressum')}><Link to="impressum" name="impressum"><FormattedMessage id="main.button.impressum" /></Link></div>
                        <div className={"footer-nav-link " + this.getActiveClass('sponsor')}><Link to="sponsor" name="sponsors" ><FormattedMessage id="main.button.sponsors" /></Link></div>
                    </Col>
                    <Col xs="12" sm="12" md="1">
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default injectIntl(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MainPage)));