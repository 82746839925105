import { observationsService } from '../services';
import { actionTypes } from './types';

export const observationsActions = {
    getObservation,
    createObservation,
    updateObservation
};

function getObservation(token, executionId, usersId, honorarkraftId) {
    return dispatch => {
        dispatch(request({ getObservation }));

        observationsService.getObservation(token, executionId, usersId, honorarkraftId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(observations) { return { type: actionTypes.OBSERVATIONS_REQUEST, observations } }
    function success(observations) { return { type: actionTypes.OBSERVATIONS_SUCCESS, observations } }
    function failure(error) { return { type: actionTypes.OBSERVATIONS_FAILURE, error } }
}


function createObservation(token, executionId, usersId, text) {
    return dispatch => {
        dispatch(request({ createObservation }));

        observationsService.createObservation(token, executionId, usersId, text)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.OBSERVATIONS_CREATE_USERS_REQUEST, status } }
    function success(status) { return { type: actionTypes.OBSERVATIONS_CREATE_USERS_SUCCESS, status } }
    function failure(status) { return { type: actionTypes.OBSERVATIONS_CREATE_USERS_FAILURE, status } }
}

function updateObservation(token, executionId, usersId, text) {
    return dispatch => {
        dispatch(request({ updateObservation }));

        observationsService.updateObservation(token, executionId, usersId, text)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.OBSERVATIONS_UPDATE_USERS_REQUEST, status } }
    function success(status) { return { type: actionTypes.OBSERVATIONS_UPDATE_USERS_SUCCESS, status } }
    function failure(status) { return { type: actionTypes.OBSERVATIONS_UPDATE_USERS_FAILURE, status } }
}
