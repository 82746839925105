import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
// import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
// import { addLocaleData } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';
// import deLocaleData from 'react-intl/locale-data/de';
// import enLocaleData from 'react-intl/locale-data/en';
import translationDE from './translations/de';
import translationEN from './translations/en';
import config from './config/config';
import App from './containers/App';
import configureStore from './store/configureStore';
import 'moment/locale/de';

import "./theme/style.css";

// addLocaleData([...deLocaleData, ...enLocaleData]);

let initialState = {};
switch (config.defaultLanguage) {
    case 'en':
        initialState.intl = translationEN;
        break;
    case 'de':
    default:
        initialState.intl = translationDE;
}

const store = configureStore(initialState);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <IntlProvider>
            <Router>
                <App />
            </Router>
        </IntlProvider>
    </Provider>
)