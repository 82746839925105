import { actionTypes } from './types';
import { motivationService } from '../services/motivationService';

export const motivationActions = {
    getMotivations,
    saveMotivations,
    saveResultMotivations
};

function getMotivations(token, executionId, studentsId, honorarkraftId) {
    return dispatch => {
        dispatch(request({getMotivations}));

        motivationService.getMotivations(token, executionId, studentsId, honorarkraftId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.MOTIVATIONS_REQUEST } }
    function success(motivationsPairs) { return { type: actionTypes.MOTIVATIONS_SUCCESS, motivationsPairs } }
    function failure(error) { return { type: actionTypes.MOTIVATIONS_FAILURE, error } }
}

function saveMotivations(token, executionId, motivationAnswers) {
    return dispatch => {
        dispatch(request({saveMotivations}));

        motivationService.saveMotivations(token, executionId, motivationAnswers)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) {return { type: actionTypes.MOTIVATIONS_SAVE_REQUEST, status }}
    function success(status) {return { type: actionTypes.MOTIVATIONS_SAVE_SUCCESS, status }}
    function failure(status) {return { type: actionTypes.MOTIVATIONS_SAVE_FAILURE, status }}
}

function saveResultMotivations(token, executionId, motivations) {
    return dispatch => {
        dispatch(request({saveResultMotivations}));

        motivationService.saveResultMotivations(token, executionId, motivations)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) {return { type: actionTypes.MOTIVATIONSRESULT_SAVE_REQUEST, status }}
    function success(status) {return { type: actionTypes.MOTIVATIONSRESULT_SAVE_SUCCESS, status }}
    function failure(status) {return { type: actionTypes.MOTIVATIONSRESULT_SAVE_FAILURE, status }}
}