import { actionTypes } from '../actions/types';

const initialState = {
    usersAddress: [],
    usersTitle: [],
    loading: false,
    loadingError: '',
    createError: '',
    updateError: '',
    deleteError: '',
    createUserState: 0,
    updateUserState: 0,
    deleteUserState: 0,
    roles: [],
    usersFunctions: [],
    users: [],
    total: 0,
    total_pages: 1,
    resetPwdState: 0,
    resetPwdError: '',
    resetPwdSuccessfulResetted: 0,
    resetPwdTotalUsers: 0, 
    resetPwdErrors: [],
    resetPwdPdfState: 0,
    resetPwdPdfStudents: [],
    resetPwdPdfExecution: null,
    resetPwdPdfKompoUrl: '',
    resetPwdPdfError: '',
    resetPwdUserPdfState: 0,
    resetPwdUserPdfUserdata: null,
    resetPwdUserPdfKompoUrl: '',
    resetPwdUserPdfError: '',
};

const executionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USERPROFILE_USERSTITLE_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.USERPROFILE_USERSTITLE_SUCCESS:
            return { 
                ...state,
                usersTitle: action.usersTitle ? action.usersTitle.data : [],
                loading: false
            };
        case actionTypes.USERPROFILE_USERSTITLE_FAILURE:
            return {
                ...state,
                usersTitle: [],   
                loading: false,
                loadingError: action.error,
            };        
        case actionTypes.USERPROFILE_USERSADDRESS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.USERPROFILE_USERSADDRESS_SUCCESS:
            return { 
                ...state,
                usersAddress: action.usersAddress ? action.usersAddress.data : [],
                loading: false
            };
        case actionTypes.USERPROFILE_USERSADDRESS_FAILURE:
            return {
                ...state,
                usersAddress: [],   
                loading: false,
                loadingError: action.error,
            };        
        case actionTypes.USERPROFILE_CREATE_USER_REQUEST:
            return {
                ...state,
                createUserState: 0,
                createError: '',
            };
        case actionTypes.USERPROFILE_CREATE_USER_SUCCESS:
            return {
                ...state,
                createUserState: 1,
            };
        case actionTypes.USERPROFILE_CREATE_USER_FAILURE:
            return {
                ...state,
                createUserState: 2,
                createError: action.error.error,
            };
        case actionTypes.USERPROFILE_UPDATE_USER_REQUEST:
            return {
                ...state,
                updateUserState: 0,
                updateError: '',
            };
        case actionTypes.USERPROFILE_UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateUserState: 1,

            };
        case actionTypes.USERPROFILE_UPDATE_USER_FAILURE:
            return {
                ...state,
                updateUserState: 2,
                updateError: action.error.error,
            };
        case actionTypes.USERPROFILE_DELETE_USER_REQUEST:
        case actionTypes.USERPROFILE_RESET_USER_REQUEST:
            return {
                ...state,
                deleteUserState: 0,
                updateError: '',
            };
        case actionTypes.USERPROFILE_DELETE_USER_SUCCESS:
        case actionTypes.USERPROFILE_RESET_USER_SUCCESS:
            return {
                ...state,
                deleteUserState: 1,
            };
        case actionTypes.USERPROFILE_DELETE_USER_FAILURE:
        case actionTypes.USERPROFILE_RESET_USER_FAILURE:
            return {
                ...state,
                deleteUserState: 2,
                updateError: action.error.error,
            };
        case actionTypes.USERPROFILE_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.USERPROFILE_ROLES_SUCCESS:
            return { 
                ...state,
                roles: action.roles ? action.roles.data : [],
                loading: false
            };
        case actionTypes.USERPROFILE_ROLES_FAILURE:
            return {
                ...state,
                roles: [],   
                loading: false,
                loadingError: action.error,
            };  
        case actionTypes.USERPROFILE_USERSFUNCTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.USERPROFILE_USERSFUNCTIONS_SUCCESS:
            return { 
                ...state,
                usersFunctions: action.usersfunctions ? action.usersfunctions.data : [],
                loading: false
            };
        case actionTypes.USERPROFILE_USERSFUNCTIONS_FAILURE:
            return {
                ...state,
                usersFunctions: [],   
                loading: false,
                loadingError: action.error,
            };  
        case actionTypes.USERPROFILE_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.USERPROFILE_USERS_SUCCESS:
            return { 
                ...state,
                users: action.users ? action.users.data : [],
                total: action.users ? action.users.total : 1,
                total_pages: action.users ? action.users.total_pages : 0,
                loading: false
            };
        case actionTypes.USERPROFILE_USERS_FAILURE:
            return {
                ...state,
                users: [],   
                total: action.users ? action.users.total : 1,
                total_pages: action.users ? action.users.total_pages : 0,
                loading: false,
                loadingError: action.error,
            };  
        case actionTypes.USERPROFILE_RESET_PWD_REQUEST:
            return {
                ...state,
                resetPwdState: 0,
                resetPwdSuccessfulResetted: 0,
                resetPwdTotalUsers: 0,
                resetPwdErrors: [],
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_SUCCESS:
            return {
                ...state,
                resetPwdState: 1,
                resetPwdSuccessfulResetted: action.response.successful_resetted,
                resetPwdTotalUsers: action.response.total_users,
                resetPwdErrors: action.response.errors,
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_FAILURE:
            return {
                ...state,
                resetPwdState: 2,
                resetPwdError: action.error,
                resetPwdSuccessfulResetted: 0,
                resetPwdTotalUsers: 0,
                resetPwdErrors: [],
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_PDF_REQUEST:
            return {
                ...state,
                resetPwdPdfState: 0,
                resetPwdPdfStudents: [],
                resetPwdPdfExecution: null,
                resetPwdPdfKompoUrl: '',
                resetPwdPdfError: '',
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_PDF_SUCCESS:
            return {
                ...state,
                resetPwdPdfState: 1,
                resetPwdPdfStudents: action.response ? action.response.students : [],
                resetPwdPdfExecution: action.response ? action.response.execution : null,
                resetPwdPdfKompoUrl: action.response ? action.response.kompo7_url : '',
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_PDF_FAILURE:
            return {
                ...state,
                resetPwdPdfState: 2,
                resetPwdPdfError: action.error,
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_USER_PDF_REQUEST:
            return {
                ...state,
                resetPwdUserPdfState: 0,
                resetPwdUserPdfUserdata: null,
                resetPwdUserPdfKompoUrl: '',
                resetPwdUserPdfError: '',
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_USER_PDF_SUCCESS:
            return {
                ...state,
                resetPwdUserPdfState: 1,
                resetPwdUserPdfUserdata: action.response ? action.response.user : null,
                resetPwdUserPdfKompoUrl: action.response ? action.response.kompo7_url : '',
            }; 
        case actionTypes.USERPROFILE_RESET_PWD_USER_PDF_FAILURE:
            return {
                ...state,
                resetPwdUserPdfState: 2,
                resetPwdUserPdfError: action.error,
            };
        default:
            return state;
    }
};

export default executionsReducer;
