import { kompo7RatingService } from '../services';
import { actionTypes } from './types';

export const kompo7RatingActions = {
    getRatingForUser,
    createUserRating,
    getRatingResults
};

function getRatingForUser(token, executionId) {
    return dispatch => {
        dispatch(request({ getRatingForUser }));

        kompo7RatingService.getRatingForUser(token, executionId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.KOMPO7RATING_REQUEST, status } }
    function success(questions) { return { type: actionTypes.KOMPO7RATING_SUCCESS, questions } }
    function failure(error) { return { type: actionTypes.KOMPO7RATING_FAILURE, error } }
}

function createUserRating(token, executionId, rating, ratingMessage) {
    return dispatch => {
        dispatch(request({ createUserRating }));

        kompo7RatingService.createUserRating(token, executionId, rating, ratingMessage)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.KOMPO7RATING_CREATE_REQUEST, status } }
    function success(status) { return { type: actionTypes.KOMPO7RATING_CREATE_SUCCESS, status } }
    function failure(status) { return { type: actionTypes.KOMPO7RATING_CREATE_FAILURE, status } }
}

function getRatingResults(token, filter) {
    return dispatch => {
        dispatch(request({ getRatingResults }));

        kompo7RatingService.getRatingResults(token, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(status) { return { type: actionTypes.KOMPO7RATING_RESULTS_REQUEST, status } }
    function success(results) { return { type: actionTypes.KOMPO7RATING_RESULTS_SUCCESS, results } }
    function failure(error) { return { type: actionTypes.KOMPO7RATING_RESULTS_FAILURE, error } }
}