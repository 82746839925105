import * as React from 'react';
import { Container, Label } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DisplaySchool from './DisplaySchool';

class MultipleSelect extends React.Component {

    static propTypes = {
        selectedElements: PropTypes.array, //[id]
        elements: PropTypes.array, //[{id:,text:}]
        setSelected: PropTypes.func,
        label: PropTypes.string,
        index: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number]).isRequired,
        disabled: PropTypes.bool
    };


    static defaultProps = {
        label: null,
    }

    constructor(props) {
        super(props);
        if (props.selectedElements) {
            this.state = { selectedElements: props.selectedElements };
        } else {
            this.state = { selectedElements: [] };
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedElements !== this.props.selectedElements) {
            this.setState({ selectedElements: this.props.selectedElements });
        }
    }

    handleSelected = (id, e) => {
        let newState = { ...this.state }
        newState.selectedElements.indexOf(id) >= 0 ? newState.selectedElements.splice(this.state.selectedElements.indexOf(id), 1) : newState.selectedElements.push(id);
        this.setState(newState);
        if (this.props.index != null) {
            this.props.setSelected(newState.selectedElements, this.props.index);
        } else {
            this.props.setSelected(newState.selectedElements);
        }
    }

    render() {
        return (
            <Container fluid className="p-0">
                {this.props.label && <Label for="multiple-select-container" className="m-0"><FormattedMessage id={this.props.label} /></Label>}
                <Container id="multiple-select-container" name="multiple-select-container" fluid className="d-flex mt-0 pt-0 flex-column rounded border multiple-select-container ">
                    <div className='multiple-content'> {/* the div is for as height problem in chrome */}
                        {
                            this.props.elements.map((el, index) => {
                                return (
                                    <div key={"ms-div-" + index} className="d-flex">
                                        <Label className="multiple-select" key={"select" + this.props.index + "_" + el.id + "_" + index} for={"select" + this.props.index + "_" + el.id + "_" + index}>
                                            <span>{el.text}</span>
                                            <input type="checkbox" disabled={this.props.disabled} defaultChecked={this.state.selectedElements.indexOf(el.id) >= 0} name={"select" + this.props.index + "_" + el.id + "_" + index} id={"select" + this.props.index + "_" + el.id + "_" + index} key={"select" + this.props.index + "_" + el.id + "_" + index} onClick={this.handleSelected.bind(this, el.id)} alt={el.text} />
                                            <div className="checkmark"></div>
                                        </Label>
                                        {el.school && <DisplaySchool placement="left" classNamePopover="multiple-select-popover-school" container="executionsgroupsmodal" school={el.school} withLabel={false} key={"MSPopover" + this.props.index + "-" + index} name={"MSPopover" + this.props.index + "-" + index} id={"MSPopover" + this.props.index + "-" + index}></DisplaySchool>}

                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                </Container>
                {/* x / y */}
                <Container fluid className="p-0 text-end small">{this.state.selectedElements.length + " / " + this.props.elements.length}</Container>

            </Container>
        );
    }
}

export default injectIntl(MultipleSelect);