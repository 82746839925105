import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Input, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Form, Alert, FormFeedback } from 'reactstrap';
import { isEquivalent } from '../utils/Tools';
import Confirm from './Confirm';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import moment from 'moment';
import Authorization from '../components/Authorization';
import { CheckAuthorization } from '../models/Authorization';
import SchoolSelect from './SchoolSelect';
import config from '../config/config';
import { schoolsActions, executionsActions, filterActions } from '../actions';
import cloneDeep from 'lodash/cloneDeep';
import { authorizationRoles } from '../config/authorization';
import ProcedureSelect from '../components/ProcedureSelect';

import 'react-datepicker/dist/react-datepicker.css';
registerLocale('de', de);
class ExecutionsModal extends React.Component {

    static propTypes = {
        dispatchGetSchoolsForUser: PropTypes.func.isRequired,
        dispatchGetSchoolsContactPerson: PropTypes.func.isRequired,
        execution: PropTypes.object,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        save: PropTypes.func,
        edit: PropTypes.func,
        procedureId: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            execution: { id: null, school: { id: -1 }, start: (moment({ days: moment().date(), hour: 0, minute: 0 })).toDate(), end: (moment({ day: moment().date(), hour: 23, minute: 59 })).toDate(), contactperson: { id: 0 }, executionsstatus: { id: 0 }, cooperationtype: { id: 0 }, procedureId: 0 },
            invalid: { date: false, contactperson: false, procedure: false },
            open: false,
            schools: [],
            schoolscontactperson: [],
            executionsstatus: [],
            cooperationtype: [],
            alertVisible: false,
            new: false,
        }
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount() {
        this.props.dispatchGetExecutionsstatus(this.props.auth.token);
        this.props.dispatchGetCooperationtype(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {

        if (this.props.execution && prevProps.execution !== this.props.execution) {
            let ex = JSON.parse(JSON.stringify(this.props.execution));
            ex.start = (moment(this.props.execution.start)).toDate();
            ex.end = (moment(this.props.execution.end)).toDate();
            this.setState({ execution: ex });
            this.props.dispatchGetSchoolsForUser(this.props.auth.token);
            this.getSchoolsContactPerson(ex.procedure.id, ex.school.id);
        } else if (this.props.execution === null && (!this.state.new || this.props.open !== prevProps.open)) {
            let procedure = this.props.procedureId;
            let school = this.props.schools.find(s => s.id === this.props.schoolId);
            if (school !== undefined && school.procedures && school.procedures.length === 1) {
                procedure = school.procedures[0].id;
            } else if (this.props.schools.length > 1 && this.props.schools[0].procedures && this.props.schools[0].procedures.length === 1) {
                procedure = this.props.schools[0].procedures[0].id;
            }
            this.setState({
                new: true, execution: {
                    id: null,
                    editable: true,
                    school: school !== undefined ? school : { id: this.props.schoolId },
                    start: (moment({ day: moment().date(), hour: 0, minute: 0 })).toDate(),
                    end: (moment({ day: moment().date(), hour: 23, minute: 59 })).toDate(),
                    contactperson: { id: 0 },
                    executionsstatus: { id: 0 },
                    cooperationtype: { id: 0 },
                    procedureId: procedure
                }
            });
            this.props.dispatchGetSchoolsForUser(this.props.auth.token);
            this.getSchoolsContactPerson(procedure, school !== undefined ? school.id : this.props.schoolId);
        }

        if (prevProps.procedureId !== this.props.procedureId) {
            this.getSchoolsContactPerson(this.props.procedureId);
            this.handleProcedure(this.props.procedureId);
        }
    }

    getSchoolsContactPerson(procedureId = -1, schoolId = -1) {
        if (CheckAuthorization("executionsmodal-contactperson", this.props.auth.roles, this.props.auth.procedure)) {
            this.props.dispatchGetSchoolsContactPerson(this.props.auth.token,
                schoolId !== -1 ? schoolId : (this.state.execution && this.state.execution.school ? this.state.execution.school.id : -1),
                procedureId);
        }
    }

    createSchoolContactPersonItems() {
        let items = [];
        items.push(<option key="cp-00" value={0} >{this.props.intl.formatMessage({ id: 'executionmodal.option.pleaseselect' })}</option>);
        this.props.schoolsContactPerson.forEach((contactperson) => {
            items.push(<option key={"cp-" + contactperson.id} value={contactperson.id} >{contactperson.lastname + ", " + contactperson.firstname + " (" + (contactperson.email ? contactperson.email : '-') + ")"}</option>);
        })
        return items;
    }

    createExecutionsstatusItems() {
        let items = [];
        this.props.executionstatus.forEach((status) => {
            items.push(<option key={"es-" + status.id} value={status.id} >{this.props.intl.formatMessage({ id: status.name })}</option>);
        })
        return items;
    }

    createCooperationtypeItems() {
        let items = [];
        this.props.executioncooptye.forEach((type) => {
            items.push(<option key={"ct-" + type.id} value={type.id} >{type.name}</option>);
        })
        return items;
    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            if (this.props.execution && !isEquivalent(this.props.execution, this.state.execution)) {
                this.toggleConfirm();
            } else {
                this.setState({ new: false });
                this.props.toggle();
            }
        } else if (type === "save") {
            this.save();
        }
    }

    handleContactPerson = async (e) => {
        this.setState(
            {
                invalid: { ...this.state.invalid, contactperson: false },
                execution: { ...this.state.execution, contactperson: { ...this.state.execution.contactperson, id: parseInt(e.target.value, 10) } }
            }
        );
    }

    handleProcedure = (procedureId) => {
        this.setState(
            {
                invalid: { ...this.state.invalid, procedure: false },
                execution: { ...this.state.execution, procedureId: procedureId }
            }
        );
    }

    handleExecutionsstatus = async (e) => {
        let newState = { ...this.state }
        newState.execution.executionsstatus.id = parseInt(e.target.value, 10);
        this.setState(newState);
    }

    handleCooperationtype = async (e) => {
        let newState = { ...this.state }
        newState.execution.cooperationtype.id = parseInt(e.target.value, 10);
        this.setState(newState);
    }

    handleSchool = async (school) => {
        let newState = this.state;
        newState.execution.school = school;
        let procedureId = school.procedures && school.procedures.length === 1 ? school.procedures[0].id : this.props.procedureId;
        newState.execution.procedureId = procedureId;
        this.setState(newState);
        this.getSchoolsContactPerson(procedureId, school.id);
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    save = (e) => {
        let doSave = true;
        let invalid = this.state.invalid;

        // Check start- and enddate (pm can edit in the past, school can edit until end date)
        if (!(this.state.execution.start &&
            this.state.execution.end &&
            this.state.execution.start.getTime() / 1000 < this.state.execution.end.getTime() / 1000 &&
            (((this.state.execution.start.getTime() / 1000 >= moment().set({ 'hour': -1, 'minute': 0, 'second': 0 }).unix()) &&
                this.props.auth.roles.indexOf(authorizationRoles.pm) === -1) ||
                (this.state.execution.end.getTime() / 1000 >= moment().set({ 'hour': -1, 'minute': 0, 'second': 0 }).unix() &&
                    this.props.auth.roles.indexOf(authorizationRoles.school) !== -1) ||
                this.props.auth.roles.indexOf(authorizationRoles.pm) !== -1)) &&
            this.state.execution.editable === true) {
            invalid.date = true;
            doSave = false;
        }

        //no contactperson selected
        if (this.state.execution.contactperson.id < 1) {
            invalid.contactperson = true;
            doSave = false;
        }
        //no procedure selected
        if (this.state.execution.procedureId === -1) {
            invalid.procedure = true;
            doSave = false;
        }

        if (doSave) {
            let ex = { ...this.state.execution }
            ex.start = moment(ex.start)
            ex.end = moment(ex.end)
            this.props.save(ex);
            this.setState({ new: false });
        } else {
            this.setState({ alertVisible: true, invalid: invalid });
            setTimeout(this.handleHideError, config.alertTimeOut);
        }
    }

    handleHideError = (e) => {
        this.setState({ alertVisible: false });
    };

    handleChangeStart(date) {

        let newState = { ...this.state }
        if (date && this.state.execution.end && date.getTime() / 1000 > this.state.execution.end.getTime() / 1000) {
            let enddate = cloneDeep(date);
            enddate.setHours(23)
            enddate.setMinutes(59)
            newState.execution.end = enddate
        }
        newState.invalid.date = false;
        newState.execution.start = date;

        this.setState(newState);
    }

    handleChangeEnd(date) {

        let newState = { ...this.state }
        if (date && this.state.execution.start && date.getTime() / 1000 < this.state.execution.start.getTime() / 1000) {
            let startdate = cloneDeep(date);
            enddate.setHours(0)
            enddate.setMinutes(0)
            newState.execution.start = startdate
        }
        newState.invalid.date = false;
        newState.execution.end = date
        this.setState(newState);
    }

    render() {
        return (
            <Container>
                <Alert isOpen={this.state.alertVisible} toggle={this.handleHideError} color="danger" className="fixed-top text-center"><FormattedMessage id="executionmodal.alert.inputs" /></Alert>

                <Confirm cancel save={this.save} dontsave={this.props.toggle} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="confirm.body.save" />
                <Form>
                    <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                        <ModalHeader className={this.props.execution && "edit"}><FormattedMessage id={!this.props.execution ? "executionmodal.title.new" : ((this.state.execution && this.state.execution.editable) || CheckAuthorization("executionsmodal-executionsstatus", this.props.auth.roles)) ? "executionmodal.title.edit" : "executionmodal.title.execution"} /></ModalHeader>

                        <ModalBody>
                            { /* Select School */}
                            <SchoolSelect className="mb-3" schools={this.props.schools} handleSchool={this.handleSchool.bind(this)} schoolId={this.state.execution && this.state.execution.school ? this.state.execution.school.id : -1} label="executionmodal.label.school" disabled={!(this.state.execution && this.state.execution.editable)} />
                            <div className="m-0 p-0 mb-3">
                                {/* Select Procedure */}
                                {(this.state.execution.school && this.state.execution.school.id !== -1 && this.state.execution.school.procedures && this.state.execution.school.procedures.length > 1) &&
                                    <ProcedureSelect label="studentadministration.label.procedures" name="procedures" withEmpty procedures={this.state.execution.school.procedures ? this.state.execution.school.procedures : []} procedureId={this.props.procedureId} handleProcedures={this.props.dispatchProcedure} />
                                }
                                {this.state.invalid.procedure &&
                                    <div className="ps-4 invalid-feedback d-block" ><FormattedMessage id="executionmodal.feedback.procedure" /></div>
                                }
                            </div>
                            <Container className={this.state.schools.length > 1 ? "border-top pt-4" : ""} fluid>
                                <Row>
                                    {/* STARTDATE */}
                                    <Col xs="12" sm="12" md="6" className="pb-4">
                                        <div className="m-0 p-0 mb-1">
                                            <Label className="fw-bold" for="startdate"><FormattedMessage id="executionmodal.label.startdate" /></Label>
                                            <DatePicker
                                                firstDayOfWeek={1}
                                                locale={'de'}
                                                maxLength="8" aria-required
                                                valid={false}
                                                id="startdate"
                                                className={!(this.state.execution && this.state.execution.editable) ? "text-dark px-2 py-1  disabled" : "text-dark px-2 py-1 "}
                                                name="startdate"
                                                timeCaption={this.props.intl.formatMessage({ id: 'datepicker.label.time' })}
                                                showWeekNumbers
                                                dateFormat="dd.MM.y"
                                                placeholderText={this.props.intl.formatMessage({ id: "executionmodal.placeholder.startdate" })}
                                                selected={this.state.execution.start}
                                                selectsStart
                                                endDate={this.state.execution.end}
                                                startDate={this.state.execution.start}
                                                onChange={this.handleChangeStart}
                                                disabled={!(this.state.execution && this.state.execution.editable)}
                                            />
                                            <span className="small">(tt.mm.jj)</span>
                                            {this.state.invalid.date &&
                                                <div className="ps-1 invalid-feedback d-block" ><FormattedMessage id="executionmodal.feedback.date" /></div>
                                            }
                                        </div>
                                    </Col>
                                    {/* ENDDATE */}
                                    <Col xs="12" sm="12" md="6" className="pb-4">
                                        <div className="m-0 p-0 mb-1">
                                            <Label className="fw-bold" for="enddate"><FormattedMessage id="executionmodal.label.enddate" /></Label>
                                            <DatePicker firstDayOfWeek={1} locale={'de'} aria-required id="enddate" name="enddate" className={!(this.state.execution && this.state.execution.editable) ? "text-dark px-2 py-1  disabled" : "text-dark px-2 py-1 "} timeCaption={this.props.intl.formatMessage({ id: 'datepicker.label.time' })} showWeekNumbers dateFormat="dd.MM.y" placeholderText={this.props.intl.formatMessage({ id: "executionmodal.placeholder.enddate" })}
                                                selected={this.state.execution.end} selectsEnd startDate={this.state.execution.start} endDate={this.state.execution.end} onChange={this.handleChangeEnd} disabled={!(this.state.execution && this.state.execution.editable)} />
                                            <span className="p-0 m-0 small">(tt.mm.jj)</span>
                                            {this.state.invalid.date &&
                                                <div className="ps-1 invalid-feedback d-block" ><FormattedMessage id="executionmodal.feedback.date" /></div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="border-top pt-4" fluid>
                                <Row>
                                    {/* CONTACTPERSON */}
                                    <Authorization id="executionsmodal-contactperson">
                                        <Col xs="12" sm="12" md="4" className="pb-4">
                                            <div className="m-0 p-0 mb-1">
                                                <Label className="fw-bold" for="contactperson"><FormattedMessage id="executionmodal.label.contactperson" /></Label>
                                                <Input bsSize="sm" type="select" invalid={this.state.invalid.contactperson} name="contactperson" id="contactperson" value={this.state.execution.contactperson && this.state.execution.contactperson.id} onChange={this.handleContactPerson} disabled={!(this.state.execution && this.state.execution.editable)}>
                                                    {this.createSchoolContactPersonItems()}
                                                </Input>
                                                {this.state.invalid.contactperson &&
                                                    <FormFeedback className="ps-1" invalid="true"><FormattedMessage id="executionmodal.feedback.contactperson" /></FormFeedback>
                                                }
                                            </div>
                                        </Col>
                                    </Authorization>
                                    {/* COOPERATION */}
                                    <Authorization id="executionsmodal-cooperationtype">
                                        <Col xs="12" sm="12" md="4" className="pb-4">
                                            <Label className="fw-bold" for="cooperationtype"><FormattedMessage id="executionmodal.label.cooperationtype" /></Label>
                                            <Input bsSize="sm" type="select" name="cooperationtype" id="cooperationtype" value={this.state.execution.cooperationtype && this.state.execution.cooperationtype.id} onChange={this.handleCooperationtype}>
                                                {this.createCooperationtypeItems()}
                                            </Input>
                                        </Col>
                                    </Authorization>
                                    {/* STATUS */}
                                    <Authorization id="executionsmodal-executionsstatus">
                                        <Col xs="12" sm="12" md="4" className="pb-4">
                                            <Label className="fw-bold" for="executionsstatus"><FormattedMessage id="executionmodal.label.executionsstatus" /></Label>
                                            <Input bsSize="sm" type="select" name="executionsstatus" id="school" value={this.state.execution.executionsstatus && this.state.execution.executionsstatus.id} onChange={this.handleExecutionsstatus} >
                                                {this.createExecutionsstatusItems()}
                                            </Input>
                                        </Col>
                                    </Authorization>
                                </Row>
                            </Container>

                        </ModalBody>

                        {((this.state.execution && this.state.execution.editable) || CheckAuthorization("executionsmodal-executionsstatus", this.props.auth.roles)) ?
                            <ModalFooter>
                                <Button type="submit" name="cancel" color="primary" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.cancel" /></Button>
                                <Button type="submit" name="save" color="primary" onClick={this.handleSubmit.bind(this, "save")}><FormattedMessage id="button.save" /></Button>
                            </ModalFooter>
                            :
                            <ModalFooter>
                                <Button type="submit" name="close" color="primary" onClick={this.props.toggle}><FormattedMessage id="button.close" /></Button>
                            </ModalFooter>
                        }
                    </Modal>
                </Form>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        schoolsContactPerson: state.schools.schoolsContactPerson,
        executioncooptye: state.executions.executioncooptype,
        executionstatus: state.executions.executionstatus,
        procedureId: state.filter.procedureIntern ? state.filter.procedureIntern.id : -1,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, true));
        },
        dispatchGetSchoolsContactPerson: (token, schoolId, procedureId) => {
            dispatch(schoolsActions.getSchoolsContactPerson(token, schoolId, procedureId));
        },
        dispatchGetExecutionsstatus: (token) => {
            dispatch(executionsActions.getExecutionsstatus(token));
        },
        dispatchGetCooperationtype: (token) => {
            dispatch(executionsActions.getCooperationtype(token));
        },
        dispatchProcedure: (procedure) => {
            dispatch(filterActions.procedureIntern(procedure));
        }
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionsModal));