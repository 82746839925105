import { actionTypes } from '../actions/types';

const initialState = {
    motivations: [],
    motivationsPairs: [],
    loading: false,
    loadingSave: false,
    motivationsError: '',
    saving: false,
    saveStatus: null
};

const motivationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MOTIVATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                motivationsError: ''
            };
        case actionTypes.MOTIVATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                motivationsPairs: action.motivationsPairs ? action.motivationsPairs : [],
                motivationsError: ''
            };
        case actionTypes.MOTIVATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                motivations: [],
                motivationsPairs: [],
                motivationsError: action.error
            };

        case actionTypes.MOTIVATIONS_SAVE_REQUEST:
            return {
                ...state,
                motivationsError: '',
                loadingSave: true,
                saving: true,
                saveStatus: null
            };
        case actionTypes.MOTIVATIONS_SAVE_SUCCESS:
            return {
                ...state,
                motivationsError: '',
                saveStatus: action.status,
                loadingSave: false,
                saving: false
            };
        case actionTypes.MOTIVATIONS_SAVE_FAILURE:
            return {
                ...state,
                motivationsError: action.error,
                loadingSave: false,
                saveStatus: action.status,
                saving: false
            };
            case actionTypes.MOTIVATIONSRESULT_SAVE_REQUEST:
                return {
                    ...state,
                    motivationsError: '',
                    loadingSave: true,
                    saving: true,
                    saveStatus: null
                };
            case actionTypes.MOTIVATIONSRESULT_SAVE_SUCCESS:
                return {
                    ...state,
                    motivationsError: '',
                    saveStatus: action.status,
                    loadingSave: false,
                    saving: false
                };
            case actionTypes.MOTIVATIONSRESULT_SAVE_FAILURE:
                return {
                    ...state,
                    motivationsError: action.error,
                    loadingSave: false,
                    saveStatus: action.status,
                    saving: false
                };
        default:
            return state;
    }
};

export default motivationReducer;
