import ENV from '../config/env';

export const statisticService = {
    getStatistic,
    getDepartmentStatistic,
};

async function getStatistic(token, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    let schoolId = filter.schoolId ? filter.schoolId : '-1';
    let start = filter.start ? ('?start=' + filter.start) : '';
    let end = filter.end ? ('&end=' + filter.end) : '';
    let departmentId = filter.departmentId ? ('&department=' + filter.departmentId) : '';
    let schooltypeId = filter.schooltypeId ? ('&schooltype=' + filter.schooltypeId) : '';
    let schoolyear = filter.schoolyear ? ('&schoolyear=' + filter.schoolyear) : '';
    let procedure = filter.procedure ? ('&procedure=' + filter.procedure) : '';

    return fetch(ENV.apiUrl + '/statistic/' + schoolId + start + end + departmentId + schooltypeId + schoolyear + procedure, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();

        }).then(response => {
            return response;
        });
}

async function getDepartmentStatistic(token, departmentId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/statistic/department/' + departmentId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();

        }).then(response => {
            return response;
        });
}
