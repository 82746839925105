import { actionTypes } from '../actions/types';

const initialState = {
    results: null,
    loading: false,
    loadingError: '',
};

const interesttestReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INTERESTTEST_RESULTS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.INTERESTTEST_RESULTS_SUCCESS:
            return {
                ...state,
                results: action.results ? action.results.results : null,
                loading: false,
            };
        case actionTypes.INTERESTTEST_RESULTS_FAILURE:
            return {
                ...state,
                loadingError: action.error,
                loading: false,
            };
        default:
            return state;
    }
};

export default interesttestReducer;
