import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import authReducer from './authReducer';
import filterReducer from './filterReducer';
import executionsReducer from './executionsReducer';
import competencesReducer from './competencesReducer';
import motivationReducer from './motivationReducer';
import observationsReducer from './observationsReducer';
import schoolsReducer from './schoolsReducer';
import settingsReducer from './settingsReducer';
import userProfileReducer from './userProfileReducer';
import inquiryReducer from './inquiryReducer';
import departmentsReducer from './departmentsReducer';
import skillsProfilesReducer from './skillsProfilesReducer';
import kompo7RatingReducer from './kompo7RatingReducer';
import documentsReducer from './documentsReducer';
import statisticReducer from './statisticReducer';
import interesttestReducer from './interesttestReducer';
import verbexerciseReducer from './verbexerciseReducer';
import kompoGResultReducer from './kompoGResultReducer';

const appReducer = combineReducers({
    auth: authReducer,
    intl: intlReducer,
    filter: filterReducer,
    executions: executionsReducer,
    competences: competencesReducer,
    motivations: motivationReducer,
    observations: observationsReducer,
    schools: schoolsReducer,
    settings: settingsReducer,
    userProfile: userProfileReducer,
    inquiry: inquiryReducer,
    departments: departmentsReducer,
    skillsProfile: skillsProfilesReducer,
    kompo7Rating: kompo7RatingReducer,
    documents: documentsReducer,
    statistic: statisticReducer,
    interesttest: interesttestReducer,
    verbexercise: verbexerciseReducer,
    kompoGResult: kompoGResultReducer
});

const rootReducer = (state, action) => {
    let newState = {};
    if (action.type === 'LOGOUT_SUCCESS') {
       newState = { intl: state.intl };
    } else {
        newState = state;
    }
    return appReducer(newState, action);
};

export default rootReducer;
