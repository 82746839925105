const student = 1;
const teacher = 2;
const pm = 3;
const school = 4;
const hkm = 5;
const hk = 6;
const kompog = 8;
const kompo7 = 9;
const kompoBoth = 0;

export const authorizationRoles = {
    student,
    teacher,
    pm,
    school,
    hkm,
    hk
};

export const allowdRolesCombinations = [];
allowdRolesCombinations[student] = [];
allowdRolesCombinations[teacher] = [school];
allowdRolesCombinations[pm] = [];
allowdRolesCombinations[school] = [teacher];
allowdRolesCombinations[hkm] = [];
allowdRolesCombinations[hk] = [];

export const authorizationList = {
    /**
    * MENU
    */
    'menu-self-assessment': [student, kompo7],
    'menu-self-assessment-kompog': [student, kompog],
    'menu-self-assessment-overview': [teacher, pm, hk, kompo7, kompoBoth],
    'menu-external-assessment': [student, teacher, pm, hk, kompo7],
    'menu-interest-test': [student, kompo7],
    'menu-interest-test-overview': [kompo7],
    'menu-skills-profile': [teacher, pm, hk, kompo7, kompoBoth],
    'menu-skills-profile-student': [student, kompo7],
    'menu-student-administration': [school, kompo7, kompog, kompoBoth],
    'menu-user-administration': [pm, school, kompo7, kompog, kompoBoth],
    'menu-school-administration': [pm, kompo7, kompog, kompoBoth],
    'menu-observations': [teacher, pm, hk, kompo7],
    'menu-daily-report': [teacher, pm, hk, kompo7],
    'menu-exercises': [teacher, pm, school, hk],
    'menu-kompo7-rating': [student, kompo7, kompog, kompoBoth],
    'menu-kompo7-rating-results': [kompo7],
    'menu-executions': [pm, school, teacher, hk, kompo7, kompog, kompoBoth],
    'menu-statistic': [hkm, pm, school, kompo7, kompog, kompoBoth],
    'menu-feedback': [teacher, pm, hk, kompo7, kompoBoth],
    'menu-results': [teacher, pm, hk, kompo7, kompog, kompoBoth],
    'menu-results-school': [school, kompo7, kompog, kompoBoth],
    'menu-teacher-administration': [kompo7],
    'menu-school': [school, kompo7, kompog, kompoBoth],
    'menu-inquiry': [school, kompo7, kompog, kompoBoth],
    'menu-observations-evaluations': [teacher, pm, hk, kompo7, kompoBoth],
    'menu-documents': [teacher, school, pm, hk, kompo7, kompog, kompoBoth],
    'menu-verbexercise': [student, kompog],
    'menu-motivationExercise': [student, kompog],
    'menu-myresults-page': [student, kompog],

    /**
     * ROUTE
     */
    '/selfassessment': [student, kompo7],
    '/selfassessmentkompog': [student, kompog],
    '/selfassessmentoverview': [teacher, pm, hk, kompo7, kompoBoth],
    // 'route-external-assessment': [student, teacher, pm, hk, kompo7],
    '/interesttest': [student, kompo7],
    '/interesttestoverview': [kompo7],
    '/skillsprofile': [teacher, pm, hk, kompo7, kompoBoth],
    '/skillsprofilestudent': [student, kompo7],
    '/studentadministration': [school, kompo7, kompog, kompoBoth],
    '/useradministration': [pm, school, kompo7, kompog, kompoBoth],
    '/schooladministration': [pm, kompo7, kompog, kompoBoth],
    // 'route-observations': [teacher, pm, hk, kompo7],
    // 'route-daily-report': [teacher, pm, hk, kompo7],
    // 'route-exercises': [teacher, pm, school, hk, kompo7],
    '/kompo7rating': [student, kompo7, kompog, kompoBoth],
    '/kompo7ratingresults': [kompo7],
    '/executions': [pm, school, teacher, hk, kompo7, kompog, kompoBoth],
    '/statistic': [hkm, pm, school, kompo7, kompog, kompoBoth],
    '/feedback': [teacher, pm, hk, kompo7, kompoBoth],
    '/results': [teacher, pm, hk, kompo7, kompog, kompoBoth],
    '/resultsschool': [school, kompo7, kompog, kompoBoth],
    // 'route-teacher-administration': [kompo7, kompog, kompoBoth],
    '/school': [school, kompo7, kompog, kompoBoth],
    '/inquiryreceived': [school, kompo7, kompog, kompoBoth],
    '/inquirysend': [school, kompo7, kompog, kompoBoth],
    '/observationsevaluations': [teacher, pm, hk, kompo7, kompoBoth],
    '/documents': [teacher, school, pm, hk, kompo7, kompog, kompoBoth],
    '/verbexercise': [student, kompog],
    '/motivationExercise': [student, kompog],
    '/myresultspage': [student, kompog],

    /**
     * EXECUTIONS
     */
    'executions-select-school': [pm, hk, kompo7, kompog, kompoBoth],
    'executions-show-procedure': [pm, hk, school, teacher, kompoBoth],
    'executions-show-department': [pm, kompo7, kompog, kompoBoth],
    'executions-show-school': [pm, hk, kompo7, kompog, kompoBoth],
    'executions-edit': [pm, school, kompo7, kompog, kompoBoth],
    'executions-delete': [pm, school, kompo7, kompog, kompoBoth],
    'executions-new': [pm, school, kompo7, kompog, kompoBoth],
    'executions-inquiry': [school, kompo7, kompog, kompoBoth],
    'executions-group': [pm, school, teacher, hk, kompo7, kompoBoth],
    'executions-reset-pwd-students': [pm, school, kompo7, kompog, kompoBoth],
    'executions-filter-cooperation': [pm, hk, kompo7, kompog, kompoBoth],
    'executions-filter-department-schooltype': [pm, hk, kompo7, kompog, kompoBoth],
    'executions-foreign-users-pdf': [school, kompo7, kompog, kompoBoth],

    /**
     * EXECUTIONS - MODAL
     */
    // 'executionsmodal-select-school': [pm, kompo7, kompog, kompoBoth],
    'executionsmodal-contactperson': [pm, school, kompo7, kompog, kompoBoth],
    'executionsmodal-cooperationtype': [kompo7],
    'executionsmodal-executionsstatus': [kompo7],

    /**
    * EXECUTIONSGROUP - MODAL
    */
    'executionsgroupmodal-new': [pm, school, kompo7, kompog, kompoBoth],
    'executionsgroupmodal-delete': [pm, school, kompo7, kompog, kompoBoth],
    'executionsgroupmodal-edit': [pm, school, kompo7, kompog, kompoBoth],
    'executionsgroupmodal-schoolyears': [pm, school, kompo7, kompog, kompoBoth],

    /**
    * SKILLSPROFILE - MODAL
    */
    'skillsprofile-approved': [pm, teacher, kompo7, kompoBoth],
    'skillsprofile-lock': [pm, teacher, kompo7, kompoBoth],
    'skillsprofile-honorarkraftselect': [pm, kompo7, kompoBoth],

    /**
    * USERADMINISTRATION
    */
    'useradministration-filter-department': [pm, kompo7, kompog, kompoBoth],
    'useradministration-filter-schooltype': [pm, kompo7, kompog, kompoBoth],
    'useradministration-filter-school': [pm, kompo7, kompog, kompoBoth],
    'useradministration-filter-certified': [pm, kompo7, kompog, kompoBoth],
    'useradministration-reset-pwd-all-teacher': [school, kompo7, kompog, kompoBoth],
    'useradministration-reset-pwd-teacher': [school, pm, kompo7, kompog, kompoBoth],
    'useradministration-import-user': [school, pm, kompo7, kompog, kompoBoth],

    /**
    * USERADMINISTRATION-MODAL
    */
    'useradministrationmodal-certified': [pm, school, kompo7, kompog, kompoBoth],
    'useradministrationmodal-trainingdate': [pm, school, kompo7, kompog, kompoBoth],
    'useradministrationmodal-trainingsupport': [pm, school, kompo7, kompog, kompoBoth],
    'useradministrationmodal-certified-edit': [pm, kompo7, kompog, kompoBoth],
    'useradministrationmodal-trainingdate-edit': [pm, kompo7, kompog, kompoBoth],
    'useradministrationmodal-trainingsupport-edit': [pm, kompo7, kompog, kompoBoth],
    'useradministrationmodal-titlefunction-show': [school, kompo7, kompog, kompoBoth],
    'useradministrationmodal-autofill-certified': [pm, kompo7, kompog, kompoBoth],

    /**
     * STATISTIC
    */
    'statistic-filter-school': [pm, hkm, kompo7, kompog, kompoBoth],
    'statistic-filter-department': [pm, hkm, kompo7, kompog, kompoBoth],
    'statistic-filter-schooltype': [pm, hkm, kompo7, kompog, kompoBoth],
    'statistic-statistic-schools': [pm, hkm, kompo7, kompog, kompoBoth],
    'statistic-statistic-departmentstats': [pm, hkm, kompo7, kompog, kompoBoth],
    'statistic-statistic-executions': [pm, kompo7, kompog, kompoBoth],
    'statistic-ratings': [pm, hkm, school, kompo7, kompog, kompoBoth],

    /**
     * OBSERVATIONS-EVALUATIONS
    */
    'observations-evaluations-honorarkraftselect': [pm, kompo7, kompog, kompoBoth],

    /**
     * RESULTS
    */
    'results-honorarkraftselect': [pm, kompo7, kompog, kompoBoth],
    'results-pdf-bwhw': [pm, hk, kompo7, kompog, kompoBoth],

    /**
    * INQUIRY
    */
    'inquiry-count-open': [school, kompo7, kompog, kompoBoth],

    /**
    * HELP
    */
    'help-self-assessment': [student, kompo7],
    'help-self-assessment-overview': [teacher, pm, hk, kompo7, kompoBoth],
    'help-self-assessment-overview-supplement': [teacher, pm, hk, kompoBoth],
    'help-external-assessment': [student, teacher, pm, hk, kompo7],
    'help-interesttest': [student, kompo7],
    'help-interest-test-overview': [kompo7],
    'help-skills-profile': [teacher, pm, hk, kompo7, kompoBoth],
    'help-skills-profile-supplement': [teacher, pm, hk, kompoBoth],
    'help-skills-profile-student': [student, kompo7],
    'help-student-administration': [school, kompo7, kompog, kompoBoth],
    'help-student-administration-collapse': [school, kompoBoth],
    'help-user-administration': [pm, school, kompo7, kompog, kompoBoth],
    'help-school-administration': [pm, kompo7, kompog, kompoBoth],
    'help-observations': [teacher, pm, hk, kompo7],
    'help-daily-report': [teacher, pm, hk, kompo7],
    'help-exercises': [teacher, pm, school, hk, kompo7],
    'help-kompo7-rating': [student, kompo7, kompog],
    'help-kompo7-rating-results': [kompo7],
    'help-executions': [pm, school, teacher, hk, kompo7, kompog, kompoBoth],
    'help-executions-collapse': [pm, school, teacher, hk, kompoBoth],
    'help-statistic': [hkm, pm, school, kompo7, kompog, kompoBoth],
    'help-feedback': [teacher, pm, hk, kompo7, kompoBoth],
    'help-feedback-supplement': [teacher, pm, hk, kompoBoth],
    'help-results': [teacher, pm, hk, kompo7, kompog, kompoBoth],
    'help-results-collapse': [teacher, pm, hk, kompoBoth],
    'help-results-school': [school, kompo7, kompog, kompoBoth],
    'help-teacher-administration': [kompo7, kompog, kompoBoth],
    'help-school': [school, kompo7, kompog, kompoBoth],
    'help-inquiry': [school, kompo7, kompog, kompoBoth],
    'help-observations-evaluations': [teacher, pm, hk, kompo7, kompoBoth],
    'help-observations-evaluations-supplement': [teacher, pm, hk, kompoBoth],
    'help-documents': [teacher, school, pm, hk, kompo7, kompog, kompoBoth],
    'help-my-competences': [student, kompog],
    'help-my-interests': [student, kompog],
    'help-my-motivations': [student, kompog],
    'help-my-results': [student, kompog],

    /** 
    * DOCUMENTS
    */

    'documents-editmode': [pm, kompo7, kompog, kompoBoth],
    'documents-edit': [pm, kompo7, kompog, kompoBoth],
    'documents-delete': [pm, kompo7, kompog, kompoBoth],
    'documents-add': [pm, kompo7, kompog, kompoBoth],
    'documents-up': [pm, kompo7, kompog, kompoBoth],
    'documents-down': [pm, kompo7, kompog, kompoBoth],
    'documents-structur-edit': [pm, kompo7, kompog, kompoBoth],
    'documents-structur-delete': [pm, kompo7, kompog, kompoBoth],
    'documents-structur-add': [pm, kompo7, kompog, kompoBoth],
    'documents-structur-up': [pm, kompo7, kompog, kompoBoth],
    'documents-structur-down': [pm, kompo7, kompog, kompoBoth],

    /** 
    * LOGIN
    */

    'login-skip-privacy-policy': [student, kompo7, kompog, kompoBoth],
}