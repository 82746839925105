const translationEN = {
    locale: 'en',
    messages: {
        /**
         * GENERIC
         */
        // generic buttons
        'button.save': 'Save',
        'button.dontsave': 'Don\'t save',
        'button.close': 'Close',
        'button.cancel': 'Cancel',
        'button.yes': 'Yes',
        'button.no': 'No',
        'button.import': 'Import',

        /**
         * CONFIRM
         */
        'confirm.header.save': 'Save?',
        'confirm.body.save': 'Do you want save the changes you made?',
        'confirm.header.delete': 'Delete?',
        'confirm.body.delete':'Do you really want to delete?',

        /**
         * LOGINPAGE
         */
        'loginpage.button.login': 'Login',
        'loginpage.label.login': 'Username/Email',
        'loginpage.label.password': 'Password',
        'loginpage.button.forgotpassword': 'Forgot password?',
        'loginpage.button.impressum': 'Impressum',
        'loginpage.modaltitle.impressum': 'Impressum',
        'loginpage.alert.login-error': 'Login failed!',

        /**
         * MAINPage
         */
        'main.button.impressum': 'Impressum',


        /**
         * HOMEPAGE
         */

        /**
        * LOGOUT
        */
        'logout.button.logout': 'Logout',

        /**
         * MENU
         */
        'menu.button.home': 'Home',
        'menu.button.settings': 'Settings',
        'menu.button.help': 'Help',
        'menu.button.documents': 'Documents',
        'menu.button.impressum': 'Impressum',
        'menu.button.self-assessment': 'Self-assessment',
        'menu.button.external-assessment': 'External assessment',
        'menu.button.interest-test': 'Interest test',
        'menu.button.skills-profile': 'Skills profile',
        'menu.button.student-administration': 'Student administration',
        'menu.button.observations': 'observations',
        'menu.button.exercises': 'exercises',
        'menu.button.user-administration': 'User administration',
        'menu.button.kompo7-rating': 'KomPo7 rating',
        'menu.button.school-administration': 'school administration',
        'menu.button.daily-report': 'Daily report',
        'menu.button.executions': 'Executions',
        'menu.button.statistic': 'Statistic',

        /**
        * HEADER
        */
        'header.button.home': 'Startseite',
        'header.button.settings': 'Einstellungen',
        'header.button.help': 'Hilfe',
        'header.button.documents': 'Dokumente',

        /**
        * EXECUTIONPAGE
        */
        'execution.label.execution': 'Execution',
        'execution.button.new-execution': 'New execution',
        'execution.th.school': 'School',
        'execution.th.startdate': 'Start date',
        'execution.th.enddate': 'End date',

        /**
         * EXECUTIONMODAL
         */
        'executionmodal.title.new': 'New exectuion',
        'executionmodal.title.edit': 'Edit execution',
        'executionmodal.label.school': 'School',
        'executionmodal.placeholder.startdate': 'Startdate',
        'executionmodal.placeholder.enddate': 'Enddate',
        'executionmodal.label.startdate': 'Startdate',
        'executionmodal.label.enddate': 'Enddate',
        'executionmodal.label.contactperson': 'Contact person',
        'executionmodal.option.nocontactperson': 'No contact person',
        'executionmodal.label.cooperationtype': 'Content of the cooperation',
        'executionmodal.label.executionsstatus': 'Status',
        'executionmodal.label.observer': 'Observer',
        'executiongroupmodal.label.startdate': 'Startdate: ',
        'executiongroupmodal.label.enddate': 'Enddate: ',
        'executiongroupmodal.label.school': 'School: ',
        'executiongroupmodal.button.newgroup': 'New observation group',
        'executiongroupmodal.alt.deletegroup': 'Delete observation group',
        'executiongroupmodal.label.groups': 'Observation group ',

        /**
        * TABLEBODY
        */
        'tablebody.alt.edit': 'Edit',
        'tablebody.alt.delete': 'Delete',
        'tablebody.alt.send': 'Send inquiry',
        'tablebody.alt.group': 'Edit groups',
        'tablebody.label.nocontent': 'no content available',

        /**
        * STUDENTADMINISTRATIONPAGE
        */
       'studentadministration.button.importstudents': 'Import students',

        /**
         * UPLOADERRORMODAL
         */
        'uploaderrormodal.title': 'Error',

        /**
         * IMPORTSTUDENTSMODAL
         */
        'importstudentsmodal.title': 'Import students',
        'importstudentsmodal.filename': 'Filename: ',
        'importstudentsmodal.nmore': ' more',
        'importstudentsmodal.th.personalnumber': 'Personalnumber',
        'importstudentsmodal.th.username': 'Username',
        'importstudentsmodal.th.firstname': 'Firstname',
        'importstudentsmodal.th.lastname': 'Lastname',
        'importstudentsmodal.th.email': 'Email',
        'importstudentsmodal.th.schoolname': 'School',
        'importstudentsmodal.th.class': 'Class',
        'importstudentsmodal.th.title': 'Title',
        'importstudentsmodal.th.address': 'Address',
        'importstudentsmodal.importcomplete': 'Import finished!',
        'importstudentsmodal.details.importcount': 'Imported: ',
        'importstudentsmodal.details.ignorecount': 'Ignored: ',
        'importstudentsmodal.details.errorcount': 'Faulty: ',
        'importstudentsmodal.details.title': 'Details:',
        'importstudentsmodal.details.line': 'Line',
        'importstudentsmodal.details.description': 'Description',
        'importstudentsmodal.details.wrongdatatype': 'Wrong datatype. Correct: ',
        'importstudentsmodal.details.propsmissing': 'Missing informations: ',
        'importstudentsmodal.details.unauthorized': 'You are not allowed to create this student!',
        'importstudentsmodal.details.duplicateentry1': 'Ignored: A student with this data (',
        'importstudentsmodal.details.duplicateentry2': ') exists already.',
        'importstudentsmodal.details.internalerror': 'An internal error occured.',

        /**
         * SETTINGS
         */
        'settingspage.title': 'Settings',
        'settings.firstname.title': 'Firstname:',
        'settings.lastname.title': 'Lastname:',
        'settings.email.title': 'Email:',
        'settings.password.title': 'Password:',
        'settings.username.title': 'Username:',
        'settings.class.title': 'Class:',
        'singleinputmodal.title': 'Change setting',
        'singleinputmodal.doublecheck.title': 'Repeat:',
        'settings.constraints.title.email': ' ',
        'settings.constraints.title.minlength': 'Minimal length: ',
        'settings.constraints.title.maxlength': 'Maximum length: ',
        'settings.constraints.title.regex.postcode': 'Valid postcode',
        'settingspage.save.failure.Unauthorized': 'The old password is wrong!',
        'settingspage.save.failure.Conflict': 'This entry is already taken!',

        /**
         * PASSWORDINPUTMODAL
         */
        'passwordinputmodal.title': 'Change password',
        'passwordinputmodal.oldpassword.title': 'Old password:',
        'passwordinputmodal.newpassword.title': 'New password:',
        'passwordinputmodal.repeatpassword.title': 'Repeat new password:',
        'passwordinputmodal.password.strength.label': 'Passwordsafety:',
        'passwordinputmodal.password.strength.0': 'low',
        'passwordinputmodal.password.strength.1': 'okay',
        'passwordinputmodal.password.strength.2': 'good',
        'passwordinputmodal.password.strength.3': 'high',
        'passwordinputmodal.password.strength.4': 'very high',

        /**
         * SCHOOL PAGE
         */
        'school.title': 'School',
        'school.label.edit': 'Edit',
        'school.save.success': 'Save successful!',
        'school.save.failure': 'Error: ',
        'school.settings.name.title': 'Name:',
        'school.settings.schooltype.title': 'Schooltype:',
        'school.settings.street.title': 'Street:',
        'school.settings.place.title': 'City:',
        'school.settings.postcode.title': 'Postcode:',
        'school.settings.phone.title': 'Phone:',
        'school.settings.fax.title': 'Fax:',
        'school.settings.email.title': 'Email:',
        'school.settings.homepage.title': 'Homepage:',

        /**
         * KOMPO7RATING PAGE
         */
        'kompo7rating.label.kompo7rating': 'KomPo7 Bewertung',
        'kompo7rating.label.school': 'Schule:',
        'kompo7rating.label.execution': 'Durchführung:',
        'kompo7rating.label.hint': 'Deine Antworten und Rückmeldungen sind uns wichtig. Sie liefern uns nützliche Informationen für zukünftige KomPo7-Veranstaltungen.',
        'kompo7rating.label.observer': 'Anleiter/-in:',
        'kompo7rating.label.rating-1': 'Trifft voll zu',
        'kompo7rating.label.rating-2': 'Neutral',
        'kompo7rating.label.rating-3': 'Trifft nicht zu',
        'kompo7rating.question-1': 'Die Aufgaben haben mir Spaß gemacht.',
        'kompo7rating.question-2': 'Ich habe die Aufgaben verstanden.',
        'kompo7rating.question-3': 'Ich habe etwas über meine Stärken erfahren.',
        'kompo7rating.question-4': 'Ich habe etwas über meine Interessen erfahren.',
        'kompo7rating.question-5': 'Ich habe Hinweise bekommen, wie ich mich über Berufe informieren kann.',
        'kompo7rating.question-6': 'Mir hat KomPo7 insgesamt gefallen.',
        'kompo7rating.label.message': 'Außerdem möchte ich noch sagen:',
        'kompo7rating.confirm.body.save': 'Nach dem Speichern können Sie keine weiteren Änderungen vornehmen! Wirklich speichern?',
        'kompo7rating.alert.notallselect': 'Sie müssen alle Kompetenzen bewerten bevor Sie speichern können!',

        /**
         * SELFASSESSMENTOVERVIEW PAGE
         */
        'selfassessmentoverview.label.selfassessmentoverview': 'Selbsteinschätzung Übersicht',
        'selfassessmentoverview.label.execution': 'Durchführung',
        'selfassessmentoverview.th.firstname': 'Vorname',
        'selfassessmentoverview.th.lastname': 'Nachname',
        'selfassessmentoverview.th.class': 'Klasse',
        'selfassessmentoverview.th.completed': 'Selbsteinschätzung abgeschlossen',
        'selfassessmentoverview.description.completed': 'Selbsteinschätzung abgeschlossen:',
        'selfassessmentoverview.description.alt.completed': 'Icon Selbsteinschätzung abgeschlossen',
    }
};

export default translationEN;