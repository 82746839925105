import ENV from '../config/env';

export const userProfileService = {
    getUserProfile,
    getUsersAddress,
    getUsersTitle,
    getUsersFunctions,
    getRoles,
    createUser,
    updateUser,
    deleteUser,
    importStudents,
    importUsers,
    getUsers,
    resetPwd,
    resetPwdPdf,
    resetPwdUserPdf,
    resetUser
};

function getUserProfile(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };

    return fetch(ENV.apiUrl + '/users/me', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response.json();
        })
        .then(response => {

            if (response) {
                localStorage.setItem('userProfile', JSON.stringify(response));
            }

            return response;
        });
}

/**
* USERSADDRESS
*/
async function getUsersAddress(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/users/usersaddress/', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}

/**
* USERS
*/
async function getUsers(token, order, desc, search, page, pagelimit, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    search = search ? "&search=" + search : "";
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";
    let schoolId = filter.schoolId && filter.schoolId >= 0 ? "&school=" + filter.schoolId : "";
    let roleId = filter.roleId && filter.roleId >= 0 ? "&role=" + filter.roleId : "";
    let departmentId = filter.departmentId && filter.departmentId >= 0 ? "&department=" + filter.departmentId : "";
    let schooltypeId = filter.schooltypeId && filter.schooltypeId >= 0 ? "&schooltype=" + filter.schooltypeId : "";
    let executionId = filter.executionId && filter.executionId >= 0 ? "&execution=" + filter.executionId : "";
    let certified = filter.certified !== null ? "&certified=" + filter.certified : "";
    let procedure = filter.procedureId >= 0 ? "&procedure=" + filter.procedureId : "";

    return fetch(ENV.apiUrl + '/users?' + order + desc + search + page + pagelimit + schoolId + roleId + departmentId + schooltypeId + executionId + certified + procedure, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}


/**
* USERSTITLE
*/
async function getUsersTitle(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/users/userstitle/', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}


/**
* ROLES
*/
async function getRoles(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/users/roles/', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}
/**
* USERSFUNCTIONS
*/
async function getUsersFunctions(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/users/usersfunctions/', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}


/**
* CREATE USER
*/
async function createUser(token, user) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user })
    };

    return fetch(ENV.apiUrl + '/users', requestOptions)
        .then(response => {
            if (!response.ok && response.status !== 409) {
                return Promise.reject(response.statusText);
            } else if (!response.ok && response.status === 409) {
                return response.json();
            }

            return response;

        });

}

/**
* UPDATE USER
*/
async function updateUser(token, user) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user })
    };

    return fetch(ENV.apiUrl + '/users/' + user.id, requestOptions)
        .then(response => {
            if (!response.ok && response.status !== 409) {
                return Promise.reject(response.statusText);
            } else if (!response.ok && response.status === 409) {
                return response.json();
            }

            return response;

        });

}

/**
* DELETE USER
*/
async function deleteUser(token, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/users/' + id, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });
}

/**
 * IMPORT STUDENTS
 */
async function importStudents(token, importFile, procedure) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'import_file': importFile, 'procedure': procedure })
    };

    return fetch(ENV.apiUrl + '/users/students/import', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        });
}

/**
 * IMPORT USERS
 */
async function importUsers(token, importFile) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'import_file': importFile })
    };

    return fetch(ENV.apiUrl + '/users/import', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        });
}

/**
 * RESET PWD
 */
async function resetPwd(token, filter) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'filter': filter })
    };

    return fetch(ENV.apiUrl + '/users/reset_pwd', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        });
}

/**
 * RESET PWD PDF
 */
async function resetPwdPdf(token, executionId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/executions/students/' + executionId + '/generate_logins', requestOptions)
        .then(response => {
            if (response.status === 204)
                return null;

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        });
}

/**
 * RESET PASSWORD OF SINGLE USER AND RETURN DATA FOR PDF
 */
async function resetPwdUserPdf(token, userId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/users/generate_login/' + userId, requestOptions)
        .then(response => {
            if (response.status === 204)
                return null;

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        });
}

/**
* RESET USER
*/
async function resetUser(token, userId) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };

    return fetch(ENV.apiUrl + '/users/reset/' + userId, requestOptions)
        .then(response => {
            if (!response.ok && response.status !== 409) {
                return Promise.reject(response.statusText);
            } else if (!response.ok && response.status === 409) {
                return response.json();
            }

            return response;

        });

}
