import moment from 'moment';
import React from 'react';

export const isEquivalent = (a, b) => {

    if (!a || !b) {
        return false;
    }

    let aProps = Object.getOwnPropertyNames(a);
    let bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];


        if (a[propName] instanceof moment || b[propName] instanceof moment) {
            if (new Date(a[propName]).toString() !== new Date(b[propName]).toString()) {
                return false;
            }
        } else if (a[propName] instanceof Object && b[propName] instanceof Object) {
            if (!isEquivalent(a[propName], b[propName])) {
                return false;
            }
        } else if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

export const isEmail = (email) => {
    // eslint-disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export const replaceDateTimeSpecialChars = (datetime) => {

    let tmp = "";
    for (let i = 0; i < datetime.length; i++) {
        tmp = tmp + (datetime.charAt(i).match(/[0-9]/) ? datetime.charAt(i) : "");
        tmp = tmp + (datetime.charAt(i).match(/\./) ? datetime.charAt(i) : "");
        tmp = tmp + (datetime.charAt(i).match(/:/) ? datetime.charAt(i) : "");
        tmp = tmp + (datetime.charAt(i).match(/\s/) ? datetime.charAt(i) : "");
    }
    return tmp;
}

export const replaceLineBreakWithBr = (text) => {
    let tmp = [];
    text.trim().split(/(?:\r\n|\r|\n)/g).forEach(function (item, key) {
        tmp.push(<span key={key}>{item}<br /></span>);
    });
    return tmp;
}

export const sortProfessinalfields = (ob1, ob2) => {
    if (ob1.procent > ob2.procent) {
        return -1;
    } else if (ob1.procent < ob2.procent) {
        return 1;
    }

    // Else go to the 2nd item
    if (ob1.name < ob2.name) {
        return -1;
    } else if (ob1.name > ob2.name) {
        return 1
    } else { // nothing to split them
        return 0;
    }

}

export const replaceQuotationMarks = (text) => {
    let tmpA = text.split(/["„“]/);
    if (tmpA.length > 1) {
        tmpA = tmpA.map((t, index) => {
            return index === 0 ? t : index % 2 === 1 ? '„' + t : '“' + t;
        })
    }
    return tmpA.join('');
}

export const getCaretPos = (domElem) => {
    var pos;

    if (document.selection) {
        domElem.focus();
        var sel = document.selection.createRange();
        sel.moveStart('character', - domElem.value.length);
        pos = sel.text.length;
    }
    else if (domElem.selectionStart || domElem.selectionStart === '0') {
        pos = domElem.selectionStart;
    }
    return pos;
}

export const setCaretPos = (domElem, pos) => {
    if (domElem.setSelectionRange) {
        domElem.focus();
        domElem.setSelectionRange(pos, pos);
    }
    else if (domElem.createTextRange) {
        var range = domElem.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
}