import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Label, Container } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { filterActions, executionsActions } from '../actions';
import Loading from './Loading';

class StudentsButtons extends React.Component {

    static propTypes = {
        dispatchStudent: PropTypes.func.isRequired,
        dispatchGetStudentsForUser: PropTypes.func.isRequired,
        executionId: PropTypes.number.isRequired,
        students: PropTypes.array.isRequired,
        order: PropTypes.string,
        desc: PropTypes.bool,
        dataEnabled: PropTypes.bool,
        className: PropTypes.string,
        filter: PropTypes.array,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        ignoreHonorarkraft: PropTypes.bool,
    };

    static defaultProps = {
        desc: false,
        dataEnabled: null,
        withEmpty: false,
        filter: [],
        disabled: false,
        label: "studentbuttons.label.default",
        ignoreHonorarkraft: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            students: [],
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.executionId !== this.props.executionId && this.props.executionId > 0) || prevProps.honorarkraftId !== this.props.honorarkraftId) {
            //await this.setState();
            if (this.props.honorarkraftId === -1) {
                this.props.dispatchStudent(null);
            }
            this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId, this.props.ignoreHonorarkraft ? -1 : this.props.honorarkraftId);
        }

        if (this.props.executionId !== prevProps.executionId || this.props.students !== prevProps.students) {
            if (this.props.executionId > 0 && this.props.students.length > 0) {
                this.setState({ students: this.props.students.filter(el => !(this.props.filter.indexOf(el.id) >= 0)) });

                let existStudentId = this.props.students.filter(el => parseInt(el.id, 10) === this.props.studentId).length > 0;
                if (this.props.studentId < 0 || !existStudentId) {
                    this.props.dispatchStudent(this.props.students[0]);
                }
            } else {
                this.props.dispatchStudent(null);
                this.setState({ students: [] });
            }
        }
    }

    componentDidMount() {
        this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId, this.props.ignoreHonorarkraft ? -1 : this.props.honorarkraftId);
    }

    handleStudent = (student, e) => {
        if (this.props.handleStudent) {
            this.props.handleStudent(student);
        } else {
            if (!this.props.saving) {
                this.props.dispatchStudent(student);
            }
        }
    }

    render() {

        return (
            <Container fluid className="m-0 p-0">
                <Row className={this.state.students.length > 0 ? this.props.className : "d-none " + this.props.className}>
                    <Col xs="12" className="fw-bold">
                        <FormattedMessage id={this.props.label} />
                    </Col>
                    {this.state.students.map((student) => {
                        return <Col xs="12" sm="6" md="6" xl="3" className="radiostudents-container" key={"colstudents" + student.id}>
                            {/*<Button color="primary" block className="m-0 mb-2 text-truncate" key={"btnstudents" + student.id} name={"btnstudents" + student.id} id={student.id}>{student.firstname} {student.lastname} {student.class && "(" + student.class + ")"}</Button> */}
                            <input type="radio" disabled={this.props.disabled || this.props.saving} className="radiostudents-input" checked={parseInt(this.props.studentId, 10) === parseInt(student.id, 10)} name="btnstudents" id={"radiostudents" + student.id} value={student.id} onChange={this.handleStudent.bind(this, student)} />
                            <Label for={"radiostudents" + student.id} className="radiostudents-label" ><span>{student.firstname} {student.lastname} {student.class && "(" + student.class + ")"}</span></Label>
                        </Col>
                    })}

                </Row>
                <Row className={this.state.students.length === 0 ? this.props.className : "d-none"}>
                    <Col xs="12" className="fw-bold">
                        <FormattedMessage id={this.props.label} />
                    </Col>
                    {
                        /* Loading, no student available and no execution select */
                        this.props.loading ?
                            <Loading />
                            :
                            this.props.executionId > 0 ?
                                <Col xs="12" className="fw-bold text-uppercase">
                                    <FormattedMessage id="studentbuttons.label.empty" />
                                </Col>
                                :
                                <Col xs="12" className="fw-bold text-uppercase">
                                    <FormattedMessage id="studentbuttons.label.emptyexecution" />
                                </Col>
                    }
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        studentId: state.filter.student && state.filter.student.id ? state.filter.student.id : -1,
        students: state.executions.studentsforuser,
        loading: state.executions.loadingstudentsforuser,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        honorarkraftId: state.filter.honorarkraft && state.executions.honorarkraft && state.executions.honorarkraft.length > 0 ? state.filter.honorarkraft.id : -1,
        error: state.observations.observationsError !== '' || state.competences.competencesError !== '' || state.skillsProfile.skillsProfileError !== '' ? true : false,
        saving: state.observations.saving || state.competences.saving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetStudentsForUser: (token, executionId, honorarkraftId) => {
            dispatch(executionsActions.getStudentsForUser(token, executionId, honorarkraftId));
        },
        dispatchStudent: (student) => {
            dispatch(filterActions.student(student));
        }
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentsButtons));