import { actionTypes } from '../actions/types';

const initialState = {
    inquiry_rejected: true,
    inquiry_past: false,
    executions_cooperation: false,
    executions_past: false,
    execution: null,
    school: null,
    student: null,
    department: null,
    schooltype: null,
    schoolyear: null,
    observationsevaluations: 1, // 1 = observation, 2 = evaluation
    role: null,
    honorarkraft: null,
    certified: null,
    procedure:  null
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FILTER_INQUIRY_REJECTED:
            return {
                ...state,
                inquiry_rejected: !state.inquiry_rejected
            };
        case actionTypes.FILTER_INQUIRY_PAST:
            return {
                ...state,
                inquiry_past: !state.inquiry_past
            };
        case actionTypes.FILTER_EXECUTIONS_PAST:
            return {
                ...state,
                executions_past: !state.executions_past
            };
        case actionTypes.FILTER_EXECUTIONS_COOPERATION:
            return {
                ...state,
                executions_cooperation: !state.executions_cooperation
            };
        case actionTypes.FILTER_EXECUTION:
            return {
                ...state,
                execution: action.execution,
                student: null,
            };
        case actionTypes.FILTER_SCHOOL:
            return {
                ...state,
                school: action.school
            };
        case actionTypes.FILTER_DEPARTMENT:
            return {
                ...state,
                department: action.department
            };
        case actionTypes.FILTER_STUDENT:
            return {
                ...state,
                student: action.student
            };
        case actionTypes.FILTER_SCHOOLTYPE:
            return {
                ...state,
                schooltype: action.schooltype
            };
        case actionTypes.FILTER_SCHOOLYEAR:
            return {
                ...state,
                schoolyear: action.schoolyear
            };
        case actionTypes.FILTER_OBSERVATIONSEVALUATIONS:
            return {
                ...state,
                observationsevaluations: action.observationsevaluations,
            };
        case actionTypes.FILTER_ROLE:
            return {
                ...state,
                role: action.role,
            };
        case actionTypes.FILTER_HONORARKRAFT:
            return {
                ...state,
                honorarkraft: action.honorarkraft,
            };
        case actionTypes.FILTER_CERTIFIED:
            return {
                ...state,
                certified: action.certified,
            };
        case actionTypes.FILTER_PROCEDURE:
            return {
                ...state,
                procedure: action.procedure
            };
        case actionTypes.FILTER_PROCEDURE_INTERN:
            return {
                ...state,
                procedureIntern: action.procedure
            };
        default:
            return state;
    }
};

export default filterReducer;
