import * as React from 'react';
import { Container } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { connect } from 'react-redux';

class ImageHeader extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {

        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {

        return (
            <Container fluid className="m-0 p-0 d-flex flex-column justify-content-end align-items-end" id="imageheader">
                <div id="title"><FormattedMessage id="imageheader.label.kompo7" /></div>
                <div id="subtitle"><FormattedMessage id="imageheader.label.competence" /></div>             
            </Container>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchIntlUpdate: (translation) => {
            dispatch(updateIntl(translation));
        }
    };
};


export default injectIntl(connect(
    null,
    mapDispatchToProps
)(ImageHeader));