import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Collapse, Label, Button, Table, Alert } from 'reactstrap';
import ExecutionSelect from '../components/ExecutionSelect';
import ExecutionsPastCheckbox from '../components/ExecutionsPastCheckbox';
import SchoolSelect from '../components/SchoolSelect';
import StudentsButtons from '../components/StudentsButtons';
import GeneratePDF from '../components/GeneratePDF';
import Loading from '../components/Loading';
import { schoolsActions, filterActions, skillsProfilesActions, competencesActions, executionsActions, kompoGResultActions } from '../actions';
import config from '../config/config';
import translationsVars from '../translations/translationsVars';
import moment from 'moment';
import { generateResultPDF } from '../pdf';
import HonorarkraftSelect from '../components/HonorarkraftSelect';
import Authorization from '../components/Authorization';
import { CheckAuthorization } from '../models/Authorization';
import { Bar } from 'react-chartjs-2';
import { replaceLineBreakWithBr, sortProfessinalfields } from '../utils/Tools';
import hookImg from '../assets/images/Haken.png';
import emptyImg from '../assets/images/Empty.png';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import { generateMyResultPDF } from '../pdf/MyResultsPDF';
import Confirm from '../components/Confirm';

class ResultsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapse: {
                about: true,
                competences: true,
                interesttest: true,
                recommendation: true,
                selfexternalassessment: false,
                skillsprofile: false,
                participationcertificate: false
            },
            variantTextId: 'skillsprofile.body.variant.1.3',
            variantPrintId: 'skillsprofile.print.body.variant.1.3',
            alert: { visible: false, textId: "alert.save", color: "success" },
            translationsVars: translationsVars,
            order: "lastname",
            desc: false,
            enabled: true,
            pdfLoading: false,
            //kompog:
            resetId: -1,
            confirmReset: false,
            students: [],
            th: [
                { text: "results.kompog.th.firstname",/**  order: "firstname",*/ width: "10%" },
                { text: "results.kompog.th.lastname", /**  order: "lastname", */ width: "10%" },
                { text: "results.kompog.th.competences", width: "8%" },
                { text: "results.kompog.th.interests", width: "8%" },
                { text: "results.kompog.th.motivations", width: "8%" },
                { text: "results.kompog.th.results", width: "8%" },
                { text: "results.kompog.th.impulses", width: "8%" },
                { text: "results.kompog.th.recommendation", width: "30%" },
                { text: "results.kompog.th.actions", width: "10%" },
            ],
            tb: [],
            tbshow: ["firstname", "lastname", "icon_competences_completed", "icon_interests_completed", "icon_motivations_completed",
                "icon_results_completed", "icon_impulses_completed", "textarea"],
        }
    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);

        if (CheckAuthorization('results-honorarkraftselect', this.props.auth.roles)) {
            this.props.dispatchGetExecutionsHonorarkraft(this.props.auth.token, this.props.executionId);
        }

        if (this.props.executionId >= 0 && this.props.studentId >= 0) {
            if (this.props.executionProcedure === 1) {//Kompo7
                this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
                this.props.dispatchGetCompetencesStudentObserver(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId, this.state.order, this.state.desc, this.state.enabled);
                this.props.dispatchGetSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId);

                let startdate = this.props.execution ? moment(this.props.execution.start.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.execution.start.replace(" ", "T"), config.dateFormat.format, true) : null : null;
                let enddate = this.props.execution ? moment(this.props.execution.end.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.execution.end.replace(" ", "T"), config.dateFormat.format, true) : null : null;

                this.setState(
                    {
                        translationsVars:
                        {
                            ...this.state.translationsVars,
                            filename: this.props.studentFirstname + "_" + this.props.studentLastname,
                            name: this.props.studentFirstname,
                            executionstartdate: startdate ? this.props.intl.formatDate(startdate, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }) : "",
                            executionenddate: enddate ? this.props.intl.formatDate(enddate, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }) : "",
                        },
                        variantTextId: 'skillsprofile.body.variant.1.' + this.props.studentAddress,
                        variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.studentAddress
                    }
                );
            }
        } else if (this.props.executionProcedure === 2) {//Kompog
            this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId, this.props.honorarkraftId !== -1 ? this.props.honorarkraftId : this.props.auth.user_id);
            this.props.dispatchGetKompogResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc);
        }
    }


    componentWillUnmount() {
        if (this.props.executionId > 0 && this.props.execution && this.props.execution.procedure && this.props.execution.procedure.id === 2) {
            this.props.dispatchExecution(-1);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.executionId !== this.props.executionId || prevProps.studentId !== this.props.studentId || prevProps.honorarkraftId !== this.props.honorarkraftId) {
            if (CheckAuthorization('results-honorarkraftselect', this.props.auth.roles)) {
                this.props.dispatchGetExecutionsHonorarkraft(this.props.auth.token, this.props.executionId);
            }
            if (this.props.executionProcedure === 1) {//Kompo7
                this.props.dispatchGetCompetences(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId);
                this.props.dispatchGetCompetencesStudentObserver(this.props.auth.token, this.props.executionId, this.props.studentId, this.props.honorarkraftId, this.state.order, this.state.desc, this.state.enabled);
                this.props.dispatchGetSkillsProfile(this.props.auth.token, this.props.executionId, this.props.studentId);

                let startdate = this.props.execution ? moment(this.props.execution.start.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.execution.start.replace(" ", "T"), config.dateFormat.format, true) : null : null;
                let enddate = this.props.execution ? moment(this.props.execution.end.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.execution.end.replace(" ", "T"), config.dateFormat.format, true) : null : null;

                this.setState(
                    {
                        translationsVars:
                        {
                            ...this.state.translationsVars,
                            filename: this.props.studentFirstname + "_" + this.props.studentLastname,
                            name: this.props.studentFirstname,
                            executionstartdate: startdate ? this.props.intl.formatDate(startdate, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }) : "",
                            executionenddate: enddate ? this.props.intl.formatDate(enddate, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }) : "",
                        },
                        variantTextId: 'skillsprofile.body.variant.1.' + this.props.studentAddress,
                        variantPrintId: 'skillsprofile.print.body.variant.1.' + this.props.studentAddress
                    }
                );
            } else if (this.props.executionProcedure === 2) {//Kompog
                this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId, this.props.honorarkraftId !== -1 ? this.props.honorarkraftId : this.props.auth.user_id);
                this.props.dispatchGetKompogResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc);
            }
        }

        if (prevProps.skillsProfile !== this.props.skillsProfile && this.props.skillsProfile && this.props.skillsProfile.jobvariant) {
            this.setState(
                {
                    variantTextId: 'skillsprofile.body.variant.' + this.props.skillsProfile.jobvariant + '.' + this.props.studentAddress,
                    variantPrintId: 'skillsprofile.print.body.variant.' + this.props.skillsProfile.jobvariant + '.' + this.props.studentAddress,
                }
            )
        }

        if (this.props.students !== prevProps.students) {//add compare
            if (this.props.students.length > 0) {//Kompog 
                let formattedData = this.convertDataToTableBodyFormat(this.props.students);
                this.setState({ students: formattedData });
            } else {
                this.setState({ students: [] });
            }
        }

        if (this.props.kompoGSaveRelease !== prevProps.kompoGSaveRelease) {
            if (this.props.kompoGSaveRelease) {
                this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId, this.props.honorarkraftId !== -1 ? this.props.honorarkraftId : this.props.auth.user_id);
                this.props.dispatchGetKompogResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc);
            } else {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

    }

    hideAlert() {
        this.setState({ alert: { ...this.state.alert, visible: false } })
    }

    convertDataToTableBodyFormat(students) {
        let studentsCopy = JSON.parse(JSON.stringify(students));
        for (let i = 0; i < studentsCopy.length; i++) {
            if (studentsCopy[i].competences_completed) {
                studentsCopy[i].icon_competences_completed = hookImg;
            } else {
                studentsCopy[i].icon_competences_completed = emptyImg;
            }
            if (studentsCopy[i].interests_completed) {
                studentsCopy[i].icon_interests_completed = hookImg;
            } else {
                studentsCopy[i].icon_interests_completed = emptyImg;
            }
            if (studentsCopy[i].motivations_completed) {
                studentsCopy[i].icon_motivations_completed = hookImg;
            } else {
                studentsCopy[i].icon_motivations_completed = emptyImg;
            }
            if (studentsCopy[i].results_completed) {
                studentsCopy[i].icon_results_completed = hookImg;
            } else {
                studentsCopy[i].icon_results_completed = emptyImg;
            }
            if (studentsCopy[i].impulses_completed) {
                studentsCopy[i].icon_impulses_completed = hookImg;
            } else {
                studentsCopy[i].icon_impulses_completed = emptyImg;
            }
            if (studentsCopy[i].feedbackTeacher) {
                studentsCopy[i].textarea = { text: studentsCopy[i].feedbackTeacher, edit: studentsCopy[i].releaseTeacher === 0 ? true : false };
            } else {
                studentsCopy[i].textarea = { text: "", edit: studentsCopy[i].releaseTeacher === 0 ? true : false };
            }

        }
        return studentsCopy;
    }

    handleCollapse = (c) => {
        switch (c) {
            case 'about':
                this.setState({ collapse: { ...this.state.collapse, about: !this.state.collapse.about } })
                break;
            case 'competences':
                this.setState({ collapse: { ...this.state.collapse, competences: !this.state.collapse.competences } })
                break;
            case 'interesttest':
                this.setState({ collapse: { ...this.state.collapse, interesttest: !this.state.collapse.interesttest } })
                break;
            case 'recommendation':
                this.setState({ collapse: { ...this.state.collapse, recommendation: !this.state.collapse.recommendation } })
                break;
            case 'selfexternalassessment':
                this.setState({ collapse: { ...this.state.collapse, selfexternalassessment: !this.state.collapse.selfexternalassessment } })
                break;
            case 'skillsprofile':
                this.setState({ collapse: { ...this.state.collapse, skillsprofile: !this.state.collapse.skillsprofile } })
                break;
            case 'participationcertificate':
                this.setState({ collapse: { ...this.state.collapse, participationcertificate: !this.state.collapse.participationcertificate } })
                break;
            default:
                break;
        }

    }


    handlePDF = async () => {
        generateResultPDF(this.props, this.state);
    }

    handlePDFKompog = async () => {
        await this.setState({ pdfLoading: true });
        let studentResultTmp = [];
        let studentTmp;
        this.state.students.forEach(student => {
            studentTmp = this.props.studentResults.find(s => s.id === student.id.toString());
            if (studentTmp) {
                studentResultTmp.push(studentTmp);
            }
        })

        let documentname = this.props.intl.formatMessage({ id: "myresultPage.kompoG.label" });

        setTimeout(async () => {
            generateMyResultPDF(this.props, this.state, studentResultTmp).download(documentname);
            await this.setState({ pdfLoading: false });
        }, 100);
    }

    getBarData(bit) {
        let barData = { labels: [], datasets: [], borderWidth: 0.5 };
        const barColors = ['rgba(56,57,128,0.75)', 'rgba(56,57,128,0.75)', 'rgba(56,57,128,0.75)']; // single color

        if (bit && bit.interesttest) {
            bit.interesttest.sort(sortProfessinalfields);
            let d = [];
            let colors = [];
            bit.interesttest.forEach((pf) => {
                let p = pf.value * 100 / pf.maxValue;
                colors.push(p >= 66.6 ? barColors[0] : p >= 33.3 ? barColors[1] : barColors[2]);
                d.push(p)
                barData.labels.push(this.props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" }));
            })
            barData.datasets.push({ label: this.props.intl.formatMessage({ id: "interesttest.chart.value" }), data: d, backgroundColor: colors });
        }
        return barData;
    }

    handleKompogPupilPDF = async (id) => {
        await this.setState({ pdfLoading: true });
        let studentResults = this.props.studentResults.find(s => s.id === id.toString());
        let documentname = this.props.intl.formatMessage({ id: "myresultPage.kompoG.label" });
        if (studentResults !== undefined) {
            documentname += "-" + studentResults.username.replace(/ /g, "_");
        }
        setTimeout(async () => {
            if (studentResults !== undefined) {
                generateMyResultPDF(this.props, this.state, [studentResults]).download(documentname);
            }
            await this.setState({ pdfLoading: false });
        }, 100, id);
    }

    handleReset = (id) => {
        this.setState({ resetId: id });
        this.toggleConfirmReset();
    }

    toggleConfirmReset = (e) => {
        this.setState({
            confirmReset: !this.state.confirmReset
        });
    }

    reset = async (e) => {
        if (this.state.resetId) {
            let student = this.state.students.find(s => s.id === this.state.resetId);
            if (student) {
                let feedback = [];
                feedback.push({ id: student.id, feedbackTeacher: student.feedbackTeacher, releaseTeacher: 1 });
                this.props.dispatchUpdateResultTeacher(this.props.auth.token, this.props.executionId, feedback);
            }
        }
        this.toggleConfirmReset();
    }

    handleEditChange = (user, data) => {
        if (user.feedbackTeacher !== data) {
            let students = this.state.students;
            students.forEach(s => { if (s.id === user.id) { s.feedbackTeacher = data; s.textarea = { text: data, edit: true }; } });
            this.setState({ students: students })
        }
    }

    handleSaveKompog = () => {
        let feedback = [];
        this.state.students.forEach(student => {
            feedback.push({ id: student.id, feedbackTeacher: student.feedbackTeacher, releaseTeacher: student.releaseTeacher });
        });
        this.props.dispatchUpdateResultTeacher(this.props.auth.token, this.props.executionId, feedback);
    }


    order = async (order, desc, e) => {
        await this.setState({ order: order, desc: desc });
        this.props.dispatchGetStudentsForUser(this.props.auth.token, this.props.executionId, this.props.honorarkraftId !== -1 ? this.props.honorarkraftId : this.props.auth.user_id);
        this.props.dispatchGetKompogResults(this.props.auth.token, this.props.executionId, this.state.order, this.state.desc);
    }

    render() {
        let configIcons = config.icons;

        let barOptions = {
            indexAxis: 'y',
            scales: {

                x: {
                    // display: false,
                    min: 0,
                    max: 100,
                    ticks: {
                        display: false,
                        stepSize: 33.4,
                        suggestedMin: 0,
                        suggestedMax: 100,
                        max: 100
                    },
                    grid: {
                        color: '#bababa',
                        offsetGridLines: true,
                        lineWidth: 1,
                    },
                    // offset: true,
                }
            },
            plugins: {
                legend: { display: false },
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let label = data.datasets[tooltipItem.datasetIndex].label || '';
                        if (label) {
                            label += ': ';
                            label += parseFloat(tooltipItem.xLabel).toFixed(0) + " %";
                        }
                        return label;
                    }
                }
            }
        };
        let barData = this.getBarData(this.props.skillsProfile);

        return (
            <Container fluid role="main" className="content-margin">
                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                {/* Confirm reset */}
                <Confirm open={this.state.confirmReset} toggle={this.toggleConfirmReset} no={this.toggleConfirmReset} yes={this.reset} headertext="confirm.header.approved" bodytext="confirm.body.approved" />

                {/* PDF LOADING */}
                {this.state.pdfLoading && <Loading absolute />}

                <h1><FormattedMessage id="results.title" /></h1>
                <Row>
                    <Col xs="12" className="my-3">
                        <ExecutionsPastCheckbox />
                    </Col>
                </Row>
                { /* Select School / Execution / Honorarkraft */}
                <Row>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3" className={this.props.schools.length > 1 ? "" : "d-none"}>
                        <SchoolSelect className="mb-3 p-0" withEmpty label="results.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="6" xl="3">
                        <ExecutionSelect className="mb-3 p-0" withEmpty label="results.label.execution" executionId={this.props.executionId} schoolId={this.props.schoolId} onlyInvolved />
                    </Col>
                    <Authorization id="results-honorarkraftselect">
                        <Col xs="12" sm="6" md="6" lg="6" xl="3">
                            <HonorarkraftSelect className={this.props.executionId > 0 && this.props.honorarkraft.length > 0 ? "mb-3 p-0" : "d-none"} withEmpty label="observationsevaluations.label.honorarkraft" honorarkraft={this.props.honorarkraft} handleHonorarkraft={this.props.dispatchHonorarkraft.bind(this)} honorarkraftId={this.props.honorarkraftId} />
                        </Col>
                    </Authorization>
                </Row>
                {this.props.executionProcedure === 1 &&
                    <div>
                        { /* Students */}
                        <StudentsButtons className="mt-4" executionId={this.props.executionId} disabled={this.props.loading} />

                        {/* Loading */}
                        {this.props.loading && <Loading />}


                        {/* PDF */}
                        <Row className={this.props.studentId > 0 && this.props.skillsProfile && this.props.skillsProfile.approved && !this.props.loading ? "pt-2" : "d-none"}>
                            <Col xs="12">
                                <Container fluid className="p-0 mb-4">
                                    <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this, this.props.intl.formatMessage({ id: "results.print.filename" }, this.state.translationsVars))} />
                                </Container>
                            </Col>
                        </Row>

                        {/*  SKILLSPROFILE */}
                        <Row className={this.props.studentId > 0 && this.props.skillsProfile && this.props.skillsProfile.approved && !this.props.loading ? "px-3" : "d-none"} >
                            <Col xs="12" className="border-bottom p-0 py-3" >
                                <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.abskillsprofileout ? "collapse.close" : "collapse.open"} /></Label>
                                <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'skillsprofile')} >
                                    <span className={this.state.collapse.skillsprofile ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                    <FormattedMessage id="results.label.skillsprofile" />
                                </Button>
                            </Col>
                        </Row>
                        <Container fluid className={this.props.studentId > 0 && this.props.skillsProfile && this.props.skillsProfile.approved && !this.props.loading ? "m-0 p-0" : "d-none m-0 p-0"}>
                            <Collapse isOpen={this.state.collapse.skillsprofile} className="border-bottom ps-4">
                                {/* ABOUT */}
                                <Row className="px-3">
                                    <Col xs="12" className="border-bottom p-0 py-3" >
                                        <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.about ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'about')} >
                                            <span className={this.state.collapse.about ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="skillsprofile.header.about" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.collapse.about} className="border-bottom">
                                    <Row className="px-3">
                                        <Col xs="12" className="text-justify py-3" style={{ paddingLeft: '1.35rem' }}>
                                            <FormattedMessage id="skillsprofile.body.about" values={this.state.translationsVars} />
                                        </Col>
                                    </Row>
                                </Collapse>
                                {/* RESULTS */}
                                <Row className="px-3">
                                    <Col xs="12" className=" py-3 pe-0 text-black fw-bold" style={{ paddingLeft: '1.35rem' }} >
                                        <FormattedMessage id="skillsprofile.header.results" />
                                    </Col>
                                </Row>
                                {/* INTERESTTEST */}
                                <Row className="px-3">
                                    <Col xs="12" className="mt-0 py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                                        <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.interesttest ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0 fw-bold" onClick={this.handleCollapse.bind(this, 'interesttest')} >
                                            <span className={this.state.collapse.interesttest ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="skillsprofile.subtitle.interesttest" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.collapse.interesttest}>
                                    <Row className="px-3">
                                        <Col xs="12" className="m-0" style={{ paddingLeft: '1.35rem' }}>
                                            <Row>
                                                <Col xs="12" className="text-justify pb-3 border-bottom" style={{ paddingLeft: '2.5rem' }}>
                                                    {
                                                        (this.props.skillsProfile === null || (this.props.skillsProfile && this.props.skillsProfile.interesttest === null)) &&
                                                        <FormattedMessage id={"professionalfield.text.nointeresttestcompleted"} values={this.state.translationsVars} />
                                                    }
                                                    {
                                                        this.props.skillsProfile && this.props.skillsProfile.interesttest !== null && this.props.skillsProfile.interesttest.length === 0 &&
                                                        <FormattedMessage id={"professionalfield.text.nointerest." + this.props.studentAddress} values={this.state.translationsVars} />
                                                    }
                                                    {
                                                        this.props.skillsProfile && this.props.skillsProfile.interesttest && this.props.skillsProfile.interesttest.length >= 1 &&
                                                        <div className="container-bar">
                                                            <Bar data={barData} responsive="false" height={100} options={barOptions} />
                                                            <div id="container-smiley">
                                                                <div id="container-bit-smiley-1"></div>
                                                                <div id="container-bit-smiley-2"></div>
                                                                <div id="container-bit-smiley-3"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse>

                                <Row className="px-3">
                                    <Col xs="12" className="py-3 pe-0" style={{ paddingLeft: '1.35rem' }}>
                                        <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.competences ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'competences')} >
                                            <span className={this.state.collapse.competences ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="skillsprofile.subtitle.competences" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.collapse.competences}>
                                    {/* COMPETENCES */
                                        /* filter competences with no rating */
                                        this.props.competences && this.props.competences.filter((competence) => {
                                            let tmp = false;
                                            this.props.studentObserverRating && this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.forEach((rating) => {

                                                if (competence.id === rating.competences_id && this.props.skillsProfile && this.props.skillsProfile.competences && this.props.skillsProfile.competences.indexOf(rating.id) !== -1) {
                                                    tmp = true;
                                                }
                                            })
                                            return tmp;
                                        }).map((competence, index) => {
                                            let rating = null;
                                            this.props.studentObserverRating.observer.forEach((r) => {
                                                if (competence.id === r.competences_id) {
                                                    rating = r;
                                                }
                                                return null;
                                            });
                                            return (
                                                <Row className="px-3" key={"competence" + index} >
                                                    <Col xs="12" className="m-0" style={{ paddingLeft: '1.35rem' }}>
                                                        <Row >
                                                            <Col xs="12" className="pt-3">
                                                                <Label for={"competence" + index} className="ps-4 mb-0 fw-bold">
                                                                    <FormattedMessage id={competence.name} />
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="12" className="border-bottom pb-3" style={{ paddingLeft: '2.5rem' }}>
                                                                {
                                                                    rating && rating.observations && replaceLineBreakWithBr(rating.observations)
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    {

                                        /* no rating for competences */
                                        this.props.skillsProfile && this.props.skillsProfile.competences && this.props.skillsProfile.competences.length === 0 &&
                                        <Row className="px-3" >
                                            <Col xs="12" style={{ paddingLeft: '2.75rem' }}><FormattedMessage id="skillsprofile.placeholder.competences" /></Col>
                                        </Row>
                                    }

                                </Collapse>

                                {/* RECOMMENDATION */}
                                <Row className="px-3">
                                    <Col xs="12" className="py-3 px-0 border-bottom" >
                                        <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.recommendation ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button name="collapse-about" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'recommendation')} >
                                            <span className={this.state.collapse.recommendation ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="skillsprofile.header.recommendation" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Collapse isOpen={this.state.collapse.recommendation}>
                                    {this.props.skillsProfile && this.props.skillsProfile.jobtext && <Row className="px-3">
                                        <Col xs="12" className="text-justify py-3" style={{ paddingLeft: '1.35rem' }} >
                                            {replaceLineBreakWithBr(this.props.skillsProfile.jobtext)}
                                        </Col>
                                    </Row>}
                                    <Row className="px-3">
                                        <Col xs="12" className="text-justify py-3" style={{ paddingLeft: '1.35rem' }}>
                                            <FormattedMessage id={this.state.variantTextId} values={this.state.translationsVars} />
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Collapse>
                        </Container>

                        {/* Self- and externalassessment */}
                        <Row className={this.props.studentId > 0 && this.props.skillsProfile && this.props.skillsProfile.approved && !this.props.loading ? "pt-2 px-3" : "d-none"}>
                            <Col xs="12" className="border-bottom p-0 py-3" >
                                <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.selfexternalassessment ? "collapse.close" : "collapse.open"} /></Label>
                                <Button name="collapse-selfexternalassessment" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'selfexternalassessment')} >
                                    <span className={this.state.collapse.selfexternalassessment ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                    <FormattedMessage id="results.label.selfexternalassessment" />
                                </Button>
                            </Col>
                        </Row>
                        <Collapse isOpen={this.state.collapse.selfexternalassessment}>
                            <Container className={this.props.studentId > 0 && this.props.skillsProfile && this.props.skillsProfile.approved && !this.props.loading ? "bg-white p-0" : "d-none"} fluid>
                                <Container className=" bg-white m-0 mt-2" fluid>
                                    <Row>
                                        <Col xs="4" className="text-uppercase border fw-bold d-flex flex-column justify-content-end py-2">
                                            <FormattedMessage id="results.label.competences" />
                                        </Col>
                                        <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                            <img className="th-smiley mb-2" src={require('../assets/images/Smiley_01.svg').default} alt="" />
                                            <br />
                                            <FormattedMessage id="results.label.rating-1" />
                                        </Col>
                                        <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                            <img className="th-smiley mb-2" src={require('../assets/images/Smiley_02.svg').default} alt="" />
                                            <br />
                                            <FormattedMessage id="results.label.rating-2" />
                                        </Col>
                                        <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                            <img className="th-smiley mb-2" src={require('../assets/images/Smiley_03.svg').default} alt="" />
                                            <br />
                                            <FormattedMessage id="results.label.rating-3" />
                                        </Col>
                                        <Col xs="2" className="th-smiley-container border border-start-0 py-2">
                                            <img className="th-smiley mb-2" src={require('../assets/images/Smiley_04.svg').default} alt="" />
                                            <br />
                                            <FormattedMessage id="results.label.rating-4" />
                                        </Col>
                                    </Row>
                                </Container>
                                {/* Table */}
                                <Container fluid className="mb-1" >
                                    {
                                        this.props.competences.map((trData, trIndex) => {
                                            return <Row key={trIndex} className="row-striped">
                                                <Col xs="4" className="border py-2">
                                                    <p><FormattedMessage id={trData.description} /></p>
                                                    <p className="text-uppercase text-italic mb-0 pb-0"><em><FormattedMessage id={trData.name} /></em></p>
                                                </Col>
                                                <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                    {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 1)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                    {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 1 || el.rating2 === 1))).length > 0 ? <span className="externalassessment"></span> : ""}
                                                </Col>
                                                <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                    {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 2)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                    {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 2 || el.rating2 === 2))).length > 0 ? <span className="externalassessment"></span> : ""}
                                                </Col>
                                                <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                    {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 3)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                    {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 3 || el.rating2 === 3))).length > 0 ? <span className="externalassessment"></span> : ""}
                                                </Col>
                                                <Col xs="2" className="p-0 competences-td border border-start-0 py-2">
                                                    {this.props.studentObserverRating.student && this.props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 4)).length > 0 ? <span className="selfassessment"></span> : ""}
                                                    {this.props.studentObserverRating.observer && this.props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 4 || el.rating2 === 4))).length > 0 ? <span className="externalassessment"></span> : ""}
                                                </Col>
                                            </Row>
                                        })
                                    }
                                </Container>
                                {/* Descriptions */}

                                <Row className={this.props.studentId > 0 ? "small fw-bold mb-4 mt-0 ps-3 pt-0" : "d-none"}>
                                    <Col className="pt-0 mt-0" xs="12">
                                        <FormattedMessage id="results.description.label.selfassessment" />
                                        <img className="descriptionselfassessment" src={require('../assets/' + configIcons.student)} alt={this.props.intl.formatMessage({ id: "results.description.alt.externalassessment" })} />
                                        <FormattedMessage id="results.description.label.externalassessment" />
                                        <img className="descriptionexternalassessment" src={require('../assets/' + configIcons.observer)} alt={this.props.intl.formatMessage({ id: "results.description.alt.noassessment" })} />
                                        <FormattedMessage id="results.description.label.noassessment" />
                                    </Col>
                                </Row>
                            </Container>
                        </Collapse>

                        {/* Participation certificate */}
                        {/* <Row className={this.props.studentId > 0 && this.props.skillsProfile && this.props.skillsProfile.approved ? "pt-2 px-3" : "d-none"}>
                        <Col xs="12" className="border-bottom p-0 py-3" >
                            <Label className="d-none" for="collapse-about"><FormattedMessage id={this.state.collapse.participationcertificate ? "collapse.close" : "collapse.open"} /></Label>
                            <Button name="collapse-participationcertificate" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'participationcertificate')} >
                                <span className={this.state.collapse.participationcertificate ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="results.label.participation-certificate" />
                            </Button>
                        </Col>
                    </Row>
                    <Collapse isOpen={this.state.collapse.participationcertificate}>
                        <Row className={this.props.studentId > 0 && this.props.skillsProfile && this.props.skillsProfile.approved ? "mb-4 ps-3" : "d-none"}>
                            <Col xs="12" className="pt-3" style={{ paddingLeft: '1.35rem' }}>
                                <h3 className="text-center">{this.props.studentFirstname} {this.props.studentLastname}</h3>
                                <p className="text-center"><FormattedMessage id="results.participation-certificate.execution" values={this.state.translationsVars} /></p>
                                <h4 className="text-center">KomPo7</h4>
                                <p className="text-justify"><FormattedMessage id="results.participation-certificate.body" values={this.state.translationsVars} /></p>
                                <p className="pb-4">{this.props.execution && this.props.execution.school.place},<FormattedDate value={moment()} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /></p>
                                <p className="pt-4">                                
                                    {this.props.skillsProfile && this.props.skillsProfile.observer && this.props.skillsProfile.observer.firstname + " " + this.props.skillsProfile.observer.lastname}
                                    <br />
                                    {this.props.execution && this.props.execution.school.name}, {this.props.execution && this.props.execution.school.place}
                                </p>

                            </Col>
                        </Row>
                    </Collapse> */}

                        {/* NO SKILLSPROFILE APPROVED */}
                        <Container fluid className={this.props.studentId > 0 && (!this.props.skillsProfile || !this.props.skillsProfile.approved) && !this.props.loading ? "fw-bold pt-4 ps-0 text-uppercase" : "d-none"}>
                            <FormattedMessage id="results.label.noskillsprofile" />
                        </Container>
                    </div>}{/*end of kompo7 */}
                {this.props.executionProcedure === 2 &&
                    <div role="main">
                        {/* <Button  color="primary" className="mb-4" onClick={this.handleSaveKompog.bind(this)} ><FormattedMessage id="button.save" /></Button> */}
                        { /* Table */}
                        <GeneratePDF className="p-0 m-0 sticky2 bg-white mb-3" handlePDF={this.handlePDFKompog.bind(this)} />
                        <Container fluid className="m-0 p-0 border">
                            <Table striped bordered className="m-0 p-0">
                                <TableHead data={this.state.th} order={this.order} orderDefault={this.state.order} />
                                <TableBody data={this.state.students}
                                    loading={this.props.loading}
                                    show={this.state.tbshow}
                                    printG={this.handleKompogPupilPDF}
                                    release={this.props.auth.roles.find(e => e === 6) ? null : this.handleReset}
                                    editField={true}
                                    editChange={this.handleEditChange.bind()} />
                            </Table>
                        </Container>
                        <Container fluid className="p-0 m-0 d-flex justify-content-end flex-row mt-4">
                            <Button color="primary" onClick={this.handleSaveKompog.bind(this)} ><FormattedMessage id="button.save" /></Button>
                        </Container>
                    </div>//end of kompog
                }
            </Container >
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        executions: state.executions.executions ? state.executions.executions : [],
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        executionProcedure: state.filter.execution && state.filter.execution.procedure ? state.filter.execution.procedure.id : -1,
        execution: state.filter.execution,
        studentId: state.filter.student && state.filter.student.id ? state.filter.student.id : -1,
        studentFirstname: state.filter.student && state.filter.student.firstname ? state.filter.student.firstname : "",
        studentLastname: state.filter.student && state.filter.student.lastname ? state.filter.student.lastname : "",
        studentClass: state.filter.student && state.filter.student.class ? state.filter.student.class : "",
        studentAddress: state.filter.student && state.filter.student.address && state.filter.student.address.id ? state.filter.student.address.id : 3,
        skillsProfile: state.skillsProfile.skillsProfile,
        studentObserverRating: state.competences.studentObserverRating,
        competences: state.competences.competences,
        honorarkraft: state.executions.honorarkraft ? state.executions.honorarkraft : [],
        honorarkraftId: state.filter.honorarkraft && state.executions.honorarkraft && state.executions.honorarkraft.length > 0 ? state.filter.honorarkraft.id : -1,
        loading: state.competences.loading || state.competences.loadingstudentObserverRating || state.skillsProfile.loading,
        students: state.executions.studentsforuser,
        kompoGSaveRelease: state.kompoGResult.saveStatus,
        studentResults: state.kompoGResult.results,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetCompetences: (token, executionId, studentId, honorarkraftId) => {
            dispatch(competencesActions.getCompetences(token, executionId, studentId, honorarkraftId, '', false, 1));
        },
        dispatchGetCompetencesStudentObserver: (token, executionId, studentId, honorarkraftId, order, desc, enabled) => {
            dispatch(competencesActions.getCompetencesStudentObserver(token, executionId, studentId, honorarkraftId, order, desc, enabled));
        },
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetSkillsProfile: (token, executionId, studentId) => {
            dispatch(skillsProfilesActions.getSkillsProfile(token, executionId, studentId))
        },
        dispatchGetExecutionsHonorarkraft: (token, executionId) => {
            dispatch(executionsActions.getExecutionsHonorarkraft(token, executionId, "lastname", false, null));
        },
        dispatchHonorarkraft: (honorarkraft) => {
            dispatch(filterActions.honorarkraft(honorarkraft));
        },
        dispatchGetStudentsForUser: (token, executionId, teacherId, order = "", desc = 0) => {
            dispatch(executionsActions.getStudentsForUser(token, executionId, teacherId));
        },
        dispatchUpdateResultTeacher: (token, executionId, feedback) => {
            dispatch(kompoGResultActions.updateResultTeacher(token, executionId, feedback));
        },
        dispatchGetKompogResults: (token, executionId, order, desc) => {
            dispatch(kompoGResultActions.getAllPupilResults(token, executionId, order, desc));
        },
        dispatchExecution: (execution) => {
            dispatch(filterActions.execution(execution));
        }
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResultsPage));