import ENV from '../config/env';

export const settingsService = {
    getSettings,
    saveSetting,
    savePassword,
};

function getSettings(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token, 
            'Content-Type': 'application/json' 
        },
    };

    return fetch(ENV.apiUrl + '/settings', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response.json();
        })
        .then(response => {
            return response;
        });
}

function saveSetting(token, settingKey, settingValue) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ settingKey, settingValue })
    };

    return fetch(ENV.apiUrl + '/settings', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText); // TODO: maybe return the whole response, instead just the statustext
            }

            return response;
        });
}

function savePassword(token, oldPassword, newPassword) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ oldPassword, newPassword })
    };

    return fetch(ENV.apiUrl + '/settings/password', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;
        });
}
