import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Collapse, Label, Button, Row, Col } from 'reactstrap';
import Authorization from '../components/Authorization'
import image1 from '../assets/images/homepage/Datenschutz.jpg';
import translationsVars from '../translations/translationsVars';

class HelpPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            translationsVars: { ...translationsVars, image_1: <img src={image1} className="help-image" alt="" /> },
            collapse: {
                settings: false,
                documents: false,
                selfassessment: false,
                selfassessmentoverview: false,
                interesttest: false,
                skillsprofile: false,
                skillsprofilestudent: false,
                studentadministration: false,
                studentadministration_k7: false,
                studentadministration_kg: false,
                observationsevaluations: false,
                useradministration: false,
                kompo7rating: false,
                executions: false,
                executions_k7: false,
                executions_kg: false,
                statistic: false,
                feedback: false,
                results: false,
                results_k7: false,
                results_kg: false,
                resultsschool: false,
                school: false,
                schooladministration: false,
                inquiry: false,
                inquirysend: false,
                inquiryreceived: false,
                kompo7ratingresults: false,
                mycompetences: false,
                myinterests: false,
                mymotivations: false,
                myresults: false,
            }
        }
    }

    componentDidMount() {

        setTimeout(() => {
            let lastvisit = localStorage.getItem('lastvisit') ? JSON.parse(localStorage.getItem('lastvisit')) : [];
            let anchorId = lastvisit[1].replace('#/', '');
            this.handleCollapse(anchorId);
            document.getElementById(anchorId) && document.getElementById(anchorId).scrollIntoView();
        }, 150);

    }

    handleCollapse = (name) => {
        let c = this.state.collapse;
        if (this.state.collapse.hasOwnProperty(name)) {
            c[name] = !c[name];
            this.setState({ collapse: c })
        }
    }

    getTextId = (id) => {

        let tmpId = "help.notfound";
        this.props.auth.roles.forEach((role) => {
            if (Object.keys(this.props.intl.messages).indexOf((id.toString() + '.' + role.toString())) !== -1) {
                tmpId = id.toString() + '.' + role.toString();
            }
        })
        return tmpId;
    }

    render() {
        let procedureString = "";
        if (this.props.auth.procedure > 0) {
            procedureString = this.props.auth.procedure === 1 ? ".kompo7" : ".kompoG";
        }

        return (
            <Container fluid role="main" className="content-margin">
                <h1><FormattedMessage id="help.title" /></h1>

                {/* selfassessment */}
                <Authorization id="help-self-assessment">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-selfassessment"><FormattedMessage id={this.state.collapse.selfassessment ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="selfassessment" name="collapse-selfassessment" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'selfassessment')} >
                                <span className={this.state.collapse.selfassessment ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.selfassessment" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.selfassessment} className="ps-4" id="selfassessment">
                                <FormattedMessage id={this.getTextId("help.label.selfassessment")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* interesttest */}
                <Authorization id="help-interesttest">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-interesttest"><FormattedMessage id={this.state.collapse.interesttest ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="interesttest" name="collapse-interesttest" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'interesttest')} >
                                <span className={this.state.collapse.interesttest ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.interesttest" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.interesttest} className="ps-4" id="interesttest">
                                <FormattedMessage id={this.getTextId("help.label.interesttest")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* skillsprofilestudent */}
                <Authorization id="help-skills-profile-student">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-skillsprofilestudent"><FormattedMessage id={this.state.collapse.skillsprofilestudent ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="skillsprofilestudent" name="collapse-skillsprofilestudent" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'skillsprofilestudent')} >
                                <span className={this.state.collapse.skillsprofilestudent ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.skillsprofilestudent" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.skillsprofilestudent} className="ps-4" id="skillsprofilestudent">
                                <FormattedMessage id={this.getTextId("help.label.skillsprofilestudent")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* myCompetences */}
                <Authorization id="help-my-competences">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-my-competences"><FormattedMessage id={this.state.collapse.mycompetences ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="mycompetences" name="collapse-my-competences" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'mycompetences')} >
                                <span className={this.state.collapse.mycompetences ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.mycompetences" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.mycompetences} className="ps-4" id="mycompetences">
                                <FormattedMessage id={this.getTextId("help.label.mycompetences")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* myInterests */}
                <Authorization id="help-my-interests">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-my-interests"><FormattedMessage id={this.state.collapse.myinterests ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="myinterests" name="collapse-my-interests" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'myinterests')} >
                                <span className={this.state.collapse.myinterests ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.myinterests" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.myinterests} className="ps-4" id="myinterests">
                                <FormattedMessage id={this.getTextId("help.label.myinterests")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* myMotivations */}
                <Authorization id="help-my-motivations">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-my-motivations"><FormattedMessage id={this.state.collapse.mymotivations ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="mymotivations" name="collapse-my-motivations" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'mymotivations')} >
                                <span className={this.state.collapse.mymotivations ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.mymotivations" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.mymotivations} className="ps-4" id="mymotivations">
                                <FormattedMessage id={this.getTextId("help.label.mymotivations")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* myResults */}
                <Authorization id="help-my-results">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-my-results"><FormattedMessage id={this.state.collapse.myresults ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="myresults" name="collapse-my-results" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'myresults')} >
                                <span className={this.state.collapse.myresults ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.myresults" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.myresults} className="ps-4" id="myresults">
                                <FormattedMessage id={this.getTextId("help.label.myresults")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* kompo7rating */}
                <Authorization id="help-kompo7-rating">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-kompo7rating"><FormattedMessage id={this.state.collapse.kompo7rating ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="kompo7rating" name="collapse-kompo7rating" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'kompo7rating')} >
                                <span className={this.state.collapse.kompo7rating ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.kompo7rating" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.kompo7rating} className="ps-4" id="kompo7rating">
                                <FormattedMessage id={this.getTextId("help.label.kompo7rating" + procedureString)} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>


                {/* school */}
                <Authorization id="help-school">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-school"><FormattedMessage id={this.state.collapse.school ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="school" name="collapse-school" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'school')} >
                                <span className={this.state.collapse.school ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.school" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.school} className="ps-4" id="results">
                                <FormattedMessage id={this.getTextId("help.label.school")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* studentadministration */}
                <Authorization id="help-student-administration">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-studentadministration"><FormattedMessage id={this.state.collapse.studentadministration ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="studentadministration" name="collapse-studentadministration" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'studentadministration')} >
                                <span className={this.state.collapse.studentadministration ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.studentadministration" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.studentadministration} className="ps-4" id="studentadministration">
                                <FormattedMessage id={this.getTextId("help.label.studentadministration" + procedureString)} values={this.state.translationsVars} />
                                <Authorization id="help-student-administration-collapse">
                                    <Row >
                                        <Col xs="12" className="py-2">
                                            <Label className="d-none" for="collapse-student-administration-k7"><FormattedMessage id={this.state.collapse.studentadministration_k7 ? "collapse.close" : "collapse.open"} /></Label>
                                            <Button id="student-administration" name="collapse-student-administration-k7" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'studentadministration_k7')} >
                                                <span className={this.state.collapse.studentadministration_k7 ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                                <FormattedMessage id="KomPo7" />
                                            </Button>
                                            <Collapse isOpen={this.state.collapse.studentadministration_k7} className="ps-4" id="student-administration-k7">
                                                <FormattedMessage id={this.getTextId("help.label.studentadministration.k7")} values={this.state.translationsVars} />
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs="12" className="py-2">
                                            <Label className="d-none" for="collapse-student-administration-kg"><FormattedMessage id={this.state.collapse.studentadministration_kg ? "collapse.close" : "collapse.open"} /></Label>
                                            <Button id="student-administration" name="collapse-student-administration-kg" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'studentadministration_kg')} >
                                                <span className={this.state.collapse.studentadministration_kg ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                                <FormattedMessage id="KomPoG" />
                                            </Button>
                                            <Collapse isOpen={this.state.collapse.studentadministration_kg} className="ps-4" id="student-administration-kg">
                                                <FormattedMessage id={this.getTextId("help.label.studentadministration.kG")} values={this.state.translationsVars} />
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Authorization>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* schooladministration */}
                <Authorization id="help-school-administration">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-schooladministration"><FormattedMessage id={this.state.collapse.schooladministration ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="schooladministration" name="collapse-schooladministration" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'schooladministration')} >
                                <span className={this.state.collapse.schooladministration ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.schooladministration" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.schooladministration} className="ps-4" id="results">
                                <FormattedMessage id={this.getTextId("help.label.schooladministration")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* useradministration */}
                <Authorization id="help-user-administration">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-useradministration"><FormattedMessage id={this.state.collapse.useradministration ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="useradministration" name="collapse-useradministration" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'useradministration')} >
                                <span className={this.state.collapse.useradministration ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.useradministration" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.useradministration} className="ps-4" id="useradministration">
                                <FormattedMessage id={this.getTextId("help.label.useradministration" + procedureString)} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* resultsschool */}
                <Authorization id="help-results-school">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-resultsschool"><FormattedMessage id={this.state.collapse.resultsschool ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="resultsschool" name="collapse-resultsschool" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'resultsschool')} >
                                <span className={this.state.collapse.resultsschool ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.resultsschool" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.resultsschool} className="ps-4" id="results">
                                <FormattedMessage id={this.getTextId("help.label.resultsschool")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* executions */}
                <Authorization id="help-executions">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-executions"><FormattedMessage id={this.state.collapse.executions ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="executions" name="collapse-executions" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'executions')} >
                                <span className={this.state.collapse.executions ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id={"help.title.executions"} />
                            </Button>
                            <Collapse isOpen={this.state.collapse.executions} className="ps-4" id="executions">
                                <FormattedMessage id={this.getTextId("help.label.executions" + procedureString)} values={this.state.translationsVars} />
                                <Authorization id="help-executions-collapse">
                                    <Row >
                                        <Col xs="12" className="py-2">
                                            <Label className="d-none" for="collapse-executions-k7"><FormattedMessage id={this.state.collapse.executions_k7 ? "collapse.close" : "collapse.open"} /></Label>
                                            <Button id="executions" name="collapse-executions-k7" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'executions_k7')} >
                                                <span className={this.state.collapse.executions_k7 ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                                <FormattedMessage id="KomPo7" />
                                            </Button>
                                            <Collapse isOpen={this.state.collapse.executions_k7} className="ps-4" id="executions-k7">
                                                <FormattedMessage id={this.getTextId("help.label.executions.k7")} values={this.state.translationsVars} />
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs="12" className="py-2">
                                            <Label className="d-none" for="collapse-executions-kg"><FormattedMessage id={this.state.collapse.executions_kg ? "collapse.close" : "collapse.open"} /></Label>
                                            <Button id="executions" name="collapse-executions-kg" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'executions_kg')} >
                                                <span className={this.state.collapse.executions_kg ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                                <FormattedMessage id="KomPoG" />
                                            </Button>
                                            <Collapse isOpen={this.state.collapse.executions_kg} className="ps-4" id="executions-kg">
                                                <FormattedMessage id={this.getTextId("help.label.executions.kG")} values={this.state.translationsVars} />
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Authorization>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* selfassessmentoverview */}
                <Authorization id="help-self-assessment-overview">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-selfassessmentoverview"><FormattedMessage id={this.state.collapse.selfassessmentoverview ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="selfassessmentoverview" name="collapse-selfassessmentoverview" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'selfassessmentoverview')} >
                                <span className={this.state.collapse.selfassessmentoverview ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.selfassessmentoverview" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.selfassessmentoverview} className="ps-4" id="selfassessmentoverview">
                                <FormattedMessage id={this.getTextId("help.label.selfassessmentoverview")} values={this.state.translationsVars} />
                                <Authorization id="help-self-assessment-overview-supplement">
                                    <br /><br /><FormattedMessage id={"help.k7.supplement"} />
                                </Authorization>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* observationsevaluations */}
                <Authorization id="help-observations-evaluations">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-observationsevaluations"><FormattedMessage id={this.state.collapse.observationsevaluations ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="observationsevaluations" name="collapse-observationsevaluations" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'observationsevaluations')} >
                                <span className={this.state.collapse.observationsevaluations ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.observationsevaluations" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.observationsevaluations} className="ps-4" id="observationsevaluations">
                                <FormattedMessage id={this.getTextId("help.label.observationsevaluations")} values={this.state.translationsVars} />
                                <Authorization id="help-observations-evaluations-supplement">
                                    <br /><br /><FormattedMessage id={"help.k7.supplement"} />
                                </Authorization>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* feedback */}
                <Authorization id="help-feedback">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-feedback"><FormattedMessage id={this.state.collapse.feedback ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="feedback" name="collapse-feedback" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'feedback')} >
                                <span className={this.state.collapse.feedback ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.feedback" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.feedback} className="ps-4" id="feedback">
                                <FormattedMessage id={this.getTextId("help.label.feedback")} values={this.state.translationsVars} />
                                <Authorization id="help-feedback-supplement">
                                    <br /><br /><FormattedMessage id={"help.k7.supplement"} />
                                </Authorization>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* skillsprofile */}
                <Authorization id="help-skills-profile">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-skillsprofile"><FormattedMessage id={this.state.collapse.skillsprofile ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="skillsprofile" name="collapse-skillsprofile" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'skillsprofile')} >
                                <span className={this.state.collapse.skillsprofile ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.skillsprofile" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.skillsprofile} className="ps-4" id="skillsprofile">
                                <FormattedMessage id={this.getTextId("help.label.skillsprofile")} values={this.state.translationsVars} />
                                <Authorization id="help-skills-profile-supplement">
                                    <br /><br /><FormattedMessage id={"help.k7.supplement"} />
                                </Authorization>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* results */}
                <Authorization id="help-results">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-results"><FormattedMessage id={this.state.collapse.results ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="results" name="collapse-results" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'results')} >
                                <span className={this.state.collapse.results ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.results" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.results} className="ps-4" id="results">
                                <FormattedMessage id={this.getTextId("help.label.results" + procedureString)} values={this.state.translationsVars} />
                                <Authorization id="help-results-collapse">
                                    <Row >
                                        <Col xs="12" className="py-2">
                                            <Label className="d-none" for="collapse-results-k7"><FormattedMessage id={this.state.collapse.results_k7 ? "collapse.close" : "collapse.open"} /></Label>
                                            <Button id="results" name="collapse-results-k7" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'results_k7')} >
                                                <span className={this.state.collapse.results_k7 ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                                <FormattedMessage id="KomPo7" />
                                            </Button>
                                            <Collapse isOpen={this.state.collapse.results_k7} className="ps-4" id="results-k7">
                                                <FormattedMessage id={this.getTextId("help.label.results.kompo7")} values={this.state.translationsVars} />
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col xs="12" className="py-2">
                                            <Label className="d-none" for="collapse-results-kg"><FormattedMessage id={this.state.collapse.results_kg ? "collapse.close" : "collapse.open"} /></Label>
                                            <Button id="results" name="collapse-results-kg" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'results_kg')} >
                                                <span className={this.state.collapse.results_kg ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                                <FormattedMessage id="KomPoG" />
                                            </Button>
                                            <Collapse isOpen={this.state.collapse.results_kg} className="ps-4" id="results-kg">
                                                <FormattedMessage id={this.getTextId("help.label.results.kompoG")} values={this.state.translationsVars} />
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Authorization>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>


                {/* inquiry */}
                <Authorization id="help-inquiry">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-inquiry"><FormattedMessage id={this.state.collapse.inquiry ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="inquiry" name="collapse-inquiry" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'inquiry')} >
                                <span className={this.state.collapse.inquiry ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.inquiry" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.inquiry} className="ps-4" id="inquiry">
                                <FormattedMessage id={this.getTextId("help.label.inquiry")} values={this.state.translationsVars} />
                                <Row >
                                    <Col xs="12" className="py-2">
                                        <Label className="d-none" for="collapse-inquiryreceived"><FormattedMessage id={this.state.collapse.inquiryreceived ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button id="inquiryreceived" name="collapse-inquiryreceived" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'inquiryreceived')} >
                                            <span className={this.state.collapse.inquiryreceived ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="help.title.inquiryreceived" />
                                        </Button>
                                        <Collapse isOpen={this.state.collapse.inquiryreceived} className="ps-4" id="inquiryreceived">
                                            <FormattedMessage id={this.getTextId("help.label.inquiryreceived")} values={this.state.translationsVars} />
                                        </Collapse>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs="12" className="py-2">
                                        <Label className="d-none" for="collapse-inquirysend"><FormattedMessage id={this.state.collapse.inquirysend ? "collapse.close" : "collapse.open"} /></Label>
                                        <Button id="inquirysend" name="collapse-inquirysend" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'inquirysend')} >
                                            <span className={this.state.collapse.inquirysend ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                            <FormattedMessage id="help.title.inquirysend" />
                                        </Button>
                                        <Collapse isOpen={this.state.collapse.inquirysend} className="ps-4" id="inquirysend">
                                            <FormattedMessage id={this.getTextId("help.label.inquirysend")} values={this.state.translationsVars} />
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* inquirysend */}
                {/* <Authorization id="help-inquiry">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-inquirysend"><FormattedMessage id={this.state.collapse.inquirysend ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="inquirysend" name="collapse-inquirysend" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'inquirysend')} >
                                <span className={this.state.collapse.inquirysend ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.inquirysend" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.inquirysend} className="ps-4" id="results">
                                <FormattedMessage id={this.getTextId("help.label.inquirysend")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization> */}


                {/* statistic */}
                <Authorization id="help-statistic">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-statistic"><FormattedMessage id={this.state.collapse.statistic ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="statistic" name="collapse-statistic" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'statistic')} >
                                <span className={this.state.collapse.statistic ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.statistic" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.statistic} className="ps-4" id="statistic">
                                <FormattedMessage id={this.getTextId("help.label.statistic" + procedureString)} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>



                {/* kompo7ratingresults */}
                <Authorization id="help-kompo7-rating-results">
                    <Row >
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-kompo7ratingresults"><FormattedMessage id={this.state.collapse.kompo7ratingresults ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="kompo7ratingresults" name="collapse-kompo7ratingresults" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'kompo7ratingresults')} >
                                <span className={this.state.collapse.kompo7ratingresults ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.kompo7ratingresults" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.kompo7ratingresults} className="ps-4" id="results">
                                <FormattedMessage id={this.getTextId("help.label.kompo7ratingresults")} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* documents */}
                <Authorization id="help-documents">
                    <Row>
                        <Col xs="12" className="border-bottom py-2">
                            <Label className="d-none" for="collapse-documents"><FormattedMessage id={this.state.collapse.documents ? "collapse.close" : "collapse.open"} /></Label>
                            <Button id="documents" name="collapse-documents" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'documents')} >
                                <span className={this.state.collapse.documents ? "collapse-open me-2" : "collapse-close me-2"}></span>
                                <FormattedMessage id="help.title.documents" />
                            </Button>
                            <Collapse isOpen={this.state.collapse.documents} className="ps-4" id="documents">
                                <FormattedMessage id={this.getTextId("help.label.documents" + procedureString)} values={this.state.translationsVars} />
                            </Collapse>
                        </Col>
                    </Row>
                </Authorization>

                {/* settings */}
                <Row>
                    <Col xs="12" className="border-bottom py-2">
                        <Label className="d-none" for="collapse-settings"><FormattedMessage id={this.state.collapse.settings ? "collapse.close" : "collapse.open"} /></Label>
                        <Button id="settings" name="collapse-settings" color="link" className="btn-collapse m-0 p-0  fw-bold" onClick={this.handleCollapse.bind(this, 'settings')} >
                            <span className={this.state.collapse.settings ? "collapse-open me-2" : "collapse-close me-2"}></span>
                            <FormattedMessage id="help.title.settings" />
                        </Button>
                        <Collapse isOpen={this.state.collapse.settings} className="ps-4" id="settings">
                            <FormattedMessage id={this.getTextId("help.label.settings")} values={this.state.translationsVars} />
                        </Collapse>
                    </Col>
                </Row>

            </Container>
        );
    }

}



const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(HelpPage));