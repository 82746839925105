import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header, HeaderLandscape } from './HeaderPDF';
import { footer } from './FooterPDF';
import { Images } from './ImagesPDF';

export const generateFeedbackPDF = (props, state) => {

    let documentname = props.intl.formatMessage({ id: "feedback.print.filename" }, state.translationsVars)
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    //Interesttest
    let interesttest = "";
    if (props.skillsProfile === null || (props.skillsProfile && props.skillsProfile.interesttest === null)) {
        interesttest = props.intl.formatMessage({ id: "professionalfield.print.nointeresttestcompleted" });
    } else if (props.skillsProfile && props.skillsProfile.interesttest !== null && props.skillsProfile.interesttest && (props.skillsProfile.interesttest.length === 0 || props.skillsProfile.interesttest[0].procent < 33.3)) {
        interesttest = props.intl.formatMessage({ id: "professionalfield.print.nointerest." + props.studentAddress }, state.translationsVars);
    } else if (props.skillsProfile && props.skillsProfile.interesttest && props.skillsProfile.interesttest.length > 0 && props.skillsProfile.interesttest[0].procent >= 33.3) {

        if (props.skillsProfile.interesttest.length === 1) {
            interesttest = props.intl.formatMessage({ id: "professionalfield.print.start.single" }, state.translationsVars);
        } else {
            interesttest = props.intl.formatMessage({ id: "professionalfield.print.start.multiple" }, state.translationsVars);
        }

        props.skillsProfile && props.skillsProfile.interesttest && props.skillsProfile.interesttest.forEach((pf, index) => {
            if (index < 3 && pf.procent >= 33.3) {
                if (index === props.skillsProfile.interesttest.length - 1 && index === 0) {
                    interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
                }
                else if (index === props.skillsProfile.interesttest.length - 1 && index >= 0) {
                    interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield.print.and" }) + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
                } else if (index === 0) {
                    interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
                } else {
                    interesttest = interesttest + ", " + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
                }
            }
        })
        interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield.print.end." + props.studentAddress }, state.translationsVars);
    }

    //Selfassessment
    //Header
    let rowsSelfassessment = [[
        { text: props.intl.formatMessage({ id: "selfassessment.label.competence" }), fontSize: 12, color: 'white', fillColor: [67, 53, 140], border: [1, 1, 1, 1] },
        {
            stack: [
                { image: 'smiley1', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-1" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley4', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-2" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley3', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-3" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley2', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-4" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
    ]];

    //Body
    props.competences.forEach((trData, trIndex) => {
        let tmpA = [];
        tmpA.push([{ text: props.intl.formatMessage({ id: trData.description }) + '\n\n' + props.intl.formatMessage({ id: trData.name }).toUpperCase(), fontSize: 10 }]);
        tmpA.push(props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 1)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 2)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 3)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === 4)).length > 0 ? { image: 'student', width: 15, height: 15, alignment: "center" } : "");
        rowsSelfassessment.push(tmpA);
    });

    //Externalassessment
    let rowsExternalassessment = [[
        { text: props.intl.formatMessage({ id: "externalassessment.label.competence" }).toUpperCase(), fontSize: 12, color: 'white', fillColor: [67, 53, 140], bold: true },
        { text: props.intl.formatMessage({ id: "externalassessment.label.observations" }).toUpperCase(), fontSize: 12, color: 'white', fillColor: [67, 53, 140], bold: true },
        {
            stack: [
                { image: 'smiley1', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-1" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley4', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-2" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley3', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-3" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley2', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-4" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
    ]];

    props.competences.forEach((trData, trIndex) => {
        let tmpA = [];
        //tmpA.push({ text: props.intl.formatMessage({ id: trData.name }).toUpperCase() + '\n\n' + props.intl.formatMessage({ id: trData.description }), fontSize: 10 });
        tmpA.push([{ text: props.intl.formatMessage({ id: "externalassessment." + trData.name }).toUpperCase() + '\n\n', fontSize: 10, bold: true }, { text: props.intl.formatMessage({ id: "externalassessment." + trData.description }), fontSize: 10 }]);
        tmpA.push({ text: props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id)).length > 0 ? props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id))[0].observations : "", fontSize: 10 });
        tmpA.push(props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 1 || el.rating2 === 1))).length > 0 ? { image: 'observer', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 2 || el.rating2 === 2))).length > 0 ? { image: 'observer', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 3 || el.rating2 === 3))).length > 0 ? { image: 'observer', width: 15, height: 15, alignment: "center" } : "");
        tmpA.push(props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === 4 || el.rating2 === 4))).length > 0 ? { image: 'observer', width: 15, height: 15, alignment: "center" } : "");
        rowsExternalassessment.push(tmpA);
    });

    //Feedback
    //Header
    let rows = [[
        { text: props.intl.formatMessage({ id: "feedback.label.competence" }), fontSize: 12, color: 'white', fillColor: [67, 53, 140], border: [1, 1, 1, 1] },
        {
            stack: [
                { image: 'smiley1', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-1" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley4', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-2" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley3', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-3" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley2', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-4" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
    ]];

    //Body
    props.competences.forEach((trData, trIndex) => {
        let tmpA = [];
        tmpA.push([{ text: props.intl.formatMessage({ id: trData.description }) + '\n\n' + props.intl.formatMessage({ id: trData.name }).toUpperCase(), fontSize: 10 }]);

        for (let i = 1; i <= 4; i++) {
            let tmpR = [];
            if (props.studentObserverRating.student && props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === i)).length > 0) {
                tmpR.push({ image: trIndex % 2 === 0 ? 'student' : 'student', width: 15, height: 15, alignment: "center" });
            }
            if (props.studentObserverRating.observer && props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === i || el.rating2 === i))).length > 0) {
                tmpR.push({ image: trIndex % 2 === 0 ? 'observer' : 'observer', width: 15, height: 15, alignment: "center" });
            }
            tmpA.push(tmpR);
        }

        rows.push(tmpA);
    });

    let tmpHeaderLandscape = JSON.parse(JSON.stringify(HeaderLandscape));
    tmpHeaderLandscape.push({
        //Title
        text: props.intl.formatMessage({ id: "externalassessment.print.title" }),
        absolutePosition: { x: 40, y: 65 },
        fontSize: 20,
    });
    tmpHeaderLandscape.push({
        //Subtitle
        text: props.intl.formatMessage({ id: "externalassessment.print.subtitle" }),
        absolutePosition: { x: 40, y: 90 },
        fontSize: 10
    });
    tmpHeaderLandscape.push({
        //Student
        text: props.intl.formatMessage({ id: "externalassessment.print.student" }) + " " + props.studentFirstname + " " + props.studentLastname,
        absolutePosition: { x: 400, y: 90 },
        fontSize: 10,
    });

    // interesttest

    const interesttestBarColumns = 40;

    //const barColors = [[0,150,64], [254,155,0], [227,6,19]]; // multi color
    //const interesttestIntervalLineColor = [60, 60, 60]; // multi color
    const barColors = [[56, 57, 128], [56, 57, 128], [56, 57, 128]]; // single color (OloV blue)
    const interesttestIntervalLineColor = [186, 186, 186]; // single color

    let interesttestRows = [];
    let interesttestWidths = [100];

    // setup columns
    let interesttestCols = [{ text: '', width: 100, height: 10 }];
    for (let i = 0; i < interesttestBarColumns; i++) {
        interesttestCols.push({
            text: '',
            width: 1,
            height: 10
        });
        interesttestWidths.push(1);
    }

    // header
    let headRow = [{ text: '', border: [0, 0, 0, 0] }];
    for (let i = 0; i < interesttestBarColumns; i++) {
        if (i === 0) {
            headRow.push({ image: 'smiley2', width: 25, height: 25, colSpan: 13, alignment: 'center', border: [0, 0, 1, 0] });
        } else if (i === 13) {
            headRow.push({ image: 'smiley3', width: 25, height: 25, colSpan: 14, alignment: 'center', border: [0, 0, 1, 0] });
        } else if (i === 27) {
            headRow.push({ image: 'smiley1', width: 25, height: 25, colSpan: 13, alignment: 'center', border: [0, 0, 0, 0] });
        } else {
            headRow.push('');
        }
    }
    interesttestRows.push(headRow);

    if (props.skillsProfile && props.skillsProfile.interesttest !== null) {
        for (let i = 0; i < props.skillsProfile.interesttest.length; i++) {
            let itField = props.skillsProfile.interesttest[i];
            let itRow = [];
            itRow.push({ text: itField.name, fontSize: 7, border: [0, 0, 0, 0], alignment: 'right' });

            // add percentage bar
            for (let j = 0; j < interesttestBarColumns; j++) {
                const pScaledVal = itField.value / itField.maxValue * interesttestBarColumns;
                const pPercentVal = pScaledVal / interesttestBarColumns * 100;

                let borderLeft = 0, borderRight = 0;
                if (j === 13) borderLeft = 1;
                else if (j === 27) borderLeft = 1;
                //else if (pScaledVal === 100) { borderLeft = 1; borderRight = 1; }
                if (j <= pScaledVal && pScaledVal !== 0) {
                    //let p = itField.value * 100 / itField.maxValue;
                    let col = (pPercentVal >= 66.6 ? barColors[0] : pPercentVal >= 33.3 ? barColors[1] : barColors[2]);

                    let percentageCol = { text: '', fillColor: col, border: [borderLeft, 0, borderRight, 1] };
                    itRow.push(percentageCol);
                } else {
                    let clearCol = { text: '', fillColor: [255, 255, 255], border: [borderLeft, 0, borderRight, 1] };
                    itRow.push(clearCol);
                }
            }

            interesttestRows.push(itRow);
        }
    }

    let content = [];

    // interest test 
    content.push({
        //Title
        text: props.intl.formatMessage({ id: "selfassessment.print.title" }),
        absolutePosition: { x: 40, y: 65 },
        fontSize: 20,
    });
    content.push({
        //Subtitle
        text: props.intl.formatMessage({ id: "feedback.print.subtitle.interesttest" }),
        absolutePosition: { x: 40, y: 90 },
        fontSize: 10,
    });
    content.push({
        //Student
        text: props.intl.formatMessage({ id: "selfassessment.print.firstname" }) + " " + props.studentFirstname + ", " + props.intl.formatMessage({ id: "selfassessment.print.lastname" }) + " " + props.studentLastname,
        fontSize: 10,
        margin: [0, 0, 0, 10]
    });

    content.push({
        //Results
        text: props.intl.formatMessage({ id: "feedback.print.header.interesttest.results" }, state.translationsVars),
        fontSize: 10,
        bold: true,
        margin: [0, 15, 0, 0]
    });
    content.push(
        {
            //Linie
            table: {
                widths: ["*"],
                body: [[""]],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return [56, 57, 128];
                },
            }
        });


    if (props.skillsProfile.interesttest !== null) {
        content.push(
            {
                //Results body
                text: props.intl.formatMessage({ id: "skillsprofile.print.interesttest.results.variant.1." + props.studentAddress }, state.translationsVars),
                fontSize: 10,
                alignment: "justify",
                lineHeight: 1.3
            });
        content.push(
            {
                table: {
                    dontBreakRows: true,
                    headerRows: 0,
                    widths: interesttestWidths,
                    body: interesttestRows
                },
                margin: [0, 25, 0, 0],
                layout: {
                    hLineWidth: function (i, node) {
                        return 5;
                    },
                    vLineWidth: function (i) {
                        return 0.8;
                    },
                    hLineColor: function (i, node) {
                        return 'white';
                    },
                    vLineColor: function (i, node) {
                        return interesttestIntervalLineColor;
                    },
                },
            });
        content.push(
            {
                columns: interesttestCols,
            });
    } else {
        content.push({
            text: props.intl.formatMessage({ id: "skillsprofile.print.interesttest.results.variant.no.result" }, state.translationsVars),
            fontSize: 10,
            alignment: "justify",
            lineHeight: 1.3,
        });
    }
    content.push(
        {
            //More information
            text: props.intl.formatMessage({ id: "skillsprofile.print.header.interesttest.moreinfo" }),
            fontSize: 10,
            bold: true,
            margin: [0, 30, 0, 0]
        });
    content.push(
        {
            //Linie
            table: {
                widths: ["*"],
                body: [[""]]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    return [56, 57, 128];
                },
            },
        });
    content.push(
        {
            //More Info body
            text: props.intl.formatMessage({ id: "skillsprofile.print.interesttest.moreinfo" }, state.translationsVars),
            fontSize: 10,
            alignment: "justify",
            lineHeight: 1.3,
            pageBreak: 'after',
        }
    );

    //Selfassessment
    content.push({
        //Title
        text: props.intl.formatMessage({ id: "selfassessment.print.title" }),
        absolutePosition: { x: 40, y: 65 },
        fontSize: 20,
    });
    content.push({
        //Subtitle
        text: props.intl.formatMessage({ id: "selfassessment.print.subtitle" }),
        absolutePosition: { x: 40, y: 90 },
        fontSize: 10,
    });
    content.push({
        //Student
        text: props.studentFirstname + " " + props.studentLastname,
        fontSize: 10,
        margin: [0, 0, 0, 10]
    });
    content.push({
        table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ["*", 40, 40, 40, 40],
            body: rowsSelfassessment
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0.5;
            },
            vLineWidth: function (i) {
                return 0.5;
            },
        },
        pageBreak: 'after',
    });

    //Self and Externalassessment
    content.push({
        //Title
        text: props.intl.formatMessage({ id: "feedback.print.title" }),
        absolutePosition: { x: 40, y: 65 },
        fontSize: 20,
    });
    content.push({
        //Subtitle
        text: props.intl.formatMessage({ id: "feedback.print.subtitle" }),
        absolutePosition: { x: 40, y: 90 },
        fontSize: 10,
    });
    content.push({
        //Student
        text: props.studentFirstname + " " + props.studentLastname,
        fontSize: 10,
        margin: [0, 0, 0, 10]
    });
    content.push({
        table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ["*", 40, 40, 40, 40],
            body: rows
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0.5;
            },
            vLineWidth: function (i) {
                return 0.5;
            },
        }
    });
    content.push({
        columns: [
            {
                width: 'auto',
                text: props.intl.formatMessage({ id: "feedback.description.print.selfassessment" }),
                fontSize: 8
            },
            {
                image: 'student',
                width: 10,
                height: 10,
            },
            {
                width: 'auto',
                text: " / " + props.intl.formatMessage({ id: "feedback.description.print.externalassessment" }),
                fontSize: 8
            },
            {
                image: 'observer',
                width: 10,
                height: 10
            },
            {
                width: 'auto',
                text: " / " + props.intl.formatMessage({ id: "feedback.description.print.noassessment" }),
                fontSize: 8
            },
        ],
        margin: [5, 2, 5, 2],
        pageBreak: 'after',
        pageOrientation: 'landscape',
    });


    content.push(
        //Externalassessment
        {
            table: {
                dontBreakRows: false,
                headerRows: 1,
                widths: ["*", "*", 40, 40, 40, 40],
                body: rowsExternalassessment
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0.5;
                },
                vLineWidth: function (i) {
                    return 0.5;
                },
            },
        },
    );


    let docDefinition = {
        //595pt x 842pt
        //pageOrientation: 'landscape',
        pageSize: 'A4',
        pageMargins: [40, 115, 40, 65],
        images: Images,
        header: (page) => {
            //Selfassessment and self- and externalassessment
            if (page <= 3) {
                return Header;
            } else {
                return tmpHeaderLandscape;
            }
        },
        footer: (page) => {
            if (page <= 3) {
                return footer(props)
            } else {
                //landscape
                return footer(props, true);
            }
        },
        content: content,
        styles: { barCell: { margin: [0, 10, 0, 10] } }


    };
    pdfMake.createPdf(docDefinition).download(documentname);

}