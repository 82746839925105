import { userProfileService } from '../services';
import { actionTypes } from './types';

export const userProfileActions = {
    createUser,
    updateUser,
    deleteUser,
    getUsersTitle,
    getUsersAddress,
    getRoles,
    getUsersFunctions,
    getUsers,
    resetPwd,
    resetPwdPdf,
    resetPwdUserPdf,
    resetUser
};

function createUser(token, schoolId, roles, user) {
    return dispatch => {
        dispatch(request());

        userProfileService.createUser(token, schoolId, roles, user)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_CREATE_USER_REQUEST } }
    function success(response) { return { type: actionTypes.USERPROFILE_CREATE_USER_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.USERPROFILE_CREATE_USER_FAILURE, error } }
}


function updateUser(token, user) {
    return dispatch => {
        dispatch(request());

        userProfileService.updateUser(token, user)
            .then(
                response => {
                    if (response.error) {
                        dispatch(failure(response));
                    } else {
                        dispatch(success(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_UPDATE_USER_REQUEST } }
    function success(response) { return { type: actionTypes.USERPROFILE_UPDATE_USER_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.USERPROFILE_UPDATE_USER_FAILURE, error } }
}

function getUsersTitle(token) {
    return dispatch => {
        dispatch(request());

        userProfileService.getUsersTitle(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_USERSTITLE_REQUEST } }
    function success(usersTitle) { return { type: actionTypes.USERPROFILE_USERSTITLE_SUCCESS, usersTitle } }
    function failure(error) { return { type: actionTypes.USERPROFILE_USERSTITLE_FAILURE, error } }
}

function getUsersAddress(token) {
    return dispatch => {
        dispatch(request());

        userProfileService.getUsersAddress(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_USERSADDRESS_REQUEST } }
    function success(usersAddress) { return { type: actionTypes.USERPROFILE_USERSADDRESS_SUCCESS, usersAddress } }
    function failure(error) { return { type: actionTypes.USERPROFILE_USERSADDRESS_FAILURE, error } }
}

function getRoles(token) {
    return dispatch => {
        dispatch(request());

        userProfileService.getRoles(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_ROLES_REQUEST } }
    function success(roles) { return { type: actionTypes.USERPROFILE_ROLES_SUCCESS, roles } }
    function failure(error) { return { type: actionTypes.USERPROFILE_ROLES_FAILURE, error } }
}

function getUsersFunctions(token) {
    return dispatch => {
        dispatch(request());

        userProfileService.getUsersFunctions(token)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_USERSFUNCTIONS_REQUEST } }
    function success(usersfunctions) { return { type: actionTypes.USERPROFILE_USERSFUNCTIONS_SUCCESS, usersfunctions } }
    function failure(error) { return { type: actionTypes.USERPROFILE_USERSFUNCTIONS_FAILURE, error } }
}

function getUsers(token, order, desc, search, page, pagelimit, filter) {
    return dispatch => {
        dispatch(request());

        userProfileService.getUsers(token, order, desc, search, page, pagelimit, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_USERS_REQUEST } }
    function success(users) { return { type: actionTypes.USERPROFILE_USERS_SUCCESS, users } }
    function failure(error) { return { type: actionTypes.USERPROFILE_USERS_FAILURE, error } }
}

function deleteUser(token, userId) {
    return dispatch => {
        dispatch(request());

        userProfileService.deleteUser(token, userId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_DELETE_USER_REQUEST } }
    function success(response) { return { type: actionTypes.USERPROFILE_DELETE_USER_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.USERPROFILE_DELETE_USER_FAILURE, error } }
}

function resetPwd(token, filter) {
    return dispatch => {
        dispatch(request());

        userProfileService.resetPwd(token, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_RESET_PWD_REQUEST } }
    function success(response) { return { type: actionTypes.USERPROFILE_RESET_PWD_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.USERPROFILE_RESET_PWD_FAILURE, error } }
}

function resetPwdPdf(token, executionId) {
    return dispatch => {
        dispatch(request());

        userProfileService.resetPwdPdf(token, executionId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_RESET_PWD_PDF_REQUEST } }
    function success(response) { return { type: actionTypes.USERPROFILE_RESET_PWD_PDF_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.USERPROFILE_RESET_PWD_PDF_FAILURE, error } }
}

function resetPwdUserPdf(token, userId) {
    return dispatch => {
        dispatch(request());

        userProfileService.resetPwdUserPdf(token, userId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_RESET_PWD_USER_PDF_REQUEST } }
    function success(response) { return { type: actionTypes.USERPROFILE_RESET_PWD_USER_PDF_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.USERPROFILE_RESET_PWD_USER_PDF_FAILURE, error } }
}

function resetUser(token, userId) {
    return dispatch => {
        dispatch(request());

        userProfileService.resetUser(token, userId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: actionTypes.USERPROFILE_RESET_USER_REQUEST } }
    function success(response) { return { type: actionTypes.USERPROFILE_RESET_USER_SUCCESS, response } }
    function failure(error) { return { type: actionTypes.USERPROFILE_RESET_USER_FAILURE, error } }
}