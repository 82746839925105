import * as React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class Pages extends React.Component {

    static propTypes = {
        totalpages: PropTypes.number,
        pages: PropTypes.number,
        page: PropTypes.number,
        changePage: PropTypes.func,
    };

    changePage(page) {
        if (page === 0) {
            page = this.props.totalpages;
        } else if (page > this.props.totalpages) {
            page = 1;
        }
        this.props.changePage(page);
    }

    render() {
        return (
            <Pagination size="md">
                <PaginationItem>
                    <PaginationLink previous onClick={() => { this.changePage(this.props.page - 1) }} />
                </PaginationItem>
                {

                    Array.apply(0, Array(this.props.pages < this.props.totalpages ? this.props.pages : this.props.totalpages === 0 ? 1 : this.props.totalpages)).map((el, i) => {
                        let start = this.props.page - (Math.floor(this.props.pages / 2)) - (this.props.pages % 2);
                        if (start + this.props.pages > this.props.totalpages) {
                            start = this.props.totalpages - this.props.pages;
                        }
                        if (start < 0) {
                            start = 0;
                        }
                        return <PaginationItem key={i} active={this.props.page === start + i + 1}>
                            <PaginationLink onClick={() => { this.changePage(start + i + 1) }} key={i} >{start + i + 1}</PaginationLink>
                        </PaginationItem>
                    })
                }
                <PaginationItem>
                    <PaginationLink next onClick={() => { this.changePage(this.props.page + 1) }} />
                </PaginationItem>
            </Pagination>
        );
    }
}

export default injectIntl(Pages);