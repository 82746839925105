import ENV from '../config/env';

export const inquiryService = {
    createInquiry,
    updateInquiry,
    getInquiryBySchoolId,
    getInquiryBySchoolIdExecutionId,
    getSendInquiryBySchoolId,
    getCountOfOpenInquirys
};


/**
* CREATE INQUIRY
*/
async function createInquiry(token, inquiry) {


    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify({ executions_id: inquiry.executions_id, schools_id: inquiry.schools_id, neededobserver: inquiry.neededobserver, text: inquiry.text })
    };

    return fetch(ENV.apiUrl + '/inquiry', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

/**
* GET INQUIRY BY SCHOOL ID
*/
async function getInquiryBySchoolId(token, schoolId, order, desc, page, pagelimit, rejected, past) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";
    rejected = "&rejected=" + (rejected ? 1 : 0);
    past = "&past=" + (past ? 1 : 0);


    return fetch(ENV.apiUrl + '/inquiry/' + schoolId + '?' + order + desc + page + pagelimit + rejected + past, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }
            return response.json();


        }).then(response => {
            return response;
        });

}
/**
* GET INQUIRY BY SCHOOL ID AND EXECUTIONID
*/
async function getInquiryBySchoolIdExecutionId(token, schoolId, executionId, order, desc, page, pagelimit, rejected, past) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";
    rejected = "&rejectec=" + (rejected ? 1 : 0);
    past = "&past=" + (past ? 1 : 0);

    return fetch(ENV.apiUrl + '/inquiry/' + schoolId + '/' + executionId + '?' + order + desc + page + pagelimit + rejected + past, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();


        }).then(response => {
            return response;
        });

}

/**
* UPDATE INQUIRY
*/
async function updateInquiry(token, inquiry) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },

        body: JSON.stringify(inquiry)
    };

    return fetch(ENV.apiUrl + '/inquiry/' + inquiry.id, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response;

        });

}

/**
* GET SEND INQUIRY BY SCHOOL ID
*/
async function getSendInquiryBySchoolId(token, schoolId, order, desc, page, pagelimit, rejected, past) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    order = order ? "order=" + order : "";
    desc = "&desc=" + (desc ? "1" : "0");
    page = page ? "&page=" + page : "";
    pagelimit = pagelimit ? "&page_limit=" + pagelimit : "";
    rejected = "&rejected=" + (rejected ? 1 : 0);
    past = "&past=" + (past ? 1 : 0);


    return fetch(ENV.apiUrl + '/sendinquiry/' + schoolId + '?' + order + desc + page + pagelimit + rejected + past, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }
            return response.json();


        }).then(response => {
            return response;
        });

}

/**
* GET COUNT OF OPEN INQUIRY
*/
async function getCountOfOpenInquirys(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };

    return fetch(ENV.apiUrl + '/openinquiry', requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }
            return response.json();


        }).then(response => {
            return response;
        });

}


