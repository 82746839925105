import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Label, Input, } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { filterActions } from '../actions';

class ExecutionsPastCheckbox extends React.Component {


    static propTypes = {
        // redux dispatch
        dispatchPastExecutions: PropTypes.func.isRequired,
        handlePast: PropTypes.func,
        past: PropTypes.bool,
    };


    handlePast = async (e) => {
        if (this.props.handlePast) {
            this.props.handlePast(e.target.checked);
        } else {
            this.props.dispatchPastExecutions();
        }

    }

    render() {
        return (
            <Label for="past" className="ms-4">
                <Input id="past" name="past" type="checkbox" defaultChecked={this.props.past} value={this.props.past} onChange={this.handlePast} disabled={this.props.saving} />
                <FormattedMessage id="executionpastcheckbox.label.past" />
            </Label>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        past: state.filter.executions_past,
        saving: state.observations.saving || state.competences.saving,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchPastExecutions: () => {
            dispatch(filterActions.executions_past());
        }
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecutionsPastCheckbox));