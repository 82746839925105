import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';

export const generateDepartmentStatisticsPDF = (props, state, stats) => {
    return new Promise((resolve, reject) => {

        let tVars = { name: '' };
        let documentname = props.intl.formatMessage({ id: 'statistics.print.filename' }, tVars)

        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        //Header 1
        let rowsSchools = [[
            {
                stack: [
                    
                    { text: props.intl.formatMessage({ id: "statistics.print.subtitle" }, { department: stats.department.name}), fontSize: 14, color: 'black', fillColor: [255, 255, 255],margin:[0,5,5,5] }
                ], border: [0, 0, 0, 1], bold: true, colSpan: 3
            },
            { text: ' ', fontSize: 20, color: 'black', fillColor: [255, 255, 255], border: [0, 0, 0, 1], bold: true },
            { text: ' ', fontSize: 20, color: 'black', fillColor: [255, 255, 255], border: [0, 0, 0, 1], bold: true },
            { text: props.intl.formatMessage({ id: "statistics.print.label.schooltable-header-1" }), fontSize: 10, color: 'black', fillColor: [242, 242, 242], alignment: 'center', bold: true },
            { text: props.intl.formatMessage({ id: "statistics.print.label.schooltable-header-2" }), fontSize: 10, color: 'black', fillColor: [191, 191, 191], alignment: 'center', bold: true, colSpan: 2 },
            { text: ' ', fontSize: 8, color: 'black', fillColor: [255, 255, 255], alignment: 'center', bold: true },
        ]];

        //Header 2
        rowsSchools.push([
            { text: ' ', fontSize: 8, color: 'black', fillColor: [255, 255, 255], border: [1, 1, 1, 1], alignment: 'center', bold: true },
            { text: props.intl.formatMessage({ id: "statistics.print.label.schooltable-1" }), fontSize: 10, color: 'black', fillColor: [255, 255, 255], bold: true },
            { text: props.intl.formatMessage({ id: "statistics.print.label.schooltable-2" }), fontSize: 10, color: 'black', fillColor: [255, 255, 255], bold: true },
            { text: props.intl.formatMessage({ id: "statistics.print.label.schooltable-3" }), fontSize: 10, color: 'black', fillColor: [242, 242, 242], alignment: 'center', bold: true },
            { text: props.intl.formatMessage({ id: "statistics.print.label.schooltable-4" }), fontSize: 10, color: 'black', fillColor: [191, 191, 191], alignment: 'center', bold: true },
            { text: props.intl.formatMessage({ id: "statistics.print.label.schooltable-5" }), fontSize: 10, color: 'black', fillColor: [191, 191, 191], alignment: 'center', bold: true }
        ]);
        stats.school_stats.forEach((school, index) => {
            // build the access info table data
            rowsSchools.push([
                { text: (index + 1).toString(), fontSize: 10, color: 'black', fillColor: [255, 255, 255], border: [1, 1, 1, 1], alignment: 'center', bold: true },
                { text: school.name, fontSize: 10, color: 'black', fillColor: [255, 255, 255], bold: true },
                { text: school.place, fontSize: 10, color: 'black', fillColor: [255, 255, 255], bold: true },
                { text: (school.certified > 0) ? school.certified.toString() : ' ', fontSize: 10, color: 'black', fillColor: [242, 242, 242], alignment: 'center', bold: true },
                /*{ text: (school.executions_cooperation > 0) ? school.executions_cooperation.toString() : ' ', fontSize: 10, color: 'black', fillColor: [191, 191, 191], alignment: 'center', bold: true },
                { text: (school.executions_standalone > 0) ? school.executions_standalone.toString() : ' ', fontSize: 10, color: 'black', fillColor: [191, 191, 191], alignment: 'center', bold: true }*/
                school.support === 1 ? { image: 'hook', width: 8, alignment: "center" } : ' ',
                school.support === 2 ? { image: 'hook', width: 8, alignment: "center" } : ' '
            ]);
        });

        // add total department stat row
        rowsSchools.push([
            { text: ' ', fontSize: 10, color: '#FFFFFF', fillColor: [67, 53, 140], border: [1, 1, 1, 1], alignment: 'center', bold: true },
            { text: stats.department.name, fontSize: 10, color: '#FFFFFF', fillColor: [67, 53, 140], bold: true },
            { text: stats.department.place, fontSize: 10, color: '#FFFFFF', fillColor: [67, 53, 140], bold: true },
            { text: (stats.total.total_certified_teachers > 0) ? stats.total.total_certified_teachers.toString() : ' ', fontSize: 10, color: '#FFFFFF', fillColor: [67, 53, 140], alignment: 'center', bold: true },
            { text: (stats.total.total_executions_cooperation > 0) ? stats.total.total_executions_cooperation.toString() : ' ', fontSize: 10, color: '#FFFFFF', fillColor: [67, 53, 140], alignment: 'center', bold: true },
            { text: (stats.total.total_executions_standalone > 0) ? stats.total.total_executions_standalone.toString() : ' ', fontSize: 10, color: '#FFFFFF', fillColor: [67, 53, 140], alignment: 'center', bold: true }
        ]);

        // page content
        let pageContent = [
            {
                text: props.intl.formatMessage({ id: "statistics.print.title" }), fontSize: 20, color: 'black', fillColor: [255, 255, 255],      
            },
            {                          
                margin: [0, 20, 0, 0],
                table: {
                    dontBreakRows: true,
                    //keepWithHeaderRows: 1,
                    headerRows: 2,
                    widths: ['5%', '*', '15%', '14%', '14%', '14%'],
                    body: rowsSchools,
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i) {
                        return 0.5;
                    },
                },
                //pageBreak: 'after',
            }
        ];

        let tmpHeader = JSON.parse(JSON.stringify(Header));

        let docDefinition = {
            // 515pt
            //595pt x 842pt
            pageSize: 'A4',
            pageMargins: [40, 75, 40, 65],
            images: Images,
            header: tmpHeader,
            footer: footer(props),
            content: pageContent,
        };

        let completeCallback = function () {
            resolve();
        };

        pdfMake.createPdf(docDefinition).download(documentname, completeCallback);
    });
}