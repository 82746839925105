import { actionTypes } from '../actions/types';

const initialState = {
    token: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).token,
    loggingIn: false,
    loginError: '',
    loggedIn: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).token ? true : false,
    user_id: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).user_id,
    firstname: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).firstname,
    lastname: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).lastname,
    class: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).class,
    address: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).address,
    email: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).email,
    personalnumber: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).personalnumber,
    roles: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).roles,
    schools: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).schools,
    isSendingMail: false,
    sendResetPwdMailSuccess: false,
    sendResetPwdMailError: '',
    privacypolicy: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).privacypolicy ? JSON.parse(sessionStorage.getItem('user')).privacypolicy : false,
    isSetPrivacypolicy: false,
    procedure: sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).procedure
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loginError: '',
                loggingIn: true,
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.auth.token,
                loggingIn: false,
                loginError: '',
                loggedIn: true,
                user_id: action.auth.user_id,
                firstname: action.auth.firstname,
                lastname: action.auth.lastname,
                class: action.auth.class,
                email: action.auth.email,
                personalnumber: action.auth.personalnumber,
                roles: action.auth.roles,
                schools: action.auth.schools,
                address: action.auth.address,      
                privacypolicy: action.auth.privacypolicy,       
                procedure: action.auth.procedure   
            };
        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                loginError: action.error,
                token: null
            };
        case actionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                token: null,
                loggedIn: false,
            };
        case actionTypes.SEND_RESET_PWD_MAIL_REQUEST:
            return {
                ...state,
                isSendingMail: true,
                sendResetPwdMailSuccess: false,
                sendResetPwdMailError: '',
            };
        case actionTypes.SEND_RESET_PWD_MAIL_SUCCESS:
            return {
                ...state,
                isSendingMail: false,
                sendResetPwdMailSuccess: true,
                sendResetPwdMailError: '',
            };
        case actionTypes.SEND_RESET_PWD_MAIL_FAILURE:
            return {
                ...state,
                isSendingMail: false,
                sendResetPwdMailSuccess: false,
                sendResetPwdMailError: action.error,
            };
        case actionTypes.PRIVACYPOLICY_REQUEST:
            return {
                ...state,
                isSetPrivacypolicy: true,
                privacypolicy: 0
            };
        case actionTypes.PRIVACYPOLICY_SUCCESS:            

            return {
                ...state,
                isSetPrivacypolicy: false,
                privacypolicy: 1
            };
        case actionTypes.PRIVACYPOLICY_FAILURE:
            return {
                ...state,
                privacypolicy: 0,
                isSetPrivacypolicy: false,
            };
        default:
            return state;
    }
};

export default authReducer;
