import { settingsService } from '../services';
import { actionTypes } from './types';

export const settingsActions = {
    getSettings,
    saveSetting,
    savePassword,
};

function getSettings(token) {
    return dispatch => {
        dispatch(request());

        settingsService.getSettings(token)
            .then(
            response => {               
                dispatch(success(response));
            },
            error => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
            }
            );
    };

    function request() { return { type: actionTypes.SETTINGS_REQUEST } }
    function success(settings) { return { type: actionTypes.SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: actionTypes.SETTINGS_FAILURE, error } }
}

function saveSetting(token, settingKey, settingValue) {
    return dispatch => {
        dispatch(request());

        settingsService.saveSetting(token, settingKey, settingValue)
            .then(
            response => {               
                dispatch(success());
            },
            error => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
            }
            );
    };

    function request() { return { type: actionTypes.SETTINGS_SAVE_SETTING_REQUEST } }
    function success() { return { type: actionTypes.SETTINGS_SAVE_SETTING_SUCCESS } }
    function failure(error) { return { type: actionTypes.SETTINGS_SAVE_SETTING_FAILURE, error } }
}

function savePassword(token, oldPassword, newPassword) {
    return dispatch => {
        dispatch(request());

        settingsService.savePassword(token, oldPassword, newPassword)
            .then(
            response => {               
                dispatch(success());
            },
            error => {
                dispatch(failure(error));
                //dispatch(alertActions.error(error));
            }
            );
    };

    function request() { return { type: actionTypes.SETTINGS_SAVE_PASSWORD_REQUEST } }
    function success() { return { type: actionTypes.SETTINGS_SAVE_PASSWORD_SUCCESS } }
    function failure(error) { return { type: actionTypes.SETTINGS_SAVE_PASSWORD_FAILURE, error } }
}