import { actionTypes } from '../actions/types';

const initialState = {
    statistic: null,
    departmentStatistic: null,
    loadingDepartmentStatistic: false,
    loading: false,
    loadingError: '',
};

const statisticReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STATISTIC_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.STATISTIC_SUCCESS:
            return {
                ...state,
                statistic: action.statistic,
                loading: false,
            };
        case actionTypes.STATISTIC_FAILURE:
            return {
                ...state,
                loadingError: action.error,
                loading: false,
            };
        case actionTypes.DEPARTMENT_STATISTIC_REQUEST:
            return {
                ...state,
                loadingDepartmentStatistic: true,
                loadingError: '',
            };
        case actionTypes.DEPARTMENT_STATISTIC_SUCCESS:
            return {
                ...state,
                departmentStatistic: action.statistic,
                loadingDepartmentStatistic: false,
            };
        case actionTypes.DEPARTMENT_STATISTIC_FAILURE:
            return {
                ...state,
                loadingError: action.error,
                loadingDepartmentStatistic: false,
            };
        default:
            return state;
    }
};

export default statisticReducer;
