import * as React from 'react';
import { Container, Input } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
class Observations extends React.Component {

    static propTypes = {
        text: PropTypes.string,
        handleText: PropTypes.func,
        editable: PropTypes.bool,
    };

    handleText = (e) => {
        this.props.handleText(e);
    }

    componentDidMount() {
        if (this.refs.observation.refs.observation) {
            let t = this.refs.observation.refs.observation;
            let l = this.props && this.props.text ? this.props.text.length : 0;
            if (t.setSelectionRange) {
                t.focus();
                t.selectionStart = t.selectionEnd = l;
            }
            else if (t.createTextRange) {
                let range = t.createTextRange();
                range.collapse(true);
                range.moveEnd('character', l);
                range.moveStart('character', l);
                range.select();
            }
        }
    }



    render() {
        return (
            <Container fluid className="m-0 p-0 pt-4 my-2 text-end border-top">
                <Container fluid className={this.props.loading ? 'd-none' : 'm-0 p-0'}>
                    <Container fluid className="text-start m-0 p-0">
                        <FormattedMessage id="observations.label.observationsfor" />
                    </Container>
                    <Input
                        type="textarea"
                        innerRef="observation"
                        ref="observation"
                        autoFocus={true}
                        disabled={!(this.props.editable)}
                        name="observation"
                        id="observation"
                        style={{ minHeight: "15rem", width: '100%' }}
                        placeholder={this.props.intl.formatMessage({ id: "observations.textarea.placeholder" })}
                        value={this.props.text}
                        onChange={this.handleText.bind(this)} />
                </Container>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Observations));