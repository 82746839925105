import { actionTypes } from '../actions/types';

const initialState = {
    loadingError: '',
    structur: [],
    loading: false,
    createStructurState: 0,
    createStructurError: '',
    updateStructurState: 0,
    updateStructurError: '',
    deleteStructurState: 0,
    deleteStructurError: '',
    sortStructurState: 0,
    sortStructurError: '',
    createDocumentState: 0,
    createDocumentError: '',
    updateDocumentState: 0,
    updateDocumentError: '',
    deleteDocumentState: 0,
    deleteDocumentError: '',
    sortDocumentState: 0,
    sortDocumentError: '',
};

const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DOCUMENTS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingError: '',
            };
        case actionTypes.DOCUMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                structur: action.structur ? action.structur : [],
            };
        case actionTypes.DOCUMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                loadingError: action.error,
                structur: [],
            };
        case actionTypes.DOCUMENTS_STRUCTUR_CREATE_REQUEST:
            return {
                ...state,
                createStructurState: 0,
                createStructurError: '',
            };
        case actionTypes.DOCUMENTS_STRUCTUR_CREATE_SUCCESS:
            return {
                ...state,
                createStructurState: 1,
            };
        case actionTypes.DOCUMENTS_STRUCTUR_CREATE_FAILURE:
            return {
                ...state,
                createStructurState: 2,
                createStructurError: action.error.error,
            };
        case actionTypes.DOCUMENTS_STRUCTUR_UPDATE_REQUEST:
            return {
                ...state,
                updateStructurState: 0,
                updateStructurError: '',
            };
        case actionTypes.DOCUMENTS_STRUCTUR_UPDATE_SUCCESS:
            return {
                ...state,
                updateStructurState: 1,
            };
        case actionTypes.DOCUMENTS_STRUCTUR_UPDATE_FAILURE:
            return {
                ...state,
                updateStructurState: 2,
                updateStructurError: action.error.error,
            };
        case actionTypes.DOCUMENTS_STRUCTUR_DELETE_REQUEST:
            return {
                ...state,
                deleteStructurState: 0,
                deleteStructurError: '',
            };
        case actionTypes.DOCUMENTS_STRUCTUR_DELETE_SUCCESS:
            return {
                ...state,
                deleteStructurState: 1,
            };
        case actionTypes.DOCUMENTS_STRUCTUR_DELETE_FAILURE:
            return {
                ...state,
                deleteStructurState: 2,
                deleteStructurError: action.error,
            };
        case actionTypes.DOCUMENTS_CREATE_REQUEST:
            return {
                ...state,
                createDocumentState: 0,
                createDocumentError: '',
            };
        case actionTypes.DOCUMENTS_CREATE_SUCCESS:
            return {
                ...state,
                createDocumentState: 1,
            };
        case actionTypes.DOCUMENTS_CREATE_FAILURE:
            return {
                ...state,
                createDocumentState: 2,
                createDocumentError: action.error.error,
            };
        case actionTypes.DOCUMENTS_UPDATE_REQUEST:
            return {
                ...state,
                updateDocumentState: 0,
                updateDocumentError: '',
            };
        case actionTypes.DOCUMENTS_UPDATE_SUCCESS:
            return {
                ...state,
                updateDocumentState: 1,
            };
        case actionTypes.DOCUMENTS_UPDATE_FAILURE:
            return {
                ...state,
                updateDocumentState: 2,
                updateDocumentError: action.error.error,
            };
        case actionTypes.DOCUMENTS_DELETE_REQUEST:
            return {
                ...state,
                deleteDocumentState: 0,
                deleteDocumentError: '',
            };
        case actionTypes.DOCUMENTS_DELETE_SUCCESS:
            return {
                ...state,
                deleteDocumentState: 1,
            };
        case actionTypes.DOCUMENTS_DELETE_FAILURE:
            return {
                ...state,
                deleteDocumentState: 2,
                deleteDocumentError: action.error,
            };
        case actionTypes.DOCUMENTS_STRUCTUR_SORT_REQUEST:
            return {
                ...state,
                sortStructurState: 0,
                sortStructurError: '',
            };
        case actionTypes.DOCUMENTS_STRUCTUR_SORT_SUCCESS:
            return {
                ...state,
                sortStructurState: 1,
            };
        case actionTypes.DOCUMENTS_STRUCTUR_SORT_FAILURE:
            return {
                ...state,
                sortStructurState: 2,
                sortStructurError: action.error,
            };
        case actionTypes.DOCUMENTS_SORT_REQUEST:
            return {
                ...state,
                sortDocumentState: 0,
                sortDocumentError: '',
            };
        case actionTypes.DOCUMENTS_SORT_SUCCESS:
            return {
                ...state,
                sortDocumentState: 1,
            };
        case actionTypes.DOCUMENTS_SORT_FAILURE:
            return {
                ...state,
                sortDocumentState: 2,
                sortDocumentError: action.error,
            };
        default:
            return state;
    }
};

export default documentsReducer;
