import ENV from '../config/env';

export const interesttestService = {
    getStudentsInteresttest,
};

async function getStudentsInteresttest(token, executionId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    };


    return fetch(ENV.apiUrl + '/interesttest/' + executionId, requestOptions)
        .then(response => {

            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            if (response.status === 204) {
                return null;
            }

            return response.json();

        }).then(response => {
            return response;
        });

}
