import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Label, Row, Col } from 'reactstrap';

class DocumentStructurModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        toggle: PropTypes.func,
        structur: PropTypes.object,
        handleAddStructur: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            structur: { id: -1, name: '' }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.structur !== this.props.structur) {
            this.setState({ structur: this.props.structur });
        }
    }

    handleName = (e) => {
        this.setState({ structur: { ...this.state.structur, name: e.target.value } });
    }

    handleSubmit = (e) => {
        this.props.handleAddStructur(this.state.structur);
    }

    render() {
        return (
            <Container>
                <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} className="modal-height-100 modal-width-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                    <ModalHeader>
                        {this.state.structur.id === -1 ?
                            <FormattedMessage id="documents.structur.modal.title.add" />
                            :
                            <FormattedMessage id="documents.structur.modal.title.edit" />
                        }
                    </ModalHeader>

                    <ModalBody>
                        {
                            <Container className="m-0 px-3">
                                <Row className="border-bottom border-top">
                                    <Col className="mb-2 mt-2" xs="12" md="3">
                                        <Label className="fw-bold d-flex align-items-center h-100" for="name"><FormattedMessage id="documents.structur.modal.name" /></Label>
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        <Input bsSize="sm" type="input" name="name" id="name" value={this.state.structur.name} onChange={this.handleName}></Input>
                                    </Col>
                                </Row>
                            </Container>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button type="submit" color="primary" onClick={this.props.toggle.bind(this)}><FormattedMessage id="button.cancel" /></Button>
                        <Button type="submit" color="primary" disabled={this.state.structur.name.length < 3} onClick={this.handleSubmit.bind(this)}>
                            <FormattedMessage id="button.save" />
                        </Button>
                    </ModalFooter>

                </Modal >
            </Container >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentStructurModal));