import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class TableHead extends React.Component {

    static propTypes = {
        data: PropTypes.array, //[{text: '', icon:{src:'',alt,'',className:''}, order: '', width: ''}]
        order: PropTypes.func,
        editdelete: PropTypes.bool,
        orderDefault: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            lastorder: { order: "", desc: "" }
        };
    }

    order(value) {
        if (this.state.lastorder.order === value && !this.state.lastorder.desc) {
            this.setState({ lastorder: { order: value, desc: true } });
            this.props.order && this.props.order(value, true);
        } else {
            this.setState({ lastorder: { order: value, desc: false } });
            this.props.order && this.props.order(value, false);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.orderDefault !== prevProps.orderDefault) {
            this.setState({ lastorder: { order: this.props.orderDefault, desc: "" } });
        }
    }

    render() {

        return <thead><tr className="tablehead-border-bottom w-100" >{this.props.data.map((value, index) => {
            if (value.text && value.order) {
                return <th key={"tablehead" + index} style={{ width: value.width ? value.width : 'auto' }} className={value.className} >

                    <Button name={'orderButton' + index} aria-sort={value.order === this.state.lastorder.order ? !this.state.lastorder.desc ? "ascending" : "descending" : "none"} color="link" onClick={() => this.order(value.order)} block className="text-start th-button d-flex flex-row justify-content-between">
                        <div><FormattedMessage id={value.text} /></div>
                        <div><span className={value.order === this.state.lastorder.order ? !this.state.lastorder.desc ? "th-order up" : "th-order down" : "th-order"}></span></div>
                    </Button>

                </th>
            } else if (value.text) {
                return <th key={"tablehead" + index} className={"text-start " + value.className} style={{ width: value.width ? value.width : 'auto' }}>
                    <div><FormattedMessage id={value.text} /></div>
                </th>

            } else if (value.icon) {
                return <th key={"tablehead" + index} className={"text-start p-1 " + value.className} style={{ width: value.width ? value.width : 'auto' }}>
                    <img className={value.icon.className && value.icon.className} src={value.icon.src && require('../assets/' + value.icon.src)} alt={this.props.intl.formatMessage({ id: value.icon.alt })} />
                </th>

            } else {
                return <th key={"tablehead" + index} style={{ width: value.width ? value.width : 'auto' }} className={value.className}>&nbsp;</th>
            }
        })}

            {
                this.props.editdelete && <th className="table-border-left-action th-min-width" key={"tableheadedit"} ></th>
            }


        </tr></thead>;

    }

}

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(TableHead));