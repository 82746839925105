import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';
import moment from 'moment';
import config from '../config/config';
import { replaceDateTimeSpecialChars } from '../utils/Tools'
import { CheckAuthorization } from '../models/Authorization';
import { sortProfessinalfields } from '../utils/Tools';

export const generateResultPDF = (props, state) => {

    let documentname = props.intl.formatMessage({ id: "results.print.filename" }, state.translationsVars)
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let execution = props.executions.filter((execution) => { return props.executionId === execution.id })[0];
    let starttime = moment(execution.start.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(execution.start.replace(" ", "T"), config.dateFormat.format, true) : null;
    let endtime = moment(execution.end.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(execution.end.replace(" ", "T"), config.dateFormat.format, true) : null;

    let interesttestArray = [];
    let lastPf = null;
    let countPf = 0;
    let approveddate = moment(props.skillsProfile.approveddate.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(props.skillsProfile.approveddate.replace(" ", "T"), config.dateFormat.format, true) : moment();

    //get 3 professionalfields with rating over 33%
    props.skillsProfile && props.skillsProfile.interesttest && props.skillsProfile.interesttest.filter((item) => { return item.procent >= 33.3 }).forEach((pf) => {
        if (lastPf === null || countPf < 3 || lastPf.procent === pf.procent) {
            countPf++;
            lastPf = pf;
            interesttestArray.push(pf);
        }
    });
    interesttestArray.sort(sortProfessinalfields);

    //Interesttest
    let interesttest = "";
    if (props.skillsProfile === null || (props.skillsProfile && props.skillsProfile.interesttest === null)) {
        interesttest = props.intl.formatMessage({ id: "professionalfield.print.nointeresttestcompleted" });
    } else if (props.skillsProfile && props.skillsProfile.interesttest !== null && props.skillsProfile.interesttest && interesttestArray.length === 0) {
        interesttest = props.intl.formatMessage({ id: "professionalfield.print.nointerest." + props.studentAddress }, state.translationsVars);
    } else if (props.skillsProfile && props.skillsProfile.interesttest && interesttestArray.length > 0) {

        if (interesttestArray.length === 1) {
            interesttest = props.intl.formatMessage({ id: "professionalfield.print.start.single" }, state.translationsVars);
        } else {
            interesttest = props.intl.formatMessage({ id: "professionalfield.print.start.multiple" }, state.translationsVars);
        }

        interesttestArray.forEach((pf, index) => {
            if (index === interesttestArray.length - 1 && index === 0) {
                interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
            }
            else if (index === interesttestArray.length - 1 && index >= 0) {
                interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield.print.and" }) + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
            } else if (index === 0) {
                interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
            } else {
                interesttest = interesttest + ", " + props.intl.formatMessage({ id: "professionalfield." + pf.id + ".name" });
            }

        })
        interesttest = interesttest + props.intl.formatMessage({ id: "professionalfield.print.end." + props.studentAddress }, state.translationsVars);
    }

    //get competences of the skillsprofile
    let competences = [];
    props.competences && props.competences.filter((competence) => {
        let tmp = false;
        props.studentObserverRating && props.studentObserverRating.observer && props.studentObserverRating.observer.forEach((rating) => {
            if (competence.id === rating.competences_id && props.skillsProfile && props.skillsProfile.competences.indexOf(rating.id) !== -1) {
                tmp = true;
            }
        })
        return tmp;
    }).forEach((competence) => {
        let rating = null;
        props.studentObserverRating.observer.forEach((r) => {
            if (competence.id === r.competences_id) {
                rating = r;
            }
            return null;
        });
        competences.push({
            table: {
                dontBreakRows: true,
                headerRows: 0,
                widths: ["*"],
                body: [
                    [
                        {
                            stack: [{

                                fontSize: 10,
                                bold: true,
                                text: props.intl.formatMessage({ id: competence.name }),
                                margin: [0, 10, 0, 0],
                                width: "100%"
                            }, {
                                fontSize: 10,
                                text: rating.observations.trim(),
                                alignment: "justify",
                                lineHeight: 1.3,
                            }]
                        }
                    ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                paddingLeft: function (i) {
                    return 0;
                },
                paddingRight: function (i, node) {
                    return 0;
                }
            }
        })

    });

    //no competences in the skillsprofile 
    if (competences.length === 0) {
        competences.push({
            //no competence
            text: props.intl.formatMessage({ id: "skillsprofile.print.placeholder.competences" }, state.translationsVars),
            fontSize: 10,
            alignment: "justify",
            lineHeight: 1.3,
            margin: [0, 10, 0, 0]
        });
    }

    //Self- and externalassessment
    //Tableheader
    let rows = [[
        { text: props.intl.formatMessage({ id: "feedback.label.competence" }), fontSize: 12, color: 'white', fillColor: [67, 53, 140], border: [1, 1, 1, 1] },
        {
            stack: [
                { image: 'smiley1', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-1" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley4', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-2" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley3', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-3" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
        {
            stack: [
                { image: 'smiley2', width: 25, height: 25, fillColor: [67, 53, 140], marginTop: 5, marginBottom: 10 },
                { text: props.intl.formatMessage({ id: "feedback.label.rating-4" }), fontSize: 8, color: 'white' },
            ],
            fillColor: [67, 53, 140],
            alignment: "center",
        },
    ]];
    //Tablebody
    props.competences.forEach((trData, trIndex) => {
        let tmpA = [];
        tmpA.push([{ text: props.intl.formatMessage({ id: trData.description }) + '\n\n' + props.intl.formatMessage({ id: trData.name }).toUpperCase(), fontSize: 10 }]);

        for (let i = 1; i <= 4; i++) {
            let tmpR = [];
            if (props.studentObserverRating.student && props.studentObserverRating.student.filter((el) => (el.competences_id === trData.id && el.rating === i)).length > 0) {
                //tmpR.push({ text: state.symbolselfassessment, alignment: "center", fontSize: 24, color: colorSelfassessment });
                tmpR.push({ image: trIndex % 2 === 0 ? 'student' : 'student', width: 15, height: 15, alignment: "center" });
            }
            if (props.studentObserverRating.observer && props.studentObserverRating.observer.filter((el) => (el.competences_id === trData.id && (el.rating === i || el.rating2 === i))).length > 0) {
                //tmpR.push({ text: state.symbolexternalassessment, alignment: "center", fontSize: 24, color: colorExternalassessment });
                tmpR.push({ image: trIndex % 2 === 0 ? 'observer' : 'observer', width: 15, height: 15, alignment: "center" });
            }
            tmpA.push(tmpR);
        }

        rows.push(tmpA);
    });

    // interesttest

    const interesttestBarColumns = 40;

    //const barColors = [[0,150,64], [254,155,0], [227,6,19]]; // multi color
    //const interesttestIntervalLineColor = [60, 60, 60]; // multi color
    const barColors = [[56, 57, 128], [56, 57, 128], [56, 57, 128]]; // single color (OloV blue)
    const interesttestIntervalLineColor = [186, 186, 186]; // single color

    let interesttestRows = [];
    let interesttestWidths = [100];

    // setup columns
    let interesttestCols = [{ text: '', width: 100, height: 10 }];
    for (let i = 0; i < interesttestBarColumns; i++) {
        interesttestCols.push({
            text: '',
            width: 1,
            height: 10
        });
        interesttestWidths.push(1);
    }

    // header
    let headRow = [{ text: '', border: [0, 0, 0, 0] }];
    for (let i = 0; i < interesttestBarColumns; i++) {
        if (i === 0) {
            headRow.push({ image: 'smiley2', width: 25, height: 25, colSpan: 13, alignment: 'center', border: [0, 0, 1, 0] });
        } else if (i === 13) {
            headRow.push({ image: 'smiley3', width: 25, height: 25, colSpan: 14, alignment: 'center', border: [0, 0, 1, 0] });
        } else if (i === 27) {
            headRow.push({ image: 'smiley1', width: 25, height: 25, colSpan: 13, alignment: 'center', border: [0, 0, 0, 0] });
        } else {
            headRow.push('');
        }
    }
    interesttestRows.push(headRow);

    if (props.skillsProfile && props.skillsProfile.interesttest !== null) {
        for (let i = 0; i < props.skillsProfile.interesttest.length; i++) {
            let itField = props.skillsProfile.interesttest[i];
            let itRow = [];
            itRow.push({ text: itField.name, fontSize: 7, border: [0, 0, 0, 0], alignment: 'right' });

            // add percentage bar
            for (let j = 0; j < interesttestBarColumns; j++) {
                const pScaledVal = itField.value / itField.maxValue * interesttestBarColumns;
                const pPercentVal = pScaledVal / interesttestBarColumns * 100;

                let borderLeft = 0, borderRight = 0;
                if (j === 13) borderLeft = 1;
                else if (j === 27) borderLeft = 1;
                //else if (pScaledVal === 100) { borderLeft = 1; borderRight = 1; }

                if (j <= pScaledVal) {
                    //let p = itField.value * 100 / itField.maxValue;
                    let col = (pPercentVal >= 66.6 ? barColors[0] : pPercentVal >= 33.3 ? barColors[1] : barColors[2]);

                    let percentageCol = { text: '', fillColor: col, border: [borderLeft, 0, borderRight, 1] };
                    itRow.push(percentageCol);
                } else {
                    let clearCol = { text: '', fillColor: [255, 255, 255], border: [borderLeft, 0, borderRight, 1] };
                    itRow.push(clearCol);
                }
            }

            interesttestRows.push(itRow);
        }
    }

    let content = []
    content.push({
        //Title
        text: props.intl.formatMessage({ id: "skillsprofile.print.title" }),
        fontSize: 20,
    });
    content.push({
        //Subtitle
        text: props.intl.formatMessage({ id: "skillsprofile.print.subtitle" }),
        fontSize: 10,
        margin: [0, 0, 0, 10],
        bold: true,
    });
    content.push({
        columns: [
            {
                //Student
                text: props.intl.formatMessage({ id: "skillsprofile.print.name" }),
                fontSize: 10,
                bold: true,
                width: 150,
                margin: [0, 0, 0, 5]
            },
            {
                //Student
                text: props.studentLastname + ", " + props.studentFirstname,
                fontSize: 10,
            }
        ]
    });
    content.push({
        columns: [
            {
                //School
                text: props.intl.formatMessage({ id: "skillsprofile.print.school" }),
                fontSize: 10,
                bold: true,
                width: 150,
                margin: [0, 0, 0, 5]
            },
            {
                //School
                text: execution.school.name + ", " + execution.school.place,
                fontSize: 10,
            }
        ]
    });
    content.push({
        columns: [
            {
                //Class
                text: props.intl.formatMessage({ id: "skillsprofile.print.class" }),
                fontSize: 10,
                bold: true,
                width: 150,
                margin: [0, 0, 0, 5]
            },
            {
                //Class
                text: props.studentClass,
                fontSize: 10,
            }
        ]
    });
    content.push({
        columns: [
            {
                //Executiontime
                text: props.intl.formatMessage({ id: "skillsprofile.print.executiontime" }),
                fontSize: 10,
                bold: true,
                width: 150,
                margin: [0, 0, 0, 5]
            },
            {
                //Executiontime
                text: replaceDateTimeSpecialChars(props.intl.formatDate(starttime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day }))
                    + " - "
                    + replaceDateTimeSpecialChars(props.intl.formatDate(endtime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day })),
                fontSize: 10,
            }
        ]
    });
    content.push({
        //About
        text: props.intl.formatMessage({ id: "skillsprofile.print.header.about" }),
        fontSize: 10,
        bold: true,
        margin: [0, 15, 0, 0]
    });
    content.push({
        //Linie
        table: {
            widths: ["*"],
            body: [[""]]
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0) ? 1 : 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return [56, 57, 128];
            },
        }
    });
    content.push({
        //About
        text: props.intl.formatMessage({ id: "skillsprofile.print.body.about" }, state.translationsVars),
        fontSize: 10,
        alignment: "justify",
        lineHeight: 1.3
    });
    content.push({
        //Result
        text: props.intl.formatMessage({ id: "skillsprofile.print.header.results" }),
        fontSize: 10,
        bold: true,
        margin: [0, 15, 0, 0]
    });
    content.push({
        //Linie
        table: {
            widths: ["*"],
            body: [[""]]
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0) ? 1 : 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return [56, 57, 128];
            },
        }
    });
    content.push({
        type: "square",
        fontSize: 10,
        color: [56, 57, 128],
        ul: [
            {
                //Interesttest
                text: props.intl.formatMessage({ id: "skillsprofile.print.subtitle.interesttest" }),
                fontSize: 10,
                bold: true,
                color: [0, 0, 0]
            }
        ],
    });
    content.push({
        margin: [0, 10, 0, 0],
        text: interesttest,
        fontSize: 10,
        alignment: "justify",
        lineHeight: 1.3,
    })
    content.push({
        type: "square",
        fontSize: 10,
        color: [56, 57, 128],
        ul: [
            {
                //Competences
                margin: [0, 10, 0, 0],
                text: props.intl.formatMessage({ id: "skillsprofile.print.subtitle.competences" }),
                fontSize: 10,
                bold: true,
                color: [0, 0, 0],
            }
        ],
    });
    content.push(competences)
    content.push({
        //Recommendation
        text: props.intl.formatMessage({ id: "skillsprofile.print.header.recommendation" }, state.translationsVars),
        fontSize: 10,
        bold: true,
        margin: [0, 15, 0, 0]
    });
    content.push({
        //Linie
        table: {
            widths: ["*"],
            body: [[""]]
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0) ? 1 : 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return [56, 57, 128];
            },
        }
    });


    if (props.skillsProfile.jobtext && props.skillsProfile.jobtext.length > 0) {
        content.push({
            //Recommendation
            text: props.skillsProfile.jobtext.trim(),
            fontSize: 10,
            alignment: "justify",
            lineHeight: 1.3,
            margin: [0, 0, 0, 15]
        });
    }
    content.push({
        //Recommendation
        text: props.intl.formatMessage({ id: state.variantPrintId }, state.translationsVars),
        fontSize: 10,
        alignment: "justify",
        lineHeight: 1.3
    });
    content.push({
        columns: [
            {
                //Place and date
                text: execution.school.place + ", " + replaceDateTimeSpecialChars(props.intl.formatDate(approveddate, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day })),
                fontSize: 10,
                width: 300,
            },
            {
                //name of observer
                text: props.skillsProfile.observer.firstname + " " + props.skillsProfile.observer.lastname,
                fontSize: 10,
            }
        ],
        margin: [0, 40, 0, 0]
    });
    content.push({
        columns: [
            {
                //name and place of school
                text: CheckAuthorization('results-pdf-bwhw', props.auth.roles) ? props.intl.formatMessage({ id: "results.print.bwhw" }) : execution.school.name + ", " + execution.school.place,
                fontSize: 6,
                margin: [300, 0, 0, 0],
                pageBreak: 'after'
            }
        ],
    });
    //Self and Externalassessment
    content.push({
        //Title
        text: props.intl.formatMessage({ id: "feedback.print.title" }),
        fontSize: 20,
    });
    content.push({
        //Subtitle
        text: props.intl.formatMessage({ id: "feedback.print.subtitle" }),
        fontSize: 10,
    });
    content.push({
        //Student
        text: props.studentFirstname + " " + props.studentLastname,
        fontSize: 10,
        margin: [0, 0, 0, 10]
    });
    content.push({
        table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ["*", 40, 40, 40, 40],
            body: rows
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0.5;
            },
            vLineWidth: function (i) {
                return 0.5;
            },
        },
    });
    content.push({
        columns: [
            {
                width: 'auto',
                text: props.intl.formatMessage({ id: "feedback.description.print.selfassessment" }),
                fontSize: 8
            },
            {
                image: 'student',
                width: 10,
                height: 10,
            },
            {
                width: 'auto',
                text: " / " + props.intl.formatMessage({ id: "feedback.description.print.externalassessment" }),
                fontSize: 8
            },
            {
                image: 'observer',
                width: 10,
                height: 10
            },
            {
                width: 'auto',
                text: " / " + props.intl.formatMessage({ id: "feedback.description.print.noassessment" }),
                fontSize: 8
            },
        ],
        margin: [5, 2, 5, 2],
        pageBreak: 'after',
    });


    // interest test
    content.push({
        //Title
        text: props.intl.formatMessage({ id: "skillsprofile.print.title" }),
        fontSize: 20,
    });
    content.push({
        //Subtitle
        text: props.intl.formatMessage({ id: "skillsprofile.print.interesttest.subtitle" }),
        fontSize: 10,
        margin: [0, 0, 0, 10],
        bold: true,
    });
    content.push({
        columns: [
            {
                //Student
                text: props.intl.formatMessage({ id: "skillsprofile.print.name" }),
                fontSize: 10,
                bold: true,
                width: 150,
                margin: [0, 0, 0, 5]
            },
            {
                //Student
                text: props.studentLastname + ", " + props.studentFirstname,
                fontSize: 10,
            }
        ]
    });
    content.push({
        columns: [
            {
                //School
                text: props.intl.formatMessage({ id: "skillsprofile.print.school" }),
                fontSize: 10,
                bold: true,
                width: 150,
                margin: [0, 0, 0, 5]
            },
            {
                //School
                text: execution.school.name + ", " + execution.school.place,
                fontSize: 10,
            }
        ]
    });
    content.push({
        columns: [
            {
                //Class
                text: props.intl.formatMessage({ id: "skillsprofile.print.class" }),
                fontSize: 10,
                bold: true,
                width: 150,
                margin: [0, 0, 0, 5]
            },
            {
                //Class
                text: props.studentClass,
                fontSize: 10,
            }
        ]
    });
    content.push({
        //Results
        text: props.intl.formatMessage({ id: "skillsprofile.print.header.interesttest.results" }, { date: replaceDateTimeSpecialChars(props.intl.formatDate(starttime, { month: config.dateFormat.month, year: config.dateFormat.year, day: config.dateFormat.day })) }),
        fontSize: 10,
        bold: true,
        margin: [0, 15, 0, 0]
    });
    content.push({
        //Linie
        table: {
            widths: ["*"],
            body: [[""]],
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0) ? 1 : 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return [56, 57, 128];
            },
        }
    });
    if (props.skillsProfile.interesttest !== null) {
        content.push({
            //Results body
            text: props.intl.formatMessage({ id: "skillsprofile.print.interesttest.results.variant.1." + props.studentAddress }, state.translationsVars),
            fontSize: 10,
            alignment: "justify",
            lineHeight: 1.3
        });
        content.push({
            table: {
                dontBreakRows: true,
                headerRows: 0,
                widths: interesttestWidths,
                body: interesttestRows
            },
            margin: [0, 25, 0, 0],
            layout: {
                hLineWidth: function (i, node) {
                    return 5;
                },
                vLineWidth: function (i) {
                    return 0.8;
                },
                hLineColor: function (i, node) {
                    return 'white';
                },
                vLineColor: function (i, node) {
                    return interesttestIntervalLineColor;
                },
            },
        });
        content.push({
            columns: interesttestCols,
        });
    } else {
        content.push({
            text: props.intl.formatMessage({ id: "skillsprofile.print.interesttest.results.variant.no.result" }, state.translationsVars),
            fontSize: 10,
            alignment: "justify",
            lineHeight: 1.3,
        });
    }
    content.push({
        //More information
        text: props.intl.formatMessage({ id: "skillsprofile.print.header.interesttest.moreinfo" }),
        fontSize: 10,
        bold: true,
        margin: [0, 30, 0, 0]
    });
    content.push({
        //Linie
        table: {
            widths: ["*"],
            body: [[""]]
        },
        layout: {
            hLineWidth: function (i, node) {
                return (i === 0) ? 1 : 0;
            },
            vLineWidth: function (i, node) {
                return 0;
            },
            hLineColor: function (i, node) {
                return [56, 57, 128];
            },
        }
    });
    content.push({
        //More Info body
        text: props.intl.formatMessage({ id: "skillsprofile.print.interesttest.moreinfo" }, state.translationsVars),
        fontSize: 10,
        alignment: "justify",
        lineHeight: 1.3
    });



    let docDefinition = {
        //595pt x 842pt
        //pageOrientation: 'landscape',
        pageSize: 'A4',
        pageMargins: [40, 75, 40, 65],
        images: Images,
        header: Header,
        footer: (currentPage, pageCount) => { return footer(props, false, currentPage, pageCount, false) },
        content: content,
        styles: { barCell: { margin: [0, 10, 0, 10] } }
    };

    pdfMake.createPdf(docDefinition).download(documentname);
}