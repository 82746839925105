import ENV from '../config/env';

export const motivationService = {
    getMotivations,
    saveMotivations,
    saveResultMotivations
};

async function getMotivations(token, executionId, studentsId, honorarkraftId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };
    
    studentsId = studentsId ? studentsId : '';
    honorarkraftId = honorarkraftId > 0 ? honorarkraftId : '';

    return fetch(ENV.apiUrl + '/motivationexercise?executionId=' + executionId + '&studentId=' + studentsId + '&honorarkraftId=' + honorarkraftId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.status === 204) {
                return null;
            }
            return response.json();
        }).then(response => {
            return response;
        });
}

async function saveMotivations(token, executionId, motivationAnswers) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({motivations: motivationAnswers})
    };

    return fetch(ENV.apiUrl + '/motivationexercise?executionId=' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return 'save-error';
            }
            return response;
        });
}

async function saveResultMotivations(token, executionId, motivations) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({motivations: motivations})
    };

    return fetch(ENV.apiUrl + '/motivationResult?executionId=' + executionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            if (response.status === 204) {
                return 'save-error';
            }
            return response;
        });
}
