import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Input, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Form, Alert, FormFeedback } from 'reactstrap';
import { isEquivalent, isEmail } from '../utils/Tools';
import Confirm from './Confirm';
import UsersAddressSelect from './UsersAddressSelect';
//import UsersTitleSelect from './UsersTitleSelect';
import config from '../config/config';
import { authorizationRoles } from '../config/authorization';
import DisplaySchool from './DisplaySchool';
import SchoolyearsSelect from './SchoolyearsSelect';
import { schoolsActions } from '../actions';

class StudentAdministrationModal extends React.Component {

    static propTypes = {
        school: PropTypes.object,
        student: PropTypes.object,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        save: PropTypes.func.isRequired,
        procedure: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            student: null,
            lastschoolyears: { id: -1 },
            open: false,
            new: false,
            alert: { visible: false, textId: "studentadministrationmodal.alert.inputs", color: "success" },
            invalid: { schoolyears: false, address: false, title: false, firstname: false, lastname: false, email: false },
        }

    }

    componentDidMount() {
        this.props.dispatchGetSchoolyears(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {

        if (this.props.student && prevProps.student !== this.props.student) {
            let ex = JSON.parse(JSON.stringify(this.props.student));
            this.setState({ student: ex, alert: { visible: false, textId: "studentadministrationmodal.alert.inputs", color: "success" } });
        }

        if (prevProps.open !== this.props.open) {
            this.setState({ new: false });
        }

        if (prevProps.student === null && !this.state.new) {
            this.setState({
                new: true,
                student: {
                    id: null,
                    editable: true,
                    username: null,
                    password: null,
                    roles: [{ id: authorizationRoles.student }],
                    school: this.props.school,
                    schoolyears: this.state.lastschoolyears,
                    procedure: this.props.procedure ? [{ id: this.props.procedure.id, name: this.props.procedure.name }] : null,
                    firstname: '',
                    lastname: '',
                    title: { id: 1 },
                    address: { id: -1 },
                    email: '', class: ''
                },
                invalid: { personalnumber: false, address: false, title: false, firstname: false, lastname: false, email: false },
                alert: { visible: false, textId: "studentadministrationmodal.alert.inputs", color: "success" }
            });
        }

        if (prevProps.createUserState !== this.props.createUserState || prevProps.updateUserState !== this.props.updateUserState) {
            if (this.props.createUserState === 2 || this.props.updateUserState === 2) {
                if (this.props.createError === 'email' || this.props.updateError === 'email') {
                    this.setState({ invalid: { personalnumber: this.state.invalid.personalnumber, address: this.state.invalid.address, title: this.state.invalid.title, firstname: this.state.invalid.firstname, lastname: this.state.invalid.lastname, email: false } });
                } else {
                    this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                    setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                }
            }
        }

    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            if (this.props.student && !isEquivalent(this.props.student, this.state.student)) {
                this.toggleConfirm();
            } else {
                this.props.toggle();
            }
        } else if (type === "save") {
            this.save();
        }
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    save = async (e) => {
        let newState = { ...this.state }
        let doSave = true;
        newState.confirm = false;
        /* if (this.state.student.personalnumber.length <= 0) {
            newState.invalid.personalnumber = false;
            doSave = false;
        } */
        if (this.state.student.schoolyears.id === -1) {
            newState.invalid.schoolyears = true;
            doSave = false;
        } else {
            // new user
            if (this.props.student === null && this.state.student.schoolyears.start < new Date().getYear() + 1900 && this.state.student.schoolyears.end < new Date().getYear() + 1900) {
                newState.invalid.schoolyears = true;
                doSave = false;
            } else if (this.props.student !== null && this.props.student.schoolyears.id !== this.state.student.schoolyears.id && this.state.student.schoolyears.start < new Date().getYear() + 1900 && this.state.student.schoolyears.end < new Date().getYear() + 1900) {
                newState.invalid.schoolyears = true;
                doSave = false;
            }
        }
        if (this.state.student.address.id === -1) {
            newState.invalid.address = true;
            doSave = false;
        }
        /* if (this.state.student.title.id === -1) {
            newState.invalid.title = false;
            doSave = false;
        } */
        if (this.state.student.firstname.length <= 1) {
            newState.invalid.firstname = true;
            doSave = false;
        }
        if (this.state.student.lastname.length <= 1) {
            newState.invalid.lastname = true;
            doSave = false;
        }
        if (this.state.student.email && this.state.student.email.length > 0 && !isEmail(this.state.student.email)) {
            newState.invalid.email = true;
            doSave = false;
        }

        if (doSave) {
            this.props.save(this.state.student);
        } else {
            newState.alert = { visible: true, textId: "studentadministrationmodal.alert.inputs", color: "danger" };
            setTimeout(this.hideAlert, config.alertTimeOut);
        }
        this.setState(newState);
    }

    hideAlert = (e) => {
        this.setState({ alert: { visible: false, textId: "studentadministrationmodal.alert.inputs", color: "danger" } });
    };

    handleSchoolyears = async (schoolyear) => {
        let newState = { ...this.state }
        newState.student.schoolyears = schoolyear;
        newState.lastschoolyears = schoolyear;
        newState.invalid.schoolyears = false;
        await this.setState(newState);
    }
    handleUsersAddressId = async (id) => {
        let newState = { ...this.state }
        newState.student.address.id = parseInt(id, 10);
        newState.invalid.address = false;
        await this.setState(newState);
    }
    /* handleUsersTitleId = async (id) => {
        let newState = { ...this.state }
        newState.student.title.id = parseInt(id, 10);
        newState.invalid.title = false;
        await this.setState(newState);
    } */

    /*  handlePersonalnumber = async (e) => {
        let newState = { ...this.state }
        newState.student.personalnumber = e.target.value;
        newState.invalid.personalnumber = false;
        await this.setState(newState);
    } */
    handleFirstname = async (e) => {
        let newState = { ...this.state }
        newState.student.firstname = e.target.value;
        newState.invalid.firstname = false;
        await this.setState(newState);
    }
    handleLastname = async (e) => {
        let newState = { ...this.state }
        newState.student.lastname = e.target.value;
        newState.invalid.lastname = false;
        await this.setState(newState);
    }
    handleEmail = async (e) => {
        let newState = { ...this.state }
        newState.student.email = e.target.value;
        newState.invalid.email = false;
        await this.setState(newState);
    }
    handleClass = async (e) => {
        let newState = { ...this.state }
        newState.student.class = e.target.value;
        newState.invalid.class = false;
        await this.setState(newState);
    }

    render() {
        return (
            <Container>
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                <Confirm cancel save={this.save} dontsave={this.props.toggle} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="confirm.body.save" />
                <Form>
                    <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                        <ModalHeader className={this.props.student && "edit"}><FormattedMessage id={!this.props.student ? "studentadministrationmodal.title.new" : "studentadministrationmodal.title.edit"} /></ModalHeader>

                        <ModalBody className="px-4">
                            {this.props.school &&
                                <Row className="border-bottom">
                                    <Col className="mb-2 fw-bold" xs="6" sm="6" md="3">
                                        <FormattedMessage id="executioninquirymodal.label.school" />
                                    </Col>
                                    <Col className="mb-2" xs="6" sm="6" md="9">
                                        <DisplaySchool school={this.props.school} name="ei" />
                                    </Col>
                                </Row>
                            }
                            {this.props.procedure && this.props.procedure.length > 0 &&
                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                        <FormattedMessage id="procedureselect.header.default" />
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        <FormattedMessage id={this.props.procedure[0].name} />
                                    </Col>
                                </Row>
                            }
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                    <FormattedMessage id="studentadministrationmodal.label.username" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    {(this.props.student && this.props.student.username) ? this.props.student.username : <FormattedMessage id="studentadministrationmodal.label.nousername" />}
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                    <FormattedMessage id="studentadministrationmodal.label.personalnumber" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    {(this.props.student && this.props.student.personalnumber) ? this.props.student.personalnumber : <FormattedMessage id="studentadministrationmodal.label.nopersonalnumber" />}
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <FormattedMessage id="studentadministrationmodal.label.schoolyears" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <SchoolyearsSelect autoSelect className="m-0 p-0" feedbacklabel="studentadministrationmodal.feedback.empty" invalid={this.state.invalid.schoolyears} withEmpty schoolyears={this.props.schoolyears} schoolyearsId={this.state.student && this.state.student.schoolyears && this.state.student.schoolyears.id ? this.state.student.schoolyears.id : -1} handleSchoolyears={this.handleSchoolyears} />
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <FormattedMessage id="studentadministrationmodal.label.address" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <UsersAddressSelect className="m-0 p-0" feedbacklabel="studentadministrationmodal.feedback.empty" invalid={this.state.invalid.address} withEmpty usersAddressId={this.state.student && this.state.student.address.id} handleUsersAddressId={this.handleUsersAddressId} />
                                </Col>
                            </Row>
                            {/*
                            <Row>
                                <Col className="mb-2 p-0" xs="12">
                                    <UsersTitleSelect feedbacklabel="studentadministrationmodal.feedback.empty" invalid={this.state.invalid.title} withEmpty label="studentadministrationmodal.label.title" usersTitleId={this.state.student && this.state.student.title.id} handleUsersTitleId={this.handleUsersTitleId} />
                                </Col>
                            </Row>
                            */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <Label for="firstname" className="fw-bold"><FormattedMessage id="studentadministrationmodal.label.firstname" /></Label>
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.firstname} name="firstname" type="text" id="firstname" value={this.state.student && this.state.student.firstname} onChange={this.handleFirstname} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.firstname ? "true" : "false"}><FormattedMessage id="studentadministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <Label for="lastname" className="fw-bold"><FormattedMessage id="studentadministrationmodal.label.lastname" /></Label>
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.lastname} name="lastname" id="lastname" type="text" value={this.state.student && this.state.student.lastname} onChange={this.handleLastname} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.lastname ? "true" : "false"}><FormattedMessage id="studentadministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <Label for="email" className="fw-bold"><FormattedMessage id="studentadministrationmodal.label.email" /></Label>
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" invalid={this.state.invalid.email} name="email" id="email" type="email" value={(this.state.student && this.state.student.email) ? this.state.student.email : ''} onChange={this.handleEmail} />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.email ? "true" : "false"}><FormattedMessage id="studentadministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <Label for="class" className="fw-bold"><FormattedMessage id="studentadministrationmodal.label.class" /></Label>
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" name="class" id="class" type="text" value={(this.state.student && this.state.student.class) ? this.state.student.class : ''} onChange={this.handleClass} />
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>

                        <ModalFooter>
                            <Button type="submit" color="primary" name="cancel" id="cancel" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.cancel" /></Button>
                            <Button type="submit" color="primary" name="save" id="save" onClick={this.handleSubmit.bind(this, "save")}><FormattedMessage id="button.save" /></Button>
                        </ModalFooter>

                    </Modal>
                </Form>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolyears: state.schools.schoolyears,
        createUserState: state.userProfile.createUserState,
        updateUserState: state.userProfile.updateUserState,
        createError: state.userProfile.createError,
        updateError: state.userProfile.updateError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolyears: (token) => {
            dispatch(schoolsActions.getSchoolyears(token, "start", false));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentAdministrationModal));