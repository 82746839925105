import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'reactstrap';

class ImpressumPage extends Component {

    render() {
        return (
            <Container fluid role="main" className={this.props.auth.loggedIn ? "content-margin" : ""}>
                <h1>Impressum</h1>
                <h2>Herausgeber</h2>
                <p>Bildungswerk der Hessischen Wirtschaft e. V.<br />
                Emil-von-Behring-Stra&szlig;e 4<br />
                60439 Frankfurt am Main<br />
                Telefon: 069 95808-0<br />
                Telefax: 069 95808-259<br />
                E-Mail: <a className="text-secondary" href="mailto:zentrale@bwhw.de">zentrale@bwhw.de</a></p>
                <h2>Gesch&auml;ftsleitung</h2>
                <p>Joachim Disser [Gesch&auml;ftsf&uuml;hrer], Kai Weber [Gesch&auml;ftsf&uuml;hrer]</p>
                <h2>Vereinsregister</h2>
                <p>Amtsgericht Frankfurt am Main, VR 6592</p>
                <h2>Umsatzsteueridentifikationsnummer</h2>
                <p>DE224284408</p>
                <h2>Bildnachweis</h2>
                <p>Mr_Vector, Johanna Goodyear, picsfive/fotolia; ranplettranplett, aldomurillo/istockphoto;</p>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ImpressumPage));