import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Input, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Form, Alert, FormFeedback } from 'reactstrap';
import { isEquivalent, isEmail } from '../utils/Tools';
import Confirm from './Confirm';
import UsersAddressSelect from './UsersAddressSelect';
import UsersTitleSelect from './UsersTitleSelect';
import config from '../config/config';
import { authorizationRoles } from '../config/authorization';
import SchoolSelect from './SchoolSelect';
import SchoolyearsSelect from './SchoolyearsSelect';
import UsersFunctionsSelect from './UsersFunctionsSelect';
import RolesCheckbox from './RolesCheckbox';
import { schoolsActions, userProfileActions } from '../actions';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import moment from 'moment';
import Authorization from './Authorization';
import { CheckAuthorization } from '../models/Authorization';

import 'react-datepicker/dist/react-datepicker.css';
registerLocale('de', de);

class UserAdministrationModal extends React.Component {

    static propTypes = {
        user: PropTypes.object,
        open: PropTypes.bool,
        toggle: PropTypes.func,
        save: PropTypes.func.isRequired,
        procedures: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            user: null,
            open: false,
            new: false,
            alert: { visible: false, textId: "useradministrationmodal.alert.inputs", color: "success" },
            invalid: { roles: false, school: false, schoolyears: false, address: false, title: false, firstname: false, lastname: false, email: false },
            lastschoolyears: { id: -1 },
        }

    }

    componentDidMount() {
        this.props.dispatchGetSchoolyears(this.props.auth.token);
        this.props.dispatchGetSchools(this.props.auth.token);
        this.props.dispatchGetUsersFunction(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {

        if (this.props.user && prevProps.user !== this.props.user) {
            let ex = JSON.parse(JSON.stringify(this.props.user));
            if (this.props.user.trainingdate) {
                ex.trainingdate = moment(this.props.user.trainingdate).toDate();
            }
            this.setState({ user: ex, alert: { visible: false, textId: "useradministrationmodal.alert.inputs", color: "success" } });
        }

        if (prevProps.open !== this.props.open) {
            this.setState({ new: false });
        }

        if (this.props.user === null && !this.state.new) {
            this.setState({
                new: true,
                user: {
                    id: null,
                    editable: true,
                    username: null,
                    personalnumber: null,
                    password: null,
                    roles: [],
                    school: { id: -1 },
                    schoolyears: { id: -1 },
                    functions: { id: -1 },
                    firstname: '',
                    lastname: '',
                    title: { id: 1 },
                    address: { id: -1 },
                    email: '',
                    class: '',
                    certified: 0,
                    trainingdate: null,
                    trainingsupport: null,
                    procedure: [],
                },
                invalid: {
                    roles: false,
                    school: false,
                    personalnumber: false,
                    functions: false,
                    address: false,
                    title: false,
                    firstname: false,
                    lastname: false,
                    email: false,
                    procedure: false,
                },
                alert: { visible: false, textId: "useradministrationmodal.alert.inputs", color: "success" }
            });
        }

        if (prevProps.createUserState !== this.props.createUserState || prevProps.updateUserState !== this.props.updateUserState) {
            if (this.props.createUserState === 2 || this.props.updateUserState === 2) {
                if (this.props.createError === 'email' || this.props.updateError === 'email') {
                    this.setState({ invalid: { ...this.state.invalid, email: false } });
                }
            }
        }

    }

    handleSubmit = (type, e) => {
        if (type === 'cancel') {
            if (this.props.user && !isEquivalent(this.props.user, this.state.user)) {
                this.toggleConfirm();
            } else {
                this.props.toggle();
            }
        } else if (type === "save") {
            this.save();
        }
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    save = async (e) => {
        let newState = { ...this.state }
        let doSave = true;
        newState.confirm = false;

        //Roles
        if (this.state.user.roles.length <= 0) {
            newState.invalid.roles = true;
            doSave = false;
        }

        //Procedures only check if role student, teacher, school
        if (this.checkForRole([authorizationRoles.student, authorizationRoles.teacher, authorizationRoles.school]) && this.state.user.procedure.length <= 0) {
            newState.invalid.procedure = true;
            doSave = false;
        }

        //School only check if role student, teacher, school
        if (this.checkForRole([authorizationRoles.student, authorizationRoles.teacher, authorizationRoles.school]) && this.state.user.school.id === -1) {
            newState.invalid.school = true;
            doSave = false;
        }

        //Schoolyear only check if role student
        if (this.checkForRole([authorizationRoles.student])) {

            if (this.state.user.schoolyears.id === -1) {
                newState.invalid.schoolyears = true;
                doSave = false;
            } else {
                // new user
                if (this.props.user === null && this.state.user.schoolyears.start < new Date().getYear() + 1900 && this.state.user.schoolyears.end < new Date().getYear() + 1900) {
                    newState.invalid.schoolyears = true;
                    doSave = false;
                } else if (this.props.user !== null && this.props.user.schoolyears.id !== this.state.user.schoolyears.id && this.state.user.schoolyears.start < new Date().getYear() + 1900 && this.state.user.schoolyears.end < new Date().getYear() + 1900) {
                    newState.invalid.schoolyears = true;
                    doSave = false;
                }
            }

        }

        //Function only check if role teacher or school
        if (this.checkForRole([authorizationRoles.teacher, authorizationRoles.school]) && (!this.state.user.functions || (this.state.user.functions && this.state.user.functions.id === -1))) {
            newState.invalid.functions = true;
            doSave = false;
        }

        //Address
        if (this.state.user.address.id === -1) {
            newState.invalid.address = true;
            doSave = false;
        }

        //Title only check if role not student
        if (!this.checkForRole([authorizationRoles.student]) && this.state.user.title.id === -1) {
            newState.invalid.title = true;
            doSave = false;
        }

        //Firstname
        if (this.state.user.firstname.length <= 1) {
            newState.invalid.firstname = true;
            doSave = false;
        }
        //Lastname
        if (this.state.user.lastname.length <= 1) {
            newState.invalid.lastname = true;
            doSave = false;
        }
        //Email
        if (!this.checkForRole([authorizationRoles.student]) || (this.checkForRole([authorizationRoles.student]) && this.state.user.email && this.state.user.email.length > 0)) {
            if (!isEmail(this.state.user.email)) {
                newState.invalid.email = true;
                doSave = false;
            }
        }

        if (doSave) {
            this.props.save(this.state.user);
        } else {
            newState.alert = { visible: true, textId: "useradministrationmodal.alert.inputs", color: "danger" };
            setTimeout(this.hideAlert, config.alertTimeOut);
        }
        this.setState(newState);
    }

    hideAlert = (e) => {
        this.setState({ alert: { visible: false, textId: "useradministrationmodal.alert.inputs", color: "danger" } });
    };

    handleSchoolyears = async (schoolyear) => {
        let newState = { ...this.state }
        newState.user.schoolyears = schoolyear;
        newState.lastschoolyears = schoolyear;
        newState.invalid.schoolyears = false;
        this.setState(newState);
    }
    handleUsersFunctions = async (usersfunctions) => {
        let newState = { ...this.state }
        newState.user.functions = usersfunctions;
        newState.invalid.functions = false;
        this.setState(newState);
    }
    handleSchool = (school) => {
        let newState = { ...this.state }
        //case: user is school:
        if (this.state.user && this.state.user.roles.length === 1 && this.state.user.roles[0].id === 4) {
            newState.user.procedure = school.procedures;
            //case: user is student / teacher:
        } else if (school.procedures.length === 1 && this.state.user && this.state.user.roles.find(e => e.id === 1 || e.id === 2)) {
            newState.user.procedure = school.procedures;
        }

        newState.user.school = school;
        newState.invalid.school = false;
        this.setState(newState);
    }
    handleUsersAddressId = async (id) => {
        let newState = { ...this.state }
        newState.user.address.id = parseInt(id, 10);
        newState.invalid.address = false;
        this.setState(newState);
    }
    handleUsersTitleId = async (id) => {
        let newState = { ...this.state }
        newState.user.title.id = parseInt(id, 10);
        newState.invalid.title = false;
        this.setState(newState);
    }

    handleFirstname = async (e) => {
        let newState = { ...this.state }
        newState.user.firstname = e.target.value;
        newState.invalid.firstname = false;
        this.setState(newState);
    }
    handleLastname = async (e) => {
        let newState = { ...this.state }
        newState.user.lastname = e.target.value;
        newState.invalid.lastname = false;
        this.setState(newState);
    }
    handleEmail = async (e) => {
        let newState = { ...this.state }
        newState.user.email = e.target.value;
        newState.invalid.email = false;
        this.setState(newState);
    }
    handleClass = async (e) => {
        let newState = { ...this.state }
        newState.user.class = e.target.value;
        newState.invalid.class = false;
        this.setState(newState);
    }
    handleCertified = async (e) => {
        let newState = { ...this.state }
        newState.user.certified = !newState.user.certified;
        newState.invalid.certified = false;
        this.setState(newState);
    }
    handleTrainingsupport = async (e) => {
        let newState = { ...this.state }
        newState.user.trainingsupport = e.target.value;
        newState.invalid.trainingsupport = false;
        this.setState(newState);
    }
    handleTrainingdate(date) {
        let newState = { ...this.state }
        newState.user.trainingdate = date;
        this.setState(newState);
    }

    handleRoles = async (roles) => {
        let newState = { ...this.state }
        newState.user.roles = roles;
        newState.invalid.roles = false;

        if (this.state.new && CheckAuthorization('useradministrationmodal-autofill-certified', this.props.auth.roles)) {
            if (this.checkForRole([authorizationRoles.school, authorizationRoles.teacher])) {
                newState.user.certified = 1;
                newState.user.trainingsupport = 'Bildungswerk der Hessischen Wirtschaft e. V.';
            }
        }

        if (this.checkForRole([authorizationRoles.student])) {
            if (newState.user.schoolyears.id === -1) {
                newState.user.schoolyears = this.state.lastschoolyears;
                newState.user.procedure = [];
            }
        }

        if (this.checkForRole([authorizationRoles.school])) {
            if (newState.user.school && newState.user.school.procedures) {
                newState.user.procedure = newState.user.school.procedures;
            }
        }

        if (!this.checkForRole([authorizationRoles.school, authorizationRoles.teacher, authorizationRoles.student])) {
            newState.user.procedure = [{ id: 1, name: 'KomPo7' }, { id: 2, name: 'KomPoG' }];
        }

        this.setState(newState);
    }

    handleProcedures = async (procedure) => {
        let newState = { ...this.state };
        newState.user.procedure = procedure;
        newState.invalid.procedure = false;

        this.setState(newState);
    };

    checkForRole(roles) {
        let tmp = false
        roles.forEach((role) => {
            this.state.user && this.state.user.roles && this.state.user.roles.forEach((sr) => {
                if (role === sr.id) {
                    tmp = true;
                }
            })
        })
        return tmp;
    }

    checkForSchool() {
        if (this.state.user && this.state.user.school && this.state.user.school.procedures && this.state.user.school.procedures.length === 2) {
            return true;
        }
        return false;
    }

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.readOnly = true;
        }
    }

    render() {
        return (
            <Container>
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                <Confirm cancel save={this.save} dontsave={this.props.toggle} open={this.state.confirm} toggle={this.toggleConfirm} headertext="confirm.header.save" bodytext="confirm.body.save" />
                <Form>
                    <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" aria-live="polite" backdrop={'static'} aria-atomic="true" aria-hidden={!this.props.toggle} >
                        <ModalHeader className={this.props.user && "edit"}><FormattedMessage id={!this.props.user ? "useradministrationmodal.title.new" : "useradministrationmodal.title.edit"} /></ModalHeader>

                        <ModalBody className="px-4">
                            {/* USERNAME */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                    <FormattedMessage id="useradministrationmodal.label.username" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    {(this.props.user && this.props.user.username) ? this.props.user.username : <FormattedMessage id="useradministrationmodal.label.nousername" />}
                                </Col>
                            </Row>

                            {/* PERSONALNUMBER */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                    <FormattedMessage id="useradministrationmodal.label.personalnumber" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    {(this.props.user && this.props.user.personalnumber) ? this.props.user.personalnumber : <FormattedMessage id="useradministrationmodal.label.nopersonalnumber" />}
                                </Col>
                            </Row>

                            {/* ROLES  */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <FormattedMessage id="useradministrationmodal.label.roles" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" sm="12" md="9">
                                    <RolesCheckbox withEmpty className="m-0 p-0" roles={this.props.roles} feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.roles} handleRoles={this.handleRoles.bind(this)} selectedRoles={this.state.user ? this.state.user.roles : []} />
                                </Col>
                            </Row>

                            {/* PROCEDURES */}
                            {this.checkForRole([authorizationRoles.student, authorizationRoles.teacher]) && this.checkForSchool() &&
                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                        <FormattedMessage id="procedureselect.header.default" />
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" sm="12" md="9">
                                        <RolesCheckbox withEmpty className="m-0 p-0" roles={this.props.procedures} feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.procedure} handleRoles={this.handleProcedures.bind(this)} selectedRoles={this.state.user ? this.state.user.procedure : []} checkAllowedCombination={false} showAsRadio={this.checkForRole([authorizationRoles.student])} />
                                    </Col>
                                </Row>
                            }

                            {/* SCHOOL */}
                            {this.checkForRole([authorizationRoles.student, authorizationRoles.school, authorizationRoles.teacher]) &&
                                <Row className={this.props.schools && this.props.schools.length > 1 ? "border-bottom" : "d-none"}>
                                    <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                        <FormattedMessage id="useradministrationmodal.label.school" />
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" sm="12" md="9">
                                        <SchoolSelect withEmpty={this.props.schools && this.props.schools.length > 1} className="m-0 p-0" schools={this.props.schools} feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.school} handleSchool={this.handleSchool.bind(this)} schoolId={this.state.user && this.state.user.school ? this.state.user.school.id : -1} />
                                    </Col>
                                </Row>
                            }

                            {/* SCHOOLYEAR */}
                            {this.checkForRole([authorizationRoles.student]) &&
                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                        <FormattedMessage id="useradministrationmodal.label.schoolyears" />
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        <SchoolyearsSelect autoSelect className="m-0 p-0" feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.schoolyears} withEmpty schoolyears={this.props.schoolyears} schoolyearsId={this.state.user && this.state.user.schoolyears && this.state.user.schoolyears.id ? this.state.user.schoolyears.id : -1} handleSchoolyears={this.handleSchoolyears} />
                                    </Col>
                                </Row>
                            }

                            {/* ADDRESS  */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                    <FormattedMessage id="useradministrationmodal.label.address" />
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <UsersAddressSelect className="m-0 p-0" feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.address} withEmpty usersAddressId={this.state.user && this.state.user.address.id} handleUsersAddressId={this.handleUsersAddressId} />
                                </Col>
                            </Row>

                            {/*  TITLE */}
                            {this.checkForRole([authorizationRoles.pm, authorizationRoles.school, authorizationRoles.teacher, authorizationRoles.hkm, authorizationRoles.hk]) || CheckAuthorization('useradministrationmodal-titlefunction-show', this.props.auth.roles) ?
                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                        <FormattedMessage id="useradministrationmodal.label.title" />
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        {<UsersTitleSelect className="m-0 p-0" feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.title} withEmpty usersTitleId={this.state.user && this.state.user.title ? this.state.user.title.id : -1} handleUsersTitleId={this.handleUsersTitleId} />}
                                    </Col>
                                </Row>
                                : null
                            }

                            {/*  FUNCTIONS */}
                            {this.checkForRole([authorizationRoles.school, authorizationRoles.teacher]) || CheckAuthorization('useradministrationmodal-titlefunction-show', this.props.auth.roles) ?
                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                        <FormattedMessage id="useradministrationmodal.label.functions" />
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        <UsersFunctionsSelect className="m-0 p-0" feedbacklabel="useradministrationmodal.feedback.empty" invalid={this.state.invalid.functions} withEmpty usersFunctions={this.props.usersFunctions} usersFunctionsId={this.state.user && this.state.user.functions ? this.state.user.functions.id : -1} handleUsersFunctions={this.handleUsersFunctions} />
                                    </Col>
                                </Row>
                                : null
                            }


                            {/* FIRSTNAME */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                    <Label for="firstname" className="d-flex align-items-center h-100"><FormattedMessage id="useradministrationmodal.label.firstname" /></Label>
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" className="h-100" invalid={this.state.invalid.firstname} name="firstname" type="text" id="firstname" value={this.state.user ? this.state.user.firstname : ""} onChange={this.handleFirstname} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.firstname ? "true" : "false"}><FormattedMessage id="useradministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            {/* LASTNAME */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                    <Label for="lastname" className="d-flex align-items-center h-100"><FormattedMessage id="useradministrationmodal.label.lastname" /></Label>
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" className="h-100" invalid={this.state.invalid.lastname} name="lastname" id="lastname" type="text" value={this.state.user ? this.state.user.lastname : ""} onChange={this.handleLastname} aria-required />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.lastname ? "true" : "false"}><FormattedMessage id="useradministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            {/* EMAIL */}
                            <Row className="border-bottom">
                                <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                    <Label for="email" className="d-flex align-items-center h-100"><FormattedMessage id="useradministrationmodal.label.email" /></Label>
                                </Col>
                                <Col className="mb-2 mt-2" xs="12" md="9">
                                    <div className="m-0 p-0 mb-1">
                                        <Input bsSize="sm" className="h-100" invalid={this.state.invalid.email} name="email" id="email" type="email" value={(this.state.user && this.state.user.email) ? this.state.user.email : ''} onChange={this.handleEmail} />
                                        <FormFeedback className="ps-1" invalid={this.state.invalid.email ? "true" : "false"}><FormattedMessage id="useradministrationmodal.feedback.empty" /></FormFeedback>
                                    </div>
                                </Col>
                            </Row>

                            {/* CLASS */}
                            {this.checkForRole([authorizationRoles.student]) &&
                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                        <Label for="class" className="d-flex align-items-center h-100"><FormattedMessage id="useradministrationmodal.label.class" /></Label>
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        <div className="m-0 p-0 mb-1">
                                            <Input bsSize="sm" className="h-100" name="class" id="class" type="text" value={(this.state.user && this.state.user.class) ? this.state.user.class : ''} onChange={this.handleClass} />
                                        </div>
                                    </Col>
                                </Row>
                            }

                            {/* CERTIFIED */}
                            <Authorization id="useradministrationmodal-certified">
                                {this.checkForRole([authorizationRoles.teacher, authorizationRoles.hk, authorizationRoles.school]) &&
                                    <Row className={CheckAuthorization('useradministrationmodal-certified-edit', this.props.auth.roles) ? 'border-bottom' : 'hidden'} >
                                        <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                            <Label for="class" className="d-flex align-items-center h-100"><FormattedMessage id="useradministrationmodal.label.certified" /></Label>
                                        </Col>
                                        <Col className="mb-2 mt-2" xs="12" md="9">
                                            <div className="m-0 p-0 mb-1">
                                                <Input bsSize="sm" style={{ appearance: 'auto' }} className="p-0 m-0 h-75 d-flex" name="certified" id="certified" type="checkbox" disabled={!CheckAuthorization('useradministrationmodal-certified-edit', this.props.auth.roles)} checked={this.state.user ? this.state.user.certified : false} onChange={this.handleCertified.bind(this)} />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Authorization>

                            {/* TRAINING DATE */}
                            <Authorization id="useradministrationmodal-trainingdate">
                                {this.checkForRole([authorizationRoles.teacher, authorizationRoles.hk, authorizationRoles.school]) &&
                                    <Row className={CheckAuthorization('useradministrationmodal-trainingdate-edit', this.props.auth.roles) ? 'border-bottom' : 'hidden'} >
                                        <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                            <Label for="class" className="d-flex align-items-center h-100"><FormattedMessage id="useradministrationmodal.label.trainingdate" /></Label>
                                        </Col>
                                        <Col className="mb-2 mt-2" xs="12" md="9">
                                            <div className="m-0 p-0 mb-1">
                                                {/* <Input bsSize="sm" className="h-100" name="class" id="class" type="text" value={(this.state.user && this.state.user.class) ? this.state.user.class : ''} onChange={this.handleClass} /> */}
                                                <DatePicker
                                                    firstDayOfWeek={1}
                                                    locale={'de'}
                                                    id="trainingdate"
                                                    name="trainingdate"
                                                    ref={el => this.onDatepickerRef(el)}
                                                    className="text-dark m-0"
                                                    isClearable={CheckAuthorization('useradministrationmodal-trainingdate-edit', this.props.auth.roles)}
                                                    dateFormat="MMMM yyyy"
                                                    disabled={!CheckAuthorization('useradministrationmodal-trainingdate-edit', this.props.auth.roles)}
                                                    selected={this.state.user.trainingdate}
                                                    onChange={this.handleTrainingdate.bind(this)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Authorization>

                            {/* TRAINING SUPPORT */}
                            <Authorization id="useradministrationmodal-trainingsupport">
                                {this.checkForRole([authorizationRoles.teacher, authorizationRoles.hk, authorizationRoles.school]) &&
                                    <Row className={CheckAuthorization('useradministrationmodal-trainingsupport-edit', this.props.auth.roles) ? 'border-bottom' : 'hidden'} >
                                        <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                            <Label for="class" className="d-flex align-items-center h-100"><FormattedMessage id="useradministrationmodal.label.trainingsupport" /></Label>
                                        </Col>
                                        <Col className="mb-2 mt-2" xs="12" md="9">
                                            <div className="m-0 p-0 mb-1">
                                                <Input bsSize="sm" className="h-100" name="trainingsupport" id="trainingsupport" type="text" disabled={!CheckAuthorization('useradministrationmodal-trainingsupport-edit', this.props.auth.roles)} value={(this.state.user && this.state.user.trainingsupport) ? this.state.user.trainingsupport : ''} onChange={this.handleTrainingsupport.bind(this)} />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Authorization>
                        </ModalBody>

                        <ModalFooter>
                            <Button type="submit" color="primary" name="cancel" id="cancel" onClick={this.handleSubmit.bind(this, "cancel")}><FormattedMessage id="button.cancel" /></Button>
                            <Button type="submit" color="primary" name="save" id="save" onClick={this.handleSubmit.bind(this, "save")}><FormattedMessage id="button.save" /></Button>
                        </ModalFooter>

                    </Modal>
                </Form>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolyears: state.schools.schoolyears,
        createUserState: state.userProfile.createUserState,
        updateUserState: state.userProfile.updateUserState,
        createError: state.userProfile.createError,
        updateError: state.userProfile.updateError,
        schools: state.schools.schoolsForUser,
        roles: state.userProfile.roles,
        usersFunctions: state.userProfile.usersFunctions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolyears: (token) => {
            dispatch(schoolsActions.getSchoolyears(token, "start", false));
        },
        dispatchGetSchools: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, true));
        },
        dispatchGetUsersFunction: (token) => {
            dispatch(userProfileActions.getUsersFunctions(token));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAdministrationModal));