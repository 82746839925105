import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Header } from './HeaderPDF';
import { Images } from './ImagesPDF';
import { footer } from './FooterPDF';
import translationsVars from '../translations/translationsVars';

// const blueColor  = '#383980';
// const grayColor  = '#808080';
// const whiteColor = '#ffffff';

const styles = {
    headline: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#d11b28',
        margin: [0, 10, 0, 10]
    },
    subheadline: {
        fontSize: 16,
        fontWeight: 'bold',
        color: [67, 53, 140],
        margin: [0, 10, 0, 4]
    }
};

export const generateVerbExercisePDF = (props, state) => {

    let documentname = props.intl.formatMessage({ id: "verb-exercise.label.verb-exercise" }, translationsVars)
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(createDocument(props, state)).download(documentname);
}

function createDocument(props, state) {
    return {
        pageSize: 'A4',
        pageMargins: [40, 75, 40, 65],
        images: Images,
        header: Header,
        footer: (currentPage, pageCount) => { return footer(props, false, currentPage, pageCount) },
        content: createContent(props, state),
        styles: styles
    };
}

function createContent(props, state) {
    return [
        createHeading(props, state),
        createSection(props, state, "verb-circle-private-label", state.verbsPrivate),
        createSection(props, state, "verb-circle-both-label1", state.verbsBoth),
        createSection(props, state, "verb-circle-work-label", state.verbsWork)
    ];
}

function createHeading(props, state) {
    return [{
        text: props.intl.formatMessage({ id: "verb-exercise.label.verb-exercise" }),
        style: 'headline'
    },
    {
        text: props.intl.formatMessage({ id: 'roles.student' }) + ": " + props.auth.firstname + " " + props.auth.lastname,
        lineHeight: 1.5
    }
    ];
}

function preventPageBreak(content) {
    return {
        layout: 'noBorders',
        table: {
            headerRows: 0,
            dontBreakRows: true,
            widths: [484],
            body: [[content]]
        }
    };
}


function createSection(props, state, heading, content) {
    const result = [];
    let idx = 0;
    if (content.length === 0) {
        result.push([{ text: props.intl.formatMessage({ id: heading }), style: 'subheadline', noPageBreak: 'after' }]);
    } else {
        //no page break between subheadline and first table
        result.push([{
            unbreakable: true, stack: [
                { text: props.intl.formatMessage({ id: heading }), style: 'subheadline', noPageBreak: 'after' },
                preventPageBreak(createTable(props, content[0].name, content[0].question, content[0].params ? content[0].params.answer : "", content[0].params ? content[0].params.highlight : 0))
            ]
        }])
    }

    for (const entry of content) {
        if (idx !== 0) {
            result.push(preventPageBreak(createTable(props, entry.name, entry.question, entry.params ? entry.params.answer : "", entry.params ? entry.params.highlight : 0)));
        }
        idx += 1;
    }

    return result;
}

function createTable(props, verb, question, answer, highlight) {
    return {
        margin: [0, 10, 0, 0],
        table: {
            widths: [330, 170],
            heights: [12, 6, 16],
            body: [
                [
                    { text: props.intl.formatMessage({ id: verb }), rowSpan: 2, color: '#ffffff', fillColor: highlight ? [121, 172, 44] : [67, 53, 140], margin: [4, 5, 0, 0] },
                    { text: '', border: [false, false, false, false] }
                ],
                [
                    '',
                    { text: '', border: [true, true, true, false] }
                ],
                [
                    { text: props.intl.formatMessage({ id: question }), colSpan: 2, color: 'gray', margin: [4, 5, 0, 0], border: [true, false, true, false] },
                    ''
                ],
                [
                    { text: answer, colSpan: 2, margin: [4, 2, 0, 0], border: [true, false, true, true] },
                    ''
                ]
            ]
        }
    };
}